import { listCustomerBillByCustomerNamePopUp } from "api/customerBill"; 
import useAPI from "hooks/useAPI";
import { useCallback, useEffect, useState } from 'react'

const useListCustomerBillPopUp = ({ currentPageNumber, pageSize }) => {
    const [searchQuery, setSearchQuery] = useState({})
    const {
        makeRequest: listCustomerBillPopUp,
        data: listCustomerBillPopUpData,
        loading: isLoadingListCustomerBIllPopUp,
        pagination: listCustomerBillPopUpPagination,
    } = useAPI()

    const onSearch = useCallback(({ searchName, searchTotal }) => {
        setSearchQuery({
            name: searchName,
            bill_amount: searchTotal,
        })
    }, [])

    useEffect(() => {
        listCustomerBillPopUp(() =>
        listCustomerBillByCustomerNamePopUp({
            page: currentPageNumber,
            pageSize,
            ...searchQuery
        })
        )
    }, [listCustomerBillPopUp, searchQuery, currentPageNumber, pageSize])

    return {
        listCustomerBillPopUpData,
        isLoadingListCustomerBIllPopUp,
        listCustomerBillPopUpPagination,
        onSearch,
    }
}

export default useListCustomerBillPopUp