/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Row, Col, Divider } from 'antd'
import { CloseStoreDataProvider } from './contexts/CloseStoreDataContext'
import DatePickerCloseStore from './components/DatePickerCloseStore'
import BankNoteFormCloseStore from './components/BankNoteFormCloseStore'
import BillVatFormCloseStore from './components/BillVatFormCloseStore'
import ListBillVatTable from './components/ListBillVatTable'
import AmendsFormCloseStore from './components/AmendsFormCloseStore'
import ListAmendsTable from './components/ListAmendsTable'

const CloseStorePage = (props) => {
  useEffect(() => {
    document.title = 'ปิดร้าน (CLose) - NoVat'
  }, [])
  return (
    <CloseStoreDataProvider>
      <h1>ปิดร้าน</h1>
      <div className="form-wrapper">
        <DatePickerCloseStore />
        <BankNoteFormCloseStore />
        <Divider />
        <div
          style={{ fontWeight: 700, fontSize: 20, margin: '0px 0px 10px 0px' }}
        >
          บิล (Vat) หรือ บิลอื่นๆ ที่เป็นเงินสด
        </div>
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 10 }}>
            <BillVatFormCloseStore />
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 14 }}>
            <ListBillVatTable />
          </Col>
        </Row>
        <Divider />
        <div
          style={{ fontWeight: 700, fontSize: 20, margin: '0px 0px 10px 0px' }}
        >
          รายการ เพิ่ม/ลด เงินสด
        </div>
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 10 }}>
            <AmendsFormCloseStore />
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 14 }}>
            <ListAmendsTable />
          </Col>
        </Row>
      </div>
    </CloseStoreDataProvider>
  )
}

export default CloseStorePage
