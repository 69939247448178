import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { handleAlert } from 'utils'
import { handleUnAuthorized } from 'utils/errorHandler'
import { addAuthRequestInterceptor, tokenToString } from 'utils/request'

const useAPI = () => {
  const dispatch = useDispatch()
  const [data, setData] = useState(undefined)
  const [pagination, setPagination] = useState(undefined)
  const [loading, setLoading] = useState(false)

  const onSetData = useCallback((data) => {
    setData(data)
  }, [])

  const onSetPagination = useCallback((pagination) => {
    setPagination(pagination)
  }, [])

  const onSetLoading = useCallback((loading) => {
    setLoading(loading)
  }, [])

  const makeRequest = useCallback(
    async (
      api,
      params = { dataTransformer: undefined, isThrowError: false }
    ) => {
      if (!tokenToString()) {
        return handleUnAuthorized(dispatch)
      }

      setLoading(true)
      try {
        addAuthRequestInterceptor()
        const apiResponse = await api()
        if (apiResponse) {
          // NOTE: dataTransformer is a function that transform data from api response
          if (params?.dataTransformer) {
            setData(
              params?.dataTransformer(
                apiResponse.data?.data
                  ? apiResponse.data?.data
                  : apiResponse.data
              )
            )
          } else {
            setData(
              apiResponse.data?.data ? apiResponse.data?.data : apiResponse.data
            )
          }
          setPagination(apiResponse.data?.pagination ?? undefined)
          setLoading(false)
        }
        return apiResponse
      } catch (error) {
        console.error(error)
        setLoading(false)
        if (params?.isThrowError) {
          throw error
        }
        handleAlert('error', error.message || error)
      }
    },
    [dispatch]
  )

  return {
    makeRequest,
    data,
    pagination,
    loading,
    onSetData,
    onSetPagination,
    onSetLoading,
  }
}

export default useAPI
