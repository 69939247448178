/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Button, Spin, Divider, Popconfirm } from 'antd'
import {
  ClearOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'

import InlineInput from 'components/InlineInput'
import ListBillPaymentMethodTable from 'components/ListBillPaymentMethodTable'
import { handleAlert, PAYMENT_METHODS } from 'utils'

import { onListBillPaymentMethod, onListSaleChannel } from 'redux/actions'

const DEFAULT_SEARCH_LIST = {
  date: moment().format('YYYY-MM-DD'),
  sale_channel_id: 3,
  payment_method_id: null,
  payment_method: 'all',
}

const ListBillPaymentMethodPage = () => {
  const [search, setSearch] = useState(DEFAULT_SEARCH_LIST)
  const [list, setList] = useState([])
  const [sumGrandTotal, setSumGrandTotal] = useState('')
  const [isTableLoading, setIsTableLoading] = useState(false)
  const dispatch = useDispatch()
  const listBillPaymentMethod = useSelector((state) => state.listBillPaymentMethod)
  const listSaleChannel = useSelector((state) => state.listSaleChannel)

  useEffect(() => {
    document.title = 'ตรวจสอบวิธีการชำระเงิน'
    dispatch(onListSaleChannel())
  }, [])

  useEffect(() => {
    const { trace, error, finished, data } = listBillPaymentMethod
    if (error) {
      handleAlert('custom', trace.message || '')
      setIsTableLoading(false)
    }
    if (!error && data && finished) {
      setList(data.bills_list)
      setSumGrandTotal(data.sum_grand_total)
      setIsTableLoading(false)
    }
  }, [listBillPaymentMethod])

  const onSearchClick = () => {
    setIsTableLoading(true)
    dispatch(
      onListBillPaymentMethod(
        search.sale_channel_id,
        search.date,
        search.payment_method,
      )
    )
  }

  const setPeymentMethod = (method_id) => {
    let payment_method_id = null
    let payment_method = 'all'
    PAYMENT_METHODS.forEach((x) => {
      if (x.id === method_id) {
        payment_method_id = x.id
        payment_method = x.value
      }
    })
    setSearch({
      ...search,
      payment_method_id,
      payment_method,
    })
  }

  const onClearInput = () => {
    setSearch(DEFAULT_SEARCH_LIST)
    setList([])
    setSumGrandTotal('')
  }

  return (
    <div className="no-print">
      {/* <Spin spinning={loading}> */}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'inline-block' }}>
          <h1>ตรวจสอบวิธีการชำระเงิน</h1>
        </div>
        <div style={{ display: 'inline-block' }}>
          <Popconfirm
            title="คุณต้องการล้างหน้าจอใช่หรือไม่ ?"
            okText="ใช่"
            cancelText="ยกเลิก"
            onConfirm={onClearInput}
          >
            <Button
              type="default"
              danger
              icon={<ClearOutlined />}
              size="large"
              block
            >
              ล้างหน้าจอ
            </Button>
          </Popconfirm>
        </div>
      </div>
      {/* <button onClick={() => console.log(form)}>FORM</button> */}
      <div className="form-wrapper">
        <div className="input-wrapper">
          <div className="input-item">
            <InlineInput
              label="วันที่ค้นหา"
              placeholder="เลือกวันที่"
              defaultValue={moment()}
              value={search.date && moment(search.date)}
              inputOption="date_picker"
              onChange={(_, dateString) => {
                setSearch({ ...search, date: dateString })
              }}
            />
          </div>
          <div className="input-item">
            <InlineInput
              label="ช่องทางการขาย"
              value={search.sale_channel_id}
              onChange={(channel_id) => {
                setSearch({ ...search, sale_channel_id : channel_id})
              }}
              width="150px"
              inputOption="select_input"
              options={listSaleChannel.data}
            />
          </div>
          <div className="input-item">
            <InlineInput
              label="ช่องทางชำระเงิน"
              value={search.payment_method_id}
              onChange={(method_id) => {
                setPeymentMethod(method_id)
              }}
              width="150px"
              inputOption="select_input"
              options={[
                { id: null, name: 'ทั้งหมด', value: 'all' },
                ...PAYMENT_METHODS,
              ]}
            />
          </div>
          <div className="input-item">
            <Button
              type="primary"
              icon={<SearchOutlined />}
              size="medium"
              onClick={onSearchClick}
              style={{ width: '184px' }}
            >
              ค้นหา
            </Button>
          </div>
        </div>
        <Divider style={{ margin: '10px 0px 20px' }} />
        <div className="input-wrapper">
          <div className="input-item">
            <InlineInput
              className="text-align-right"
              label="ยอดรวมบิลทั้งหมด"
              width="125px"
              value={sumGrandTotal}
              disabled={true}
              unit="บาท"
            />
          </div>
        </div>
        <Spin spinning={isTableLoading}>
          <ListBillPaymentMethodTable list={list} />
        </Spin>
      </div>
    </div>
  )
}

export default withRouter(ListBillPaymentMethodPage)
