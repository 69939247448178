import { listSupplierBillBySupplierNamePopUp } from "api/supplierBill"; 
import useAPI from "hooks/useAPI";
import { useCallback, useEffect, useState } from 'react'

const useListSupplierBillPopUp = ({ currentPageNumber, pageSize }) => {
    const [searchName, setSearchName] = useState(undefined)
    const {
        makeRequest: listSupplierBillPopUp,
        data: listSupplierBillPopUpData,
        loading: isLoadingListSupplierBillPopUp,
        pagination: listSupplierBillPopUpPagination,
    } = useAPI()

    const onSearchName = useCallback(({ searchName }) => {
        setSearchName(searchName)
    }, [])

    useEffect(() => {
        listSupplierBillPopUp(() =>
        listSupplierBillBySupplierNamePopUp({
            page: currentPageNumber,
            pageSize,
            name: searchName,
        })
        )
    }, [listSupplierBillPopUp, searchName, currentPageNumber, pageSize])

    return {
        listSupplierBillPopUpData,
        isLoadingListSupplierBillPopUp,
        listSupplierBillPopUpPagination,
        onSearchName,
    }
}

export default useListSupplierBillPopUp