import React, { useState, createContext } from 'react'
import useRequestStoreReportData from '../hooks/useRequestStoreReportData'
import moment from 'moment'

moment.locale('th')

// NOTE: Context
const StoreReportDataContext = createContext({})

// NOTE: Provider
const StoreReportDataProvider = ({ children }) => {
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
  const {
    reportData,
    reportDataLoading,
    onRefreshReport,
  } = useRequestStoreReportData({ date })

  const onDateChange = (value) => {
    setDate(value)
  }

  return (
    <StoreReportDataContext.Provider
      value={{
        date,
        onDateChange,
        reportData,
        reportDataLoading,
        onRefreshReport,
      }}
    >
      {children}
    </StoreReportDataContext.Provider>
  )
}

export { StoreReportDataContext, StoreReportDataProvider }
