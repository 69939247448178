import React from 'react'
import { Button } from 'antd'
import moment from 'moment'
import useListPriceInPriceOutDataContext from 'pages/ListPriceInPriceOutComparisonPage/hooks/useListPriceInPriceOutDataContext'
import {
  ReloadOutlined,
  LeftCircleOutlined,
  RiseOutlined,
} from '@ant-design/icons'
import InlineInput from 'components/InlineInput'
import './style.css'

const ProfitMonthlySearch = () => {
  const { profitMonth, setProfitMonth, onClickRefreshProfit } =
    useListPriceInPriceOutDataContext()
  return (
    <div className="input-wrapper" style={{ marginBottom: '15px' }}>
      <div className="input-item profit-title">
        <RiseOutlined style={{ color: 'green' }} /> กำไร
      </div>
      <div className="input-item">
        <InlineInput
          label="เดือนที่ค้นหา"
          required
          defaultValue={moment()}
          value={profitMonth && moment(profitMonth)}
          inputOption="date_picker"
          onChange={(_, dateString) => {
            setProfitMonth(dateString)
          }}
          pickerType="month"
        />
      </div>
      <div className="input-item">
        <Button
          type="primary"
          icon={<ReloadOutlined />}
          size="medium"
          onClick={onClickRefreshProfit}
          style={{ width: '120px' }}
        >
          Refresh
        </Button>
      </div>
      <div className="input-item">
        <Button
          disabled
          className="icon-more-than-thirty"
          shape="circle"
          icon={<LeftCircleOutlined />}
        />
        30 ถึง 49%
        <Button
          disabled
          className="icon-more-than-fifty"
          shape="circle"
          icon={<LeftCircleOutlined />}
        />
        50 ถึง 74%
        <Button
          disabled
          className="icon-more-than-seventy-five"
          shape="circle"
          icon={<LeftCircleOutlined />}
        />
        มากกว่า 75%
      </div>
    </div>
  )
}

export default ProfitMonthlySearch
