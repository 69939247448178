import React from 'react'
import useCloseStoreDataContext from '../hooks/useCloseStoreDataContext'
import { layout, tailLayout } from 'constants/layout'
import { Spin, Button, Form, Input, Space } from 'antd'
import moment from 'moment'

moment.locale('th')

const BankNoteFormCloseStore = () => {
  const {
    id,
    form,
    onCashChange,
    onSubmitCashForm,
    addCashesLoading,
    onResetForm,
    listCashesLoading,
  } = useCloseStoreDataContext()

  const initialValues = {
    thousand: 0,
    fivehundred: 0,
    onehundred: 0,
    fifty: 0,
    twenty: 0,
    ten: 0,
    five: 0,
    two: 0,
    one: 0,
    total: 0,
  }

  const renderStatus = () => {
    return (
      <div style={{ margin: '0px 0px 10px 0px', fontWeight: 700 }}>
        สถานะ :{' '}
        {id === 0 ? (
          <span style={{ color: 'green' }}>สร้างรายการใหม่</span>
        ) : (
          <span style={{ color: 'blue' }}>กำลังแก้ไขรายการที่บันทึกไปแล้ว</span>
        )}
      </div>
    )
  }

  return (
    <Spin spinning={addCashesLoading || listCashesLoading}>
      {renderStatus()}
      <Form
        {...layout}
        form={form}
        name="banknote"
        onFinish={onSubmitCashForm}
        onValuesChange={onCashChange}
        style={{
          maxWidth: 200,
        }}
        initialValues={initialValues}
      >
        <Form.Item
          name="thousand"
          label="1,000"
          rules={[
            {
              required: true,
              message: 'กรุณาระบุข้อมูลให้ครบถ้วน'
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          name="fivehundred"
          label="500"
          rules={[
            {
              required: true,
              message: 'กรุณาระบุข้อมูลให้ครบถ้วน'
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          name="onehundred"
          label="100"
          rules={[
            {
              required: true,
              message: 'กรุณาระบุข้อมูลให้ครบถ้วน'
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          name="fifty"
          label="50"
          rules={[
            {
              required: true,
              message: 'กรุณาระบุข้อมูลให้ครบถ้วน'
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          name="twenty"
          label="20"
          rules={[
            {
              required: true,
              message: 'กรุณาระบุข้อมูลให้ครบถ้วน'
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          name="ten"
          label="10"
          rules={[
            {
              required: true,
              message: 'กรุณาระบุข้อมูลให้ครบถ้วน'
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          name="five"
          label="5"
          rules={[
            {
              required: true,
              message: 'กรุณาระบุข้อมูลให้ครบถ้วน'
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          name="two"
          label="2"
          rules={[
            {
              required: true,
              message: 'กรุณาระบุข้อมูลให้ครบถ้วน'
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          name="one"
          label="1"
          rules={[
            {
              required: true,
              message: 'กรุณาระบุข้อมูลให้ครบถ้วน'
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item name="total" label="รวม">
          <Input type="number" disabled />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Space>
            <Button type="primary" htmlType="submit">
              บันทึก
            </Button>
            <Button htmlType="button" onClick={onResetForm}>
              รีเซ็ต
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Spin>
  )
}

export default BankNoteFormCloseStore
