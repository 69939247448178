import React from 'react'
import { Table, Input, Button, Popconfirm } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

const DeliveryOrderTable = (props) => {
  const { onChange, onDelete, isDataFromBill } = props
  const tableColumns = [
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: '50px',
      fixed: 'left',
      render: (_, record, index) => {
        return (
          <Popconfirm
            title="คุณต้องการลบรายการนี้ใช่ไหม ?"
            okText="ลบ"
            cancelText="ยกเลิก"
            onConfirm={() => onDelete(record, index)}
          >
            <Button
              size="small"
              type="primary"
              danger
              disabled={isDataFromBill ? true : false}
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        )
      },
    },
    {
      title: 'รหัส',
      dataIndex: 'product_vat_code',
      key: 'product_vat_code',
      width: '70px',
      fixed: 'left',
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'product_vat_name',
      key: 'product_vat_name',
      width: '400px',
      fixed: 'left',
    },
    {
      title: 'จำนวน',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '80px',
      render: (_, record, index) => {
        return (
          <Input
            className="text-align-right"
            value={record.quantity}
            disabled={isDataFromBill ? true : false}
            onChange={(event) =>
              onChange(record, 'quantity', event.target.value, index)
            }
          />
        )
      },
    },
    {
      title: 'หน่วย',
      dataIndex: 'unit_name',
      key: 'unit_name',
      width: '80px',
    },
    {
      title: 'ราคาต่อหน่วย',
      dataIndex: 'price_out',
      key: 'price_out',
      width: '110px',
      className: 'text-align-right',
    },
    {
      title: 'ส่วนลด',
      dataIndex: '',
      key: 'v',
      width: '230px',
      render: (_, record) => {
        return `${record.discount_1 ? castFloat2Int(record.discount_1) : 0}+${
          record.discount_2 ? castFloat2Int(record.discount_2) : 0
        }+${record.discount_3 ? castFloat2Int(record.discount_3) : 0}+${
          record.discount_4 ? castFloat2Int(record.discount_4) : 0
        }`
      },
    },
    {
      title: 'ยอดรวม',
      dataIndex: 'total',
      key: 'total',
      width: '110px',
      render: (text) => <div className="text-align-right">{text}</div>,
    },
    {
      title: 'หมายเหตุ',
      dataIndex: 'remark',
      key: 'remark',
      width: '200px',
      render: (_, record, index) => {
        return (
          <Input
            value={(record.remark)}
            disabled={isDataFromBill ? true : false}
            size="small"
            onChange={(event) =>
              onChange(record, 'remark', event.target.value, index)
            }
          />
        )
      },
    },
    {
      title: 'รหัสปกติ',
      dataIndex: 'product_normal_code',
      key: 'product_normal_code',
      width: '70px',
    },
    {
      title: 'ชื่อสินค้าปกติ',
      dataIndex: 'product_normal_name',
      key: 'product_normal_name',
      width: '250px',
    },
  ]

  const castFloat2Int = (float) => {
    return Number(float).toFixed(4)
  }

  return (
    <div>
      <Table
        rowKey={(record) => `id+${record.product_code}`}
        dataSource={props.productList}
        columns={tableColumns}
        pagination={false}
        scroll={{ x: 1500 }}
        size="small"
        style={{ minHeight: '320px', backgroundColor: '#FFFFFF' }}
      />
    </div>
  )
}

export default DeliveryOrderTable
