import React from 'react'
import useCloseStoreDataContext from '../hooks/useCloseStoreDataContext'
import { Spin, Form, Input, Select, Space, Button } from 'antd'
import { layout, tailLayout } from 'constants/layout'

const AmendsFormCloseStore = () => {
  const { amendsId, amendsForm, onSubmitAmendsForm, onResetAmendsForm } =
    useCloseStoreDataContext()
  const initialValues = {
    type: '',
    cash_amount: 0,
    remark: '',
  }

  const renderStatus = () => {
    return (
      <div style={{ margin: '0px 0px 10px 0px', fontWeight: 700 }}>
        สถานะ :{' '}
        {amendsId === 0 ? (
          <span style={{ color: 'green' }}>สร้างรายการใหม่</span>
        ) : (
          <span style={{ color: 'blue' }}>แก้ไขรายการที่ : {amendsId}</span>
        )}
      </div>
    )
  }

  return (
    <>
      <Spin spinning={false}>
        {renderStatus()}
        <Form
          {...layout}
          form={amendsForm}
          name="amendsForm"
          onFinish={onSubmitAmendsForm}
          style={{
            maxWidth: 300,
          }}
          initialValues={initialValues}
        >
          <Form.Item
            name="type"
            label="ประเภท"
            rules={[
              {
                required: true,
                message: 'กรุณาระบุข้อมูลให้ครบถ้วน'
              },
            ]}
          >
            <Select
              options={[
                {
                  value: 'plus',
                  label: 'เพิ่ม',
                },
                {
                  value: 'minus',
                  label: 'ลด',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="cash_amount"
            label="จำนวนเงิน"
            rules={[
              {
                required: true,
                message: 'กรุณาระบุข้อมูลให้ครบถ้วน'
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item name="remark" label="หมายเหตุ">
            <Input />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Space>
              <Button type="primary" htmlType="submit">
                บันทึก
              </Button>
              <Button htmlType="button" onClick={onResetAmendsForm}>
                รีเซ็ต
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Spin>
    </>
  )
}

export default AmendsFormCloseStore
