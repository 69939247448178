import React from 'react'
import { Form, Input, Space, Button } from 'antd'
import useEditPriceInDataContext from '../hooks/useEditPriceInDataContext'
import { layout, tailLayout } from 'constants/layout'

const SavePriceInFormEditPriceIn = () => {
  const { selectedId, saveForm, onSubmitForm, onResetForm } =
    useEditPriceInDataContext()
  const initialValues = {
    customer_bill_no: '',
    price_in: '',
    price_out: '',
    quantity: '',
    unit_name: '',
    total: '',
    product_vat_type_name: '',
    new_price_in: 0,
  }
  const renderStatus = () => {
    console.log('ID : ', selectedId)
    return (
      <div style={{ margin: '0px 0px 20px 0px', fontWeight: 700 }}>
        สถานะ :{' '}
        {selectedId === 0 ? (
          <span style={{ color: 'darkgray' }}>ยังไม่มีรายการถูกเลือก</span>
        ) : (
          <span style={{ color: 'blue' }}>
            กำลังแก้ไข ID ที่ : {selectedId}
          </span>
        )}
      </div>
    )
  }
  return (
    <>
      {renderStatus()}
      <Form
        {...layout}
        form={saveForm}
        name="editpriceform"
        onFinish={onSubmitForm}
        style={{
          maxWidth: 300,
        }}
        initialValues={initialValues}
      >
        <Form.Item name="customer_bill_no" label="เลขที่บิล">
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="new_price_in"
          label="ราคาเข้าใหม่"
          rules={[
            {
              required: true,
              message: 'กรุณาระบุ ราคาเข้าใหม่ ก่อนบันทึก',
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item name="price_in" label="ราคาเข้า">
          <Input disabled />
        </Form.Item>
        <Form.Item name="price_out" label="ราคาขาย">
          <Input disabled />
        </Form.Item>
        {/* <Form.Item name="quantity" label="จำนวน">
          <Input disabled />
        </Form.Item>
        <Form.Item name="unit_name" label="หน่วย">
          <Input disabled />
        </Form.Item>
        <Form.Item name="total" label="ยอดรวม">
          <Input disabled />
        </Form.Item>
        <Form.Item name="product_vat_type_name" label="VAT">
          <Input disabled />
        </Form.Item> */}
        <Form.Item {...tailLayout}>
          <Space>
            <Button type="primary" htmlType="submit">
              บันทึก
            </Button>
            <Button htmlType="button" onClick={onResetForm}>
              รีเซ็ต
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  )
}

export default SavePriceInFormEditPriceIn
