import React from 'react'
import { Table } from 'antd'
// import styled from 'styled-components'

import { addCommas } from 'utils'
import './style.scss'

// const LinkStyle = styled.div`
//   text-decoration: underline;
//   cursor: pointer;
// `

const MoveStockTable = (props) => {
  const columns = [
    {
      title: 'รหัสสินค้า',
      dataIndex: 'code',
      key: 'code',
      fixed: 'left',
      width: 80,
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: 450,
    },
    {
      title: 'สต็อก1',
      dataIndex: 'stock1',
      key: 'stock1',
      width: 100,
      render: (text) => (
        <div className="text-align-right">{addCommas(text)}</div>
      ),
    },
    {
      title: 'สต็อก2',
      dataIndex: 'stock2',
      key: 'stock2',
      width: 100,
      render: (text) => (
        <div className="text-align-right">{addCommas(text)}</div>
      ),
    },
    {
      title: 'ราคาเข้า1',
      dataIndex: 'price_in_1',
      key: 'price_in_1',
      width: 110,
      render: (text) => (
        <div className="text-align-right">
          {addCommas(castFloat2Int(text).toFixed(4))}
        </div>
      ),
    },
    {
      title: 'ราคาเข้า2',
      dataIndex: 'price_in_2',
      key: 'price_in_2',
      width: 110,
      render: (text) => (
        <div className="text-align-right">
          {addCommas(castFloat2Int(text).toFixed(4))}
        </div>
      ),
    },
    {
      title: 'ราคาตั้งราคาส่ง',
      dataIndex: 'price_out_atm',
      key: 'price_out_atm',
      width: 110,
      render: (text) => (
        <div className="text-align-right">
          {addCommas(castFloat2Int(text).toFixed(4))}
        </div>
      ),
    },
    {
      title: 'ส่วนลดราคาส่ง',
      dataIndex: '',
      key: 'x',
      width: 130,
      render: (_, record) => {
        return `${
          record.discount_atm_1 ? castFloat2Int(record.discount_atm_1) : 0
        }+${record.discount_atm_2 ? castFloat2Int(record.discount_atm_2) : 0}+${
          record.discount_atm_3 ? castFloat2Int(record.discount_atm_3) : 0
        }+${record.discount_atm_4 ? castFloat2Int(record.discount_atm_4) : 0}`
      },
    },
    {
      title: 'ราคาตั้งราคาปลีก',
      dataIndex: 'price_out_cash',
      key: 'price_out_cash',
      width: 110,
      render: (text) => (
        <div className="text-align-right">
          {addCommas(castFloat2Int(text).toFixed(4))}
        </div>
      ),
    },
    {
      title: 'ส่วนลดราคาปลีก',
      dataIndex: '',
      key: 'x',
      width: 130,
      render: (_, record) => {
        return `${
          record.discount_cash_1 ? castFloat2Int(record.discount_cash_1) : 0
        }+${
          record.discount_cash_2 ? castFloat2Int(record.discount_cash_2) : 0
        }+${
          record.discount_cash_3 ? castFloat2Int(record.discount_cash_3) : 0
        }+${record.discount_cash_4 ? castFloat2Int(record.discount_cash_4) : 0}`
      },
    },
    {
      title: 'ราคาตั้ง Vat',
      dataIndex: 'product_vat_price_out',
      key: 'product_vat_price_out',
      width: 110,
      render: (text) => (
        <div className="text-align-right">
          {addCommas(castFloat2Int(text).toFixed(4))}
        </div>
      ),
    },
    {
      title: 'สินค้า Vat',
      dataIndex: 'product_vat_code',
      key: 'product_vat_code',
      width: 200,
      render: (_, record) => {
        if (record.product_vat_code !== '') {
          return (
            <div className="break-word">{`${record.product_vat_code}: ${record.product_vat_name}`}</div>
          )
        } else {
          return (
            <div>ไม่มีสินค้า Vat</div>
          )
        }
      },
    },
  ]

  const castFloat2Int = (float) => {
    return Number(float)
  }

  const getRowClassName = (record) => {
    if (record.price_in_2 > record.price_in_1) {
      return 'red-row cursor-pointer'
    } else {
      return 'cursor-pointer'
    }
  }

  return (
    <div>
      <Table
        bordered
        size="small"
        rowKey="id"
        dataSource={props.moveStockList}
        columns={columns}
        scroll={{ y: 770, x: 1800 }}
        rowClassName={getRowClassName}
        style={{ height: '780px', maxWidth: '1850px' }}
        pagination={{
          simple: true,
          defaultPageSize: 20,
          showSizeChanger: true,
          position: ['bottomRight'],
          pageSizeOptions: ['10', '20', '50', '100'],
        }}
        onRow={(record) => {
          return {
            onClick: () => {
              props.onClickProduct(record)
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              })
            },
          }
        }}
      />
    </div>
  )
}

export default MoveStockTable
