import React, { useEffect } from 'react'
import { Form, Input, Button, Radio } from 'antd'
import { withRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { loginRequest } from '../../redux/actions'
import './style.scss'

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
}
const tailLayout2 = {
  wrapperCol: {
    span: 16,
  },
}

const LoginPage = () => {
  useEffect(() => {
    document.title = 'Login Page'
  }, [])
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }
  const dispatch = useDispatch()

  const handleLogin = (values) => {
    dispatch(loginRequest(values))
  }

  return (
    <div className="mt-3">
      <Form
        {...layout}
        name="basic"
        initialValues={{
          remember: false,
        }}
        onFinish={handleLogin}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
              message: 'Please input your username!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          {...tailLayout2}
          label="ทำงานกับสต็อก"
          name="menu"
          rules={[{ required: true, message: 'กรุณาเลือกสต็อก' }]}
        >
          <Radio.Group>
            <Radio value="blue" className="blue-menu">
              สต็อกโนแวต (NO-VAT)
            </Radio>
            <Radio value="pink" className="pink-menu">
              สต็อกแวต (VAT)
            </Radio>
          </Radio.Group>
        </Form.Item>

        {/* <Form.Item {...tailLayout} name="remember" valuePropName="checked">
          <Checkbox>Remember me</Checkbox>
        </Form.Item> */}

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default withRouter(LoginPage)
