/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Button, Spin, Table, Popconfirm, Modal } from 'antd'
import { SaveOutlined, ClearOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

import { onListBillItemBySupplierAndProductNoVat } from 'redux/actions'
import { useSelector, useDispatch } from 'react-redux'

import InlineInput from 'components/InlineInput'
import SearchProductNoVatPopUp from 'components/SearchProductNoVatPopUp'
import SearchSupplierPopUp from 'components/SearchSupplierPopUp'
import { handleAlert, addCommas } from 'utils'

const DEFAULT_FORM = {
  supplier_code: '',
  product_code: '',
  period: 'three_month',
  period_id: 1,
}

const searchTimePeriod = [
  {
    id: 1,
    name: 'ย้อนหลัง 3 เดือน',
    value: 'three_month',
  },
  {
    id: 2,
    name: 'ย้อนหลัง 1 ปี',
    value: 'one_year',
  },
  {
    id: 3,
    name: 'ย้อนหลังทั้งหมด',
    value: 'all',
  },
]

const PurchaseHistoryNoVatPage = () => {
  const columns = [
    {
      title: 'รหัสผู้ขาย',
      dataIndex: 'supplier_code',
      key: 'supplier_code',
      width: '80px',
      sorter: (a, b) => ('' + a.supplier_code).localeCompare(b.supplier_code),
      sortDirections: ['ascend', 'descend'],
      fixed: 'left',
    },
    {
      title: 'ชื่อผู้ขาย',
      dataIndex: 'supplier_name',
      key: 'supplier_name',
      width: '180px',
      sorter: (a, b) => ('' + a.supplier_name).localeCompare(b.supplier_name),
      sortDirections: ['ascend', 'descend'],
      fixed: 'left',
    },
    {
      title: 'วันที่ซื้อ',
      dataIndex: 'bill_date',
      key: 'bill_date',
      width: '70px',
      sorter: (a, b) => ('' + a.bill_date).localeCompare(b.bill_date),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'เลขที่บิล',
      dataIndex: 'bill_no',
      key: 'bill_no',
      width: '120px',
      render: (text, record) => (
        <Link to={`/no-vat/stock?bill_no=${record.bill_no}`} target="_blank">
          {text}
        </Link>
      ),
    },
    {
      title: 'รหัสสินค้า',
      dataIndex: 'product_code',
      key: 'product_code',
      width: '80px',
      sorter: (a, b) => ('' + a.product_code).localeCompare(b.product_code),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'product_name',
      key: 'product_name',
      width: '300px',
    },
    {
      title: 'จำนวน',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '50px',
    },
    {
      title: 'หน่วย',
      dataIndex: 'unit_name',
      key: 'unit_name',
      width: '50px',
      render: (text) => <div className="text-align-right pr-2">{text}</div>,
    },
    {
      title: 'ราคาตั้ง',
      dataIndex: 'price_in',
      key: 'price_in',
      width: '80px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
    },
    {
      title: 'ส่วนลด',
      dataIndex: 'discount',
      key: 'discount',
      width: '120px',
      render: (_, record) => {
        return `${Number(record.discount_1)}+${Number(
          record.discount_2
        )}+${Number(record.discount_3)}+${Number(record.discount_4)}+${Number(
          record.discount_5
        )}+${Number(record.discount_6)}`
      },
    },
    {
      title: 'ส่วนลดพิเศษ',
      dataIndex: 'special_discount',
      key: 'special_discount',
      width: '90px',
    },
  ]
  const [form, setForm] = useState(DEFAULT_FORM)
  const [billList, setBillList] = useState([])
  const [isListLoading, setIsListLoading] = useState(false)
  const [isShowProductPopUp, setIsShowProductPopUp] = useState(false)
  const [isShowSupplierPopUp, setIsShowSupplierPopUp] = useState(false)
  const dispatch = useDispatch()
  const listBillItemBySupplierAndProductNoVat = useSelector(
    (state) => state.listBillItemBySupplierAndProductNoVat
  )

  useEffect(() => {
    document.title = 'ค้นหาประวัติการซื้อสินค้า'
  }, [])

  useEffect(() => {
    const { loading, data, finished, error, trace } =
    listBillItemBySupplierAndProductNoVat
    setIsListLoading(loading)
    if (!error && finished && !loading) {
      if (data.length === 0) {
        handleAlert('custom', 'ไม่พบรายการนี้ในระบบ')
      }
      setBillList(data)
    }
    if (error && finished && !loading) {
      Modal.error({
        centered: true,
        title: 'ตรวจสอบ',
        content: `${trace.message}`,
      })
    }
  }, [listBillItemBySupplierAndProductNoVat])

  const onSearchProductHandler = (value) => {
    if (value === '') {
      setIsShowProductPopUp(true)
    }
  }

  const onSearchSupplierHandler = (value) => {
    if (value === '') {
      setIsShowSupplierPopUp(true)
    }
  }

  const onSearchHistory = () => {
    const { supplier_code, product_code, period_id, period } = form
    if (period_id) {
      dispatch(
        onListBillItemBySupplierAndProductNoVat(supplier_code, product_code, period)
      )
    } else {
      handleAlert('custom', 'กรุณาเลือกช่วงเวลาก่อนค้นหา')
    }
  }

  const onChangePeriod = (period_id) => {
    let id = ''
    let value = ''
    searchTimePeriod.forEach((x) => {
      if (x.id === period_id) {
        id = x.id
        value = x.value
      }
    })
    setForm({
      ...form,
      period_id: id,
      period: value,
    })
  }

  const onCancelProdutPopUp = () => {
    setIsShowProductPopUp(false)
  }

  const onSelectProduct = (data) => {
    setForm({
      ...form,
      product_code: data.code,
    })
    setIsShowProductPopUp(false)
  }

  const onSelectSupplier = (data) => {
    setForm({
      ...form,
      supplier_code: data.code,
    })
    setIsShowSupplierPopUp(false)
  }

  const onCancelSupplierPopUp = () => {
    setIsShowSupplierPopUp(false)
  }

  const onClearInput = () => {
    setForm(DEFAULT_FORM)
    setBillList([])
  }

  return (
    <div>
      <SearchSupplierPopUp
        visible={isShowSupplierPopUp}
        onCancel={onCancelSupplierPopUp}
        onClick={onSelectSupplier}
      />
      <SearchProductNoVatPopUp
        visible={isShowProductPopUp}
        onCancel={onCancelProdutPopUp}
        onClick={onSelectProduct}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'inline-block' }}>
          <h1>ค้นหาประวัติการซื้อสินค้า</h1>
        </div>
        <div style={{ display: 'inline-block' }}>
          <Popconfirm
            title="คุณต้องการล้างหน้าจอใช่หรือไม่ ?"
            okText="ใช่"
            cancelText="ยกเลิก"
            onConfirm={onClearInput}
          >
            <Button
              type="default"
              danger
              icon={<ClearOutlined />}
              size="large"
              block
            >
              ล้างหน้าจอ
            </Button>
          </Popconfirm>
        </div>
      </div>
      <div className="form-wrapper" style={{ paddingBottom: '10px' }}>
        <div className="input-wrapper">
          <div className="input-item">
            <InlineInput
              onSearch={(value) => {
                onSearchSupplierHandler(value)
              }}
              inputOption="search"
              label="รหัสผู้ขาย"
              placeholder="รหัสผู้ขาย"
              value={form.supplier_code}
              onChange={(event) => {
                setForm({
                  ...form,
                  supplier_code: event.target.value.toUpperCase(),
                })
              }}
            />
          </div>
          <div className="input-item">
            <InlineInput
              label="ช่วงเวลาในการค้นหา"
              placeholder="เลือกช่วงเวลา"
              required
              value={form.period_id}
              onChange={(period_id) => {
                onChangePeriod(period_id)
              }}
              width="200px"
              inputOption="select_input"
              options={searchTimePeriod}
            />
          </div>
        </div>
        <div className="input-wrapper">
          <div className="input-item">
            <InlineInput
              onSearch={(value) => {
                onSearchProductHandler(value)
              }}
              inputOption="search"
              label="รหัสสินค้า"
              placeholder="รหัสสินค้า"
              value={form.product_code}
              onChange={(event) => {
                setForm({
                  ...form,
                  product_code: event.target.value.toUpperCase(),
                })
              }}
            />
          </div>
          <div
            className="input-item"
            style={{ marginLeft: '20px', width: '180px' }}
          >
            <Button
              type="primary"
              icon={<SaveOutlined />}
              size="large"
              onClick={onSearchHistory}
              block
            >
              ค้นหา
            </Button>
          </div>
        </div>
      </div>
      <Spin spinning={isListLoading}>
        <div style={{ marginTop: '10px' }}>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={billList}
            size="small"
            scroll={{ x: 1500, y: 680 }}
            style={{ height: '685px' }}
            pagination={{
              simple: true,
              position: ['bottomRight'],
              defaultPageSize: 20,
              showSizeChanger: true,
            }}
          />
        </div>
      </Spin>
    </div>
  )
}

export default PurchaseHistoryNoVatPage
