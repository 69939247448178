/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Checkbox, Spin, Divider, Popconfirm, Modal } from 'antd'
import {
  SaveOutlined,
  ClearOutlined,
  PlusCircleOutlined,
  PrinterOutlined,
} from '@ant-design/icons'
import moment from 'moment'

import InlineInput from 'components/InlineInput'
import DiscountInput from 'components/InlineInput/DiscountInput'
import CustomerBillVatTable from 'components/CustomerBillVatTable'
import ProductMemberModal from 'components/ProductMemberModal'
import CashChangeAmountModal from 'components/CashChangeAmountModal'
import SearchProductVatPopUp from 'components/SearchProductVatPopUp'
import SearchCustomerVatPopUp from 'components/SearchCustomerVatPopUp'
import SearchCustomerBillVatPopUp from 'components/SearchCustomerBillVatPopUp'
import {
  useQuery,
  handleAlert,
  roundToTwo,
  roundToFour,
  PAYMENT_METHODS,
} from 'utils'

import {
  onListBillVatType,
  onListSaleChannel,
  onGetCustomerVatByCode,
  onGetProductVatWithLatestBoughtCustomer,
  onGetCustomerBillVat,
  onSaveCustomerBillVat,
} from 'redux/actions'

const DEFAULT_FORM = {
  created: '',
  bill_vat_no: '',
  bill_vat_date: moment().format('YYYY-MM-DD'),
  bill_vat_type_id: null,
  bill_vat_type_value: '',
  sale_channel_id: null,
  customer_data: {
    id: null,
    code: '',
    name: '',
    tax_id: '',
    branch_name: '',
    address: '',
    tel: '',
    fax: '',
    website: '',
    email: '',
    remark: '',
    customer_type: '',
  },
  is_full_tax_invoice: false,
  full_tax_invoice_type: '',
  product_is_included_vat: false,
  purchase_order_number: '',
  percent_vat: '7.00',
  sale_person: '',
  special_discount_baht: '',
  special_discount_percent: '',
  special_discount: '',
  special_discount_with_vat: '',
  total_charging_vat: '',
  value_added_tax: '',
  grand_total_with_vat: '',
  total_before_special_discount: '',
  total_after_special_discount: '',
  total_with_vat_before_special_discount: '',
  total_items: '',
  cash_input_amount: '',
  cash_change_amount: '',
  remark: '',
  payment_method_id: null,
  payment_method: '',
  products_vat: [],
}

const DEFAULT_PRODUCT = {
  id: 0,
  product_vat_id: null,
  product_vat_code: '',
  product_vat_name: '',
  product_normal_id: '',
  product_normal_code: '',
  product_normal_name: '',
  package_size: '',
  stock1: '',
  unit_id: '',
  unit_name: '',
  quantity: '',
  price_out_with_vat: '',
  price_out: '',
  discount_1: '0.0000',
  discount_2: '0.0000',
  discount_3: '0.0000',
  discount_4: '0.0000',
  total: '',
  total_with_vat: '',
  is_allow_low: false,
  last_purchased: '',
  remark: '',
  net: '0.0000',
}

const CustomerBillVatPage = (props) => {
  function useAsyncState(initialValue) {
    const [value, setValue] = useState(initialValue)
    const setter = (x) =>
      new Promise((resolve) => {
        setValue(x)
        resolve(x)
      })
    return [value, setter]
  }
  const query = useQuery()
  const bill_no = query.get('bill_no')
  const [form, setForm] = useAsyncState(DEFAULT_FORM)
  const [product, setProduct] = useAsyncState(DEFAULT_PRODUCT)
  const [billVatType, setBillVatType] = useState([])
  const [saleChannel, setSaleChannel] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isCarbonPrint, setisCarbonPrint] = useState(false)
  const [isFullPrint, setIsFullPrint] = useState(false)
  const [isShortPrint, setIsShortPrint] = useState(false)
  const [disableDiscountBaht, setDisableDiscountBaht] = useState(false)
  const [disableDiscountPercent, setDisableDiscountPercent] = useState(false)
  const [isShowBilltPopUp, setIsShowBillPopUp] = useState(false)
  const [isShowQuantityModal, setIsShowQuantityModal] = useState(false)
  const [isShowCashAmount, setIsShowCashAmount] = useState(false)
  const [isShowNormalQuantityModal, setIsShowNormalQuantityModal] =
    useState(false)
  const [memberList, setMemberList] = useState([])
  const [isShowMemberList, setIsShowMemberList] = useState(false)
  const [isShowProductPopUp, setIsShowProductPopUp] = useState(false)
  const [isShowCustomerPopUp, setIsShowCustomerPopUp] = useState(false)
  const [printPopUp, setPrintPopUp] = useState(false)
  const dispatch = useDispatch()
  const listBillVatType = useSelector((state) => state.listBillVatType)
  const listSaleChannel = useSelector((state) => state.listSaleChannel)
  let getCustomerBillVat = useSelector((state) => state.getCustomerBillVat)
  let getCustomerVatByCode = useSelector((state) => state.getCustomerVatByCode)
  let productVatWithLatestBoughtCustomer = useSelector(
    (state) => state.productVatWithLatestBoughtCustomer
  )
  let saveCustomerBillVat = useSelector((state) => state.saveCustomerBillVat)

  useEffect(() => {
    setIsLoading(true)
    dispatch(onListBillVatType())
    dispatch(onListSaleChannel())
    getCustomerBillVat = {}
    getCustomerVatByCode = {}
    productVatWithLatestBoughtCustomer = {}
    saveCustomerBillVat = {}
    initialIsIncludedVat(form)
    document.title = 'เปิด-แก้ไขบิล [VAT]'
  }, [])

  useEffect(() => {
    setIsLoading(true)
    if (bill_no) {
      dispatch(onGetCustomerBillVat(bill_no))
    }
  }, [bill_no, props.history])

  useEffect(() => {
    const { error, data, trace, loading, finished } = listBillVatType
    if (!loading && !error && finished) {
      setBillVatType(data)
      setIsLoading(false)
    }
    if (!loading && error) {
      handleAlert('custom', trace.message ? trace.message : '')
      setIsLoading(false)
    }
  }, [listBillVatType])

  useEffect(() => {
    const { error, data, trace, loading, finished } = listSaleChannel
    if (!loading && !error && finished) {
      setSaleChannel(data)
      setIsLoading(false)
    }
    if (!loading && error) {
      handleAlert('custom', trace.message ? trace.message : '')
      setIsLoading(false)
    }
  }, [listSaleChannel])

  useEffect(() => {
    const { error, loading, finished, data, trace } = getCustomerVatByCode
    if (!error && !loading && finished) {
      handleAlert('success')
      setForm({
        ...form,
        customer_data: data,
      })
      setIsLoading(false)
    }
    if (error && !loading) {
      setIsLoading(false)
      handleAlert('custom', trace.message ? trace.message : '')
      setForm({
        ...form,
        customer_data: {
          ...form.customer_data,
          id: null,
          name: '',
          tax_id: '',
          branch_name: '',
          address: '',
          tel: '',
          fax: '',
          website: '',
          email: '',
          remark: '',
          customer_type: '',
        },
      })
    }
  }, [getCustomerVatByCode])

  useEffect(() => {
    const { error, loading, finished, data, trace } = getCustomerBillVat
    setIsLoading(true)
    if (error) {
      Modal.warning({
        centered: true,
        title: 'ตรวจสอบ',
        content: `${trace.message}`,
      })
      setIsLoading(false)
    }
    if (!loading && !error && data && finished) {
      handleAlert('success')
      setForm(data).then((form) => {
        initialSpecialDiscount(form)
      })
      setProduct(DEFAULT_PRODUCT)
    }
  }, [getCustomerBillVat])

  useEffect(() => {
    const { error, loading, finished, trace, data } =
      productVatWithLatestBoughtCustomer
    if (!error && !loading && finished) {
      if (data.stock1 > 0) {
        initialOldPriceOut(data)
      } else {
        Modal.warning({
          centered: true,
          title: 'ตรวจสอบ',
          content: `ไม่มีสินค้า ${data.product_vat_code} : ${data.product_vat_name} เหลือในสต๊อกแวต`,
        })
      }
    }
    if (error && !loading) {
      Modal.error({
        centered: true,
        title: 'ตรวจสอบ',
        content: `${trace.message}`,
      })
    }
  }, [productVatWithLatestBoughtCustomer])

  useEffect(() => {
    const { error, loading, finished, data, trace } = saveCustomerBillVat
    setIsLoading(loading)
    if (!error && !loading && finished) {
      setForm(data).then((_form) => {
        initialPaymentMethod(_form)
      })
      setPrintPopUp(true)
    }
    if (error && !loading) {
      Modal.error({
        centered: true,
        title: 'ตรวจสอบ',
        content: `${trace.message}`,
      })
    }
  }, [saveCustomerBillVat])

  const initialIsIncludedVat = (_form) => {
    if (_form.is_full_tax_invoice) {
      if (_form.full_tax_invoice_type === 'carbon') {
        setisCarbonPrint(true)
        setIsFullPrint(false)
        setIsShortPrint(false)
      } else if (_form.full_tax_invoice_type === 'full') {
        setisCarbonPrint(false)
        setIsFullPrint(true)
        setIsShortPrint(false)
      } else {
        setisCarbonPrint(false)
        setIsFullPrint(false)
        setIsShortPrint(false)
      }
    } else {
      setisCarbonPrint(false)
      setIsFullPrint(false)
      setIsShortPrint(true)
    }
    setIsLoading(false)
  }

  const onChangeBillVatType = (bill_id) => {
    // initial bill_vat_type_value * required parameter
    let bill_vat_type_id = ''
    let bill_vat_type_value = ''
    billVatType.forEach((x) => {
      if (x.id === bill_id) {
        bill_vat_type_id = x.id
        bill_vat_type_value = x.value
      }
    })
    setForm({
      ...form,
      bill_vat_type_id,
      bill_vat_type_value,
    })
  }

  const onChangeMethodType = (method_id, _form) => {
    let payment_method_id = null
    let payment_method = ''
    PAYMENT_METHODS.forEach((x) => {
      if (x.id === method_id) {
        payment_method_id = x.id
        payment_method = x.value
      }
    })
    setForm({
      ..._form,
      payment_method_id,
      payment_method,
    })
  }

  const onQuantityChange = (new_quantity) => {
    setProduct({ ...product, quantity: new_quantity })
    if (
      Number(new_quantity) > Number(product.stock1) &&
      product.stock1 !== null
    ) {
      setIsShowQuantityModal(true)
    }
  }

  const handleQuantityOk = (type) => {
    if (type === 'vat') {
      setProduct({ ...product, quantity: product.stock1 })
    } else {
      setProduct({ ...product, quantity: product.product_normal_stock1 })
    }
    setIsShowQuantityModal(false)
    setIsShowNormalQuantityModal(false)
  }

  const handleQuantityCancel = () => {
    setProduct(DEFAULT_PRODUCT)
    setIsShowQuantityModal(false)
    setIsShowNormalQuantityModal(false)
  }

  const onCancelMemberListModal = () => {
    setIsShowMemberList(false)
  }

  const onSelectMember = (data) => {
    setProduct({
      ...product,
      product_normal_id: data.product_id,
      product_normal_code: data.product_code,
      product_normal_name: data.product_name,
      product_normal_stock1: data.stock1,
    }).then((product) => {
      setIsShowMemberList(false)
      if (product.product_normal_stock1 < product.quantity) {
        setIsShowNormalQuantityModal(true)
      } else {
        setMemberList([])
        addProductToList(product)
      }
    })
  }

  const onPrintChange = (event, type) => {
    switch (type) {
      case 'carbon': {
        setForm({
          ...form,
          is_full_tax_invoice: event.target.checked,
          full_tax_invoice_type: event.target.checked ? 'carbon' : '',
        })
        setisCarbonPrint(event.target.checked)
        setIsFullPrint(false)
        setIsShortPrint(false)
        break
      }
      case 'full': {
        setForm({
          ...form,
          is_full_tax_invoice: event.target.checked,
          full_tax_invoice_type: event.target.checked ? 'full' : '',
        })
        setisCarbonPrint(false)
        setIsFullPrint(event.target.checked)
        setIsShortPrint(false)
        break
      }
      case 'short': {
        setForm({
          ...form,
          is_full_tax_invoice: false,
          full_tax_invoice_type: '',
        })
        setisCarbonPrint(false)
        setIsFullPrint(false)
        setIsShortPrint(event.target.checked)
        break
      }
      default:
        break
    }
  }

  const initialOldPriceOut = (data) => {
    let old_discount = `${
      Number(data.old_discount_1) > 0 ? data.old_discount_1 : '00.0000'
    }+${Number(data.old_discount_2) > 0 ? data.old_discount_2 : '00.0000'}+${
      Number(data.old_discount_3) > 0 ? data.old_discount_3 : '00.0000'
    }+${Number(data.old_discount_4) > 0 ? data.old_discount_4 : '00.0000'} `
    setProduct({
      ...data,
      quantity: '',
      id: 0,
      remark: '',
      old_discount,
    }).then((_product) => {
      onCalculateNet(_product)
    })
    setMemberList(data.members)
  }

  const onEditTableData = (row, key, value) => {
    if (
      ((key === 'quantity' || key === 'price_out') && Number(value) >= 0) ||
      (key !== 'quantity' && key !== 'price_out')
    ) {
      let newProducts = []
      let productTotal = []
      form.products_vat.forEach((x) => {
        if (form.bill_vat_type_value === 'send_with_vat') {
          if (
            x.product_normal_code === row.product_normal_code &&
            x.product_vat_code === row.product_vat_code
          ) {
            x[key] = value
            if (key !== 'remark') {
              productTotal = sumProductTotal(x, true)
              x.total = productTotal[0]
              x.price_out_with_vat = productTotal[2]
              x.total_with_vat = productTotal[3]
            }
            return newProducts.push(x)
          } else {
            return newProducts.push(x)
          }
        } else {
          if (
            x.product_vat_id === row.product_vat_id &&
            x.product_vat_code === row.product_vat_code
          ) {
            x[key] = value
            if (key !== 'remark') {
              productTotal = sumProductTotal(x, true)
              x.total = productTotal[0]
              x.price_out_with_vat = productTotal[2]
              x.total_with_vat = productTotal[3]
            }
            return newProducts.push(x)
          } else {
            return newProducts.push(x)
          }
        }
      })
      setForm({
        ...form,
        products_vat: newProducts,
      }).then((_form) => {
        onChangeCalCulatedValue(_form)
      })
    }
    if ((key === 'quantity' || key === 'price_out') && Number(value) <= 0) {
      handleAlert('custom', 'จำนวนสินค้ากับราคา ควรมีค่ามากกว่า 0')
    }
  }

  const onDeleteProductFromBill = (row) => {
    let tempProduct = form.products_vat
    setIsLoading(true)
    tempProduct = tempProduct.filter((x) => {
      if (form.bill_vat_type_value === 'send_with_vat') {
        return (
          x.product_vat_code !== row.product_vat_code ||
          x.product_normal_code !== row.product_normal_code
        )
      } else {
        return x.product_vat_code !== row.product_vat_code
      }
    })
    setForm({
      ...form,
      total_items: tempProduct.length,
      products_vat: [...tempProduct],
    }).then((form) => {
      setIsLoading(false)
      onChangeCalCulatedValue(form)
    })
  }

  const onCalculateNet = (_product) => {
    let net = Number(_product.price_out)
    net *= Number(_product.discount_1)
      ? 1 - Number(_product.discount_1) / 100
      : 1
    net *= Number(_product.discount_2)
      ? 1 - Number(_product.discount_2) / 100
      : 1
    net *= Number(_product.discount_3)
      ? 1 - Number(_product.discount_3) / 100
      : 1
    net *= Number(_product.discount_4)
      ? 1 - Number(_product.discount_4) / 100
      : 1
    setProduct({
      ..._product,
      net: roundToFour(net).toFixed(4),
    })
  }

  const sumProductTotal = (selectProduct, isEdit = false) => {
    let total = 0
    let totalWithVat = 0
    let priceOut = 0
    let priceOutWithVat = 0
    if (!isEdit) {
      if (form.is_full_tax_invoice) {
        //// Full Bill
        if (form.product_is_included_vat) {
          priceOutWithVat = Number(selectProduct.price_out)
          priceOut = Number(selectProduct.price_out) / 1.07
        } else {
          priceOut = selectProduct.price_out
          priceOutWithVat = Number(selectProduct.price_out) * 1.07
        }
      } else {
        //// Short Bill
        priceOutWithVat = Number(selectProduct.price_out)
        priceOut = Number(selectProduct.price_out) / 1.07
      }
    } else {
      priceOut = selectProduct.price_out
      priceOutWithVat = Number(selectProduct.price_out) * 1.07
    }
    total = Number(selectProduct.quantity) * priceOut
    total *= Number(selectProduct.discount_1)
      ? 1 - Number(selectProduct.discount_1) / 100
      : 1
    total *= Number(selectProduct.discount_2)
      ? 1 - Number(selectProduct.discount_2) / 100
      : 1
    total *= Number(selectProduct.discount_3)
      ? 1 - Number(selectProduct.discount_3) / 100
      : 1
    total *= Number(selectProduct.discount_4)
      ? 1 - Number(selectProduct.discount_4) / 100
      : 1

    totalWithVat = Number(selectProduct.quantity) * priceOutWithVat
    totalWithVat *= Number(selectProduct.discount_1)
      ? 1 - Number(selectProduct.discount_1) / 100
      : 1
    totalWithVat *= Number(selectProduct.discount_2)
      ? 1 - Number(selectProduct.discount_2) / 100
      : 1
    totalWithVat *= Number(selectProduct.discount_3)
      ? 1 - Number(selectProduct.discount_3) / 100
      : 1
    totalWithVat *= Number(selectProduct.discount_4)
      ? 1 - Number(selectProduct.discount_4) / 100
      : 1

    total = roundToTwo(total).toFixed(2)
    priceOut = roundToFour(priceOut).toFixed(4)
    priceOutWithVat = roundToFour(priceOutWithVat).toFixed(4)
    totalWithVat = roundToTwo(totalWithVat).toFixed(2)
    return [total, priceOut, priceOutWithVat, totalWithVat]
  }

  const onChangeCalCulatedValue = (_form) => {
    let grandTotalWithVat = 0
    let sumBeforeDiscount = 0
    let sumAfterDiscount = 0
    let valueAddedTax = 0
    let sumAllProductWithVat = 0
    let discountValue = 0
    let discountValueWithVat = 0
    sumBeforeDiscount = _form.products_vat.reduce(
      (accumulator, currentValue) =>
        Number(accumulator) + Number(currentValue.total),
      0
    )
    sumAllProductWithVat = _form.products_vat.reduce(
      (accumulator, currentValue) =>
        Number(accumulator) + Number(currentValue.total_with_vat),
      0
    )
    if (form.is_full_tax_invoice === false) {
      // Short Bill Calcualation
      if (
        Number(_form.special_discount_baht) > 0 &&
        Number(_form.special_discount_percent) === 0
      ) {
        discountValue = Number(_form.special_discount_baht)
      } else if (
        Number(_form.special_discount_percent) > 0 &&
        Number(_form.special_discount_baht) === 0
      ) {
        discountValue =
          (sumAllProductWithVat * Number(_form.special_discount_percent)) / 100
      } else {
        discountValue = 0
      }

      sumAfterDiscount = roundToTwo(sumAllProductWithVat).toFixed(2)
      valueAddedTax = roundToTwo(
        sumAfterDiscount - sumAfterDiscount / 1.07
      ).toFixed(2)
      sumBeforeDiscount = roundToTwo(sumAfterDiscount - valueAddedTax).toFixed(
        2
      )
      discountValue = roundToTwo(discountValue).toFixed(2)
      grandTotalWithVat = roundToTwo(sumAfterDiscount - discountValue).toFixed(
        2
      )
      // discountValueWithVat =
      //   discountValue * (1 + Number(_form.percent_vat) / 100)
      // discountValueWithVat = roundToTwo(discountValueWithVat).toFixed(2)
      // sumAllProductWithVat = roundToTwo(sumAllProductWithVat).toFixed(2)

      setForm({
        ..._form,
        value_added_tax: valueAddedTax,
        total_before_special_discount: sumBeforeDiscount,
        total_after_special_discount: sumAfterDiscount,
        special_discount: discountValue,
        grand_total_with_vat: grandTotalWithVat,
        // special_discount_with_vat: discountValueWithVat,
        // total_with_vat_before_special_discount: sumAllProductWithVat,
      })
    } else {
      // Full Bill Calcualation
      if (
        Number(_form.special_discount_baht) > 0 &&
        Number(_form.special_discount_percent) === 0
      ) {
        discountValue = Number(_form.special_discount_baht)
        sumAfterDiscount = sumBeforeDiscount - discountValue
      } else if (
        Number(_form.special_discount_percent) > 0 &&
        Number(_form.special_discount_baht) === 0
      ) {
        discountValue =
          (sumBeforeDiscount * Number(_form.special_discount_percent)) / 100
        sumAfterDiscount = sumBeforeDiscount - discountValue
      } else {
        sumAfterDiscount = sumBeforeDiscount
      }

      discountValueWithVat =
        discountValue * (1 + Number(_form.percent_vat) / 100)
      valueAddedTax = (sumAfterDiscount * Number(_form.percent_vat)) / 100
      grandTotalWithVat = sumAfterDiscount + valueAddedTax
      sumBeforeDiscount = roundToTwo(sumBeforeDiscount).toFixed(2)
      sumAfterDiscount = roundToTwo(sumAfterDiscount).toFixed(2)
      valueAddedTax = roundToTwo(valueAddedTax).toFixed(2)
      grandTotalWithVat = roundToTwo(grandTotalWithVat).toFixed(2)
      discountValue = roundToTwo(discountValue).toFixed(2)
      discountValueWithVat = roundToTwo(discountValueWithVat).toFixed(2)
      sumAllProductWithVat = roundToTwo(sumAllProductWithVat).toFixed(2)

      setForm({
        ..._form,
        value_added_tax: valueAddedTax,
        total_before_special_discount: sumBeforeDiscount,
        total_after_special_discount: sumAfterDiscount,
        special_discount: discountValue,
        special_discount_with_vat: discountValueWithVat,
        total_with_vat_before_special_discount: sumAllProductWithVat,
        grand_total_with_vat: grandTotalWithVat,
      })
    }
  }

  const checkAddBothStock = () => {
    if (form.bill_vat_type_id !== null) {
      if (
        form.bill_vat_type_value === 'send_with_vat' &&
        memberList.length !== 0
      ) {
        if (Number(product.quantity) > 0 && Number(product.price_out) > 0) {
          setIsShowMemberList(true)
        } else {
          Modal.warning({
            centered: true,
            title: 'ตรวจสอบ',
            content: 'กรุณากรอกจำนวนสินค้า หรือราคาให้ครบถ้วนก่อนทำรายการ',
          })
        }
      } else if (
        form.bill_vat_type_value === 'send_with_vat' &&
        memberList.length === 0
      ) {
        Modal.warning({
          centered: true,
          title: 'ตรวจสอบ',
          content: 'ไม่พบรหัสสินค้าปกติ สำหรับสินค้าแวตรายการนี้',
        })
      } else {
        addProductToList(product)
      }
    } else {
      Modal.warning({
        centered: true,
        title: 'ตรวจสอบ',
        content: 'กรุณาระบุ "ประเภทบิล" ให้เรียบร้อยก่อนเพิ่มรายการ',
      })
    }
  }

  const addProductToList = (_product) => {
    let isDuplicatedProduct = false
    form.products_vat.forEach((x) => {
      if (form.bill_vat_type_value === 'send_with_vat') {
        if (x.product_vat_code === _product.product_vat_code) {
          if (x.product_normal_code === _product.product_normal_code) {
            return (isDuplicatedProduct = true)
          }
        }
      } else {
        if (x.product_vat_code === _product.product_vat_code) {
          return (isDuplicatedProduct = true)
        }
      }
    })
    if (!isDuplicatedProduct) {
      if (Number(_product.quantity) > 0 && Number(_product.price_out) > 0) {
        let sumTotal = []
        sumTotal = sumProductTotal(_product)
        setProduct({
          ..._product,
          total: sumTotal[0],
          price_out: sumTotal[1],
          price_out_with_vat: sumTotal[2],
          total_with_vat: sumTotal[3],
        }).then((product) => {
          const oldProduct = form.products_vat
          setForm({
            ...form,
            total_items: oldProduct.length + 1,
            products_vat: [...oldProduct, product],
          })
            .then((form) => {
              onChangeCalCulatedValue(form)
            })
            .then(() => setProduct(DEFAULT_PRODUCT))
        })
      } else {
        Modal.warning({
          centered: true,
          title: 'ตรวจสอบ',
          content: 'กรุณาระบุ "กรุณากรอกจำนวนสินค้า หรือราคาให้ครบถ้วน',
        })
      }
    } else {
      Modal.warning({
        centered: true,
        title: 'ตรวจสอบ',
        content:
          'กรุณาระบุ "มีสินค้านี้อยู่ในรายการอยู่แล้ว กรุณาเพิ่มสินค้าใหม่',
      })
      setProduct(DEFAULT_PRODUCT)
    }
  }

  const initialSpecialDiscount = (_form) => {
    if (Number(_form.special_discount_baht) > 0) {
      setDisableDiscountPercent(true)
    } else if (Number(_form.special_discount_percent) > 0) {
      setDisableDiscountBaht(true)
    } else {
      setDisableDiscountPercent(false)
      setDisableDiscountBaht(false)
    }
    initialPaymentMethod(_form)
  }

  const initialPaymentMethod = (_form) => {
    if (_form.payment_method === '') {
      setForm({
        ..._form,
        payment_method_id: null,
      }).then((newForm) => {
        initialIsIncludedVat(newForm)
      })
    } else {
      PAYMENT_METHODS.forEach((x) => {
        if (_form.payment_method === x.value) {
          setForm({
            ..._form,
            payment_method_id: x.id,
          }).then((newForm) => {
            initialIsIncludedVat(newForm)
          })
        }
      })
    }
  }

  const onSpecialDiscountChange = (value, name) => {
    if (name === 'baht' && Number(value) > 0) {
      setDisableDiscountPercent(true)
      setForm({
        ...form,
        special_discount_percent: '',
      })
    } else if (name === 'percent' && Number(value) > 0) {
      setDisableDiscountBaht(true)
      setForm({
        ...form,
        special_discount_bath: '',
      })
    } else if (Number(value) === 0) {
      setDisableDiscountBaht(false)
      setDisableDiscountPercent(false)
      setForm({
        ...form,
        special_discount_bath: '',
        special_discount_percent: '',
      })
    }
  }

  const onCancleAmountModal = () => {
    setIsShowCashAmount(false)
  }

  const handleCashInputChange = (
    input = 0,
    isSetChange = false,
    cashChange = ''
  ) => {
    if (isSetChange) {
      setForm({
        ...form,
        cash_input_amount: input,
        cash_change_amount: roundToTwo(cashChange),
      })
    } else {
      setForm({
        ...form,
        cash_input_amount: input,
      })
    }
  }

  const onSubmitAmountModal = () => {
    dispatch(onSaveCustomerBillVat(form))
    setIsShowCashAmount(false)
  }

  const onSaveBillVat = () => {
    if (form.is_full_tax_invoice) {
      if (
        form.customer_data.id !== null &&
        form.bill_vat_type_id &&
        form.sale_channel_id &&
        form.bill_vat_date
      ) {
        dispatch(onSaveCustomerBillVat(form))
      } else {
        handleAlert('custom', 'กรุณากรอกข้อมูลที่มีเครื่องหมาย * ให้ครบถ้วน')
      }
    } else {
      if (isShortPrint) {
        if (
          form.customer_data.id !== null &&
          form.bill_vat_type_id &&
          form.sale_channel_id &&
          form.bill_vat_date
        ) {
          setIsShowCashAmount(true)
        } else {
          handleAlert('custom', 'กรุณากรอกข้อมูลที่มีเครื่องหมาย * ให้ครบถ้วน')
        }
      } else {
        Modal.warning({
          centered: true,
          title: 'ตรวจสอบ',
          content: 'กรุณาเลือกประเภทใบกำกับ ก่อนทำการบันทึก',
        })
      }
    }
  }

  const onSearchProduct = (value) => {
    if (value) {
      dispatch(
        onGetProductVatWithLatestBoughtCustomer(value, form.customer_data.code)
      )
    } else {
      setIsShowProductPopUp(true)
    }
  }

  const onCancelProdutPopUp = () => {
    setIsShowProductPopUp(false)
  }

  const onSelectProductPopUp = (data) => {
    onSearchProduct(data.code)
    setIsShowProductPopUp(false)
  }

  const onSearchCustomerCode = (code) => {
    if (code !== '') {
      dispatch(onGetCustomerVatByCode(code))
    } else {
      setIsShowCustomerPopUp(true)
    }
  }

  const onSelectCustomerPopUp = (data) => {
    dispatch(onGetCustomerVatByCode(data.code))
    setIsShowCustomerPopUp(false)
  }

  const onCancelCustomerPopUp = () => {
    setIsShowCustomerPopUp(false)
  }

  const onClearInput = () => {
    setForm(DEFAULT_FORM).then((form) => {
      initialIsIncludedVat(form)
    })
    setProduct(DEFAULT_PRODUCT)
    setDisableDiscountBaht(false)
    setDisableDiscountPercent(false)
    setIsFullPrint(false)
    setIsShortPrint(false)
  }

  const onSearchBill = (value) => {
    if (value) {
      dispatch(onGetCustomerBillVat(value))
    } else {
      setIsShowBillPopUp(true)
    }
  }

  const onCancelBillPopUp = () => {
    setIsShowBillPopUp(false)
  }

  const onSelectBillPopUp = (data) => {
    onSearchBill(data.bill_no)
    setIsShowBillPopUp(false)
  }

  const printBillVat = () => {
    if (form.full_tax_invoice_type === 'full') {
      window.open(
        `/vat/customer-bill/full-print?bill_no=${form.bill_vat_no}`,
        '_blank'
      )
    } else if (form.full_tax_invoice_type === 'carbon') {
      window.open(
        `/vat/customer-bill/carbon-print?bill_no=${form.bill_vat_no}`,
        '_blank'
      )
    } else {
      window.open(
        `/vat/customer-bill/short-print?bill_no=${form.bill_vat_no}`,
        '_blank'
      )
    }
    setPrintPopUp(false)
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'inline-block' }}>
          <h1>เปิด-แก้ไขบิล [VAT]</h1>
        </div>
        <div style={{ display: 'inline-block' }}>
          <Popconfirm
            title="คุณต้องการล้างหน้าจอใช่หรือไม่ ?"
            okText="ใช่"
            cancelText="ยกเลิก"
            onConfirm={onClearInput}
          >
            <Button
              type="default"
              danger
              icon={<ClearOutlined />}
              size="large"
              block
            >
              ล้างหน้าจอ
            </Button>
          </Popconfirm>
        </div>
      </div>
      <SearchCustomerVatPopUp
        visible={isShowCustomerPopUp}
        onCancel={onCancelCustomerPopUp}
        onClick={onSelectCustomerPopUp}
      />
      <SearchProductVatPopUp
        visible={isShowProductPopUp}
        onCancel={onCancelProdutPopUp}
        onClick={onSelectProductPopUp}
      />
      <SearchCustomerBillVatPopUp
        visible={isShowBilltPopUp}
        onCancel={onCancelBillPopUp}
        onClick={onSelectBillPopUp}
        type=""
        customer_name={form.customer_data.name}
      />
      <Modal
        title="สินค้าเหลือไม่พอ"
        visible={isShowQuantityModal || isShowNormalQuantityModal}
        onOk={
          isShowQuantityModal
            ? () => handleQuantityOk('vat')
            : () => handleQuantityOk('normal')
        }
        onCancel={handleQuantityCancel}
        footer={[
          <Button
            style={{ float: 'left' }}
            key="submit"
            type="primary"
            onClick={
              isShowQuantityModal
                ? () => handleQuantityOk('vat')
                : () => handleQuantityOk('normal')
            }
          >
            จัดสินค้าเท่าที่มี
          </Button>,
          <Button key="back" onClick={handleQuantityCancel}>
            ยกเลิกรายการนี้
          </Button>,
        ]}
      >
        {isShowQuantityModal ? (
          <div style={{ textAlign: 'center' }}>
            สินค้า {product.name} มีในสต๊อกแวตเพียง {product.stock1}{' '}
            {product.unit_name}
          </div>
        ) : (
          <div style={{ textAlign: 'center' }}>
            สินค้า {product.product_normal_name} มีในสต๊อกปกติเพียง{' '}
            {product.product_normal_stock1} {product.unit_name}
          </div>
        )}
      </Modal>
      <Modal
        width="500px"
        centered
        visible={printPopUp}
        title="บันทึกรายการเรียบร้อย"
        onCancel={() => {
          setPrintPopUp(false)
        }}
        onOk={printBillVat}
      >
        <div>
          {form.is_full_tax_invoice
            ? `ต้องการพิมพ์ใบกำกับภาษีแบบ${
                form.full_tax_invoice_type === 'full' ? 'เต็ม' : 'คาร์บอน'
              } ใช่หรือไม่ ?`
            : 'ต้องการพิมพ์ใบกำกับภาษีแบบย่อ ใช่หรือไม่?'}
        </div>
      </Modal>
      <ProductMemberModal
        visible={isShowMemberList}
        members={memberList}
        onCancel={onCancelMemberListModal}
        onClick={onSelectMember}
        title="เลือกรหัสสินค้าปกติ"
      />
      <CashChangeAmountModal
        visible={isShowCashAmount}
        created={form.created}
        grandTotal={form.grand_total_with_vat}
        billCashInput={form.cash_input_amount}
        billCashChange={form.cash_change_amount}
        onCancleAmountModal={onCancleAmountModal}
        onSubmitAmountModal={onSubmitAmountModal}
        handleCashInputChange={handleCashInputChange}
      />
      <Spin spinning={isLoading ? true : false}>
        <div className="form-wrapper-vat">
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                onSearch={(value) => {
                  onSearchCustomerCode(value)
                }}
                inputOption="search"
                label="รหัสลูกค้า VAT"
                placeholder="รหัสลูกค้า VAT"
                isLoading={false}
                width="180px"
                value={form.customer_data.code}
                onChange={(event) => {
                  setForm({
                    ...form,
                    customer_data: {
                      ...form.customer_data,
                      code: event.target.value.toUpperCase(),
                    },
                  })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ชื่อลูกค้า"
                width="250px"
                required={form.is_full_tax_invoice}
                value={form.customer_data.name}
                disabled={true}
                disableColon
              />
            </div>
            <div
              className="input-item"
              style={{ marginTop: '6px', height: '20px' }}
            >
              <Checkbox
                checked={isFullPrint}
                onChange={(event) => onPrintChange(event, 'full')}
                disabled={
                  form.created !== ''
                    ? form.is_full_tax_invoice
                      ? false
                      : true
                    : false
                }
              >
                เปิดใบกำกับแบบเต็ม
              </Checkbox>
              <Checkbox
                checked={isCarbonPrint}
                onChange={(event) => onPrintChange(event, 'carbon')}
                disabled={
                  form.created !== ''
                    ? form.is_full_tax_invoice
                      ? false
                      : true
                    : false
                }
              >
                เปิดใบกำกับแบบคาร์บอน
              </Checkbox>
              <Checkbox
                checked={isShortPrint}
                onChange={(event) => onPrintChange(event, 'short')}
                disabled={
                  form.created !== ''
                    ? form.is_full_tax_invoice
                      ? true
                      : false
                    : false
                }
              >
                เปิดใบกำกับแบบย่อ
              </Checkbox>
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="ประเภทบิล"
                placeholder="เลือกประเภทบิล"
                required
                value={form.bill_vat_type_id}
                onChange={(bill_id) => {
                  onChangeBillVatType(bill_id)
                }}
                width="150px"
                inputOption="select_input"
                options={billVatType}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ช่องทาง"
                placeholder="เลือกช่องทาง"
                required
                value={form.sale_channel_id}
                onChange={(channel_id) => {
                  setForm({ ...form, sale_channel_id: channel_id })
                }}
                width="110px"
                inputOption="select_input"
                options={saleChannel}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="รหัสผู้ขาย"
                width="70px"
                value={form.sale_person}
                onChange={(event) => {
                  setForm({ ...form, sale_person: event.target.value })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                onSearch={(value) => {
                  onSearchBill(value)
                }}
                inputOption="search"
                label="เลขที่บิล"
                placeholder="เลขที่บิล"
                isLoading={false}
                value={form.bill_vat_no}
                onChange={(event) => {
                  setForm({
                    ...form,
                    bill_vat_no: event.target.value.toUpperCase(),
                  })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="วันที่เปิดบิล"
                placeholder="กรุณาเลือกวันที่"
                required
                defaultValue={moment()}
                value={form.bill_vat_date && moment(form.bill_vat_date)}
                inputOption="date_picker"
                onChange={(_, dateString) => {
                  setForm({ ...form, bill_vat_date: dateString })
                }}
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="เลขที่ใบสั่งซื้อ"
                placeholder="เลขที่ใบสั่งซื้อ"
                width="150px"
                value={form.purchase_order_number}
                onChange={(event) => {
                  setForm({
                    ...form,
                    purchase_order_number: event.target.value,
                  })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="Vat"
                placeholder="Vat"
                width="150px"
                value={form.percent_vat}
                onChange={(event) => {
                  setForm({ ...form, percent_vat: event.target.value })
                }}
                unit="%"
              />
            </div>
            <div
              className="input-item"
              style={{ marginTop: '6px', height: '20px' }}
            >
              <Checkbox
                checked={form.product_is_included_vat}
                onChange={(event) => {
                  setForm({
                    ...form,
                    product_is_included_vat: event.target.checked,
                  })
                }}
                disabled={form.is_full_tax_invoice === false}
              >
                ราคารวม Vat
              </Checkbox>
            </div>
          </div>
          <Divider style={{ margin: '0px 0px 10px' }} />
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                onSearch={(value) => {
                  onSearchProduct(value)
                }}
                inputOption="search"
                label="รหัสสินค้า"
                placeholder="รหัสสินค้า"
                isLoading={false}
                value={product.product_vat_code}
                onChange={(event) => {
                  setProduct({
                    ...product,
                    product_vat_code: event.target.value.toUpperCase(),
                  })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ชื่อสินค้า"
                width="440px"
                value={product.product_vat_name}
                disabled={true}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ขนาดบรรจุ"
                width="120px"
                value={product.package_size}
                disabled={true}
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="จำนวน"
                width="100px"
                required
                value={product.quantity}
                onChange={(event) => {
                  onQuantityChange(event.target.value)
                }}
              />
            </div>
            <div className="input-item" style={{ marginTop: '5px' }}>
              {product.unit_name}
            </div>
            <div className="input-item">
              <InlineInput
                label="ราคาต่อหน่วย"
                required
                width="100px"
                value={product.price_out}
                onChange={(event) => {
                  setProduct({
                    ...product,
                    price_out: event.target.value,
                  }).then((_product) => {
                    onCalculateNet(_product)
                  })
                }}
                unit="บาท"
              />
            </div>
            <div
              className="input-item"
              style={{ marginTop: '5px', color: '#0000FF' }}
            >
              <strong>
                สุทธิ: {Number(product.net) > 0 ? product.net : '0.0000'}
              </strong>
            </div>
            <div className="input-item">
              <DiscountInput
                label="ส่วนลด"
                name1="discount_1"
                name2="discount_2"
                name3="discount_3"
                name4="discount_4"
                value1={product.discount_1}
                value2={product.discount_2}
                value3={product.discount_3}
                value4={product.discount_4}
                onChange={(value, name) =>
                  setProduct({ ...product, [name]: value }).then((_product) => {
                    onCalculateNet(_product)
                  })
                }
                width="150px"
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item" style={{ marginTop: '5px' }}>
              <strong>
                ราคาเก่า:{' '}
                {product.old_price_out
                  ? form.product_is_included_vat
                    ? roundToFour(
                        product.old_price_out * (1 + form.percent_vat / 100)
                      )
                    : product.old_price_out
                  : '-'}
              </strong>
            </div>
            <div className="input-item" style={{ marginTop: '5px' }}>
              ส่วนลด: {product.old_discount}
            </div>
            <div className="input-item">
              <InlineInput
                label="วันที่ซื้อล่าสุด"
                placeholder={
                  product.last_purchased === 'ไม่มีประวัติการซื้อ'
                    ? 'ไม่มีประวัติการซื้อ'
                    : ''
                }
                defaultValue={moment()}
                value={
                  product.last_purchased &&
                  product.last_purchased !== 'ไม่มีประวัติการซื้อ'
                    ? moment(product.last_purchased)
                    : ''
                }
                inputOption="date_picker"
                disabled
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="หมายเหตุ"
                width="300px"
                value={product.remark}
                onChange={(event) => {
                  setProduct({ ...product, remark: event.target.value })
                }}
              />
            </div>
            {/* <div className="input-item">
              <InlineInput
                label="ค่า Vat ที่เก็บ"
                placeholder="ค่า Vat ที่เก็บ"
                width="150px"
                required
                value={product.vat}
                onChange={(event) => {
                  setProduct({ ...product, vat: event.target.value })
                }}
                unit="%"
              />
            </div> */}
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              size="large"
              onClick={checkAddBothStock}
              style={{ marginLeft: '50px' }}
            >
              เพิ่มรายการ
            </Button>
          </div>
          <Divider style={{ margin: '3px 0px 10px' }} />
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="จำนวนในรายการ"
                width="50px"
                value={form.total_items}
                disabled
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="วันที่แก้ไขล่าสุด"
                placeholder="YYYY/MM/DD"
                defaultValue={moment()}
                value={form.updated_at && moment(form.updated_at)}
                disabled
                inputOption="date_picker"
              />
            </div>
          </div>
          <div style={{ background: '#f1f1f1', marginBottom: '20px' }}>
            <CustomerBillVatTable
              productList={form.products_vat}
              onChange={onEditTableData}
              onDelete={onDeleteProductFromBill}
            />
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '30%', display: 'inline-block' }}>
              <div className="input-wrapper">
                <div className="input-item">
                  <InlineInput
                    className="text-align-right"
                    label="ส่วนลดพิเศษ"
                    width="120px"
                    value={form.special_discount_baht}
                    unit="บาท"
                    disabled={disableDiscountBaht}
                    onChange={(event) => {
                      onSpecialDiscountChange(event.target.value, 'baht')
                      setForm({
                        ...form,
                        special_discount_baht: event.target.value,
                      }).then((form) => onChangeCalCulatedValue(form))
                    }}
                  />
                </div>
                <div className="input-item">
                  <InlineInput
                    className="text-align-right"
                    width="80px"
                    value={form.special_discount_percent}
                    unit="%"
                    disabled={disableDiscountPercent}
                    disableColon={true}
                    onChange={(event) => {
                      onSpecialDiscountChange(event.target.value, 'percent')
                      setForm({
                        ...form,
                        special_discount_percent: event.target.value,
                      }).then((form) => onChangeCalCulatedValue(form))
                    }}
                  />
                </div>
              </div>
              <div className="input-wrapper">
                <div className="input-item">
                  <InlineInput
                    label="หมายเหตุบิล"
                    width="300px"
                    value={form.remark}
                    onChange={(event) => {
                      setForm({
                        ...form,
                        remark: event.target.value,
                      })
                    }}
                  />
                </div>
              </div>
              <div className="input-wrapper">
                <div className="input-item">
                  <InlineInput
                    label="ช่องทางชำระเงิน"
                    placeholder="ไม่ระบุ"
                    disabled={form.bill_vat_type_value !== 'send_with_vat'}
                    value={form.payment_method_id}
                    onChange={(method_id) => {
                      onChangeMethodType(method_id, form)
                    }}
                    width="120px"
                    inputOption="select_input"
                    options={[
                      { id: null, name: '-', value: '' },
                      ...PAYMENT_METHODS,
                    ]}
                  />
                </div>
              </div>
            </div>
            <div style={{ width: '25%', display: 'inline-block' }}>
              <div className="input-wrapper">
                {/* <div className="input-item margin-left-auto">
                  <InlineInput
                    className="text-align-right"
                    label="ค่า Vat ที่เรียกเก็บ"
                    width="130px"
                    value={form.total_charging_vat}
                    disabled
                  />
                </div> */}
                <div className="input-item margin-left-auto">
                  <InlineInput
                    className="text-align-right"
                    label="ภาษีมูลค่าเพิ่ม"
                    width="150px"
                    disabled
                    value={form.value_added_tax}
                  />
                </div>
              </div>
            </div>
            <div style={{ width: '25%', display: 'inline-block' }}>
              <div className="input-wrapper">
                <div className="input-item margin-left-auto">
                  <InlineInput
                    className="text-align-right"
                    label={
                      form.is_full_tax_invoice
                        ? 'ยอดก่อนหักส่วนลดพิเศษ'
                        : 'ยอดก่อนรวมภาษี'
                    }
                    width="180px"
                    value={form.total_before_special_discount}
                    disabled
                  />
                </div>
                <div className="input-item margin-left-auto">
                  <InlineInput
                    className="text-align-right"
                    label={
                      form.is_full_tax_invoice
                        ? 'ยอดหลังหักส่วนลดพิเศษ'
                        : 'ยอดรวมภาษี'
                    }
                    width="180px"
                    disabled
                    value={form.total_after_special_discount}
                  />
                </div>
                <div className="input-item margin-left-auto">
                  <InlineInput
                    className="text-align-right"
                    label={form.is_full_tax_invoice ? 'ยอดรวมภาษ๊' : 'ยอดสุทธิ'}
                    width="180px"
                    disabled
                    value={form.grand_total_with_vat}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                width: '12%',
                marginLeft: 'auto',
                marginRight: '30px',
                display: 'inline-block',
              }}
            >
              <Button
                type="primary"
                icon={<SaveOutlined />}
                size="large"
                style={{ height: '80px' }}
                block
                onClick={onSaveBillVat}
              >
                บันทึก/แก้ไขบิล
              </Button>
            </div>
            <div
              style={{
                width: '8%',
                display: 'inline-block',
              }}
            >
              <div style={{ marginBottom: '10px' }}>
                <Button
                  type="default"
                  icon={<PrinterOutlined />}
                  size="large"
                  style={{ height: '80px' }}
                  block
                  onClick={() => {
                    if (form.bill_vat_no !== '') {
                      setPrintPopUp(true)
                    } else {
                      Modal.warning({
                        centered: true,
                        title: 'ตรวจสอบ',
                        content: 'กรุณาค้นหาบิลก่อนสั่งพิมพ์',
                      })
                    }
                  }}
                >
                  พิมพ์บิล
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  )
}

export default withRouter(CustomerBillVatPage)
