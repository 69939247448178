import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { unAuthorized } from '../../../redux/actions'
import { Layout, Menu } from 'antd'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { DownOutlined } from '@ant-design/icons'
import { renderRoutes } from 'react-router-config'
const { SubMenu } = Menu

const { Header, Content, Footer } = Layout
const LogoText = styled.p`
  line-height: 2;
  text-align: center;
  color: #333;
  font-weight: bold;
`

const CustomLayout = ({ route }) => {
  const dispatch = useDispatch()
  const authenticate = useSelector((reducers) => reducers.authenticate)
  const logOut = () => {
    return dispatch(unAuthorized())
  }
  const renderSubMenu = (title, menu_perm, key, target, link) => {
    if (!authenticate.is_superuser) {
      return (
        authenticate.permissions.includes(menu_perm) && (
          <Menu.Item key={key}>
            <Link target={target} to={link}>
              {title}
            </Link>
          </Menu.Item>
        )
      )
    } else {
      return (
        <Menu.Item key={key}>
          <Link target={target} to={link}>
            {title}
          </Link>
        </Menu.Item>
      )
    }
  }
  return (
    <>
      <Layout>
        <Header
          style={{
            position: 'fixed',
            zIndex: 1,
            width: '100%',
            height: '53px',
            lineHeight: '50px',
            background: '#F6C9CC',
          }}
        >
          <Link target="_blank" to="/home">
            <div className="site-logo">
              <LogoText>Bon Lightings</LogoText>
            </div>
          </Link>
          {authenticate.is_login && (
            <>
              <Menu
                // theme="dark"
                mode="horizontal"
                style={{ background: '#F6C9CC' }}
              >
                {authenticate.is_superuser |
                  (authenticate.permissions.filter((perm) =>
                    perm.startsWith('customer_bills_vat.BLAD_PINK')
                  ).length >
                    0) && (
                  <SubMenu title="บิล VAT">
                    {renderSubMenu(
                      'เปิด-แก้ไขบิล',
                      'customer_bills_vat.BLAD_PINK_access_bill',
                      'customer-bill-vat',
                      '_blank',
                      '/vat/customer-bill'
                    )}
                    {renderSubMenu(
                      'ตรวจสอบวิธีการชำระเงินรายวัน',
                      'customer_bills_vat.BLAD_PINK_view_dailybillpayment',
                      'list-bill-vat-payment-method',
                      '_blank',
                      '/vat/list-bill-vat-payment-method'
                    )}
                    <Menu.Divider dashed />
                    {renderSubMenu(
                      'ค้นหาประวัติการขายสินค้า',
                      'customer_bills_vat.BLAD_PINK_view_salehistory',
                      'sales-history',
                      '_blank',
                      '/vat/sales-history'
                    )}
                    {renderSubMenu(
                      'ค้นหาประวัติการซื้อสินค้า',
                      'customer_bills_vat.BLAD_PINK_view_buyhistory',
                      'purchase-history',
                      '_blank',
                      '/vat/purchase-history'
                    )}
                    <Menu.Divider dashed />
                    {renderSubMenu(
                      'ใบลดหนี้/เพิ่มหนี้ ลูกค้า',
                      'customer_bills_vat.BLAD_PINK_access_cndncustomer',
                      'credit-note-customer',
                      '_blank',
                      '/vat/credit-note/customer'
                    )}
                    {renderSubMenu(
                      'ใบลดหนี้/เพิ่มหนี้ ผู้ขาย',
                      'customer_bills_vat.BLAD_PINK_access_cndnsupplier',
                      'credit-note-supplier',
                      '_blank',
                      '/vat/credit-note/supplier'
                    )}
                    <Menu.Divider dashed />
                    {renderSubMenu(
                      'ใบส่งของ',
                      'customer_bills_vat.BLAD_PINK_access_deliveryorder',
                      'delivery-order',
                      '_blank',
                      '/vat/delivery-order'
                    )}
                    {renderSubMenu(
                      'ใบรับเงินมัดจำ',
                      'customer_bills_vat.BLAD_PINK_access_depositreceipt',
                      'deposit-receipt',
                      '_blank',
                      '/vat/deposit-receipt'
                    )}
                  </SubMenu>
                )}
                {authenticate.is_superuser |
                  (authenticate.permissions.filter((perm) =>
                    perm.startsWith('stocks_vat.BLAD_PINK')
                  ).length >
                    0) && (
                  <SubMenu title="สต๊อก VAT">
                    {renderSubMenu(
                      'รับสินค้าเข้าสต็อก',
                      'stocks_vat.BLAD_PINK_access_stock',
                      'stock-vat',
                      '_blank',
                      '/vat/stock'
                    )}
                  </SubMenu>
                )}
                {authenticate.is_superuser |
                  (authenticate.permissions.filter((perm) =>
                    perm.startsWith('products_vat.BLAD_PINK')
                  ).length >
                    0) && (
                  <SubMenu title="สินค้า VAT">
                    {renderSubMenu(
                      'ข้อมูลสินค้า',
                      'products_vat.BLAD_PINK_access_product',
                      'product-vat',
                      '_blank',
                      '/vat/product'
                    )}
                    {renderSubMenu(
                      'รายการสินค้าทั้งหมด',
                      'products_vat.BLAD_PINK_view_productlist',
                      'product-vat-list',
                      '_blank',
                      '/vat/product/list'
                    )}
                    {renderSubMenu(
                      'ลบสินค้า',
                      'products_vat.BLAD_PINK_access_deleteproduct',
                      'product-vat-delete',
                      '_blank',
                      '/vat/product/delete'
                    )}
                    <Menu.Divider dashed />
                    {renderSubMenu(
                      'จับคู่รหัสสินค้า VAT - No VAT',
                      'products_vat.BLAD_PINK_access_normalvatgroup',
                      'product-group-mapping',
                      '_blank',
                      '/vat/product-group-mapping'
                    )}
                  </SubMenu>
                )}
                {authenticate.is_superuser |
                  (authenticate.permissions.filter((perm) =>
                    perm.startsWith('customers_vat.BLAD_PINK')
                  ).length >
                    0) |
                  (authenticate.permissions.filter((perm) =>
                    perm.startsWith('suppliers_vat.BLAD_PINK')
                  ).length >
                    0) && (
                  <SubMenu title="ข้อมูลผู้ขาย/ลูกค้า VAT">
                    {renderSubMenu(
                      'ลูกค้า',
                      'customers_vat.BLAD_PINK_access_customer',
                      'customer-vat',
                      '_blank',
                      '/vat/customer'
                    )}
                    {renderSubMenu(
                      'ผู้ขาย',
                      'suppliers_vat.BLAD_PINK_access_supplier',
                      'supplier-vat',
                      '_blank',
                      '/vat/supplier'
                    )}
                  </SubMenu>
                )}
                {authenticate.is_superuser |
                  (authenticate.permissions.filter((perm) =>
                    perm.startsWith('supplier_vat_invoice.BLAD_PINK')
                  ).length >
                    0) |
                  (authenticate.permissions.filter((perm) =>
                    perm.startsWith('customer_vat_invoice.BLAD_PINK')
                  ).length >
                    0) && (
                  <SubMenu title="การจ่ายเงิน">
                    {renderSubMenu(
                      'รวมยอดบิลซื้อสินค้า [ผู้ขาย]',
                      'supplier_vat_invoice.BLAD_PINK_access_svi',
                      'supplier-vat-invoice',
                      '_blank',
                      '/vat/supplier-invoice'
                    )}
                    {renderSubMenu(
                      'บันทึกการจ่ายบัญชีผู้ขาย',
                      'supplier_vat_invoice.BLAD_PINK_access_svipayment',
                      'payment-supplier-vat-invoice',
                      '_blank',
                      '/vat/payment-supplier-invoice'
                    )}
                    {renderSubMenu(
                      'รายการบัญชีผู้ขายที่ยังไม่ได้ชำระ',
                      'supplier_vat_invoice.BLAD_PINK_view_sviunpaid',
                      'unpaid-payment-supplier-vat-invoice',
                      '_blank',
                      '/vat/unpaid-payment-supplier-invoice'
                    )}
                    <Menu.Divider dashed />
                    {renderSubMenu(
                      'รวมยอดบิลขายสินค้า [ลูกค้า]',
                      'customer_vat_invoice.BLAD_PINK_access_cvi',
                      'customer-vat-invoice',
                      '_blank',
                      '/vat/customer-invoice'
                    )}
                    {renderSubMenu(
                      'บันทึกการจ่ายบัญชีลูกค้า',
                      'customer_vat_invoice.BLAD_PINK_access_cvipayment',
                      'payment-customer-vat-invoice',
                      '_blank',
                      '/vat/payment-customer-invoice'
                    )}
                    {renderSubMenu(
                      'รายการบัญชีลูกค้าที่ยังไม่ได้ชำระ',
                      'customer_vat_invoice.BLAD_PINK_view_cviunpaid',
                      'unpaid-payment-customer-vat-invoice',
                      '_blank',
                      '/vat/unpaid-payment-customer-invoice'
                    )}
                  </SubMenu>
                )}
                <SubMenu
                  style={{ float: 'right' }}
                  key="11"
                  icon={<DownOutlined />}
                  title={authenticate.username || 'unknown user'}
                >
                  <Menu.ItemGroup title="User control">
                    <Menu.Item key="setting:1" onClick={logOut}>
                      Log out
                    </Menu.Item>
                  </Menu.ItemGroup>
                </SubMenu>
              </Menu>
            </>
          )}
        </Header>
        <Content className="site-layout">{renderRoutes(route.routes)}</Content>
        <Footer style={{ textAlign: 'center' }}>Bon Lightings ©2024</Footer>
      </Layout>
    </>
  )
}

export default CustomLayout
