/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Button, Spin, Input, Popconfirm, Divider } from 'antd'
import {
  SaveOutlined,
  ClearOutlined,
  ArrowDownOutlined,
  SyncOutlined,
} from '@ant-design/icons'

import {
  onGetProductByCode,
  onGetUnitList,
  onSaveProductTransition,
  onListProductTransitionHistory,
} from 'redux/actions'
import { useSelector, useDispatch } from 'react-redux'

import InlineInput from 'components/InlineInput'
import SearchProductNoVatPopUp from 'components/SearchProductNoVatPopUp'
import ProductTransitionHistoryTable from 'components/ProductTransitionHistoryTable'
import { handleAlert, roundToFour } from 'utils'
import './style.scss'

const Search = Input.Search

const DEFAULT_FORM = {
  original_product_id: null,
  original_product_code: '',
  original_product_name: '',
  original_product_price_in: '',
  original_product_unit_id: null,
  original_product_stock1: '',
  original_quantity: '',
  destination_product_id: null,
  destination_product_code: '',
  destination_product_name: '',
  destination_product_unit_id: null,
  destination_product_stock1: '',
  destination_quantity: '',
  destination_calculated_price: '',
}

const DURATION_LIST = [
  {
    id: 1,
    name: 'วันนี้',
    value: 'today',
  },
  {
    id: 2,
    name: '1 สัปดาห์',
    value: 'one_week',
  },
  {
    id: 3,
    name: '1 เดือน',
    value: 'one_month',
  },
  {
    id: 4,
    name: 'ย้อนหลัง 3 เดือน',
    value: 'three_month',
  },
  {
    id: 5,
    name: 'ท้งหมด',
    value: 'all',
  },
]

const DEFAULT_SEARCH_FORM = {
  duration_id: 1,
  duration: 'today',
}

const ProductTransitionPage = () => {
  const [form, setForm] = useState(DEFAULT_FORM)
  const [searchForm, setSearchForm] = useState(DEFAULT_SEARCH_FORM)
  const [isLoading, setIsLoading] = useState(false)
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState('')
  const [isShowProductPopUp, setIsShowProductPopUp] = useState(false)
  const [temporaryList, setTemporaryList] = useState([])
  const [filteredList, setFilteredList] = useState([])
  const [searchText, setSearchText] = useState('')
  const dispatch = useDispatch()
  const saveProductTransition = useSelector(
    (state) => state.saveProductTransition
  )
  const getProductByCode = useSelector((state) => state.getProductByCode)
  const units = useSelector((state) => state.getUnitList.data)
  const listProductTransitionHistory = useSelector(
    (state) => state.listProductTransitionHistory
  )

  useEffect(() => {
    dispatch(onGetUnitList())
    document.title = 'เฉลี่ยต้นทุนสินค้า ต้นทาง - ปลายทาง - NoVat'
  }, [])

  useEffect(() => {
    dispatch(onListProductTransitionHistory(searchForm.duration))
  }, [searchForm])

  useEffect(() => {
    if (temporaryList !== []) {
      handleSearch(searchText)
    }
  }, [temporaryList, searchText])

  useEffect(() => {
    const { loading, error, data, finished, trace } =
      listProductTransitionHistory
    setIsTableLoading(loading)
    if (!error && !loading && finished) {
      setTemporaryList(data)
    }
    if (!loading && error) {
      handleAlert('error', trace.message || '')
    }
  }, [listProductTransitionHistory])

  useEffect(() => {
    const { loading, error, finished, data, trace } = getProductByCode
    setIsLoading(loading)
    if (!loading && !error && finished) {
      if (selectedProduct === 'original') {
        setForm({
          ...form,
          original_product_id: data.id,
          original_product_code: data.code,
          original_product_name: data.name,
          original_product_price_in: data.price_in_1,
          original_product_unit_id: data.unit_id,
          original_product_stock1: data.stock1,
        })
      } else {
        setForm({
          ...form,
          destination_product_id: data.id,
          destination_product_code: data.code,
          destination_product_name: data.name,
          destination_product_unit_id: data.unit_id,
          destination_product_stock1: data.stock1,
        })
      }
    }
    if (!loading && error) {
      setForm(DEFAULT_FORM)
      handleAlert('error', trace.message || '')
    }
  }, [getProductByCode])

  useEffect(() => {
    const { loading, error, finished, data, trace } = saveProductTransition
    setIsLoading(loading)
    if (!loading && !error && finished) {
      handleAlert('success', data.message)
      setForm(DEFAULT_FORM)
      dispatch(onListProductTransitionHistory(searchForm.duration))
    }
    if (!loading && error) {
      handleAlert('error', trace.message || '')
    }
  }, [saveProductTransition])

  const renderUnitType = (value) => {
    let unitName = ''
    units.forEach((x, index) => {
      if (x.id === value) {
        unitName = x.name
      }
    })
    return (
      <div className="input-item" style={{ marginTop: '5px' }}>
        {unitName}
      </div>
    )
  }

  const onSave = () => {
    if (form.destination_calculated_price) {
      dispatch(onSaveProductTransition(form))
    } else {
      handleAlert('custom', 'กรุณากรอกรายละเอียดให้ครบถ้วน')
    }
  }

  const onSearchProduct = (value, target) => {
    setSelectedProduct(target)
    if (value) {
      dispatch(onGetProductByCode(value))
    } else {
      setIsShowProductPopUp(true)
    }
  }

  const onCancelProdutPopUp = () => {
    setIsShowProductPopUp(false)
  }

  const onSelectProductPopUp = (data) => {
    onSearchProduct(data.code)
    setIsShowProductPopUp(false)
  }

  const onChangeQuantityOriginalQuantity = (value) => {
    let quantity =
      Number(value) > Number(form.original_product_stock1)
        ? form.original_product_stock1
        : value
    setForm({
      ...form,
      original_quantity: quantity,
      destination_quantity: '',
      destination_calculated_price: '',
    })
  }
  const onChangeQuantity = (value) => {
    let calculatedPriceIn = 0
    if (Number(value) > 0) {
      calculatedPriceIn =
        (Number(form.original_product_price_in) *
          Number(form.original_quantity)) /
        Number(value)
    } else {
      handleAlert('custom', 'กรุณากรอกจำนวนสินค้าให้ครบถ้วน')
    }
    setForm({
      ...form,
      destination_quantity: value,
      destination_calculated_price: roundToFour(calculatedPriceIn).toFixed(4),
    })
  }

  const handleSearch = (searchText) => {
    const _temporaryList = [...temporaryList]
    const filteredData = _temporaryList.filter(({ original_product_code }) => {
      original_product_code = original_product_code.toString().toLowerCase()
      return original_product_code.startsWith(searchText.toLowerCase())
    })
    setFilteredList(filteredData)
  }

  const onFetchList = () => {
    dispatch(onListProductTransitionHistory(searchForm.duration))
  }

  const onClearInput = () => {
    setForm(DEFAULT_FORM)
  }

  const onClearSearch = () => {
    setFilteredList(temporaryList)
    setSearchText('')
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'inline-block' }}>
          <h1>เฉลี่ยต้นทุนสินค้า ต้นทาง - ปลายทาง</h1>
        </div>
        <div style={{ display: 'inline-block' }}>
          <Popconfirm
            title="คุณต้องการล้างหน้าจอใช่หรือไม่ ?"
            okText="ใช่"
            cancelText="ยกเลิก"
            onConfirm={onClearInput}
          >
            <Button
              type="default"
              danger
              icon={<ClearOutlined />}
              size="large"
              block
            >
              ล้างหน้าจอ
            </Button>
          </Popconfirm>
        </div>
      </div>
      <SearchProductNoVatPopUp
        visible={isShowProductPopUp}
        onCancel={onCancelProdutPopUp}
        onClick={onSelectProductPopUp}
      />
      <Spin spinning={isLoading}>
        <div className="form-wrapper">
          <div className="input-wrapper">
            <div className="input-item">
              <div className="transition-title-original">สินค้าต้นทาง</div>
            </div>
            <div className="input-item">
              <InlineInput
                onSearch={(value) => {
                  onSearchProduct(value, 'original')
                }}
                inputOption="search"
                // label="รหัสสินค้า"
                placeholder="รหัสสินค้า"
                width="180px"
                isLoading={false}
                value={form.original_product_code}
                onChange={(event) => {
                  setForm({
                    ...form,
                    original_product_code: event.target.value.toUpperCase(),
                  })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ชื่อสินค้า"
                width="500px"
                value={form.original_product_name}
                disabled
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="สต๊อก1"
                width="100px"
                value={form.original_product_stock1}
                disabled
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ราคาเข้า"
                width="150px"
                value={form.original_product_price_in}
                disabled
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="จำนวน"
                width="100px"
                value={form.original_quantity}
                onChange={(event) => {
                  onChangeQuantityOriginalQuantity(event.target.value)
                }}
              />
            </div>
            {renderUnitType(form.original_product_unit_id)}
          </div>
          <div className="input-wrapper">
            <ArrowDownOutlined
              style={{ fontSize: '34px', padding: '10px 0px 0px 350px', marginBottom: '20px' }}
            />
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <div className="transition-title-destination">สินค้าปลายทาง</div>
            </div>
            <div className="input-item">
              <InlineInput
                onSearch={(value) => {
                  onSearchProduct(value, 'destination')
                }}
                inputOption="search"
                // label="รหัสสินค้า"
                placeholder="รหัสสินค้า"
                isLoading={false}
                width="180px"
                value={form.destination_product_code}
                onChange={(event) => {
                  setForm({
                    ...form,
                    destination_product_code: event.target.value.toUpperCase(),
                  })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ชื่อสินค้า"
                width="500px"
                value={form.destination_product_name}
                disabled
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="ราคาเข้าใหม่"
                width="150px"
                value={form.destination_calculated_price}
                disabled
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="จำนวน"
                width="100px"
                value={form.destination_quantity}
                onChange={(event) => {
                  onChangeQuantity(event.target.value)
                }}
              />
            </div>
            {renderUnitType(form.destination_product_unit_id)}
          </div>
          <div className="input-wrapper">
            <div
              className="input-item"
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <Button
                type="primary"
                icon={<SaveOutlined />}
                size="large"
                style={{ width: '180px', height: '50px' }}
                onClick={onSave}
              >
                บันทึก
              </Button>
            </div>
          </div>
          <Divider/>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="ช่วงเวลาในการค้นหา"
                placeholder="เลือกช่วงเวลา"
                required
                value={searchForm.duration_id}
                onChange={(channel_id) => {
                  channel_id === 1
                    ? setSearchForm({
                        duration_id: channel_id,
                        duration: 'today',
                      })
                    : channel_id === 2
                    ? setSearchForm({
                        duration_id: channel_id,
                        duration: 'one_week',
                      })
                    : channel_id === 3
                    ? setSearchForm({
                        duration_id: channel_id,
                        duration: 'one_month',
                      })
                    : channel_id === 4
                    ? setSearchForm({
                        duration_id: channel_id,
                        duration: 'three_month',
                      })
                    : setSearchForm({
                        duration_id: channel_id,
                        duration: 'all',
                      })
                }}
                width="150px"
                inputOption="select_input"
                options={DURATION_LIST}
              />
            </div>
            <div className="input-item">
              <Button
                type="primary"
                icon={<SyncOutlined />}
                onClick={onFetchList}
              >
                รีเฟรชลิสต์
              </Button>
            </div>
            <div className="input-item">
              <Search
                value={searchText}
                onChange={(event) => {
                  setSearchText(event.target.value.toUpperCase())
                }}
                placeholder="กรองรหัสต้นทาง"
                onSearch={(value) => {
                  handleSearch(value)
                }}
              />
            </div>
            <div className="input-item">
              <Button
                type="primary"
                icon={<ClearOutlined />}
                onClick={onClearSearch}
              >
                เคลียร์
              </Button>
            </div>
          </div>
          <Spin spinning={isTableLoading}>
            <ProductTransitionHistoryTable historyList={filteredList} />
          </Spin>
        </div>
      </Spin>
    </div>
  )
}

export default ProductTransitionPage
