import React, { useCallback, useState, createContext } from 'react'
import useRequestListCashes from '../hooks/useRequestListCashes'
import moment from 'moment'
import { Form } from 'antd'

moment.locale('th')

// NOTE: Context
const OpenStoreDataContext = createContext({})

// NOTE: Provider
const OpenStoreDataProvider = ({ children }) => {
  const [form] = Form.useForm()
  const [id, setId] = useState(0)
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
  const onResetForm = useCallback(() => {
    form.resetFields()
    setId(0)
  }, [form])

  const {
    listCashesData,
    listCashesLoading,
    onDeleteCash,
    deleteLoading,
    onAddCash,
    addCashesLoading,
  } = useRequestListCashes({ date, onResetForm })

  const onSubmitCashForm = (value) => {
    onAddCash({
      id,
      date,
      banknote: value,
      record_type: 'open',
    })
  }

  const onClickRow = (record) => {
    setId(record.id)
    form.setFieldsValue(record.banknote)
  }

  const onCashChange = (_, allValue) => {
    let totalCash = 0
    totalCash += allValue?.thousand * 1000
    totalCash += allValue?.fivehundred * 500
    totalCash += allValue?.onehundred * 100
    totalCash += allValue?.fifty * 50
    totalCash += allValue?.twenty * 20
    totalCash += allValue?.ten * 10
    totalCash += allValue?.five * 5
    totalCash += allValue?.two * 2
    totalCash += allValue?.one * 1
    form.setFieldsValue({ total: totalCash })
  }

  const onDateChange = (value) => {
    setDate(value)
  }

  return (
    <OpenStoreDataContext.Provider
      value={{
        initial: 'test-initial',
        id,
        form,
        date,
        onDateChange,
        listCashesData,
        listCashesLoading,
        onCashChange,
        onDeleteCash,
        deleteLoading,
        onSubmitCashForm,
        addCashesLoading,
        onResetForm,
        onClickRow,
      }}
    >
      {children}
    </OpenStoreDataContext.Provider>
  )
}

export { OpenStoreDataContext, OpenStoreDataProvider }
