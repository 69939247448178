/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Button, Checkbox, Spin, Divider, Popconfirm, Modal } from 'antd'
import {
  SaveOutlined,
  ClearOutlined,
  PlusCircleOutlined,
  PrinterOutlined,
} from '@ant-design/icons'
import moment from 'moment'

import InlineInput from 'components/InlineInput'
import DiscountInput from 'components/InlineInput/DiscountInput'
import QuotationProductTable from 'components/QuotationProductTable'
import SearchProductNoVatPopUp from 'components/SearchProductNoVatPopUp'
import SearchCustomerPopUp from 'components/SearchCustomerPopUp'
import SearchQuotationPopUp from 'components/SearchQuotationPopUp'

import { useDispatch, useSelector } from 'react-redux'
import {
  onListQOBillType,
  onListQODurationUnit,
  onListQODocType,
  onGetLatestBoughtProductOfCustomer,
  onGetCustomerByCode,
  onGetCustomerType,
  onGetQuotation,
  onSaveQuotation,
} from 'redux/actions'
import { handleAlert, useQuery } from 'utils'

const DEFAULT_FORM = {
  id: 0,
  customer_id: '',
  customer_code: '',
  customer_name: '',
  customer_type_id: null,
  bill_type_id: null,
  doc_no: '',
  doc_date: moment().format('YYYY-MM-DD'),
  doc_type_id: null,
  doc_type_value: '',
  tax_id: '',
  to_message: '',
  from_message: '',
  is_included_vat: false,
  total_items: '',
  special_discount_baht: '0',
  special_discount_percent: '0',
  total_before_special_discount: '',
  total_after_special_discount: '',
  grand_total: '',
  payment_condition_duration: '',
  payment_condition_duration_unit_id: 1,
  delivery_due_date: '',
  accepted_price_due_date: '',
  accepted_price_due_date_unit_id: 1,
  remark: '',
  items: [],
}

const DEFAULT_PRODUCT = {
  id: 0,
  code: '',
  name: '',
  stock1: null,
  unit_id: null,
  unit_name: '',
  quantity: null,
  lastest_quantity: null,
  old_price_out_atm: null,
  old_price_out_cash: null,
  price_out_atm: null,
  price_out_cash: null,
  price_out: null,
  old_price_out: null,
  discount_1: '',
  discount_2: '',
  discount_3: '',
  discount_4: '',
  total: '',
  ramark: '',
  last_purchased: '',
  net: '0.00',
}

const QuotationPage = (props) => {
  function useAsyncState(initialValue) {
    const [value, setValue] = useState(initialValue)
    const setter = (x) =>
      new Promise((resolve) => {
        setValue(x)
        resolve(x)
      })
    return [value, setter]
  }
  const query = useQuery()
  const doc_no = query.get('doc_no')
  const [form, setForm] = useAsyncState(DEFAULT_FORM)
  const [product, setProduct] = useAsyncState(DEFAULT_PRODUCT)
  const [isLoading, setIsLoading] = useState(false)
  const [customerType, setCustomerType] = useState([])
  const [disableDiscountBaht, setDisableDiscountBaht] = useState(false)
  const [disableDiscountPercent, setDisableDiscountPercent] = useState(false)
  const [isShowQuotationPopUp, setIsShowQuotationPopUp] = useState(false)
  const [isShowProductPopUp, setIsShowProductPopUp] = useState(false)
  const [isShowCustomerPopUp, setIsShowCustomerPopUp] = useState(false)
  const [printPopUp, setPrintPopUp] = useState(false)
  const [isAutoCreateDO, setIsAutoCreateDO] = useState(false)
  const dispatch = useDispatch()
  const listQODocType = useSelector((state) => state.listQODocType)
  const listQOBillType = useSelector((state) => state.listQOBillType)
  const listQODurationUnit = useSelector((state) => state.listQODurationUnit)
  const getCustomerType = useSelector((state) => state.getCustomerType)
  const getCustomerByCode = useSelector((state) => state.getCustomerByCode)
  const getQuotation = useSelector((state) => state.getQuotation)

  const getLatestBoughtProductOfCustomer = useSelector(
    (state) => state.getLatestBoughtProductOfCustomer
  )
  const saveQuotation = useSelector((state) => state.saveQuotation)

  useEffect(() => {
    dispatch(onListQODocType())
    dispatch(onListQOBillType())
    dispatch(onListQODurationUnit())
    dispatch(onGetCustomerType())
    document.title = 'เปิด-แก้ไขใบเสนอราคา - NoVat'
  }, [])

  useEffect(() => {
    setIsLoading(true)
    if (doc_no) {
      dispatch(onGetQuotation(doc_no))
    }
  }, [doc_no, props.history])

  useEffect(() => {
    const { error, trace, loading, finished } = listQOBillType
    setIsLoading(loading)
    if (!loading && !error && finished) {
      // setBillType(data)
    }
    if (!loading && error) {
      handleAlert('error', trace.message ? trace.message : '')
    }
  }, [listQOBillType])

  useEffect(() => {
    const { loading, error, trace, finished } = listQODocType
    setIsLoading(loading)
    if (!loading && !error && finished) {
      // setCustomerType(data)
    }
    if (error && !loading) {
      handleAlert('error', trace.message ? trace.message : '')
    }
  }, [listQODocType])

  useEffect(() => {
    const { error, loading, finished, trace } = listQODurationUnit
    setIsLoading(loading)
    if (!loading && !error && finished) {
      // setSaleChannel(data)
    }
    if (!loading && error) {
      handleAlert('custom', trace.message ? trace.message : '')
    }
  }, [listQODurationUnit])

  useEffect(() => {
    const { loading, error, data, trace, finished } = getCustomerType
    setIsLoading(loading)
    if (!loading && !error && finished) {
      setCustomerType(data)
    }
    if (error && !loading) {
      handleAlert('error', trace.message ? trace.message : '')
    }
  }, [getCustomerType])

  useEffect(() => {
    const { error, loading, finished, data, trace } = getCustomerByCode
    setIsLoading(loading)
    if (!error && !loading && finished) {
      handleAlert('success')
      initialCustomerType(data)
    }
    if (error && !loading) {
      handleAlert('custom', trace.message ? trace.message : '')
      setForm({
        ...form,
        customer_id: '',
        customer_name: '',
        customer_type_id: null,
      })
    }
  }, [getCustomerByCode])

  const initialCustomerType = (data) => {
    let typeName = ''
    customerType.forEach((x, index) => {
      if (x.id === data.customer_type_id) {
        typeName = x.name
      }
    })
    setForm({
      ...form,
      customer_id: data.id,
      customer_code: data.code,
      customer_name: data.name,
      customer_type_id: data.customer_type_id,
      customer_type: typeName,
    })
  }
  const onVatChecked = (event) => {
    if (event.target.checked) {
      setForm({
        ...form,
        is_included_vat: true,
      }).then((_form) => {
        onChangeCalCulatedValue(_form)
      })
    } else {
      setForm({
        ...form,
        is_included_vat: false,
      }).then((_form) => {
        onChangeCalCulatedValue(_form)
      })
    }
  }

  const onChangeBillType = (type_id, _form) => {
    let bill_type_id = ''
    let bill_type_value = ''
    listQOBillType.data.forEach((x) => {
      if (x.id === type_id) {
        bill_type_id = x.id
        bill_type_value = x.value
      }
    })
    setForm({
      ..._form,
      bill_type_id,
      bill_type_value,
    })
  }

  useEffect(() => {
    const { error, loading, trace, data, finished } = getQuotation
    setIsLoading(loading)
    if (!error && !loading && finished) {
      setForm(data).then((form) => {
        initialSpecialDiscount(form)
      })
      setProduct(DEFAULT_PRODUCT)
    }
    if (!loading && error) {
      handleAlert('error', trace.message ? trace.message : '')
    }
  }, [getQuotation])

  useEffect(() => {
    const { error, loading, finished, trace, data } =
      getLatestBoughtProductOfCustomer
    if (!error && !loading && finished) {
      onSetPriceOut(form.bill_type_value.toLowerCase(), data)
    }
    if (error && !loading) {
      handleAlert('custom', `ไม่สามารถทำรายการได้ - ${trace.message}`)
    }
  }, [getLatestBoughtProductOfCustomer])

  const onSetPriceOut = (bill_type, data) => {
    let old_discount = `${
      Number(data.old_discount_1) > 0 ? data.old_discount_1 : '00.00'
    }+${Number(data.old_discount_2) > 0 ? data.old_discount_2 : '00.00'}+${
      Number(data.old_discount_3) > 0 ? data.old_discount_3 : '00.00'
    }+${Number(data.old_discount_4) > 0 ? data.old_discount_4 : '00.00'} `
    if (bill_type === 'atm') {
      setProduct({
        ...data,
        price_out: data.price_out_atm,
        old_price_out: data.old_price_out_atm,
        lastest_quantity: data.quantity,
        old_discount: old_discount,
        quantity: '',
        remark: '',
        id: 0,
        discount_1: data.discount_atm_1,
        discount_2: data.discount_atm_2,
        discount_3: data.discount_atm_3,
        discount_4: data.discount_atm_4,
      }).then((_product) => {
        onCalculateNet(_product)
      })
    } else if (bill_type === 'cash') {
      setProduct({
        ...data,
        price_out: data.price_out_cash,
        old_price_out: data.old_price_out_cash,
        lastest_quantity: data.quantity,
        old_discount: old_discount,
        quantity: '',
        remark: '',
        id: 0,
        discount_1: data.discount_cash_1,
        discount_2: data.discount_cash_2,
        discount_3: data.discount_cash_3,
        discount_4: data.discount_cash_4,
      }).then((_product) => {
        onCalculateNet(_product)
      })
    } else {
      setProduct({
        ...data,
        price_out: '',
        old_price_out: '',
        lastest_quantity: data.quantity,
        old_discount: old_discount,
        quantity: '',
        remark: '',
        id: 0,
        discount_1: '',
        discount_2: '',
        discount_3: '',
        discount_4: '',
      }).then((_product) => {
        onCalculateNet(_product)
      })
    }
  }

  const roundToTwo = (num) => +(Math.round(num + 'e+2') + 'e-2')

  const onCalculateNet = (_product) => {
    let net = Number(_product.price_out)
    net *= Number(_product.discount_1)
      ? 1 - Number(_product.discount_1) / 100
      : 1
    net *= Number(_product.discount_2)
      ? 1 - Number(_product.discount_2) / 100
      : 1
    net *= Number(_product.discount_3)
      ? 1 - Number(_product.discount_3) / 100
      : 1
    net *= Number(_product.discount_4)
      ? 1 - Number(_product.discount_4) / 100
      : 1
    setProduct({
      ..._product,
      net: roundToTwo(net).toFixed(2),
    })
  }

  const sumProductTotal = (_product) => {
    let total = 0
    total = Number(_product.quantity) * Number(_product.price_out)
    total *= Number(_product.discount_1)
      ? 1 - Number(_product.discount_1) / 100
      : 1
    total *= Number(_product.discount_2)
      ? 1 - Number(_product.discount_2) / 100
      : 1
    total *= Number(_product.discount_3)
      ? 1 - Number(_product.discount_3) / 100
      : 1
    total *= Number(_product.discount_4)
      ? 1 - Number(_product.discount_4) / 100
      : 1
    return roundToTwo(total).toFixed(2)
  }

  const addProductToList = (_product) => {
    let sumTotal = 0
    sumTotal = sumProductTotal(_product)
    setProduct({
      ..._product,
      total: sumTotal,
    }).then((_product) => {
      const oldProduct = form.items
      setForm({
        ...form,
        total_items: oldProduct.length + 1,
        items: [...oldProduct, _product],
      })
        .then((form) => {
          onChangeCalCulatedValue(form)
        })
        .then(() => setProduct(DEFAULT_PRODUCT))
    })
  }

  const onChangeCalCulatedValue = (_form) => {
    let grandTotal = 0
    let sumBeforeDiscount = 0
    let sumAfterDiscount = 0
    let valueAddedTax = 0
    let sumOfAllProduct = _form.items.reduce(
      (accumulator, currentValue) =>
        Number(accumulator) + Number(currentValue.total),
      0
    )
    sumBeforeDiscount = sumOfAllProduct

    if (
      Number(_form.special_discount_baht) > 0 &&
      Number(_form.special_discount_percent) === 0
    ) {
      sumAfterDiscount = sumBeforeDiscount - Number(_form.special_discount_baht)
    } else if (
      Number(_form.special_discount_percent) > 0 &&
      Number(_form.special_discount_baht) === 0
    ) {
      sumAfterDiscount =
        sumBeforeDiscount * (1 - Number(_form.special_discount_percent) / 100)
    } else {
      sumAfterDiscount = sumBeforeDiscount
    }

    if (_form.is_included_vat) {
      valueAddedTax = sumAfterDiscount * 0.07
    }

    grandTotal = sumAfterDiscount + valueAddedTax
    setForm({
      ..._form,
      value_added_tax: roundToTwo(valueAddedTax).toFixed(2),
      total_before_special_discount: roundToTwo(sumBeforeDiscount).toFixed(2),
      total_after_special_discount: roundToTwo(sumAfterDiscount).toFixed(2),
      grand_total: roundToTwo(grandTotal).toFixed(2),
    })
    setIsLoading(false)
  }

  const onEditTableData = (row, key, value, index) => {
    let newProducts = []
    form.items.forEach((x, item_index) => {
      if (item_index === index && x.id === row.id && x.name === row.name) {
        x[key] = value
        if (
          key !== 'remark' &&
          key !== 'name' &&
          key !== 'code' &&
          key !== 'unit_name'
        ) {
          x.total = sumProductTotal(x)
        }
        return newProducts.push(x)
      } else {
        return newProducts.push(x)
      }
    })
    setForm({
      ...form,
      items: newProducts,
    }).then((form) => {
      onChangeCalCulatedValue(form)
    })
  }

  const onDeleteProductFromTable = (row, index) => {
    const { items } = form
    let tempProduct = [...items]
    tempProduct.splice(index, 1)
    setForm({
      ...form,
      total_items: tempProduct.length,
      items: [...tempProduct],
    }).then((_form) => {
      onChangeCalCulatedValue(_form)
    })
  }

  const initialSpecialDiscount = (_form) => {
    if (Number(_form.special_discount_baht) > 0) {
      setDisableDiscountPercent(true)
    } else if (Number(_form.special_discount_percent) > 0) {
      setDisableDiscountBaht(true)
    } else {
      setDisableDiscountPercent(false)
      setDisableDiscountBaht(false)
    }
    onChangeBillType(_form.bill_type_id, _form)
  }

  const onSpecialDiscountChange = (value, name) => {
    if (name === 'baht' && Number(value) > 0) {
      setDisableDiscountPercent(true)
      setForm({
        ...form,
        special_discount_percent: '',
      })
    } else if (name === 'percent' && Number(value) > 0) {
      setDisableDiscountBaht(true)
      setForm({
        ...form,
        special_discount_bath: '',
      })
    } else if (Number(value) === 0) {
      setDisableDiscountBaht(false)
      setDisableDiscountPercent(false)
      setForm({
        ...form,
        special_discount_bath: '',
        special_discount_percent: '',
      })
    }
  }

  useEffect(() => {
    const { error, loading, finished, data, trace } = saveQuotation
    setIsLoading(loading)
    if (!error && !loading && finished) {
      if (isAutoCreateDO === true) {
        setIsAutoCreateDO(false)
        setIsLoading(false)
        window.open(`/no-vat/quotation?doc_no=${data.doc_no}`, '_blank')
      } else {
        setForm(data).then((form) => {
          initialSpecialDiscount(form)
        })
        setProduct(DEFAULT_PRODUCT)
        setPrintPopUp(true)
      }
    }
    if (error && !loading) {
      handleAlert('error', trace.message ? trace.message : '')
    }
  }, [saveQuotation])

  const onSaveDoc = () => {
    if (form.customer_code && form.doc_type_id && form.doc_date) {
      dispatch(onSaveQuotation(form))
    } else {
      handleAlert('custom', 'กรุณากรอกข้อมูลที่มีเครื่องหมาย * ให้ครบถ้วน')
    }
  }

  const onSearchProduct = (value) => {
    if (form.customer_code && form.bill_type_value) {
      if (value) {
        dispatch(
          onGetLatestBoughtProductOfCustomer(
            value,
            form.customer_code,
            form.bill_type_value
          )
        )
      } else {
        setIsShowProductPopUp(true)
      }
    } else {
      handleAlert('custom', 'กรุณากรอกรหัสลูกค้า และประเภทบิล ก่อนค้นหา')
    }
  }

  const onCreateNewDOFromThisQO = () => {
    setIsAutoCreateDO(true)
    let newDOForm = {
      ...form,
      id: 0,
      doc_type_id: 4,
      doc_type_value: 'do',
      doc_no: '',
      doc_date: moment().format('YYYY-MM-DD'),
    }
    if (
      newDOForm.customer_code &&
      newDOForm.doc_type_id &&
      newDOForm.doc_date
    ) {
      dispatch(onSaveQuotation(newDOForm))
    }
  }

  const onCancelProdutPopUp = () => {
    setIsShowProductPopUp(false)
  }

  const onSelectProductPopUp = (data) => {
    onSearchProduct(data.code)
    setIsShowProductPopUp(false)
  }

  const onSearchCustomerCode = (code) => {
    if (code !== '') {
      dispatch(onGetCustomerByCode(code))
    } else {
      setIsShowCustomerPopUp(true)
    }
  }

  const onSelectCustomerPopUp = (data) => {
    dispatch(onGetCustomerByCode(data.code))
    setIsShowCustomerPopUp(false)
  }

  const onCancelCustomerPopUp = () => {
    setIsShowCustomerPopUp(false)
  }

  const onSearchQuotation = (value) => {
    if (value) {
      dispatch(onGetQuotation(value))
    } else {
      setIsShowQuotationPopUp(true)
    }
  }

  const onCancelQuotationPopUp = () => {
    setIsShowQuotationPopUp(false)
  }

  const onSelectQuotationPopUp = (data) => {
    onSearchQuotation(data.doc_no)
    setIsShowQuotationPopUp(false)
  }

  const onClearInput = () => {
    setForm(DEFAULT_FORM)
    setProduct(DEFAULT_PRODUCT)
    setDisableDiscountBaht(false)
    setDisableDiscountPercent(false)
    setIsAutoCreateDO(false)
  }

  const onChangeDocType = (type_id) => {
    let type_value = ''
    listQODocType.data.forEach((x) => {
      if (x.id === type_id) {
        type_value = x.value
      }
    })
    setForm({
      ...form,
      doc_type_id: type_id,
      doc_type_value: type_value,
    })
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'inline-block' }}>
          <h1>เปิด-แก้ไขใบเสนอราคา</h1>
        </div>
        <div style={{ display: 'inline-block' }}>
          <Popconfirm
            title="คุณต้องการล้างหน้าจอใช่หรือไม่ ?"
            okText="ใช่"
            cancelText="ยกเลิก"
            onConfirm={onClearInput}
          >
            <Button
              type="default"
              danger
              icon={<ClearOutlined />}
              size="large"
              block
            >
              ล้างหน้าจอ
            </Button>
          </Popconfirm>
        </div>
      </div>
      {/* <button onClick={() => console.log(form)}>Form</button> */}
      <SearchCustomerPopUp
        visible={isShowCustomerPopUp}
        onCancel={onCancelCustomerPopUp}
        onClick={onSelectCustomerPopUp}
      />
      <SearchProductNoVatPopUp
        visible={isShowProductPopUp}
        onCancel={onCancelProdutPopUp}
        onClick={onSelectProductPopUp}
      />
      <SearchQuotationPopUp
        visible={isShowQuotationPopUp}
        onCancel={onCancelQuotationPopUp}
        onClick={onSelectQuotationPopUp}
        customer_name={form.customer_name}
        doc_type={form.doc_type_value}
      />
      <Modal
        width="500px"
        centered
        visible={printPopUp}
        onCancel={() => {
          setPrintPopUp(false)
        }}
        onOk={() => {
          setPrintPopUp(false)
          window.open(`/no-vat/quotation/print?doc_no=${form.doc_no}`, '_blank')
        }}
        title="บันทึกบิลสำเร็จ"
      >
        <div>คุณต้องการพิมพ์ใบเสร็จใช่หรือไม่ ?</div>
      </Modal>
      <Spin spinning={isLoading}>
        <div className="form-wrapper">
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                onSearch={(value) => {
                  onSearchCustomerCode(value)
                }}
                inputOption="search"
                label="รหัสลูกค้า"
                placeholder="รหัสลูกค้า"
                isLoading={false}
                required
                width="180px"
                value={form.customer_code}
                onChange={(event) => {
                  setForm({
                    ...form,
                    customer_id: '',
                    customer_name: '',
                    customer_type_id: null,
                    customer_code: event.target.value.toUpperCase(),
                  })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ชื่อลูกค้า"
                width="280px"
                required
                value={form.customer_name}
                disabled={true}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ชื่อลูกค้า"
                width="280px"
                required
                value={form.customer_type}
                disabled={true}
              />
            </div>
            {/* {renderCustomerType()} */}
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="ประเภทเอกสาร"
                placeholder="เลือกประเภท"
                required
                value={form.doc_type_id}
                onChange={(type_id) => {
                  onChangeDocType(type_id)
                  // setForm({ ...form, doc_type_id: type_id })
                }}
                width="130px"
                inputOption="select_input"
                options={listQODocType.data}
              />
            </div>
            <div className="input-item">
              <InlineInput
                onSearch={(value) => {
                  onSearchQuotation(value)
                }}
                inputOption="search"
                label="เลขที่เอกสาร"
                placeholder="เลขที่เอกสาร"
                isLoading={false}
                value={form.doc_no}
                onChange={(event) => {
                  setForm({
                    ...form,
                    doc_no: event.target.value.toUpperCase(),
                  })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="วันที่เอกสาร"
                placeholder="กรุณาเลือกวันที่"
                required
                defaultValue={moment()}
                value={form.doc_date && moment(form.doc_date)}
                inputOption="date_picker"
                onChange={(_, dateString) => {
                  setForm({ ...form, doc_date: dateString })
                }}
              />
            </div>
            <div
              className="input-item"
              style={{ marginTop: '6px', height: '20px' }}
            >
              <Checkbox checked={form.is_included_vat} onChange={onVatChecked}>
                Vat
              </Checkbox>
            </div>
            <div className="input-item">
              <Popconfirm
                title="คุณต้องการสร้างใบส่งสินค้าใช่หรือไม่"
                okText="ใช่"
                cancelText="ยกเลิก"
                onConfirm={onCreateNewDOFromThisQO}
              >
                <Button
                  type="primary"
                  icon={<PlusCircleOutlined />}
                  size="middle"
                  disabled={
                    form.doc_type_id !== 1 ||
                    form.doc_no === '' ||
                    form.id === 0
                  }
                >
                  สร้างใบส่งสินค้าอัตโนมัติ
                </Button>
              </Popconfirm>
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="ประเภทบิล"
                placeholder="เลือกประเภทบิล"
                required
                // disabled
                value={form.bill_type_id}
                onChange={(type_id) => {
                  onChangeBillType(type_id, form)
                  // setForm({ ...form, bill_type_id: type_id })
                }}
                width="120px"
                inputOption="select_input"
                options={listQOBillType.data}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="เลขผู้เสียภาษี"
                width="150px"
                value={form.tax_id}
                onChange={(event) => {
                  setForm({ ...form, tax_id: event.target.value })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="เรียนคุณ"
                width="200px"
                value={form.to_message}
                onChange={(event) => {
                  setForm({ ...form, to_message: event.target.value })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="จากคุณ"
                width="200px"
                value={form.from_message}
                onChange={(event) => {
                  setForm({ ...form, from_message: event.target.value })
                }}
              />
            </div>
          </div>
          <Divider style={{ margin: '0px 0px 10px' }} />
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                onSearch={(value) => {
                  onSearchProduct(value)
                }}
                inputOption="search"
                label="รหัสสินค้า"
                placeholder="รหัสสินค้า"
                isLoading={false}
                value={product.code}
                onChange={(event) => {
                  setProduct({
                    ...DEFAULT_PRODUCT,
                    code: event.target.value.toUpperCase(),
                  })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ชื่อสินค้า"
                width="440px"
                value={product.name}
                onChange={(event) => {
                  setProduct({ ...product, name: event.target.value })
                }}
                // disabled={true}
              />
            </div>
            <div className="input-item" style={{ marginRight: '0px' }}>
              <InlineInput
                label="จำนวน"
                width="100px"
                value={product.quantity}
                onChange={(event) => {
                  // onQuantityChange(event.target.value)
                  setProduct({ ...product, quantity: event.target.value })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                width="100px"
                disableColon
                value={product.unit_name}
                onChange={(event) => {
                  // onQuantityChange(event.target.value)
                  setProduct({ ...product, unit_name: event.target.value })
                }}
              />
            </div>
            {/* <div className="input-item">
              <InlineInput
                label="ขนาดบรรจุ"
                width="120px"
                value={product.package_size}
                // disabled={true}
              />
            </div> */}
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="ราคาปัจจุบัน"
                width="100px"
                value={product.price_out}
                onChange={(event) => {
                  setProduct({
                    ...product,
                    price_out: event.target.value,
                  }).then((_product) => {
                    onCalculateNet(_product)
                  })
                }}
              />
            </div>
            <div
              className="input-item"
              style={{ marginTop: '5px', color: '#0000FF' }}
            >
              <strong>
                สุทธิ: {Number(product.net) > 0 ? product.net : '0.00'}
              </strong>
            </div>
            <div className="input-item">
              <DiscountInput
                label="ส่วนลด"
                name1="discount_1"
                name2="discount_2"
                name3="discount_3"
                name4="discount_4"
                value1={product.discount_1}
                value2={product.discount_2}
                value3={product.discount_3}
                value4={product.discount_4}
                onChange={(value, name) =>
                  setProduct({ ...product, [name]: value }).then((_product) => {
                    onCalculateNet(_product)
                  })
                }
                width="150px"
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="หมายเหตุ"
                width="300px"
                value={product.remark}
                onChange={(event) => {
                  setProduct({ ...product, remark: event.target.value })
                }}
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item" style={{ marginTop: '5px' }}>
              <strong>
                ราคาเก่า:{' '}
                {Number(product.old_price_out) > 0
                  ? product.old_price_out
                  : '0.00'}
              </strong>
            </div>
            <div className="input-item" style={{ marginTop: '5px' }}>
              ส่วนลด: {product.old_discount}
            </div>
            <div className="input-item">
              <InlineInput
                label="วันที่ซื้อล่าสุด"
                placeholder={
                  product.last_purchased === 'ไม่มีประวัติการซื้อ'
                    ? 'ไม่มีประวัติการซื้อ'
                    : ''
                }
                defaultValue={moment()}
                value={
                  product.last_purchased &&
                  product.last_purchased !== 'ไม่มีประวัติการซื้อ'
                    ? moment(product.last_purchased)
                    : ''
                }
                inputOption="date_picker"
                disabled
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="รายละเอียดสินค้า"
                width="300px"
                value={product.product_remark}
                disabled
              />
            </div>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              size="large"
              style={{ margin: '0px 10px 0px 50px' }}
              onClick={() => addProductToList(product)}
            >
              เพิ่มรายการ
            </Button>
          </div>
          <Divider style={{ margin: '0px 0px 10px' }} />
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="จำนวนในรายการ"
                width="50px"
                value={form.total_items}
                disabled
              />
            </div>
            {/* <div className="input-item">
              <InlineInput
                label="วันที่แก้ไขล่าสุด"
                placeholder="YYYY/MM/DD"
                defaultValue={moment()}
                value={form.updated_at && moment(form.updated_at)}
                disabled
                inputOption="date_picker"
              />
            </div> */}
            <div className="input-item" style={{ marginRight: '0px' }}>
              <InlineInput
                label="เงื่อนไขการชำระเงิน"
                width="60px"
                value={form.payment_condition_duration}
                onChange={(event) => {
                  setForm({
                    ...form,
                    payment_condition_duration: event.target.value,
                  })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                disableColon
                value={form.payment_condition_duration_unit_id}
                onChange={(type_id) => {
                  setForm({
                    ...form,
                    payment_condition_duration_unit_id: type_id,
                  })
                }}
                width="80px"
                inputOption="select_input"
                options={listQODurationUnit.data}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="กำหนดส่งของ"
                width="60px"
                disableColon
                value={form.delivery_due_date}
                unit="วัน"
                onChange={(event) => {
                  setForm({ ...form, delivery_due_date: event.target.value })
                }}
              />
            </div>
            <div className="input-item" style={{ marginRight: '0px' }}>
              <InlineInput
                label="กำหนดรับราคา"
                width="60px"
                value={form.accepted_price_due_date}
                onChange={(event) => {
                  setForm({
                    ...form,
                    accepted_price_due_date: event.target.value,
                  })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                disableColon
                value={form.accepted_price_due_date_unit_id}
                onChange={(type_id) => {
                  setForm({ ...form, accepted_price_due_date_unit_id: type_id })
                }}
                width="80px"
                inputOption="select_input"
                options={listQODurationUnit.data}
              />
            </div>
          </div>
          <div style={{ background: '#f1f1f1', marginBottom: '20px' }}>
            <QuotationProductTable
              productList={form.items}
              onChange={onEditTableData}
              onDelete={onDeleteProductFromTable}
            />
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '35%', display: 'inline-block' }}>
              <div className="input-wrapper">
                <div className="input-item">
                  <InlineInput
                    className="text-align-right"
                    label="ส่วนลดพิเศษ"
                    width="100px"
                    value={form.special_discount_baht}
                    unit="บาท"
                    disabled={disableDiscountBaht}
                    onChange={(event) => {
                      onSpecialDiscountChange(event.target.value, 'baht')
                      setForm({
                        ...form,
                        special_discount_baht: event.target.value,
                      }).then((form) => onChangeCalCulatedValue(form))
                    }}
                  />
                </div>
                <div className="input-item">
                  <InlineInput
                    className="text-align-right"
                    width="80px"
                    value={form.special_discount_percent}
                    unit="%"
                    disabled={disableDiscountPercent}
                    disableColon={true}
                    onChange={(event) => {
                      onSpecialDiscountChange(event.target.value, 'percent')
                      setForm({
                        ...form,
                        special_discount_percent: event.target.value,
                      }).then((form) => onChangeCalCulatedValue(form))
                    }}
                  />
                </div>
              </div>
              <div className="input-wrapper">
                <div className="input-item">
                  <InlineInput
                    label="หมายเหตุบิล"
                    width="300px"
                    value={form.remark}
                    onChange={(event) => {
                      setForm({
                        ...form,
                        remark: event.target.value,
                      })
                    }}
                  />
                </div>
              </div>
            </div>
            <div style={{ width: '40%', display: 'inline-block' }}>
              <div className="input-wrapper">
                <div className="input-item margin-left-auto">
                  <InlineInput
                    className="text-align-right"
                    label="ยอดก่อนหักส่วนลดพิเศษ"
                    width="180px"
                    value={form.total_before_special_discount}
                    disabled
                  />
                </div>
                <div className="input-item margin-left-auto">
                  <InlineInput
                    className="text-align-right"
                    label="ยอดหลังหักส่วนลดพิเศษ"
                    width="180px"
                    disabled
                    value={form.total_after_special_discount}
                  />
                </div>
                <div className="input-item margin-left-auto">
                  <InlineInput
                    className="text-align-right"
                    label="ยอดรวมภาษ๊"
                    width="180px"
                    disabled
                    value={form.grand_total}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                width: '15%',
                marginLeft: '20px',
                marginRight: '30px',
                display: 'inline-block',
              }}
            >
              <Button
                type="primary"
                icon={<SaveOutlined />}
                size="large"
                block
                style={{ height: '80px' }}
                onClick={onSaveDoc}
              >
                บันทึก/แก้ไขบิล
              </Button>
            </div>
            <div
              style={{
                width: '8%',
                display: 'inline-block',
              }}
            >
              <div style={{ marginBottom: '10px' }}>
                <Button
                  type="default"
                  icon={<PrinterOutlined />}
                  size="large"
                  style={{ height: '80px' }}
                  block
                  onClick={() => {
                    if (form.doc_no !== '') {
                      setPrintPopUp(true)
                    } else {
                      Modal.warning({
                        centered: true,
                        title: 'ตรวจสอบ',
                        content: 'กรุณาค้นหาบิลก่อนสั่งพิมพ์',
                      })
                    }
                  }}
                >
                  พิมพ์บิล
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  )
}

export default withRouter(QuotationPage)
