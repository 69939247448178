/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Table, Spin, Button, Popconfirm } from 'antd'
import { ClearOutlined, SearchOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { onListBillPerDay, onListSaleChannel } from 'redux/actions'
import InlineInput from 'components/InlineInput'

import { handleAlert, addCommas } from 'utils'

const ListBillPerDayPage = () => {
  const tableColumns = [
    {
      title: 'เลขที่บิล',
      dataIndex: 'bill_no',
      key: 'bill_no',
      width: '100px',
      fixed: 'left',
    },
    {
      title: 'วันที่เปิดบิล',
      dataIndex: 'bill_date',
      key: 'bill_date',
      width: '80px',
      fixed: 'left',
    },
    {
      title: 'VAT',
      dataIndex: 'vat_type',
      key: 'vat_type',
      width: '50px',
      fixed: 'left',
      render: (text) => (
        <div className={text === 'IV' ? 'iv-text' : ''}>{text}</div>
      ),
    },
    {
      title: 'รหัสสินค้า',
      dataIndex: 'product_code',
      key: 'product_code',
      width: '60px',
      fixed: 'left',
    },
    {
      title: 'รหัส VAT',
      dataIndex: 'product_vat_code',
      key: 'product_vat_code',
      width: '60px',
      fixed: 'left',
      className: 'text-bold',
    },
    {
      title: 'จำนวน',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '50px',
      className: 'text-align-right',
      fixed: 'left',
    },
    {
      title: 'หน่วย',
      dataIndex: 'unit_name',
      key: 'unit_name',
      width: '60px',
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'product_name',
      key: 'product_name',
      width: '300px',
    },
    {
      title: 'ราคาต่อหน่วย',
      dataIndex: 'price_out',
      key: 'price_out',
      width: '110px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
    },
    {
      title: 'ส่วนลด',
      dataIndex: '',
      key: 'v',
      width: '130px',
      render: (_, record) => (
        <div>
          {Number(record.discount_4) > 0
            ? `${record.discount_1}+${record.discount_2}+${record.discount_3}+${record.discount_4}`
            : Number(record.discount_3) > 0
            ? `${record.discount_1}+${record.discount_2}+${record.discount_3}`
            : Number(record.discount_2) > 0
            ? `${record.discount_1}+${record.discount_2}`
            : Number(record.discount_1) > 0
            ? `${record.discount_1}`
            : ''}
        </div>
      ),
    },
    {
      title: 'ยอดรวม',
      dataIndex: 'total',
      key: 'total',
      width: '110px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
    },
  ]
  const [saleChannel, setSaleChannel] = useState(null)
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
  const [billList, setBillList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const listBillPerDay = useSelector((state) => state.listBillPerDay)
  const listSaleChannel = useSelector((state) => state.listSaleChannel)

  useEffect(() => {
    dispatch(onListSaleChannel())
    document.title = 'รายการบิลรายวัน - NoVat'
  }, [])

  useEffect(() => {
    const { error, data, trace, loading, finished } = listBillPerDay
    setIsLoading(loading)
    if (!loading && !error && finished) {
      setBillList(data)
    }
    if (!loading && error) {
      handleAlert(
        'custom',
        trace.message ? trace.message : 'ทำรายการไม่สำเร็จ ค้นหาใหม่อีกครั้ง'
      )
    }
  }, [listBillPerDay])

  const onSearch = () => {
    dispatch(onListBillPerDay(saleChannel, date))
  }

  const onClearInput = () => {
    setSaleChannel(null)
    setDate(moment().format('YYYY-MM-DD'))
    setBillList([])
  }

  return (
    <div>
      <Spin spinning={isLoading}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'inline-block' }}>
            <h1>รายการบิลรายวัน</h1>
          </div>
          <div style={{ display: 'inline-block' }}>
            <Popconfirm
              title="คุณต้องการล้างหน้าจอใช่หรือไม่ ?"
              okText="ใช่"
              cancelText="ยกเลิก"
              onConfirm={onClearInput}
            >
              <Button
                type="default"
                danger
                icon={<ClearOutlined />}
                size="large"
                block
              >
                ล้างหน้าจอ
              </Button>
            </Popconfirm>
          </div>
        </div>{' '}
        <div className="form-wrapper">
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="ช่องทาง"
                placeholder="เลือกช่องทาง"
                required
                value={saleChannel}
                onChange={(channel_id) => {
                  setSaleChannel(channel_id)
                }}
                width="120px"
                inputOption="select_input"
                options={listSaleChannel.data}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="วันที่"
                placeholder="YYYY/MM/DD"
                defaultValue={moment()}
                value={date && moment(date)}
                inputOption="date_picker"
                onChange={(_, dateString) => {
                  setDate(dateString)
                }}
              />
            </div>
            <Button type="primary" icon={<SearchOutlined />} onClick={onSearch}>
              ค้นหา
            </Button>
          </div>
        </div>
        <Table
          rowKey="id"
          columns={tableColumns}
          dataSource={billList}
          size="small"
          scroll={{ x: 1400, y: 800 }}
          style={{ height: '75vh' }}
          pagination={false}
          rowClassName={(record) => record.is_included_vat === true ? 'vat-row-color' :  ''}
          // pagination={{
          //   simple: true,
          //   position: ['bottomRight'],
          //   defaultPageSize: 20,
          //   showSizeChanger: true,
          //   pageSizeOptions: ['10', '20', '50', '100'],
          // }}
        />
      </Spin>
    </div>
  )
}

export default ListBillPerDayPage
