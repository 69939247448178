import React, { useEffect } from 'react'
import { Row, Col } from 'antd'
import { EditPriceInDataProvider } from './contexts/EditPriceInDataContext'
import ListItemAdhocTableEditPriceIn from './components/ListItemAdhocTableEditPriceIn'
import SavePriceInFormEditPriceIn from './components/SavePriceInFormEditPriceIn'
import RefreshTableButtonSection from './components/RefreshTableButtonSection'

const EditPriceInPage = () => {
  useEffect(() => {
    document.title = 'แก้ไขรายการราคาเข้าต่ำกว่า 0 - NoVat'
  }, [])
  return (
    <EditPriceInDataProvider>
      <h1>แก้ไขรายการราคาเข้าต่ำกว่า 0</h1>
      <div className="form-wrapper">
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <SavePriceInFormEditPriceIn />
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 16 }}>
            <RefreshTableButtonSection />
            <ListItemAdhocTableEditPriceIn />
          </Col>
        </Row>
      </div>
    </EditPriceInDataProvider>
  )
}

export default EditPriceInPage
