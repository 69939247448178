import { request } from './request'

export const listBillType = () =>
  request({
    url: '/api/customer_bill/bill_type/list',
    method: 'get',
  })

export const listSaleChannel = () =>
  request({
    url: '/api/customer_bill/sale_channel/list',
    method: 'get',
  })

export const getCustomerBill = (id) =>
  request({
    url: `/api/customer_bill/${id}`,
    method: 'get',
  })

export const getLastestBoughtProductOfCustomer = (
  product_code,
  customer_code,
  bill_type_value
) =>
  request({
    url: '/api/customer_bill/product',
    method: 'get',
    params: {
      product_code,
      customer_code,
      bill_type_value,
    },
  })

export const saveCustomerBill = (data) =>
request({
  url: '/api/customer_bill/save',
  method: 'post',
  data,
})

export const listBillRemark = () =>
request({
  url: '/api/customer_bill/bill_remark/list',
  method: 'get',
})

export const listCustomerBillByCustomerCode = (customer_code) =>
request({
  url: '/api/customer_bill/list',
  method: 'get',
  params: {
    customer_code,
  },
})

export const listCustomerBillByCustomerNamePopUp = (params) =>
  request({
    url: `/api/customer_bill/customer_name/list`,
    method: 'get',
    params: params ?? null,
  })

export const listBillItemAdhoc = (params) =>
  request({
    url: `/api/customer_bill/bills/items/adhoc`,
    method: 'get',
    params: params ?? null,
  })

export const UpdatePriceIn = (data) =>
  request({
    url: '/api/customer_bill/bills/item/price_in',
    method: 'post',
    data,
  })