/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Spin, Button, Input } from 'antd'
import { withRouter } from 'react-router-dom'
import { ClearOutlined } from '@ant-design/icons'

import TemporaryStockTable from 'components/TemporaryStockTable'
import { handleAlert } from 'utils'

import { onListTemporaryStock, onReturnTemporaryStock } from 'redux/actions'
import { useSelector, useDispatch } from 'react-redux'

const Search = Input.Search

const ListTemporaryStockPage = (props) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [temporaryList, setTemporaryList] = useState([])
  const [filteredProductList, setFilteredProductList] = useState([])
  const [searchText, setSearchText] = useState('')
  const listTemporaryStock = useSelector(state => state.listTemporaryStock)
  const returnTemporaryStock = useSelector(state => state.returnTemporaryStock)

  useEffect(() => {
    dispatch(onListTemporaryStock())
    document.title = "เช็ครายการรับสินค้าเข้าชั่วคราว - NoVat"
  }, [])

  useEffect(() => {
    setIsLoading(listTemporaryStock.loading)
    if (!listTemporaryStock.error && !listTemporaryStock.loading && listTemporaryStock.finished) {
      setTemporaryList(listTemporaryStock.data)
      setFilteredProductList(listTemporaryStock.data)
    }
    if (!listTemporaryStock.loading && listTemporaryStock.error) {
      handleAlert('error', listTemporaryStock.trace.message || '')
    }
  }, [listTemporaryStock])

  useEffect(() => {
    setIsLoading(returnTemporaryStock.loading)
    if (!returnTemporaryStock.loading && !returnTemporaryStock.error && returnTemporaryStock.finished) {
      setTemporaryList(returnTemporaryStock.data)
      setFilteredProductList(returnTemporaryStock.data)
    }

    if (!returnTemporaryStock.loading && returnTemporaryStock.error) {
      handleAlert('custom', returnTemporaryStock.trace.message)
    }
  }, [returnTemporaryStock])

  const onReturnHandler = (id) => {
    dispatch(onReturnTemporaryStock(id))
  }

  const handleSearch = (searchText) => {
    const _temporaryList = [...temporaryList]
    const filteredData = _temporaryList.filter(({ product_code, product_name }) => {
      product_code = product_code.toString().toLowerCase()
      product_name = product_name.toString().toLowerCase()
      return (
        product_code.includes(searchText.toLowerCase()) ||
        product_name.includes(searchText.toLowerCase())
      )
    })

    setFilteredProductList(filteredData)
  }

  const onClearSearch = () => {
    setFilteredProductList(temporaryList)
    setSearchText('')
  }

  return (
    <div>
      <h1>เช็ครายการรับสินค้าเข้าชั่วคราว</h1>
      <div className="mb-2">
        <Search
          value={searchText}
          onChange={(event) => {
            setSearchText(event.target.value.toUpperCase())
          }}
          placeholder="คำค้นหา"
          onSearch={handleSearch}
          style={{ width: 200, marginRight: 10 }}
        />
        <Button
          type="primary"
          icon={<ClearOutlined />}
          onClick={onClearSearch}
        >
          เคลียร์ผลการค้นหา
          </Button>
      </div>
      <Spin spinning={isLoading}>
        <TemporaryStockTable
          temporaryList={filteredProductList}
          onReturn={onReturnHandler}
        />
      </Spin>
    </div>
  )
}

export default withRouter(ListTemporaryStockPage)
