/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'
import { Redirect } from 'react-router-dom'
// import CustomLayout from 'pages/CustomLayout'
import BlueLayout from 'pages/CustomLayout/BlueLayout'
import StockPage from 'pages/StockPage'
import MainPage from 'pages/MainPage'
import CustomerPage from 'pages/CustomerPage'
import ProductPage from 'pages/ProductPage'
import ListProductPage from 'pages/ListProductPage'
import DeleteProductPage from 'pages/DeleteProductPage'
import SupplierPage from 'pages/SupplierPage'
import MoveStockPage from 'pages/MoveStockPage'
import EditPricePage from 'pages/EditPricePage'
import TemporaryStockPage from 'pages/TemporaryStockPage'
import ListTemporaryStockPage from 'pages/ListTemporaryStockPage'
import AlterStockPage from 'pages/AlterStockPage'
import CustomerBillPage from 'pages/CustomerBillPage'
import CreateBillPDF from 'components/CreateBillPDF'
import ListBillPerDayPage from 'pages/ListBillPerDayPage'
import SalesHistoryNoVatPage from 'pages/SalesHistoryNoVatPage'
import PurchaseHistoryNoVatPage from 'pages/PurchaseHistoryNoVatPage'
import QuotationPage from 'pages/QuotationPage'
import QuotationPrintPDF from 'components/QuotationPrintPDF'
import SupplierInvoicePage from 'pages/SupplierInvoicePage'
import PaymentSupplierInvoicePage from 'pages/PaymentSupplierInvoicePage'
import SupplierInvoicePDF from 'components/SupplierInvoicePDF'
import ListProductWithStockLowPage from 'pages/ListProductWithStockLowPage'
import ListProductFromSupplierPage from 'pages/ListProductFromSupplierPage'
import ProductPricingGroupPage from 'pages/ProductPricingGroupPage'
import EditPricebyProductGroupPage from 'pages/EditPricebyProductGroupPage'
import EditPriceHistoryPage from 'pages/EditPriceHistoryPage'
import ProductTransitionPage from 'pages/ProductTransitionPage'
import ListUnpaidSupplierInvoicePage from 'pages/ListUnpaidSupplierInvoicePage'
import ChangeProductCodePage from 'pages/ChangProductCodePage'
import EditWholesaleQuantityPage from 'pages/EditWholesaleQuantityPage'
import ExpensesPage from 'pages/ExpensesPage'
import ListBillPaymentMethodPage from 'pages/ListBillPaymentMethodPage'
import ForbiddenPage from 'pages/Error/ForbiddenPage'
import OpenStorePage from 'pages/OpenStorePage'
import CloseStorePage from 'pages/CloseStorePage'
import SummaryStoreReportPage from 'pages/SummaryStoreReportPage'
import EditPriceInPage from 'pages/EditPriceInPage'
import ListPriceInPriceOutComparisonPage from 'pages/ListPriceInPriceOutComparisonPage'

const PRIVATE_ROUTES = [
  {
    path: '/no-vat/stock',
    component: StockPage,
    permission: 'stocks.BLAD_BLUE_access_stock',
  },
  {
    path: '/no-vat/customer',
    component: CustomerPage,
    permission: 'customers.BLAD_BLUE_access_customer',
  },
  {
    path: '/no-vat/product/list',
    component: ListProductPage,
    permission: 'products.BLAD_BLUE_view_productlist',
  },
  {
    path: '/no-vat/product/delete',
    component: DeleteProductPage,
    permission: 'products.BLAD_BLUE_access_deleteproduct',
  },
  {
    path: '/no-vat/product',
    component: ProductPage,
    permission: 'products.BLAD_BLUE_access_product',
  },
  {
    path: '/no-vat/change-product-code',
    component: ChangeProductCodePage,
    permission: 'products.BLAD_BLUE_access_changeproductcode',
  },
  {
    path: '/no-vat/supplier',
    component: SupplierPage,
    permission: 'suppliers.BLAD_BLUE_access_supplier',
  },
  {
    path: '/no-vat/list-temporary-stock',
    component: ListTemporaryStockPage,
    permission: 'stocks.BLAD_BLUE_access_temporarystocklist',
  },
  {
    path: '/no-vat/temporary-stock',
    component: TemporaryStockPage,
    permission: 'stocks.BLAD_BLUE_access_temporarystock',
  },
  {
    path: '/no-vat/move-stock',
    component: MoveStockPage,
    permission: 'stocks.BLAD_BLUE_access_movestock',
  },
  {
    path: '/no-vat/alter-stock',
    component: AlterStockPage,
    permission: 'stocks.BLAD_BLUE_access_alterstock',
  },
  {
    path: '/no-vat/edit-price',
    component: EditPricePage,
    permission: 'products.BLAD_BLUE_access_editproductprice',
  },
  {
    path: '/no-vat/purchase-history',
    component: PurchaseHistoryNoVatPage,
    permission: 'customer_bills.BLAD_BLUE_view_buyhistory',
  },
  {
    path: '/no-vat/sales-history',
    component: SalesHistoryNoVatPage,
    permission: 'customer_bills.BLAD_BLUE_view_salehistory',
  },
  {
    path: '/no-vat/customer-bill/print',
    component: CreateBillPDF,
    permission: 'customer_bills.BLAD_BLUE_access_bill',
  },
  {
    path: '/no-vat/customer-bill',
    component: CustomerBillPage,
    permission: 'customer_bills.BLAD_BLUE_access_bill',
  },
  {
    path: '/no-vat/list-bill-per-day',
    component: ListBillPerDayPage,
    permission: 'customer_bills.BLAD_BLUE_view_dailybilllist',
  },
  {
    path: '/no-vat/quotation/print',
    component: QuotationPrintPDF,
    permission: 'customer_bills.BLAD_BLUE_access_quotation',
  },
  {
    path: '/no-vat/quotation',
    component: QuotationPage,
    permission: 'customer_bills.BLAD_BLUE_access_quotation',
  },
  {
    path: '/no-vat/supplier-invoice/print',
    component: SupplierInvoicePDF,
    permission: 'supplier_invoice.BLAD_BLUE_access_si',
  },
  {
    path: '/no-vat/supplier-invoice',
    component: SupplierInvoicePage,
    permission: 'supplier_invoice.BLAD_BLUE_access_si',
  },
  {
    path: '/no-vat/payment-supplier-invoice',
    component: PaymentSupplierInvoicePage,
    permission: 'supplier_invoice.BLAD_BLUE_access_sipayment',
  },
  {
    path: '/no-vat/unpaid-payment-supplier-invoice',
    component: ListUnpaidSupplierInvoicePage,
    permission: 'supplier_invoice.BLAD_BLUE_view_siunpaid',
  },
  {
    path: '/no-vat/list-stock-low',
    component: ListProductWithStockLowPage,
    permission: 'stocks.BLAD_BLUE_view_stocklowlist',
  },
  {
    path: '/no-vat/list-product-from-supplier',
    component: ListProductFromSupplierPage,
    permission: 'stocks.BLAD_BLUE_view_stockinfo',
  },
  {
    path: '/no-vat/product-group-pricing',
    component: ProductPricingGroupPage,
    permission: 'products.BLAD_BLUE_access_productgroup',
  },
  {
    path: '/no-vat/edit-price-by-product-group',
    component: EditPricebyProductGroupPage,
    permission: 'products.BLAD_BLUE_access_changeproductgroupprice',
  },
  {
    path: '/no-vat/edit-price-history',
    component: EditPriceHistoryPage,
    permission: 'products.BLAD_BLUE_view_editpricehistory',
  },
  {
    path: '/no-vat/product-transition',
    component: ProductTransitionPage,
    permission: 'products.BLAD_BLUE_access_productcostaverage',
  },
  {
    path: '/no-vat/edit-wholesale-quantity',
    component: EditWholesaleQuantityPage,
    permission: 'products.BLAD_BLUE_access_editproductminquantity',
  },
  {
    path: '/no-vat/expenses',
    component: ExpensesPage,
    permission: 'supplier_invoice.BLAD_BLUE_access_expenses',
  },
  {
    path: '/no-vat/list-bill-payment-method',
    component: ListBillPaymentMethodPage,
    permission: 'customer_bills.BLAD_BLUE_access_dailybillpayment',
  },
  {
    path: '/no-vat/open-store',
    component: OpenStorePage,
    permission: 'cash_check.BLAD_BLUE_access_openstore',
  },
  {
    path: '/no-vat/close-store',
    component: CloseStorePage,
    permission: 'cash_check.BLAD_BLUE_access_closestore',
  },
  {
    path: '/no-vat/summary-report-store',
    component: SummaryStoreReportPage,
    permission: 'cash_check.BLAD_BLUE_view_storereport',
  },
  {
    path: '/no-vat/edit-price-in',
    component: EditPriceInPage,
    permission: 'customer_bills.BLAD_BLUE_access_billsitemsadhoc',
  },
  {
    path: '/no-vat/compare-price-in-price-out',
    component: ListPriceInPriceOutComparisonPage,
    permission: 'customer_bills.BLAD_BLUE_access_billsitemsadhoc',
  },
]

const INITIAL_ROUTES = [
  {
    path: '/home',
    component: MainPage,
  },
  {
    path: '*',
    component: ({ location }) => {
      if (location.pathname === '/') {
        return <Redirect to={{ pathname: '/home' }} />
      } else {
        return <ForbiddenPage />
      }
    },
    exact: true,
  },
]

export default function PrivateRoute(prop) {
  const filterRoutesFromPermission = () => {
    let filterdRoutes = PRIVATE_ROUTES.filter((route) =>
      prop?.permissions.includes(route.permission)
    )
    return filterdRoutes
  }

  if (!prop?.is_superuser) {
    // Normol User Need Filter Routes
    let filterdRoutes = [
      {
        path: '*',
        exact: true,
        component: ({ location, history, ...rest }) => {
          if (location.pathname.includes('/login')) {
            return (
              <Redirect to={{ pathname: '/', state: { from: location } }} />
            )
          }
          if (location.pathname.includes('/vat')) {
            return (
              <Redirect to={{ pathname: '/', state: { from: location } }} />
            )
          }
          if (localStorage.getItem('menu') === 'blue') {
            return <BlueLayout {...rest} />
          }
        },
        routes: [...filterRoutesFromPermission(), ...INITIAL_ROUTES],
      },
    ]
    return filterdRoutes
  } else {
    // Super User No Need Filter Routes
    let SuperUserRoutes = [
      {
        path: '*',
        exact: true,
        component: ({ location, history, ...rest }) => {
          if (location.pathname.includes('/login')) {
            return (
              <Redirect to={{ pathname: '/', state: { from: location } }} />
            )
          }
          if (location.pathname.includes('/vat')) {
            return (
              <Redirect to={{ pathname: '/', state: { from: location } }} />
            )
          }
          if (localStorage.getItem('menu') === 'blue') {
            return <BlueLayout {...rest} />
          }
        },
        routes: [...PRIVATE_ROUTES, ...INITIAL_ROUTES],
      },
    ]
    return SuperUserRoutes
  }
}
