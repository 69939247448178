import React from 'react'
import useStoreReportDataContext from 'pages/SummaryStoreReportPage/hooks/useStoreReportDataContext'
import { Table, Spin } from 'antd'
import columns from './columns'

const BillVatTableReport = () => {
  const { reportData, reportDataLoading } = useStoreReportDataContext()

  return (
    <Spin spinning={reportDataLoading}>
      <div
        style={{ fontWeight: 700, fontSize: 18, margin: '10px 0px 10px 0px' }}
      >
        บิล (Vat) หรือ บิลอื่นๆ ที่เป็นเงินสด
      </div>
      <Table
        rowKey={(record) => `id+${record.id}`}
        dataSource={reportData?.bill_vat_list}
        columns={columns()}
        scroll={{ x: 450 }}
        style={{
          maxWidth: '500px',
        }}
        size="small"
        pagination={false}
      />
    </Spin>
  )
}

export default BillVatTableReport
