/* eslint-disable react-hooks/exhaustive-deps */
import React, { Component } from 'react'
import { Button } from 'antd'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import { onGetDeliveryOrder } from 'redux/actions'
import { handleAlert, addCommas } from 'utils'
import Logo from 'assets/logo.jpg'
import './style.scss'

const FullPrintBlock = styled.div`
  @page {
    size: A4;
    margin: 0px;
  }
`

const mapStateToProps = (state) => {
  return {
    getDeliveryOrder: state.getDeliveryOrder,
  }
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ onGetDeliveryOrder }, dispatch)

const emptyProduct = {
  product_vat_code: '',
  product_vat_name: '',
  unit_name: '',
  quantity: '',
  price_out: '',
  discount_1: '',
  discount_2: '',
  discount_3: '',
  discount_4: '',
  total: '',
  remark: '',
}

const maximumProductPerPage = 20 // maximum must less or equal 25 product per page

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class index extends Component {
    constructor(props) {
      super(props)
      this.state = {
        bill: {},
        printArray: [],
        isLoading: false,
        totalPage: 0,
        isDataAvailable: false,
      }
    }

    componentDidMount() {
      let search = window.location.search
      let params = new URLSearchParams(search)
      let doc_no = params.get('doc_no')
      this.getBillFromQuery(doc_no)
    }

    componentDidUpdate(prevProps, PrevState) {
      const { getDeliveryOrder } = this.props
      if (prevProps.getDeliveryOrder !== getDeliveryOrder) {
        const { error, loading, finished, data, trace } = getDeliveryOrder
        this.setState({ isLoading: loading })
        if (!loading && !error && finished) {
          this.setState({
            bill: data,
            isDataAvailable: true,
          })
          this.splitProductVat(data.products_vat)
          // this.splitProductVat(bill.products_vat)
        }
        if (!loading && error) {
          handleAlert('error', trace.message ? trace.message : '')
          this.setState({ isLoading: loading })
        }
      }
    }

    getBillFromQuery = (doc_no) => {
      this.props.onGetDeliveryOrder(doc_no)
    }

    splitProductVat = (products_vat) => {
      let splitArray = []
      if (products_vat.length > maximumProductPerPage) {
        for (
          let i = 0;
          i < Math.ceil(products_vat.length / maximumProductPerPage);
          i++
        ) {
          splitArray[i] = products_vat.slice(
            i * maximumProductPerPage,
            (i + 1) * maximumProductPerPage
          )
        }
      } else {
        splitArray[0] = products_vat
      }
      this.fullFillArray(splitArray)
    }

    fullFillArray = (splitArray) => {
      const rowMaximun = 30
      let lastChunk = splitArray.length - 1
      let lastIndex = splitArray[lastChunk].length - 1
      for (let y = 0; y < lastChunk + 1; y++) {
        if (y !== lastChunk) {
          for (let i = 0; i < rowMaximun - maximumProductPerPage; i++) {
            splitArray[y].push(emptyProduct)
          }
        } else {
          for (let i = 0; i < rowMaximun - lastIndex - 1; i++) {
            splitArray[y].push(emptyProduct)
          }
        }
      }
      this.setState({
        printArray: [...splitArray],
        totalPage: splitArray.length,
      })
      setTimeout(() => {
        window.print()
      }, 1000)
    }

    renderDiscount = (product_vat_code) => {
      if (product_vat_code === '') {
        return <td style={{ textAlign: 'center' }}>&nbsp;</td>
      } else {
        return <td style={{ textAlign: 'center' }}>&nbsp;</td>
      }
    }

    handleRenderPage = () => {
      const { printArray, totalPage, bill } = this.state
      // const { printArray, totalPage } = this.state
      return printArray.map((products, i) => {
        return (
          <FullPrintBlock key={i}>
            <div id="pdfdiv" className="pdf-page-delivery-order">
              <div className="print-bill-header">
                <div className="company-logo">
                  <img src={Logo} alt="Bonlighting Logo" />
                </div>
                <div className="company-detail">
                  <div className="company-name">
                    บริษัท บี โอ เอ็น ไลติ้ง จำกัด
                  </div>
                  <div>68/3 หมู่ 11 ถ.ลำลูกกา ต.ลำลูกกา อ.ลำลูกกา</div>
                  <div>จ.ปทุมธานี 12150</div>
                  <div>สำนักงานใหญ่ เลขประจำตัวผู้เสียภาษี 0135563021857</div>
                  <div>โทร : 02-5692354, แฟกซ์ : 02-5692355</div>
                </div>
                <div className="invoice-header">
                  <div className="invoice-tag">ใบส่งของ</div>
                  <div className="bill-no-detail">
                    เลขที่ NO : {bill.doc_no}
                  </div>
                </div>
              </div>
              <div className="print-bill-customer-detail">
                <div className="customer-bill-detail">
                  <div>
                    <strong>รหัสลูกค้า :</strong> {bill.customer_data.code}
                  </div>
                  <div>
                    <strong>ชื่อลูกค้า :</strong> {bill.customer_data.name}
                  </div>
                  <div className="branch-tax">
                    <div className="branch-name">
                      <strong>สาขา :</strong> {bill.customer_data.branch_name}
                    </div>
                    <div className="tax-id">
                      <strong>เลขประจำตัวผู้เสียภาษี :</strong>{' '}
                      {bill.customer_data.tax_id}
                    </div>
                  </div>
                  <div>
                    <strong>ที่อยู่ :</strong> {bill.customer_data.address}
                  </div>
                  <div>
                    <strong>โทร : </strong> {bill.customer_data.tel}
                  </div>
                </div>
                <div className="date-detail">
                  <div className="box-spacing">
                    <strong>วันที่ :</strong> {bill.doc_date}
                  </div>
                </div>
                <div className="page-index">
                  หน้าที่ {i + 1} จาก {totalPage}
                </div>
              </div>
              {/*------------- Product Table ------------- */}
              <div className="product-bill-table">
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: '5%' }}>
                        <strong>ลำดับ</strong>
                      </th>
                      <th style={{ width: '8%' }}>
                        <strong>จำนวน</strong>
                      </th>
                      <th style={{ width: '8%' }}>
                        <strong>หน่วย</strong>
                      </th>
                      <th style={{ width: '44%' }}>
                        <strong>รายการสินค้า</strong>
                      </th>
                      <th style={{ width: '8%' }}>
                        <strong>ส่วนลด</strong>
                      </th>
                      <th style={{ width: '12%' }}>
                        <strong>ราคา/หน่วย</strong>
                      </th>
                      <th style={{ width: '15%' }}>
                        <strong>จำนวนเงิน</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {products &&
                      products.map((product, index) => {
                        let productTitle =
                          product.product_vat_name + ' ' + product.remark
                        return (
                          <tr key={index}>
                            <td>
                              {product.product_vat_code
                                ? i * maximumProductPerPage + index + 1
                                : ''}
                            </td>
                            <td>{addCommas(product.quantity)}</td>
                            <td>{product.unit_name}</td>
                            <td
                              style={{
                                textAlign: 'left',
                                paddingLeft: '5px',
                                paddingRight: '5px',
                              }}
                            >
                              <div className="product-title-column">
                                {productTitle}
                              </div>
                            </td>
                            {this.renderDiscount(
                              product.discount_1,
                              product.discount_2,
                              product.discount_3,
                              product.discount_4,
                              product.product_vat_code
                            )}
                            <td
                              style={{
                                textAlign: 'right',
                                paddingRight: '5px',
                              }}
                            >
                              &nbsp;
                            </td>
                            <td
                              style={{
                                textAlign: 'right',
                                paddingRight: '5px',
                              }}
                            >
                              &nbsp;
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
              <div className="total-summary-section">
                <div className="last-row-in-table">
                  <div className="col-one"> </div>
                  <div className="col-two"> </div>
                  <div className="col-three"> </div>
                  <div className="col-four">ผิด ตก ยกเว้น E. & O.E.</div>
                  <div className="col-five"> </div>
                  <div className="col-six"> </div>
                  <div className="col-seven"> </div>
                </div>
                <div className="total-section">
                  <div className="bill-remark">
                    <p>หมายเหตุ :</p>
                    <p>{i === totalPage - 1 ? bill.remark : ' '}</p>
                  </div>
                  <div className="discount-total-vat">
                    <div className="text-total-label">
                      <div>ส่วนลด</div>
                      <div>ยอดรวม</div>
                      <div>ภาษีมูลค่าเพิ่ม 7%</div>
                    </div>
                    <div className="number-total">
                      <div>{i === totalPage - 1 ? ' ' : ' '}</div>
                      <div>{i === totalPage - 1 ? ' ' : ' '}</div>
                      <div>{i === totalPage - 1 ? ' ' : ' '}</div>
                    </div>
                  </div>
                </div>
                <div className="grand-total-section">
                  <div className="text-section">
                    <div className="text-label">
                      <span>จำนวนเงิน</span>
                      <br />
                      <span>(ตัวอักษร)</span>
                    </div>
                    <div className="text-grand-total">
                      {i === totalPage - 1 ? ' ' : ' '}
                    </div>
                  </div>
                  <div className="number-section">
                    <div className="number-label">รวมเงินทั้งสิ้น</div>
                    <div className="number-grand-total">
                      {i === totalPage - 1 ? ' ' : ' '}
                    </div>
                  </div>
                </div>
                <div className="footer-section">
                  <div className="section-one">
                    <p style={{ marginBottom: '20px' }}>ผู้ส่งสินค้า</p>
                    <br />
                    <span>ลงชื่อ</span>
                    <p style={{ marginBottom: '0px' }}>
                      วันที่&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/{' '}
                    </p>
                  </div>
                  <div className="section-two">
                    {' '}
                    <p style={{ marginBottom: '20px' }}>ผู้ตรวจสินค้า</p>
                    <br />
                    <span>ลงชื่อ</span>
                    <p style={{ marginBottom: '0px' }}>
                      วันที่&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/{' '}
                    </p>
                  </div>
                  <div className="section-three">
                    <p style={{ marginBottom: '20px' }}>
                      ได้รับสินค้าตามตารางข้างต้นไว้ถูกต้อง
                    </p>
                    <br />
                    <span>ลงชื่อผู้รับ</span>
                    <p style={{ marginBottom: '0px' }}>
                      วันที่&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/{' '}
                    </p>
                  </div>
                  <div className="section-four">
                    <p style={{ marginBottom: '20px' }}>
                      ในนาม บริษัท บี โอ เอ็น ไลติ้ง จำกัด
                    </p>
                    <br />
                    <span>อนุมัติโดย</span>
                    <p style={{ marginBottom: '0px' }}>
                      วันที่&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/{' '}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {i < printArray.length - 1 ? this.renderPageBreak() : ''}
          </FullPrintBlock>
        )
      })
    }

    renderPageBreak = () => {
      return (
        <div style={{ marginTop: '10px' }}>
          <span className="page-break-before" />
          <span className="page-break-after" />
        </div>
      )
    }

    render() {
      const { isDataAvailable } = this.state
      return (
        <div>
          <Button type="primary" className="no-print" onClick={window.print}>
            พิมพ์บิล
          </Button>
          {isDataAvailable && this.handleRenderPage()}
        </div>
      )
    }
  }
)
