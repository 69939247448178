/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Col, Row, Button, Spin, Divider, Modal, Popconfirm } from 'antd'
import {
  SaveOutlined,
  ClearOutlined,
  SearchOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'

import SearchCustomerVatPopUp from 'components/SearchCustomerVatPopUp'
import PaymentInvoiceTable from 'components/PaymentInvoiceTable'
import ListCustomerVatBillByPaymentTable from 'components/ListCustomerVatBillByPaymentTable'
import InlineInput from 'components/InlineInput'
import {
  handleAlert,
  PAYMENT_CHANNEL,
  PAYMENT_STATUS,
  // SEARCH_TIME_PERIOD,
} from 'utils'

import {
  onGetCustomerVatByCode,
  onGetCustomerPaymentDetailVatByBill,
  onSaveCustomerVatPaymentDetail,
  onCancelCustomerPaymentVat,
  onListCustomerVatBillByPaymentStatusAndMonth,
} from 'redux/actions'

const DEFAULT_FORM = {
  customer_id: null,
  customer_code: '',
  customer_name: '',
  customer_bill_id: null,
  customer_bill_no: '',
  customer_invoice_id: null,
  customer_invoice_no: '',
  grand_total: '',
  invoice_actually_paid_total: '',
  actually_paid_total: '',
  remaining_unpaid_amount: '',
  payment_list: [],
  bill_type: '',
}

const DEFAULT_PAYMENT = {
  id: 0,
  payment_channel_id: null,
  payment_channel_name: '',
  date: moment().format('YYYY-MM-DD'),
  amount: '',
  remark: '',
}

const DEFAULT_SEARCH = {
  customer_code: '',
  payment_status_id: 4,
  payment_status: 'all',
  // duration_id: 1,
  // duration: 'three_month',
  month: moment().format('YYYY-MM'),
  list: [],
}

const PaymentCustomerVatInvoicePage = (props) => {
  function useAsyncState(initialValue) {
    const [value, setValue] = useState(initialValue)
    const setter = (x) =>
      new Promise((resolve) => {
        setValue(x)
        resolve(x)
      })
    return [value, setter]
  }
  const [form, setForm] = useAsyncState(DEFAULT_FORM)
  const [payment, setPayment] = useAsyncState(DEFAULT_PAYMENT)
  const [search, setSearch] = useAsyncState(DEFAULT_SEARCH)
  const [isLoading, setIsLoading] = useState(false)
  const [isShowCustomerPopUp, setIsShowCustomerPopUp] = useState(false)
  const dispatch = useDispatch()
  const getCustomerVatByCode = useSelector(
    (state) => state.getCustomerVatByCode
  )
  let getCustomerPaymentDetailVatByBill = useSelector(
    (state) => state.getCustomerPaymentDetailVatByBill
  )
  let saveCustomerVatPaymentDetail = useSelector(
    (state) => state.saveCustomerVatPaymentDetail
  )
  let cancelCustomerPaymentVat = useSelector(
    (state) => state.cancelCustomerPaymentVat
  )
  let listCustomerVatBillByPaymentStatusAndMonth = useSelector(
    (state) => state.listCustomerVatBillByPaymentStatusAndMonth
  )

  useEffect(() => {
    document.title = 'บันทึกการจ่ายบัญชีลูกค้า - Vat'
  }, [])

  useEffect(() => {
    const { trace, error, finished, data } = getCustomerVatByCode
    if (error) {
      handleAlert('custom', trace.message || '')
      setForm({
        ...form,
        customer_id: null,
        customer_name: '',
      })
      setSearch({
        ...search,
        customer_code: '',
      })
      setIsLoading(false)
    }
    if (!error && data && finished) {
      setForm({
        ...form,
        customer_id: data.id,
        customer_code: data.code,
        customer_name: data.name,
      })
      setSearch({
        ...search,
        customer_code: data.code,
      })
      setIsLoading(false)
    }
  }, [getCustomerVatByCode]) // Only re-subscribe if props.searchCustomerCode changes

  useEffect(() => {
    const { trace, error, finished, data } = getCustomerPaymentDetailVatByBill
    if (error) {
      handleAlert('custom', trace.message || '')
      setForm({
        ...form,
        grand_total: '',
        invoice_actually_paid_total: '',
        actually_paid_total: '',
        remaining_unpaid_amount: '',
        payment_list: [],
        bill_type: '',
      })
      setPayment(DEFAULT_PAYMENT)
      setIsLoading(false)
    }
    if (!error && data && finished) {
      setForm({
        ...data,
        actually_paid_total: '',
        bill_type: '',
      }).then((newForm) => {
        if (Number(data.invoice_actually_paid_total) === 0) {
          setForm({
            ...newForm,
            bill_type: 'freeVat',
          })
        }
      })
      setPayment(DEFAULT_PAYMENT)
      setIsLoading(false)
    }
  }, [getCustomerPaymentDetailVatByBill])

  useEffect(() => {
    const { trace, error, finished, data } = saveCustomerVatPaymentDetail
    if (error) {
      handleAlert('custom', trace.message || '')
      setIsLoading(false)
    }
    if (!error && data && finished) {
      setForm(data).then((res) => {
        Modal.success({
          centered: true,
          title: 'ทำรายการสำเร็จ',
          content: `บันทึกรายการชำระเงินเรียบร้อย`,
          onOk() {
            if (res.payment_status === 'ชำระเงินแล้ว') {
              window.open(
                `/vat/customer-invoice/print?bill_no=${res.customer_bill_no}&customer_invoice_no=${res.customer_invoice_no}&customer_code=${res.customer_code}`,
                '_blank'
              )
            }
          },
        })
        dispatch(
          onListCustomerVatBillByPaymentStatusAndMonth(
            search.customer_code,
            search.payment_status,
            search.month
          )
        )
      })
      setIsLoading(false)
    }
  }, [saveCustomerVatPaymentDetail])

  useEffect(() => {
    const { trace, error, finished, data } = cancelCustomerPaymentVat
    if (error) {
      handleAlert('custom', trace.message || '')
      setIsLoading(false)
    }
    if (!error && data && finished) {
      Modal.success({
        centered: true,
        title: 'ทำรายการสำเร็จ',
        content: `ลบรายการชำระเงินทั้งหมดเรียบร้อยแล้ว`,
      })
      if (form.customer_invoice_id !== '') {
        dispatch(
          onGetCustomerPaymentDetailVatByBill(
            '',
            form.customer_invoice_no,
            form.customer_code
          )
        )
      } else {
        dispatch(
          onGetCustomerPaymentDetailVatByBill(
            form.customer_bill_no,
            '',
            form.customer_code
          )
        )
      }
      dispatch(
        onListCustomerVatBillByPaymentStatusAndMonth(
          search.customer_code,
          search.payment_status,
          search.month
        )
      )
    }
  }, [cancelCustomerPaymentVat])

  useEffect(() => {
    const { trace, error, finished, data } =
      listCustomerVatBillByPaymentStatusAndMonth
    if (error) {
      handleAlert('custom', trace.message || '')
      setIsLoading(false)
    }
    if (!error && data && finished) {
      setSearch({
        ...search,
        list: data,
      })
      setIsLoading(false)
    }
  }, [listCustomerVatBillByPaymentStatusAndMonth])

  const onSearchCustomerCode = (code) => {
    if (code !== '') {
      setIsLoading(true)
      dispatch(onGetCustomerVatByCode(code))
    } else {
      setIsShowCustomerPopUp(true)
    }
  }

  const onSelectCustomerPopup = (data) => {
    dispatch(onGetCustomerVatByCode(data.code))
    setIsShowCustomerPopUp(false)
  }

  const onCancelCustomerPopUp = () => {
    setIsShowCustomerPopUp(false)
  }

  const onSearchCustomerBill = (value) => {
    if (form.customer_id !== null) {
      setIsLoading(true)
      dispatch(
        onGetCustomerPaymentDetailVatByBill(value, '', form.customer_code)
      )
    } else {
      handleAlert('custom', 'กรุณาเลือกรหัสลูกค้าก่อน')
    }
  }

  const onSearchCustomerInvoice = (value) => {
    if (form.customer_id !== null) {
      setIsLoading(true)
      dispatch(
        onGetCustomerPaymentDetailVatByBill('', value, form.customer_code)
      )
    } else {
      handleAlert('custom', 'กรุณาเลือกรหัสลูกค้าก่อน')
    }
  }

  const onChangePaymentChannel = (id) => {
    let paymentChannelName = ''
    PAYMENT_CHANNEL.forEach((x) => {
      if (x.id === id) {
        paymentChannelName = x.name
      }
    })
    if (id === 4) {
      let tmpAmont = form.remaining_unpaid_amount + ''
      let x = tmpAmont.split('.')
      setPayment({
        ...payment,
        payment_channel_id: id,
        payment_channel_name: paymentChannelName,
        amount: '0.' + x[1],
      })
    } else {
      setPayment({
        ...payment,
        payment_channel_id: id,
        payment_channel_name: paymentChannelName,
      })
    }
  }

  const onAddPayment = () => {
    if (payment.payment_channel_id !== null) {
      let tmpPayment = {
        ...payment,
        amount: Number(payment.amount).toFixed(2),
      }
      let newRemainingAmount = 0
      if (payment.payment_channel_id === 6) {
        newRemainingAmount =
          Number(form.remaining_unpaid_amount) + Number(payment.amount)
      } else {
        newRemainingAmount =
          Number(form.remaining_unpaid_amount) - Number(payment.amount)
      }
      setForm({
        ...form,
        remaining_unpaid_amount: newRemainingAmount.toFixed(2),
        payment_list: [...form.payment_list, tmpPayment],
      }).then((_) => {
        setPayment(DEFAULT_PAYMENT)
      })
    } else {
      handleAlert('custom', 'กรุณาเลือกช่องทางการชำระก่อน')
    }
  }

  const onDeletePaymentItem = (index, item) => {
    let newRemainingAmount = 0
    if (item.payment_channel_id === 6) {
      newRemainingAmount =
        Number(form.remaining_unpaid_amount) - Number(item.amount)
    } else {
      newRemainingAmount =
        Number(form.remaining_unpaid_amount) + Number(item.amount)
    }
    const { payment_list } = form
    let tempList = [...payment_list]
    tempList.splice(index, 1)
    setForm({
      ...form,
      remaining_unpaid_amount: newRemainingAmount.toFixed(2),
      payment_list: tempList,
    })
  }

  const onSavePaymentDetail = () => {
    setIsLoading(true)
    dispatch(onSaveCustomerVatPaymentDetail(form))
  }

  const onDeletePayment = () => {
    setIsLoading(true)
    let body = {}
    if (form.customer_invoice_id !== '') {
      body = {
        customer_id: form.customer_id,
        customer_invoice_no: form.customer_invoice_no,
        customer_bill_no: '',
      }
    } else {
      body = {
        customer_id: form.customer_id,
        customer_invoice_no: '',
        customer_bill_no: form.customer_bill_no,
      }
    }
    dispatch(onCancelCustomerPaymentVat(body))
  }

  const onChangePaymentStatus = (status_id) => {
    let value = ''
    PAYMENT_STATUS.forEach((x) => {
      if (x.id === status_id) {
        value = x.value
      }
    })
    setSearch({
      ...search,
      payment_status_id: status_id,
      payment_status: value,
    })
  }

  // const onChangeDuration = (duration_id) => {
  //   let value = ''
  //   SEARCH_TIME_PERIOD.forEach((x) => {
  //     if (x.id === duration_id) {
  //       value = x.value
  //     }
  //   })
  //   setSearch({
  //     ...search,
  //     duration_id: duration_id,
  //     duration: value,
  //   })
  // }

  const onSearchList = () => {
    setIsLoading(true)
    dispatch(
      onListCustomerVatBillByPaymentStatusAndMonth(
        search.customer_code,
        search.payment_status,
        search.month
      )
    )
  }

  const onClickRow = (record) => {
    setIsLoading(true)
    if (record.customer_invoice_no !== '') {
      dispatch(
        onGetCustomerPaymentDetailVatByBill(
          '',
          record.customer_invoice_no,
          record.customer_code
        )
      )
    } else {
      dispatch(
        onGetCustomerPaymentDetailVatByBill(
          record.bill_no,
          '',
          record.customer_code
        )
      )
    }
  }

  const onClearInput = () => {
    setForm(DEFAULT_FORM)
    setPayment(DEFAULT_PAYMENT)
    setSearch(DEFAULT_SEARCH)
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'inline-block' }}>
          <h1>บันทึกการจ่ายบัญชีลูกค้า</h1>
        </div>
        <div style={{ display: 'inline-block' }}>
          <Popconfirm
            title="คุณต้องการล้างหน้าจอใช่หรือไม่ ?"
            okText="ใช่"
            cancelText="ยกเลิก"
            onConfirm={onClearInput}
          >
            <Button
              type="default"
              danger
              icon={<ClearOutlined />}
              size="large"
              block
            >
              ล้างหน้าจอ
            </Button>
          </Popconfirm>
        </div>
      </div>
      <SearchCustomerVatPopUp
        visible={isShowCustomerPopUp}
        onCancel={onCancelCustomerPopUp}
        onClick={onSelectCustomerPopup}
      />
      {/* <button onClick={() => console.log(search)}>SEARCH</button> */}
      <Spin spinning={isLoading}>
        <div className="form-wrapper-vat">
          <Row style={{ width: '100%' }} gutter={[32, 16]}>
            <Col lg={{ span: 24 }} xl={{ span: 10 }}>
              <div className="input-wrapper">
                <div className="input-item">
                  <InlineInput
                    onSearch={(value) => {
                      onSearchCustomerCode(value)
                    }}
                    inputOption="search"
                    label="รหัสลูกค้า"
                    placeholder="รหัสลูกค้า"
                    width="170px"
                    isLoading={false}
                    required={true}
                    value={form.customer_code}
                    onChange={(event) => {
                      setForm({
                        ...DEFAULT_FORM,
                        customer_code: event.target.value.toUpperCase(),
                      })
                      setPayment(DEFAULT_PAYMENT)
                      setSearch(DEFAULT_SEARCH)
                    }}
                  />
                </div>
                <div className="input-item">
                  <InlineInput
                    label="ชื่อลูกค้า"
                    width="330px"
                    value={form.customer_name}
                    disabled={true}
                  />
                </div>
                <Divider style={{ margin: '0px 0px 10px' }} />
              </div>
              <div className="input-wrapper">
                <div className="input-item">
                  <InlineInput
                    onSearch={(value) => {
                      onSearchCustomerBill(value)
                    }}
                    inputOption="search"
                    label="เลขที่บิล"
                    placeholder="เลขที่บิล"
                    disabled={form.customer_invoice_no !== ''}
                    isLoading={false}
                    value={form.customer_bill_no}
                    onChange={(event) => {
                      setForm({
                        ...form,
                        customer_bill_no: event.target.value.toUpperCase(),
                      })
                    }}
                  />
                </div>
                <div className="input-item">
                  <InlineInput
                    onSearch={(value) => {
                      onSearchCustomerInvoice(value)
                    }}
                    inputOption="search"
                    label="เลขที่ใบวางบิล"
                    placeholder="เลขที่ใบวางบิล"
                    disabled={form.customer_bill_no !== ''}
                    isLoading={false}
                    value={form.customer_invoice_no}
                    onChange={(event) => {
                      setForm({
                        ...form,
                        customer_invoice_no: event.target.value.toUpperCase(),
                      })
                    }}
                  />
                </div>
              </div>
              <div className="input-wrapper">
                <div className="input-item">
                  <InlineInput
                    label="ยอดรวม"
                    width="160px"
                    className="text-align-right"
                    value={form.invoice_actually_paid_total}
                    disabled={true}
                  />
                </div>
                <div className="input-item">
                  <InlineInput
                    label="ยอดค้างชำระ"
                    width="160px"
                    className="text-align-right"
                    value={form.remaining_unpaid_amount}
                    disabled={true}
                  />
                </div>
                <div className="input-item" style={{ marginTop: '5px' }}>
                  <strong>สถานะ: {form.payment_status}</strong>
                </div>
              </div>
              <Divider style={{ margin: '0px 0px 10px' }} />
              <div className="input-wrapper">
                <div className="input-item">
                  <InlineInput
                    label="ช่องทางการชำระ"
                    placeholder="เลือกช่องทาง"
                    required
                    value={payment.payment_channel_id}
                    onChange={(id) => {
                      onChangePaymentChannel(id)
                    }}
                    width="150px"
                    inputOption="select_input"
                    options={PAYMENT_CHANNEL}
                  />
                </div>
                <div className="input-item">
                  <InlineInput
                    label="วันที่ชำระ"
                    placeholder="กรุณาเลือกวันที่"
                    required
                    defaultValue={moment()}
                    value={payment.date && moment(payment.date)}
                    inputOption="date_picker"
                    onChange={(_, dateString) => {
                      setPayment({ ...payment, date: dateString })
                    }}
                  />
                </div>
              </div>
              <div className="input-wrapper">
                <div className="input-item">
                  <InlineInput
                    label="จำนวนเงิน"
                    width="120px"
                    required
                    value={payment.amount}
                    onChange={(event) => {
                      if (
                        form.remaining_unpaid_amount !== '' &&
                        Number(event.target.value) >
                          Number(form.remaining_unpaid_amount)
                      ) {
                        setPayment({
                          ...payment,
                          amount: form.remaining_unpaid_amount,
                        })
                      } else {
                        setPayment({ ...payment, amount: event.target.value })
                      }
                    }}
                  />
                </div>
                <div className="input-item">
                  <InlineInput
                    label="หมายเหตุ"
                    width="400px"
                    value={payment.remark}
                    onChange={(event) => {
                      setPayment({ ...payment, remark: event.target.value })
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingBottom: '10px',
                }}
              >
                <div style={{ display: 'inline-block' }}>
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    size="medium"
                    onClick={onAddPayment}
                    style={{ width: '200px' }}
                    disabled={form.bill_type !== 'freeVat'}
                  >
                    เพิ่มช่องทางบิลให้เปล่า
                  </Button>
                </div>
                <div style={{ display: 'inline-block' }}>
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    size="medium"
                    onClick={onAddPayment}
                    style={{ width: '184px' }}
                    disabled={
                      form.customer_invoice_id === null ||
                      form.customer_bill_id === null ||
                      Number(form.remaining_unpaid_amount) === 0 ||
                      payment.payment_channel_id === null ||
                      payment.date === '' ||
                      Number(payment.amount) === 0
                    }
                  >
                    เพิ่มรายการ
                  </Button>
                </div>
              </div>
              <Divider style={{ margin: '0px 0px 10px' }} />
              <PaymentInvoiceTable
                list={form.payment_list}
                onDeletePaymentItem={onDeletePaymentItem}
              />
              <div style={{ display: 'flex', paddingTop: '10px' }}>
                <div style={{ display: 'inline-block', width: '515px' }}>
                  <Popconfirm
                    title="คุณต้องการลบรายการใช่หรือไม่ ?"
                    okText="ใช่"
                    cancelText="ยกเลิก"
                    onConfirm={onDeletePayment}
                  >
                    <Button
                      type="danger"
                      icon={<DeleteOutlined />}
                      size="medium"
                      // onClick={onDeletePayment}
                      style={{ width: '220px', height: '50px' }}
                      disabled={
                        form.customer_invoice_id === null ||
                        form.customer_bill_id === null
                      }
                    >
                      ลบรายการชำระเงินทั้งหมด
                    </Button>
                  </Popconfirm>
                </div>
                <div style={{ display: 'inline-block' }}>
                  <Button
                    type="primary"
                    icon={<SaveOutlined />}
                    size="medium"
                    onClick={onSavePaymentDetail}
                    style={{ width: '184px', height: '50px' }}
                    disabled={
                      form.customer_invoice_id === null ||
                      form.customer_bill_id === null
                    }
                  >
                    บันทึกพร้อมพิมพ์
                  </Button>
                </div>
              </div>
            </Col>
            <Col lg={{ span: 24 }} xl={{ span: 14 }}>
              <h2>
                ค้นหาการจ่ายบัญชีลูกค้า
                {search.customer_code !== ''
                  ? ` : ${search.customer_code}`
                  : ''}{' '}
              </h2>
              <div className="input-wrapper">
                <div className="input-item">
                  <InlineInput
                    label="ประเภทการชำระ"
                    placeholder="เลือกช่องทาง"
                    required
                    value={search.payment_status_id}
                    onChange={(id) => {
                      onChangePaymentStatus(id)
                    }}
                    width="150px"
                    inputOption="select_input"
                    options={PAYMENT_STATUS}
                  />
                </div>
                {/* <div className="input-item">
                  <InlineInput
                    label="ช่วงเวลา"
                    placeholder="เลือกช่องทาง"
                    required
                    value={search.duration_id}
                    onChange={(id) => {
                      onChangeDuration(id)
                    }}
                    width="150px"
                    inputOption="select_input"
                    options={SEARCH_TIME_PERIOD}
                  />
                </div> */}
                <div className="input-item">
                  <InlineInput
                    label="เดือนที่ค้นหา"
                    // placeholder="เลือกเดือนที่ต้องการค้นหา"
                    required
                    defaultValue={moment()}
                    value={search.month && moment(search.month)}
                    inputOption="date_picker"
                    onChange={(_, dateString) => {
                      setSearch({ ...search, month: dateString })
                    }}
                    pickerType="month"
                  />
                </div>
                <div className="input-item">
                  <Button
                    type="primary"
                    icon={<SearchOutlined />}
                    size="medium"
                    onClick={onSearchList}
                    style={{ width: '150px' }}
                    disabled={
                      search.month === '' || search.payment_status === ''
                    }
                  >
                    ค้นหา
                  </Button>
                </div>
                <div style={{ display: 'inline-block' }}>
                  <Button
                    type="default"
                    icon={<ClearOutlined />}
                    style={{ width: '120px', marginLeft: '80px' }}
                    onClick={() => {
                      setSearch({
                        ...search,
                        customer_code: '',
                        list: [],
                      })
                    }}
                    size="medium"
                  >
                    เคลียร์
                  </Button>
                </div>
              </div>
              <Divider style={{ margin: '10px 0px 20px' }} />
              <ListCustomerVatBillByPaymentTable
                list={search.list}
                onClickRow={onClickRow}
              />
            </Col>
          </Row>
        </div>
      </Spin>
    </div>
  )
}

export default withRouter(PaymentCustomerVatInvoicePage)
