/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Modal, Table, Button, Spin } from 'antd'
import { SelectOutlined } from '@ant-design/icons'

import { addCommas } from 'utils'
import useListCustomerBillVatPopUp from './hooks/useListCustomerBillVatPopUp'
import usePagination from 'hooks/usePagination'

import InlineInput from 'components/InlineInput'

const SearchCustomerBillVatPopUp = ({
  visible,
  onClick,
  onCancel,
  type = '',
  customer_name = '',
}) => {
  const {
    currentPageNumber,
    pageSize,
    paginationSetting,
    setCurrentPageNumber,
  } = usePagination({ customPageSize: 10 })
  const {
    listCustomerBillVatPopUpData,
    isLoadingListCustomerBIllVatPopUp,
    listCustomerBillVatPopUpPagination,
    onSearch,
  } = useListCustomerBillVatPopUp({ currentPageNumber, pageSize })
  const [name, setName] = useState('')
  const [total, setTotal] = useState('')

  useEffect(() => {
    if (visible && customer_name !== '') {
      setCurrentPageNumber(1)
      onSearch({ searchName: customer_name, searchTotal: '', billType: type })
      setName(customer_name)
      setTotal('')
    } else if (visible && customer_name === '') {
      setCurrentPageNumber(1)
      onSearch({ searchName: '', searchTotal: '', billType: type })
      setName('')
      setTotal('')
    } else {
      setName('')
      setTotal('')
    }
  }, [visible])

  const fetchListByName = (value) => {
    setCurrentPageNumber(1)
    onSearch({ searchName: value, searchTotal: total, billType: type })
  }

  const fetchListByTotal = (value) => {
    setCurrentPageNumber(1)
    onSearch({ searchName: name, searchTotal: value, billType: type })
  }

  const tableColumns = [
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: '30px',
      render: (_, record) => {
        return (
          <Button
            size="small"
            type="primary"
            icon={<SelectOutlined />}
            onClick={() => onClick(record)}
          />
        )
      },
    },
    {
      title: 'เลขที่บิล',
      dataIndex: 'bill_no',
      key: 'bill_no',
      width: '100px',
    },
    {
      title: 'วันที่เปิดบิล',
      dataIndex: 'bill_date',
      key: 'bill_date',
      width: '100px',
    },
    {
      title: 'ชื่อลูกค้า',
      dataIndex: 'customer_name',
      key: 'customer_name',
      width: '300px',
      render: (text) => <div className="break-word">{text}</div>,
    },
    {
      title: 'ยอดรวม',
      dataIndex: 'total',
      key: 'total',
      width: '100px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
    },
  ]

  return (
    <Modal
      visible={visible}
      width="750px"
      onCancel={onCancel}
      footer={null}
      title={
        <div style={{ display: 'flex' }}>
          <InlineInput
            onSearch={(value) => {
              fetchListByName(value)
            }}
            inputOption="search"
            label="ค้นหา"
            placeholder="ใส่ชื่อลูกค้า"
            value={name}
            width="250px"
            onChange={(event) => {
              setName(event.target.value)
            }}
          />
          <div style={{ display: 'inline-block', marginRight: '20px' }} />
          <InlineInput
            onSearch={(value) => {
              fetchListByTotal(value)
            }}
            inputOption="search"
            label="ยอดบิล"
            placeholder="กรอกยอดบิล"
            value={total}
            width="170px"
            onChange={(event) => {
              setTotal(event.target.value)
            }}
          />
        </div>
      }
    >
      <Spin spinning={isLoadingListCustomerBIllVatPopUp}>
        <Table
          rowKey={(record) => `id+${record.code}`}
          dataSource={listCustomerBillVatPopUpData}
          columns={tableColumns}
          style={{ height: '440px' }}
          scroll={{ y: 360 }}
          size="small"
          pagination={paginationSetting({
            totalItems: listCustomerBillVatPopUpPagination?.totalItems,
            simple: true,
          })}
        />
      </Spin>
    </Modal>
  )
}

export default SearchCustomerBillVatPopUp
