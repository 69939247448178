import React from 'react'
import { addCommas } from 'utils'

const columns = () => {
  const tableColumns = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
      width: '40px',
      fixed: 'left',
    },
    {
      title: 'วันที่',
      dataIndex: 'date',
      key: 'date',
      width: '90px',
      fixed: 'left',
    },
    {
      title: '1000',
      dataIndex: 'thousand',
      key: 'thousand',
      width: '50px',
      render: (_, record) => (
        <div className="text-align-right pr-1">
          {addCommas(record.banknote.thousand)}
        </div>
      ),
    },
    {
      title: '500',
      dataIndex: 'fivehundred',
      key: 'fivehundred',
      width: '50px',
      render: (_, record) => (
        <div className="text-align-right pr-1">
          {addCommas(record.banknote.fivehundred)}
        </div>
      ),
    },
    {
      title: '100',
      dataIndex: 'onehundred',
      key: 'onehundred',
      width: '50px',
      render: (_, record) => (
        <div className="text-align-right pr-1">
          {addCommas(record.banknote.onehundred)}
        </div>
      ),
    },
    {
      title: '50',
      dataIndex: 'fifty',
      key: 'fifty',
      width: '50px',
      render: (_, record) => (
        <div className="text-align-right pr-1">
          {addCommas(record.banknote.fifty)}
        </div>
      ),
    },
    {
      title: '20',
      dataIndex: 'twenty',
      key: 'twenty',
      width: '50px',
      render: (_, record) => (
        <div className="text-align-right pr-1">
          {addCommas(record.banknote.twenty)}
        </div>
      ),
    },
    {
      title: '10',
      dataIndex: 'ten',
      key: 'ten',
      width: '50px',
      render: (_, record) => (
        <div className="text-align-right pr-1">
          {addCommas(record.banknote.ten)}
        </div>
      ),
    },
    {
      title: '5',
      dataIndex: 'five',
      key: 'five',
      width: '50px',
      render: (_, record) => (
        <div className="text-align-right pr-1">
          {addCommas(record.banknote.five)}
        </div>
      ),
    },
    {
      title: '2',
      dataIndex: 'two',
      key: 'two',
      width: '50px',
      render: (_, record) => (
        <div className="text-align-right pr-1">
          {addCommas(record.banknote.two)}
        </div>
      ),
    },
    {
      title: '1',
      dataIndex: 'one',
      key: 'one',
      width: '50px',
      render: (_, record) => (
        <div className="text-align-right pr-1">
          {addCommas(record.banknote.one)}
        </div>
      ),
    },
    {
      title: 'total',
      dataIndex: 'total',
      key: 'total',
      width: '80px',
      render: (_, record) => (
        <div className="text-align-right pr-1">
          {addCommas(record.banknote.total)}
        </div>
      ),
    },
  ]
  return tableColumns
}

export default columns
