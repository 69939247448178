import React from 'react'
import { Table, Checkbox } from 'antd'
import { addCommas } from 'utils'

const InvoiceCustomerVatTable = (props) => {
  const { list, onSelectedBill } = props
  const tableColumns = [
    {
      title: 'เลือก',
      dataIndex: '',
      key: 'x',
      width: '40px',
      fixed: 'left',
      render: (_, record, index) => {
        return (
          <Checkbox
            checked={record.is_checked}
            onChange={(event) =>
              onSelectedBill(
                event.target.checked,
                record.bill_total,
                record.bill_no,
                record.actually_paid_total,
                record.payment_duration,
              )
            }
            disabled={
              record.payment_status_value === 'unpaid'
                ? record.customer_invoice_no === ''
                  ? false
                  : true
                : true
            }
          />
        )
      },
    },
    {
      title: 'รหัสลูกค้า',
      dataIndex: 'customer_code',
      key: 'customer_code',
      width: '60px',
      fixed: 'left',
    },
    {
      title: 'ระยะเวลาชำระ',
      dataIndex: 'payment_duration',
      key: 'payment_duration',
      width: '80px',
      fixed: 'left',
      sorter: (a, b) =>
        ('' + a.payment_duration).localeCompare(b.payment_duration),
      sortDirections: ['ascend', 'descend'],
      render: (text) => (
        <div className="text-align-right pr-2">{text}</div>
      ),
    },
    {
      title: 'เลขที่บิล',
      dataIndex: 'bill_no',
      key: 'bill_no',
      width: '90px',
      fixed: 'left',
      sorter: (a, b) => ('' + a.bill_no).localeCompare(b.bill_no),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'วันที่ซื้อ',
      dataIndex: 'bill_date',
      key: 'bill_date',
      width: '70px',
      fixed: 'left',
      sorter: (a, b) => ('' + a.bill_date).localeCompare(b.bill_date),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'เลขที่ใบวางบิล',
      dataIndex: 'customer_invoice_no',
      key: 'customer_invoice_no',
      width: '90px',
      sorter: (a, b) =>
        ('' + a.customer_invoice_no).localeCompare(b.customer_invoice_no),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'ยอดบิล',
      dataIndex: 'bill_total',
      key: 'bill_total',
      width: '80px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
    },
    {
      title: 'ยอดจ่ายจริง',
      dataIndex: 'actually_paid_total',
      key: 'actually_paid_total',
      width: '80px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
    },
    {
      title: 'ยอดรวม',
      dataIndex: 'invoice_actually_paid_total',
      key: 'invoice_actually_paid_total',
      width: '80px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
    },
    {
      title: 'สถานะการชำระ',
      dataIndex: 'payment_status_name',
      key: 'payment_status_name',
      width: '100px',
      sorter: (a, b) =>
        ('' + a.payment_status_name).localeCompare(b.payment_status_name),
      sortDirections: ['ascend', 'descend'],
    },
  ]

  return (
    <div>
      <Table
        rowKey={(record) => `id+${record.id}`}
        dataSource={list}
        columns={tableColumns}
        pagination={false}
        scroll={{ x: 1250, y: 450 }}
        size="small"
        style={{
          maxWidth: '1300px',
          minHeight: '480px',
          backgroundColor: '#FFFFFF',
        }}
      />
    </div>
  )
}

export default InvoiceCustomerVatTable
