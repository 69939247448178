import React from 'react'
import useListPriceInPriceOutDataContext from 'pages/ListPriceInPriceOutComparisonPage/hooks/useListPriceInPriceOutDataContext'
import { Table } from 'antd'
import columns from './columns'

const ListProfitCompariconTable = () => {
  const { listItemProfitAdhocData, listItemProfitAdhocLoading } =
    useListPriceInPriceOutDataContext()

  return (
    <div>
      <Table
        rowKey={(record) => `id+${record.id}`}
        loading={listItemProfitAdhocLoading}
        dataSource={listItemProfitAdhocData}
        columns={columns()}
        scroll={{ x: 1300 }}
        style={{
          maxWidth: '1350px',
          minHeight: '690px',
        }}
        size="small"
        pagination={{
          simple: true,
          defaultPageSize: 20,
          position: ['bottomRight'],
        }}
      />
    </div>
  )
}

export default ListProfitCompariconTable
