/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Spin, Button, Input } from 'antd'
import { SyncOutlined, ClearOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router-dom'

import InlineInput from 'components/InlineInput'
import EditProductPriceHistoryTable from 'components/EditProductPriceHistoryTable'
import { handleAlert } from 'utils'

import { onListProductPriceHistory } from 'redux/actions'
import { useSelector, useDispatch } from 'react-redux'

const Search = Input.Search

const DURATION_LIST = [
  {
    id: 1,
    name: 'วันนี้',
    value: 'today',
  },
  {
    id: 2,
    name: '1 สัปดาห์',
    value: 'one_week',
  },
  {
    id: 3,
    name: '1 เดือน',
    value: 'one_month',
  },
  {
    id: 4,
    name: 'ย้อนหลัง 3 เดือน',
    value: 'three_month',
  },
  {
    id: 5,
    name: 'ท้งหมด',
    value: 'all',
  },
]

const DEFAULT_FORM = {
  duration_id: 1,
  duration: 'today',
}

const EditPriceHistoryPage = (props) => {
  const dispatch = useDispatch()
  const [form, setForm] = useState(DEFAULT_FORM)
  const [isLoading, setIsLoading] = useState(false)
  const [temporaryList, setTemporaryList] = useState([])
  const [filteredList, setFilteredList] = useState([])
  const [searchText, setSearchText] = useState('')
  const listProductPriceHistory = useSelector(
    (state) => state.listProductPriceHistory
  )

  useEffect(() => {
    dispatch(onListProductPriceHistory('today'))
    document.title = 'ค้นหาประวัติการแก้ไขราคา - NoVat'
  }, [])

  useEffect(() => {
    dispatch(onListProductPriceHistory(form.duration))
  }, [form])

  useEffect(() => {
    if (temporaryList !== []) {
      handleSearch(searchText)
    }
  }, [temporaryList, searchText])

  useEffect(() => {
    const { loading, error, data, finished, trace } = listProductPriceHistory
    setIsLoading(loading)
    if (!error && !loading && finished) {
      setTemporaryList(data)
    }
    if (!loading && error) {
      handleAlert('error', trace.message || '')
    }
  }, [listProductPriceHistory])

  const handleSearch = (searchText) => {
    const _temporaryList = [...temporaryList]
    const filteredData = _temporaryList.filter(({ code }) => {
      code = code.toString().toLowerCase()
      return code.startsWith(searchText.toLowerCase())
    })
    setFilteredList(filteredData)
  }

  const onFetchList = () => {
    dispatch(onListProductPriceHistory(form.duration))
  }

  const onClearSearch = () => {
    setFilteredList(temporaryList)
    setSearchText('')
  }

  return (
    <div>
      <h1>ค้นหาประวัติการแก้ไขราคา</h1>
      <div className="form-wrapper">
        <div className="input-wrapper">
          <div className="input-item">
            <InlineInput
              label="ช่วงเวลาในการค้นหา"
              placeholder="เลือกช่วงเวลา"
              required
              value={form.duration_id}
              onChange={(channel_id) => {
                channel_id === 1
                  ? setForm({
                      duration_id: channel_id,
                      duration: 'today',
                    })
                  : channel_id === 2
                  ? setForm({
                      duration_id: channel_id,
                      duration: 'one_week',
                    })
                  : channel_id === 3
                  ? setForm({
                      duration_id: channel_id,
                      duration: 'one_month',
                    })
                  : channel_id === 4
                  ? setForm({
                      duration_id: channel_id,
                      duration: 'three_month',
                    })
                  : setForm({
                      duration_id: channel_id,
                      duration: 'all',
                    })
              }}
              width="150px"
              inputOption="select_input"
              options={DURATION_LIST}
            />
          </div>
          <div className="input-item">
            <Button
              type="primary"
              icon={<SyncOutlined />}
              onClick={onFetchList}
            >
              รีเฟรชลิสต์
            </Button>
          </div>
          <div className="input-item">
            <Search
              value={searchText}
              onChange={(event) => {
                setSearchText(event.target.value)
              }}
              placeholder="กรองรหัสสินค้า"
              onSearch={(value) => {
                handleSearch(value)
              }}
            />
          </div>
          <div className="input-item">
            <Button
              type="primary"
              icon={<ClearOutlined />}
              onClick={onClearSearch}
            >
              เคลียร์
            </Button>
          </div>
        </div>
        <Spin spinning={isLoading}>
          <EditProductPriceHistoryTable historyList={filteredList} />
        </Spin>
      </div>
    </div>
  )
}

export default withRouter(EditPriceHistoryPage)
