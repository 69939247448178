import { useContext } from 'react'
import { CloseStoreDataContext } from '../contexts/CloseStoreDataContext'

const useCloseStoreDataContext = () => {
  const context = useContext(CloseStoreDataContext)
  if (!context)
    throw new Error(
      'OpenStoreDataContext must be use inside PaymentOrderProvider'
    )
  return context
}

export default useCloseStoreDataContext
