import React from 'react'
import { Spin } from 'antd'
import useStoreReportDataContext from '../../hooks/useStoreReportDataContext'
import { addCommas, roundToTwo } from 'utils'
import './style.scss'

const SummaryReportSection = () => {
  const { reportData, reportDataLoading } = useStoreReportDataContext()

  return (
    <Spin spinning={reportDataLoading}>
      <div style={{ marginTop: '40px' }}>
        <div className="summary-row">
          <div className="summary-title">เงินทอน (เงินเปิดร้าน) :</div>
          <div className="summary-value">
            {addCommas(
              roundToTwo(reportData?.summary?.total_open_store_cashes).toFixed(
                2
              )
            )}
          </div>
        </div>
        <div className="summary-row">
          <div className="summary-title">ขาย (ยอดเงินสดจากบิล) :</div>
          <div className="summary-value">
            {addCommas(
              roundToTwo(reportData?.summary?.total_sum_cash).toFixed(2)
            )}
          </div>
        </div>
        <div className="summary-row">
          <div className="summary-title">รวม : </div>
          <div className="summary-value">
            {addCommas(
              roundToTwo(
                reportData?.summary?.total_sum_cash +
                  reportData?.summary?.total_open_store_cashes
              ).toFixed(2)
            )}
          </div>
        </div>
        <div className="summary-row">
          <div className="summary-title">เพิ่ม/ลด รายการ :</div>
          <div className="summary-value">
            {addCommas(
              roundToTwo(reportData?.summary?.total_amends).toFixed(2)
            )}
          </div>
        </div>
        <div className="summary-row" style={{ fontWeight: 700, fontSize: '16px' }}>
          <div className="summary-title">Net :</div>
          <div className="summary-value">
            {addCommas(roundToTwo(reportData?.summary?.net).toFixed(2))}
          </div>
        </div>
        <div className="summary-row">
          <div className="summary-title">เงินสด (ปิดร้าน)</div>
          <div className="summary-value">
            {addCommas(roundToTwo(reportData?.summary?.total_close_store_cashes).toFixed(2))}
          </div>
        </div>
        <div className="summary-row summary-status">
          <div className="summary-title">สถานะยอดเงินรวม : </div>
          <div className="summary-value">
            {reportData?.summary?.net ===
            reportData?.summary?.total_close_store_cashes ? (
              <span style={{ color: 'green' }}>ยอดตรงกัน</span>
            ) : (
              <span style={{ color: 'red' }}>ยอดไม่ตรง</span>
            )}
          </div>
        </div>
      </div>
    </Spin>
  )
}

export default SummaryReportSection
