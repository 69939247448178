import React from 'react'
import OpenStoreTableReport from '../../components/OpenStoreTableReport'
import CloseStoreTableReport from '../../components/CloseStoreTableReport'
import CustomerBillTableReport from '../../components/CustomerBillTableReport'
import BillVatTableReport from '../../components/BillVatTableReport'
import AmendsTableReport from '../../components/AmendsTableReport'
import SummaryReportSection from '../../components/SummaryReportSection'
import useStoreReportDataContext from 'pages/SummaryStoreReportPage/hooks/useStoreReportDataContext'

const SummaryReportPDF = () => {
  const { date } = useStoreReportDataContext()
  return (
    <div style={{ padding: '0px', width: '790px' }}>
      <span style={{ fontWeight: 700 }}>
        {' '}
        ตารางแสดงรายงานเงินสดร้านค้าประจำวันที่ :{' '}
      </span>
      <span style={{ color: 'blue', marginRight: '20px' }}>{date}</span>
      <OpenStoreTableReport />
      <CloseStoreTableReport />
      <div className="page-break-before" style={{ marginTop: '30px' }} />
      <CustomerBillTableReport isPrint={true} />
      <div className="page-break-before" style={{ marginTop: '30px' }} />
      <BillVatTableReport />
      <AmendsTableReport />
      <SummaryReportSection />
    </div>
  )
}

export default SummaryReportPDF
