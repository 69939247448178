import React from 'react'
import useStoreReportDataContext from '../hooks/useStoreReportDataContext'
import { ReloadOutlined, PrinterOutlined } from '@ant-design/icons'
import { Button, DatePicker, Divider } from 'antd'
import moment from 'moment'

moment.locale('th')

const DatePickerSummaryReportStore = ({ handlePrint }) => {
  const { date, onDateChange, onRefreshReport } = useStoreReportDataContext()

  return (
    <>
      <span style={{ paddingRight: '10px' }}>เลือกวันที่ :</span>
      <DatePicker
        defaultValue={moment()}
        value={moment(date)}
        disabledDate={(current) => current.isAfter(moment())}
        onChange={(_, dateString) => {
          onDateChange(dateString)
        }}
      />
      <span
        style={{
          fontWeight: 700,
          margin: '0px 0px 10px 20px',
          fontSize: '16px',
        }}
      >
        ตารางแสดงรายงานเงินสดร้านค้า ประจำวันที่ :{' '}
        <span style={{ color: 'blue', marginRight: '20px' }}>{date}</span>
        <Button onClick={onRefreshReport} icon={<ReloadOutlined />}>
          รีเฟรช
        </Button>
        <Button type="primary" icon={<PrinterOutlined />} onClick={handlePrint}>
          พิมพ์รายงาน
        </Button>
      </span>
      <Divider style={{ margin: '20px 0px 10px 0px' }} />
    </>
  )
}

export default DatePickerSummaryReportStore
