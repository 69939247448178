import React from 'react'
import { addCommas } from 'utils'

const columns = () => {
  const tableColumns = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
      width: '40px',
      fixed: 'left',
    },
    {
      title: 'ประเภท',
      dataIndex: 'type',
      key: 'type',
      width: '50px',
      fixed: 'left',
      render: (text) =>
        text === 'plus' ? (
          <div className="text-align-center">เพิ่ม</div>
        ) : text === 'minus' ? (
          <div className="text-align-center">ลด</div>
        ) : (
          ''
        ),
    },
    {
      title: 'จำนวนเงิน',
      dataIndex: 'cash_amount',
      key: 'cash_amount',
      width: '80px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
    },
    {
      title: 'หมายเหตุ',
      dataIndex: 'remark',
      key: 'remark',
      width: '150px',
    },
  ]
  return tableColumns
}

export default columns
