/* eslint-disable react-hooks/exhaustive-deps */
import React, { Component } from 'react'
import { Button, Checkbox } from 'antd'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import { onGetCustomerBillVat } from 'redux/actions'
import { handleAlert, ArabicNumberToText, addCommas, roundToFour } from 'utils'
import Logo from 'assets/logo.jpg'
import './style.scss'

const FullPrintBlock = styled.div`
  @page {
    size: A4;
    margin: 0px;
  }
`

const mapStateToProps = (state) => {
  return {
    getCustomerBillVat: state.getCustomerBillVat,
  }
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ onGetCustomerBillVat }, dispatch)

const emptyProduct = {
  product_vat_code: '',
  product_vat_name: '',
  unit_name: '',
  quantity: '',
  price_out: '',
  discount_1: '',
  discount_2: '',
  discount_3: '',
  discount_4: '',
  total: '',
  remark: '',
}

const maximumProductPerPage = 20 // maximum must less or equal 25 product per page

// const bill = {
//   created: '2021-04-08T11:40:15.864471Z',
//   updated_at: '2021-04-08T11:40:15.864522Z',
//   bill_vat_no: 'P6404000001',
//   bill_vat_type_id: 1,
//   bill_vat_type_value: 'charge_vat',
//   bill_vat_type_name: 'เก็บค่า Vat',
//   bill_vat_date: '2021-04-08',
//   customer_data: {
//     id: 2,
//     code: 'AA0002',
//     name: 'Test',
//     tax_id: '1234567890123',
//     branch_name: 'สำนักงานใหญ่',
//     address:
//       'Bangkok กรุงเทพมหานคร อมรรัตนโกสินทร์ มหินทรายุธยา มหาดิลกภพ นพรัตนราชธานีบูรีรมย์ อุดมราชนิเวศน์ 10100',
//     tel: '0999999999',
//     fax: '022222222',
//     website: 'google.com',
//     email: 'test2@gmail.com',
//     remark: 'This is remark for this customer',
//   },
//   is_full_tax_invoice: false,
//   full_tax_invoice_type: '',
//   sale_person: 'WXT',
//   product_is_included_vat: true,
//   sale_channel_id: 1,
//   sale_channel_name: 'Lazada',
//   purchase_order_number: 'POWXT0001',
//   percent_vat: '7.00',
//   special_discount_baht: '7.00',
//   special_discount_percent: '0.00',
//   total_charging_vat: '0.0000',
//   value_added_tax: '161.51',
//   grand_total_with_vat: '2461858.75',
//   total_before_special_discount: '2307.24',
//   total_after_special_discount: '2300.24',
//   total_items: 3,
//   products_vat: [
//     {
//       id: 97,
//       created: '2021-04-08T11:40:15.878880Z',
//       updated_at: '2021-04-08T11:40:15.878919Z',
//       product_vat_id: 2,
//       product_vat_code: '0020',
//       product_vat_name: 'Test Name +++++++++asdfasdfasdfasdfasdfasdfasdfasdf',
//       product_normal_id: '',
//       product_normal_code: '',
//       product_normal_name: '',
//       package_size: '12',
//       stock1: 2369,
//       unit_id: 2,
//       unit_name: 'ชิ้น',
//       quantity: 5,
//       price_out_with_vat: '100.0000',
//       price_out: 93.4579,
//       discount_1: '0.0000',
//       discount_2: '0.0000',
//       discount_3: '0.0000',
//       discount_4: '0.0000',
//       total: '467.2900',
//       is_allow_low: false,
//       last_purchased: '2021-04-08',
//       remark: 'ดำมากกกกกกกกกกกกกกกก',
//     },
//   ],
//   remark: '',
// }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class index extends Component {
    constructor(props) {
      super(props)
      this.state = {
        bill: {},
        printArray: [],
        isLoading: false,
        totalPage: 0,
        isDataAvailable: false,
        isFourDocumentCheck: false,
      }
    }

    componentDidMount() {
      let search = window.location.search
      let params = new URLSearchParams(search)
      let bill_no = params.get('bill_no')
      this.getBillFromQuery(bill_no)
    }

    componentDidUpdate(prevProps, PrevState) {
      const { getCustomerBillVat } = this.props
      if (prevProps.getCustomerBillVat !== getCustomerBillVat) {
        const { error, loading, finished, data, trace } = getCustomerBillVat
        this.setState({ isLoading: loading })
        if (!loading && !error && finished) {
          this.setState({
            bill: data,
            isDataAvailable: true,
          })
          this.splitProductVat(data.products_vat)
          // this.splitProductVat(bill.products_vat)
        }
        if (!loading && error) {
          handleAlert('error', trace.message ? trace.message : '')
          this.setState({ isLoading: loading })
        }
      }
    }

    getBillFromQuery = (bill_no) => {
      this.props.onGetCustomerBillVat(bill_no)
    }

    splitProductVat = (products_vat) => {
      let splitArray = []
      if (products_vat.length > maximumProductPerPage) {
        for (
          let i = 0;
          i < Math.ceil(products_vat.length / maximumProductPerPage);
          i++
        ) {
          splitArray[i] = products_vat.slice(
            i * maximumProductPerPage,
            (i + 1) * maximumProductPerPage
          )
        }
      } else {
        splitArray[0] = products_vat
      }
      this.fullFillArray(splitArray)
    }

    fullFillArray = (splitArray) => {
      const rowMaximun = 30
      let lastChunk = splitArray.length - 1
      let lastIndex = splitArray[lastChunk].length - 1
      for (let y = 0; y < lastChunk + 1; y++) {
        if (y !== lastChunk) {
          for (let i = 0; i < rowMaximun - maximumProductPerPage; i++) {
            splitArray[y].push(emptyProduct)
          }
        } else {
          for (let i = 0; i < rowMaximun - lastIndex - 1; i++) {
            splitArray[y].push(emptyProduct)
          }
        }
      }
      this.setState({
        printArray: [...splitArray],
        totalPage: splitArray.length,
      })
      setTimeout(() => {
        window.print()
      }, 1000)
    }

    renderDiscount = (
      discount1,
      discount2,
      discount3,
      discount4,
      product_vat_code
    ) => {
      if (product_vat_code === '') {
        return <td style={{ textAlign: 'center' }}>&nbsp;</td>
      } else {
        let renderDiscount = 0
        renderDiscount = 100 * (1 - Number(discount1) / 100)
        renderDiscount *= 1 - Number(discount2) / 100
        renderDiscount *= 1 - Number(discount3) / 100
        renderDiscount *= 1 - Number(discount4) / 100
        renderDiscount = 100 - renderDiscount
        if (renderDiscount === 0) {
          return <td style={{ textAlign: 'center' }}>&nbsp;</td>
        } else {
          return (
            <td style={{ textAlign: 'center' }}>
              {roundToFour(renderDiscount)}%
            </td>
          )
        }
      }
    }

    renderTotal = () => {
      const { bill } = this.state
      //----- Special Discount ------//
      let baht = Number(bill.special_discount_baht)
      let percent = Number(bill.special_discount_percent)
      let renderSpecialDicsount = ''
      if (baht > 0) {
        renderSpecialDicsount = addCommas(bill.special_discount_baht)
      } else if (percent > 0) {
        renderSpecialDicsount = percent + '%'
      } else {
        renderSpecialDicsount = '-'
      }
      return renderSpecialDicsount
    }

    handleRenderPage = (title, sub_title) => {
      const { printArray, totalPage, bill } = this.state
      // const { printArray, totalPage } = this.state
      return printArray.map((products, i) => {
        return (
          <FullPrintBlock key={i}>
            <div id="pdfdiv" className="pdf-page">
              <div className="print-bill-header">
                <div className="company-logo">
                  <img src={Logo} alt="Bonlighting Logo" />
                </div>
                <div className="company-detail">
                  <div className="company-name">
                    บริษัท บี โอ เอ็น ไลติ้ง จำกัด
                  </div>
                  <div>68/3 หมู่ 11 ถ.ลำลูกกา ต.ลำลูกกา อ.ลำลูกกา</div>
                  <div>จ.ปทุมธานี 12150</div>
                  <div>สำนักงานใหญ่ เลขประจำตัวผู้เสียภาษี 0135563021857</div>
                  <div>โทร : 02-5692354, แฟกซ์ : 02-5692355</div>
                </div>
                <div className="invoice-header">
                  <div className="invoice-tag">{title}</div>
                  {sub_title === 'ต้นฉบับ' ? (
                    <div className="main-title">{sub_title}</div>
                  ) : (
                    <div className="copy-title">{sub_title}</div>
                  )}
                  <div className="bill-no-detail">
                    เลขที่ NO : {bill.bill_vat_no}
                  </div>
                </div>
              </div>
              <div className="print-bill-customer-detail">
                <div className="customer-bill-detail">
                  <div>
                    <strong>รหัสลูกค้า :</strong> {bill.customer_data.code}
                  </div>
                  <div>
                    <strong>ชื่อลูกค้า :</strong> {bill.customer_data.name}
                  </div>
                  <div className="branch-tax">
                    <div className="branch-name">
                      <strong>สาขา :</strong> {bill.customer_data.branch_name}
                    </div>
                    <div className="tax-id">
                      <strong>เลขประจำตัวผู้เสียภาษี :</strong>{' '}
                      {bill.customer_data.tax_id}
                    </div>
                  </div>
                  <div>
                    <strong>ที่อยู่ :</strong> {bill.customer_data.address}
                  </div>
                  <div>
                    <strong>โทร : </strong> {bill.customer_data.tel}
                  </div>
                </div>
                <div className="date-detail">
                  <div className="box-spacing">
                    <strong>วันที่ :</strong> {bill.bill_vat_date}
                  </div>
                  <div className="box-spacing">
                    <strong>ใบสั่งขาย :</strong> {bill.purchase_order_number}
                  </div>
                  <div>
                    <strong>พนักงานขาย :</strong> {bill.sale_person}
                  </div>
                </div>
                <div className="page-index">
                  หน้าที่ {i + 1} จาก {totalPage}
                </div>
              </div>
              {/*------------- Product Table ------------- */}
              <div className="product-bill-table">
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: '5%' }}>
                        <strong>ลำดับ</strong>
                      </th>
                      <th style={{ width: '8%' }}>
                        <strong>จำนวน</strong>
                      </th>
                      <th style={{ width: '8%' }}>
                        <strong>หน่วย</strong>
                      </th>
                      <th style={{ width: '44%' }}>
                        <strong>รายการสินค้า</strong>
                      </th>
                      <th style={{ width: '8%' }}>
                        <strong>ส่วนลด</strong>
                      </th>
                      <th style={{ width: '12%' }}>
                        <strong>ราคา/หน่วย</strong>
                      </th>
                      <th style={{ width: '15%' }}>
                        <strong>จำนวนเงิน</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {products &&
                      products.map((product, index) => {
                        let productTitle =
                          product.product_vat_name + ' ' + product.remark
                        return (
                          <tr key={index}>
                            <td>
                              {product.product_vat_code
                                ? i * maximumProductPerPage + index + 1
                                : ''}
                            </td>
                            <td>{addCommas(product.quantity)}</td>
                            <td>{product.unit_name}</td>
                            <td
                              style={{
                                textAlign: 'left',
                                paddingLeft: '5px',
                                paddingRight: '5px',
                              }}
                            >
                              <div className="product-title-column">
                                {productTitle}
                              </div>
                            </td>
                            {this.renderDiscount(
                              product.discount_1,
                              product.discount_2,
                              product.discount_3,
                              product.discount_4,
                              product.product_vat_code
                            )}
                            <td
                              style={{
                                textAlign: 'right',
                                paddingRight: '5px',
                              }}
                            >
                              {addCommas(product.price_out)}
                            </td>
                            <td
                              style={{
                                textAlign: 'right',
                                paddingRight: '5px',
                              }}
                            >
                              {addCommas(product.total)}
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
              <div className="total-summary-section">
                <div className="last-row-in-table">
                  <div className="col-one"> </div>
                  <div className="col-two"> </div>
                  <div className="col-three"> </div>
                  <div className="col-four">ผิด ตก ยกเว้น E. & O.E.</div>
                  <div className="col-five"> </div>
                  <div className="col-six"> </div>
                  <div className="col-seven"> </div>
                </div>
                <div className="total-section">
                  <div className="bill-remark">
                    <p>หมายเหตุ :</p>
                    <p>{i === totalPage - 1 ? bill.remark : ' '}</p>
                  </div>
                  <div className="discount-total-vat">
                    <div className="text-total-label">
                      <div>รวม</div>
                      <div>ส่วนลด</div>
                      <div>ยอดหลังหักส่วนลด</div>
                      <div>ภาษีมูลค่าเพิ่ม 7%</div>
                    </div>
                    <div className="number-total">
                      <div>
                        {i === totalPage - 1
                          ? addCommas(bill.total_before_special_discount)
                          : ' '}
                      </div>
                      <div>
                        {i === totalPage - 1
                          ? addCommas(this.renderTotal())
                          : ' '}
                      </div>
                      <div>
                        {i === totalPage - 1
                          ? addCommas(bill.total_after_special_discount)
                          : ' '}
                      </div>
                      <div>
                        {i === totalPage - 1
                          ? addCommas(bill.value_added_tax)
                          : ' '}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grand-total-section">
                  <div className="text-section">
                    <div className="text-label">
                      <span>จำนวนเงิน</span>
                      <br />
                      <span>(ตัวอักษร)</span>
                    </div>
                    <div className="text-grand-total">
                      {i === totalPage - 1
                        ? ArabicNumberToText(bill.grand_total_with_vat)
                        : ' '}
                    </div>
                  </div>
                  <div className="number-section">
                    <div className="number-label">รวมเงินทั้งสิ้น</div>
                    <div className="number-grand-total">
                      {i === totalPage - 1
                        ? addCommas(bill.grand_total_with_vat)
                        : ''}
                    </div>
                  </div>
                </div>
                <div className="footer-section">
                  <div className="section-one">รายการชำระเงิน</div>
                  <div className="section-two">ผู้อนุมัติ</div>
                  <div className="section-three">ผู้รับเงิน</div>
                  <div className="section-four">
                    <p style={{ marginBottom: '20px' }}>
                      ได้รับสินค้าตามตารางข้างต้นไว้ถูกต้อง
                    </p>
                    <br />
                    <span>ลงชื่อผู้รับ</span>
                    <p style={{ marginBottom: '0px' }}>
                      วันที่&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/{' '}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {i < printArray.length - 1 ? this.renderPageBreak() : ''}
          </FullPrintBlock>
        )
      })
    }

    renderPageBreak = () => {
      return (
        <div style={{ marginTop: '10px' }}>
          <span className="page-break-before" />
          <span className="page-break-after" />
        </div>
      )
    }

    render() {
      const { isDataAvailable, isFourDocumentCheck } = this.state
      return (
        <div>
          <div className="no-print">
            <Button type="primary" onClick={window.print}>
              พิมพ์บิล
            </Button>
            <Checkbox
              checked={isFourDocumentCheck}
              style={{ paddingLeft: '20px' }}
              onChange={(event) => {
                this.setState({ isFourDocumentCheck: event.target.checked })
              }}
            >
              พิมพ์เอกสาร 4 ชุด
            </Checkbox>
          </div>
          {isFourDocumentCheck ? (
            <>
              {isDataAvailable &&
                this.handleRenderPage('ใบเสร็จรับเงิน / ใบส่งสินค้า', '')}
              {this.renderPageBreak()}
              {isDataAvailable &&
                this.handleRenderPage('ใบกำกับภาษี', 'ต้นฉบับ')}
              {this.renderPageBreak()}
              {isDataAvailable && this.handleRenderPage('ใบกำกับภาษี', 'สำเนา')}
              {this.renderPageBreak()}
              {isDataAvailable && this.handleRenderPage('ใบกำกับภาษี', 'สำเนา')}
            </>
          ) : (
            <>
              {isDataAvailable &&
                this.handleRenderPage(
                  'ใบกำกับภาษี / ใบเสร็จรับเงิน',
                  'ต้นฉบับ'
                )}
              {this.renderPageBreak()}
              {isDataAvailable &&
                this.handleRenderPage('ใบกำกับภาษี / ใบเสร็จรับเงิน', 'สำเนา')}
            </>
          )}
        </div>
      )
    }
  }
)
