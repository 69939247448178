import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { createBrowserHistory } from 'history';
import RouterRender from './router'
import 'styles/custom.scss'
import 'antd/dist/antd.css'
import './App.scss'

const history = createBrowserHistory();

function App() {

  return (
    <Router history={history}>
      <RouterRender />
    </Router>
  )

}

export default App
