/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Button, Spin, Popconfirm, Checkbox } from 'antd'
import { SaveOutlined, ClearOutlined } from '@ant-design/icons'

import InlineInput from 'components/InlineInput'
import DiscountInput from 'components/InlineInput/DiscountInput'
import SearchProductVatPopUp from 'components/SearchProductVatPopUp'
import { useQuery, handleAlert } from 'utils'
import { useSelector, useDispatch } from 'react-redux'

import {
  onGetProductVatByCode,
  onSaveProductVat,
  onGetUnitList,
} from 'redux/actions'
const DEFAULT_PRODUCT = {
  id: 0,
  code: '',
  vat_name: '',
  // normal_name: '',
  package_size: '',
  stock1: '0',
  unit_id: null,
  price_in_1: '0',
  price_out: '0',
  discount_1: '',
  discount_2: '',
  discount_3: '',
  discount_4: '',
  is_allow_low: false,
  remark: '',
  relationship_group: null,
}

const ProductVatPage = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [product, setProduct] = useState(DEFAULT_PRODUCT)
  const getUnitList = useSelector((state) => state.getUnitList)
  const [isShowProductPopUp, setIsShowProductPopUp] = useState(false)
  const [unitList, setUnitList] = useState([])
  const saveProductVat = useSelector((state) => state.saveProductVat)
  const getProductVatByCode = useSelector((state) => state.getProductVatByCode)
  const dispatch = useDispatch()
  const query = useQuery()
  const code = query.get('code')

  useEffect(() => {
    if (code) {
      dispatch(onGetProductVatByCode(code))
    }
  }, [code, props.history])

  useEffect(() => {
    dispatch(onGetUnitList())
    document.title = 'ข้อมูลสินค้า [VAT]'
  }, [])

  useEffect(() => {
    const { loading, finished, error, data, trace } = getUnitList
    setIsLoading(loading)
    if (!loading && !error && finished && data) {
      setUnitList(data)
    }
    if (error && !loading) {
      handleAlert('error', trace.message || '')
    }
  }, [getUnitList])

  useEffect(() => {
    const { loading, finished, error, data, trace } = getProductVatByCode
    setIsLoading(loading)
    if (!error && !loading && finished && data) {
      setProduct(data)
      handleAlert('success')
    }
    if (error && !loading) {
      setProduct((prev) => ({
        ...DEFAULT_PRODUCT,
        code: prev.code,
      }))
      handleAlert('custom', trace.message || '')
    }
  }, [getProductVatByCode])

  useEffect(() => {
    const { loading, finished, error, data, trace } = saveProductVat
    setIsLoading(loading)
    if (!loading && !error && finished) {
      setProduct(data)
      handleAlert('success')
    }
    if (!loading && error) {
      handleAlert('error', trace.message || '')
    }
  }, [saveProductVat])

  const onSaveProductHandler = () => {
    dispatch(onSaveProductVat(product))
  }

  // const onSearchCode = (code) => {
  //   dispatch(onGetProductVatByCode(code))
  // }

  const onSearchCode = (value) => {
    if (value !== '') {
      dispatch(onGetProductVatByCode(value))
    } else {
      setIsShowProductPopUp(true)
    }
  }

  const onCancelProdutPopUp = () => {
    setIsShowProductPopUp(false)
  }

  const onSelectProduct = (data) => {
    onSearchCode(data.code)
    setIsShowProductPopUp(false)
  }

  const onClearInput = () => {
    setProduct(DEFAULT_PRODUCT)
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'inline-block' }}>
          <h1>ข้อมูลสินค้า [VAT]</h1>
        </div>
        <div style={{ display: 'inline-block' }}>
          <Popconfirm
            title="คุณต้องการล้างหน้าจอใช่หรือไม่ ?"
            okText="ใช่"
            cancelText="ยกเลิก"
            onConfirm={onClearInput}
          >
            <Button
              type="default"
              danger
              icon={<ClearOutlined />}
              size="large"
              block
            >
              ล้างหน้าจอ
            </Button>
          </Popconfirm>
        </div>
      </div>
      <SearchProductVatPopUp
        visible={isShowProductPopUp}
        onCancel={onCancelProdutPopUp}
        onClick={onSelectProduct}
      />
      <Spin spinning={isLoading}>
        <div className="form-wrapper-vat">
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                onSearch={(value) => {
                  onSearchCode(value)
                }}
                inputOption="search"
                label="รหัสสินค้า"
                isLoading={isLoading}
                value={product.code}
                onChange={(event) => {
                  setProduct({
                    ...product,
                    code: event.target.value.toUpperCase(),
                  })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ชื่อสินค้า VAT"
                width="300px"
                value={product.vat_name}
                required
                onChange={(event) =>
                  setProduct({ ...product, vat_name: event.target.value })
                }
              />
            </div>
            {/* <div className="input-item">
              <InlineInput
                label="ชื่อสินค้าปกติ"
                width="300px"
                value={product.normal_name}
                disabled
                onChange={(event) =>
                  setProduct({ ...product, normal_name: event.target.value })
                }
              />
            </div> */}
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="ขนาดบรรจุ"
                value={product.package_size}
                onChange={(event) =>
                  setProduct({ ...product, package_size: event.target.value })
                }
                width="120px"
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="จำนวนในสต็อก"
                value={product.stock1}
                onChange={(event) =>
                  setProduct({ ...product, stock1: event.target.value })
                }
                width="100px"
              />
            </div>
            <div className="input-item pr-2">
              <InlineInput
                label="หน่วยนับ"
                value={product.unit_id}
                onChange={(value) => {
                  setProduct({ ...product, unit_id: value })
                }}
                width="120px"
                inputOption="select_input"
                options={unitList}
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="ราคาเข้า"
                value={product.price_in_1}
                onChange={(event) =>
                  setProduct({ ...product, price_in_1: event.target.value })
                }
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ราคาขาย"
                value={product.price_out}
                onChange={(event) =>
                  setProduct({ ...product, price_out: event.target.value })
                }
              />
            </div>
            <div className="input-item">
              <DiscountInput
                label="ส่วนลด"
                name1="discount_1"
                name2="discount_2"
                name3="discount_3"
                name4="discount_4"
                value1={product.discount_1}
                value2={product.discount_2}
                value3={product.discount_3}
                value4={product.discount_4}
                onChange={(value, name) =>
                  setProduct({ ...product, [name]: value })
                }
                width="150px"
              />
            </div>
            <div className="input-item" style={{ paddingTop: '5px' }}>
              <Checkbox
                checked={product.is_allow_low}
                onChange={(event) =>
                  setProduct({ ...product, is_allow_low: event.target.checked })
                }
              >
                อนุญาตให้เปิดบิลต่ำกว่าทุน
              </Checkbox>
            </div>
            <div className="input-item" style={{ marginLeft: 'auto' }}>
              <Button
                type="primary"
                icon={<SaveOutlined />}
                size="large"
                style={{ height: '70px', width: '150px' }}
                onClick={onSaveProductHandler}
              >
                บันทึก
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  )
}

export default withRouter(ProductVatPage)
