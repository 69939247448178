import React from 'react'
import { addCommas } from 'utils'

const StockLowPDF = (props) => {
  const { listStockLow } = props
  const renderPageBreak = () => {
    return (
      <>
        <div style={{ marginTop: '10px' }}>
          <span className="page-break-before" />
          <span className="page-break-after" />
        </div>
        <div
          style={{ paddingTop: '30px', borderBottom: '1px solid #000000' }}
        ></div>
      </>
    )
  }

  return (
    <div>
      <div style={{ fontSize: '13px', color: '#000000cc' }}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            border: '1px solid #000000',
            fontWeight: '500',
          }}
        >
          <div style={{ display: 'inline-block', width: '8%' }}>รหัส</div>
          <div style={{ display: 'inline-block', width: '36%' }}>
            ชื่อสินค้า
          </div>
          <div style={{ textAlign:'right', display: 'inline-block', width: '10%' }}>สต๊อก 1</div>
          <div style={{ textAlign:'right', display: 'inline-block', width: '10%' }}>สต๊อก 2</div>
          <div style={{ textAlign:'right', display: 'inline-block', width: '10%' }}>สต๊อกรวม</div>
          <div style={{ paddingLeft: '5px', display: 'inline-block', width: '8%' }}>หน่วย</div>
          <div style={{ display: 'inline-block', width: '12%' }}>หมายเหตุ</div>
        </div>
        {listStockLow.map((row, i) => {
          return (
            <>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  borderBottom: '1px solid #5c5c5c',
                }}
              >
                <div style={{ display: 'inline-block', width: '8%' }}>
                  {row.code}
                </div>
                <div style={{ display: 'inline-block', width: '36%' }}>
                  {row.name}
                </div>
                <div style={{ textAlign:'right',  display: 'inline-block', width: '10%' }}>
                  {addCommas(row.stock1)}
                </div>
                <div style={{ textAlign:'right', display: 'inline-block', width: '10%' }}>
                  {addCommas(row.stock2)}
                </div>
                <div style={{ textAlign:'right', display: 'inline-block', width: '10%' }}>
                  {addCommas(row.stock_total)}
                </div>
                <div style={{ paddingLeft: '5px', display: 'inline-block', width: '8%' }}>
                  {row.unit_name}
                </div>
                <div style={{ display: 'inline-block', width: '12%' }}>
                  &nbsp;
                </div>
              </div>
              {i !== 0 && i % 45 === 0 ? renderPageBreak() : ''}
            </>
          )
        })}
      </div>
    </div>
  )
}

export default StockLowPDF
