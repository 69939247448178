import { request } from './request'

export const listCashes = (params) =>
  request({
    url: `/api/cash_check/cashes`,
    method: 'get',
    params: params ?? null,
  })

export const addCashes = (data) =>
  request({
    url: `/api/cash_check/cashes`,
    method: 'post',
    data,
  })

export const deleteCashes = (id) =>
  request({
    url: `/api/cash_check/cashes/${id}`,
    method: 'delete',
  })

export const listBillVatCashes = (params) =>
  request({
    url: `/api/cash_check/close/bills_vat`,
    method: 'get',
    params: params ?? null,
  })

export const addBillVatCashes = (data) =>
  request({
    url: `/api/cash_check/close/bills_vat`,
    method: 'post',
    data,
  })

export const deleteBillVatCashes = (id) =>
  request({
    url: `/api/cash_check/close/bills_vat/${id}`,
    method: 'delete',
  })

export const listAmends = (params) =>
  request({
    url: `/api/cash_check/close/amends`,
    method: 'get',
    params: params ?? null,
  })

export const addAmends = (data) =>
  request({
    url: `/api/cash_check/close/amends`,
    method: 'post',
    data,
  })

export const deleteAmends = (id) =>
  request({
    url: `/api/cash_check/close/amends/${id}`,
    method: 'delete',
  })

export const getBillPayment = (params) =>
  request({
    url: `/api/cash_check/bills`,
    method: 'get',
    params: params ?? null,
  })

export const addBillPayment = (data) =>
  request({
    url: `/api/cash_check/bills`,
    method: 'post',
    data,
  })

export const getCashCheckReport = (params) =>
  request({
    url: `/api/cash_check/report`,
    method: 'get',
    params: params ?? null,
  })