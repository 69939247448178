import React, { Component } from 'react'
import { Result } from 'antd'
import './style.scss'

export default class MainPage extends Component {
  componentDidMount() {
    document.title = 'Bonlighting Admin'
  }
  render() {
    return (
      <div className="main-page">
            <div>
      <Result
        status="success"
        title="ยินดีต้อนรับสู่ Bon Lightings System"
        subTitle={"กรุณาเลือกหน้าจาก เมนู ด้านบน"}
      />
    </div>
      </div>
    )
  }
}
