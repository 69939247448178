import { useEffect } from 'react'
import { listBillItemAdhoc } from 'api/customerBill'
import useAPI from 'hooks/useAPI'

const useRequestListPriceInPriceOutData = ({ month, profitMonth }) => {
  const {
    makeRequest: listItemAdhocRequest,
    data: listItemAdhocData,
    loading: listItemAdhocLoading,
  } = useAPI()

  useEffect(() => {
    listItemAdhocRequest(() =>
      listBillItemAdhoc({
        query: 'price_out_lt_price_in',
        month,
      })
    )
  }, [listItemAdhocRequest, month])

  const onSearchLossData = (month) => {
    listItemAdhocRequest(() =>
      listBillItemAdhoc({
        query: 'price_out_lt_price_in',
        month,
      })
    )
  }

  const {
    makeRequest: listItemProfitAdhocRequest,
    data: listItemProfitAdhocData,
    loading: listItemProfitAdhocLoading,
  } = useAPI()

  useEffect(() => {
    listItemProfitAdhocRequest(() =>
      listBillItemAdhoc({
        query: 'price_out_gt_price_in_30',
        month: profitMonth,
      })
    )
  }, [listItemProfitAdhocRequest, profitMonth])

  const onSearchProfitData = (profitMonth) => {
    listItemProfitAdhocRequest(() =>
      listBillItemAdhoc({
        query: 'price_out_gt_price_in_30',
        month: profitMonth,
      })
    )
  }

  return {
    listItemAdhocData,
    listItemAdhocLoading,
    listItemProfitAdhocData,
    listItemProfitAdhocLoading,
    onSearchLossData,
    onSearchProfitData,
  }
}

export default useRequestListPriceInPriceOutData
