import React from 'react'
import { Table } from 'antd'
import useEditPriceInDataContext from 'pages/EditPriceInPage/hooks/useEditPriceInDataContext'
import columns from './columns'
import './style.css'

const ListItemAdhocTableEditPriceIn = () => {
  const {
    listBillItemAdhocData,
    listBillItemAdhocLoading,
    onClickRow,
    selectedId,
  } = useEditPriceInDataContext()

  const getRowClassName = (record) => {
    if (record.id === selectedId) {
      return 'selected-row cursor-pointer'
    } else {
      return 'cursor-pointer'
    }
  }

  return (
    <div>
      <Table
        loading={listBillItemAdhocLoading}
        rowKey={(record) => `id+${record.id}`}
        dataSource={listBillItemAdhocData}
        columns={columns()}
        scroll={{ x: 1150 }}
        style={{
          maxWidth: '1200px',
        }}
        size="small"
        pagination={{
          simple: true,
          defaultPageSize: 20,
          position: ['bottomRight'],
        }}
        rowClassName={getRowClassName}
        onRow={(record) => {
          return {
            onClick: () => {
              onClickRow(record)
            },
          }
        }}
      />
    </div>
  )
}

export default ListItemAdhocTableEditPriceIn
