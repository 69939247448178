import React from 'react'
import { Table, Button } from 'antd'
import { PrinterOutlined } from '@ant-design/icons'

import { addCommas } from 'utils'

const ListSupplierBillByPaymentTable = (props) => {
  const { list, onClickRow } = props
  const onPrint = (record) => {
    window.open(
      `/no-vat/supplier-invoice/print?bill_no=${record.bill_no}&supplier_invoice_no=${record.supplier_invoice_no}&supplier_code=${record.supplier_code}`,
      '_blank'
    )
  }

  const tableColumns = [
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: '40px',
      fixed: 'left',
      render: (_, record) => {
        return (
          <Button
            type="primary"
            size="small"
            icon={<PrinterOutlined />}
            onClick={(_) => onPrint(record)}
            disabled={record.payment_status !== 'ชำระเงินแล้ว'}
          />
        )
      },
    },
    {
      title: 'เลขที่บิล',
      dataIndex: 'bill_no',
      key: 'bill_no',
      width: '100px',
      fixed: 'left',
      sorter: (a, b) => ('' + a.bill_no).localeCompare(b.bill_no),
      sortDirections: ['ascend', 'descend'],
      render: (_, record) => {
        if (record.supplier_invoice_no === '') {
          return (
            <div
              onClick={() => onClickRow(record)}
              style={{
                cursor: 'pointer',
                color: '#0000ff',
              }}
            >
              {record.bill_no}
            </div>
          )
        } else {
          return <div>{record.bill_no}</div>
        }
      },
    },
    {
      title: 'เลขที่ใบวางบิล',
      dataIndex: 'supplier_invoice_no',
      key: 'supplier_invoice_no',
      width: '100px',
      fixed: 'left',
      sorter: (a, b) =>
        ('' + a.supplier_invoice_no).localeCompare(b.supplier_invoice_no),
      sortDirections: ['ascend', 'descend'],
      render: (_, record) => {
        if (record.supplier_invoice_no !== '') {
          return (
            <div
              onClick={() => onClickRow(record)}
              style={{
                cursor: 'pointer',
                color: '#0000ff',
              }}
            >
              {record.supplier_invoice_no}
            </div>
          )
        } else {
          return <div>{record.supplier_invoice_no}</div>
        }
      },
    },
    {
      title: 'วันที่',
      dataIndex: 'date',
      key: 'date',
      width: '80px',
      fixed: 'left',
      sorter: (a, b) => ('' + a.date).localeCompare(b.date),
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'รหัสผู้ขาย',
      dataIndex: 'supplier_code',
      key: 'supplier_code',
      width: '80px',
    },
    {
      title: 'ชื่อผู้ขาย',
      dataIndex: 'supplier_name',
      key: 'supplier_name',
      width: '150px',
      render: (text) => <div className="break-word">{text}</div>,
    },
    {
      title: 'ยอดรวม',
      dataIndex: 'bill_total',
      key: 'bill_total',
      width: '90px',
      className: 'text-align-right',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
    },
    {
      title: 'สถานะ',
      dataIndex: 'payment_status',
      key: 'payment_status',
      width: '90px',
      sorter: (a, b) => ('' + a.payment_status).localeCompare(b.payment_status),
      sortDirections: ['ascend', 'descend'],
    },
  ]

  return (
    <div>
      <Table
        rowKey={(record) => `id+${record.product_code}`}
        dataSource={list}
        columns={tableColumns}
        pagination={false}
        scroll={{ x: 900, y: 580 }}
        size="small"
        style={{
          maxWidth: '950px',
          minHeight: '600px',
          backgroundColor: '#FFFFFF',
        }}
      />
    </div>
  )
}

export default ListSupplierBillByPaymentTable
