/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Col, Row, Button, Spin, Divider, Modal, Popconfirm } from 'antd'
import {
  SaveOutlined,
  ClearOutlined,
  SearchOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'

import SearchSupplierPopUp from 'components/SearchSupplierPopUp'
import PaymentInvoiceTable from 'components/PaymentInvoiceTable'
import ListSupplierBillByPaymentTable from 'components/ListSupplierBillByPaymentTable'
import InlineInput from 'components/InlineInput'
import {
  handleAlert,
  PAYMENT_CHANNEL,
  PAYMENT_STATUS,
  SEARCH_TIME_PERIOD,
} from 'utils'

import {
  fetchSearchSupplierCode,
  onGetPaymentDetailByBill,
  onSaveSupplierPaymentDetail,
  onCancelPayment,
  onListSupplierBillByPaymentStatus,
} from 'redux/actions'

const DEFAULT_FORM = {
  supplier_id: null,
  supplier_code: '',
  supplier_name: '',
  supplier_bill_id: null,
  supplier_bill_no: '',
  supplier_invoice_id: null,
  supplier_invoice_no: '',
  grand_total: '',
  remaining_unpaid_amount: '',
  payment_list: [],
}

const DEFAULT_PAYMENT = {
  id: 0,
  payment_channel_id: null,
  payment_channel_name: '',
  date: moment().format('YYYY-MM-DD'),
  amount: '',
  remark: '',
}

const DEFAULT_SEARCH = {
  supplier_code: '',
  payment_status_id: 4,
  payment_status: 'all',
  duration_id: 1,
  duration: 'three_month',
  list: [],
}

const PaymentSupplierInvoicePage = (props) => {
  function useAsyncState(initialValue) {
    const [value, setValue] = useState(initialValue)
    const setter = (x) =>
      new Promise((resolve) => {
        setValue(x)
        resolve(x)
      })
    return [value, setter]
  }
  const [form, setForm] = useAsyncState(DEFAULT_FORM)
  const [payment, setPayment] = useAsyncState(DEFAULT_PAYMENT)
  const [search, setSearch] = useAsyncState(DEFAULT_SEARCH)
  const [isLoading, setIsLoading] = useState(false)
  const [isShowSupplierPopUp, setIsShowSupplierPopUp] = useState(false)
  const dispatch = useDispatch()
  const searchSupplierCode = useSelector((state) => state.searchSupplierCode)
  let getPaymentDetailByBill = useSelector(
    (state) => state.getPaymentDetailByBill
  )
  let saveSupplierPaymentDetail = useSelector(
    (state) => state.saveSupplierPaymentDetail
  )
  let cancelPayment = useSelector((state) => state.cancelPayment)
  let listSupplierBillByPaymentStatus = useSelector(
    (state) => state.listSupplierBillByPaymentStatus
  )

  useEffect(() => {
    document.title = 'บันทึกการจ่ายบัญชีผู้ขาย - NoVat'
  }, [])

  useEffect(() => {
    const { trace, error, finished, data } = searchSupplierCode
    if (error) {
      handleAlert('custom', trace.message || '')
      setForm({
        ...form,
        supplier_id: null,
        supplier_name: '',
      })
      setSearch({
        ...search,
        supplier_code: '',
      })
      setIsLoading(false)
    }
    if (!error && data && finished) {
      setForm({
        ...form,
        supplier_id: data.id,
        supplier_code: data.code,
        supplier_name: data.name,
      })
      setSearch({
        ...search,
        supplier_code: data.code,
      })
      setIsLoading(false)
    }
  }, [searchSupplierCode]) // Only re-subscribe if props.searchSupplierCode changes

  useEffect(() => {
    const { trace, error, finished, data } = getPaymentDetailByBill
    if (error) {
      handleAlert('custom', trace.message || '')
      setForm({
        ...form,
        grand_total: '',
        remaining_unpaid_amount: '',
        payment_list: [],
      })
      setPayment(DEFAULT_PAYMENT)
      setIsLoading(false)
    }
    if (!error && data && finished) {
      setForm(data)
      setPayment(DEFAULT_PAYMENT)
      setIsLoading(false)
    }
  }, [getPaymentDetailByBill])

  useEffect(() => {
    const { trace, error, finished, data } = saveSupplierPaymentDetail
    if (error) {
      handleAlert('custom', trace.message || '')
      setIsLoading(false)
    }
    if (!error && data && finished) {
      Modal.success({
        centered: true,
        title: 'ทำรายการสำเร็จ',
        content: `บันทึกรายการชำระเงินเรียบร้อย`,
      })
      setForm(data).then(() => {
        dispatch(
          onListSupplierBillByPaymentStatus(
            search.supplier_code,
            search.payment_status,
            search.duration
          )
        )
      })
      setIsLoading(true)
    }
  }, [saveSupplierPaymentDetail])

  useEffect(() => {
    const { trace, error, finished, data } = cancelPayment
    if (error) {
      handleAlert('custom', trace.message || '')
      setIsLoading(false)
    }
    if (!error && data && finished) {
      Modal.success({
        centered: true,
        title: 'ทำรายการสำเร็จ',
        content: `ลบรายการชำระเงินทั้งหมดเรียบร้อยแล้ว`,
      })
      if (form.supplier_invoice_id !== '') {
        dispatch(
          onGetPaymentDetailByBill(
            '',
            form.supplier_invoice_no,
            form.supplier_code
          )
        )
      } else {
        dispatch(
          onGetPaymentDetailByBill(
            form.supplier_bill_no,
            '',
            form.supplier_code
          )
        )
      }
      dispatch(
        onListSupplierBillByPaymentStatus(
          search.supplier_code,
          search.payment_status,
          search.duration
        )
      )
    }
  }, [cancelPayment])

  useEffect(() => {
    const { trace, error, finished, data } = listSupplierBillByPaymentStatus
    if (error) {
      handleAlert('custom', trace.message || '')
      setIsLoading(false)
    }
    if (!error && data && finished) {
      setSearch({
        ...search,
        list: data,
      })
      setIsLoading(false)
    }
  }, [listSupplierBillByPaymentStatus])

  const onSearchSupplierCode = (code) => {
    if (code !== '') {
      setIsLoading(true)
      dispatch(fetchSearchSupplierCode(code))
    } else {
      setIsShowSupplierPopUp(true)
    }
  }

  const onSelectSupplierPopup = (data) => {
    dispatch(fetchSearchSupplierCode(data.code))
    setIsShowSupplierPopUp(false)
  }

  const onCancelSupplierPopUp = () => {
    setIsShowSupplierPopUp(false)
  }

  const onSearchSupplierBill = (value) => {
    if (form.supplier_id !== null) {
      setIsLoading(true)
      dispatch(onGetPaymentDetailByBill(value, '', form.supplier_code))
    } else {
      handleAlert('custom', 'กรุณาเลือกรหัสผู้ขายก่อน')
    }
  }

  const onSearchSupplierInvoice = (value) => {
    if (form.supplier_id !== null) {
      setIsLoading(true)
      dispatch(onGetPaymentDetailByBill('', value, form.supplier_code))
    } else {
      handleAlert('custom', 'กรุณาเลือกรหัสผู้ขายก่อน')
    }
  }

  const onChangePaymentChannel = (id) => {
    let paymentChannelName = ''
    PAYMENT_CHANNEL.forEach((x) => {
      if (x.id === id) {
        paymentChannelName = x.name
      }
    })
    if (id === 4) {
      let tmpAmont = form.remaining_unpaid_amount + ''
      let x = tmpAmont.split('.')
      setPayment({
        ...payment,
        payment_channel_id: id,
        payment_channel_name: paymentChannelName,
        amount: '0.' + x[1],
      })
    } else {
      setPayment({
        ...payment,
        payment_channel_id: id,
        payment_channel_name: paymentChannelName,
      })
    }
  }

  const onAddPayment = () => {
    let tmpPayment = {
      ...payment,
      amount: Number(payment.amount).toFixed(2),
    }
    let newRemainingAmount = 0
    if (payment.payment_channel_id === 6) {
      newRemainingAmount =
        Number(form.remaining_unpaid_amount) + Number(payment.amount)
    } else {
      newRemainingAmount =
        Number(form.remaining_unpaid_amount) - Number(payment.amount)
    }
    setForm({
      ...form,
      remaining_unpaid_amount: newRemainingAmount.toFixed(2),
      payment_list: [...form.payment_list, tmpPayment],
    }).then((_) => {
      setPayment(DEFAULT_PAYMENT)
    })
  }

  const onDeletePaymentItem = (index, item) => {
    let newRemainingAmount = 0
    if (item.payment_channel_id === 6) {
      newRemainingAmount =
        Number(form.remaining_unpaid_amount) - Number(item.amount)
    } else {
      newRemainingAmount =
        Number(form.remaining_unpaid_amount) + Number(item.amount)
    }
    const { payment_list } = form
    let tempList = [...payment_list]
    tempList.splice(index, 1)
    setForm({
      ...form,
      remaining_unpaid_amount: newRemainingAmount.toFixed(2),
      payment_list: tempList,
    })
  }

  const onSavePaymentDetail = () => {
    setIsLoading(true)
    dispatch(onSaveSupplierPaymentDetail(form))
  }

  const onDeletePayment = () => {
    setIsLoading(true)
    let body = {}
    if (form.supplier_invoice_id !== '') {
      body = {
        supplier_id: form.supplier_id,
        supplier_invoice_no: form.supplier_invoice_no,
        supplier_bill_no: '',
      }
    } else {
      body = {
        supplier_id: form.supplier_id,
        supplier_invoice_no: '',
        supplier_bill_no: form.supplier_bill_no,
      }
    }
    dispatch(onCancelPayment(body))
  }

  const onChangePaymentStatus = (status_id) => {
    let value = ''
    PAYMENT_STATUS.forEach((x) => {
      if (x.id === status_id) {
        value = x.value
      }
    })
    setSearch({
      ...search,
      payment_status_id: status_id,
      payment_status: value,
    })
  }

  const onChangeDuration = (duration_id) => {
    let value = ''
    SEARCH_TIME_PERIOD.forEach((x) => {
      if (x.id === duration_id) {
        value = x.value
      }
    })
    setSearch({
      ...search,
      duration_id: duration_id,
      duration: value,
    })
  }

  const onSearchList = () => {
    setIsLoading(true)
    dispatch(
      onListSupplierBillByPaymentStatus(
        search.supplier_code,
        search.payment_status,
        search.duration
      )
    )
  }

  const onClickRow = (record) => {
    setIsLoading(true)
    if (record.supplier_invoice_no !== '') {
      dispatch(
        onGetPaymentDetailByBill(
          '',
          record.supplier_invoice_no,
          record.supplier_code
        )
      )
    } else {
      dispatch(onGetPaymentDetailByBill(record.bill_no, '', form.supplier_code))
    }
  }

  const onClearInput = () => {
    setForm(DEFAULT_FORM)
    setPayment(DEFAULT_PAYMENT)
    setSearch(DEFAULT_SEARCH)
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'inline-block' }}>
          <h1>บันทึกการจ่ายบัญชีผู้ขาย</h1>
        </div>
        <div style={{ display: 'inline-block' }}>
          <Popconfirm
            title="คุณต้องการล้างหน้าจอใช่หรือไม่ ?"
            okText="ใช่"
            cancelText="ยกเลิก"
            onConfirm={onClearInput}
          >
            <Button
              type="default"
              danger
              icon={<ClearOutlined />}
              size="large"
              block
            >
              ล้างหน้าจอ
            </Button>
          </Popconfirm>
        </div>
      </div>
      <SearchSupplierPopUp
        visible={isShowSupplierPopUp}
        onCancel={onCancelSupplierPopUp}
        onClick={onSelectSupplierPopup}
      />
      <Spin spinning={isLoading}>
        <div className="form-wrapper">
          <Row style={{ width: '100%' }} gutter={[32, 16]}>
            <Col lg={{ span: 24 }} xl={{ span: 11 }}>
              <div className="input-wrapper">
                <div className="input-item">
                  <InlineInput
                    onSearch={(value) => {
                      onSearchSupplierCode(value)
                    }}
                    inputOption="search"
                    label="รหัสผู้ขาย"
                    placeholder="รหัสผู้ขาย"
                    width="170px"
                    isLoading={false}
                    required={true}
                    value={form.supplier_code}
                    onChange={(event) => {
                      setForm({
                        ...DEFAULT_FORM,
                        supplier_code: event.target.value.toUpperCase(),
                      })
                      setPayment(DEFAULT_PAYMENT)
                      setSearch(DEFAULT_SEARCH)
                    }}
                  />
                </div>
                <div className="input-item">
                  <InlineInput
                    label="ชื่อผู้ขาย"
                    width="330px"
                    value={form.supplier_name}
                    disabled={true}
                  />
                </div>
                <Divider style={{ margin: '0px 0px 10px' }} />
              </div>
              <div className="input-wrapper">
                <div className="input-item">
                  <InlineInput
                    onSearch={(value) => {
                      onSearchSupplierBill(value)
                    }}
                    inputOption="search"
                    label="เลขที่บิล"
                    placeholder="เลขที่บิล"
                    disabled={form.supplier_invoice_no !== ''}
                    isLoading={false}
                    value={form.supplier_bill_no}
                    onChange={(event) => {
                      setForm({
                        ...form,
                        supplier_bill_no: event.target.value.toUpperCase(),
                      })
                    }}
                  />
                </div>
                <div className="input-item">
                  <InlineInput
                    onSearch={(value) => {
                      onSearchSupplierInvoice(value)
                    }}
                    inputOption="search"
                    label="เลขที่ใบวางบิล"
                    placeholder="เลขที่ใบวางบิล"
                    disabled={form.supplier_bill_no !== ''}
                    isLoading={false}
                    value={form.supplier_invoice_no}
                    onChange={(event) => {
                      setForm({
                        ...form,
                        supplier_invoice_no: event.target.value.toUpperCase(),
                      })
                    }}
                  />
                </div>
              </div>
              <div className="input-wrapper">
                <div className="input-item">
                  <InlineInput
                    label="ยอดรวม"
                    width="160px"
                    className="text-align-right"
                    value={form.grand_total}
                    disabled={true}
                  />
                </div>
                <div className="input-item">
                  <InlineInput
                    label="ยอดค้างชำระ"
                    width="160px"
                    className="text-align-right"
                    value={form.remaining_unpaid_amount}
                    disabled={true}
                  />
                </div>
                <div className="input-item" style={{ marginTop: '5px' }}>
                  <strong>สถานะ: {form.payment_status}</strong>
                </div>
              </div>
              <Divider style={{ margin: '0px 0px 10px' }} />
              <div className="input-wrapper">
                <div className="input-item">
                  <InlineInput
                    label="ช่องทางการชำระ"
                    placeholder="เลือกช่องทาง"
                    required
                    value={payment.payment_channel_id}
                    onChange={(id) => {
                      onChangePaymentChannel(id)
                    }}
                    width="150px"
                    inputOption="select_input"
                    options={PAYMENT_CHANNEL}
                  />
                </div>
                <div className="input-item">
                  <InlineInput
                    label="วันที่ชำระ"
                    placeholder="กรุณาเลือกวันที่"
                    required
                    defaultValue={moment()}
                    value={payment.date && moment(payment.date)}
                    inputOption="date_picker"
                    onChange={(_, dateString) => {
                      setPayment({ ...payment, date: dateString })
                    }}
                  />
                </div>
              </div>
              <div className="input-wrapper">
                <div className="input-item">
                  <InlineInput
                    label="จำนวนเงิน"
                    width="120px"
                    required
                    value={payment.amount}
                    onChange={(event) => {
                      if (
                        form.remaining_unpaid_amount !== '' &&
                        Number(event.target.value) >
                          Number(form.remaining_unpaid_amount)
                      ) {
                        setPayment({
                          ...payment,
                          amount: form.remaining_unpaid_amount,
                        })
                      } else {
                        setPayment({ ...payment, amount: event.target.value })
                      }
                    }}
                  />
                </div>
                <div className="input-item">
                  <InlineInput
                    label="หมายเหตุ"
                    width="400px"
                    value={payment.remark}
                    onChange={(event) => {
                      setPayment({ ...payment, remark: event.target.value })
                    }}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'end', paddingBottom: '10px' }}>
                <div style={{ display: 'inline-block' }}>
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    size="medium"
                    onClick={onAddPayment}
                    style={{ width: '184px' }}
                    disabled={
                      form.supplier_invoice_id === null ||
                      form.supplier_bill_id === null ||
                      Number(form.remaining_unpaid_amount) === 0 ||
                      payment.payment_channel_id === null ||
                      payment.date === '' ||
                      Number(payment.amount) === 0
                    }
                  >
                    เพิ่มรายการ
                  </Button>
                </div>
              </div>
              <Divider style={{ margin: '0px 0px 10px' }} />
              <PaymentInvoiceTable
                list={form.payment_list}
                onDeletePaymentItem={onDeletePaymentItem}
              />
              <div style={{ display: 'flex', paddingTop: '10px' }}>
                <div style={{ display: 'inline-block', width: '515px' }}>
                  <Popconfirm
                    title="คุณต้องการลบรายการใช่หรือไม่ ?"
                    okText="ใช่"
                    cancelText="ยกเลิก"
                    onConfirm={onDeletePayment}
                  >
                    <Button
                      type="danger"
                      icon={<DeleteOutlined />}
                      size="medium"
                      // onClick={onDeletePayment}
                      style={{ width: '220px', height: '50px' }}
                      disabled={
                        form.supplier_invoice_id === null ||
                        form.supplier_bill_id === null
                      }
                    >
                      ลบรายการชำระเงินทั้งหมด
                    </Button>
                  </Popconfirm>
                </div>
                <div style={{ display: 'inline-block' }}>
                  <Button
                    type="primary"
                    icon={<SaveOutlined />}
                    size="medium"
                    onClick={onSavePaymentDetail}
                    style={{ width: '184px', height: '50px' }}
                    disabled={
                      form.supplier_invoice_id === null ||
                      form.supplier_bill_id === null
                    }
                  >
                    บันทึกรายการ
                  </Button>
                </div>
              </div>
            </Col>
            <Col lg={{ span: 24 }} xl={{ span: 13 }}>
              <h2>ค้นหาการจ่ายบัญชีผู้ขาย</h2>
              <div className="input-wrapper">
                <div className="input-item">
                  <InlineInput
                    label="ประเภทการชำระ"
                    placeholder="เลือกช่องทาง"
                    required
                    value={search.payment_status_id}
                    onChange={(id) => {
                      onChangePaymentStatus(id)
                    }}
                    width="150px"
                    inputOption="select_input"
                    options={PAYMENT_STATUS}
                  />
                </div>
                <div className="input-item">
                  <InlineInput
                    label="ช่วงเวลา"
                    placeholder="เลือกช่องทาง"
                    required
                    value={search.duration_id}
                    onChange={(id) => {
                      onChangeDuration(id)
                    }}
                    width="150px"
                    inputOption="select_input"
                    options={SEARCH_TIME_PERIOD}
                  />
                </div>
                <div className="input-item">
                  <Button
                    type="primary"
                    icon={<SearchOutlined />}
                    size="medium"
                    onClick={onSearchList}
                    style={{ width: '150px' }}
                    disabled={
                      search.supplier_code === '' ||
                      search.duration === '' ||
                      search.payment_status === ''
                    }
                  >
                    ค้นหา
                  </Button>
                </div>
                <div style={{ display: 'inline-block' }}>
                  <Button
                    type="default"
                    icon={<ClearOutlined />}
                    style={{ width: '120px', marginLeft: '100px' }}
                    onClick={() => {
                      setSearch({
                        ...search,
                        list: [],
                      })
                    }}
                    size="medium"
                  >
                    เคลียร์
                  </Button>
                </div>
              </div>
              <Divider style={{ margin: '10px 0px 20px' }} />
              <ListSupplierBillByPaymentTable
                list={search.list}
                onClickRow={onClickRow}
              />
            </Col>
          </Row>
        </div>
      </Spin>
    </div>
  )
}

export default withRouter(PaymentSupplierInvoicePage)
