import { AUTHORIZING, UNAUTHORIZED, AUTHORIZED } from '../actions'

const initialState = {
  is_login: false,
  username: '',
  first_name: '',
  last_name: '',
  error: null,
  isActive: '',
  email: '',
  is_loading: false,
}

const authenticateReducer = (state = initialState, action) => {
  const { type, payload, error } = action

  switch (type) {
    case AUTHORIZING: {
      return {
        ...state,
        ...payload,
        is_login: false,
        is_loading: true,
      }
    }
    case AUTHORIZED:
      return {
        ...state,
        ...payload,
        is_loading: false,
        is_login: true,
      }
    case UNAUTHORIZED: {
      return {
        ...initialState,
        error,
        is_loading: false,
        is_login: false,
      }
    }

    default: {
      return state
    }
  }
}

export default authenticateReducer
