/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Modal, Table, Button, Spin } from 'antd'
import { SelectOutlined } from '@ant-design/icons'
import { useSelector, useDispatch } from 'react-redux'

import { onListCustomerByName } from 'redux/actions'

import InlineInput from 'components/InlineInput'

const DEFAULT_FORM = {
  name: '',
  tel: '',
}

const SearchCustomerPopUp = ({ visible, onClick, onCancel }) => {
  const [list, setList] = useState([])
  const [form, setForm] = useState(DEFAULT_FORM)
  const [isLoading, setIsLoading] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [filteredList, setFilteredList] = useState([])
  const dispatch = useDispatch()
  let listCustomerByName = useSelector((state) => state.listCustomerByName)

  useEffect(() => {
    const { error, loading, finished, data, trace } = listCustomerByName
    if (!error && !loading && finished) {
      setList(data)
      setFilteredList(data)
      setIsLoading(false)
    }
    if (error && !loading) {
      Modal.error({
        centered: true,
        title: 'ตรวจสอบ',
        content: `${trace.message}`,
      })
      setIsLoading(false)
    }
  }, [listCustomerByName])

  const handleSearch = (searchValue) => {
    const _list = [...list]
    const filteredData = _list.filter(({ code, name }) => {
      code = code.toString().toLowerCase()
      name = name.toString().toLowerCase()
      return (
        code.includes(searchValue.toLowerCase()) ||
        name.includes(searchValue.toLowerCase())
      )
    })
    setFilteredList(filteredData)
  }

  const fetchList = () => {
    setIsLoading(true)
    dispatch(onListCustomerByName(form.name, form.tel))
  }

  const onClosePopUp = () => {
    clearInput()
    onCancel()
  }

  const onSelectItem = (record) => {
    onClick(record)
    clearInput()
  }

  const clearInput = () => {
    setList([])
    setFilteredList([])
    setSearchText('')
    setForm(DEFAULT_FORM)
  }

  const tableColumns = [
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: '30px',
      render: (_, record) => {
        return (
          <Button
            size="small"
            type="primary"
            icon={<SelectOutlined />}
            onClick={() => onSelectItem(record)}
          />
        )
      },
    },
    {
      title: 'รหัส',
      dataIndex: 'code',
      key: 'code',
      width: '80px',
    },
    {
      title: 'ชื่อ',
      dataIndex: 'name',
      key: 'name',
      width: '380px',
      render: (text) => <div className="break-word">{text}</div>,
    },
    {
      title: 'เบอร์โทร',
      dataIndex: 'tel',
      key: 'tel',
      width: '220px',
      render: (text) => <div className="break-word">{text}</div>,
    },
  ]

  return (
    <Modal
      visible={visible}
      width="800px"
      onCancel={onClosePopUp}
      footer={null}
      title={
        <div style={{ display: 'flex' }}>
          <div style={{ display: 'inline-block' }}>
            <InlineInput
              onSearch={() => {
                fetchList()
              }}
              inputOption="search"
              label="ค้นหา"
              placeholder="ใส่ชื่อลูกค้า"
              value={form.name}
              width="250px"
              onChange={(event) => {
                setForm({ name: event.target.value.toUpperCase(), tel: '' })
              }}
            />
          </div>
          <div style={{ display: 'inline-block' }}>
            <InlineInput
              onSearch={(value) => {
                fetchList(value)
              }}
              inputOption="search"
              placeholder="เบอร์โทร"
              disableColon
              value={form.tel}
              width="250px"
              onChange={(event) => {
                setForm({ tel: event.target.value.toUpperCase(), name: '' })
              }}
            />
          </div>
        </div>
      }
    >
      <Spin spinning={isLoading}>
        <Table
          rowKey={(record) => `id+${record.code}`}
          dataSource={filteredList}
          columns={tableColumns}
          style={{ height: '440px' }}
          scroll={{ y: 360 }}
          size="small"
          pagination={{
            position: ['bottomRight'],
            simple: true,
            defaultPageSize: 10,
          }}
        />
        <div style={{ position: 'absolute', bottom: '-8px' }}>
          <div className="mb-1">
            <InlineInput
              onSearch={(value) => {
                handleSearch(value)
              }}
              inputOption="search"
              label="กรองข้อมูล"
              placeholder="ใส่รหัส หรือ ชื่อ"
              value={searchText}
              width="280px"
              onChange={(event) => {
                setSearchText(event.target.value.toUpperCase())
              }}
            />
          </div>
        </div>
      </Spin>
    </Modal>
  )
}

export default SearchCustomerPopUp
