import { useEffect } from 'react'
import {
  listCashes,
  addCashes,
  listBillVatCashes,
  addBillVatCashes,
  deleteBillVatCashes,
  addAmends,
  listAmends,
  deleteAmends,
} from 'api/cashCheck'
import useAPI from 'hooks/useAPI'
import { handleAlert } from 'utils'

const useRequestCloseStoreData = ({
  date,
  onResetBillVatForm,
  onResetAmendsForm,
}) => {
  const {
    makeRequest: listCashesRequest,
    data: listCashesData,
    loading: listCashesLoading,
  } = useAPI()

  const {
    makeRequest: addCashesRequest,
    data: addCashesData,
    loading: addCashesLoading,
  } = useAPI()

  const {
    makeRequest: listBillVatCashesRequest,
    data: listBillVatCashesData,
    loading: listBillVatCashesLoading,
  } = useAPI()

  const {
    makeRequest: addBillVatCashesRequest,
    loading: addBillVatCashesLoading,
  } = useAPI()

  const {
    makeRequest: deleteBillVatCashesRequest,
    loading: deleteBillVatCashesLoading,
  } = useAPI()

  const {
    makeRequest: listAmendsRequest,
    data: listAmendsData,
    loading: listAmendsLoading,
  } = useAPI()

  const { makeRequest: addAmendsRequest, loading: addAmendsLoading } = useAPI()

  const { makeRequest: deleteAmendsRequest, loading: deleteAmendsLoading } =
    useAPI()

  useEffect(() => {
    listCashesRequest(() =>
      listCashes({
        date: date,
        record_type: 'close',
      })
    )
    listBillVatCashesRequest(() =>
      listBillVatCashes({
        date: date,
      })
    )
    listAmendsRequest(() =>
      listAmends({
        date: date,
      })
    )
  }, [listCashesRequest, listBillVatCashesRequest, listAmendsRequest, date])

  // NOTE: async await style
  const onAddCash = async (body) => {
    const res = await addCashesRequest(() => addCashes(body))
    if (res?.status === 200) {
      handleAlert('success')
    }
  }

  const onAddBillVatCash = async (body) => {
    await addBillVatCashesRequest(() => addBillVatCashes(body))
    await listBillVatCashesRequest(() =>
      listBillVatCashes({
        date: date,
      })
    )
    onResetBillVatForm()
  }

  const onDeleteBillVatCash = async (id) => {
    const res = await deleteBillVatCashesRequest(() => deleteBillVatCashes(id))
    if (res.status === 204) {
      await listBillVatCashesRequest(() =>
        listBillVatCashes({
          date: date,
        })
      )
    }
    onResetBillVatForm()
  }

  const onAddAmends = async (body) => {
    await addAmendsRequest(() => addAmends(body))
    await listAmendsRequest(() =>
      listAmends({
        date: date,
      })
    )
    onResetAmendsForm()
  }

  const onDeleteAmends = async (id) => {
    const res = await deleteAmendsRequest(() => deleteAmends(id))
    if (res.status === 204) {
      await listAmendsRequest(() =>
        listAmends({
          date: date,
        })
      )
    }
    onResetAmendsForm()
  }

  return {
    listCashesData,
    listCashesLoading,
    onAddCash,
    addCashesData,
    addCashesLoading,
    listBillVatCashesData,
    listBillVatCashesLoading,
    addBillVatCashesLoading,
    onAddBillVatCash,
    deleteBillVatCashesLoading,
    onDeleteBillVatCash,
    onAddAmends,
    onDeleteAmends,
    listAmendsData,
    listAmendsLoading,
    addAmendsLoading,
    deleteAmendsLoading,
  }
}

export default useRequestCloseStoreData
