/* eslint-disable import/no-cycle */
/* eslint-disable import/export */
import authAction from './authAction';
import createAction from './createAction'
export * from './authAction';
/**
 * @param  {Object} body - The Object keys values for application json.
 */

export const loginRequest = (body) => authAction('api/login', 'post', body,);
export const fetchPaymentDurationList = () => createAction('paymentDurationList', '/api/stock/payment_duration/list', null, 'get', null, response => response)
export const fetchUnitList = () => createAction('unitList', '/api/product/unit/list', null, 'get', null, response => response,)
export const fetchSearchSupplierCode = code => createAction('searchSupplierCode', '/api/supplier/item', { code }, 'get', null, response => response)
export const fetchSupplierBill = bill_no => createAction('supplierBill', "/api/stock/supplier_bill", {bill_no}, 'get', null, response => response)
export const onSaveStock = body => createAction('saveStock', "/api/stock/save", {}, 'post', body, response => response)
export const getProductWithLastestBoughtSupplier = params => createAction('searchProductCode', "/api/stock/product/latest_bought_supplier", params, 'get', null, response => response)
export const onDeleteSupplierBill = id => createAction('deleteSupplierBill', `/api/stock/supplier_bill/delete/${id}`, null, 'delete', null, response => response)
export const onMoveStock = body => createAction('moveStock', "/api/stock/move_stock", null, 'post', body, response => response)
export const onDeleteLatestBoughtSupplier = id => createAction('deleteLatestBoughtSupplier', `/api/stock/latest_bought_supplier/delete/${id}`, null, 'delete', null, response => response)
export const onSaveLatestBoughtSupplier = data => createAction('saveLatestBoughtSupplier', "/api/stock/latest_bought_supplier/add", null, 'post', data, response => response)
export const onListLatestBoughtSupplierByProductId = product_id => createAction("listLatestBoughtSupplierByProductId", "/api/stock/latest_bought_supplier/list", { product_id }, 'get', null, res => res)
export const onListMoveStock = () => createAction("listMoveStock", "/api/stock/move_stock/list", null, 'get', null, res => res)
export const onSaveSupplier = body => createAction("saveSupplier", "/api/supplier/save", null, 'post', body, res => res)
export const onListSupplier = () => createAction('listSupplier', "/api/supplier/list", null, 'get', null, res => res)
export const onDeleteSupplierContact = id => createAction('deleteSupplierContact', `/api/supplier/contact/delete/${id}`, null, 'delete', null, res => res)
export const onGetReasonList = () => createAction('getReasonList', '/api/temporary/stock/reason/list', null, 'get', null, res => res)
export const onListTemporaryStock = () => createAction('listTemporaryStock', '/api/temporary/stock/list', null, 'get', null, res => res)
export const onSaveTemporaryStock = body => createAction('saveTemporaryStock', '/api/temporary/stock/add', null, 'post', body, res => res)
export const onReturnTemporaryStock = id => createAction('returnTemporaryStock', `/api/temporary/stock/return/${id}`, null, 'post', null, res => res)
export const onSaveProductVat = body => createAction('saveProductVat', "/api/product_vat/save", null, 'post', body, res => res)
export const onGetProductVatByCode = code => createAction('getProductVatByCode', '/api/product_vat/item', { code }, 'get', null, res => res)
export const onDeleteProductVat = id => createAction('deleteProductVat', `/api/product_vat/delete/${id}`, null, 'delete', null, res => res)
export const onListProductVat = () => createAction('listProductVat', '/api/product_vat/list/all', null, 'get', null, res => res)
export const onSaveProduct = body => createAction('saveProduct', '/api/product/save', null, 'post', body, res => res)
export const onGetProductByCode = code => createAction('getProductByCode', '/api/product/item', { code } , 'get', null, res => res)
export const onListProduct = () => createAction('listProduct', `/api/product/list/all`, null, 'get', null, res => res)
export const onListProductWithWholesale = () => createAction('listProductWithWholesale', `/api/product/wholesale_quantity/list`, null, 'get', null, res => res)
export const onDeleteProduct = id => createAction('deleteProduct', `/api/product/delete/${id}`, null, 'delete', null, res => res)
export const onGetUnitList = () => createAction('getUnitList', '/api/product/unit/list', null, 'get', null, res => res)
export const onGetVatTypeList = () => createAction('getVatTypeList', '/api/product/vat_type/list', null, 'get', null, res => res)
export const onGetMovementTypeList = () => createAction('getMovementTypeList', `/api/product/movement_type/list`, null, 'get', null, res => res)
export const onGetPhysicalStockZoneList = () => createAction('getPhysicalStockZoneList', `/api/product/physical_stock_zone/list`, null, 'get', null, res => res)
export const onGetPhysicalStockShelfList = () => createAction('getPhysicalStockShelfList', '/api/product/physical_stock_shelf/list', null, 'get', null, res => res)
export const onListBillType = () => createAction('listBillType', '/api/customer_bill/bill_type/list', null, 'get', null, res => res)
export const onListSaleChannel = () => createAction('listSaleChannel', '/api/customer_bill/sale_channel/list', null, 'get', null, res => res)
export const onGetCustomerBill = bill_no => createAction('getCustomerBill', '/api/customer_bill/bill', { bill_no }, 'get', null, res => res)

/**
 * Assign the project to an employee.
 * @param {String} product_code - The product code responsible.
 * @param {string} customer_code - The code of the customer.
 * @param {string} bill_type_value - any.
 */
export const onGetLatestBoughtProductOfCustomer = (
  product_code,
  customer_code,
  bill_type
) => createAction('getLatestBoughtProductOfCustomer', '/api/customer_bill/product', {
  product_code, customer_code, bill_type,
}, 'get', null, res => res)

/**
 * @param  {Object} body - The Object keys values for application json.
 */
export const onSaveCustomerBill = (body) => createAction('saveCustomerBill', '/api/customer_bill/save', null, 'post', body, res => res)
export const onListBillRemark = () => createAction('listBillRemark', '/api/customer_bill/bill_remark/list', null, 'get', null, res => res)
/**
 * Assign the project to an employee.
 * @param {String} customer_code - The customer code responsible.
 * @param {string} bill_no - The code of the customer.
 */
export const onListCustomerBillByCustomerCode = customer_code => createAction('listCustomerBillByCustomerCode', '/api/customer_bill/list', { customer_code }, 'get', null, res => res)
export const onGetCustomerByCode = code => createAction('getCustomerByCode', '/api/customer/item', { code }, 'get', null, res => res)

/**
 * @param  {Object} body - The Object keys values for application json.
 */
export const onSaveCustomer = body => createAction('saveCustomer', '/api/customer/save', null, 'post', body, res => res)
export const onListCustomer = () => createAction('listCustomer', '/api/customer/list', null, 'get', null, res => res)
export const onGetCustomerType = () => createAction('getCustomerType', '/api/customer/type', null, 'get', null, res => res)

/**
 * @param  {String} body - The id of the customer contact.
 */
export const onDeleteCustomerContact = id => createAction('deleteCustomerContact', `/api/customer/contact/delete/${id}`, null, 'delete', null, res => res)
export const onGetLastCheckStock = () => createAction('getLastCheckStock', '/api/alter/stock/last_check_stock', null, 'get', null, res => res)
export const onListAlterStock = (product_code, start_date, end_date) => createAction('listAlterStock', '/api/alter/stock/list', {
  product_code,
  start_date,
  end_date,
}, 'get', null, res => res)

/**
 * @param  {Object} body - The Object keys values for application json.
 */
export const onSaveAlterStock = (body) => createAction('saveAlterStock', '/api/alter/stock/save', null, 'post', body, res => res)

/**
 * @param  {Object} body - The Object keys values for application json.
 */
export const onSaveLastCheckStockDate = body => createAction('saveLastCheckStockDate', '/api/alter/stock/last_check_stock/save', null, 'post', body, res => res)

/******************************************************************************************************/ 
/******************************************* Stock Vat API ********************************************/ 
/******************************************************************************************************/ 

export const onListStockVatType = () => createAction('listStockVatType', '/api/stock_vat/supplier_bill_vat_type/list', null, 'get', null, res => res)

/**
 * @param  {String} bill_no - bill number.
 */
export const onListSupplierBillVatByBillNo = bill_no => createAction('listSupplierBillVatByBillNo', '/api/stock_vat/supplier_bill_vat/bill_no/list', { bill_no } , 'get', null, res => res)

/**
 * @param  {String} supplier_code - code of supplier.
 */
export const onListSupplierBillVatBySupplierCode = code => createAction('listSupplierBillVatBySupplierCode', '/api/stock_vat/supplier_bill_vat/supplier_code/list', { code }, 'get', null , res => res)

/**
 * @param  {String} product_code - code of product vat.
 * @param  {String} supplier_code - code of supplier.
 */
export const onGetProductVatWithLatestBoughtSupplier = (
  product_code,
  supplier_code
) => createAction('productVatWithLatestBoughtSupplier', '/api/stock_vat/latest_bought_supplier', { product_code, supplier_code }, 'get', null, res => res)

/**
 * @param  {Object} body - The Object keys values for application json.
 */
export const onSaveStockVat = body => createAction('saveStockVat', '/api/stock_vat/save', null, 'post', body, res => res)

export const onDeleteStockVat = id => createAction('deleteStockVat', `/api/stock_vat/supplier_bill_vat/delete/${id}`, null, 'delete', null, res => res)

export const onGetSupplierBillVatByBillNo = bill_no => createAction('getSupplierBillVatByBillNo', '/api/stock_vat/supplier_bill_vat', {bill_no}, 'get', null, res => res)

/******************************************************************************************************/ 
/***************************************** CUSTOMER BILL VAT ******************************************/ 
/******************************************************************************************************/ 

export const onListBillVatType = () => createAction('listBillVatType', '/api/customer_bill_vat/bill_vat_type/list', null, 'get', null, res => res)

/**
 * @param  {String} bill_no - bill number.
 */
export const onGetCustomerBillVat = bill_no => createAction('getCustomerBillVat', `/api/customer_bill_vat/bill`, {bill_no}, 'get', null, res => res)

export const onListCustomerBillVat = (customer_vat_code, bill_vat_no) => createAction('listCustomerBillVat', '/api/customer_bill_vat/list', { customer_vat_code, bill_vat_no }, 'get', null , res => res)

/**
 * @param  {String} product_vat_code - code of product vat.
 * @param  {String} customer_vat_code - code of customer vat.
 */
export const onGetProductVatWithLatestBoughtCustomer = (
  product_vat_code,
  customer_vat_code
) => createAction('productVatWithLatestBoughtCustomer', '/api/customer_bill_vat/product_vat', { product_vat_code, customer_vat_code }, 'get', null, res => res)

/**
 * @param  {Object} body - The Object keys values for application json.
 */
export const onSaveCustomerBillVat = body => createAction('saveCustomerBillVat', '/api/customer_bill_vat/save', null, 'post', body, res => res)


/******************************************************************************************************/ 
/****************************************** CUSTOMER VAT **********************************************/ 
/******************************************************************************************************/ 

export const onGetCustomerVatByCode = code => createAction('getCustomerVatByCode', '/api/customer_vat/item', { code }, 'get', null, res => res)

/**
 * @param  {Object} body - The Object keys values for application json.
 */
export const onSaveCustomerVat = body => createAction('saveCustomerVat', '/api/customer_vat/save', null, 'post', body, res => res)
export const onListCustomerVat = () => createAction('listCustomerVat', '/api/customer_vat/list', null, 'get', null, res => res)
export const onDeleteCustomerVatContact = id => createAction('deleteCustomerVatContact', `/api/customer_vat/contact/delete/${id}`, null, 'delete', null, res => res)

/*****************************************************************************************************/ 
/****************************************** Supplier VAT **********************************************/ 
/******************************************************************************************************/ 

export const onGetSupplierVatByCode = code => createAction('getSupplierVatByCode', '/api/supplier_vat/item', { code }, 'get', null, res => res)

/**
 * @param  {Object} body - The Object keys values for application json.
 */
export const onSaveSupplierVat = body => createAction('saveSupplierVat', '/api/supplier_vat/save', null, 'post', body, res => res)
export const onListSupplierVat = () => createAction('listSupplierVat', '/api/supplier_vat/list', null, 'get', null, res => res)
export const onDeleteSupplierVatContact = id => createAction('deleteSupplierVatContact', `/api/supplier_vat/contact/delete/${id}`, null, 'delete', null, res => res)

/******************************************************************************************************/ 
/****************************************** PRODUCT GROUP *********************************************/ 
/******************************************************************************************************/

export const onGetProductGroup = id => createAction('getProductGroup', `/api/product_group/normal_vat_group/${id}`, null, 'get', null, res => res)
export const onListProductGroup = () => createAction('listProductGroup', '/api/product_group/normal_vat_group/list', null, 'get', null, res => res)
export const onListNoGroupProuctNormal = () => createAction('listNoGroupProductNormal', '/api/product_group/no_group_product_normal/list', null, 'get', null, res => res)
export const onListNoGroupProuctVat = () => createAction('listNoGroupProductVat', '/api/product_group/no_group_product_vat/list', null, 'get', null, res => res)
/**
 * @param  {Object} body - The Object keys values for application json.
 */
export const onSaveProductGroup = body => createAction('saveProductGroup', '/api/product_group/normal_vat_group/save', null, 'post', body, res => res)
/**
 * @param  {string} id - group id.
 */
export const onDeleteProductGroup = id => createAction('deleteProductGroup', `/api/product_group/normal_vat_group/delete/${id}`, null, 'delete', null, res => res)

/**
 * @param  {Object} body - The Object keys values for application json.
 */
export const onAddProductToGroup = body => createAction('addProductToGroup', '/api/product_group/normal_vat_map/add', null, 'post', body, res => res)
/**
 * @param  {string} id - member id in group.
 */
export const onDeleteProductFromGroup = id => createAction('deleteProductFromGroup', `/api/product_group/normal_vat_group/member/remove/${id}`, null, 'delete', null, res => res)

export const onListBillPerDay = (sale_channel_id, date) => createAction('listBillPerDay', '/api/customer_bill/bills/items/list', { sale_channel_id, date }, 'get', null , res => res)

/**
 * Pop Up
 */
 export const onListProductVatByName = name => createAction('listProductVatByName', `/api/product_vat/popup/list`, {name}, 'get', null, res => res)
 export const onListProductNoVatByName = name => createAction('listProductNoVatByName', `/api/product/popup/list`, {name}, 'get', null, res => res)
 export const onListSupplierVatByName = name => createAction('listSupplierVatByName', `/api/supplier_vat/popup/list`, {name}, 'get', null, res => res)
 export const onListCustomerVatByName = (name, tel) => createAction('listCustomerVatByName', `/api/customer_vat/popup/list`, {name, tel}, 'get', null, res => res)
 export const onListCustomerBillVatByCustomerName = (name, bill_type) => createAction('listCustomerBillVatByCustomerName', '/api/customer_bill_vat/customer_name/list/all', {name, bill_type}, 'get', null , res => res)
 export const onListSupplierBillVatBySupplierName = (name) => createAction('listSupplierBillVatBySupplierName', '/api/stock_vat/supplier_bill_vat/supplier_name/list/all', {name}, 'get', null , res => res)
 export const onListCustomerByName = (name, tel) => createAction('listCustomerByName', `/api/customer/popup/list`, {name, tel}, 'get', null, res => res)
 export const onListCustomerBillByCustomerName = (name) => createAction('listCustomerBillByCustomerName', '/api/customer_bill/customer_name/list/all', {name}, 'get', null , res => res)
 export const onListSupplierByName = name => createAction('listSupplierByName', `/api/supplier/popup/list`, {name}, 'get', null, res => res)
 export const onListSupplierBillBySupplierName = (name) => createAction('listSupplierBillBySupplierName', '/api/stock/supplier_bill/supplier_name/list/all', {name}, 'get', null , res => res)

 /**
 *  History
 */
  export const onListBillItemBySupplierAndProduct = (
    supplier_code,
    product_code,
    period
    ) => createAction('listBillItemBySupplierAndProduct', `/api/stock_vat/bill/items/list`, {
      supplier_code,
      product_code,
      period
    }, 'get', null, res => res)

  export const onListBillItemByCustomerAndProduct = (
    customer_code,
    product_code,
    period
    ) => createAction('listBillItemByCustomerAndProduct', `/api/customer_bill_vat/bill/items/list`, {
      customer_code,
      product_code,
      period
    }, 'get', null, res => res)

  export const onListBillItemBySupplierAndProductNoVat = (
    supplier_code,
    product_code,
    period
    ) => createAction('listBillItemBySupplierAndProductNoVat', `/api/stock/bill/supplier/product/items/list`, {
      supplier_code,
      product_code,
      period
    }, 'get', null, res => res)

  export const onListBillItemByCustomerAndProductNoVat = (
    customer_code,
    product_code,
    period
    ) => createAction('listBillItemByCustomerAndProductNoVat', `/api/customer_bill/bill/customer/product/items/list`, {
      customer_code,
      product_code,
      period
    }, 'get', null, res => res)

/////// Stock (No vat)
export const onListSupplierBillBySupplierCode = code => createAction('listSupplierBillBySupplierCode', `/api/stock/supplier_bill/supplier_code/list`, {code}, 'get', null, res => res)

/// -------------------- CREDIT NOTE --------------------------------///
export const onListNoteReason = () => createAction('listNoteReason', '/api/credit_debit_note/reason/list', null, 'get', null, res => res)
export const onSaveCustomerCreditNote = body => createAction('saveCustomerCreditNote', '/api/credit_debit_note/customer/save', null, 'post', body, res => res)
export const onGetCustomerCreditNote = doc_no => createAction('getCustomerCreditNote', `/api/credit_debit_note/customer`, {doc_no}, 'get', null, res => res)
export const onListNoteByCustomerName = (
  doc_type,
  customer_name,
  ) => createAction('listNoteByCustomerName', `/api/credit_debit_note/customer/list`, {
    doc_type,
    customer_name,
  }, 'get', null, res => res)

export const onSaveSupplierCreditNote = body => createAction('saveSupplierCreditNote', '/api/credit_debit_note/supplier/save', null, 'post', body, res => res)
export const onGetSupplierCreditNote = doc_no => createAction('getSupplierCreditNote', `/api/credit_debit_note/supplier`, {doc_no}, 'get', null, res => res)
export const onListNoteBySupplierName = (
  doc_type,
  supplier_name,
  ) => createAction('listNoteBySupplierName', `/api/credit_debit_note/supplier/list`, {
    doc_type,
    supplier_name,
  }, 'get', null, res => res)

export const onCancelCustomerNote = id => createAction('cancelCustomerNote', `/api/credit_debit_note/customer/cancel/${id}`, null, 'post', null, response => response)
export const onCancelSupplierNote = id => createAction('cancelSupplierNote', `/api/credit_debit_note/supplier/cancel/${id}`, null, 'post', null, response => response)

/// -------------------- CREDIT NOTE --------------------------------///
export const onListQOBillType = () => createAction('listQOBillType', '/api/quotation/bill_type/list', null, 'get', null, res => res)
export const onListQODurationUnit = () => createAction('listQODurationUnit', '/api/quotation/duration_unit/list', null, 'get', null, res => res)
export const onListQODocType = () => createAction('listQODocType', '/api/quotation/doc_type/list', null, 'get', null, res => res)
export const onListQOByCustomerName = (
  name,
  doc_type,
  ) => createAction('listQOByCustomerName', `/api/quotation/bill/customer_name/list`, {
    name,
    doc_type,
  }, 'get', null, res => res)
export const onGetQuotation = doc_no => createAction('getQuotation', `/api/quotation/bill`, {doc_no}, 'get', null, res => res)
export const onSaveQuotation = body => createAction('saveQuotation', '/api/quotation/save', null, 'post', body, res => res)

/// -------------------- DERIVERY ORDER --------------------------///
export const onListDeliveryOrderBillByCustomerName = (
  name,
  ) => createAction('listDeliveryOrderBillByCustomerName', `/api/delivery_order_vat/bill/customer_name/list`, {
    name,
  }, 'get', null, res => res)
  export const onGetDeliveryOrder = doc_no => createAction('getDeliveryOrder', `/api/delivery_order_vat/bill`, {doc_no}, 'get', null, res => res)
  export const onSaveDeliveryOrder = body => createAction('saveDeliveryOrder', '/api/delivery_order_vat/save', null, 'post', body, res => res)

/// -------------------- DEPOSIT RECEIPT --------------------------///
export const onListDepositReceiptBillByCustomerName = (
  name,
  ) => createAction('listDepositReceiptBillByCustomerName', '/api/deposit_receipt_vat/bill/customer_name/list', {
    name,
  }, 'get', null, res => res)
export const onGetDepositReceipt = doc_no => createAction('getDepositReceipt', '/api/deposit_receipt_vat/bill', {doc_no}, 'get', null, res => res)
export const onSaveDepositReceipt = body => createAction('saveDepositReceipt', '/api/deposit_receipt_vat/save', null, 'post', body, res => res)

  /// -------------------- Supplier Invoice + Payment --------------------------///
export const onListSupplierBillBySupplierAndDate = (
  supplier_code,
  start_date,
  end_date,
  ) => createAction('listSupplierBillBySupplierAndDate', '/api/supplier_invoice/supplier_bill/list', {
    supplier_code,
    start_date,
    end_date,
}, 'get', null, res => res)
export const onSaveSupplierInvoice = body => createAction('saveSupplierInvoice', '/api/supplier_invoice/save', null, 'post', body, res => res)
export const onListSupplierInvoiceBySupplierName = (
  supplier_name,
  ) => createAction('listSupplierInvoiceBySupplierName', '/api/supplier_invoice/bill/popup/list', {
    supplier_name,
}, 'get', null, res => res)
export const onCancelSupplierInvoice = id => createAction('cancelSupplierInvoice', `/api/supplier_invoice/bill/${id}/cancel`, null, 'delete', null, res => res)
export const onGetSupplierInvoiceBySupplier = (
  supplier_code,
  supplier_invoice_no,
  ) => createAction('getSupplierInvoiceBySupplier', '/api/supplier_invoice/bill', {
    supplier_code,
    supplier_invoice_no,
}, 'get', null, res => res)
export const onGetPaymentDetailByBill = (
  supplier_bill_no,
  supplier_invoice_no,
  supplier_code,
) => createAction('getPaymentDetailByBill', '/api/supplier_invoice/payment', {
  supplier_bill_no,
  supplier_invoice_no,
  supplier_code,
}, 'get', null, res => res)
export const onSaveSupplierPaymentDetail = body => createAction('saveSupplierPaymentDetail', '/api/supplier_invoice/payment/save', null, 'post', body, res => res)
export const onCancelPayment = body => createAction('cancelPayment', '/api/supplier_invoice/payment/cancel', null, 'post', body, res => res)
export const onListSupplierBillByPaymentStatus = (
  supplier_code,
  payment_status,
  duration,
) => createAction('listSupplierBillByPaymentStatus', '/api/supplier_invoice/supplier_payment/list', {
  supplier_code,
  payment_status,
  duration,
}, 'get', null, res => res)
export const onPrintSupplierInvoice = (
  supplier_bill_no,
  supplier_invoice_no,
  supplier_code,
) => createAction('printSupplierInvoice', '/api/supplier_invoice/print', {
  supplier_bill_no,
  supplier_invoice_no,
  supplier_code,
}, 'get', null, res => res)
export const onListUnpaidSupplierPayment = () => createAction('listUnpaidSupplierPayment', '/api/supplier_invoice/supplier_payment/unpaid/list', null, 'get', null, res => res)


/// -------------------- Supplier [VAT] Invoice + Payment --------------------------///
export const onListSupplierVatBillBySupplierAndDate = (
  supplier_code,
  start_date,
  end_date,
  ) => createAction('listSupplierVatBillBySupplierAndDate', '/api/supplier_vat_invoice/supplier_bill/list', {
    supplier_code,
    start_date,
    end_date,
}, 'get', null, res => res)
export const onSaveSupplierVatInvoice = body => createAction('saveSupplierVatInvoice', '/api/supplier_vat_invoice/save', null, 'post', body, res => res)
export const onListSupplierVatInvoiceBySupplierName = (
  supplier_name,
  ) => createAction('listSupplierVatInvoiceBySupplierName', '/api/supplier_vat_invoice/bill/popup/list', {
    supplier_name,
}, 'get', null, res => res)
export const onCancelSupplierVatInvoice = id => createAction('cancelSupplierVatInvoice', `/api/supplier_vat_invoice/bill/${id}/cancel`, null, 'delete', null, res => res)
export const onGetSupplierVatInvoiceBySupplier = (
  supplier_code,
  supplier_invoice_no,
  ) => createAction('getSupplierVatInvoiceBySupplier', '/api/supplier_vat_invoice/bill', {
    supplier_code,
    supplier_invoice_no,
}, 'get', null, res => res)
export const onGetPaymentDetailVatByBill = (
  supplier_bill_no,
  supplier_invoice_no,
  supplier_code,
) => createAction('getPaymentDetailVatByBill', '/api/supplier_vat_invoice/payment', {
  supplier_bill_no,
  supplier_invoice_no,
  supplier_code,
}, 'get', null, res => res)
export const onSaveSupplierVatPaymentDetail = body => createAction('saveSupplierVatPaymentDetail', '/api/supplier_vat_invoice/payment/save', null, 'post', body, res => res)
export const onCancelPaymentVat = body => createAction('cancelPaymentVat', '/api/supplier_vat_invoice/payment/cancel', null, 'post', body, res => res)
export const onListSupplierVatBillByPaymentStatus = (
  supplier_code,
  payment_status,
  duration,
) => createAction('listSupplierVatBillByPaymentStatus', '/api/supplier_vat_invoice/supplier_payment/list', {
  supplier_code,
  payment_status,
  duration,
}, 'get', null, res => res)
export const onPrintSupplierVatInvoice = (
  supplier_bill_no,
  supplier_invoice_no,
  supplier_code,
) => createAction('printSupplierVatInvoice', '/api/supplier_vat_invoice/print', {
  supplier_bill_no,
  supplier_invoice_no,
  supplier_code,
}, 'get', null, res => res)
export const onListUnpaidSupplierVatPayment = () => createAction('listUnpaidSupplierVatPayment', '/api/supplier_vat_invoice/supplier_payment/unpaid/list', null, 'get', null, res => res)

/// -------------------- Customer [VAT] Invoice + Payment --------------------------///
export const onListCustomerVatBillByCustomerAndDate = (
  customer_code,
  start_date,
  end_date,
  ) => createAction('listCustomerVatBillByCustomerAndDate', '/api/customer_vat_invoice/customer_bill/list', {
    customer_code,
    start_date,
    end_date,
}, 'get', null, res => res)
export const onSaveCustomerVatInvoice = body => createAction('saveCustomerVatInvoice', '/api/customer_vat_invoice/save', null, 'post', body, res => res)
export const onListCustomerVatInvoiceByCustomerName = (
  customer_name,
  ) => createAction('listCustomerVatInvoiceByCustomerName', '/api/customer_vat_invoice/bill/popup/list', {
    customer_name,
}, 'get', null, res => res)
export const onCancelCustomerVatInvoice = id => createAction('cancelCustomerVatInvoice', `/api/customer_vat_invoice/bill/${id}/cancel`, null, 'delete', null, res => res)
export const onGetCustomerVatInvoiceByCustomer = (
  customer_code,
  customer_invoice_no,
  ) => createAction('getCustomerVatInvoiceByCustomer', '/api/customer_vat_invoice/bill', {
    customer_code,
    customer_invoice_no,
}, 'get', null, res => res)
export const onGetCustomerPaymentDetailVatByBill = (
  customer_bill_no,
  customer_invoice_no,
  customer_code,
) => createAction('getCustomerPaymentDetailVatByBill', '/api/customer_vat_invoice/payment', {
  customer_bill_no,
  customer_invoice_no,
  customer_code,
}, 'get', null, res => res)
export const onSaveCustomerVatPaymentDetail = body => createAction('saveCustomerVatPaymentDetail', '/api/customer_vat_invoice/payment/save', null, 'post', body, res => res)
export const onCancelCustomerPaymentVat = body => createAction('cancelCustomerPaymentVat', '/api/customer_vat_invoice/payment/cancel', null, 'post', body, res => res)
export const onListCustomerVatBillByPaymentStatus = (
  customer_code,
  payment_status,
  duration,
) => createAction('listCustomerVatBillByPaymentStatus', '/api/customer_vat_invoice/customer_payment/list', {
  customer_code,
  payment_status,
  duration,
}, 'get', null, res => res)
export const onPrintCustomerVatInvoice = (
  customer_bill_no,
  customer_invoice_no,
  customer_code,
) => createAction('printCustomerVatInvoice', '/api/customer_vat_invoice/print', {
  customer_bill_no,
  customer_invoice_no,
  customer_code,
}, 'get', null, res => res)
export const onListCustomerVatBillByPaymentStatusAndMonth = (
  customer_code,
  payment_status,
  month,
) => createAction('listCustomerVatBillByPaymentStatusAndMonth', '/api/customer_vat_invoice/customer_payment/month/list', {
  customer_code,
  payment_status,
  month,
}, 'get', null, res => res)
export const onListUnpaidCustomerVatPayment = () => createAction('listUnpaidCustomerVatPayment', '/api/customer_vat_invoice/customer_payment/unpaid/list', null, 'get', null, res => res)


/// -------------------- Stock Low --------------------------///
export const onListProductWithLowStock = () => createAction('listProductWithLowStock', '/api/stock_low/products/list', null , 'get', null, res => res)
export const onListLastestBoughtProductFromSupplier = id => createAction('listLastestBoughtProductFromSupplier', `/api/stock_low/lastest_products/supplier/${id}/list`, null, 'get', null, res => res)


/// -------------------- Pricing Group --------------------------///
export const onListProductPriceGroup = () => createAction('listProductPriceGroup', '/api/product_group/product_price_group/list', null , 'get', null, res => res)
export const onListProductPriceGroupWithoutMember = () => createAction('listProductPriceGroupWithoutMember', '/api/product_group/product_price_group/no_member/list', null , 'get', null, res => res)
export const onListProductNormalWithNoPriceGroup = () => createAction('listProductNormalWithNoPriceGroup', '/api/product_group/no_product_price_group/list', null , 'get', null, res => res)
export const onSaveProductPriceGroup = body => createAction('saveProductPriceGroup', '/api/product_group/product_price_group/save', null, 'post', body, res => res)
export const onDeleteProductPriceGroup = id => createAction('deleteProductPriceGroup', `/api/product_group/product_price_group/delete/${id}`, null, 'delete', null, response => response)
export const onAddProductPriceGroupMember = body => createAction('addProductPriceGroupMember', '/api/product_group/product_price_group/member/add', null, 'post', body, res => res)
export const onDeleteMemberFromProductPriceGroup = id => createAction('deleteMemberFromProductPriceGroup', `/api/product_group/product_price_group/member/remove/${id}`, null, 'delete', null, response => response)
export const onEditPriceByProductPriceGroup = (id, body) => createAction('editPriceByProductPriceGroup', `/api/product/edit/price/product_price_group/${id}`, null, 'post', body, res => res)

/// -------------------- Edit Product Price History --------------------------///
export const onListProductPriceHistory = (
duration
) => createAction('listProductPriceHistory', '/api/product/price_history/list', {
  duration
}, 'get', null, res => res)


export const onSaveProductTransition = body => createAction('saveProductTransition', '/api/product/transition/save', null, 'post', body, res => res)
export const onListProductTransitionHistory = (
  duration
  ) => createAction('listProductTransitionHistory', '/api/product/transition_history/list', {
    duration
  }, 'get', null, res => res)

  ///////////////////// Expenses Page //////////////////////

export const onListExpenseType = () => createAction('listExpenseType', '/api/expenses/type/list', null , 'get', null, res => res)
export const onListExpenseHistory = (
  start_date,
  end_date,
  expense_type,
  remark ) => createAction('listExpenseHistory', '/api/expenses/items', {
    start_date,
    end_date,
    expense_type,
    remark } , 'get', null, res => res)
export const onSaveExpense = body => createAction('saveExpense', '/api/expenses/item/add', null, 'post', body, res => res)
export const onDeleteExpense = id => createAction('deleteExpense', `/api/expenses/item/delete/${id}`, null, 'delete', null, response => response)

  ///////////////////// Bill Payment Method Page //////////////////////

export const onListBillPaymentMethod = (
  sale_channel_id,
  date,
  payment_mehtod ) => createAction('listBillPaymentMethod', '/api/customer_bill/daily_bill/list', {
    sale_channel_id,
    date,
    payment_mehtod } , 'get', null, res => res)

export const onListBillVatPaymentMethod = (
  sale_channel_id,
  date,
  payment_mehtod ) => createAction('listBillVatPaymentMethod', '/api/customer_bill_vat/daily_bill/list', {
    sale_channel_id,
    date,
    payment_mehtod } , 'get', null, res => res)