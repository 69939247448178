import React from 'react'
import { addCommas } from 'utils'
import moment from 'moment'
import 'moment/locale/th'
moment.locale('th')

const columns = () => {
  const tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '60px',
    },
    {
      title: 'เลขที่บิล',
      dataIndex: 'customer_bill_no',
      key: 'customer_bill_no',
      width: '100px',
    },
    {
      title: 'วันที่บิล',
      dataIndex: 'customer_bill_date',
      key: 'customer_bill_date',
      sorter: (a, b) => ('' + a.customer_bill_date).localeCompare(b.customer_bill_date),
      sortDirections: ['ascend', 'descend'],
      width: '80px',
      render: (_, record) => {
        return moment(record.customer_bill_date).format('L')
      },
    },
    {
      title: 'รหัส',
      dataIndex: 'code',
      key: 'code',
      width: '60px',
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'name',
      key: 'name',
      width: '300px',
    },
    {
      title: 'ราคาเข้า',
      dataIndex: 'price_in',
      key: 'price_in',
      width: '80px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
    },
    {
      title: 'ราคาขาย',
      dataIndex: 'price_out',
      key: 'price_out',
      width: '80px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
    },
    {
      title: 'จำนวน',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '50px',
    },
    {
      title: 'หน่วย',
      dataIndex: 'unit_name',
      key: 'unit_name',
      width: '50px',
    },
    {
      title: 'ยอดรวม',
      dataIndex: 'total',
      key: 'total',
      width: '80px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
    },
    {
      title: 'Vat',
      dataIndex: 'product_vat_type_name',
      key: 'product_vat_type_name',
      width: '30px',
    },
  ]
  return tableColumns
}

export default columns
