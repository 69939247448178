import { useState } from 'react'

const usePagination = (props = { customPageSize: undefined }) => {
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(
    props.customPageSize ? props.customPageSize : 20
  )
  const paginationSetting = ({ totalItems, position, simple = false }) => {
    return {
      simple,
      current: currentPageNumber,
      onChange: (page, pageSize) => {
        setPageSize(pageSize)
        setCurrentPageNumber(page)
      },
      onShowSizeChange: (_, pageSize) => {
        setPageSize(pageSize)
      },
      showTotal: simple
        ? false
        : (total, range) => `${range[0]}-${range[1]} of ${total} items`,
      total: totalItems,
      defaultPageSize: pageSize,
      showSizeChanger: true,
      position: position || ['bottomRight'],
    }
  }

  return {
    currentPageNumber,
    pageSize,
    paginationSetting,
    setCurrentPageNumber,
  }
}

export default usePagination
