import React from 'react'
import { addCommas } from 'utils'
import moment from 'moment'
import 'moment/locale/th'
moment.locale('th')

const ExpensesPDF = (props) => {
  const { list, summary } = props
  const renderPageBreak = () => {
    return (
      <>
        <div style={{ marginTop: '10px' }}>
          <span className="page-break-before" />
          <span className="page-break-after" />
        </div>
        <div
          style={{ paddingTop: '30px', borderBottom: '1px solid #000000' }}
        ></div>
      </>
    )
  }

  return (
    <div>
      <div style={{ fontSize: '13px', color: '#000000cc' }}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            border: '1px solid #000000',
            fontWeight: '500',
          }}
        >
          <div
            style={{ paddingLeft: '5px', display: 'inline-block', width: '8%' }}
          >
            ลำดับ
          </div>
          <div style={{ display: 'inline-block', width: '15%' }}>
            วันที่จ่าย
          </div>
          <div style={{ display: 'inline-block', width: '15%' }}>ประเภท</div>
          <div
            style={{
              textAlign: 'right',
              display: 'inline-block',
              width: '20%',
            }}
          >
            จำนวนเงิน (บาท)
          </div>
          <div
            style={{
              paddingLeft: '10px',
              display: 'inline-block',
              width: '42%',
            }}
          >
            หมายเหตุ
          </div>
        </div>
        {list.map((row, i) => {
          return (
            <div key={i}>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  borderBottom: '1px solid #5c5c5c',
                }}
              >
                <div
                  style={{
                    paddingLeft: '5px',
                    display: 'inline-block',
                    width: '8%',
                  }}
                >
                  {i + 1}
                </div>
                <div style={{ display: 'inline-block', width: '15%' }}>
                  {moment(row.payment_date).format('L')}
                </div>
                <div style={{ display: 'inline-block', width: '15%' }}>
                  {row.expense_type_name}
                </div>
                <div
                  style={{
                    textAlign: 'right',
                    display: 'inline-block',
                    width: '20%',
                  }}
                >
                  {addCommas(row.amount)}
                </div>
                <div
                  style={{
                    paddingLeft: '10px',
                    display: 'inline-block',
                    width: '42%',
                  }}
                >
                  {row.remark}
                </div>
              </div>
              {i !== 0 && i % 45 === 0 ? renderPageBreak() : ''}
            </div>
          )
        })}
        <div
          style={{
            textAlign: 'right',
            paddingTop: '5px',
            marginRight: '279px',
          }}
        >
          ยอดรวมค่าใช้จ่าย: {summary} บาท
        </div>
      </div>
    </div>
  )
}

export default ExpensesPDF
