/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Spin, Divider, Popconfirm, Modal } from 'antd'
import {
  SaveOutlined,
  ClearOutlined,
  PlusCircleOutlined,
  PrinterOutlined,
} from '@ant-design/icons'
import moment from 'moment'

import InlineInput from 'components/InlineInput'
import DeliveryOrderTable from 'components/DeliveryOrderTable'
import SearchProductVatPopUp from 'components/SearchProductVatPopUp'
import SearchCustomerVatPopUp from 'components/SearchCustomerVatPopUp'
import SearchCustomerBillVatPopUp from 'components/SearchCustomerBillVatPopUp'
import SearchDeliveryOrderPopUp from 'components/SearchDeliveryOrderPopUp'
import { useQuery, handleAlert } from 'utils'

import {
  onGetCustomerVatByCode,
  onGetProductVatByCode,
  onGetCustomerBillVat,
  onGetUnitList,
  onSaveDeliveryOrder,
  onGetDeliveryOrder,
} from 'redux/actions'

const DEFAULT_FORM = {
  id: 0,
  created: '',
  doc_no: '',
  doc_date: moment().format('YYYY-MM-DD'),
  bill_vat_no: '',
  bill_vat_date: null,
  bill_vat_id: null,
  bill_vat_type_id: null,
  bill_vat_type_value: '',
  sale_channel_id: null,
  customer_data: {
    id: null,
    code: '',
    name: '',
    tax_id: '',
    branch_name: '',
    address: '',
    tel: '',
    fax: '',
    website: '',
    email: '',
    remark: '',
    customer_type: '',
  },
  is_full_tax_invoice: false,
  full_tax_invoice_type: '',
  product_is_included_vat: false,
  purchase_order_number: '',
  percent_vat: '7.00',
  sale_person: '',
  special_discount_baht: '',
  special_discount_percent: '',
  special_discount: '',
  special_discount_with_vat: '',
  total_charging_vat: '',
  value_added_tax: '',
  grand_total_with_vat: '',
  total_before_special_discount: '',
  total_after_special_discount: '',
  total_with_vat_before_special_discount: '',
  total_items: '',
  cash_input_amount: '',
  cash_change_amount: '',
  remark: '',
  products_vat: [],
}

const DEFAULT_PRODUCT = {
  id: 0,
  product_vat_id: null,
  product_vat_code: '',
  product_vat_name: '',
  product_normal_id: '',
  product_normal_code: '',
  product_normal_name: '',
  package_size: '',
  stock1: '',
  unit_id: '',
  unit_name: '',
  quantity: '',
  price_out_with_vat: '0',
  price_out: '0',
  discount_1: '0.0000',
  discount_2: '0.0000',
  discount_3: '0.0000',
  discount_4: '0.0000',
  total: '',
  total_with_vat: '',
  is_allow_low: false,
  last_purchased: '',
  remark: '',
  net: '0.0000',
}

const DeliveryOrderPage = (props) => {
  function useAsyncState(initialValue) {
    const [value, setValue] = useState(initialValue)
    const setter = (x) =>
      new Promise((resolve) => {
        setValue(x)
        resolve(x)
      })
    return [value, setter]
  }
  const query = useQuery()
  const bill_no = query.get('bill_no')
  const [form, setForm] = useAsyncState(DEFAULT_FORM)
  const [product, setProduct] = useAsyncState(DEFAULT_PRODUCT)
  const [isLoading, setIsLoading] = useState(false)
  const [isShowBilltPopUp, setIsShowBillPopUp] = useState(false)
  const [isShowDeliveryOrderPopUp, setIsShowDeliveryOrderPopUp] =
    useState(false)
  const [isShowProductPopUp, setIsShowProductPopUp] = useState(false)
  const [isShowCustomerPopUp, setIsShowCustomerPopUp] = useState(false)
  const [printPopUp, setPrintPopUp] = useState(false)
  const dispatch = useDispatch()
  let getCustomerBillVat = useSelector((state) => state.getCustomerBillVat)
  let getCustomerVatByCode = useSelector((state) => state.getCustomerVatByCode)
  let getProductVatByCode = useSelector((state) => state.getProductVatByCode)
  const getUnitList = useSelector((state) => state.getUnitList)
  let saveDeliveryOrder = useSelector((state) => state.saveDeliveryOrder)
  let getDeliveryOrder = useSelector((state) => state.getDeliveryOrder)

  useEffect(() => {
    setIsLoading(true)
    dispatch(onGetUnitList())
    getCustomerBillVat = {}
    getCustomerVatByCode = {}
    getProductVatByCode = {}
    saveDeliveryOrder = {}
    getDeliveryOrder = {}
    document.title = 'ใบส่งของ [VAT]'
  }, [])

  useEffect(() => {
    setIsLoading(true)
    if (bill_no) {
      dispatch(onGetCustomerBillVat(bill_no))
    }
  }, [bill_no, props.history])

  useEffect(() => {
    const { loading, finished, error, data, trace } = getUnitList
    setIsLoading(loading)
    if (!loading && !error && finished && data) {
      // success
    }
    if (error && !loading) {
      handleAlert('custom', trace.message || '')
    }
  }, [getUnitList])

  useEffect(() => {
    const { error, loading, finished, data, trace } = getCustomerVatByCode
    if (!error && !loading && finished) {
      handleAlert('success')
      setForm({
        ...form,
        customer_data: data,
      })
      setIsLoading(false)
    }
    if (error && !loading) {
      setIsLoading(false)
      handleAlert('custom', trace.message ? trace.message : '')
      setForm({
        ...form,
        customer_data: {
          ...form.customer_data,
          id: null,
          name: '',
          tax_id: '',
          branch_name: '',
          address: '',
          tel: '',
          fax: '',
          website: '',
          email: '',
          remark: '',
          customer_type: '',
        },
      })
    }
  }, [getCustomerVatByCode])

  useEffect(() => {
    const { error, loading, finished, data, trace } = getCustomerBillVat
    setIsLoading(true)
    if (error) {
      Modal.warning({
        centered: true,
        title: 'ตรวจสอบ',
        content: `${trace.message}`,
      })
      setIsLoading(false)
    }
    if (!loading && !error && data && finished) {
      handleAlert('success')
      setForm({
        ...form,
        ...data,
        id: 0,
        bill_vat_id: data.id,
      })
      setProduct(DEFAULT_PRODUCT)
      setIsLoading(false)
    }
  }, [getCustomerBillVat])

  const initialUnitName = (id) => {
    let unit_name = ''
    getUnitList.data.forEach((x) => {
      if (x.id === id) {
        unit_name = x.name
      }
    })
    return unit_name
  }

  useEffect(() => {
    const { error, loading, finished, trace, data } = getProductVatByCode
    if (!error && !loading && finished) {
      // initial product data
      setProduct({
        ...product,
        id: 0,
        product_vat_id: data.id,
        product_vat_code: data.code,
        product_vat_name: data.vat_name,
        package_size: data.package_size,
        stock1: data.stock1,
        unit_id: data.unit_id,
        unit_name: initialUnitName(data.unit_id),
        quantity: '',
      })
    }
    if (error && !loading) {
      Modal.error({
        centered: true,
        title: 'ตรวจสอบ',
        content: `${trace.message}`,
      })
    }
  }, [getProductVatByCode])

  useEffect(() => {
    const { error, loading, finished, data, trace } = saveDeliveryOrder
    setIsLoading(loading)
    if (!error && !loading && finished) {
      setForm(data)
      setPrintPopUp(true)
    }
    if (error && !loading) {
      Modal.error({
        centered: true,
        title: 'ตรวจสอบ',
        content: `${trace.message}`,
      })
    }
  }, [saveDeliveryOrder])

  useEffect(() => {
    const { error, loading, trace, data, finished } = getDeliveryOrder
    setIsLoading(loading)
    if (!error && !loading && finished) {
      setForm(data)
      setProduct(DEFAULT_PRODUCT)
    }
    if (!loading && error) {
      handleAlert('custom', trace.message ? trace.message : '')
    }
  }, [getDeliveryOrder])

  const onEditTableData = (row, key, value, index) => {
    let newProducts = []
    form.products_vat.forEach((x, item_index) => {
      if (item_index === index && x.id === row.id && x.product_vat_name === row.product_vat_name) {
        x[key] = value
        return newProducts.push(x)
      } else {
        return newProducts.push(x)
      }
    })
    setForm({
      ...form,
      products_vat: newProducts,
    })
  }

  const onDeleteProductFromBill = (row, index) => {
    const { products_vat } = form
    let tempProduct = [...products_vat]
    tempProduct.splice(index, 1)
    setForm({
      ...form,
      total_items: tempProduct.length,
      products_vat: [...tempProduct],
    })
  }

  const addProductToList = (_product) => {
    let isDuplicatedProduct = false
    form.products_vat.forEach((x) => {
      if (form.bill_vat_type_value === 'send_with_vat') {
        if (x.product_vat_code === _product.product_vat_code) {
          if (x.product_normal_code === _product.product_normal_code) {
            return (isDuplicatedProduct = true)
          }
        }
      } else {
        if (x.product_vat_code === _product.product_vat_code) {
          return (isDuplicatedProduct = true)
        }
      }
    })
    if (!isDuplicatedProduct) {
      if (Number(_product.quantity) > 0) {
        const oldProduct = form.products_vat
        setForm({
          ...form,
          total_items: oldProduct.length + 1,
          products_vat: [...oldProduct, _product],
        }).then(() => setProduct(DEFAULT_PRODUCT))
      } else {
        Modal.warning({
          centered: true,
          title: 'ตรวจสอบ',
          content: 'กรุณาระบุ "กรุณากรอกจำนวนสินค้าให้ครบถ้วน',
        })
      }
    } else {
      Modal.warning({
        centered: true,
        title: 'ตรวจสอบ',
        content:
          'กรุณาระบุ "มีสินค้านี้อยู่ในรายการอยู่แล้ว กรุณาเพิ่มสินค้าใหม่',
      })
      setProduct(DEFAULT_PRODUCT)
    }
  }

  const onSaveDoc = () => {
    if (form.customer_data.code && form.doc_date) {
      dispatch(onSaveDeliveryOrder(form))
    } else {
      handleAlert('custom', 'กรุณากรอกข้อมูลที่มีเครื่องหมาย * ให้ครบถ้วน')
    }
  }

  const onSearchProduct = (value) => {
    if (value) {
      dispatch(onGetProductVatByCode(value))
    } else {
      setIsShowProductPopUp(true)
    }
  }

  const onCancelProdutPopUp = () => {
    setIsShowProductPopUp(false)
  }

  const onSelectProductPopUp = (data) => {
    onSearchProduct(data.code)
    setIsShowProductPopUp(false)
  }

  const onSearchCustomerCode = (code) => {
    if (code !== '') {
      dispatch(onGetCustomerVatByCode(code))
    } else {
      setIsShowCustomerPopUp(true)
    }
  }

  const onSelectCustomerPopUp = (data) => {
    dispatch(onGetCustomerVatByCode(data.code))
    setIsShowCustomerPopUp(false)
  }

  const onCancelCustomerPopUp = () => {
    setIsShowCustomerPopUp(false)
  }

  const onClearInput = () => {
    setForm(DEFAULT_FORM)
    setProduct(DEFAULT_PRODUCT)
  }

  const onSearchBill = (value) => {
    if (value) {
      dispatch(onGetCustomerBillVat(value))
    } else {
      setIsShowBillPopUp(true)
    }
  }

  const onCancelBillPopUp = () => {
    setIsShowBillPopUp(false)
  }

  const onSelectBillPopUp = (data) => {
    onSearchBill(data.bill_no)
    setIsShowBillPopUp(false)
  }

  const onSearchDoc = (value) => {
    if (value) {
      dispatch(onGetDeliveryOrder(value))
    } else {
      setIsShowDeliveryOrderPopUp(true)
    }
  }

  const onCancelDocPopUp = () => {
    setIsShowDeliveryOrderPopUp(false)
  }

  const onSelectDocPopUp = (data) => {
    onSearchDoc(data.doc_no)
    setIsShowDeliveryOrderPopUp(false)
  }

  const printDoc = () => {
    window.open(
      `/vat/delivery-order/print?doc_no=${form.doc_no}`,
      '_blank'
    )
    setPrintPopUp(false)
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'inline-block' }}>
          <h1>ใบส่งของ [VAT]</h1>
        </div>
        <div style={{ display: 'inline-block' }}>
          <Popconfirm
            title="คุณต้องการล้างหน้าจอใช่หรือไม่ ?"
            okText="ใช่"
            cancelText="ยกเลิก"
            onConfirm={onClearInput}
          >
            <Button
              type="default"
              danger
              icon={<ClearOutlined />}
              size="large"
              block
            >
              ล้างหน้าจอ
            </Button>
          </Popconfirm>
        </div>
      </div>
      {/* <button onClick={() => console.log(form)}>FORM</button>
      <button onClick={() => console.log(product)}>PRODUCT</button> */}
      <SearchCustomerVatPopUp
        visible={isShowCustomerPopUp}
        onCancel={onCancelCustomerPopUp}
        onClick={onSelectCustomerPopUp}
      />
      <SearchProductVatPopUp
        visible={isShowProductPopUp}
        onCancel={onCancelProdutPopUp}
        onClick={onSelectProductPopUp}
      />
      <SearchCustomerBillVatPopUp
        visible={isShowBilltPopUp}
        onCancel={onCancelBillPopUp}
        onClick={onSelectBillPopUp}
        type=""
        customer_name={form.customer_data.name}
      />
      <SearchDeliveryOrderPopUp
        visible={isShowDeliveryOrderPopUp}
        onCancel={onCancelDocPopUp}
        onClick={onSelectDocPopUp}
        customer_name={form.customer_data.name}
      />
      <Modal
        width="500px"
        centered
        visible={printPopUp}
        title="บันทึกรายการเรียบร้อย"
        onCancel={() => {
          setPrintPopUp(false)
        }}
        onOk={printDoc}
      >
        <div>
            คุณต้องการพิมพ์ใบส่งของใช่หรือไม่ ?
        </div>
      </Modal>
      <Spin spinning={isLoading ? true : false}>
        <div className="form-wrapper-vat">
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                onSearch={(value) => {
                  onSearchCustomerCode(value)
                }}
                inputOption="search"
                label="รหัสลูกค้า VAT"
                placeholder="รหัสลูกค้า VAT"
                isLoading={false}
                width="180px"
                value={form.customer_data.code}
                onChange={(event) => {
                  setForm({
                    ...form,
                    customer_data: {
                      ...form.customer_data,
                      code: event.target.value.toUpperCase(),
                    },
                  })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ชื่อลูกค้า"
                width="250px"
                required={form.is_full_tax_invoice}
                value={form.customer_data.name}
                disabled={true}
                disableColon
              />
            </div>
            <div className="input-item">
              <InlineInput
                onSearch={(value) => {
                  onSearchDoc(value)
                }}
                inputOption="search"
                required
                label="เลขที่เอกสาร"
                placeholder="เลขที่เอกสาร"
                isLoading={false}
                width="250px"
                value={form.doc_no}
                onChange={(event) => {
                  setForm({
                    ...form,
                    doc_no: event.target.value.toUpperCase(),
                  })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="วันที่เอกสาร"
                placeholder="กรุณาเลือกวันที่"
                required
                disabled={form.id !== 0 || form.is_canceled ? true : false}
                defaultValue={moment()}
                value={form.doc_date && moment(form.doc_date)}
                inputOption="date_picker"
                onChange={(_, dateString) => {
                  setForm({ ...form, doc_date: dateString })
                }}
              />
            </div>
          </div>
          <Divider style={{ margin: '0px 0px 10px' }} />
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                onSearch={(value) => {
                  onSearchBill(value)
                }}
                inputOption="search"
                label="เลขที่บิล"
                placeholder="เลขที่บิล"
                isLoading={false}
                value={form.bill_vat_no}
                onChange={(event) => {
                  setForm({
                    ...form,
                    bill_vat_no: event.target.value.toUpperCase(),
                  })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="วันที่เปิดบิล"
                placeholder="YYYY-MM-DD"
                disabled
                defaultValue={moment()}
                value={form.bill_vat_date && moment(form.bill_vat_date)}
                inputOption="date_picker"
                onChange={(_, dateString) => {
                  setForm({ ...form, bill_vat_date: dateString })
                }}
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                onSearch={(value) => {
                  onSearchProduct(value)
                }}
                inputOption="search"
                label="รหัสสินค้า"
                placeholder="รหัสสินค้า"
                isLoading={false}
                value={product.product_vat_code}
                onChange={(event) => {
                  setProduct({
                    ...product,
                    product_vat_code: event.target.value.toUpperCase(),
                  })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ชื่อสินค้า"
                width="440px"
                value={product.product_vat_name}
                disabled={true}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ขนาดบรรจุ"
                width="120px"
                value={product.package_size}
                disabled={true}
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item" style={{ marginRight: '0px' }}>
              <InlineInput
                label="จำนวน"
                width="100px"
                value={product.quantity}
                onChange={(event) => {
                  setProduct({ ...product, quantity: event.target.value })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                width="100px"
                disableColon
                value={product.unit_name}
                onChange={(event) => {
                  setProduct({ ...product, unit_name: event.target.value })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="หมายเหตุ"
                width="300px"
                value={product.remark}
                onChange={(event) => {
                  setProduct({ ...product, remark: event.target.value })
                }}
              />
            </div>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              size="large"
              disabled={form.bill_vat_id !== null ? true : false}
              onClick={() => addProductToList(product)}
              style={{ marginLeft: '50px' }}
            >
              เพิ่มรายการ
            </Button>
          </div>
          <Divider style={{ margin: '3px 0px 10px' }} />
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="จำนวนในรายการ"
                width="50px"
                value={form.total_items}
                disabled
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="วันที่แก้ไขล่าสุด"
                placeholder="YYYY/MM/DD"
                defaultValue={moment()}
                value={form.updated_at && moment(form.updated_at)}
                disabled
                inputOption="date_picker"
              />
            </div>
          </div>
          <div style={{ background: '#f1f1f1', marginBottom: '20px' }}>
            <DeliveryOrderTable
              productList={form.products_vat}
              onChange={onEditTableData}
              onDelete={onDeleteProductFromBill}
              isDataFromBill={form.bill_vat_id !== null ? true : false}
            />
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '30%', display: 'inline-block' }}>
              <div className="input-wrapper">
                <div className="input-item">
                  <InlineInput
                    className="text-align-right"
                    label="ส่วนลดพิเศษ"
                    width="120px"
                    value={form.special_discount_baht}
                    unit="บาท"
                    disabled
                  />
                </div>
                <div className="input-item">
                  <InlineInput
                    className="text-align-right"
                    width="80px"
                    value={form.special_discount_percent}
                    unit="%"
                    disabled
                    disableColon={true}
                  />
                </div>
              </div>
              <div className="input-wrapper">
                <div className="input-item">
                  <InlineInput
                    label="หมายเหตุบิล"
                    width="300px"
                    value={form.remark}
                    onChange={(event) => {
                      setForm({
                        ...form,
                        remark: event.target.value,
                      })
                    }}
                  />
                </div>
              </div>
            </div>
            <div style={{ width: '25%', display: 'inline-block' }}>
              <div className="input-wrapper">
                <div className="input-item margin-left-auto">
                  <InlineInput
                    className="text-align-right"
                    label="ภาษีมูลค่าเพิ่ม"
                    width="150px"
                    disabled
                    value={form.value_added_tax}
                  />
                </div>
              </div>
            </div>
            <div style={{ width: '25%', display: 'inline-block' }}>
              <div className="input-wrapper">
                <div className="input-item margin-left-auto">
                  <InlineInput
                    className="text-align-right"
                    label="ยอดก่อนหักส่วนลดพิเศษ"
                    width="180px"
                    value={form.total_before_special_discount}
                    disabled
                  />
                </div>
                <div className="input-item margin-left-auto">
                  <InlineInput
                    className="text-align-right"
                    label="ยอดหลังหักส่วนลดพิเศษ"
                    width="180px"
                    disabled
                    value={form.total_after_special_discount}
                  />
                </div>
                <div className="input-item margin-left-auto">
                  <InlineInput
                    className="text-align-right"
                    label="ยอดรวมภาษ๊"
                    width="180px"
                    disabled
                    value={form.grand_total_with_vat}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                width: '12%',
                marginLeft: 'auto',
                marginRight: '30px',
                display: 'inline-block',
              }}
            >
              <Button
                type="primary"
                icon={<SaveOutlined />}
                size="large"
                style={{ height: '80px' }}
                block
                onClick={onSaveDoc}
              >
                บันทึก
              </Button>
            </div>
            <div
              style={{
                width: '8%',
                display: 'inline-block',
              }}
            >
              <div style={{ marginBottom: '10px' }}>
                <Button
                  type="default"
                  icon={<PrinterOutlined />}
                  size="large"
                  style={{ height: '80px' }}
                  block
                  onClick={() => {
                    if (form.doc_no !== '' && form.id !== 0) {
                      setPrintPopUp(true)
                    } else {
                      Modal.warning({
                        centered: true,
                        title: 'ตรวจสอบ',
                        content: 'กรุณาค้นหาเอกสารก่อนสั่งพิมพ์',
                      })
                    }
                  }}
                >
                  พิมพ์เอกสาร
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  )
}

export default withRouter(DeliveryOrderPage)
