import React from 'react'
import { Row, Col, Input } from 'antd'
import './style.scss'
import styled from 'styled-components'
import TextArea from 'antd/lib/input/TextArea'

const { Search } = Input

const RequiredText = styled.span`
  color: red;
`

export const InputComponent = (props) => {
  const {
    id,
    label,
    value,
    handleInputChange,
    // span,
    isTextArea,
    isSearch,
    onSearch,
    required,
    isTaxId,
    disabled,
  } = props
  return (
    <div className="input-block">
      <Row gutter={10}>
        <Col xs={{ span: 24 }} lg={ isTaxId ? { span: 7 } : { span: 5 }}>
          <div className="label-Block">
            <label>{label} {required ? <RequiredText>* &nbsp;</RequiredText> : '' } :</label>
          </div>
        </Col>
        <Col xs={{ span: 24 }} lg={ isTaxId ? { span: 17 } : { span: 19 }}>
          {isTextArea ? (
            <TextArea
              rows={3}
              id={id}
              value={value || ''}
              onChange={handleInputChange}
              placeholder={`กรอก${label}`}
              disabled={disabled}
            ></TextArea>
          ) : isSearch ? (
            <Search
              id={id}
              onSearch={onSearch}
              enterButton
              placeholder={`กรอก${label}`}
              value={value || ''}
              onChange={handleInputChange}
              className="search-input"
              disabled={disabled}
            />
          ) : (
            <Input
              type="text"
              id={id}
              value={value || ''}
              onChange={handleInputChange}
              placeholder={`กรอก${label}`}
              disabled={disabled}
            />
          )}
        </Col>
      </Row>
    </div>
  )
}
