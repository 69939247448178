import React, { Component } from 'react'
import { Button } from 'antd'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import styled from 'styled-components'

import { onGetCustomerByCode, onGetCustomerBill } from 'redux/actions'
import { handleAlert, ArabicNumberToText, addCommas } from 'utils'
import './style.scss'

const NoVatPrintBlock = styled.div`
  @page {
    size: A4;
    margin: 0px;
  }
`

const mapStateToProps = (state) => {
  return {
    getCustomerByCode: state.getCustomerByCode,
    getCustomerBill: state.getCustomerBill,
  }
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ onGetCustomerByCode, onGetCustomerBill }, dispatch)

// const bill = {
//   created: '2020-08-25T09:25:50.593770Z',
//   updated_at: '2021-04-27T10:45:06.562538Z',
//   customer_code: '0001',
//   customer_id: 7,
//   customer_name: 'ช่างใหญ่ ลำลูกกา',
//   customer_type_id: 1,
//   bill_no: 'ALZ6300031',
//   bill_date: '2020-08-25',
//   bill_type_id: 1,
//   bill_type_name: 'ATM',
//   bill_type_abbr: 'A',
//   is_included_vat: false,
//   sale_channel_id: 1,
//   sale_channel_name: 'Lazada',
//   sale_channel_abbr: 'LZ',
//   special_discount_baht: '100.00',
//   special_discount_percent: '0.00',
//   total_before_special_discount: '72200.00',
//   total_after_special_discount: '72100.00',
//   grand_total: '72100.00',
//   total_items: 2,
//   remark:
//     'sdfasdjfl;jsdlfjalskdjfsjdfjาก่ดฟวสาห่กดรีทกดฟสหากดสาสาแอ่นรหกดวสาหก่ดห',
//   products: [
//     {
//       id: 41,
//       created: '2020-08-25T09:25:50.604920Z',
//       updated_at: '2021-04-27T10:45:06.570790Z',
//       last_purchased: '2020-08-25',
//       code: '0002',
//       name: 'ไฟทางเท้า',
//       package_size: '15',
//       product_remark: 'Remark for product',
//       stock1: 65.0,
//       unit_id: 1,
//       unit_name: 'หลอด',
//       quantity: '710.00',
//       price_out: '100.0000',
//       discount_1: '0.0000',
//       discount_2: '0.0000',
//       discount_3: '0.0000',
//       discount_4: '0.0000',
//       total: '71000.00',
//       remark: '',
//     },
//     {
//       id: 47,
//       created: '2021-01-08T10:42:15.987604Z',
//       updated_at: '2021-04-27T10:45:06.579595Z',
//       last_purchased: '2020-08-25',
//       code: '0003',
//       name: 'สินค้าทดสอบโคมไฟ ชื่อยาวมาก มากที่สุดในโลก',
//       package_size: '25',
//       product_remark: 'Test',
//       stock1: 1380.0,
//       unit_id: 1,
//       unit_name: 'หลอด',
//       quantity: '10.00',
//       price_out: '120.0000',
//       discount_1: '0.0000',
//       discount_2: '0.0000',
//       discount_3: '0.0000',
//       discount_4: '0.0000',
//       total: '1200.00',
//       remark: '',
//     },
//     {
//       id: 41,
//       created: '2020-08-25T09:25:50.604920Z',
//       updated_at: '2021-04-27T10:45:06.570790Z',
//       last_purchased: '2020-08-25',
//       code: '0002',
//       name: 'ไฟทางเท้า',
//       package_size: '15',
//       product_remark: 'Remark for product',
//       stock1: 65.0,
//       unit_id: 1,
//       unit_name: 'หลอด',
//       quantity: '710.00',
//       price_out: '100.0000',
//       discount_1: '0.0000',
//       discount_2: '0.0000',
//       discount_3: '0.0000',
//       discount_4: '0.0000',
//       total: '71000.00',
//       remark: '',
//     },
//   ],
// }

const maximumProductPerPage = 20 // maximum must less or equal 25 product per page

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class index extends Component {
    constructor(props) {
      super(props)
      this.state = {
        bill: {},
        customer: {},
        printArray: [],
        isLoading: false,
        totalPage: 0,
        isDataAvailable: false,
      }
    }

    componentDidMount() {
      let search = window.location.search
      let params = new URLSearchParams(search)
      let bill_no = params.get('bill_no')
      this.getBillFromQuery(bill_no)
    }

    componentDidUpdate(prevProps, PrevState) {
      const { getCustomerByCode, getCustomerBill } = this.props
      if (prevProps.getCustomerBill !== getCustomerBill) {
        const { error, loading, finished, data, trace } = getCustomerBill
        this.setState({ isLoading: loading })
        if (!loading && !error && finished) {
          this.setState({
            bill: data,
            isDataAvailable: true,
          })
          this.splitProductVat(data.products)
          this.getCustomerDetail(data.customer_code)
        }
        if (!loading && error) {
          handleAlert('error', trace.message ? trace.message : '')
          this.setState({ isLoading: loading })
        }
      }
      if (prevProps.getCustomerByCode !== getCustomerByCode) {
        const { error, loading, finished, data, trace } = getCustomerByCode
        this.setState({ isLoading: loading })
        if (!loading && !error && finished) {
          this.setState({
            customer: data,
          })
          setTimeout(() => {
            window.print()
          }, 1000)
        }
        if (!loading && error) {
          handleAlert('error', trace.message ? trace.message : '')
          this.setState({ isLoading: loading })
        }
      }
    }

    getBillFromQuery = (bill_no) => {
      this.props.onGetCustomerBill(bill_no)
    }

    getCustomerDetail = (customer_code) => {
      this.props.onGetCustomerByCode(customer_code)
    }

    splitProductVat = (products) => {
      let splitArray = []
      if (products.length > maximumProductPerPage) {
        for (
          let i = 0;
          i < Math.ceil(products.length / maximumProductPerPage);
          i++
        ) {
          splitArray[i] = products.slice(
            i * maximumProductPerPage,
            (i + 1) * maximumProductPerPage
          )
        }
      } else {
        splitArray[0] = products
      }
      this.setState({
        printArray: [...splitArray],
        totalPage: splitArray.length,
      })
    }

    renderDiscount = (discount1, discount2, discount3, discount4) => {
      let isShowPercent = false
      let render_discount = ''
      isShowPercent =
        Number(discount1) > 0 ||
        Number(discount2) > 0 ||
        Number(discount3) > 0 ||
        Number(discount4) > 0
          ? true
          : false
      render_discount =
        Number(discount4) > 0
          ? `${Number(discount1)}+${Number(discount2)}+${Number(
              discount3
            )}+${Number(discount4)}`
          : Number(discount3) > 0
          ? `${Number(discount1)}+${Number(discount2)}+${Number(discount3)}`
          : Number(discount2) > 0
          ? `${Number(discount1)}+${Number(discount2)}`
          : Number(discount1) > 0
          ? `${Number(discount1)}`
          : ''

      return (
        <td style={{ textAlign: 'center' }}>
          {render_discount}
          {isShowPercent && '%'}
        </td>
      )
    }

    renderTotal = () => {
      const { bill } = this.state
      //----- Special Discount ------//
      let baht = Number(bill.special_discount_baht)
      let percent = Number(bill.special_discount_percent)
      let renderSpecialDicsount = ''
      if (baht > 0) {
        renderSpecialDicsount = addCommas(bill.special_discount_baht)
      } else if (percent > 0) {
        renderSpecialDicsount = percent + '%'
      } else {
        renderSpecialDicsount = '-'
      }
      // ---------- Total ---------//
      let renderTotalLabel = ''
      if (bill.is_included_vat) {
        renderTotalLabel = '(ยอดรวมภาษีมูลค่าเพิ่ม) ยอดสุทธิ: '
      } else {
        renderTotalLabel = '(ยังไม่รวมภาษีมูลค่าเพิ่ม) ยอดสุทธิ: '
      }
      //----------- Text ----------//
      let renderText = ArabicNumberToText(Number(bill.grand_total))

      return (
        <>
          <div style={{ display: 'flex' }}>
            <div
              style={{
                display: 'inline-inblock',
                width: '50%',
              }}
            >
              หมายเหตุ: {bill.remark}
            </div>
            <div style={{ display: 'inline-inblock', width: '50%' }}>
              <div
                style={{
                  textAlign: 'right',
                  display: 'inline-block',
                  width: '60%',
                }}
              >
                ยอดรวม:
              </div>
              <div
                style={{
                  textAlign: 'right',
                  display: 'inline-block',
                  width: '40%',
                }}
              >
                {addCommas(bill.total_before_special_discount)}
              </div>
              <div
                style={{
                  textAlign: 'right',
                  display: 'inline-block',
                  width: '60%',
                }}
              >
                ส่วนลดพิเศษ:
              </div>
              <div
                style={{
                  textAlign: 'right',
                  display: 'inline-block',
                  width: '40%',
                }}
              >
                {renderSpecialDicsount}
              </div>
              {/* <div
                style={{
                  textAlign: 'right',
                  display: 'inline-block',
                  width: '60%',
                }}
              >
                ยอดรวมหลังหักส่วนลด:
              </div>
              <div
                style={{
                  textAlign: 'right',
                  display: 'inline-block',
                  width: '40%',
                }}
              >
                {addCommas(bill.total_after_special_discount)}
              </div> */}
              <div
                style={{
                  textAlign: 'right',
                  display: 'inline-block',
                  width: '60%',
                  fontWeight: '700',
                }}
              >
                {renderTotalLabel}
              </div>
              <div
                style={{
                  textAlign: 'right',
                  display: 'inline-block',
                  width: '40%',
                  fontWeight: '700',
                }}
              >
                {addCommas(bill.grand_total)}
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'inline-inblock',
              borderBottom: '1px dashed black',
              paddingBottom: '5px',
              marginBottom: '5px',
            }}
          >
            ตัวอักษร: {renderText}
          </div>
        </>
      )
    }

    handleRenderPage = () => {
      const { printArray, totalPage, bill, customer } = this.state
      // const { printArray, totalPage, customer } = this.state
      return printArray.map((page, i) => {
        return (
          <NoVatPrintBlock key={i}>
            <div id="pdfdiv" className="no-vat-pdf">
              <div className="no-vat-header">
                <div
                  style={{
                    textAlign: 'center',
                    fontWeight: '700',
                    fontSize: '22px',
                  }}
                >
                  BL
                </div>
                <div style={{ textAlign: 'center' }}>ใบตรวจรับสินค้า</div>
                <div style={{ display: 'flex' }}>
                  <div
                    style={{
                      width: '65%',
                      paddingRight: '10px',
                      display: 'inline-block',
                    }}
                  >
                    <div>
                      <strong>รหัสลูกค้า:</strong> {customer.code}
                    </div>
                    <div>
                      <strong>ชื่อ:</strong> {customer.name}
                    </div>
                    <div>
                      <strong>ที่อยู่:</strong> {customer.address}
                    </div>
                    <div>
                      <strong>เบอร์โทร:</strong> {customer.tel}
                    </div>
                    <div>
                      <strong>สถานที่จัดส่ง:</strong>
                    </div>
                    <div>
                      <strong>ผู้รับ:</strong>
                    </div>
                  </div>
                  <div style={{ width: '35%', display: 'inline-block' }}>
                    <div>
                      <strong>เลขที่บิล:</strong> {bill.bill_no}
                    </div>
                    <div>
                      <strong>วันที่:</strong>{' '}
                      {moment(bill.bill_date).format('DD/MM/YYYY')}
                    </div>
                    <div>
                      <strong>ผู้ขาย:</strong>
                    </div>
                    <div>
                      {/* <strong>ประเภทบิล:</strong> {bill.bill_type_name} */}
                    </div>
                    {/* <div>
                      <strong>วันครบกำหนดชำระ: </strong>{' '}
                      {moment(bill.bill_date)
                        .add(1, 'months')
                        .format('DD/MM/YYYY')}
                    </div> */}
                    <div style={{ paddingTop: '40px', textAlign: 'right' }}>
                      หน้าที่ {i + 1} จาก {totalPage}{' '}
                    </div>
                  </div>
                </div>
              </div>
              <div className="no-vat-product-section">
                <table>
                  <thead>
                    <tr
                      style={{
                        borderTop: '1px dashed',
                        borderBottom: '1px dashed',
                        textAlign: 'center',
                      }}
                    >
                      <th style={{ width: '3%' }}>
                        <strong>#</strong>
                      </th>
                      <th style={{ width: '8%' }}>
                        <strong>รหัส</strong>
                      </th>
                      <th style={{ width: '7%' }}>
                        <strong>จำนวน</strong>
                      </th>
                      <th style={{ width: '7%' }}>
                        <strong>หน่วย</strong>
                      </th>
                      <th style={{ width: '38%' }}>
                        <strong>ชื่อสินค้า</strong>
                      </th>
                      <th style={{ width: '12%' }}>
                        <strong>หน่วยละ</strong>
                      </th>
                      <th style={{ width: '13%' }}>
                        <strong>ส่วนลด</strong>
                      </th>
                      <th style={{ width: '12%' }}>
                        <strong>รวม</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {page &&
                      page.map((product, index) => {
                        return (
                          <tr key={index}>
                            <td style={{ textAlign: 'center' }}>
                              {i * maximumProductPerPage + index + 1}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {product.code}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {addCommas(Number(product.quantity))}
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {product.unit_name}
                            </td>
                            <td>
                              <div className="product-title-column">
                                {product.name}
                              </div>
                              {product.remark && (
                                <div className="product-title-column">
                                  {product.remark}
                                </div>
                              )}
                            </td>
                            <td
                              style={{
                                textAlign: 'right',
                                paddingRight: '5px',
                              }}
                            >
                              {addCommas(product.price_out)}
                            </td>
                            {this.renderDiscount(
                              product.discount_1,
                              product.discount_2,
                              product.discount_3,
                              product.discount_4
                            )}
                            <td style={{ textAlign: 'right' }}>
                              {addCommas(product.total)}
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
              <div className="no-vat-footer-section">
                {this.renderTotal()}
                <div>ได้รับสินค้าตามรายการข้างต้นไว้ถูกต้อง</div>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    marginTop: '12px',
                  }}
                >
                  <div
                    style={{
                      display: 'inline-block',
                      width: '60%',
                      paddingLeft: '20px',
                    }}
                  >
                    ผู้รับสินค้า: _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _
                  </div>
                  <div style={{ display: 'inline-block', width: '40%' }}>
                    ผู้ตรวจสินค้า: _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    marginTop: '12px',
                  }}
                >
                  <div
                    style={{
                      display: 'inline-block',
                      width: '60%',
                      paddingLeft: '20px',
                    }}
                  >
                    วันที่รับสินค้า: _ _ _ _ _ /_ _ _ _ _ /_ _ _ _ _ _ _{' '}
                  </div>
                  <div style={{ display: 'inline-block', width: '40%' }}>
                    ผู้ส่งสินค้า: _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _
                  </div>
                </div>
                <div style={{ textAlign: 'center', marginTop: '12px' }}>
                  <strong>หมายเหตุ:</strong>{' '}
                  หากสินค้าและราคาไม่ถูกต้องโปรดแจ้งให้ทราบ
                  ทางบริษัทจะเปิดบิลส่งสินค้าพร้อมใบกำกับภาษีให้ใหม่
                </div>
              </div>
            </div>
            {i < printArray.length - 1 ? this.renderPageBreak() : ''}
          </NoVatPrintBlock>
        )
      })
    }
    renderPageBreak = () => {
      return (
        <div style={{ marginTop: '10px' }}>
          <span className="page-break-before" />
          <span className="page-break-after" />
        </div>
      )
    }
    render() {
      const { isDataAvailable } = this.state
      return (
        <div>
          <Button type="primary" className="no-print" onClick={window.print}>
            พิมพ์บิล
          </Button>
          {isDataAvailable && this.handleRenderPage('ต้นฉบับ')}
        </div>
      )
    }
  }
)
