import { listProduct } from 'api/products'
import useAPI from 'hooks/useAPI'
import { useCallback, useEffect, useState } from 'react'

const useListProduct = ({ currentPageNumber, pageSize }) => {
  const [searchTerm, setSearchTerm] = useState(undefined)
  const [productCode, setProductCode] = useState(undefined)
  const {
    makeRequest,
    data: productsData,
    loading: isLoadingProductsData,
    pagination,
  } = useAPI()

  const onSearchFilerProducts = useCallback(({ searchTerm }) => {
    setSearchTerm(searchTerm)
  }, [])

  const onSearchProductCode = useCallback(({ productCode }) => {
    setProductCode(productCode)
  }, [])

  const onListProduct = useCallback(() => {
    makeRequest(() =>
      listProduct({
        page: currentPageNumber,
        pageSize,
        searchTerm,
        code: productCode,
      })
    )
  }, [makeRequest, currentPageNumber, pageSize, searchTerm, productCode])

  useEffect(() => {
    onListProduct()
  }, [onListProduct, makeRequest, currentPageNumber, pageSize, searchTerm, productCode])

  return {
    productsData,
    isLoadingProductsData,
    pagination,
    onSearchFilerProducts,
    onSearchProductCode,
    onListProduct,
  }
}

export default useListProduct
