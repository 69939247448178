/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Table, Spin, Button, Input } from 'antd'
import { ClearOutlined } from '@ant-design/icons'

import { addCommas } from 'utils'
import useListProductVat from './hooks/useListProductVat'
import usePagination from 'hooks/usePagination'

const Search = Input.Search

const columns = [
  {
    title: 'รหัสสินค้า',
    dataIndex: 'code',
    key: 'code',
    width: '150px',
    render: (text, record) => (
      <Link to={`/vat/product?code=${record.code}`} target="_blank">
        {text}
      </Link>
    ),
    sorter: (a, b) => ('' + a.code).localeCompare(b.code),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'ชื่อสินค้า',
    dataIndex: 'vat_name',
    key: 'vat_name',
    width: '600px',
    sorter: (a, b) => ('' + a.vat_name).localeCompare(b.vat_name),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'ขนาดบรรจุ',
    dataIndex: 'package_size',
    key: 'package_size',
    width: '150px',
    render: (text) => <div className="text-align-right pr-2">{text}</div>,
  },
  {
    title: 'หน่วย',
    dataIndex: 'unit_name',
    key: 'unit_name',
    width: '80px',
  },
  {
    title: 'จำนวนในสต็อก1',
    dataIndex: 'stock1',
    key: 'stock1',
    sorter: (a, b) => Number(a.stock1) - Number(b.stock1),
    sortDirections: ['ascend', 'descend'],
    width: '200px',
    render: (text) => <div>{addCommas(text)}</div>,
  },
  {
    title: 'ราคาขาย',
    dataIndex: 'price_out',
    key: 'price_out',
    render: (text) => <div>{addCommas(text)}</div>,
  },
  {
    title: 'ส่วนลด',
    render: (_, record) => {
      return `${Number(record.discount_1)}+${Number(
        record.discount_2
      )}+${Number(record.discount_3)}+${Number(record.discount_4)}`
    },
  },
]

const ListProductVatPage = () => {
  const {
    currentPageNumber,
    pageSize,
    paginationSetting,
    setCurrentPageNumber,
  } = usePagination()
  const {
    listProductVatData,
    isLoadingListProductVatData,
    listProductVatPagination,
    onSearchFilerProductsVat,
    onSearchProductsVatCode,
  } = useListProductVat({ currentPageNumber, pageSize })

  const [searchText, setSearchText] = useState('')
  const [searchCode, setSearchCode] = useState('')

  useEffect(() => {
    document.title = 'รายการสินค้าทั้งหมด [VAT]'
  }, [])

  const handleSearch = (searchText) => {
    setCurrentPageNumber(1)
    onSearchFilerProductsVat({ searchTerm: searchText.toLowerCase() })
    onSearchProductsVatCode({ productCode: undefined })
  }

  const handleSearchCode = (searchCode) => {
    setCurrentPageNumber(1)
    onSearchProductsVatCode({ productCode: searchCode.toLowerCase() })
    onSearchFilerProductsVat({ searchTerm: undefined })
  }

  const onClearSearch = () => {
    // setFilteredProductList(productList)
    setCurrentPageNumber(1)
    onSearchFilerProductsVat({ searchTerm: undefined })
    onSearchProductsVatCode({ productCode: undefined })
    setSearchText('')
    setSearchCode('')
  }

  return (
    <div>
      <Spin spinning={isLoadingListProductVatData}>
        <h1>รายการสินค้าทั้งหมด [VAT]</h1>
        <div className="mb-2">
          <Search
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value)
              setSearchCode('')
            }}
            placeholder="คำค้นหา"
            onSearch={handleSearch}
            style={{ width: 200, marginRight: 10 }}
          />
          <Search
            value={searchCode}
            onChange={(event) => {
              setSearchCode(event.target.value)
              setSearchText('')
            }}
            placeholder="ค้นหาจากรหัส"
            onSearch={handleSearchCode}
            style={{ width: 200, marginRight: 10 }}
          />
          <Button
            type="primary"
            icon={<ClearOutlined />}
            onClick={onClearSearch}
          >
            เคลียร์ผลการค้นหา
          </Button>
        </div>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={listProductVatData}
          size="small"
          scroll={{ x: 1500, y: 680 }}
          style={{ height: '690px' }}
          pagination={paginationSetting({
            totalItems: listProductVatPagination?.totalItems,
            simple: true,
          })}
        />
      </Spin>
    </div>
  )
}

export default ListProductVatPage
