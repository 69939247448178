import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { unAuthorized } from '../../redux/actions'
import { Layout, Menu } from 'antd'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { DownOutlined } from '@ant-design/icons'
import { renderRoutes } from 'react-router-config'
const { SubMenu } = Menu

const { Header, Content, Footer } = Layout
const LogoText = styled.p`
  line-height: 2;
  text-align: center;
  color: white;
  font-weight: bold;
`

const CustomLayout = ({ route }) => {
  const dispatch = useDispatch()
  const authenticate = useSelector((reducers) => reducers.authenticate)
  const logOut = () => {
    return dispatch(unAuthorized())
  }
  return (
    <>
      <Layout>
        <Header
          style={{
            position: 'fixed',
            zIndex: 1,
            width: '100%',
            height: '53px',
            lineHeight: '50px',
          }}
        >
          <Link to="/">
            <div className="site-logo">
              <LogoText>Bon Lightings</LogoText>
            </div>
          </Link>
          {authenticate.is_login && (
            <Menu theme="dark" mode="horizontal">
              <SubMenu
                style={{ float: 'right' }}
                key="11"
                icon={<DownOutlined />}
                title={authenticate.username || 'unknown user'}
              >
                <Menu.ItemGroup title="User control">
                  <Menu.Item key="setting:1" onClick={logOut}>
                    Log out
                  </Menu.Item>
                </Menu.ItemGroup>
              </SubMenu>
            </Menu>
          )}
        </Header>
        <Content className="site-layout">{renderRoutes(route.routes)}</Content>
        {/* <MainPage /> */}
        <Footer style={{ textAlign: 'center' }}>Bon Lightings ©2024</Footer>
      </Layout>
    </>
  )
}

export default CustomLayout
