/* eslint-disable react-hooks/exhaustive-deps */
import React, { Component } from 'react'
import { Col, Row, Button, Table, Spin } from 'antd'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { CloseOutlined } from '@ant-design/icons'

import { SearchBox } from 'components/SearchBox'
import { CustomerInput } from 'components/CustomerInput'
import SearchSupplierVatPopUp from 'components/SearchSupplierVatPopUp'
import { ContactList } from 'components/ContactList'
import { handleAlert } from 'utils'
import './style.scss'

import {
  onListSupplier,
  fetchSearchSupplierCode,
  onSaveSupplier,
  onDeleteSupplierContact,
  onGetSupplierVatByCode,
} from 'redux/actions'

const listColumns = [
  {
    title: 'รหัสผู้ขาย',
    dataIndex: 'code',
    key: 'code',
    width: '7%',
    render: (text) => (
      <span style={{ color: 'rgb(37, 185, 255)' }}>{text}</span>
    ),
    sorter: (a, b) => ('' + a.code).localeCompare(b.code),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'ชื่อ',
    dataIndex: 'name',
    key: 'name',
    width: '25%',
    sorter: (a, b) => ('' + a.name).localeCompare(b.name),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'โทรศัพท์',
    dataIndex: 'tel',
    key: 'tel',
    width: '20%',
  },
  {
    title: 'รหัส VAT',
    dataIndex: 'supplier_vat_code',
    key: 'supplier_vat_code',
    width: '7%',
  },
  {
    title: 'ชื่อ VAT',
    dataIndex: 'supplier_vat_name',
    key: 'supplier_vat_name',
    width: '25%',
  },
  {
    title: 'เว็บไซต์',
    dataIndex: 'website',
    key: 'website',
    width: '13%',
  },
  {
    title: 'อีเมล์',
    dataIndex: 'email',
    key: 'email',
    width: '13%',
  },
]

const DEFAULT_STATE = {
  supplierList: [],
  searchList: [],
  isLoadingList: false,
  supplier: {
    id: 0,
    code: '',
    name: '',
    address: '',
    tel: '',
    fax: '',
    website: '',
    email: '',
    supplier_vat_id: '',
    supplier_vat_code: '',
    supplier_vat_name: '',
    contacts: [
      {
        id: 0,
        name: '',
        tel: '',
        email: '',
      },
    ],
  },
  isLoading: false,
  isShowSupplierVatPopUp: false,
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onListSupplier,
      fetchSearchSupplierCode,
      onSaveSupplier,
      onDeleteSupplierContact,
      onGetSupplierVatByCode,
    },
    dispatch
  )

const mapStateToProps = (state) => {
  return {
    listSupplier: state.listSupplier,
    deleteSupplierContact: state.deleteSupplierContact,
    saveSupplier: state.saveSupplier,
    searchSupplierCode: state.searchSupplierCode,
    getSupplierVatByCode: state.getSupplierVatByCode,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class index extends Component {
    constructor(props) {
      super(props)
      this.state = {
        supplierList: [],
        searchList: [],
        isLoadingList: false,
        supplier: {
          id: 0,
          code: '',
          name: '',
          address: '',
          tel: '',
          fax: '',
          website: '',
          email: '',
          supplier_vat_id: '',
          supplier_vat_code: '',
          supplier_vat_name: '',
          contacts: [
            {
              id: 0,
              name: '',
              tel: '',
              email: '',
            },
          ],
        },
        isLoading: false,
        isContactLoading: false,
        tmpContact: [],
        deleteIndex: 0,
        deleteContractID: 0,
        isShowSupplierVatPopUp: false,
      }
    }

    componentDidMount() {
      this.listSupplierHandler()
      document.title = 'ข้อมูลผู้ขาย - NoVat'
    }

    componentDidUpdate(prevProps, prevState) {
      const {
        listSupplier,
        searchSupplierCode,
        saveSupplier,
        deleteSupplierContact,
        getSupplierVatByCode,
      } = this.props
      //  listSupplier subscription state renew
      if (prevProps.listSupplier !== listSupplier) {
        this.setState({
          isLoading: listSupplier.loading,
        })
        if (
          !listSupplier.error &&
          !listSupplier.loading &&
          listSupplier.data &&
          listSupplier.finished
        ) {
          this.setState({
            supplierList: listSupplier.data,
            searchList: listSupplier.data,
          })
        }
        if (
          !listSupplier.loading &&
          listSupplier.error
        ) {
          this.handleResetField()
          handleAlert('custom', listSupplier.trace.message || '')
        }
      }

      if (prevProps.searchSupplierCode !== searchSupplierCode) {
        this.setState({ isLoading: searchSupplierCode.loading })

        if (
          !searchSupplierCode.error &&
          !searchSupplierCode.loading &&
          searchSupplierCode.finished
        ) {
          this.setState({
            supplier: {
              ...searchSupplierCode.data,
            },
          })
        }
        if (!searchSupplierCode.loading && searchSupplierCode.error) {
          handleAlert('custom', searchSupplierCode.trace.message || '')
          this.setState({
            supplier: { ...DEFAULT_STATE.supplier },
            isLoading: false,
          })
        }
      }

      if (prevProps.saveSupplier !== saveSupplier) {
        if (
          !saveSupplier.loading &&
          !saveSupplier.error &&
          saveSupplier.finished
        ) {
          handleAlert('success')
          this.setState({
            supplier: saveSupplier.data,
          })
          this.listSupplierHandler()
        }
        if (
          !saveSupplier.loading &&
          saveSupplier.error
        ) {
          handleAlert('custom', saveSupplier.trace.message || '')
        }
      }

      if (prevProps.deleteSupplierContact !== deleteSupplierContact) {
        const { supplier, tmpContact, deleteIndex } = this.state
        this.setState({ isContactLoading: deleteSupplierContact.loading })
        if (
          !deleteSupplierContact.loading &&
          !deleteSupplierContact.error &&
          deleteSupplierContact.finished
        ) {
          tmpContact.splice(deleteIndex, 1)
          this.setState({
            supplier: {
              ...supplier,
              contacts: tmpContact,
            },
          })
        }
        if (
          !deleteSupplierContact.loading &&
          deleteSupplierContact.error
        ) {
          handleAlert('custom', deleteSupplierContact.trace.message || '')
        }
      }

      if (prevProps.getSupplierVatByCode !== getSupplierVatByCode) {
        const { supplier } = this.state
        const { error, loading, finished, data, trace } = getSupplierVatByCode
        if (error) {
          handleAlert('custom', trace.message || '')
        }
        if (!loading && !error && data && finished) {
          this.setState({
            supplier: {
              ...supplier,
              supplier_vat_id: data.id,
              supplier_vat_code: data.code,
              supplier_vat_name: data.name,
            },
          })
        }
      }
    }

    saveSupplierHandler = () => {
      const { supplier } = this.state
      this.props.onSaveSupplier(supplier)
    }

    listSupplierHandler = () => {
      this.props.onListSupplier()
    }

    getSupplierHandler = (code = '') => {
      const tmpCode = code
      if (tmpCode) {
        this.props.fetchSearchSupplierCode(tmpCode)
      } else {
        handleAlert('custom', 'กรุณากรอกรหัสก่อนค้นหา')
      }
    }

    handleInputChange = (event) => {
      let value = ''
      let id = ''
      let tmp = {}
      if (typeof event === 'object') {
        value = event.target.value
        id = event.target.id
        if (id === 'code' || id === 'supplier_vat_code') {
          tmp = {
            ...this.state.supplier,
            [id]: value.toUpperCase(),
          }
        } else {
          tmp = {
            ...this.state.supplier,
            [id]: value,
          }
        }
      } else {
        value = event
        id = 'customer_type_id'
        tmp = {
          ...this.state.customer,
          [id]: value,
        }
      }
      this.setState({
        supplier: tmp,
      })
    }

    addContact = () => {
      const {
        supplier,
        supplier: { contacts },
      } = this.state
      const newContact = {
        id: 0,
        name: '',
        tel: '',
        email: '',
      }
      this.setState({
        supplier: {
          ...supplier,
          contacts: [...contacts, newContact],
        },
      })
    }

    handleContactUpdate = (changeIndex, key, value) => {
      const {
        supplier: { contacts },
      } = this.state
      const newData = contacts.map((row, index) => {
        if (index === changeIndex) {
          return {
            ...row,
            [key]: value,
          }
        }
        return row
      })
      this.setState({ supplier: { ...this.state.supplier, contacts: newData } })
    }

    deleteContactFromIndexHandler = (index, id) => {
      const {
        supplier,
        supplier: { contacts },
      } = this.state
      let tmpContact = [...contacts]
      if (id !== 0) {
        this.setState({ tmpContact, deleteIndex: index })
        this.props.onDeleteSupplierContact(id)
      } else {
        tmpContact.splice(index, 1)
        this.setState({
          supplier: {
            ...supplier,
            contacts: tmpContact,
          },
        })
      }
    }

    handleResetField = () => {
      this.setState({
        supplier: DEFAULT_STATE.supplier,
      })
    }

    handleSearch = (searchText) => {
      const { supplierList } = this.state
      const filteredData = supplierList.filter(
        ({ code, name, tel, website, email }) => {
          code = code.toString().toLowerCase()
          name = name.toString().toLowerCase()
          tel = tel.toString().toLowerCase()
          website = website.toString().toLowerCase()
          email = email.toString().toLowerCase()
          return (
            code.includes(searchText.toLowerCase()) ||
            name.includes(searchText.toLowerCase()) ||
            tel.includes(searchText.toLowerCase()) ||
            website.includes(searchText.toLowerCase()) ||
            email.includes(searchText.toLowerCase())
          )
        }
      )
      filteredData.sort((a, b) => a.code - b.code)
      this.setState({
        searchList: filteredData,
      })
    }

    onSearchSupplierCode = (code) => {
      if (code !== '') {
        this.props.onGetSupplierVatByCode(code)
      } else {
        this.setState({ isShowSupplierVatPopUp: true })
      }
    }

    onSelectSupplierPopup = (data) => {
      this.props.onGetSupplierVatByCode(data.code)
      this.setState({ isShowSupplierVatPopUp: false })
    }

    onCancelSupplierPopUp = () => {
      this.setState({ isShowSupplierVatPopUp: false })
    }

    clearSupplierVatDetail = () => {
      const { supplier } = this.state
      this.setState({
        supplier: {
          ...supplier,
          supplier_vat_id: '',
          supplier_vat_code: '',
          supplier_vat_name: '',
        },
      })
    }

    render() {
      const {
        supplier,
        supplier: { contacts },
        searchList,
        isLoadingList,
        isLoading,
        isContactLoading,
      } = this.state
      return (
        <div>
          <h1>ข้อมูลผู้ขาย</h1>
          <SearchSupplierVatPopUp
            visible={this.state.isShowSupplierVatPopUp}
            onCancel={this.onCancelSupplierPopUp}
            onClick={this.onSelectSupplierPopup}
          />
          <div className="supplier-detail">
            <Row gutter={[0, 32]}>
              <Col xs={{ offset: 1, span: 22 }} lg={{ offset: 1, span: 10 }}>
                <CustomerInput
                  label="ผู้ขาย"
                  data={supplier}
                  getDetail={this.getSupplierHandler}
                  handleInputChange={this.handleInputChange}
                  getSupplierVat={this.onSearchSupplierCode}
                  isLoading={isLoading}
                  customerType={''}
                  isSupplierPage
                />
                <Button
                  type="primary"
                  htmlType="button"
                  danger
                  icon={<CloseOutlined />}
                  onClick={this.clearSupplierVatDetail}
                >
                  ลบรหัสผู้ขาย VAT
                </Button>
              </Col>
              <Col xs={{ offset: 1, span: 22 }} lg={{ offset: 1, span: 11 }}>
                <div className="add-contact-button">
                  <Button
                    type="primary"
                    onClick={this.addContact}
                    htmlType="submit"
                  >
                    เพิ่มรายชื่อ
                  </Button>
                </div>
                <Spin spinning={isContactLoading}>
                  <table className="ant-table-header contact-table">
                    <thead className="ant-table-thead">
                      <tr>
                        <th id="del">#</th>
                        <th id="name">Name</th>
                        <th id="Tel">Tel</th>
                        <th id="Email">Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      {contacts &&
                        contacts.map((row, index) => (
                          <ContactList
                            key={index}
                            data={row}
                            index={index}
                            handleContactUpdate={this.handleContactUpdate}
                            deleteContact={this.deleteContactFromIndexHandler}
                          />
                        ))}
                    </tbody>
                  </table>
                </Spin>
              </Col>
            </Row>
            <Row>
              <Col xs={{ offset: 8, span: 12 }} lg={{ offset: 10, span: 12 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={this.saveSupplierHandler}
                >
                  บันทึก
                </Button>
                <Button
                  htmlType="button"
                  onClick={this.handleResetField}
                  danger
                >
                  ล้างข้อมูล
                </Button>
              </Col>
            </Row>
          </div>
          <Row>
            <Col xs={{ offset: 1, span: 22 }} lg={{ offset: 1, span: 22 }}>
              <h1 className="title">รายชื่อผู้ขาย</h1>
            </Col>
          </Row>
          <div className="supplier-list">
            <Row>
              <Col xs={{ offset: 1, span: 11 }}>
                <SearchBox
                  onSearch={this.handleSearch}
                  className={'search-box'}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={{ offset: 1, span: 22 }} lg={{ offset: 1, span: 22 }}>
                <Table
                  scroll={{ x: 1500, y: 680 }}
                  columns={listColumns}
                  dataSource={searchList}
                  loading={isLoadingList}
                  rowKey={(record) => record.id}
                  rowClassName={'customer-list-item'}
                  size="small"
                  style={{ height: '720px' }}
                  pagination={{
                    simple: true,
                    position: ['bottomRight'],
                    defaultPageSize: 20,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'],
                  }}
                  onRow={(record, index) => {
                    return {
                      onClick: () => {
                        this.getSupplierHandler(record.code)
                        window.scrollTo({
                          top: 0,
                          behavior: 'smooth',
                        })
                      },
                    }
                  }}
                ></Table>
              </Col>
            </Row>
          </div>
        </div>
      )
    }
  }
)
