import React from 'react'
import { Table, Button } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

import { addCommas } from 'utils'

const PaymentInvoiceTable = (props) => {
  const { list, onDeletePaymentItem } = props
  const tableColumns = [
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: '40px',
      fixed: 'left',
      render: (_, record, index) => {
        return (
          <Button
            type="default"
            danger
            size='small'
            icon={<DeleteOutlined />}
            onClick={(_) => onDeletePaymentItem(index, record)}
            disabled={record.id !== 0 ? true : false}
          />
        )
      },
    },
    {
      title: 'วิธีการชำระ',
      dataIndex: 'payment_channel_name',
      key: 'payment_channel_name',
      width: '80px',
      fixed: 'left',
    },
    {
      title: 'วันที่ชำระ',
      dataIndex: 'date',
      key: 'date',
      width: '70px',
      fixed: 'left',
    },
    {
      title: 'จำนวนเงิน',
      dataIndex: 'amount',
      key: 'amount',
      width: '90px',
      className: 'text-align-right',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
      fixed: 'left',
    },
    {
      title: 'หมายเหตุ',
      dataIndex: 'remark',
      key: 'remark',
      width: '280px',
    },
  ]

  return (
    <div>
      <Table
        rowKey={(record) => `id+${record.product_code}`}
        dataSource={list}
        columns={tableColumns}
        pagination={false}
        scroll={{ x: 600, y: 360 }}
        size="small"
        style={{
          maxWidth: '700px',
          minHeight: '360px',
          backgroundColor: '#FFFFFF',
        }}
      />
    </div>
  )
}

export default PaymentInvoiceTable
