/* eslint-disable react-hooks/exhaustive-deps */
import React, { Component } from 'react'
import { Button } from 'antd'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import styled from 'styled-components'

import { onGetCustomerBillVat } from 'redux/actions'
import { handleAlert, ArabicNumberToText, addCommas, roundToTwo } from 'utils'
import './style.scss'

const CarbonBlock = styled.div`
  @page {
    size: letter;
    margin: 0px;
  }
`

const mapStateToProps = (state) => {
  return {
    getCustomerBillVat: state.getCustomerBillVat,
  }
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ onGetCustomerBillVat }, dispatch)

// const bill = {
//   created: '2021-04-08T11:40:15.864471Z',
//   updated_at: '2021-04-08T11:40:15.864522Z',
//   bill_vat_no: 'P6404000001',
//   bill_vat_type_id: 1,
//   bill_vat_type_value: 'charge_vat',
//   bill_vat_type_name: 'เก็บค่า Vat',
//   bill_vat_date: '2021-04-08',
//   customer_data: {
//     id: 2,
//     code: 'AA0002',
//     name: 'Test',
//     tax_id: '1234567890123',
//     branch_name: 'สำนักงานใหญ่',
//     address: 'Bangkok',
//     tel: '0999999999',
//     fax: '022222222',
//     website: 'google.com',
//     email: 'test2@gmail.com',
//     remark: 'This is remark for this customer',
//   },
//   is_full_tax_invoice: false,
//   full_tax_invoice_type: '',
//   sale_person: 'WXT',
//   product_is_included_vat: true,
//   sale_channel_id: 1,
//   sale_channel_name: 'Lazada',
//   purchase_order_number: 'POWXT0001',
//   percent_vat: '7.00',
//   special_discount_baht: '0.0000',
//   special_discount_percent: '0.0000',
//   total_charging_vat: '0.0000',
//   value_added_tax: '161.51',
//   grand_total_with_vat: '2468.75',
//   total_before_special_discount: '2307.24',
//   total_after_special_discount: '2307.24',
//   total_items: 3,
//   products_vat: [
//     {
//       id: 97,
//       created: '2021-04-08T11:40:15.878880Z',
//       updated_at: '2021-04-08T11:40:15.878919Z',
//       product_vat_id: 2,
//       product_vat_code: '0020',
//       product_vat_name: 'Test Name +++++++++asdfasdfasdfasdfasdfasdfasdfasdf',
//       product_normal_id: '',
//       product_normal_code: '',
//       product_normal_name: '',
//       package_size: '12',
//       stock1: 2369,
//       unit_id: 2,
//       unit_name: 'ชิ้น',
//       quantity: 5,
//       price_out_with_vat: '100.0000',
//       price_out: 93.4579,
//       discount_1: '0.0000',
//       discount_2: '0.0000',
//       discount_3: '0.0000',
//       discount_4: '0.0000',
//       total: '467.2900',
//       is_allow_low: false,
//       last_purchased: '2021-04-08',
//       remark: 'ดำ',
//     },
//     {
//       id: 98,
//       created: '2021-04-08T11:40:15.894396Z',
//       updated_at: '2021-04-08T11:40:15.894437Z',
//       product_vat_id: 3,
//       product_vat_code: '0021',
//       product_vat_name: 'ชื่อสินค้าปกติ',
//       product_normal_id: '',
//       product_normal_code: '',
//       product_normal_name: '',
//       package_size: '10',
//       stock1: 492,
//       unit_id: 1,
//       unit_name: 'หลอด',
//       quantity: 10,
//       price_out_with_vat: '100.0000',
//       price_out: 93.4579,
//       discount_1: '10.0000',
//       discount_2: '0.0000',
//       discount_3: '0.0000',
//       discount_4: '0.0000',
//       total: '841.1200',
//       is_allow_low: false,
//       last_purchased: '2021-04-08',
//       remark: '...',
//     },
//     {
//       id: 99,
//       created: '2021-04-08T11:40:15.907592Z',
//       updated_at: '2021-04-08T11:40:15.907624Z',
//       product_vat_id: 4,
//       product_vat_code: '0023',
//       product_vat_name: 'หลอดกลมแสงอ่อน',
//       product_normal_id: '',
//       product_normal_code: '',
//       product_normal_name: '',
//       package_size: '12',
//       stock1: 2145,
//       unit_id: '',
//       unit_name: '',
//       quantity: 5,
//       price_out_with_vat: '500.0000',
//       price_out: 467.2897,
//       discount_1: '50.0000',
//       discount_2: '10.0000',
//       discount_3: '5.0000',
//       discount_4: '0.0000',
//       total: '998.8300',
//       is_allow_low: false,
//       last_purchased: '2021-04-08',
//       remark: '',
//     },
//   ],
//   remark: '',
// }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class index extends Component {
    constructor(props) {
      super(props)
      this.state = {
        bill: {},
        isLoading: false,
        isDataAvailable: false,
      }
    }

    componentDidMount() {
      let search = window.location.search
      let params = new URLSearchParams(search)
      let bill_no = params.get('bill_no')
      this.getBillFromQuery(bill_no)
    }

    componentDidUpdate(prevProps, PrevState) {
      const { getCustomerBillVat } = this.props
      if (prevProps.getCustomerBillVat !== getCustomerBillVat) {
        const { error, loading, finished, data, trace } = getCustomerBillVat
        this.setState({ isLoading: loading })
        if (!loading && !error && finished) {
          this.setState({
            bill: data,
            isDataAvailable: true,
          })
          setTimeout(() => {
            window.print()
          }, 1000)
        }
        if (!loading && error) {
          handleAlert('error', trace.message ? trace.message : '')
          this.setState({ isLoading: loading })
        }
      }
    }

    getBillFromQuery = (bill_no) => {
      this.props.onGetCustomerBillVat(bill_no)
    }

    renderDiscount = (discount1, discount2, discount3, discount4) => {
      let renderDiscount = 0
      renderDiscount = 100 * (1 - Number(discount1) / 100)
      renderDiscount *= 1 - Number(discount2) / 100
      renderDiscount *= 1 - Number(discount3) / 100
      renderDiscount *= 1 - Number(discount4) / 100
      renderDiscount = 100 - renderDiscount
      return (
        <div className="carbon-fifth-column">
          {renderDiscount > 0 ? roundToTwo(renderDiscount) + '%' : ''}
        </div>
      )
    }

    renderPage = () => {
      const { bill } = this.state
      return (
        <CarbonBlock className="carbon-pdf">
          <div className="carbon-bill-no">{bill.bill_vat_no}</div>
          <div className="carbon-customer-detail">
            <div className="first-section">
              <div className="customer-code">{bill.customer_data.code}</div>
              <div className="customer-name">{bill.customer_data.name}</div>
              <div className="customer-branch">
                {bill.customer_data.branch_name}
              </div>
              <div className="customer-tax-id">{bill.customer_data.tax_id}</div>
              <div className="customer-address">
                <span>&nbsp;</span>
                {bill.customer_data.address}
              </div>
              <div className="customer-tel">
                Tel.: {bill.customer_data.tel ? bill.customer_data.tel : '-'}
              </div>
            </div>
            <div className="second-section">
              <div>{bill.bill_vat_date}</div>
              <div className="pay-duration-date">1</div>
              <div className="payment-date">
                {moment(bill.bill_vat_date)
                  .add(1, 'months')
                  .format('YYYY-MM-DD')}
              </div>
              <div className="sale-person">
                {bill.sale_person ? bill.sale_person : '-'}
              </div>
              <div className="purchase-order-number">
                {bill.purchase_order_number ? bill.purchase_order_number : '-'}
              </div>
            </div>
          </div>
          <div className="carbon-product-section">
            {bill.products_vat &&
              bill.products_vat.map((product, index) => {
                return (
                  <div className="carbon-product-row" key={index}>
                    <div className="carbon-first-column">{index + 1}</div>
                    <div className="carbon-second-column">
                      {addCommas(product.quantity)}
                    </div>
                    <div className="carbon-third-column">
                      {product.unit_name}
                    </div>
                    <div className="carbon-fourth-column">
                      {product.product_vat_name} {product.remark}
                    </div>
                    {this.renderDiscount(
                      product.discount_1,
                      product.discount_2,
                      product.discount_3,
                      product.discount_4
                    )}
                    <div className="carbon-sixth-column">
                      {addCommas(product.price_out)}
                    </div>
                    <div className="carbon-seventh-column">
                      {addCommas(product.total)}
                    </div>
                  </div>
                )
              })}
          </div>
          <div className="carbon-total-section">
            <div className="carbon-total-summary">
              {addCommas(bill.special_discount_baht)}
            </div>
            <div className="carbon-total-summary">
              {addCommas(bill.total_after_special_discount)}
            </div>
            <div className="carbon-total-summary">{bill.value_added_tax}</div>
            <div className="carbon-grand-total-row">
              <div className="carbon-grand-total-text">
                {ArabicNumberToText(bill.grand_total_with_vat)}
              </div>
              <div className="carbon-grand-total-value">
                {addCommas(bill.grand_total_with_vat)}
              </div>
            </div>
          </div>
        </CarbonBlock>
      )
    }

    render() {
      const { isDataAvailable } = this.state
      return (
        <div>
          <Button type="primary" className="no-print" onClick={window.print}>
            พิมพ์บิล
          </Button>
          {isDataAvailable && this.renderPage()}
        </div>
      )
    }
  }
)
