/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import CustomLayout from 'pages/CustomLayout'
import SplashPage from 'pages/SplashPage'
export default () => {
  return [
    {
      path: '*',
      exact: true,
      component:CustomLayout,
      routes: [
        {
          path:"",
          component:SplashPage,
          exact:true
        }
      ]

    },
  ];
}
