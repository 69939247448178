import { request } from '../../api/request'

export const AUTHORIZED = 'AUTHORIZED'
export const UNAUTHORIZED = 'UNAUTHORIZED'
export const AUTHORIZING = 'AUTHORIZING'

export const getCredential = (payload) => (dispatch) => {
  dispatch({ type: AUTHORIZING, payload })
  const tokenToString = () => {
    const token = localStorage.getItem('bearerToken')
    return token
  }
  if (!tokenToString() || tokenToString() === '') {
    return dispatch(unAuthorized())
  }
  return request({
    url: 'api/auth/credential',
    method: 'get',
    headers: {
      Authorization: `Bearer ${tokenToString()}`,
    },
  })
    .then((response) => response.data)
    .then((data) => data.user)
    .then((json) => dispatch(authorized(json)))
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        return dispatch(unAuthorized())
      }
    })
}

export const unAuthorized = () => (dispatch) => {
  localStorage.removeItem('bearerToken')
  localStorage.removeItem('menu')
  return dispatch({
    type: UNAUTHORIZED,
  })
}

export const authorized = (payload) => (dispatch) => {
  return dispatch({
    type: AUTHORIZED,
    payload,
  })
}

export const loginAuthorized = (payload, menu) => (dispatch) => {
  localStorage.setItem('bearerToken', payload.access)
  localStorage.setItem('bearerRefresh', payload.refresh)
  localStorage.setItem('menu', menu)
  dispatch(getCredential())
}

export const refreshToken = () => (dispatch) => {
  const tokenToString = () => {
    const token = localStorage.getItem('bearerToken')
    return token
  }
  if (!tokenToString()) {
    return unAuthorized()
  }
}

export default function authAction(path, method = 'get', body = {}, transform) {
  return (dispatch) => {
    const getDataStart = () => ({
      type: AUTHORIZING,
      payload: body,
    })

    const getError = (error) => ({
      type: UNAUTHORIZED,
      error,
    })

    const fetchDataWithRetry = (delay) =>
      request({
        url: path,
        method,
        data: method === 'post' && body ? body : undefined,
      })
        .then((response) => {
          if (!response.status) {
            const err = new Error()
            err.fetchError = true
            dispatch(getError(response.status))
            if (response.status >= 400 && response.status < 500) {
              err.clientError = true
              err.message = 'fetch failed - client error'
            } else {
              err.message = 'fetch failed - retrying'
            }
            throw err
          }
          return response.data
        })
        .then(transform || ((json) => json))
        .then((json) => dispatch(loginAuthorized(json, body.menu)))
        .catch((e) => {
          // eslint-disable-next-line no-console
          if (e.code === 'ECONNABORTED') {
            return dispatch(getError({ cause: 'ECONNABORTED' }))
          }
          if (e.fetchError && !e.clientError) {
            setTimeout(() => fetchDataWithRetry(delay + 3000), delay)
          }
          if (!e.fetchError) {
            // console.log({ ...e })
            return dispatch(getError(e?.response?.data))
          }
        })
    dispatch(getDataStart())
    return fetchDataWithRetry(300)
  }
}
