/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Button, Spin, Radio, Divider, Input, Popconfirm } from 'antd'
import { SaveOutlined, ClearOutlined, SearchOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import InlineInput from 'components/InlineInput'
import AlterStockTable from 'components/AlterStockTable'
import SearchProductNoVatPopUp from 'components/SearchProductNoVatPopUp'
import { handleAlert, roundToTwo } from 'utils'

import {
  onGetUnitList,
  onGetProductByCode,
  onSaveAlterStock,
  onSaveLastCheckStockDate,
  onGetLastCheckStock,
  onListAlterStock,
} from 'redux/actions'

const DEFAULT_FORM = {
  product_id: 0,
  product_code: '',
  product_name: '',
  unit_id: '',
  stock1: 0,
  stock2: 0,
  increase_stock: '',
  reduce_stock: '',
  issued_date: moment().format('YYYY-MM-DD'),
}

const DEFAULT_SEARCH_FORM = {
  product_code: '',
  start_date: moment().format('YYYY-MM-DD'),
  end_date: moment().format('YYYY-MM-DD'),
}

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
  marginBottom: '10px',
}

const AlterStockPage = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isDisableName, setIsDisableName] = useState(false)
  const [unitList, setUnitList] = useState([])
  const [disableToggle, setDisableToggle] = useState(false)
  const [isShowProductPopUp, setIsShowProductPopUp] = useState(false)
  const [form, setForm] = useState(DEFAULT_FORM)
  const [lastCheckStockDate, setLastCheckStockDate] = useState('')
  const [searchForm, setSearchForm] = useState(DEFAULT_SEARCH_FORM)
  const [alterStockList, setAlterStockList] = useState([])
  const dispatch = useDispatch()
  const getUnitList = useSelector((state) => state.getUnitList)
  const getLastCheckStock = useSelector((state) => state.getLastCheckStock)
  const getProductByCode = useSelector((state) => state.getProductByCode)
  const saveAlterStock = useSelector((state) => state.saveAlterStock)
  const saveLastCheckStockDate = useSelector(
    (state) => state.saveLastCheckStockDate
  )
  const listAlterStock = useSelector((state) => state.listAlterStock)
  useEffect(() => {
    setIsLoading(getUnitList.loading)
    if (!getUnitList.loading && !getUnitList.error && getUnitList.finished) {
      setUnitList(getUnitList.data)
    }
    if (!getUnitList.loading && getUnitList.error) {
      handleAlert(
        'error',
        getUnitList.trace.message
          ? getUnitList.trace.message
          : 'กรุณาลองใหม่อีกครั้ง'
      )
    }
  }, [getUnitList])

  useEffect(() => {
    // useDispatch(()=>)
    setIsLoading(getLastCheckStock.loading)
    if (
      !getLastCheckStock.loading &&
      !getLastCheckStock.error &&
      getLastCheckStock.finished
    ) {
      setLastCheckStockDate(getLastCheckStock.data.date)
    }

    if (!getLastCheckStock.loading && getLastCheckStock.error) {
      handleAlert(
        'error',
        getLastCheckStock.trace.message
          ? getLastCheckStock.trace.message
          : 'กรุณาลองใหม่อีกครั้ง'
      )
    }
  }, [getLastCheckStock])

  useEffect(() => {
    dispatch(onGetUnitList())
    dispatch(onGetLastCheckStock())
    document.title = 'แก้ไขจำนวนสินค้า - NoVat'
  }, [])

  useEffect(() => {
    setIsLoading(getProductByCode.loading)
    if (
      !getProductByCode.loading &&
      !getProductByCode.error &&
      getProductByCode.finished
    ) {
      setIsDisableName(true)
      handleAlert('success')
      setForm({
        product_id: getProductByCode.data.id,
        product_code: getProductByCode.data.code,
        product_name: getProductByCode.data.name,
        unit_id: getProductByCode.data.unit_id,
        stock1: getProductByCode.data.stock1,
        stock2: getProductByCode.data.stock2,
        increase_stock: '',
        reduce_stock: '',
        issued_date: moment().format('YYYY-MM-DD'),
      })
    }
    if (!getProductByCode.loading && getProductByCode.error) {
      setIsDisableName(false)

      handleAlert('custom', getProductByCode.trace.message)
    }
  }, [getProductByCode])

  useEffect(() => {
    setIsLoading(saveAlterStock.loading)
    if (
      !saveAlterStock.loading &&
      !saveAlterStock.error &&
      saveAlterStock.finished
    ) {
      handleAlert('success')
      setForm(DEFAULT_FORM)
    }
    if (!saveAlterStock.loading && saveAlterStock.error) {
      handleAlert(
        'custom',
        `ไม่สามารถทำรายการได้ - ${saveAlterStock.trace.message}`
      )
    }
  }, [saveAlterStock])

  const onSaveAlterStockHandler = () => {
    const data = {
      product_code: form.product_code,
      increase_stock: Number(form.increase_stock),
      reduce_stock: Number(form.reduce_stock),
      issued_date: form.issued_date,
    }
    dispatch(onSaveAlterStock(data))
  }

  useEffect(() => {
    setIsLoading(saveLastCheckStockDate.loading)
    if (
      !saveLastCheckStockDate.loading &&
      !saveLastCheckStockDate.error &&
      saveLastCheckStockDate.finished
    ) {
      handleAlert('success')
      setLastCheckStockDate(saveLastCheckStockDate.data.date)
    }

    if (!saveLastCheckStockDate.loading && saveLastCheckStockDate.error) {
      handleAlert(
        'custom',
        `ไม่สามารถทำรายการได้ - ${saveLastCheckStockDate.trace.message}`
      )
    }
  }, [saveLastCheckStockDate])

  const onSaveLastCheckStockDateHandler = (date) => {
    const data = {
      date: date,
    }
    dispatch(onSaveLastCheckStockDate(data))
  }
  useEffect(() => {
    setIsLoading(listAlterStock.loading)
    if (
      !listAlterStock.loading &&
      !listAlterStock.error &&
      listAlterStock.finished
    ) {
      handleAlert('success')
      setAlterStockList(listAlterStock.data)
    }
    if (!listAlterStock.loading && listAlterStock.error) {
      handleAlert(
        'custom',
        `ไม่สามารถทำรายการได้ - ${listAlterStock.trace.message}`
      )
    }
  }, [listAlterStock])

  const onSearchAlterStockHandler = () => {
    dispatch(
      onListAlterStock(
        searchForm.product_code,
        searchForm.start_date,
        searchForm.end_date
      )
    )
  }

  const renderUnit = () => {
    let unitName = ''
    let key = ''
    unitList.forEach((x, index) => {
      if (x.id === form.unit_id) {
        unitName = x.name
        key = index
      }
    })
    return (
      <div style={{ marginTop: '6px' }} key={key}>
        {unitName}
      </div>
    )
  }

  const onRadioChange = (e) => {
    if (e.target.value === 1) {
      setDisableToggle(false)
      setForm({
        ...form,
        reduce_stock: '',
      })
    } else {
      setDisableToggle(true)
      setForm({
        ...form,
        increase_stock: '',
      })
    }
  }

  const onSearchProductHandler = (code) => {
    if (code !== '') {
      dispatch(onGetProductByCode(code))
    } else {
      setIsShowProductPopUp(true)
    }
  }

  const onCancelProdutPopUp = () => {
    setIsShowProductPopUp(false)
  }

  const onSelectProductPopUp = (data) => {
    onSearchProductHandler(data.code)
    setIsShowProductPopUp(false)
  }

  const onClearInput = () => {
    setForm(DEFAULT_FORM)
    setIsDisableName(false)
    setAlterStockList([])
    setSearchForm(DEFAULT_SEARCH_FORM)
  }

  const renderTotalStock = () => {
    let total = 0.0
    total = Number(form.stock1) + Number(form.stock2)

    return (
      <div className="input-item">
        <InlineInput
          label="จำนวนรวม"
          width="150px"
          value={roundToTwo(total).toFixed(2)}
          disabled
        />
      </div>
    )
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'inline-block' }}>
          <h1>แก้ไขจำนวนสินค้า</h1>
        </div>
        <div style={{ display: 'inline-block' }}>
          <Popconfirm
            title="คุณต้องการล้างหน้าจอใช่หรือไม่ ?"
            okText="ใช่"
            cancelText="ยกเลิก"
            onConfirm={onClearInput}
          >
            <Button
              type="default"
              danger
              icon={<ClearOutlined />}
              size="large"
              block
            >
              ล้างหน้าจอ
            </Button>
          </Popconfirm>
        </div>
      </div>
      <SearchProductNoVatPopUp
        visible={isShowProductPopUp}
        onCancel={onCancelProdutPopUp}
        onClick={onSelectProductPopUp}
      />
      <Spin spinning={isLoading}>
        <div className="form-wrapper">
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                onSearch={(value) => {
                  onSearchProductHandler(value)
                }}
                inputOption="search"
                label="รหัสสินค้า"
                placeholder="รหัสสินค้า"
                isLoading={false}
                required={true}
                value={form.product_code}
                onChange={(event) => {
                  setForm({
                    ...form,
                    product_code: event.target.value.toUpperCase(),
                  })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ชื่อสินค้า"
                width="400px"
                value={form.product_name}
                disabled={isDisableName}
                onChange={(event) =>
                  setForm({ ...form, product_name: event.target.value })
                }
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="จำนวนในสต๊อก 1"
                width="150px"
                disabled
                value={form.stock1 ? form.stock1 : ''}
                onChange={(event) =>
                  setForm({ ...form, stock1: event.target.value })
                }
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="จำนวนในสต๊อก 2"
                width="150px"
                disabled
                value={form.stock2 ? form.stock2 : ''}
                onChange={(event) =>
                  setForm({ ...form, stock2: event.target.value })
                }
              />
            </div>
            {renderTotalStock()}
            {renderUnit()}
          </div>
          <Divider style={{ margin: '10px 0px 20px' }} />
          <div className="input-wrapper">
            <div style={{ width: '270px' }}>
              <Radio.Group
                defaultValue={1}
                onChange={(event) => onRadioChange(event)}
              >
                <Radio style={radioStyle} value={1}>
                  รับเพิ่ม
                  <Input
                    disabled={disableToggle}
                    style={{ width: 120, marginLeft: 35 }}
                    value={form.increase_stock}
                    onChange={(event) =>
                      setForm({ ...form, increase_stock: event.target.value })
                    }
                  />
                </Radio>
                <Radio style={radioStyle} value={2}>
                  ลดจำนวน
                  <Input
                    disabled={!disableToggle}
                    style={{ width: 120, marginLeft: 17 }}
                    value={form.reduce_stock}
                    onChange={(event) =>
                      setForm({ ...form, reduce_stock: event.target.value })
                    }
                  />
                </Radio>
              </Radio.Group>
            </div>
            <div style={{ width: '270px' }}>
              <div className="input-wrapper">
                <div className="input-item">
                  <InlineInput
                    label="วันที่"
                    placeholder="เลือกวัน"
                    defaultValue={moment()}
                    value={form.issued_date && moment(form.issued_date)}
                    inputOption="date_picker"
                    onChange={(_, dateString) => {
                      setForm({ ...form, issued_date: dateString })
                    }}
                  />
                </div>
              </div>
              <Button
                type="primary"
                icon={<SaveOutlined />}
                style={{
                  marginLeft: '44px',
                  marginTop: '10px',
                  width: '140px',
                  height: '45px',
                }}
                size="medium"
                onClick={onSaveAlterStockHandler}
              >
                บันทึก
              </Button>
            </div>
            <div
              style={{
                width: 'auto',
                padding: '10px',
                border: '2px #cccccc solid',
              }}
            >
              <h3>นับสต็อกครั้งสุดท้าย</h3>
              <div className="input-wrapper">
                <div className="input-item" style={{ marginLeft: '20px' }}>
                  <InlineInput
                    label="วันที่"
                    placeholder="เลือกวัน"
                    defaultValue={moment()}
                    value={lastCheckStockDate && moment(lastCheckStockDate)}
                    inputOption="date_picker"
                    onChange={(_, dateString) => {
                      setLastCheckStockDate(dateString)
                    }}
                  />
                </div>
                <div className="input-item">
                  <Popconfirm
                    title="คุณต้องการบันทึกวันที่นับสต๊อกครั้งสุดท้ายใหม่ใช่ไหริอไม่ ?"
                    okText="ใช่"
                    cancelText="ยกเลิก"
                    onConfirm={() =>
                      onSaveLastCheckStockDateHandler(lastCheckStockDate)
                    }
                  >
                    <Button
                      type="primary"
                      icon={<SaveOutlined />}
                      style={{
                        marginLeft: '44px',
                        width: '140px',
                      }}
                      size="medium"
                    >
                      บันทึก
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            </div>
          </div>
          <Divider style={{ margin: '20px 0px 30px' }} />
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="รหัสสินค้า"
                placeholder="รหัสสินค้า"
                value={searchForm.product_code}
                onChange={(event) => {
                  setSearchForm({
                    ...searchForm,
                    product_code: event.target.value.toUpperCase(),
                  })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="วันที่เริ่มค้นหา"
                placeholder="เลือกวัน"
                defaultValue={moment()}
                value={searchForm.start_date && moment(searchForm.start_date)}
                inputOption="date_picker"
                onChange={(_, dateString) => {
                  setSearchForm({ ...searchForm, start_date: dateString })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ถึง"
                placeholder="เลือกวัน"
                defaultValue={moment()}
                value={searchForm.end_date && moment(searchForm.end_date)}
                inputOption="date_picker"
                onChange={(_, dateString) => {
                  setSearchForm({ ...searchForm, end_date: dateString })
                }}
              />
            </div>
            <div className="input-item">
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="medium"
                onClick={onSearchAlterStockHandler}
              >
                ค้นหา
              </Button>
            </div>
          </div>
          <AlterStockTable alterStockList={alterStockList} />
        </div>
      </Spin>
    </div>
  )
}

export default withRouter(AlterStockPage)
