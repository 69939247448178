import React from 'react'
import { Table, Input, Button } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import styled from 'styled-components'

const InputGroup = styled.div`
  display: flex;
  align-items: center;
  .discount-input {
    width: 60px;
    padding-left: 2px;
    padding-right: 2px;
    text-align: center;
  }
`

const QuotationProductTable = (props) => {
  const { onChange, onDelete } = props
  const tableColumns = [
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: '50px',
      fixed: 'left',
      render: (_, record, index) => {
        return (
          <Button
            size="small"
            type="primary"
            danger
            onClick={() => onDelete(record, index)}
            icon={<DeleteOutlined />}
          />
        )
      },
    },
    {
      title: 'รหัส',
      dataIndex: 'code',
      key: 'code',
      width: '70px',
      fixed: 'left',
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'name',
      key: 'name',
      width: '400px',
      fixed: 'left',
      render: (_, record, index) => {
        return (
          <Input
            value={record.name}
            size='small'
            onChange={(event) =>
              onChange(record, 'name', event.target.value, index)
            }
          />
        )
      },
    },
    {
      title: 'จำนวน',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '80px',
      render: (_, record, index) => {
        return (
          <Input
            className="text-align-right"
            value={record.quantity}
            size='small'
            onChange={(event) =>
              onChange(record, 'quantity', event.target.value, index)
            }
          />
        )
      },
    },
    {
      title: 'หน่วย',
      dataIndex: 'unit_name',
      key: 'unit_name',
      width: '100px',
      render: (_, record, index) => {
        return (
          <Input
            className="text-align-right"
            value={record.unit_name}
            size='small'
            onChange={(event) =>
              onChange(record, 'unit_name', event.target.value, index)
            }
          />
        )
      },
    },
    {
      title: 'ราคาต่อหน่วย',
      dataIndex: 'price_out',
      key: 'price_out',
      width: '130px',
      className: 'text-align-right',
      render: (_, record, index) => {
        return (
          <Input
            className="text-align-right"
            value={record.price_out}
            size='small'
            onChange={(event) =>
              onChange(record, 'price_out', event.target.value, index)
            }
          />
        )
      },
    },
    {
      title: 'ส่วนลด',
      dataIndex: '',
      key: 'v',
      width: '300px',
      render: (_, record, index) => {
        return (
          <InputGroup>
            <Input
              className="discount-input"
              value={record.discount_1}
              size='small'
              onChange={(event) =>
                onChange(record, 'discount_1', event.target.value, index)
              }
            />
            +
            <Input
              className="discount-input"
              value={record.discount_2}
              size='small'
              onChange={(event) =>
                onChange(record, 'discount_2', event.target.value, index)
              }
            />
            +
            <Input
              className="discount-input"
              value={record.discount_3}
              size='small'
              onChange={(event) =>
                onChange(record, 'discount_3', event.target.value, index)
              }
            />
            +
            <Input
              className="discount-input"
              value={record.discount_4}
              size='small'
              onChange={(event) =>
                onChange(record, 'discount_4', event.target.value, index)
              }
            />
          </InputGroup>
        )
      },
    },
    {
      title: 'ยอดรวม',
      dataIndex: 'total',
      key: 'total',
      width: '110px',
      render: (text) => <div className="text-align-right">{text}</div>,
    },
    {
      title: 'หมายเหตุ',
      dataIndex: 'remark',
      key: 'remark',
      width: '200px',
      render: (_, record, index) => {
        return (
          <Input
            value={record.remark}
            size='small'
            onChange={(event) =>
              onChange(record, 'remark', event.target.value, index)
            }
          />
        )
      },
    },
  ]

  return (
    <div>
      <Table
        rowKey={(record) => `id+${record.product_code}`}
        dataSource={props.productList}
        columns={tableColumns}
        pagination={false}
        scroll={{ x: 1500 }}
        style={{ minHeight: '320px', backgroundColor: '#FFFFFF'}}
        size='small'
      />
    </div>
  )
}

export default QuotationProductTable
