/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Button, Spin, Divider, Modal, Popconfirm } from 'antd'
import {
  SaveOutlined,
  ClearOutlined,
  CloseOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import InlineInput from 'components/InlineInput'
import DiscountInput from 'components/InlineInput/DiscountInput'
import StockProductTable from 'components/StockProductTable'
import SearchProductNoVatPopUp from 'components/SearchProductNoVatPopUp'
import SearchSupplierPopUp from 'components/SearchSupplierPopUp'
import SearchSupplierBillPopUp from 'components/SearchSupplierBillPopUp'
import { handleAlert, useQuery } from 'utils'

import {
  getProductWithLastestBoughtSupplier,
  fetchPaymentDurationList,
  fetchUnitList,
  fetchSearchSupplierCode,
  fetchSupplierBill,
  onDeleteSupplierBill,
  onSaveStock,
} from 'redux/actions'

const DEFAULT_FORM = {
  id: 0,
  supplier_id: 0,
  supplier_code: '',
  supplier_name: '',
  vat: 0,
  bill_no: '',
  bill_date: moment().format('YYYY-MM-DD'),
  payment_duration: '',
  payment_date: '',
  special_discount_baht: 0,
  special_discount_percent: 0,
  total_before_special_discount: 0,
  total_after_special_discount: 0,
  grand_total_with_vat: 0,
  products: [],
}

const DEFAULT_PRODUCT = {
  product_id: 0,
  product_code: '',
  product_name: '',
  quantity: '',
  unit_id: '',
  unit_name: '',
  price_in: '',
  discount_1: 0,
  discount_2: 0,
  discount_3: 0,
  discount_4: 0,
  discount_5: 0,
  discount_6: 0,
  lead_time: '',
  total: '',
}

const StockPage = (props) => {
  function useAsyncState(initialValue) {
    const [value, setValue] = useState(initialValue)
    const setter = (x) =>
      new Promise((resolve) => {
        setValue(x)
        resolve(x)
      })
    return [value, setter]
  }
  const query = useQuery()
  const bill_no = query.get('bill_no')
  const [form, setForm] = useAsyncState(DEFAULT_FORM)
  const [product, setProduct] = useAsyncState(DEFAULT_PRODUCT)
  const [isShowProductPopUp, setIsShowProductPopUp] = useState(false)
  const [isShowSupplierPopUp, setIsShowSupplierPopUp] = useState(false)
  const [isShowBilltPopUp, setIsShowBillPopUp] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [disableDiscountBaht, setDisableDiscountBaht] = useState(false)
  const [disableDiscountPercent, setDisableDiscountPercent] = useState(false)
  const paymentDurationList = useSelector((state) => state.paymentDurationList)
  const saveStock = useSelector((state) => state.saveStock)
  const deleteSupplierBill = useSelector((state) => state.deleteSupplierBill)
  const unitList = useSelector((state) => state.unitList)
  const searchProductCode = useSelector((state) => state.searchProductCode)
  const searchSupplierCode = useSelector((state) => state.searchSupplierCode)
  const supplierBill = useSelector((state) => state.supplierBill)


  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchPaymentDurationList())
    dispatch(fetchUnitList())
    document.title = 'รับสินค้าเข้าสต๊อก - NoVat'
  }, [])

  useEffect(() => {
    if (bill_no) {
      setIsLoading(true)
      dispatch(fetchSupplierBill(bill_no))
    }
  }, [bill_no, props.history])

  useEffect(() => {
    const { trace, loading, error, finished, data } = searchSupplierCode
    setIsLoading(loading)
    if (error) {
      handleAlert('custom', trace.message || '')
      setForm({
        ...form,
        supplier_id: 0,
        supplier_name: '',
      })
    }
    if (!error && data && finished) {
      handleAlert('success')
      setForm({
        ...form,
        supplier_id: data.id,
        supplier_code: data.code,
        supplier_name: data.name,
      })
    }
  }, [searchSupplierCode]) // Only re-subscribe if props.searchSupplierCode changes

  const initialSpecialDiscount = (form) => {
    if (Number(form.special_discount_baht) > 0) {
      setDisableDiscountPercent(true)
    } else if (Number(form.special_discount_percent) > 0) {
      setDisableDiscountBaht(true)
    } else {
      setDisableDiscountPercent(false)
      setDisableDiscountBaht(false)
    }
  }

  useEffect(() => {
    const { trace, loading, error, finished, data } = supplierBill
    setIsLoading(loading)
    if (!loading && error) {
      setForm(DEFAULT_FORM)
      handleAlert('custom', trace.message || '')
    }
    if (!loading && !error && data && finished) {
      handleAlert('success')
      setForm(data).then((_form) => {
        initialSpecialDiscount(_form)
      })
    }
  }, [supplierBill]) // Only re - subscribe if props.supplierBill changes

  useEffect(() => {
    const { trace, loading, error, finished, data } = searchProductCode
    setIsLoading(loading)
    if (error) {
      handleAlert('custom', trace.message || '')
    }
    if (!loading && !error && data && finished) {
      setProduct({
        ...data,
        total: 0,
        id: 0,
      })
    }
  }, [searchProductCode]) // Only re - subscribe if props.searchProductCode changes

  const onBillDateChange = (form) => {
    let paymentDate = {}
    let duration = ''
    if (form.payment_duration && form.bill_date) {
      duration = paymentDurationList.data.find(
        (x) => x.id === form.payment_duration
      ).name
      paymentDate = moment(form.bill_date)
        .add(duration, 'days')
        .format('YYYY-MM-DD')
      setForm({
        ...form,
        payment_date: paymentDate,
      })
    }
  }

  const handleVatChange = (form) => {
    if (form.products.length !== 0) {
      onChangeCalCulatedValue(form)
    }
  }

  const onClearInput = () => {
    setForm(DEFAULT_FORM)
    setProduct(DEFAULT_PRODUCT)
    setDisableDiscountBaht(false)
    setDisableDiscountPercent(false)
  }

  const onChangeSupplierCode = (current_value) => {
    onClearInput()
    setForm((latestForm) => ({ ...latestForm, supplier_code: current_value }))
  }

  const roundToTwo = (num) => +(Math.round(num + 'e+2') + 'e-2')

  const sumProductTotal = (product) => {
    let total = 0
    total = Number(product.quantity) * Number(product.price_in)
    total *= Number(product.discount_1)
      ? 1 - Number(product.discount_1) / 100
      : 1
    total *= Number(product.discount_2)
      ? 1 - Number(product.discount_2) / 100
      : 1
    total *= Number(product.discount_3)
      ? 1 - Number(product.discount_3) / 100
      : 1
    total *= Number(product.discount_4)
      ? 1 - Number(product.discount_4) / 100
      : 1
    total *= Number(product.discount_5)
      ? 1 - Number(product.discount_5) / 100
      : 1
    total *= Number(product.discount_6)
      ? 1 - Number(product.discount_6) / 100
      : 1
    return roundToTwo(total).toFixed(2)
  }
  const addProductToList = (_product) => {
    let isDuplicatedProduct = false
    form.products.forEach((x) => {
      if (x.product_code === _product.product_code) {
        return (isDuplicatedProduct = true)
      }
    })
    if (!isDuplicatedProduct) {
      if (_product.product_id !== 0) {
        let sumTotal = 0
        sumTotal = sumProductTotal(_product)
        setProduct({
          ..._product,
          total: sumTotal,
        }).then((product) => {
          const oldProduct = form.products
          setForm({
            ...form,
            products: [...oldProduct, product],
          })
            .then((form) => {
              onChangeCalCulatedValue(form)
            })
            .then(() => setProduct(DEFAULT_PRODUCT))
        })
      } else {
        handleAlert('custom', 'กรุณากดค้นหาสินค้า ก่อนเพิ่มรายการ')
      }
    } else {
      handleAlert(
        'custom',
        'มีสินค้านี้อยู่ในรายการอยู่แล้ว กรุณาเพิ่มสินค้าใหม่'
      )
      setProduct(DEFAULT_PRODUCT)
    }
  }

  const onChangeCalCulatedValue = (form) => {
    let grandTotal = 0
    let sumBeforeDiscount = 0
    let sumAfterDiscount = 0
    let sumOfAllProduct = form.products.reduce(
      (accumulator, currentValue) =>
        Number(accumulator) + Number(currentValue.total),
      0
    )
    sumBeforeDiscount = sumOfAllProduct

    if (
      Number(form.special_discount_baht) > 0 &&
      Number(form.special_discount_percent) === 0
    ) {
      sumAfterDiscount = sumBeforeDiscount - Number(form.special_discount_baht)
    } else if (
      Number(form.special_discount_percent) > 0 &&
      Number(form.special_discount_baht) === 0
    ) {
      sumAfterDiscount =
        sumBeforeDiscount * (1 - Number(form.special_discount_percent) / 100)
    } else {
      sumAfterDiscount = sumBeforeDiscount
    }

    grandTotal =
      sumAfterDiscount * (Number(form.vat) ? 1 + Number(form.vat) / 100 : 1)
    setForm({
      ...form,
      total_before_special_discount: roundToTwo(sumBeforeDiscount).toFixed(2),
      total_after_special_discount: roundToTwo(sumAfterDiscount).toFixed(2),
      grand_total_with_vat: roundToTwo(grandTotal).toFixed(2),
    })
  }

  const onSpecialDiscountChange = (value, name) => {
    if (name === 'baht' && Number(value) > 0) {
      setDisableDiscountPercent(true)
      setForm({
        ...form,
        special_discount_percent: '',
      })
    } else if (name === 'percent' && Number(value) > 0) {
      setDisableDiscountBaht(true)
      setForm({
        ...form,
        special_discount_bath: '',
      })
    } else if (Number(value) === 0) {
      setDisableDiscountBaht(false)
      setDisableDiscountPercent(false)
      setForm({
        ...form,
        special_discount_bath: '',
        special_discount_percent: '',
      })
    }
  }

  const saveStockHandler = () => {
    if (form.supplier_code && form.bill_no && form.bill_date) {
      setIsLoading(true)
      dispatch(onSaveStock(form))
    } else {
      Modal.warning({
        centered: true,
        title: 'ตรวจสอบ',
        content:
          'กรุณาระบุรหัสผู้ขาย, เลขที่บิล และวันที่เปิดบิล ให้เรียบร้อยก่อนบันทึก',
      })
    }
  }

  useEffect(() => {
    const { trace, loading, finished, data, error } = saveStock
    setIsLoading(loading)
    if (error) {
      handleAlert('custom', trace.message || '')
    }
    if (!loading && !error && data && finished) {
      handleAlert('success')
      setForm(data)
    }
  }, [saveStock]) // Only re - subscribe if props.saveStock changes

  const onEditTableData = (row, key, value) => {
    let newProducts = []
    form.products.forEach((x) => {
      if (x.id === row.id && x.product_code === row.product_code) {
        x[key] = value
        x.total = sumProductTotal(x)
        return newProducts.push(x)
      } else {
        return newProducts.push(x)
      }
    })
    setForm({
      ...form,
      products: newProducts,
    }).then((form) => {
      onChangeCalCulatedValue(form)
    })
  }

  const onDeleteProductFromBill = (row) => {
    let tempProduct = form.products
    setIsLoading(true)
    tempProduct = tempProduct.filter((x) => x.product_code !== row.product_code)
    setIsLoading(false)
    setForm({
      ...form,
      products: [...tempProduct],
    }).then((form) => {
      onChangeCalCulatedValue(form)
    })
  }

  const DeleteBillHandler = () => {
    if (form.id && form.id !== 0) {
      dispatch(onDeleteSupplierBill(form.id))
    } else {
      handleAlert('custom', 'ทำรายการไม่ได้ กรุณาค้นหาบิลที่ต้องการลบก่อน')
    }
  }
  useEffect(() => {
    const { trace, loading, finished, error } = deleteSupplierBill
    setIsLoading(loading)
    if (error && !loading) {
      handleAlert('error', trace.message || '')
    }
    if (!loading && !error && finished) {
      handleAlert('success')
      setForm(DEFAULT_FORM)
    }
  }, [deleteSupplierBill])

  ////// Search Product ////////
  const searchProductHandler = (product_code) => {
    if (form.supplier_code) {
      if (product_code) {
        dispatch(
          getProductWithLastestBoughtSupplier({
            product_code,
            supplier_code: form.supplier_code,
          })
        )
      } else {
        setIsShowProductPopUp(true)
      }
    } else {
      handleAlert('custom', 'กรุณากรอก รหัสผู้ขาย ก่อนค้นหา')
    }
  }

  const onCancelProdutPopUp = () => {
    setIsShowProductPopUp(false)
  }

  const onSelectProductPopUp = (data) => {
    searchProductHandler(data.code)
    setIsShowProductPopUp(false)
  }

  const onSearchSupplierCode = (code) => {
    if (code !== '') {
      dispatch(fetchSearchSupplierCode(code))
    } else {
      setIsShowSupplierPopUp(true)
    }
  }

  const onSelectSupplierPopup = (data) => {
    dispatch(fetchSearchSupplierCode(data.code))
    setIsShowSupplierPopUp(false)
  }

  const onCancelSupplierPopUp = () => {
    setIsShowSupplierPopUp(false)
  }

  const onSearchBill = (value) => {
    if (value) {
      dispatch(fetchSupplierBill(value))
    } else {
      setIsShowBillPopUp(true)
    }
  }

  const onCancelBillPopUp = () => {
    setIsShowBillPopUp(false)
  }

  const onSelectBillPopUp = (data) => {
    onSearchBill(data.bill_no)
    setIsShowBillPopUp(false)
  }

  const onChangeBillNo = (value) => {
    let currentForm = form
    if (form.id !== 0) {
      setForm({
        ...DEFAULT_FORM,
        supplier_id: currentForm.supplier_id,
        supplier_code: currentForm.supplier_code,
        supplier_name: currentForm.supplier_name,
        bill_no: value.toUpperCase(),
      })
    } else {
      setForm({
        ...currentForm,
        bill_no: value.toUpperCase(),
      })
    }
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'inline-block' }}>
          <h1>รับสินค้าเข้าสต๊อก</h1>
        </div>
        <div style={{ display: 'inline-block' }}>
          <Popconfirm
            title="คุณต้องการล้างหน้าจอใช่หรือไม่ ?"
            okText="ใช่"
            cancelText="ยกเลิก"
            onConfirm={onClearInput}
          >
            <Button
              type="default"
              danger
              icon={<ClearOutlined />}
              size="large"
              block
            >
              ล้างหน้าจอ
            </Button>
          </Popconfirm>
        </div>
      </div>
      <SearchSupplierPopUp
        visible={isShowSupplierPopUp}
        onCancel={onCancelSupplierPopUp}
        onClick={onSelectSupplierPopup}
      />
      <SearchProductNoVatPopUp
        visible={isShowProductPopUp}
        onCancel={onCancelProdutPopUp}
        onClick={onSelectProductPopUp}
      />
      <SearchSupplierBillPopUp
        visible={isShowBilltPopUp}
        onCancel={onCancelBillPopUp}
        onClick={onSelectBillPopUp}
        supplier_name={form.supplier_name}
      />
      <Spin spinning={isLoading}>
        <div className="form-wrapper">
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                onSearch={(value) => {
                  onSearchSupplierCode(value)
                }}
                inputOption="search"
                label="รหัสผู้ขาย"
                placeholder="รหัสผู้ขาย"
                isLoading={false}
                required={true}
                value={form.supplier_code}
                onChange={(event) => {
                  onChangeSupplierCode(event.target.value.toUpperCase())
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ชื่อผู้ขาย"
                width="300px"
                value={form.supplier_name}
                disabled={true}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="อัตราภาษีมูลค่าเพิ่ม"
                value={form.vat}
                width="60px"
                onChange={(event) => {
                  setForm({ ...form, vat: event.target.value }).then((form) => {
                    handleVatChange(form)
                  })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ระยะเวลาชำระเงิน"
                placeholder="Select a Duration"
                value={form.payment_duration}
                onChange={(value) => {
                  setForm({ ...form, payment_duration: value }).then((form) => {
                    onBillDateChange(form)
                  })
                }}
                width="80px"
                inputOption="select_input"
                options={paymentDurationList.data || []}
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                onSearch={(value) => {
                  onSearchBill(value)
                }}
                inputOption="search"
                label="เลขที่บิล"
                placeholder="เลขที่บิล"
                width="300px"
                isLoading={false}
                required={true}
                value={form.bill_no}
                onChange={(event) => {
                  onChangeBillNo(event.target.value)
                  // setForm({
                  //   ...form,
                  //   bill_no: event.target.value.toUpperCase(),
                  // })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="วันที่เปิดบิล"
                placeholder="เลือกวันเปิดบิล"
                required
                defaultValue={moment()}
                value={form.bill_date && moment(form.bill_date)}
                onChange={(value, dateString) => {
                  setForm({ ...form, bill_date: dateString }).then((form) => {
                    onBillDateChange(form)
                  })
                }}
                inputOption="date_picker"
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="วันที่นัดชำระ"
                disabled
                defaultValue={moment()}
                value={form.payment_date && moment(form.payment_date)}
                inputOption="date_picker"
              />
            </div>
          </div>
          <Divider style={{ margin: '0px 0px 10px' }} />
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                onSearch={(value) => searchProductHandler(value)}
                inputOption="search"
                label="รหัสสินค้า"
                placeholder="รหัสสินค้า"
                isLoading={false}
                value={product.product_code}
                onChange={(event) => {
                  setProduct({
                    ...product,
                    product_code: event.target.value.toUpperCase(),
                  })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ชื่อสินค้า"
                width="300px"
                value={product.product_name}
                disabled
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="จำนวน"
                required
                width="80px"
                value={product.quantity ? product.quantity : ''}
                unit={
                  product.unit_name
                    ? product.unit_name
                    : product.unit_id &&
                      unitList.data.find((x) => x.id === product.unit_id).name
                }
                onChange={(event) =>
                  setProduct({ ...product, quantity: event.target.value })
                }
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ราคาต่อหน่วย"
                width="90px"
                required
                value={product.price_in ? product.price_in : ''}
                unit="บาท"
                onChange={(event) =>
                  setProduct({ ...product, price_in: event.target.value })
                }
              />
            </div>
            <div className="input-item">
              <DiscountInput
                label="ส่วนลด"
                name1="discount_1"
                name2="discount_2"
                name3="discount_3"
                name4="discount_4"
                name5="discount_5"
                name6="discount_6"
                value1={product.discount_1}
                value2={product.discount_2}
                value3={product.discount_3}
                value4={product.discount_4}
                value5={product.discount_5}
                value6={product.discount_6}
                onChange={(value, name) =>
                  setProduct({ ...product, [name]: value })
                }
                width="150px"
              />
            </div>
            {/* <div className="input-item">
              <InlineInput
                label="ระยะเวลารอคอย"
                width="80px"
                value={product.lead_time}
                onChange={(event) =>
                  setProduct({
                    ...product,
                    lead_time: event.target.value,
                  })
                }
              />
            </div> */}
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              size="large"
              onClick={() => addProductToList(product)}
              style={{ marginLeft: '50px' }}
            >
              เพิ่มรายการ
            </Button>
          </div>
          <div style={{ background: '#f1f1f1', margin: '10px 0px 20px' }}>
            <div>
              <StockProductTable
                productList={form.products}
                onChange={onEditTableData}
                onDelete={onDeleteProductFromBill}
              />
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div
              style={{
                width: '10%',
                display: 'inline-block',
                marginRight: '20px',
              }}
            >
              <div style={{ marginBottom: '10px' }}>
                <Popconfirm
                  title="คุณต้องการลบบิลนี้ใช่หรือไม่ ?"
                  okText="ลบ"
                  cancelText="ยกเลิก"
                  onConfirm={DeleteBillHandler}
                >
                  <Button
                    type="primary"
                    danger
                    icon={<CloseOutlined />}
                    style={{ height: '80px' }}
                    size="large"
                    block
                  >
                    ยกเลิกบิล
                  </Button>
                </Popconfirm>
              </div>
            </div>
            <div style={{ width: '45%', display: 'inline-block' }}>
              <div className="input-wrapper">
                <div className="input-item">
                  <InlineInput
                    className="text-align-right"
                    label="ส่วนลดพิเศษ"
                    width="150px"
                    value={form.special_discount_baht}
                    unit="บาท"
                    disabled={disableDiscountBaht}
                    onChange={(event) => {
                      onSpecialDiscountChange(event.target.value, 'baht')
                      setForm({
                        ...form,
                        special_discount_baht: event.target.value,
                      }).then((form) => onChangeCalCulatedValue(form))
                    }}
                  />
                </div>
                <div className="input-item">
                  <InlineInput
                    className="text-align-right"
                    width="80px"
                    value={form.special_discount_percent}
                    unit="%"
                    disabled={disableDiscountPercent}
                    disableColon={true}
                    onChange={(event) => {
                      onSpecialDiscountChange(event.target.value, 'percent')
                      setForm({
                        ...form,
                        special_discount_percent: event.target.value,
                      }).then((form) => onChangeCalCulatedValue(form))
                    }}
                  />
                </div>
              </div>
            </div>
            <div style={{ width: '30%', display: 'inline-block' }}>
              <div className="input-wrapper">
                <div className="input-item margin-left-auto">
                  <InlineInput
                    className="text-align-right"
                    label="ยอดก่อนหักส่วนลดพิเศษ"
                    width="180px"
                    value={form.total_before_special_discount}
                    disabled
                    onChange={(event) =>
                      setForm({
                        ...form,
                        total_before_special_discount: event.target.value,
                      })
                    }
                  />
                </div>
                <div className="input-item margin-left-auto">
                  <InlineInput
                    className="text-align-right"
                    label="ยอดหลังหักส่วนลดพิเศษ"
                    width="180px"
                    disabled
                    value={form.total_after_special_discount}
                    onChange={(event) =>
                      setForm({
                        ...form,
                        total_after_special_discount: event.target.value,
                      })
                    }
                  />
                </div>
                <div className="input-item margin-left-auto">
                  <InlineInput
                    className="text-align-right"
                    label="ยอดรวมภาษ๊"
                    width="180px"
                    disabled
                    value={form.grand_total_with_vat}
                    onChange={(event) =>
                      setForm({
                        ...form,
                        grand_total_with_vat: event.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                width: '10%',
                marginLeft: 'auto',
                display: 'inline-block',
              }}
            >
              <Button
                type="primary"
                icon={<SaveOutlined />}
                size="large"
                block
                style={{ height: '80px' }}
                onClick={saveStockHandler}
              >
                บันทึก
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  )
}

export default withRouter(StockPage)
