import React from 'react'
import { Table } from 'antd'
import { addCommas } from 'utils'

const ListStockLowTable = (props) => {
  const { listStockLow } = props
  const tableColumns = [
    {
      title: 'รหัสสินค้า',
      dataIndex: 'code',
      key: 'code',
      width: '100px',
    },
    {
      title: 'ชือสินค้า',
      dataIndex: 'name',
      key: 'name',
      width: '500px',
    },
    {
      title: 'สต๊อก 1',
      dataIndex: 'stock1',
      key: 'stock1',
      width: '100px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
      sorter: (a, b) => ('' + a.stock1).localeCompare(b.stock1),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'สต๊อก 2',
      dataIndex: 'stock2',
      key: 'stock2',
      width: '100px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
      sorter: (a, b) => ('' + a.stock2).localeCompare(b.stock2),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'สต๊อกรวม',
      dataIndex: 'stock_total',
      key: 'stock_total',
      width: '100px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
      sorter: (a, b) => ('' + a.stock_total).localeCompare(b.stock_total),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'สต๊อกขั้นต่ำ',
      dataIndex: 'stock_low',
      key: 'stock_low',
      width: '110px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
      sorter: (a, b) => ('' + a.stock_low).localeCompare(b.stock_low),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'หน่วย',
      dataIndex: 'unit_name',
      key: 'unit_name',
      width: '120px',
    },
  ]

  return (
    <div>
      <Table
        rowKey={(record) => `id+${record.id}`}
        dataSource={listStockLow}
        columns={tableColumns}
        pagination={{
          simple: true,
          defaultPageSize: 20,
          showSizeChanger: true,
          position: ['bottomRight'],
          pageSizeOptions: ['10', '20', '50', '100'],
        }}
        size="small"
        scroll={{ x: 1100 }}
        style={{
          maxWidth: '1150px',
          height: '740px',
          minHeight: '100%',
          backgroundColor: '#FFFFFF',
          marginTop: '10px',
        }}
      />
    </div>
  )
}

export default ListStockLowTable
