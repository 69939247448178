/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Button, Spin, Divider, Modal, Popconfirm } from 'antd'
import {
  SaveOutlined,
  ClearOutlined,
  SearchOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'

import SearchSupplierPopUp from 'components/SearchSupplierPopUp'
import SearchSupplierInvoicePopup from 'components/SearchSupplierInvoicePopup'
import InlineInput from 'components/InlineInput'
import InvoiceSupplierTable from 'components/InvoiceSupplierTable'
import { handleAlert } from 'utils'

import {
  fetchSearchSupplierCode,
  onListSupplierBillBySupplierAndDate,
  onSaveSupplierInvoice,
  onCancelSupplierInvoice,
  onGetSupplierInvoiceBySupplier,
} from 'redux/actions'

const DEFAULT_FORM = {
  id: null,
  supplier_id: null,
  supplier_code: '',
  supplier_name: '',
  start_date: moment().format('YYYY-MM-DD'),
  end_date: moment().format('YYYY-MM-DD'),
  supplier_invoice_total: 0,
  supplier_invoice_no: '',
  date: moment().format('YYYY-MM-DD'),
  list: [],
}

const DEFAULT_DELETED_INVOICE = {
  id: null,
  supplier_id: null,
  supplier_code: '',
  supplier_name: '',
  date: '',
  supplier_invoice_no: '',
  supplier_invoice_total: '',
}

const SupplierInvoicePage = (props) => {
  function useAsyncState(initialValue) {
    const [value, setValue] = useState(initialValue)
    const setter = (x) =>
      new Promise((resolve) => {
        setValue(x)
        resolve(x)
      })
    return [value, setter]
  }
  const [form, setForm] = useAsyncState(DEFAULT_FORM)
  const [isLoading, setIsLoading] = useState(false)
  const [billList, setBillList] = useState([])
  const [deletedInvoice, setDeletedInvoice] = useState(DEFAULT_DELETED_INVOICE)
  const [isShowSupplierPopUp, setIsShowSupplierPopUp] = useState(false)
  const [isShowSupplierInvoicePopUp, setIsShowSupplierInvoicePopUp] =
    useState(false)
  const dispatch = useDispatch()
  const searchSupplierCode = useSelector((state) => state.searchSupplierCode)
  let listSupplierBillBySupplierAndDate = useSelector(
    (state) => state.listSupplierBillBySupplierAndDate
  )
  let saveSupplierInvoice = useSelector((state) => state.saveSupplierInvoice)
  let cancelSupplierInvoice = useSelector(
    (state) => state.cancelSupplierInvoice
  )
  let getSupplierInvoiceBySupplier = useSelector(
    (state) => state.getSupplierInvoiceBySupplier
  )

  useEffect(() => {
    document.title = 'รวมยอดบิลซื้อสินค้า - NoVat'
    listSupplierBillBySupplierAndDate = []
  }, [])

  useEffect(() => {
    const { trace, error, finished, data } = searchSupplierCode
    if (error) {
      handleAlert('custom', trace.message || '')
      setForm({
        ...form,
        supplier_id: null,
        supplier_name: '',
      })
      setIsLoading(false)
    }
    if (!error && data && finished) {
      handleAlert('success')
      setForm({
        ...form,
        supplier_id: data.id,
        supplier_code: data.code,
        supplier_name: data.name,
      })
      setIsLoading(false)
    }
  }, [searchSupplierCode]) // Only re-subscribe if props.searchSupplierCode changes

  useEffect(() => {
    const { trace, error, finished, data } = listSupplierBillBySupplierAndDate
    if (error) {
      handleAlert('custom', trace.message || '')
      setBillList([])
      setIsLoading(false)
    }
    if (!error && data && finished) {
      handleAlert('success')
      setForm({
        ...form,
        supplier_invoice_total: '',
        supplier_invoice_no: '',
        list: data,
      })
      setBillList([])
      setIsLoading(false)
    }
  }, [listSupplierBillBySupplierAndDate])

  useEffect(() => {
    const { trace, error, finished, data } = saveSupplierInvoice
    if (error) {
      handleAlert('custom', trace.message || '')
      setIsLoading(false)
    }
    if (!error && data && finished) {
      setBillList([])
      setForm({
        ...form,
        supplier_invoice_total: 0,
        supplier_invoice_no: '',
        date: moment().format('YYYY-MM-DD'),
      })
      dispatch(
        onListSupplierBillBySupplierAndDate(
          form.supplier_code,
          form.start_date,
          form.end_date
        )
      )
      setIsLoading(false)
    }
  }, [saveSupplierInvoice])

  useEffect(() => {
    const { trace, error, finished, data } = cancelSupplierInvoice
    if (error) {
      handleAlert('custom', trace.message || '')
      setIsLoading(false)
    }
    if (!error && data && finished) {
      setDeletedInvoice(DEFAULT_DELETED_INVOICE)
      Modal.success({
        centered: true,
        title: 'สำเร็จ',
        content: 'ยกเลิกใบวางบิลเรียบร้อยแล้ว',
      })
      if (form.list.length > 0) {
        dispatch(
          onListSupplierBillBySupplierAndDate(
            form.supplier_code,
            form.start_date,
            form.end_date
          )
        )
      } else {
        setIsLoading(false)
      }
    }
  }, [cancelSupplierInvoice])

  useEffect(() => {
    const { trace, error, finished, data } = getSupplierInvoiceBySupplier
    if (error) {
      handleAlert('custom', trace.message || '')
      setIsLoading(false)
    }
    if (!error && data && finished) {
      handleAlert('success')
      setForm({
        ...form,
        id: data.id,
        date: data.date,
        supplier_invoice_no: data.supplier_invoice_no,
        supplier_invoice_total: data.supplier_invoice_total,
        list: data.bill_list,
      })
      setIsLoading(false)
    }
  }, [getSupplierInvoiceBySupplier])

  const onSearchSupplierCode = (code) => {
    if (code !== '') {
      setIsLoading(true)
      dispatch(fetchSearchSupplierCode(code))
    } else {
      setIsShowSupplierPopUp(true)
    }
  }

  //----------- POP UP SUPPLIER ------------//
  const onSelectSupplierPopup = (data) => {
    dispatch(fetchSearchSupplierCode(data.code))
    setIsShowSupplierPopUp(false)
  }

  const onCancelSupplierPopUp = () => {
    setIsShowSupplierPopUp(false)
  }
  //----------- POP UP INVOICE ------------//

  const onSearchSupplierInvoice = (invoice_no) => {
    if (form.supplier_id !== null) {
      if (invoice_no !== '') {
        setIsLoading(true)
        dispatch(onGetSupplierInvoiceBySupplier(form.supplier_code, invoice_no))
      } else {
        setIsShowSupplierInvoicePopUp(true)
      }
    } else {
      handleAlert('custom', 'กรุณาเลือกข้อมูลผู้ขายก่อน')
    }
  }

  const onSelectSupplierInvoicePopup = (data) => {
    dispatch(onGetSupplierInvoiceBySupplier(form.supplier_code, data.supplier_invoice_no))
    setIsShowSupplierInvoicePopUp(false)
  }

  const onCancelSupplierInvoicePopUp = () => {
    setIsShowSupplierInvoicePopUp(false)
  }

  const onDeleteSupplierInvoice = (data) => {
    setDeletedInvoice(data)
    setIsShowSupplierInvoicePopUp(false)
  }

  const onSearchClick = () => {
    if (form.supplier_id !== null) {
      if (form.start_date !== '' && form.end_date !== '') {
        setIsLoading(true)
        dispatch(
          onListSupplierBillBySupplierAndDate(
            form.supplier_code,
            form.start_date,
            form.end_date
          )
        )
      } else {
        handleAlert('custom', 'กรุณาระบุวันที่ให้ครบถ้วน')
      }
    } else {
      handleAlert('custom', 'กรุณาเลือกผู้ขายก่อนค้นหา')
    }
  }

  const onSaveClick = () => {
    if (form.date !== '' && form.supplier_invoice_no !== '') {
      setIsLoading(true)
      let data = {
        supplier_id: form.supplier_id,
        supplier_invoice_no: form.supplier_invoice_no,
        date: form.date,
        supplier_invoice_total: form.supplier_invoice_total,
        bill_list: billList,
      }
      dispatch(onSaveSupplierInvoice(data))
    } else {
      handleAlert('custom', 'กรุณากรอกวันที่ และเลขที่ใบวางบิลให้ครบถ้วน')
    }
  }

  const onSelectedBill = (
    checked_value,
    bill_total,
    seleced_bill_no
  ) => {
    let newList = form.list
    let checkedBillIndex = newList.findIndex(bill => bill.bill_no === seleced_bill_no)
    let tmpBillList = billList
    let item = { bill_no: seleced_bill_no }
    newList[checkedBillIndex].is_checked = checked_value
    let newTotal = form.supplier_invoice_total
    if (checked_value === true) {
      newTotal = Number(newTotal) + Number(bill_total)
      tmpBillList.push(item)
      setBillList(tmpBillList)
    } else {
      newTotal = Number(newTotal) - Number(bill_total)
      tmpBillList = tmpBillList.filter(
        (bill) => bill.bill_no !== seleced_bill_no
      )
      setBillList(tmpBillList)
    }
    newTotal = newTotal.toFixed(2)
    setForm({
      ...form,
      supplier_invoice_total: newTotal,
      list: newList,
    })
  }

  const onCancelInvoice = () => {
    if (deletedInvoice.id !== null) {
      setIsLoading(true)
      dispatch(onCancelSupplierInvoice(deletedInvoice.id))
    }
  }

  const onClearInput = () => {
    setForm(DEFAULT_FORM)
    setBillList([])
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'inline-block' }}>
          <h1>รวมยอดบิลซื้อสินค้า</h1>
        </div>
        <div style={{ display: 'inline-block' }}>
          <Popconfirm
            title="คุณต้องการล้างหน้าจอใช่หรือไม่ ?"
            okText="ใช่"
            cancelText="ยกเลิก"
            onConfirm={onClearInput}
          >
            <Button
              type="default"
              danger
              icon={<ClearOutlined />}
              size="large"
              block
            >
              ล้างหน้าจอ
            </Button>
          </Popconfirm>
        </div>
      </div>
      {/* <button onClick={() => console.log(form)}>FORM</button> */}
      <SearchSupplierPopUp
        visible={isShowSupplierPopUp}
        onCancel={onCancelSupplierPopUp}
        onClick={onSelectSupplierPopup}
      />
      <SearchSupplierInvoicePopup
        visible={isShowSupplierInvoicePopUp}
        onCancel={onCancelSupplierInvoicePopUp}
        onClick={onSelectSupplierInvoicePopup}
        onDeleteItem={onDeleteSupplierInvoice}
        supplierName={form.supplier_name}
      />
      <Spin spinning={isLoading}>
        <div className="form-wrapper">
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                onSearch={(value) => {
                  onSearchSupplierCode(value)
                }}
                inputOption="search"
                label="รหัสผู้ขาย"
                placeholder="รหัสผู้ขาย"
                isLoading={false}
                required={true}
                value={form.supplier_code}
                onChange={(event) => {
                  setForm({
                    ...form,
                    supplier_code: event.target.value.toUpperCase(),
                  })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ชื่อผู้ขาย"
                width="300px"
                value={form.supplier_name}
                disabled={true}
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="วันที่เริ่มค้นหา"
                placeholder="เลือกวัน"
                defaultValue={moment()}
                value={form.start_date && moment(form.start_date)}
                inputOption="date_picker"
                onChange={(_, dateString) => {
                  setForm({ ...form, start_date: dateString })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="วันที่สิ้นสุด"
                placeholder="เลือกวัน"
                defaultValue={moment()}
                value={form.end_date && moment(form.end_date)}
                inputOption="date_picker"
                onChange={(_, dateString) => {
                  setForm({ ...form, end_date: dateString })
                }}
              />
            </div>
            <div className="input-item">
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="medium"
                onClick={onSearchClick}
                style={{ width: '184px' }}
              >
                ค้นหา
              </Button>
            </div>
          </div>
          <Divider style={{ margin: '0px 0px 10px' }} />
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                className="text-align-right"
                label="ยอดรวมรายการที่เลือก"
                width="125px"
                value={form.supplier_invoice_total}
                disabled={true}
              />
            </div>
            <div className="input-item">
              {/* <InlineInput
                label="เลขที่ใบวางบิล"
                width="300px"
                value={form.supplier_invoice_no}
                onChange={(event) => {
                  setForm({
                    ...form,
                    supplier_invoice_no: event.target.value.toUpperCase(),
                  })
                }}
              /> */}
              <InlineInput
                onSearch={(value) => {
                  onSearchSupplierInvoice(value)
                }}
                inputOption="search"
                label="เลขที่ใบวางบิล"
                width="300px"
                isLoading={false}
                value={form.supplier_invoice_no}
                onChange={(event) => {
                  setForm({
                    ...form,
                    supplier_invoice_no: event.target.value.toUpperCase(),
                  })
                }}
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="วันที่วางบิล"
                placeholder="เลือกวัน"
                defaultValue={moment()}
                value={form.date && moment(form.date)}
                inputOption="date_picker"
                onChange={(_, dateString) => {
                  setForm({ ...form, date: dateString })
                }}
              />
            </div>
            <div className="input-item">
              <Button
                type="primary"
                icon={<SaveOutlined />}
                size="medium"
                onClick={onSaveClick}
                style={{ width: '250px' }}
              >
                บันทึกเลขที่ใบวางบิล
              </Button>
            </div>
          </div>
          <div style={{ background: '#f1f1f1', margin: '10px 0px 20px' }}>
            <InvoiceSupplierTable
              list={form.list}
              onSelectedBill={onSelectedBill}
            />
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                // inputOption="search"
                label="ใบวางบิลที่จะยกเลิก"
                width="220px"
                placeholder="เลขที่ใบวางบิล"
                isLoading={false}
                value={deletedInvoice.supplier_invoice_no}
                onChange={(event) => {
                  if (event.target.value === '') {
                    setDeletedInvoice(DEFAULT_DELETED_INVOICE)
                  } else {
                    setDeletedInvoice({
                      ...deletedInvoice,
                      supplier_invoice_no: event.target.value.toUpperCase(),
                    })
                  }
                }}
              />
            </div>
            <div className="input-item">
              <Button
                type="primary"
                size="medium"
                onClick={() => {
                  setDeletedInvoice(DEFAULT_DELETED_INVOICE)
                }}
                style={{ width: '65px' }}
              >
                เคลียร์
              </Button>
            </div>
            <div className="input-item">
              <InlineInput
                label="ชื่อผู้ขาย"
                width="300px"
                value={deletedInvoice.supplier_name}
                disabled={true}
              />
            </div>
            <div className="input-item">
              <InlineInput
                className="text-align-right"
                width="110px"
                value={deletedInvoice.supplier_invoice_total}
                disabled={true}
              />
            </div>
            <div className="input-item">
              <Button
                type="danger"
                icon={<CloseOutlined />}
                size="medium"
                disabled={deletedInvoice.id === null}
                onClick={onCancelInvoice}
                style={{ width: '150px' }}
              >
                ยกเลิก
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  )
}

export default withRouter(SupplierInvoicePage)
