import React from 'react'
import { Table, Button, Popconfirm } from 'antd'
import { RollbackOutlined } from '@ant-design/icons'
import moment from 'moment'
import 'moment/locale/th'
moment.locale('th')

const TemporaryStockTable = (props) => {
  const { onReturn, temporaryList } = props
  const tableColumns = [
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: '50px',
      render: (_, record) => {
        return (
          <Popconfirm
            title="คุณต้องการคืนรายการนี้ใช่ไหม ?"
            okText="ใช่"
            cancelText="ยกเลิก"
            onConfirm={() => onReturn(record.id)}
          >
            <Button
              size='small'
              type="primary"
              icon={<RollbackOutlined />}
            />
          </Popconfirm>
        )
      },
    },
    {
      title: 'วันที่',
      dataIndex: 'created',
      key: 'created',
      width: '100px',
      render: (_, record) => {
        return(
          moment(record.created).format('L')
        )
      },
    },
    {
      title: 'รหัส',
      dataIndex: 'product_code',
      key: 'product_code',
      width: '80px',
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'product_name',
      key: 'product_name',
      width: '600px',
    },
    {
      title: 'จำนวน',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '120px',
    },
    {
      title: 'หน่วย',
      dataIndex: 'unit_name',
      key: 'unit_name',
      width: '100px',
    },
    {
      title: 'สาเหตุ',
      dataIndex: 'reason_name',
      key: 'reason_name',
      width: '130px',
    },
    {
      title: 'รหัสใช้งาน',
      dataIndex: '',
      key: '',
    },
  ]

  return (
    <div>
      <Table
        rowKey={(record) => `id+${record.id}`}
        dataSource={temporaryList}
        columns={tableColumns}
        pagination={false}
        size="small"
      />
    </div>
  )
}

export default TemporaryStockTable
