import { useLocation } from 'react-router-dom'
import { message } from 'antd'

import { ERROR_MSG, SUCCESS_MSG, NOT_FOUND_MSG } from 'config'

export const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

export const PAYMENT_CHANNEL = [
  {
    id: 1,
    name: 'เงินสด',
  },
  {
    id: 2,
    name: 'โอนเงิน',
  },
  {
    id: 3,
    name: 'เช็ค',
  },
  {
    id: 4,
    name: 'ปรับเศษ',
  },
  {
    id: 5,
    name: 'ลดหนี้',
  },
  {
    id: 6,
    name: 'เพิ่มหนี้',
  },
]

export const PAYMENT_STATUS = [
  {
    id: 1,
    name: 'ยังไม่ชำระเงิน',
    value: 'unpaid',
  },
  {
    id: 2,
    name: 'ชำระเงินบางส่วน',
    value: 'partially_paid',
  },
  {
    id: 3,
    name: 'ชำระเงินแล้ว',
    value: 'paid',
  },
  {
    id: 4,
    name: 'แสดงทั้งหมด',
    value: 'all',
  },
]

export const SEARCH_TIME_PERIOD = [
  {
    id: 1,
    name: 'ย้อนหลัง 3 เดือน',
    value: 'three_month',
  },
  {
    id: 2,
    name: 'ย้อนหลัง 1 ปี',
    value: 'one_year',
  },
  {
    id: 3,
    name: 'ย้อนหลังทั้งหมด',
    value: 'all',
  },
]

export const PAYMENT_METHODS = [
  {
    id: 1,
    name: 'เงินสด',
    value: 'cash'
  },
  {
    id: 2,
    name: 'โอนเงิน',
    value: 'transfer'
  },
]

export const handleAlert = (type, msg = '') => {
  switch (type) {
    case 'success':
      return message.success(SUCCESS_MSG + ' ' + msg)
    case '404':
      return message.error(NOT_FOUND_MSG, 2)
    case 'error':
      return message.error(ERROR_MSG + `${msg ? `: ${msg}` : ''} `, 2)
    case 'custom':
      return message.error(msg)
    default:
      return ''
  }
}

export const roundToTwo = (num) => +(Math.round(num + 'e+2') + 'e-2')
export const roundToFour = (num) => +(Math.round(num + 'e+4') + 'e-4')

export const addCommas = (nStr) => {
  nStr += ''
  let x = nStr.split('.')
  let x1 = x[0]
  let x2 = x.length > 1 ? '.' + x[1] : ''
  let rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1,$2')
  }
  return x1 + x2
}

export const ArabicNumberToText = (number) => {
  let checkedNumber = CheckNumber(number)
  let NumberArray = [
    'ศูนย์',
    'หนึ่ง',
    'สอง',
    'สาม',
    'สี่',
    'ห้า',
    'หก',
    'เจ็ด',
    'แปด',
    'เก้า',
    'สิบ',
  ]
  let DigitArray = ['', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน', 'ล้าน']
  let BahtText = ''
  let DecimalLen = ''
  if (isNaN(checkedNumber)) {
    return 'ข้อมูลนำเข้าไม่ถูกต้อง'
  } else {
    if (checkedNumber - 0 > 9999999.9999) {
      return '............................................................................................................................'
    } else {
      checkedNumber = checkedNumber.split('.')
      if (checkedNumber[1].length > 0) {
        checkedNumber[1] = checkedNumber[1].substring(0, 2)
      }
      let NumberLen = checkedNumber[0].length - 0
      for (let i = 0; i < NumberLen; i++) {
        let tmp = checkedNumber[0].substring(i, i + 1) - 0
        if (tmp !== 0) {
          if (i === NumberLen - 1 && tmp === 1) {
            BahtText += 'เอ็ด'
          } else if (i === NumberLen - 2 && tmp === 2) {
            BahtText += 'ยี่'
          } else if (i === NumberLen - 2 && tmp === 1) {
            BahtText += ''
          } else {
            BahtText += NumberArray[tmp]
          }
          BahtText += DigitArray[NumberLen - i - 1]
        }
      }
      BahtText += 'บาท'
      if (checkedNumber[1] === '0' || checkedNumber[1] === '00') {
        BahtText += 'ถ้วน'
      } else {
        DecimalLen = checkedNumber[1].length - 0
        for (let i = 0; i < DecimalLen; i++) {
          let tmp = checkedNumber[1].substring(i, i + 1) - 0
          if (tmp !== 0) {
            if (i === DecimalLen - 1 && tmp === 1) {
              BahtText += 'เอ็ด'
            } else if (i === DecimalLen - 2 && tmp === 2) {
              BahtText += 'ยี่'
            } else if (i === DecimalLen - 2 && tmp === 1) {
              BahtText += ''
            } else {
              BahtText += NumberArray[tmp]
            }
            BahtText += DigitArray[DecimalLen - i - 1]
          }
        }
        BahtText += 'สตางค์'
      }
      return BahtText
    }
  }
}

export const CheckNumber = (number) => {
  let decimal = false
  let stringNumber = number.toString()
  stringNumber = stringNumber.replace(/ |,|บาท|฿/gi, '')
  for (let i = 0; i < stringNumber.length; i++) {
    if (stringNumber[i] === '.') {
      decimal = true
    }
  }
  if (decimal === false) {
    stringNumber = stringNumber + '.00'
  }
  return stringNumber
}
