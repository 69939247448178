/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Modal } from 'antd'

import InlineInput from 'components/InlineInput'
import { roundToTwo } from 'utils'

const CashChangeAmountModal = ({
  created,
  grandTotal,
  billCashInput,
  billCashChange,
  handleCashInputChange,
  visible,
  onCancleAmountModal,
  onSubmitAmountModal,
}) => {
  const [canSubmit, setCanSubmit] = useState(false)
  const [isShowWarning, setIsShowWarning] = useState(false)

  useEffect(() => {
    handleCashInput(billCashInput)
  }, [visible])

  const handleCancel = () => {
    onCancleAmountModal()
    // setIsDisableInput(false)
  }

  const handleOk = () => {
    onSubmitAmountModal()
    // setIsDisableInput(false)
  }

  const handleCashInput = (input) => {
    let cashChange = roundToTwo(Number(input)) - roundToTwo(Number(grandTotal))
    if (cashChange >= 0) {
      setIsShowWarning(false)
      setCanSubmit(true)
      handleCashInputChange(input, true, cashChange)
    } else {
      setIsShowWarning(true)
      setCanSubmit(false)
      handleCashInputChange(input, false)
    }
  }

  return (
    <>
      <Modal
        width="450px"
        visible={visible}
        onCancel={handleCancel}
        onOk={handleOk}
        okButtonProps={{
          disabled: created !== '' ? false : !canSubmit,
        }}
        title="คำนวณเงินทอน"
      >
        <div className="form-wrapper">
          {isShowWarning && (
            <span style={{ color: 'red' }}>
              * จำนวนเงินที่รับ น้อยกว่า ยอดสุทธิ กรุณตรวจสอบให้ถูกต้อง
            </span>
          )}
          <div className="input-wrapper">
            <div className="input-item margin-left-auto" style={{ marginRight: '30px'}}>
              <InlineInput
                label="จำนวนเงินที่รับ"
                className="text-align-right"
                value={billCashInput}
                // disabled={isDisableInput}
                onChange={(event) => {
                  handleCashInput(event.target.value)
                }}
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item margin-left-auto" style={{ marginRight: '30px'}}>
              <InlineInput
                className="text-align-right"
                label="ยอดสุทธิ"
                value={grandTotal}
                disabled
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item margin-left-auto" style={{ marginRight: '30px'}}>
              <InlineInput
                label="เงินทอน"
                className="text-align-right"
                value={billCashChange}
                disabled
              />{' '}
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default CashChangeAmountModal
