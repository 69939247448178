/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Spin, Popconfirm, Modal } from 'antd'
import {
  SaveOutlined,
  ClearOutlined,
  CloseOutlined,
  PrinterOutlined,
  CheckCircleTwoTone,
} from '@ant-design/icons'

import moment from 'moment'

import { handleAlert, roundToTwo } from 'utils'
import InlineInput from 'components/InlineInput'
import SearchSupplierBillVatPopUp from 'components/SearchSupplierBillVatPopUp'
import SearchSupplierNotePopUp from 'components/SearchSupplierNotePopUp'
import SupplierCreditNoteProductTable from 'components/SupplierCreditNoteProductTable'

import {
  onListBillVatType,
  onGetSupplierBillVatByBillNo,
  onListNoteReason,
  onSaveSupplierCreditNote,
  onGetSupplierCreditNote,
  onCancelSupplierNote,
} from 'redux/actions'

const DEFAULT_FORM = {
  id: 0,
  doc_no: '',
  doc_date: moment().format('YYYY-MM-DD'),
  bill_no: '',
  bill_id: null,
  bill_date: '',
  vat: '',
  supplier_name: '',
  supplier_code: '',
  supplier_doc_no: '',
  bill_type_id: null,
  bill_type_value: '',
  bill_total_after_special_discount: '',
  is_credit_note: null,
  is_canceled: null,
  reason_id: null,
  special_discount: '',
  remark: '',
  value_added_tax: '',
  total_before_special_discount: '',
  total_after_special_discount: '',
  grand_total_with_vat: '',
  total_items: 0,
  items: [],
}

const DOC_TYPE = [
  {
    id: 1,
    name: 'ใบลดหนี้',
  },
  {
    id: 2,
    name: 'ใบเพิ่มหนี้',
  },
  {
    id: 3,
    name: '---',
  },
]

const SupplierCreditNotePage = (props) => {
  function useAsyncState(initialValue) {
    const [value, setValue] = useState(initialValue)
    const setter = (x) =>
      new Promise((resolve) => {
        setValue(x)
        resolve(x)
      })
    return [value, setter]
  }
  const [form, setForm] = useAsyncState(DEFAULT_FORM)
  const [isShowBilltPopUp, setIsShowBillPopUp] = useState(false)
  const [isShowNotePopUp, setIsShowNotePopUp] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [printPopUp, setPrintPopUp] = useState(false)
  const [debitReason, setDebitReason] = useState([])
  const dispatch = useDispatch()
  const listBillVatType = useSelector((state) => state.listBillVatType)
  const listNoteReason = useSelector((state) => state.listNoteReason)
  let getSupplierBillVatByBillNo = useSelector(
    (state) => state.getSupplierBillVatByBillNo
  )
  let saveSupplierCreditNote = useSelector(
    (state) => state.saveSupplierCreditNote
  )
  let getSupplierCreditNote = useSelector(
    (state) => state.getSupplierCreditNote
  )
  let cancelSupplierNote = useSelector((state) => state.cancelSupplierNote)

  useEffect(() => {
    dispatch(onListBillVatType())
    dispatch(onListNoteReason())
    getSupplierBillVatByBillNo = {}
    saveSupplierCreditNote = {}
    document.title = 'ใบลดหนี้/เพิ่มหนี้ ผู้ขาย'
  }, [])

  useEffect(() => {
    const { error, trace, loading, finished } = listBillVatType
    if (!loading && !error && finished) {
      setIsLoading(false)
    }
    if (!loading && error) {
      handleAlert('custom', trace.message ? trace.message : '')
      setIsLoading(false)
    }
  }, [listBillVatType])

  useEffect(() => {
    const { error, trace, loading, finished, data } = listNoteReason
    if (!loading && !error && finished) {
      initialReason(data)
      setIsLoading(false)
    }
    if (!loading && error) {
      handleAlert('custom', trace.message ? trace.message : '')
      setIsLoading(false)
    }
  }, [listNoteReason])

  useEffect(() => {
    const { error, loading, finished, data, trace } = getSupplierBillVatByBillNo
    setIsLoading(true)
    if (error) {
      Modal.warning({
        centered: true,
        title: 'ตรวจสอบ',
        content: `${trace.message}`,
      })
      setIsLoading(false)
    }
    if (!loading && !error && data && finished) {
      // setBill(data)
      setForm({
        ...form,
        bill_no: data.bill_no,
        bill_id: data.id,
        bill_date: data.bill_date,
        vat: data.vat,
        items: data.products_vat,
        supplier_name: data.supplier_name,
        supplier_code: data.supplier_code,
        bill_type_id: data.bill_type_id,
        bill_type_value: data.bill_type_value,
        bill_total_after_special_discount: data.total_after_special_discount,
        value_added_tax: data.value_added_tax,
        total_before_special_discount: data.total_before_special_discount,
        total_after_special_discount: data.total_after_special_discount,
        grand_total_with_vat: data.grand_total_with_vat,
        total_items: data.products_vat.length,
      }).then((_form) => onChangeCalCulatedValue(_form))
      setIsLoading(false)
    }
  }, [getSupplierBillVatByBillNo])

  useEffect(() => {
    const { error, data, trace, loading, finished } = getSupplierCreditNote
    if (!loading && !error && finished) {
      setForm(data)
      setIsLoading(false)
    }
    if (!loading && error) {
      handleAlert('custom', trace.message ? trace.message : '')
      setIsLoading(false)
    }
  }, [getSupplierCreditNote])

  useEffect(() => {
    const { error, data, trace, loading, finished } = saveSupplierCreditNote
    if (!loading && !error && finished) {
      setForm(data)
      setIsLoading(false)
      setPrintPopUp(true)
    }
    if (!loading && error) {
      handleAlert('custom', trace.message ? trace.message : '')
      setIsLoading(false)
    }
  }, [saveSupplierCreditNote])

  useEffect(() => {
    const { error, trace, data, loading, finished } = cancelSupplierNote
    if (!loading && !error && finished) {
      setForm(data)
      Modal.success({
        centered: true,
        content: 'ยกเลิกเอกสารเรียบร้อย',
      })
    }
    if (!loading && error) {
      handleAlert('custom', trace.message ? trace.message : '')
      setIsLoading(false)
    }
  }, [cancelSupplierNote])

  const initialReason = (list) => {
    let debitReason = list.filter((item) => item.value !== 'refund')
    setDebitReason(debitReason)
  }

  const onSearchNote = (value) => {
    if (value) {
      setIsLoading(true)
      dispatch(onGetSupplierCreditNote(value))
    } else {
      setIsShowNotePopUp(true)
    }
  }

  const onCancelNotePopUp = () => {
    setIsShowNotePopUp(false)
  }

  const onSelectNotePopUp = (data) => {
    onSearchNote(data.doc_no)
    setIsShowNotePopUp(false)
  }

  const onSearchBill = (value) => {
    if (value) {
      setIsLoading(true)
      dispatch(onGetSupplierBillVatByBillNo(value))
    } else {
      setIsShowBillPopUp(true)
    }
  }

  const onCancelBillPopUp = () => {
    setIsShowBillPopUp(false)
  }

  const onSelectBillPopUp = (data) => {
    onSearchBill(data.bill_no)
    setIsShowBillPopUp(false)
  }

  const sumProductTotal = (_product) => {
    let total = 0
    total = Number(_product.quantity) * Number(_product.price_in)
    total *= Number(_product.discount_1)
      ? 1 - Number(_product.discount_1) / 100
      : 1
    total *= Number(_product.discount_2)
      ? 1 - Number(_product.discount_2) / 100
      : 1
    total *= Number(_product.discount_3)
      ? 1 - Number(_product.discount_3) / 100
      : 1
    total *= Number(_product.discount_4)
      ? 1 - Number(_product.discount_4) / 100
      : 1
    return roundToTwo(total).toFixed(2)
  }

  const onChangeCalCulatedValue = (_form) => {
    let grandTotalWithVat = 0
    let sumBeforeDiscount = 0
    let sumAfterDiscount = 0
    let valueAddedTax = 0
    let discountValue = 0

    sumBeforeDiscount = _form.items.reduce(
      (accumulator, currentValue) =>
        Number(accumulator) + Number(currentValue.total),
      0
    )
    /////// special discount
    if (Number(_form.special_discount) > 0) {
      discountValue = Number(_form.special_discount)
      sumAfterDiscount = sumBeforeDiscount - discountValue
    } else {
      sumAfterDiscount = sumBeforeDiscount
    }


    valueAddedTax = (sumAfterDiscount * Number(_form.vat)) / 100

    grandTotalWithVat = sumAfterDiscount + valueAddedTax
    sumBeforeDiscount = roundToTwo(sumBeforeDiscount).toFixed(2)
    sumAfterDiscount = roundToTwo(sumAfterDiscount).toFixed(2)
    valueAddedTax = roundToTwo(valueAddedTax).toFixed(2)
    grandTotalWithVat = roundToTwo(grandTotalWithVat).toFixed(2)

    setForm({
      ..._form,
      value_added_tax: valueAddedTax,
      total_before_special_discount: sumBeforeDiscount,
      total_after_special_discount: sumAfterDiscount,
      grand_total_with_vat: grandTotalWithVat,
    })
  }

  const onEditTableData = (row, key, value) => {
    if (
      ((key === 'quantity' || key === 'price_in') && Number(value) >= 0) ||
      (key !== 'quantity' && key !== 'price_in')
    ) {
      let newProducts = []
      form.items.forEach((x) => {
        if (x.product_normal_code !== '') {
          if (
            x.product_normal_code === row.product_normal_code &&
            x.product_vat_code === row.product_vat_code
          ) {
            x[key] = value
            if (key !== 'remark') {
              x.total = sumProductTotal(x)
            }
            return newProducts.push(x)
          } else {
            return newProducts.push(x)
          }
        } else {
          if (
            x.product_vat_id === row.product_vat_id &&
            x.product_vat_code === row.product_vat_code
          ) {
            x[key] = value
            if (key !== 'remark') {
              x.total = sumProductTotal(x)
            }
            return newProducts.push(x)
          } else {
            return newProducts.push(x)
          }
        }
      })
      setForm({
        ...form,
        items: newProducts,
      }).then((_form) => {
        onChangeCalCulatedValue(_form)
      })
    }
    if ((key === 'quantity' || key === 'price_in') && Number(value) <= 0) {
      handleAlert('custom', 'จำนวนสินค้ากับราคา ควรมีค่ามากกว่า 0')
    }
  }

  const onDeleteProductFromBill = (row) => {
    let tempProduct = form.items
    setIsLoading(true)
    tempProduct = tempProduct.filter((x) => {
      if (x.product_normal_code !== '') {
        return (
          x.product_vat_code !== row.product_vat_code ||
          x.product_normal_code !== row.product_normal_code
        )
      } else {
        return x.product_vat_code !== row.product_vat_code
      }
    })
    setForm({
      ...form,
      total_items: tempProduct.length,
      items: [...tempProduct],
    }).then((_form) => {
      setIsLoading(false)
      onChangeCalCulatedValue(_form)
    })
  }

  const onSaveDoc = () => {
    if (
      form.is_credit_note === null ||
      form.doc_date === '' ||
      form.reason_id === null ||
      form.bill_no === '' ||
      form.supplier_doc_no === '' ||
      Number(form.total_items) === 0
    ) {
      handleAlert('custom', 'กรุณากรอกข้อมูลที่จำเป็นให้ครบถ้วน')
    } else {
      setIsLoading(true)
      dispatch(onSaveSupplierCreditNote(form))
    }
  }

  const printDoc = () => {
    window.open(
      `/vat/credit-note/supplier/print?doc_no=${form.doc_no}`,
      '_blank'
    )
    setPrintPopUp(false)
  }

  const onCancelDoc = () => {
    if (!form.is_canceled && form.id !== 0) {
      dispatch(onCancelSupplierNote(form.id))
    }
  }

  const onClearInput = () => {
    setForm(DEFAULT_FORM)
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'inline-block' }}>
          <h1>ใบลดหนี้/เพิ่มหนี้ ผู้ขาย</h1>
        </div>
        <div style={{ display: 'inline-block' }}>
          <Popconfirm
            title="คุณต้องการล้างหน้าจอใช่หรือไม่ ?"
            okText="ใช่"
            cancelText="ยกเลิก"
            onConfirm={onClearInput}
          >
            <Button
              type="default"
              danger
              icon={<ClearOutlined />}
              size="large"
              block
            >
              ล้างหน้าจอ
            </Button>
          </Popconfirm>
        </div>
      </div>
      <Modal
        width="500px"
        centered
        visible={printPopUp}
        title={
          <div>
            <CheckCircleTwoTone twoToneColor="#52c41a" /> บันทึกเรียบร้อย{' '}
          </div>
        }
        onCancel={() => {
          setPrintPopUp(false)
        }}
        onOk={printDoc}
      >
        <div>
          {form.is_credit_note
            ? `ต้องการพิมพ์ "ใบลดหนี้" หรือไม่ ?`
            : 'ต้องการพิมพ์ "ใบเพิ่มหนี้" หรือไม่ ?'}
        </div>
      </Modal>
      <SearchSupplierNotePopUp
        visible={isShowNotePopUp}
        onCancel={onCancelNotePopUp}
        onClick={onSelectNotePopUp}
      />
      <SearchSupplierBillVatPopUp
        visible={isShowBilltPopUp}
        onCancel={onCancelBillPopUp}
        onClick={onSelectBillPopUp}
        type="full"
      />
      {/* <button onClick={() => console.log(form)}>FORM</button> */}
      <Spin spinning={isLoading ? true : false}>
        <div className="form-wrapper-vat">
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="ประเภทเอกสาร"
                placeholder="เลือกประเภท"
                required
                disabled={form.id !== 0 || form.is_canceled ? true : false}
                value={
                  form.is_credit_note === null ? 3 : form.is_credit_note ? 1 : 2
                }
                onChange={(channel_id) => {
                  channel_id === 1
                    ? setForm({
                        ...form,
                        is_credit_note: true,
                        reason_id: null,
                      })
                    : channel_id === 2
                    ? setForm({
                        ...form,
                        is_credit_note: false,
                        reason_id: null,
                      })
                    : setForm({
                        ...form,
                        is_credit_note: null,
                        reason_id: null,
                      })
                }}
                width="120px"
                inputOption="select_input"
                options={DOC_TYPE}
              />
            </div>{' '}
            <div className="input-item">
              <InlineInput
                onSearch={(value) => {
                  onSearchNote(value)
                }}
                inputOption="search"
                required
                label="เลขที่เอกสาร"
                placeholder="เลขที่เอกสาร"
                isLoading={false}
                width="250px"
                value={form.doc_no}
                onChange={(event) => {
                  setForm({
                    ...form,
                    doc_no: event.target.value.toUpperCase(),
                  })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="วันที่เอกสาร"
                placeholder="กรุณาเลือกวันที่"
                required
                disabled={form.id !== 0 || form.is_canceled ? true : false}
                defaultValue={moment()}
                value={form.doc_date && moment(form.doc_date)}
                inputOption="date_picker"
                onChange={(_, dateString) => {
                  setForm({ ...form, doc_date: dateString })
                }}
              />
            </div>
            <div className="input-item" style={{ marginTop: '5px' }}>
              สถานะ:{' '}
              {form.is_canceled === null ? (
                '-'
              ) : form.is_canceled === false ? (
                <strong>
                  <span style={{ color: '#0000ff' }}>
                    บันทึกเข้าระบบเรียบร้อย
                  </span>
                </strong>
              ) : (
                <strong>
                  <span style={{ color: '#ff0000' }}>ถูกยกเลิก</span>
                </strong>
              )}
            </div>
            <div className="input-item">
              <Button
                type="default"
                icon={<PrinterOutlined />}
                size="large"
                style={{ height: '45px' }}
                block
                disabled={form.is_canceled || form.id === 0 ? true : false}
                onClick={() => {
                  if (
                    form.id !== 0 &&
                    form.doc_no !== '' &&
                    !form.is_canceled
                  ) {
                    setPrintPopUp(true)
                  } else {
                    Modal.warning({
                      centered: true,
                      title: 'ตรวจสอบ',
                      content: 'กรุณาค้นหาเอกสารก่อนสั่งพิมพ์',
                    })
                  }
                }}
              >
                พิมพ์บิล
              </Button>
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                onSearch={(value) => {
                  onSearchBill(value)
                }}
                inputOption="search"
                label="เลขที่บิล"
                placeholder="เลขที่บิล"
                disabled={form.id !== 0 || form.is_canceled ? true : false}
                isLoading={false}
                value={form.bill_no}
                onChange={(event) => {
                  setForm({
                    ...form,
                    bill_no: event.target.value.toUpperCase(),
                  })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="วันที่เปิดบิล"
                disabled
                value={form.bill_date && moment(form.bill_date)}
                inputOption="date_picker"
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="เลขที่เอกสารผู้ขาย"
                width="300px"
                required
                disabled={form.is_canceled ? true : false}
                value={form.supplier_doc_no ? form.supplier_doc_no : ''}
                onChange={(event) => {
                  setForm({
                    ...form,
                    supplier_doc_no: event.target.value.toUpperCase(),
                  })
                }}
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="รหัสผู้ขาย"
                width="150px"
                value={form.supplier_code ? form.supplier_code : ''}
                disabled={true}
                disableColon
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ชื่อผู้ขาย"
                width="350px"
                value={form.supplier_name ? form.supplier_name : ''}
                disabled={true}
                disableColon
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="ประเภทบิล"
                placeholder="เลือกประเภทบิล"
                value={form.bill_type_id ? form.bill_type_id : null}
                width="150px"
                disabled
                inputOption="select_input"
                options={listBillVatType.data}
              />
            </div>
            <div className="input-item">
              <InlineInput
                className="text-align-right"
                label="ยอดใบกำกับภาษี(ไม่รวมแวต)"
                width="150px"
                value={
                  form.bill_total_after_special_discount
                    ? Number(form.bill_total_after_special_discount).toFixed(2)
                    : ''
                }
                disabled={true}
                disableColon
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ค่าแวต"
                width="70px"
                value={form.vat ? Number(form.vat).toFixed(2) : ''}
                disabled={true}
                disableColon
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="สาเหตุ"
                placeholder="เลือกสาเหตุ"
                required
                disabled={
                  form.id !== 0 ||
                  form.is_canceled ||
                  form.is_credit_note === null
                    ? true
                    : false
                }
                value={form.reason_id}
                onChange={(reason_id) => {
                  setForm({ ...form, reason_id: reason_id })
                }}
                width="180px"
                inputOption="select_input"
                options={
                  form.is_credit_note ? listNoteReason.data : debitReason
                }
              />
              {form.is_credit_note === null ? (
                <div>- กรุณาเลือกประเภทเอกสารก่อนระบุสาเหตุ</div>
              ) : (
                ''
              )}
            </div>
          </div>
          <div style={{ background: '#f1f1f1', marginBottom: '20px' }}>
            <SupplierCreditNoteProductTable
              productList={form.items}
              onChange={onEditTableData}
              onDelete={onDeleteProductFromBill}
            />
          </div>
          <div style={{ display: 'flex' }}>
            <div
              style={{
                width: '8%',
                display: 'inline-block',
                marginRight: '20px',
              }}
            >
              <div style={{ marginBottom: '10px' }}>
                <Popconfirm
                  title={`คุณต้องการ "ยกเลิก" เอกสานี้ใช่หรือไม่ ?`}
                  okText="ยกเลิก"
                  cancelText="ไม่ต้องการ"
                  onConfirm={onCancelDoc}
                >
                  <Button
                    type="primary"
                    danger
                    icon={<CloseOutlined />}
                    size="large"
                    disabled={form.is_canceled ? true : false}
                    style={{ height: '80px' }}
                    block
                  >
                    ยกเลิก
                  </Button>
                </Popconfirm>
              </div>
            </div>
            <div style={{ width: '32%', display: 'inline-block' }}>
              <div className="input-wrapper">
                <div className="input-item">
                  <InlineInput
                    className="text-align-right"
                    label="ส่วนลดพิเศษ"
                    width="150px"
                    value={form.special_discount}
                    unit="บาท"
                    onChange={(event) => {
                      setForm({
                        ...form,
                        special_discount: event.target.value,
                      }).then((_form) => onChangeCalCulatedValue(_form))
                    }}
                  />
                </div>
                <div className="input-item">
                  <InlineInput
                    label="หมายเหตุ"
                    width="250px"
                    value={form.remark}
                    onChange={(event) => {
                      setForm({ ...form, remark: event.target.value })
                    }}
                  />
                </div>
              </div>
            </div>
            <div style={{ width: '20%', display: 'inline-block' }}>
              <div className="input-wrapper">
                <div className="input-item margin-left-auto">
                  <InlineInput
                    className="text-align-right"
                    label="ภาษีมูลค่าเพิ่ม"
                    width="150px"
                    disabled
                    value={form.value_added_tax}
                  />
                </div>
              </div>
            </div>
            <div style={{ width: '30%', display: 'inline-block' }}>
              <div className="input-wrapper">
                <div className="input-item margin-left-auto">
                  <InlineInput
                    className="text-align-right"
                    label="ยอดก่อนหักส่วนลดพิเศษ"
                    width="180px"
                    value={form.total_before_special_discount}
                    disabled
                  />
                </div>
                <div className="input-item margin-left-auto">
                  <InlineInput
                    className="text-align-right"
                    label="ยอดหลังหักส่วนลดพิเศษ"
                    width="180px"
                    disabled
                    value={form.total_after_special_discount}
                  />
                </div>
                <div className="input-item margin-left-auto">
                  <InlineInput
                    className="text-align-right"
                    label="ยอดรวมภาษ๊"
                    width="180px"
                    disabled
                    value={form.grand_total_with_vat}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                width: '10%',
                marginLeft: 'auto',
                display: 'inline-block',
              }}
            >
              <div>
                <Button
                  type="primary"
                  icon={<SaveOutlined />}
                  size="large"
                  block
                  disabled={form.is_canceled ? true : false}
                  style={{ height: '80px', marginBottom: '10px' }}
                  onClick={onSaveDoc}
                >
                  บันทึก
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  )
}

export default withRouter(SupplierCreditNotePage)
