/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Button, Spin, Modal, Popconfirm } from 'antd'
import { SaveOutlined, ClearOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import InlineInput from 'components/InlineInput'
import SearchProductNoVatPopUp from 'components/SearchProductNoVatPopUp'
import { handleAlert } from 'utils'

import {
  onGetUnitList,
  onGetProductByCode,
  onGetReasonList,
  onSaveTemporaryStock,
} from 'redux/actions'

const DEFAULT_FORM = {
  product_id: 0,
  product_code: '',
  product_name: '',
  quantity: '',
  unit_id: '',
  reason_value: '',
}

const TemporaryStockPage = () => {
  const [form, setForm] = useState(DEFAULT_FORM)
  const [isLoading, setIsLoading] = useState(false)
  const [isShowProductPopUp, setIsShowProductPopUp] = useState(false)
  const getUnitList = useSelector((state) => state.getUnitList)
  const getReasonList = useSelector((state) => state.getReasonList)
  const getProductByCode = useSelector((state) => state.getProductByCode)
  const saveTemporaryStock = useSelector((state) => state.saveTemporaryStock)
  const dispatch = useDispatch()
  const [isDisableName, setIsDisableName] = useState(false)
  const [unitList, setUnitList] = useState([])
  const [reasonList, setReasonList] = useState([])

  useEffect(() => {
    dispatch(onGetUnitList())
    dispatch(onGetReasonList())
    document.title = 'นำสินค้าเข้าเพื่อเปิดบิล - NoVat'
  }, [])

  useEffect(() => {
    if (!getUnitList.error && getUnitList.finished && !getUnitList.loading) {
      setUnitList(getUnitList.data)
    }
    if (getUnitList.error && !getUnitList.loading) {
      handleAlert('error', getUnitList.trace.message || '')
    }
  }, [getUnitList])

  useEffect(() => {
    setIsLoading(getReasonList.loading)
    if (
      !getReasonList.error &&
      getReasonList.finished &&
      !getReasonList.loading
    ) {
      const data = getReasonList.data.map((e) => ({
        id: e.value,
        name: e.reason,
      }))
      setReasonList(data)
    }
  }, [getReasonList])

  useEffect(() => {
    const { trace, finished, error, loading } = getProductByCode
    setIsLoading(getProductByCode.loading)
    if (!error && finished && !loading) {
      setIsDisableName(true)
      handleAlert('success')
      setForm({
        product_id: getProductByCode.data.id,
        product_code: getProductByCode.data.code,
        product_name: getProductByCode.data.name,
        unit_id: getProductByCode.data.unit_id,
      })
    }
    if (error && !loading && !finished) {
      setIsDisableName(false)
      handleAlert('custom', trace.message || '')
      setForm((prev) => ({
        ...DEFAULT_FORM,
        product_code: prev.product_code,
      }))
    }
  }, [getProductByCode])

  useEffect(() => {
    setIsLoading(saveTemporaryStock.loading)
    if (
      !saveTemporaryStock.error &&
      saveTemporaryStock.finished &&
      !saveTemporaryStock.loading
    ) {
      Modal.success({
        centered: true,
        content: 'บันทึกเรียบร้อย',
      })
      setForm(DEFAULT_FORM)
    }
    if (saveTemporaryStock.error && !saveTemporaryStock.loading) {
      handleAlert('custom', 'ไม่สามารถทำรายการได้ กรุณาลองใหม่อีกครั้ง')
    }
  }, [saveTemporaryStock])

  const saveTemporaryStockHandler = () => {
    dispatch(onSaveTemporaryStock(form))
  }

  const onSearchProductHandler = (code) => {
    if (code !== '') {
      dispatch(onGetProductByCode(code))
    } else {
      setIsShowProductPopUp(true)
    }
  }

  const onCancelProdutPopUp = () => {
    setIsShowProductPopUp(false)
  }

  const onSelectProductPopUp = (data) => {
    onSearchProductHandler(data.code)
    setIsShowProductPopUp(false)
  }

  const onClearInput = () => {
    setForm(DEFAULT_FORM)
    setIsDisableName(false)
  }
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'inline-block' }}>
          <h1>นำสินค้าเข้าเพื่อเปิดบิล</h1>
        </div>
        <div style={{ display: 'inline-block' }}>
          <Popconfirm
            title="คุณต้องการล้างหน้าจอใช่หรือไม่ ?"
            okText="ใช่"
            cancelText="ยกเลิก"
            onConfirm={onClearInput}
          >
            <Button
              type="default"
              danger
              icon={<ClearOutlined />}
              size="large"
              block
            >
              ล้างหน้าจอ
            </Button>
          </Popconfirm>
        </div>
      </div>
      <SearchProductNoVatPopUp
        visible={isShowProductPopUp}
        onCancel={onCancelProdutPopUp}
        onClick={onSelectProductPopUp}
      />
      <Spin spinning={isLoading}>
        <div className="form-wrapper">
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                onSearch={(value) => {
                  onSearchProductHandler(value)
                }}
                inputOption="search"
                label="รหัสสินค้า"
                placeholder="รหัสสินค้า"
                isLoading={false}
                required={true}
                value={form.product_code}
                onChange={(event) => {
                  setForm({
                    ...form,
                    product_code: event.target.value.toUpperCase(),
                  })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ชื่อสินค้า"
                width="400px"
                value={form.product_name}
                disabled={isDisableName}
                onChange={(event) =>
                  setForm({ ...form, product_name: event.target.value })
                }
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="จำนวน"
                width="100px"
                value={form.quantity ? Number(form.quantity) : 0}
                onChange={(event) =>
                  setForm({ ...form, quantity: event.target.value })
                }
              />
            </div>
            <div className="input-item">
              <InlineInput
                value={form.unit_id ? form.unit_id : ''}
                onChange={(value) => {
                  setForm({ ...form, unit_id: value })
                }}
                width="100px"
                inputOption="select_input"
                options={unitList}
                disableColon
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="สาเหตุ"
                value={form.reason_value ? form.reason_value : ''}
                onChange={(value) => {
                  setForm({ ...form, reason_value: value })
                }}
                width="200px"
                inputOption="select_input"
                options={reasonList}
                disableColon
              />
            </div>
          </div>
          <div
            className="input-wrapper"
            style={{ width: '270px', margin: '40px auto 0' }}
          >
            <div className="input-item">
              <Button
                type="primary"
                icon={<SaveOutlined />}
                size="large"
                onClick={saveTemporaryStockHandler}
              >
                บันทึก
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  )
}

export default withRouter(TemporaryStockPage)
