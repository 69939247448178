import { useContext } from 'react'
import { StoreReportDataContext } from '../contexts/StoreReportDataContext'

const useStoreReportDataContext = () => {
  const context = useContext(StoreReportDataContext)
  if (!context)
    throw new Error(
      'OpenStoreDataContext must be use inside PaymentOrderProvider'
    )
  return context
}

export default useStoreReportDataContext
