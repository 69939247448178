import { useContext } from 'react'
import { EditPriceInContext } from '../contexts/EditPriceInDataContext'

const useEditPriceInDataContext = () => {
  const context = useContext(EditPriceInContext)
  if (!context)
    throw new Error(
      'OpenStoreDataContext must be use inside PaymentOrderProvider'
    )
  return context
}

export default useEditPriceInDataContext
