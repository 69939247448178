import React, { useState, useEffect } from 'react'
import { Spin, Button, Popconfirm, Modal } from 'antd'
import { DeleteOutlined, ClearOutlined } from '@ant-design/icons'

import InlineInput from 'components/InlineInput'

import { onGetProductByCode, onDeleteProduct } from 'redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import SearchProductNoVatPopUp from 'components/SearchProductNoVatPopUp'
import { handleAlert } from 'utils'

const DEFAULT_FORM = {
  id: null,
  code: '',
  name: '',
  stock1: 0,
  stock2: 0,
}

const DeleteProductPage = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [form, setForm] = useState(DEFAULT_FORM)
  const [isShowProductPopUp, setIsShowProductPopUp] = useState(false)
  const [isShowDeletePopUp, setIsShowDeletePopUp] = useState(false)
  const dispatch = useDispatch()
  const getProductByCode = useSelector((state) => state.getProductByCode)
  const deleteProduct = useSelector((state) => state.deleteProduct)
  useEffect(() => {
    document.title = 'ลบสินค้า - NoVat'
  }, [])

  useEffect(() => {
    setIsLoading(getProductByCode.loading)
    if (
      !getProductByCode.loading &&
      !getProductByCode.error &&
      getProductByCode.finished
    ) {
      const { data } = getProductByCode
      setForm({
        id: data.id,
        code: data.code,
        name: data.name,
        stock1: data.stock1,
        stock2: data.stock2,
      })
    }
    if (!getProductByCode.loading && getProductByCode.error) {
      setForm(DEFAULT_FORM)
      handleAlert('error', getProductByCode.trace.message || '')
    }
  }, [getProductByCode])

  const onChangeInputValue = (value, name) => {
    if (errors[name] && value) {
      const tmp_errors = { ...errors }
      delete tmp_errors[name]
      setErrors(tmp_errors)
    }
    setForm({ ...DEFAULT_FORM, [name]: value.toUpperCase() })
  }

  useEffect(() => {
    const { error, loading, finished, trace } = deleteProduct
    setIsLoading(loading)
    if (!error && !loading && finished) {
      handleAlert('success')
      setForm(DEFAULT_FORM)
    }
    if (error && !loading) {
      handleAlert('error', trace.message || '')
    }
  }, [deleteProduct])

  const showPopUpBeforeDelete = () => {
    if (form.id !== null) {
      setIsShowDeletePopUp(true)
    } else {
      handleAlert('custom', 'กรุณาเลือกสินค้าก่อนทำการลบ')
    }
  }

  const _deleteProduct = () => {
    if (Number(form.stock1) !== 0) {
      handleAlert('custom', 'ไม่สามารถลบได้ สต็อกต้องเป็น 0')
      setErrors({
        ...errors,
        stock1: 'สต็อกต้องเป็น 0',
      })
      setIsShowDeletePopUp(false)
    } else if (Number(form.stock2) !== 0) {
      handleAlert('custom', 'ไม่สามารถลบได้ สต็อกต้องเป็น 0')
      setErrors({
        ...errors,
        stock2: 'สต็อกต้องเป็น 0',
      })
      setIsShowDeletePopUp(false)
    } else {
      setErrors({})
      dispatch(onDeleteProduct(form.id))
      setIsShowDeletePopUp(false)
    }
  }

  const onSearchCodeHandler = (code) => {
    if (code !== '') {
      dispatch(onGetProductByCode(code))
    } else {
      setIsShowProductPopUp(true)
    }
  }

  const onCancelProdutPopUp = () => {
    setIsShowProductPopUp(false)
  }

  const onSelectProductPopUp = (data) => {
    onSearchCodeHandler(data.code)
    setIsShowProductPopUp(false)
  }

  const onClearProduct = () => {
    setForm(DEFAULT_FORM)
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'inline-block' }}>
          <h1>ลบสินค้า</h1>
        </div>
        <div style={{ display: 'inline-block' }}>
          <Popconfirm
            title="คุณต้องการล้างหน้าจอใช่หรือไม่ ?"
            okText="ใช่"
            cancelText="ยกเลิก"
            onConfirm={onClearProduct}
          >
            <Button
              type="default"
              danger
              icon={<ClearOutlined />}
              size="large"
              block
            >
              ล้างหน้าจอ
            </Button>
          </Popconfirm>
        </div>
      </div>
      <Modal
        title="ลบสินค้า"
        visible={isShowDeletePopUp}
        onOk={() => _deleteProduct()}
        onCancel={() => setIsShowDeletePopUp(false)}
      >
        การลบสินค้า จะส่งผลให้สินค้าชิ้นนี้หายไปจากระบบทันที
        และจะไม่เหลือข้อมูลใดๆ ทิ้งไว้ในระบบอีกต่อไป คุณแน่ใจที่จะ "ลบ"
        สินค้าชิ้นนี้ใช่หรือไม่ ?
      </Modal>
      <SearchProductNoVatPopUp
        visible={isShowProductPopUp}
        onCancel={onCancelProdutPopUp}
        onClick={onSelectProductPopUp}
      />
      <Spin spinning={isLoading}>
        <div className="form-wrapper">
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                onSearch={(value) => {
                  setForm({ ...form, code: value })
                  onSearchCodeHandler(value)
                }}
                inputOption="search"
                label="รหัสสินค้า"
                placeholder="รหัสสินค้า"
                isLoading={false}
                required={true}
                value={form.code}
                onChange={(event) => {
                  onChangeInputValue(event.target.value, 'code')
                }}
                error={errors.code}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ชื่อสินค้า"
                width="300px"
                value={form.name}
                onChange={(event) =>
                  setForm({ ...form, name: event.target.value })
                }
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="จำนวนในสต็อก1"
                value={form.stock1}
                onChange={(event) =>
                  setForm({ ...form, stock1: event.target.value })
                }
                width="100px"
                disabled={true}
                error={errors.stock1}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="จำนวนในสต็อก2"
                value={form.stock2}
                onChange={(event) =>
                  setForm({ ...form, stock2: event.target.value })
                }
                width="100px"
                disabled={true}
                error={errors.stock2}
              />
            </div>
          </div>
          <div className="text-right">
            <Button
              type="primary"
              danger
              icon={<DeleteOutlined />}
              size="large"
              onClick={() => showPopUpBeforeDelete()}
            >
              ลบสินค้า
            </Button>
          </div>
        </div>
      </Spin>
    </div>
  )
}

export default DeleteProductPage
