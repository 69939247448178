import React from 'react'
import { Table } from 'antd'
import { addCommas } from 'utils'
import moment from 'moment'
import 'moment/locale/th'
moment.locale('th')

const EditProductPriceHistoryTable = (props) => {
  const { historyList } = props
  const tableColumns = [
    {
      title: 'วันที่แก้ไข',
      dataIndex: 'created',
      key: 'created',
      width: '140px',
      sorter: (a, b) => ('' + a.created).localeCompare(b.created),
      sortDirections: ['ascend', 'descend'],
      fixed: 'left',
      render: (_, record) => {
        return moment(record.created).format('L LT')
      },
    },
    {
      title: 'รหัสสินค้า',
      dataIndex: 'code',
      key: 'code',
      sorter: (a, b) => ('' + a.code).localeCompare(b.code),
      sortDirections: ['ascend', 'descend'],
      width: '120px',
      fixed: 'left',
      render: (text) => <div className="break-word">{text}</div>,
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'name',
      key: 'name',
      width: '200px',
      fixed: 'left',
      render: (text) => <div className="break-word">{text}</div>,
    },
    {
      title: 'ราคาขายส่งใหม่',
      dataIndex: 'new_price_out_atm',
      key: 'new_price_out_atm',
      width: '120px',
      render: (_, record) => (
        <div className="text-align-right pr-2 new-price">
          {record.new_price_out_atm === null
            ? ''
            : addCommas(record.new_price_out_atm)}
        </div>
      ),
    },
    {
      title: 'ส่วนลดราคาส่งใหม่',
      width: '150px',
      render: (_, record) => {
        if (record.new_discount_atm_1 === null) {
          return ''
        } else {
          return (
            <div className="new-price">
              {Number(record.new_discount_atm_1)}+
              {Number(record.new_discount_atm_2)}+
              {Number(record.new_discount_atm_3)}+
              {Number(record.new_discount_atm_4)}
            </div>
          )
        }
      },
    },
    {
      title: 'ราคาขายปลีกใหม่',
      dataIndex: 'new_price_out_cash',
      key: 'new_price_out_cash',
      width: '120px',
      render: (_, record) => (
        <div className="text-align-right pr-2 new-price">
          {record.new_price_out_cash === null
            ? ''
            : addCommas(record.new_price_out_cash)}
        </div>
      ),
    },
    {
      title: 'ส่วนลดราคาปลีกใหม่',
      width: '150px',
      render: (_, record) => {
        if (record.new_discount_cash_1 === null) {
          return ''
        } else {
          return (
            <div className="new-price">
              {Number(record.new_discount_cash_1)}+
              {Number(record.new_discount_cash_2)}+
              {Number(record.new_discount_cash_3)}+
              {Number(record.new_discount_cash_4)}
            </div>
          )
        }
      },
    },
    {
      title: 'ราคาขายส่งเก่า',
      dataIndex: 'old_price_out_atm',
      key: 'old_price_out_atm',
      width: '120px',
      render: (_, record) => (
        <div className="text-align-right pr-2">
          {record.old_price_out_atm === null
            ? ''
            : addCommas(record.old_price_out_atm)}
        </div>
      ),
    },
    {
      title: 'ส่วนลดราคาส่งเก่า',
      width: '150px',
      render: (_, record) => {
        if (record.old_discount_atm_1 === null) {
          return ''
        } else {
          return (
            <div>
              {Number(record.old_discount_atm_1)}+
              {Number(record.old_discount_atm_2)}+
              {Number(record.old_discount_atm_3)}+
              {Number(record.old_discount_atm_4)}
            </div>
          )
        }
      },
    },
    {
      title: 'ราคาขายปลีกเก่า',
      dataIndex: 'old_price_out_cash',
      key: 'old_price_out_cash',
      width: '120px',
      render: (_, record) => (
        <div className="text-align-right pr-2">
          {record.old_price_out_cash === null
            ? ''
            : addCommas(record.old_price_out_cash)}
        </div>
      ),
    },
    {
      title: 'ส่วนลดราคาปลีกเก่า',
      width: '150px',
      render: (_, record) => {
        if (record.old_discount_cash_1 === null) {
          return ''
        } else {
          return (
            <div>
              {Number(record.old_discount_cash_1)}+
              {Number(record.old_discount_cash_2)}+
              {Number(record.old_discount_cash_3)}+
              {Number(record.old_discount_cash_4)}
            </div>
          )
        }
      },
    },
  ]

  return (
    <div>
      <Table
        rowKey={(record) => `id+${record.id}`}
        dataSource={historyList}
        columns={tableColumns}
        pagination={{
          simple: true,
          defaultPageSize: 20,
          showSizeChanger: true,
          position: ['bottomRight'],
          pageSizeOptions: ['10', '20', '50', '100'],
        }}
        size="small"
        scroll={{ x: 1200, y: 750 }}
        style={{
          maxWidth: '1550px',
          height: '740px',
          minHeight: '100%',
          backgroundColor: '#FFFFFF',
          marginTop: '10px',
        }}
      />
    </div>
  )
}

export default EditProductPriceHistoryTable
