import { Popconfirm, Button } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

import React from 'react'
import { addCommas } from 'utils'

const columns = ({ onDeleteCash }) => {
  const tableColumns = [
    {
      title: '',
      dataIndex: '',
      key: '',
      width: '40px',
      fixed: 'left',
      render: (_, record) => {
        if (record.id === 'total') {
          return <></>
        } else {
          return (
            <Popconfirm
              title="คุณต้องการลบรายการนี้ใช่ไหม ?"
              okText="ลบ"
              cancelText="ยกเลิก"
              onConfirm={() => onDeleteCash(record.id)}
            >
              <Button
                size="small"
                type="primary"
                danger
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          )
        }
      },
    },
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
      width: '50px',
      fixed: 'left',
    },
    {
      title: 'วันที่',
      dataIndex: 'date',
      key: 'date',
      width: '100px',
      fixed: 'left',
    },
    {
      title: '1000',
      dataIndex: 'thousand',
      key: 'thousand',
      width: '70px',
      render: (_, record) => (
        <div className="text-align-right pr-2">
          {addCommas(record.banknote.thousand)}
        </div>
      ),
    },
    {
      title: '500',
      dataIndex: 'fivehundred',
      key: 'fivehundred',
      width: '70px',
      render: (_, record) => (
        <div className="text-align-right pr-2">
          {addCommas(record.banknote.fivehundred)}
        </div>
      ),
    },
    {
      title: '100',
      dataIndex: 'onehundred',
      key: 'onehundred',
      width: '70px',
      render: (_, record) => (
        <div className="text-align-right pr-2">
          {addCommas(record.banknote.onehundred)}
        </div>
      ),
    },
    {
      title: '50',
      dataIndex: 'fifty',
      key: 'fifty',
      width: '70px',
      render: (_, record) => (
        <div className="text-align-right pr-2">
          {addCommas(record.banknote.fifty)}
        </div>
      ),
    },
    {
      title: '20',
      dataIndex: 'twenty',
      key: 'twenty',
      width: '70px',
      render: (_, record) => (
        <div className="text-align-right pr-2">
          {addCommas(record.banknote.twenty)}
        </div>
      ),
    },
    {
      title: '10',
      dataIndex: 'ten',
      key: 'ten',
      width: '70px',
      render: (_, record) => (
        <div className="text-align-right pr-2">
          {addCommas(record.banknote.ten)}
        </div>
      ),
    },
    {
      title: '5',
      dataIndex: 'five',
      key: 'five',
      width: '70px',
      render: (_, record) => (
        <div className="text-align-right pr-2">
          {addCommas(record.banknote.five)}
        </div>
      ),
    },
    {
      title: '2',
      dataIndex: 'two',
      key: 'two',
      width: '70px',
      render: (_, record) => (
        <div className="text-align-right pr-2">
          {addCommas(record.banknote.two)}
        </div>
      ),
    },
    {
      title: '1',
      dataIndex: 'one',
      key: 'one',
      width: '70px',
      render: (_, record) => (
        <div className="text-align-right pr-2">
          {addCommas(record.banknote.one)}
        </div>
      ),
    },
    {
      title: 'total',
      dataIndex: 'total',
      key: 'total',
      width: '100px',
      render: (_, record) => (
        <div className="text-align-right pr-2">
          {addCommas(record.banknote.total)}
        </div>
      ),
    },
  ]
  return tableColumns
}

export default columns
