import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Result, Button } from 'antd'
import { useHistory } from 'react-router-dom'

const UnauthorizedPage = (props) => {
  useEffect(() => {
    document.title = 'Bonlighting Admin'
  }, [])
  let history = useHistory()
  return (
    <div>
      <Result
        status="403"
        title="คุณยังไม่ได้เข้าสู่ระบบ"
        subTitle="กรุณา login เข้าสู่ระบบก่อนใช้งาน"
        extra={
          <Button onClick={() => history.push('/login')} type="primary">
            Login
          </Button>
        }
      />
    </div>
  )
}

export default withRouter(UnauthorizedPage)
