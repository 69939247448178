/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Table, Spin, Button, Input, Popconfirm, Modal } from 'antd'
import {
  DeleteOutlined,
  ClearOutlined,
  SelectOutlined,
  PlusOutlined,
  EditOutlined,
} from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'

import {
  // onGetProductGroup,
  onListProductGroup,
  onListNoGroupProuctNormal,
  onListNoGroupProuctVat,
  onSaveProductGroup,
  onDeleteProductGroup,
  onAddProductToGroup,
  onDeleteProductFromGroup,
} from 'redux/actions'
import InlineInput from 'components/InlineInput'
import { handleAlert } from 'utils'
import './style.scss'

const Search = Input.Search

const ProductGroupMappingPage = () => {
  const NO_GROUP_NORMAL_COLUMN = [
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: '40px',
      fixed: 'left',
      render: (_, record, index) => {
        return (
          <Button
            size="small"
            type="primary"
            icon={<SelectOutlined />}
            onClick={() => handleAddProductToGroup(record, 'normal', index)}
          />
        )
      },
    },
    {
      title: 'รหัส',
      dataIndex: 'code',
      key: 'code',
      width: '70px',
      sorter: (a, b) => ('' + a.code).localeCompare(b.code),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'name',
      key: 'name',
      width: '500px',
      sorter: (a, b) => ('' + a.name).localeCompare(b.name),
      sortDirections: ['ascend', 'descend'],
    },
  ]
  const NO_GROUP_VAT_COLUMN = [
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: '30px',
      fixed: 'left',
      render: (_, record) => {
        return (
          <Button
            size="small"
            type="primary"
            icon={<SelectOutlined />}
            onClick={() => handleAddProductToGroup(record, 'vat')}
          />
        )
      },
    },
    {
      title: 'รหัส',
      dataIndex: 'code',
      key: 'code',
      width: '70px',
      sorter: (a, b) => ('' + a.code).localeCompare(b.code),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'name',
      key: 'name',
      width: '500px',
      sorter: (a, b) => ('' + a.name).localeCompare(b.name),
      sortDirections: ['ascend', 'descend'],
    },
  ]
  const GROUP_COLUMN = [
    {
      title: 'ชื่อกลุ่ม',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: '78%',
      className: 'break-word',
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: '22%',
      render: (record) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div style={{ display: 'inline-block', width: '32px' }}>
              <Button
                icon={<EditOutlined />}
                size="small"
                block
                onClick={(event) => {
                  event.stopPropagation()
                  setNewGroup({
                    group_id: record.id,
                    group_name: record.name,
                  })
                  setIsShowAddModal(true)
                }}
              />
            </div>
            <div style={{ display: 'inline-block', width: '30px' }}>
              <Popconfirm
                title={`คุณต้องการลบกลุ่ม "${record.name}" ใช่ไหม ?`}
                okText="ใช่"
                cancelText="ยกเลิก"
                onConfirm={(event) => {
                  event.stopPropagation()
                  dispatch(onDeleteProductGroup(record.id))
                }}
                onCancel={(event) => {
                  event.stopPropagation()
                }}
              >
                <Button
                  type="primary"
                  danger
                  icon={<DeleteOutlined />}
                  size="small"
                  block
                  onClick={(event) => {
                    event.stopPropagation()
                  }}
                />
              </Popconfirm>
            </div>
          </div>
        )
      },
    },
  ]
  const DEFAULT_NEW_GROUP = {
    group_id: 0,
    group_name: '',
  }
  const DEFAULT_ADD_PRODUCT_TO_GROUP = {
    group_id: '',
    product_normal_code: '',
    product_vat_code: '',
  }
  function useAsyncState(initialValue) {
    const [value, setValue] = useState(initialValue)
    const setter = (x) =>
      new Promise((resolve) => {
        setValue(x)
        resolve(x)
      })
    return [value, setter]
  }
  // const [isLoading, setIsLoading] = useState(false)
  const [activeGroup, setActiveGroup] = useState(null)
  const [tempList, setTempList] = useState([])
  const [formAddProductToGroup, setFormAddProductToGroup] = useAsyncState(
    DEFAULT_ADD_PRODUCT_TO_GROUP
  )
  const [lastestProductGroupList, setLastestProductGroupList] = useState([])
  const [filteredProductGroupList, setFilteredProductGroupList] = useAsyncState(
    []
  )
  const [isProductGroupLoading, setIsProductGroupLoading] = useState(false)
  const [searchGroup, setSearchGroup] = useState('')
  const [searchGroupMember, setSearchGroupMember] = useState('')
  const [lastestNoGroupNormalList, setLastestNoGroupNormalList] = useState([])
  const [filteredNoGroupNormalList, setFilteredNoGroupNormalList] = useState([])
  const [isNoGroupNormalLoading, setIsNoGroupNormalLoading] = useState(false)
  const [searchNormal, setSearchNormal] = useState('')
  const [searchNormalName, setSearchNormalName] = useState('')
  const [lastestNoGroupVatList, setLastestNoGroupVatList] = useState([])
  const [filteredNoGroupVatList, setFilteredNoGroupVatList] = useState([])
  const [isNoGroupVatLoading, setIsNoGroupVatLoading] = useState(false)
  const [searchVat, setSearchVat] = useState('')
  const [searchVatName, setSearchVatName] = useState('')
  const [tempProduct, setTempProduct] = useState({})
  const [newGroup, setNewGroup] = useState(DEFAULT_NEW_GROUP)
  const [isShowAddModal, setIsShowAddModal] = useState(false)
  const [expandedRowKeys, setExpandedRowKeys] = useState([])

  const dispatch = useDispatch()
  // const getProductGroup = useSelector((state) => state.getProductGroup)
  const listProductGroup = useSelector((state) => state.listProductGroup)
  const listNoGroupProductNormal = useSelector(
    (state) => state.listNoGroupProductNormal
  )
  const listNoGroupProductVat = useSelector(
    (state) => state.listNoGroupProductVat
  )
  const saveProductGroup = useSelector((state) => state.saveProductGroup)
  const deleteProductGroup = useSelector((state) => state.deleteProductGroup)
  const addProductToGroup = useSelector((state) => state.addProductToGroup)
  const deleteProductFromGroup = useSelector(
    (state) => state.deleteProductFromGroup
  )

  useEffect(() => {
    dispatch(onListProductGroup())
    dispatch(onListNoGroupProuctNormal())
    dispatch(onListNoGroupProuctVat())
    document.title = 'จับคู่รหัสสินค้า [VAT]'
  }, [])

  useEffect(() => {
    const { error, data, loading, finished, trace } = listProductGroup
    setIsProductGroupLoading(loading)
    if (!error && finished && !loading && data) {
      setLastestProductGroupList(data)
      setIsProductGroupLoading(false)
    }
    if (error && !loading) {
      setIsProductGroupLoading(false)
      handleAlert('error', trace.message || '')
    }
  }, [listProductGroup])

  useEffect(() => {
    const { error, data, loading, finished, trace } = listNoGroupProductNormal
    if (!error && finished && !loading && data) {
      setLastestNoGroupNormalList(data)
      setIsNoGroupNormalLoading(loading)
    }
    if (error && !loading) {
      setIsNoGroupNormalLoading(loading)
      handleAlert('error', trace.message || '')
    }
  }, [listNoGroupProductNormal])

  useEffect(() => {
    const { error, data, loading, finished, trace } = listNoGroupProductVat
    if (!error && finished && !loading && data) {
      setIsNoGroupVatLoading(loading)
      setLastestNoGroupVatList(data)
    }
    if (error && !loading) {
      setIsNoGroupVatLoading(loading)
      handleAlert('error', trace.message || '')
    }
  }, [listNoGroupProductVat])

  useEffect(() => {
    if (lastestProductGroupList !== []) {
      if (searchGroup === '') {
        handleGroupMemberSearch(searchGroupMember)
      }
      if (searchGroupMember === '') {
        handleGroupSearch(searchGroup)
      }
    }
  }, [lastestProductGroupList, searchGroup, searchGroupMember])

  useEffect(() => {
    if (lastestNoGroupNormalList !== []) {
      if (searchNormal === '') {
        handleNormalNameSearch(searchNormalName)
      }
      if (searchNormalName === '') {
        handleNormalSearch(searchNormal)
      }
    }
  }, [lastestNoGroupNormalList, searchNormal, searchNormalName])

  useEffect(() => {
    if (lastestNoGroupVatList !== []) {
      if (searchVat === '') {
        handleVatNameSearch(searchVatName)
      }
      if (searchVatName === '') {
        handleVatSearch(searchVat)
      }
    }
  }, [lastestNoGroupVatList, searchVat, searchVatName])

  useEffect(() => {
    const { error, loading, finished, data, trace } = addProductToGroup
    let addedProduct = formAddProductToGroup
    if (!error && !loading && finished && data) {
      let newList = lastestProductGroupList.map((group) =>
        group.id === data.id ? data : group
      )
      setLastestProductGroupList(newList)
      if (addedProduct.product_normal_code !== '') {
        let lastestNormal = lastestNoGroupNormalList
        setIsNoGroupNormalLoading(true)
        lastestNormal = lastestNormal.filter(
          (x) => x.code !== addedProduct.product_normal_code
        )
        setLastestNoGroupNormalList(lastestNormal)
        setIsNoGroupNormalLoading(false)
      } else {
        setIsNoGroupVatLoading(true)
        let lastestVat = lastestNoGroupVatList
        lastestVat = lastestVat.filter(
          (x) => x.code !== addedProduct.product_vat_code
        )
        setLastestNoGroupVatList(lastestVat)
        setIsNoGroupVatLoading(false)
      }
    }
    if (error && !loading) {
      Modal.error({
        centered: true,
        title: 'ตรวจสอบ',
        content: `${trace.message}`,
      })
    }
  }, [addProductToGroup])

  const fetchAllList = () => {
    dispatch(onListProductGroup())
    dispatch(onListNoGroupProuctNormal())
    dispatch(onListNoGroupProuctVat())
    setIsProductGroupLoading(false)
  }

  useEffect(() => {
    const { error, data, loading, finished, trace } = saveProductGroup
    if (!error && finished && !loading && data) {
      let pharseData = {
        id: data.id,
        name: data.name,
        members: [],
      }
      let _tempList = tempList
      let _newGroup = newGroup
      if (_newGroup.group_id !== 0) {
        let newList = _tempList.map((group) => {
          if (group.id === pharseData.id) {
            return {
              ...group,
              name: pharseData.name,
            }
          } else return group
        })
        setLastestProductGroupList([...newList])
        setNewGroup(DEFAULT_NEW_GROUP)
        setIsProductGroupLoading(false)
        setIsShowAddModal(false)
      } else {
        setLastestProductGroupList([pharseData, ...lastestProductGroupList])
        setNewGroup(DEFAULT_NEW_GROUP)
        setIsProductGroupLoading(false)
        setIsShowAddModal(false)
      }
    }
    if (error && !loading) {
      Modal.error({
        centered: true,
        title: 'ตรวจสอบ',
        content: `${
          trace.message !== undefined
            ? trace.message
            : trace.name !== undefined
            ? 'ไม่สามารถตั้งชื่อกลุ่มซ้ำได้'
            : ''
        }`,
      })
      setIsShowAddModal(false)
      setIsProductGroupLoading(false)
    }
  }, [saveProductGroup])

  useEffect(() => {
    const { error, data, loading, finished, trace } = deleteProductGroup
    if (!error && finished && !loading && data) {
      setIsNoGroupNormalLoading(true)
      setIsProductGroupLoading(true)
      setIsNoGroupVatLoading(true)
      fetchAllList()
    }
    if (error && !loading) {
      Modal.error({
        centered: true,
        title: 'ตรวจสอบ',
        content: `${trace.message}`,
      })
    }
  }, [deleteProductGroup])

  useEffect(() => {
    const { error, data, loading, finished, trace } = deleteProductFromGroup
    if (!error && finished && !loading && data) {
      let newList = lastestProductGroupList.map((group) =>
        group.id === data.id ? data : group
      )
      setLastestProductGroupList(newList)
      if (tempProduct.type === 'normal') {
        let lastestList = [tempProduct, ...lastestNoGroupNormalList]
        lastestList.sort((a, b) => a.code - b.code)
        setLastestNoGroupNormalList(lastestList)
        setIsNoGroupNormalLoading(false)
        setIsProductGroupLoading(false)
        setIsNoGroupVatLoading(false)
      } else {
        let lastestList = [tempProduct, ...lastestNoGroupVatList]
        lastestList.sort((a, b) => a.code - b.code)
        setLastestNoGroupVatList(lastestList)
        setIsNoGroupVatLoading(false)
        setIsProductGroupLoading(false)
        setIsNoGroupNormalLoading(false)
      }
    }
    if (error && !loading) {
      Modal.error({
        centered: true,
        title: 'ตรวจสอบ',
        content: `${trace.message}`,
      })
      setIsNoGroupVatLoading(false)
      setIsProductGroupLoading(false)
      setIsNoGroupNormalLoading(false)
    }
  }, [deleteProductFromGroup])

  const handleGroupSearch = (searchGroup) => {
    const filteredData = lastestProductGroupList.filter(({ name }) => {
      name = name.toString().toLowerCase()
      return name.includes(searchGroup.toLowerCase())
    })
    setFilteredProductGroupList(filteredData)
    setIsProductGroupLoading(false)
  }
  
  const handleGroupMemberSearch = (searchGroupMember) => {
    const filteredData = []
    lastestProductGroupList.forEach((group) => {
      let foundMember = group.members.filter(
        ({ product_name, product_code }) => {
          product_name = product_name.toString().toLowerCase()
          product_code = product_code.toString().toLowerCase()
          return (
            product_name.includes(searchGroupMember.toLowerCase()) ||
            product_code.startsWith(searchGroupMember.toLowerCase())
          )
        }
      )
      if (foundMember.length !== 0) {
        return filteredData.push(group)
      }
    })
    setFilteredProductGroupList(filteredData)
    setIsProductGroupLoading(false)
  }

  const onClearSearchGroup = () => {
    setFilteredProductGroupList(lastestProductGroupList)
    setSearchGroup('')
  }

  const handleNormalSearch = (searchNormal) => {
    const filteredData = lastestNoGroupNormalList.filter(({ code }) => {
      code = code.toString().toLowerCase()
      return code.startsWith(searchNormal.toString().toLowerCase())
    })
    setFilteredNoGroupNormalList(filteredData)
  }

  const handleNormalNameSearch = (searchNormalName) => {
    const filteredData = lastestNoGroupNormalList.filter(({ name }) => {
      name = name.toString().toLowerCase()
      return name.includes(searchNormalName.toString().toLowerCase())
    })
    setFilteredNoGroupNormalList(filteredData)
  }

  const onClearSearchNormal = () => {
    setFilteredNoGroupNormalList(lastestNoGroupNormalList)
    setSearchNormal('')
    setSearchNormalName('')
  }

  const handleVatSearch = (searchVat) => {
    const filteredData = lastestNoGroupVatList.filter(({ code }) => {
      code = code.toString().toLowerCase()
      return code.startsWith(searchVat.toString().toLowerCase())
    })
    setFilteredNoGroupVatList(filteredData)
  }

  const handleVatNameSearch = (searchVatName) => {
    const filteredData = lastestNoGroupVatList.filter(({ name }) => {
      name = name.toString().toLowerCase()
      return name.includes(searchVatName.toString().toLowerCase())
    })
    setFilteredNoGroupVatList(filteredData)
  }

  const onClearSearchVat = () => {
    setFilteredNoGroupVatList(lastestNoGroupVatList)
    setSearchVat('')
    setSearchVatName('')
  }

  const handleAddProductToGroup = (product, type) => {
    setIsProductGroupLoading(true)
    let tmpNormal = filteredNoGroupNormalList
    let tmpVat = filteredNoGroupVatList
    if (activeGroup !== null) {
      if (type === 'normal') {
        setIsNoGroupNormalLoading(true)
        setTempList(tmpNormal)
        setFormAddProductToGroup({
          group_id: activeGroup,
          product_normal_code: product.code,
          product_vat_code: '',
        }).then((form) => {
          dispatch(onAddProductToGroup(form))
        })
      }
      if (type === 'vat') {
        setIsNoGroupVatLoading(true)
        setTempList(tmpVat)
        setFormAddProductToGroup({
          group_id: activeGroup,
          product_normal_code: '',
          product_vat_code: product.code,
        }).then((form) => {
          dispatch(onAddProductToGroup(form))
        })
      }
    } else {
      Modal.warning({
        centered: true,
        title: 'ตรวจสอบ',
        content: 'กรุณาเลือกกลุ่มก่อนเพิ่มสินค้าเข้ากลุ่ม',
      })
      setIsProductGroupLoading(false)
    }
  }

  const handleDeleteProductFromGroup = (member) => {
    setIsProductGroupLoading(true)
    setIsNoGroupNormalLoading(true)
    setIsNoGroupVatLoading(true)
    setTempProduct({
      id: member.product_id,
      code: member.product_code,
      name: member.product_name,
      type: member.product_type,
    })
    dispatch(onDeleteProductFromGroup(member.id))
  }

  const handleAddGroup = (addGroup) => {
    let tmplist = lastestProductGroupList
    if (addGroup.group_name !== '') {
      setIsProductGroupLoading(true)
      setTempList(tmplist)
      dispatch(onSaveProductGroup(addGroup))
    } else {
      Modal.warning({
        title: 'ตรวจสอบ',
        centered: true,
        content: 'กรุณากรอกชื่อกลุ่ม',
      })
    }
  }

  const onTableRowExpand = (expanded, record) => {
    const keys = []
    let group_id = null
    if (expanded) {
      keys.push(record.id) // I have set my record.id as row key. Check the documentation for more details.
      group_id = record.id
    }
    setExpandedRowKeys(keys)
    setActiveGroup(group_id)
  }

  return (
    <div>
      <h1>จับคู่รหัสสินค้า VAT กับรหัสสินค้าปกติ</h1>
      <Modal
        width="500px"
        centered
        visible={isShowAddModal}
        onCancel={() => {
          setIsShowAddModal(false)
          setNewGroup(DEFAULT_NEW_GROUP)
        }}
        onOk={() => {
          handleAddGroup(newGroup)
        }}
        title="เพิ่ม/แก้ไขกลุ่มสินค้า"
      >
        <div className="input-wrapper">
          <div className="input-item">
            <InlineInput
              label="ชื่อ"
              width="400px"
              required
              value={newGroup.group_name}
              onChange={(event) => {
                setNewGroup({ ...newGroup, group_name: event.target.value })
              }}
            />
          </div>
        </div>
      </Modal>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '20px 0px',
          background: '#F6C9CC',
        }}
      >
        <div
          style={{
            display: 'inline-block',
            width: '33%',
            padding: '0px 20px',
          }}
        >
          <h2
            className="mb-2"
            style={{ textAlign: 'center', background: 'white ' }}
          >
            สินค้า VAT
          </h2>
          <div className="mb-2">
            <Search
              value={searchVat}
              onChange={(event) => {
                setSearchVat(event.target.value)
                setSearchVatName('')
              }}
              placeholder="ค้นรหัส"
              onSearch={(value) => {
                handleVatSearch(value)
              }}
              style={{ width: '35%', marginRight: '20px' }}
            />
            <Search
              value={searchVatName}
              onChange={(event) => {
                setSearchVatName(event.target.value)
                setSearchVat('')
              }}
              placeholder="ค้นชื่อ"
              onSearch={(value) => {
                handleVatNameSearch(value)
              }}
              style={{ width: '35%' }}
            />
            <Button
              type="primary"
              icon={<ClearOutlined />}
              onClick={onClearSearchVat}
              style={{ width: '20%', float: 'right', marginRight: 0 }}
            >
              เคลียร์
            </Button>
          </div>
          <Spin spinning={isNoGroupVatLoading}>
            <Table
              rowKey="id"
              columns={NO_GROUP_VAT_COLUMN}
              dataSource={filteredNoGroupVatList}
              size="middle"
              scroll={{ y: '65vh', x: '800' }}
              style={{ height: '65vh' }}
              pagination={{
                simple: true,
                position: ['bottomRight'],
                defaultPageSize: 20,
              }}
            />
          </Spin>
        </div>
        <div
          style={{
            display: 'inline-block',
            width: '33%',
            padding: '0px 20px',
          }}
        >
          <div>
            <h2
              className="mb-2"
              style={{ textAlign: 'center', background: 'white ' }}
            >
              กลุ่มสินค้า
              <Button
                icon={<PlusOutlined />}
                onClick={() => {
                  setIsShowAddModal(true)
                }}
                style={{ float: 'right', marginRight: 10 }}
              />
            </h2>
          </div>
          <div className="mb-2">
            <Search
              value={searchGroup}
              onChange={(event) => {
                setSearchGroup(event.target.value)
                setSearchGroupMember('')
              }}
              placeholder="ค้นชื่อกลุ่ม"
              onSearch={(value) => {
                handleGroupSearch(value)
              }}
              style={{ width: '35%',  marginRight: '20px' }}
            />
            <Search
              value={searchGroupMember}
              onChange={(event) => {
                setSearchGroupMember(event.target.value)
                setSearchGroup('')
              }}
              placeholder="ค้นรหัส/ชื่อ สมาชิก"
              onSearch={(value) => {
                handleGroupMemberSearch(value)
              }}
              style={{ width: '35%' }}
            />
            <Button
              type="primary"
              icon={<ClearOutlined />}
              onClick={onClearSearchGroup}
              style={{ width: '20%', float: 'right', marginRight: 0 }}
            >
              เคลียร์
            </Button>
          </div>
          <Spin spinning={isProductGroupLoading}>
            <Table
              rowKey="id"
              columns={GROUP_COLUMN}
              expandable={{
                expandedRowClassName: '',
                expandRowByClick: true,
                expandedRowKeys: expandedRowKeys,
                onExpand: onTableRowExpand,
                expandedRowRender: (record) => {
                  if (record.members.length === 0) {
                    return (
                      <div>
                        <i>ยังไม่มีสมาชิก</i>
                      </div>
                    )
                  } else {
                    return record.members
                      .sort((a, b) =>
                        a.product_type.localeCompare(b.product_type)
                      )
                      .map((member) => {
                        return (
                          <div
                            key={member.id}
                            className="group-product-content-row"
                          >
                            <div className="product-delete-icon">
                              <Popconfirm
                                title={`ต้องการลบสินค้าออกจากกลุ่มใช่ไหม ?`}
                                okText="ใช่"
                                cancelText="ยกเลิก"
                                onConfirm={(event) => {
                                  event.stopPropagation()
                                  handleDeleteProductFromGroup(member)
                                }}
                                onCancel={(event) => {
                                  event.stopPropagation()
                                }}
                              >
                                <Button
                                  size="small"
                                  type="primary"
                                  icon={<DeleteOutlined />}
                                  // onClick={() =>
                                  //   handleDeleteProductFromGroup(member)
                                  // }
                                />
                              </Popconfirm>
                            </div>
                            <div
                              className={
                                member.product_type === 'vat'
                                  ? 'product-code-vat'
                                  : 'product-code'
                              }
                            >
                              {member.product_code}
                            </div>
                            <div className="product-name">
                              {member.product_name}
                            </div>
                          </div>
                        )
                      })
                  }
                },
              }}
              dataSource={filteredProductGroupList}
              size="middle"
              scroll={{ y: '65vh', x: '800' }}
              style={{ height: '65vh' }}
              pagination={{
                simple: true,
                position: ['bottomRight'],
                defaultPageSize: 20,
              }}
            />
          </Spin>
        </div>
        <div
          style={{
            display: 'inline-block',
            width: '33%',
            padding: '0px 20px',
          }}
        >
          <h2
            className="mb-2"
            style={{ textAlign: 'center', background: 'white ' }}
          >
            สินค้าปกติ
          </h2>
          <div className="mb-2">
            <Search
              value={searchNormal}
              onChange={(event) => {
                setSearchNormal(event.target.value)
                setSearchNormalName('')
              }}
              placeholder="ค้นรหัส"
              onSearch={(value) => {
                handleNormalSearch(value)
              }}
              style={{ width: '35%', marginRight: '20px' }}
            />
            <Search
              value={searchNormalName}
              onChange={(event) => {
                setSearchNormalName(event.target.value)
                setSearchNormal('')
              }}
              placeholder="ค้นชื่อ"
              onSearch={(value) => {
                handleNormalNameSearch(value)
              }}
              style={{ width: '35%' }}
            />
            <Button
              type="primary"
              icon={<ClearOutlined />}
              onClick={onClearSearchNormal}
              style={{ width: '20%', float: 'right', marginRight: 0 }}
            >
              เคลียร์
            </Button>
          </div>
          <Spin spinning={isNoGroupNormalLoading}>
            <Table
              rowKey="id"
              columns={NO_GROUP_NORMAL_COLUMN}
              dataSource={filteredNoGroupNormalList}
              size="middle"
              scroll={{ y: '65vh', x: '800' }}
              style={{ height: '65vh' }}
              pagination={{
                simple: true,
                position: ['bottomRight'],
                defaultPageSize: 20,
              }}
            />
          </Spin>
        </div>
      </div>
    </div>
  )
}

export default ProductGroupMappingPage
