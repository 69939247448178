import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { unAuthorized } from '../../../redux/actions'
import { Layout, Menu } from 'antd'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { DownOutlined } from '@ant-design/icons'
import { renderRoutes } from 'react-router-config'
const { SubMenu } = Menu

const { Header, Content, Footer } = Layout
const LogoText = styled.p`
  line-height: 2;
  text-align: center;
  color: #333;
  font-weight: bold;
`

const CustomLayout = ({ route }) => {
  const dispatch = useDispatch()
  const authenticate = useSelector((reducers) => reducers.authenticate)
  const logOut = () => {
    return dispatch(unAuthorized())
  }
  const renderSubMenu = (title, menu_perm, key, target, link) => {
    if (!authenticate.is_superuser) {
      return (
        authenticate.permissions.includes(menu_perm) && (
          <Menu.Item key={key}>
            <Link target={target} to={link}>
              {title}
            </Link>
          </Menu.Item>
        )
      )
    } else {
      return (
        <Menu.Item key={key}>
          <Link target={target} to={link}>
            {title}
          </Link>
        </Menu.Item>
      )
    }
  }
  return (
    <>
      <Layout>
        <Header
          style={{
            position: 'fixed',
            zIndex: 1,
            width: '100%',
            height: '53px',
            lineHeight: '50px',
            background: '#c1ebff',
          }}
        >
          <Link target="_blank" to="/home">
            <div className="site-logo">
              <LogoText>Bon Lightings</LogoText>
            </div>
          </Link>
          {authenticate.is_login && (
            <>
              <Menu
                // theme="dark"
                mode="horizontal"
                style={{ background: '#c1ebff' }}
              >
                {authenticate.is_superuser |
                  (authenticate.permissions.filter((perm) =>
                    perm.startsWith('customer_bills.BLAD_BLUE')
                  ).length >
                    0) && (
                  <SubMenu title="บิล" popupClassName="sub-menu-pop-up">
                    {renderSubMenu(
                      'เปิด-แก้ไขบิล',
                      'customer_bills.BLAD_BLUE_access_bill',
                      'customer-bill',
                      '_blank',
                      '/no-vat/customer-bill'
                    )}
                    {renderSubMenu(
                      'รายการบิลรายวัน',
                      'customer_bills.BLAD_BLUE_view_dailybilllist',
                      'list-bill-per-day',
                      '_blank',
                      '/no-vat/list-bill-per-day'
                    )}
                    {renderSubMenu(
                      'ตรวจสอบวิธีการชำระเงินรายวัน',
                      'customer_bills.BLAD_BLUE_view_dailybillpayment',
                      'list-bill-payment-method',
                      '_blank',
                      '/no-vat/list-bill-payment-method'
                    )}
                    <Menu.Divider dashed />
                    {renderSubMenu(
                      'แก้ไขรายการราคาเข้าต่ำกว่า 0',
                      'customer_bills.BLAD_BLUE_access_billsitemsadhoc',
                      'edit-price-in',
                      '_blank',
                      '/no-vat/edit-price-in'
                    )}
                    {renderSubMenu(
                      'รายการเปรียบเทียบ ราคาซื้อ/ราคาขาย',
                      'customer_bills.BLAD_BLUE_access_billsitemsadhoc',
                      'compare-price-in-price-out',
                      '_blank',
                      '/no-vat/compare-price-in-price-out'
                    )}
                    <Menu.Divider dashed />
                    {renderSubMenu(
                      'ค้นหาประวัติการขายสินค้า',
                      'customer_bills.BLAD_BLUE_view_salehistory',
                      'no-vat-sales-history',
                      '_blank',
                      '/no-vat/sales-history'
                    )}
                    {renderSubMenu(
                      'ค้นหาประวัติการซื้อสินค้า',
                      'customer_bills.BLAD_BLUE_view_buyhistory',
                      'no-vat-purchase-history',
                      '_blank',
                      '/no-vat/purchase-history'
                    )}
                    <Menu.Divider dashed />
                    {renderSubMenu(
                      'เปิด-แก้ไข ใบเสนอราคา',
                      'customer_bills.BLAD_BLUE_access_quotation',
                      'no-vat-quotation',
                      '_blank',
                      '/no-vat/quotation'
                    )}
                  </SubMenu>
                )}
                {authenticate.is_superuser |
                  (authenticate.permissions.filter((perm) =>
                    perm.startsWith('stocks.BLAD_BLUE')
                  ).length >
                    0) && (
                  <SubMenu title="สต็อก" popupClassName="sub-menu-pop-up">
                    {renderSubMenu(
                      'รับสินค้าเข้าสต็อก',
                      'stocks.BLAD_BLUE_access_stock',
                      'stock',
                      '_blank',
                      '/no-vat/stock'
                    )}
                    {renderSubMenu(
                      'ย้ายสินค้าเข้าสต็อก',
                      'stocks.BLAD_BLUE_access_movestock',
                      'move-stock',
                      '_blank',
                      '/no-vat/move-stock'
                    )}
                    {renderSubMenu(
                      'นำสินค้าเข้าเพื่อเปิดบิล',
                      'stocks.BLAD_BLUE_access_temporarystock',
                      'temporary-stock',
                      '_blank',
                      '/no-vat/temporary-stock'
                    )}
                    {renderSubMenu(
                      'เช็ครายการรับสินค้าเข้าชั่วคราว',
                      'stocks.BLAD_BLUE_access_temporarystocklist',
                      'list-temporary-stock',
                      '_blank',
                      '/no-vat/list-temporary-stock'
                    )}
                    {renderSubMenu(
                      'แก้ไขจำนวนสินค้า',
                      'stocks.BLAD_BLUE_access_alterstock',
                      'alter-stock',
                      '_blank',
                      '/no-vat/alter-stock'
                    )}
                    {renderSubMenu(
                      'รายการสินค้าน้อยกว่ากำหนด',
                      'stocks.BLAD_BLUE_view_stocklowlist',
                      'stock-low',
                      '_blank',
                      '/no-vat/list-stock-low'
                    )}
                    {renderSubMenu(
                      'ข้อมูลเพื่อการสั่งซื้อ',
                      'stocks.BLAD_BLUE_view_stockinfo',
                      'list-product-from-supplier',
                      '_blank',
                      '/no-vat/list-product-from-supplier'
                    )}
                  </SubMenu>
                )}
                {authenticate.is_superuser |
                  (authenticate.permissions.filter((perm) =>
                    perm.startsWith('products.BLAD_BLUE')
                  ).length >
                    0) && (
                  <SubMenu title="สินค้า">
                    {renderSubMenu(
                      'ข้อมูลสินค้า',
                      'products.BLAD_BLUE_access_product',
                      'product-detail',
                      '_blank',
                      '/no-vat/product'
                    )}
                    {renderSubMenu(
                      'รายการสินค้าทั้งหมด',
                      'products.BLAD_BLUE_view_productlist',
                      'product-list',
                      '_blank',
                      '/no-vat/product/list'
                    )}
                    {renderSubMenu(
                      'เปลี่ยนรหัสสินค้า',
                      'products.BLAD_BLUE_access_changeproductcode',
                      'change-product-code',
                      '_blank',
                      '/no-vat/change-product-code'
                    )}
                    {renderSubMenu(
                      'ลบสินค้า',
                      'products.BLAD_BLUE_access_deleteproduct',
                      'product-delete',
                      '_blank',
                      '/no-vat/product/delete'
                    )}
                    <Menu.Divider dashed />
                    {renderSubMenu(
                      'จับกลุ่มสินค้าราคาเดียวกัน',
                      'products.BLAD_BLUE_access_productgroup',
                      'product-pricing-group',
                      '_blank',
                      '/no-vat/product-group-pricing'
                    )}
                    {renderSubMenu(
                      'เปลี่ยนราคากลุ่มสินค้า',
                      'products.BLAD_BLUE_access_changeproductgroupprice',
                      'edit-price-by-product-group',
                      '_blank',
                      '/no-vat/edit-price-by-product-group'
                    )}
                    <Menu.Divider dashed />
                    {renderSubMenu(
                      'แก้ไขราคาสินค้า',
                      'products.BLAD_BLUE_access_editproductprice',
                      'edit-price',
                      '_blank',
                      '/no-vat/edit-price'
                    )}
                    {renderSubMenu(
                      'แก้ไขปริมาณขั้นต่ำสำหรับราคาขายส่ง',
                      'products.BLAD_BLUE_access_editproductminquantity',
                      'edit-wholesale-quantity',
                      '_blank',
                      '/no-vat/edit-wholesale-quantity'
                    )}
                    {renderSubMenu(
                      'เฉลี่ยต้นทุนสินค้า ต้นทาง - ปลายทาง',
                      'products.BLAD_BLUE_access_productcostaverage',
                      'product-transition',
                      '_blank',
                      '/no-vat/product-transition'
                    )}
                    {renderSubMenu(
                      'ค้นหาประวัติการแก้ไขราคาสินค้า',
                      'products.BLAD_BLUE_view_editpricehistory',
                      'edit-price-history',
                      '_blank',
                      '/no-vat/edit-price-history'
                    )}
                  </SubMenu>
                )}
                {authenticate.is_superuser |
                  (authenticate.permissions.filter((perm) =>
                    perm.startsWith('suppliers.BLAD_BLUE')
                  ).length >
                    0) |
                  (authenticate.permissions.filter((perm) =>
                    perm.startsWith('customers.BLAD_BLUE')
                  ).length >
                    0) && (
                  <SubMenu title="ข้อมูลผู้ขาย/ลูกค้า">
                    {renderSubMenu(
                      'ลูกค้า',
                      'customers.BLAD_BLUE_access_customer',
                      'customer',
                      '_blank',
                      '/no-vat/customer'
                    )}
                    {renderSubMenu(
                      'ผู้ขาย',
                      'suppliers.BLAD_BLUE_access_supplier',
                      'supplier',
                      '_blank',
                      '/no-vat/supplier'
                    )}
                  </SubMenu>
                )}
                {authenticate.is_superuser |
                  (authenticate.permissions.filter((perm) =>
                    perm.startsWith('supplier_invoice.BLAD_BLUE')
                  ).length >
                    0) && (
                  <SubMenu title="การจ่ายเงิน">
                    {renderSubMenu(
                      'รวมยอดบิลซื้อสินค้า',
                      'supplier_invoice.BLAD_BLUE_access_si',
                      'supplier-invoice',
                      '_blank',
                      '/no-vat/supplier-invoice'
                    )}
                    {renderSubMenu(
                      'บันทึกการจ่ายบัญชีผู้ขาย',
                      'supplier_invoice.BLAD_BLUE_access_sipayment',
                      'payment-supplier-invoice',
                      '_blank',
                      '/no-vat/payment-supplier-invoice'
                    )}
                    {renderSubMenu(
                      'รายการบัญชีผู้ขายที่ยังไม่ได้ชำระ',
                      'supplier_invoice.BLAD_BLUE_view_siunpaid',
                      'unpaid-payment-supplier-invoice',
                      '_blank',
                      '/no-vat/unpaid-payment-supplier-invoice'
                    )}
                    <Menu.Divider dashed />
                    {renderSubMenu(
                      'บันทึกค่าใช้จ่าย',
                      'supplier_invoice.BLAD_BLUE_access_expenses',
                      'expenses',
                      '_blank',
                      '/no-vat/expenses'
                    )}
                  </SubMenu>
                )}
                {authenticate.is_superuser |
                  (authenticate.permissions.filter((perm) =>
                    perm.startsWith('cash_check.BLAD_BLUE')
                  ).length >
                    0) && (
                  <SubMenu title="ร้านค้า">
                    {renderSubMenu(
                      'เปิดร้าน (Open)',
                      'cash_check.BLAD_BLUE_access_openstore',
                      'open-store',
                      '_blank',
                      '/no-vat/open-store'
                    )}
                    {renderSubMenu(
                      'ปิดร้าน (Close)',
                      'cash_check.BLAD_BLUE_access_closestore',
                      'close-store',
                      '_blank',
                      '/no-vat/close-store'
                    )}
                    <Menu.Divider dashed />
                    {renderSubMenu(
                      'สรุปรายงานเปิด-ปิดร้าน',
                      'cash_check.BLAD_BLUE_view_storereport',
                      'summary-report-store',
                      '_blank',
                      '/no-vat/summary-report-store'
                    )}
                  </SubMenu>
                )}
                <SubMenu
                  style={{ float: 'right' }}
                  key="11"
                  icon={<DownOutlined />}
                  title={authenticate.username || 'unknown user'}
                >
                  <Menu.ItemGroup title="User control">
                    <Menu.Item key="setting:1" onClick={logOut}>
                      Log out
                    </Menu.Item>
                  </Menu.ItemGroup>
                </SubMenu>
              </Menu>
            </>
          )}
        </Header>
        <Content className="site-layout">{renderRoutes(route.routes)}</Content>
        <Footer style={{ textAlign: 'center' }}>Bon Lightings ©2024</Footer>
      </Layout>
    </>
  )
}

export default CustomLayout
