import React from 'react'
import styled from 'styled-components'
import { Input, Select, DatePicker } from 'antd'

const { Option } = Select
const { Search } = Input

const INPUT_OPTIONS = {
  SEARCH_INPUT: 'search',
  INPUT: 'input',
  SELECT_INPUT: 'select_input',
  TEXT_AREA_INPUT: 'text_area_input',
  DATE_PICKER: 'date_picker',
  PASSWORD: 'password',
}

const InputGroup = styled.div`
  display: flex;
  align-items: center;
`

const InputLabel = styled.span`
  margin-right: 10px;
  display: inline-block;
  width: ${(props) => (props.minWidth ? props.minWidth : 'unset')};
`
const InputRequiredLabel = styled.span`
  margin-right: 10px;
  display: inline-block;
  color: red;
  width: ${(props) => (props.minWidth ? props.minWidth : 'unset')};
`

const InputUnit = styled.span`
  margin-left: 10px;
  margin-right: 5px;
  display: inline-block;
  width: ${(props) => (props.minWidth ? props.minWidth : 'unset')};
`

const InputDOM = styled.div`
  position: relative;
  width: ${(props) => (props.width ? props.width : 'unset')};
  .search-input {
    .ant-btn {
      margin-right: 0 !important;
    }
  }
  .error-input {
    border: 1px solid red;
    input {
      border: 1px solid red;
    }
  }
`

const RequiredText = styled.span`
  color: red;
`

const InlineInput = ({
  onSearch,
  onChange,
  isLoading,
  inputOption,
  label,
  placeholder,
  width,
  value,
  disabled,
  options,
  required,
  error,
  labelWidth,
  showToday,
  dateDefaultValue,
  selectDefaultValue,
  unit,
  unitWidth,
  disableColon,
  className,
  pickerType,
  visibilityToggle,
  autoComplete
}) => {
  const getInput = () => {
    switch (inputOption) {
      case INPUT_OPTIONS.SEARCH_INPUT:
        return (
          <Search
            placeholder={placeholder}
            onSearch={onSearch}
            enterButton
            loading={isLoading}
            value={value}
            onChange={onChange}
            disabled={disabled}
            className={`${
              error ? 'error-input' : ''
            } search-input ${className}`}
          />
        )
      case INPUT_OPTIONS.SELECT_INPUT:
        return (
          <Select
            className={className}
            placeholder={placeholder}
            showSearch
            defaultValue={selectDefaultValue}
            style={{ width }}
            onChange={onChange}
            disabled={disabled}
            value={value}
            filterOption={(input, option) =>
              option.children
                .toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {options.map((item, index) => {
              return (
                <Option key={index} value={item.id}>
                  {item.name}
                </Option>
              )
            })}
          </Select>
        )
      case INPUT_OPTIONS.TEXT_AREA_INPUT:
        return (
          <Input.TextArea
            className={className}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            disabled={disabled}
          />
        )
      case INPUT_OPTIONS.DATE_PICKER:
        return (
          <DatePicker
            className={className}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            disabled={disabled}
            showToday={showToday}
            defaultValue={dateDefaultValue}
            picker={pickerType}
            style={{ width: '100%' }}
          />
        )

      case INPUT_OPTIONS.PASSWORD:
        return (
          <Input.Password
            className={className}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            disabled={disabled}
            visibilityToggle={visibilityToggle}
            autoComplete={autoComplete}
          />
        )
      case INPUT_OPTIONS.INPUT:
      default:
        return (
          <Input
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            disabled={disabled}
            className={`${error ? 'error-input' : ''} ${className}`}
          />
        )
    }
  }

  return (
    <InputGroup>
      {required ? (
        <InputRequiredLabel className="required-label" minWidth={labelWidth}>
          {label}
          <RequiredText>*</RequiredText>
          {disableColon ? '' : <span> :</span>}
        </InputRequiredLabel>
      ) : (
        <InputLabel className="input-label" minWidth={labelWidth}>
          {label}
          {disableColon ? '' : <span> :</span>}
        </InputLabel>
      )}
      <InputDOM width={width}>{getInput()}</InputDOM>
      {unit && (
        <InputUnit className="input-label" minWidth={unitWidth}>
          {unit}
        </InputUnit>
      )}
    </InputGroup>
  )
}

InlineInput.defaultProps = {
  onSearch: () => {},
  onChange: () => {},
  isLoading: false,
  disabled: false,
  required: false,
  label: '',
  placeholder: '',
  width: '',
  value: '',
  inputOption: 'input', // 'input', 'search', 'select_input'
  options: [],
  error: null,
  labelWidth: null,
  showToday: false,
  dateDefaultValue: () => {},
  unit: '',
  unitWidth: null,
}

export default InlineInput
