import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Button, Spin, Modal, Popconfirm } from 'antd'
import { DeleteOutlined, ClearOutlined } from '@ant-design/icons'

import InlineInput from 'components/InlineInput'
import SearchProductVatPopUp from 'components/SearchProductVatPopUp'
import { handleAlert } from 'utils'

import { onDeleteProductVat, onGetProductVatByCode } from 'redux/actions'
import { useDispatch, useSelector } from 'react-redux'
const DEFAULT_PRODUCT = {
  id: null,
  code: '',
  vat_name: '',
  stock1: '',
}

const DeleteProductVatPage = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [product, setProduct] = useState(DEFAULT_PRODUCT)
  const [errors, setErrors] = useState({})
  const [isShowProductPopUp, setIsShowProductPopUp] = useState(false)
  const [isShowDeletePopUp, setIsShowDeletePopUp] = useState(false)
  const dispatch = useDispatch()
  const getProductVatByCode = useSelector((state) => state.getProductVatByCode)
  const deleteProductVat = useSelector((state) => state.deleteProductVat)

  useEffect(() => {
    document.title = 'ลบสินค้า [VAT]'
  }, [])

  useEffect(() => {
    const { loading, finished, error, data, trace } = getProductVatByCode
    if (!loading && !error && finished) {
      setIsLoading(loading)
      setProduct({
        id: data.id,
        code: data.code,
        vat_name: data.vat_name,
        stock1: data.stock1,
      })
    }
    if (!loading && error) {
      setIsLoading(loading)
      handleAlert('custom', trace.message || '')
      setProduct(DEFAULT_PRODUCT)
    }
  }, [getProductVatByCode])

  useEffect(() => {
    const { loading, finished, error, trace } = deleteProductVat
    if (!loading && !error && finished) {
      setIsLoading(loading)
      setProduct(DEFAULT_PRODUCT)
      handleAlert('success')
    }
    if (!loading && error) {
      setIsLoading(loading)
      setProduct(DEFAULT_PRODUCT)
      handleAlert('custom', trace.message || '')
    }
  }, [deleteProductVat])

  const onSearchCode = (value) => {
    if (value !== '') {
      dispatch(onGetProductVatByCode(value))
    } else {
      setIsShowProductPopUp(true)
    }
  }

  const onCancelProdutPopUp = () => {
    setIsShowProductPopUp(false)
  }

  const onSelectProduct = (data) => {
    onSearchCode(data.code)
    setIsShowProductPopUp(false)
  }

  const showPopUpBeforeDelete = () => {
    if (product.id !== null) {
      setIsShowDeletePopUp(true)
    } else {
      handleAlert('custom', 'กรุณาเลือกสินค้าก่อนทำการลบ')
    }
  }

  const _deleteProduct = () => {
    if (Number(product.stock1) !== 0) {
      handleAlert('custom', 'ไม่สามารถลบได้ สต็อกต้องเป็น 0')
      setErrors({
        ...errors,
        stock1: 'สต็อกต้องเป็น 0',
      })
      setIsShowDeletePopUp(false)
    } else {
      setErrors({})
      dispatch(onDeleteProductVat(product.id))
      setIsShowDeletePopUp(false)
    }
  }

  const onClearInput = () => {
    setProduct(DEFAULT_PRODUCT)
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'inline-block' }}>
          <h1>ลบสินค้า [VAT]</h1>
        </div>
        <div style={{ display: 'inline-block' }}>
          <Popconfirm
            title="คุณต้องการล้างหน้าจอใช่หรือไม่ ?"
            okText="ใช่"
            cancelText="ยกเลิก"
            onConfirm={onClearInput}
          >
            <Button
              type="default"
              danger
              icon={<ClearOutlined />}
              size="large"
              block
            >
              ล้างหน้าจอ
            </Button>
          </Popconfirm>
        </div>
      </div>
      <Modal
        title="ลบสินค้า"
        visible={isShowDeletePopUp}
        onOk={() => _deleteProduct()}
        onCancel={() => setIsShowDeletePopUp(false)}
      >
        <strong style={{ color: 'red' }}>** ไม่ควรลบสินค้า Vat *** </strong>
        การลบสินค้า จะส่งผลให้สินค้าชิ้นนี้หายไปจากระบบทันที
        และจะไม่เหลือข้อมูลใดๆ ทิ้งไว้ในระบบอีกต่อไป คุณแน่ใจที่จะ "ลบ"
        สินค้าชิ้นนี้ใช่หรือไม่ ? 
      </Modal>
      <SearchProductVatPopUp
        visible={isShowProductPopUp}
        onCancel={onCancelProdutPopUp}
        onClick={onSelectProduct}
      />
      <Spin spinning={isLoading}>
        <div className="form-wrapper-vat">
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                onSearch={(value) => {
                  onSearchCode(value)
                }}
                inputOption="search"
                label="รหัสสินค้า"
                isLoading={isLoading}
                value={product.code}
                onChange={(event) => {
                  setProduct({
                    ...DEFAULT_PRODUCT,
                    code: event.target.value.toUpperCase(),
                  })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ชื่อสินค้า VAT"
                width="300px"
                value={product.vat_name}
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput label="สต๊อก" width="100px" value={product.stock1} />
            </div>
          </div>
          <div className="input-wrapper" style={{ marginTop: '20px' }}>
            <div
              className="input-item"
              style={{ marginLeft: 'auto', marginRight: 'auto' }}
            >
              <Button
                type="primary"
                danger
                icon={<DeleteOutlined />}
                size="large"
                block
                onClick={() => showPopUpBeforeDelete()}
              >
                ลบสินค้า
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  )
}

export default withRouter(DeleteProductVatPage)
