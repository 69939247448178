import { combineReducers } from 'redux';
import authenticateReducer from './authenticate'
import createReducer from './createReducer';
const rootReducer = combineReducers({
  authenticate: authenticateReducer,
  paymentDurationList: createReducer('paymentDurationList'),
  unitList: createReducer('unitList'),
  searchSupplierCode: createReducer('searchSupplierCode'),
  supplierBill: createReducer('supplierBill'),
  saveStock: createReducer('saveStock'),
  deleteSupplierBill: createReducer('deleteSupplierBill'),
  moveStock: createReducer('moveStock'),
  deleteLatestBoughtSupplier: createReducer('deleteLatestBoughtSupplier'),
  searchProductCode: createReducer('searchProductCode'),
  saveLatestBoughtSupplier: createReducer('saveLatestBoughtSupplier'),
  listLatestBoughtSupplierByProductId: createReducer('listLatestBoughtSupplierByProductId'),
  listMoveStock: createReducer('listMoveStock'),
  saveSupplier: createReducer('saveSupplier'),
  listSupplier: createReducer('listSupplier'),
  deleteSupplierContact: createReducer('deleteSupplierContact'),
  getReasonList: createReducer('getReasonList'),
  listTemporaryStock: createReducer('listTemporaryStock'),
  saveTemporaryStock: createReducer('saveTemporaryStock'),
  returnTemporaryStock: createReducer('returnTemporaryStock'),
  saveProductVat: createReducer('saveProductVat'),
  getProductVatByCode: createReducer('getProductVatByCode'),
  deleteProductVat: createReducer('deleteProductVat'),
  listProductVat: createReducer('listProductVat'),
  saveProduct: createReducer('saveProduct'),
  getProductByCode: createReducer('getProductByCode'),
  listProduct: createReducer('listProduct'),
  listProductWithWholesale: createReducer('listProductWithWholesale'),
  deleteProduct: createReducer('deleteProduct'),
  getUnitList: createReducer('getUnitList'),
  getVatTypeList: createReducer('getVatTypeList'),
  getMovementTypeList: createReducer('getMovementTypeList'),
  getPhysicalStockZoneList: createReducer('getPhysicalStockZoneList'),
  getPhysicalStockShelfList: createReducer('getPhysicalStockShelfList'),
  listBillType: createReducer('listBillType'),
  listSaleChannel: createReducer('listSaleChannel'),
  getCustomerBill: createReducer('getCustomerBill'),
  getLatestBoughtProductOfCustomer: createReducer('getLatestBoughtProductOfCustomer'),
  saveCustomerBill: createReducer('saveCustomerBill'),
  listBillRemark: createReducer('listBillRemark'),
  listCustomerBillByCustomerCode: createReducer('listCustomerBillByCustomerCode'),
  listCustomer: createReducer('listCustomer'),
  getCustomerByCode: createReducer('getCustomerByCode'),
  saveCustomer: createReducer('saveCustomer'),
  getCustomerType: createReducer('getCustomerType'),
  deleteCustomerContact: createReducer('deleteCustomerContact'),
  getLastCheckStock: createReducer('getLastCheckStock'),
  listAlterStock: createReducer('listAlterStock'),
  saveAlterStock: createReducer('saveAlterStock'),
  saveLastCheckStockDate: createReducer('saveLastCheckStockDate'),
  // Stock Vat
  listStockVatType: createReducer('listStockVatType'),
  listSupplierBillVatByBillNo: createReducer('listSupplierBillVatByBillNo'),
  listSupplierBillVatBySupplierCode: createReducer('listSupplierBillVatBySupplierCode'),
  productVatWithLatestBoughtSupplier: createReducer('productVatWithLatestBoughtSupplier'),
  saveStockVat: createReducer('saveStockVat'),
  deleteStockVat: createReducer('deleteStockVat'),
  getSupplierBillVatByBillNo: createReducer('getSupplierBillVatByBillNo'),
  // Customer Bill Vat
  listBillVatType: createReducer('listBillVatType'),
  getCustomerBillVat: createReducer('getCustomerBillVat'),
  listCustomerBillVat: createReducer('listCustomerBillVat'),
  productVatWithLatestBoughtCustomer: createReducer('productVatWithLatestBoughtCustomer'),
  saveCustomerBillVat: createReducer('saveCustomerBillVat'),
  // Customer Vat
  getCustomerVatByCode : createReducer('getCustomerVatByCode'),
  saveCustomerVat : createReducer('saveCustomerVat'),
  listCustomerVat : createReducer('listCustomerVat'),
  deleteCustomerVatContact : createReducer('deleteCustomerVatContact'),
  // Supplier Vat
  getSupplierVatByCode : createReducer('getSupplierVatByCode'),
  saveSupplierVat : createReducer('saveSupplierVat'),
  listSupplierVat : createReducer('listSupplierVat'),
  deleteSupplierVatContact : createReducer('deleteSupplierVatContact'),
  // Product Group
  getProductGroup : createReducer('getProductGroup'),
  listProductGroup : createReducer('listProductGroup'),
  listNoGroupProductNormal : createReducer('listNoGroupProductNormal'),
  listNoGroupProductVat : createReducer('listNoGroupProductVat'),
  saveProductGroup : createReducer('saveProductGroup'),
  deleteProductGroup : createReducer('deleteProductGroup'),
  addProductToGroup : createReducer('addProductToGroup'),
  deleteProductFromGroup : createReducer('deleteProductFromGroup'),
  // List Bill Per Day
  listBillPerDay : createReducer('listBillPerDay'),
  // Pop Up
  listProductVatByName : createReducer('listProductVatByName'),
  listProductNoVatByName : createReducer('listProductNoVatByName'),
  listSupplierVatByName : createReducer('listSupplierVatByName'),
  listCustomerVatByName : createReducer('listCustomerVatByName'),
  listCustomerBillVatByCustomerName : createReducer('listCustomerBillVatByCustomerName'),
  listSupplierBillVatBySupplierName : createReducer('listSupplierBillVatBySupplierName'),
  listCustomerByName : createReducer('listCustomerByName'),
  listCustomerBillByCustomerName : createReducer('listCustomerBillByCustomerName'),
  listSupplierByName : createReducer('listSupplierByName'),
  listSupplierBillBySupplierName : createReducer('listSupplierBillBySupplierName'),
  // History
  listBillItemBySupplierAndProduct : createReducer('listBillItemBySupplierAndProduct'),
  listBillItemByCustomerAndProduct : createReducer('listBillItemByCustomerAndProduct'),
  listBillItemBySupplierAndProductNoVat : createReducer('listBillItemBySupplierAndProductNoVat'),
  listBillItemByCustomerAndProductNoVat : createReducer('listBillItemByCustomerAndProductNoVat'),
  // Stock
  listSupplierBillBySupplierCode : createReducer('listSupplierBillBySupplierCode'),
  // Credit Note
  listNoteReason : createReducer('listNoteReason'),
  saveCustomerCreditNote : createReducer('saveCustomerCreditNote'),
  getCustomerCreditNote : createReducer('getCustomerCreditNote'),
  listNoteByCustomerName : createReducer('listNoteByCustomerName'),
  saveSupplierCreditNote : createReducer('saveSupplierCreditNote'),
  getSupplierCreditNote : createReducer('getSupplierCreditNote'),
  listNoteBySupplierName : createReducer('listNoteBySupplierName'),
  cancelCustomerNote : createReducer('cancelCustomerNote'),
  cancelSupplierNote : createReducer('cancelSupplierNote'),
  // Quotation
  listQOBillType : createReducer('listQOBillType'),
  listQODurationUnit : createReducer('listQODurationUnit'),
  listQODocType : createReducer('listQODocType'),
  listQOByCustomerName : createReducer('listQOByCustomerName'),
  getQuotation : createReducer('getQuotation'),
  saveQuotation : createReducer('saveQuotation'),
  // Derivery Order
  listDeliveryOrderBillByCustomerName : createReducer('listDeliveryOrderBillByCustomerName'),
  getDeliveryOrder : createReducer('getDeliveryOrder'),
  saveDeliveryOrder : createReducer('saveDeliveryOrder'),
  // Deposit Receipt
  listDepositReceiptBillByCustomerName : createReducer('listDepositReceiptBillByCustomerName'),
  getDepositReceipt : createReducer('getDepositReceipt'),
  saveDepositReceipt : createReducer('saveDepositReceipt'),
  // Supplier Invoice
  listSupplierBillBySupplierAndDate : createReducer('listSupplierBillBySupplierAndDate'),
  saveSupplierInvoice : createReducer('saveSupplierInvoice'),
  listSupplierInvoiceBySupplierName : createReducer('listSupplierInvoiceBySupplierName'),
  cancelSupplierInvoice : createReducer('cancelSupplierInvoice'),
  getSupplierInvoiceBySupplier : createReducer('getSupplierInvoiceBySupplier'),
  getPaymentDetailByBill : createReducer('getPaymentDetailByBill'),
  saveSupplierPaymentDetail : createReducer('saveSupplierPaymentDetail'),
  cancelPayment : createReducer('cancelPayment'),
  listSupplierBillByPaymentStatus : createReducer('listSupplierBillByPaymentStatus'),
  printSupplierInvoice : createReducer('printSupplierInvoice'),
  listUnpaidSupplierPayment : createReducer('listUnpaidSupplierPayment'),
  // Supplier VAT Invoice
  listSupplierVatBillBySupplierAndDate : createReducer('listSupplierVatBillBySupplierAndDate'),
  saveSupplierVatInvoice : createReducer('saveSupplierVatInvoice'),
  listSupplierVatInvoiceBySupplierName : createReducer('listSupplierVatInvoiceBySupplierName'),
  cancelSupplierVatInvoice : createReducer('cancelSupplierVatInvoice'),
  getSupplierVatInvoiceBySupplier : createReducer('getSupplierVatInvoiceBySupplier'),
  getPaymentDetailVatByBill : createReducer('getPaymentDetailVatByBill'),
  saveSupplierVatPaymentDetail : createReducer('saveSupplierVatPaymentDetail'),
  cancelPaymentVat : createReducer('cancelPaymentVat'),
  listSupplierVatBillByPaymentStatus : createReducer('listSupplierVatBillByPaymentStatus'),
  printSupplierVatInvoice : createReducer('printSupplierVatInvoice'),
  listUnpaidSupplierVatPayment : createReducer('listUnpaidSupplierVatPayment'),
  // Customer VAT Invoice
  listCustomerVatBillByCustomerAndDate : createReducer('listCustomerVatBillByCustomerAndDate'),
  saveCustomerVatInvoice : createReducer('saveCustomerVatInvoice'),
  listCustomerVatInvoiceByCustomerName : createReducer('listCustomerVatInvoiceByCustomerName'),
  cancelCustomerVatInvoice : createReducer('cancelCustomerVatInvoice'),
  getCustomerVatInvoiceByCustomer : createReducer('getCustomerVatInvoiceByCustomer'),
  getCustomerPaymentDetailVatByBill : createReducer('getCustomerPaymentDetailVatByBill'),
  saveCustomerVatPaymentDetail : createReducer('saveCustomerVatPaymentDetail'),
  cancelCustomerPaymentVat : createReducer('cancelCustomerPaymentVat'),
  listCustomerVatBillByPaymentStatus : createReducer('listCustomerVatBillByPaymentStatus'),
  printCustomerVatInvoice : createReducer('printCustomerVatInvoice'),
  listCustomerVatBillByPaymentStatusAndMonth : createReducer('listCustomerVatBillByPaymentStatusAndMonth'),
  listUnpaidCustomerVatPayment : createReducer('listUnpaidCustomerVatPayment'),
  // Stock Low
  listProductWithLowStock : createReducer('listProductWithLowStock'),
  listLastestBoughtProductFromSupplier : createReducer('listLastestBoughtProductFromSupplier'),
  // Pricing Group
  listProductPriceGroup : createReducer('listProductPriceGroup'),
  listProductPriceGroupWithoutMember : createReducer('listProductPriceGroupWithoutMember'),
  listProductNormalWithNoPriceGroup : createReducer('listProductNormalWithNoPriceGroup'),
  saveProductPriceGroup : createReducer('saveProductPriceGroup'),
  deleteProductPriceGroup : createReducer('deleteProductPriceGroup'),
  addProductPriceGroupMember : createReducer('addProductPriceGroupMember'),
  deleteMemberFromProductPriceGroup : createReducer('deleteMemberFromProductPriceGroup'),
  editPriceByProductPriceGroup : createReducer('editPriceByProductPriceGroup'),
  //Product Price history
  listProductPriceHistory : createReducer('listProductPriceHistory'),
  saveProductTransition : createReducer('saveProductTransition'),
  listProductTransitionHistory : createReducer('listProductTransitionHistory'),
  // Expense
  listExpenseType : createReducer('listExpenseType'),
  listExpenseHistory : createReducer('listExpenseHistory'),
  saveExpense : createReducer('saveExpense'),
  deleteExpense : createReducer('deleteExpense'),
  // bill payment method
  listBillPaymentMethod : createReducer('listBillPaymentMethod'),
  listBillVatPaymentMethod : createReducer('listBillVatPaymentMethod'),
});
export default rootReducer;