import { useEffect } from 'react'
import { listBillItemAdhoc, UpdatePriceIn } from 'api/customerBill'
import useAPI from 'hooks/useAPI'
import { handleAlert } from 'utils'

const useRequestEditPriceInData = ({ onResetForm }) => {
  const {
    makeRequest: listBillItemAdhocRequest,
    data: listBillItemAdhocData,
    loading: listBillItemAdhocLoading,
  } = useAPI()

  const {
    makeRequest: UpdatePriceInRequest,
    data: UpdatePriceInData,
    loading: UpdatePriceInLoading,
  } = useAPI()

  useEffect(() => {
    listBillItemAdhocRequest(() =>
      listBillItemAdhoc({
        query: 'price_in_lt_0',
      })
    ) // eslint-disable-next-line
  }, [])

  const onListItemAdhoc = () => {
    listBillItemAdhocRequest(() =>
      listBillItemAdhoc({
        query: 'price_in_lt_0',
      })
    )
  }

  // NOTE: async await style
  const onUpdatePriceIn = async (body) => {
    const res = await UpdatePriceInRequest(() => UpdatePriceIn(body))
    if (res?.status === 200) {
      handleAlert('success')
      onResetForm()
      listBillItemAdhocRequest(() =>
        listBillItemAdhoc({
          query: 'price_in_lt_0',
        })
      )
    }
  }

  return {
    listBillItemAdhocData,
    listBillItemAdhocLoading,
    UpdatePriceInData,
    UpdatePriceInLoading,
    onUpdatePriceIn,
    onListItemAdhoc,
  }
}

export default useRequestEditPriceInData
