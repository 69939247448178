import { listCustomerBillVatByCustomerNamePopUp } from "api/customerBillVat"; 
import useAPI from "hooks/useAPI";
import { useCallback, useEffect, useState } from 'react'

const useListCustomerBillVatPopUp = ({ currentPageNumber, pageSize }) => {
    const [searchQuery, setSearchQuery] = useState({})
    const {
        makeRequest: listCustomerBillVatPopUp,
        data: listCustomerBillVatPopUpData,
        loading: isLoadingListCustomerBIllVatPopUp,
        pagination: listCustomerBillVatPopUpPagination,
    } = useAPI()

    const onSearch = useCallback(({ searchName, searchTotal, billType }) => {
        setSearchQuery({
            name: searchName,
            bill_amount: searchTotal,
            bill_type: billType,
        })
    }, [])

    useEffect(() => {
        listCustomerBillVatPopUp(() =>
        listCustomerBillVatByCustomerNamePopUp({
            page: currentPageNumber,
            pageSize,
            ...searchQuery
        })
        )
    }, [listCustomerBillVatPopUp, searchQuery, currentPageNumber, pageSize])

    return {
        listCustomerBillVatPopUpData,
        isLoadingListCustomerBIllVatPopUp,
        listCustomerBillVatPopUpPagination,
        onSearch,
    }
}

export default useListCustomerBillVatPopUp