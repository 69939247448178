import React from 'react'
import { Button } from 'antd'
import moment from 'moment'
import useListPriceInPriceOutDataContext from 'pages/ListPriceInPriceOutComparisonPage/hooks/useListPriceInPriceOutDataContext'
import {
  ReloadOutlined,
  LeftCircleOutlined,
  FallOutlined,
} from '@ant-design/icons'
import InlineInput from 'components/InlineInput'
import './style.css'

const LossMonthlySearch = () => {
  const { month, setMonth, onClickRefreshLoss } =
    useListPriceInPriceOutDataContext()
  return (
    <div className="input-wrapper" style={{ marginBottom: '15px' }}>
      <div className="input-item loss-title">
        <FallOutlined style={{ color: 'red' }} /> ขาดทุน
      </div>
      <div className="input-item">
        <InlineInput
          label="เดือนที่ค้นหา"
          required
          defaultValue={moment()}
          value={month && moment(month)}
          inputOption="date_picker"
          onChange={(_, dateString) => {
            setMonth(dateString)
          }}
          pickerType="month"
        />
      </div>
      <div className="input-item">
        <Button
          type="primary"
          icon={<ReloadOutlined />}
          size="medium"
          onClick={onClickRefreshLoss}
          style={{ width: '120px' }}
        >
          Refresh
        </Button>
      </div>
      <div className="input-item">
        <Button
          disabled
          className="icon-less-than-fifty"
          shape="circle"
          icon={<LeftCircleOutlined />}
        />
        น้อยกว่า -50%
        <Button
          disabled
          className="icon-less-than-twenty-five"
          shape="circle"
          icon={<LeftCircleOutlined />}
        />
        -49 ถึง -25%
        <Button
          disabled
          className="icon-less-than-ten"
          shape="circle"
          icon={<LeftCircleOutlined />}
        />
        -24 ถึง -10%
      </div>
    </div>
  )
}

export default LossMonthlySearch
