import { request } from 'api/request'

export const tokenToString = () => {
  const token = localStorage.getItem('bearerToken')
  return token
}

const requestInterceptor = (config) => {
  const configure = {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${tokenToString()}`,
    },
  }

  return configure
}

export const addAuthRequestInterceptor = () => {
  request.interceptors.request.use(requestInterceptor)
}
