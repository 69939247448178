/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Spin } from 'antd'
import { withRouter } from 'react-router-dom'

import SearchSupplierPopUp from 'components/SearchSupplierPopUp'
import ListProductFromSupplierTable from 'components/ListProductFromSupplierTable'
import InlineInput  from 'components/InlineInput'
import { handleAlert } from 'utils'

import {
  onListLastestBoughtProductFromSupplier,
  fetchSearchSupplierCode,
} from 'redux/actions'
import { useSelector, useDispatch } from 'react-redux'

const DEFAULT_FORM = {
  supplier_id: null,
  supplier_code: '',
  supplier_name: '',
  list: [],
}

const ListProductFromSupplierPage = (props) => {
  const dispatch = useDispatch()
  const [form, setForm] = useState(DEFAULT_FORM)
  const [isLoading, setIsLoading] = useState(false)
  const [filteredList, setFilteredList] = useState([])
  const [isShowSupplierPopUp, setIsShowSupplierPopUp] = useState(false)
  const listLastestBoughtProductFromSupplier = useSelector(
    (state) => state.listLastestBoughtProductFromSupplier
  )
  const searchSupplierCode = useSelector((state) => state.searchSupplierCode)

  useEffect(() => {
    document.title = 'ข้อมูลเพื่อการสั่งซื้อ - NoVat'
  }, [])

  useEffect(() => {
    const { trace, loading, error, finished, data } = searchSupplierCode
    setIsLoading(loading)
    if (error) {
      handleAlert('custom', trace.message || '')
      setForm({
        ...form,
        supplier_id: null,
        supplier_name: '',
      })
    }
    if (!error && data && finished) {
      setForm({
        supplier_id: data.id,
        supplier_code: data.code,
        supplier_name: data.name,
        list: [],
      })
      setFilteredList([])
      dispatch(onListLastestBoughtProductFromSupplier(data.id))
    }
  }, [searchSupplierCode])

  useEffect(() => {
    const { loading, error, data, finished, trace } = listLastestBoughtProductFromSupplier
    setIsLoading(loading)
    if (!error && !loading && finished) {
      setForm({
        ...form,
        list: data
      })
      setFilteredList(data)
    }
    if (!loading && error) {
      handleAlert('error', trace.message || '')
    }
  }, [listLastestBoughtProductFromSupplier])

  const onSearchSupplierCode = (code) => {
    if (code !== '') {
      dispatch(fetchSearchSupplierCode(code))
    } else {
      setIsShowSupplierPopUp(true)
    }
  }

  const onSelectSupplierPopup = (data) => {
    dispatch(fetchSearchSupplierCode(data.code))
    setIsShowSupplierPopUp(false)
  }

  const onCancelSupplierPopUp = () => {
    setIsShowSupplierPopUp(false)
  }

  return (
    <div>
      <h1>ข้อมูลเพื่อการสั่งซื้อ</h1>
      <SearchSupplierPopUp
        visible={isShowSupplierPopUp}
        onCancel={onCancelSupplierPopUp}
        onClick={onSelectSupplierPopup}
      />
      <div className="form-wrapper">
        <div className="input-wrapper">
          <div className="input-item">
            <InlineInput
              onSearch={(value) => {
                onSearchSupplierCode(value)
              }}
              inputOption="search"
              label="รหัสผู้ขาย"
              placeholder="รหัสผู้ขาย"
              isLoading={false}
              required={true}
              value={form.supplier_code}
              onChange={(event) => {
                setForm({
                  ...form,
                  supplier_code: event.target.value.toUpperCase(),
                  supplier_id: null,
                  supplier_name: '',
                  ilst: [],
                })
                setFilteredList([])
              }}
            />
          </div>
          <div className="input-item">
            <InlineInput
              label="ชื่อผู้ขาย"
              width="300px"
              value={form.supplier_name}
              disabled={true}
            />
          </div>
        </div>
        <Spin spinning={isLoading}>
          <ListProductFromSupplierTable list={filteredList} />
        </Spin>
      </div>
    </div>
  )
}

export default withRouter(ListProductFromSupplierPage)
