import React from 'react'
import useCloseStoreDataContext from '../../hooks/useCloseStoreDataContext'
import { Table, Spin } from 'antd'
import columns from './columns'
import './style.css'

const ListAmendsTable = () => {
  const {
    listAmendsData,
    listAmendsLoading,
    deleteAmendsLoading,
    onDeleteAmends,
    onClickAmendsRow,
    amendsId,
  } = useCloseStoreDataContext()

  const getRowClassName = (record) => {
    if (record.id === amendsId) {
      return 'amend-selected-row cursor-pointer'
    } else if (record.id === 'total') {
      return 'disable-row'
    } else {
      return 'cursor-pointer'
    }
  }

  return (
    <Spin spinning={listAmendsLoading || deleteAmendsLoading}>
      <Table
        rowKey={(record) => `id+${record.id}`}
        dataSource={listAmendsData?.length === 1 ? [] : listAmendsData}
        columns={columns({ onDeleteAmends })}
        scroll={{ x: 550 }}
        style={{
          maxWidth: '600px',
        }}
        size="small"
        pagination={false}
        rowClassName={getRowClassName}
        onRow={(record) => {
          return {
            onClick: () => {
              onClickAmendsRow(record)
            },
          }
        }}
      />
    </Spin>
  )
}

export default ListAmendsTable
