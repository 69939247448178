import React from 'react'
import { Input, Button, Popconfirm } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import './style.scss'

export const ContactList = (props) => {
  const {
    index,
    data: { id, name, tel, email },
    handleContactUpdate,
    deleteContact,
  } = props

  const handleContactChange = (e) => {
    handleContactUpdate(index, e.target.name, e.target.value)
  }

  const handleDeleteConntact = () => {
    deleteContact(index, id)
  }

  return (
    <tr>
      <td>
        <Popconfirm
          title="รายชื่อจะหายจากระบบทันที คุณต้องการลบรายชื่อนี้ใช่ไหม ?"
          okText="ลบ"
          cancelText="ยกเลิก"
          onConfirm={handleDeleteConntact}
        >
          <Button danger icon={<DeleteOutlined />}></Button>
        </Popconfirm>
      </td>
      <td>
        <Input
          id={'name-' + index}
          name="name"
          value={name}
          onChange={handleContactChange}
        />
      </td>
      <td>
        <Input
          id={'tel-' + index}
          name="tel"
          value={tel}
          onChange={handleContactChange}
        />
      </td>
      <td>
        <Input
          id={'email-' + index}
          name="email"
          value={email}
          onChange={handleContactChange}
        />
      </td>
    </tr>
  )
}
