import { useEffect } from 'react'
import { getCashCheckReport } from 'api/cashCheck'
import useAPI from 'hooks/useAPI'

const useRequestStoreReportData = ({ date }) => {
  const {
    makeRequest: getReportRequest,
    data: reportData,
    loading: reportDataLoading,
  } = useAPI()

  useEffect(() => {
    getReportRequest(() =>
      getCashCheckReport({
        date: date,
      })
    )
  }, [getReportRequest, date])

  const onRefreshReport = async () => {
    await getReportRequest(() =>
      getCashCheckReport({
        date: date,
      })
    )
  }

  return {
    reportData,
    reportDataLoading,
    onRefreshReport,
  }
}

export default useRequestStoreReportData
