import { useContext } from 'react'
import { ListPriceInPriceOutContext } from '../contexts/ListPriceInPriceOutContext'

const useListPriceInPriceOutDataContext = () => {
  const context = useContext(ListPriceInPriceOutContext)
  if (!context)
    throw new Error(
      'OpenStoreDataContext must be use inside PaymentOrderProvider'
    )
  return context
}

export default useListPriceInPriceOutDataContext
