import React from 'react'
import { CheckCircleOutlined, BorderOutlined } from '@ant-design/icons'
import { addCommas } from 'utils'

const columns = (isPrint) => {
  const tableColumns = [
    {
      title: 'เลขที่บิล',
      dataIndex: 'bill_no',
      key: 'bill_no',
      width: '100px',
      fixed: 'left',
    },
    {
      title: 'ชื่อลูกค้า',
      dataIndex: 'customer_name',
      key: 'customer_name',
      width: '100px',
      fixed: 'left',
    },
    {
      title: 'Vat',
      dataIndex: 'is_included_vat',
      key: 'is_included_vat',
      width: '40px',
      render: (_, record) => {
        if (record.is_included_vat) {
          return (
            <div className="text-align-center">
              <CheckCircleOutlined style={{ color: '#52c41a' }} />
            </div>
          )
        }
      },
    },
    {
      title: 'ยอดบิล',
      dataIndex: 'bill_grand_total',
      key: 'bill_grand_total',
      width: '90px',
      render: (text) => (
        <div className="text-align-right pr-1">{addCommas(text)}</div>
      ),
    },
    {
      title: 'เงินสด',
      dataIndex: 'cash_amount',
      key: 'cash_amount',
      width: '90px',
      render: (text) => (
        <div className="text-align-right pr-1">{addCommas(text)}</div>
      ),
    },
    {
      title: 'สถานะการชำระ',
      dataIndex: 'payment_status',
      key: 'payment_status',
      width: '80px',
    },
    {
      title: 'หมายเหตุ',
      dataIndex: 'remark',
      key: 'remark',
      width: '80px',
    },
  ]
  const printTableColumns = [
    {
      title: '',
      dataIndex: '',
      key: '',
      width: '30px',
      fixed: 'left',
      render: (_) => (
        <div className="text-align-center">
          <BorderOutlined />
        </div>
      ),
    },
    {
      title: 'เลขที่บิล',
      dataIndex: 'bill_no',
      key: 'bill_no',
      width: '100px',
      fixed: 'left',
    },
    {
      title: 'ชื่อลูกค้า',
      dataIndex: 'customer_name',
      key: 'customer_name',
      width: '100px',
      fixed: 'left',
    },
    {
      title: 'Vat',
      dataIndex: 'is_included_vat',
      key: 'is_included_vat',
      width: '40px',
      render: (_, record) => {
        if (record.is_included_vat) {
          return (
            <div className="text-align-center">
              <CheckCircleOutlined style={{ color: '#52c41a' }} />
            </div>
          )
        }
      },
    },
    {
      title: 'ยอดบิล',
      dataIndex: 'bill_grand_total',
      key: 'bill_grand_total',
      width: '90px',
      render: (text) => (
        <div className="text-align-right pr-1">{addCommas(text)}</div>
      ),
    },
    {
      title: 'เงินสด',
      dataIndex: 'cash_amount',
      key: 'cash_amount',
      width: '90px',
      render: (text) => (
        <div className="text-align-right pr-1">{addCommas(text)}</div>
      ),
    },
    {
      title: 'สถานะการชำระ',
      dataIndex: 'payment_status',
      key: 'payment_status',
      width: '80px',
    },
    {
      title: 'หมายเหตุ',
      dataIndex: 'remark',
      key: 'remark',
      width: '80px',
    },
  ]

  const onSelectColumnFormat = () => {
    if (isPrint === true) {
      return printTableColumns
    } else {
      return tableColumns
    }
  }
  return onSelectColumnFormat()
}

export default columns
