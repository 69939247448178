import React, { useCallback, useState, createContext } from 'react'
import { Form } from 'antd'
import useRequestEditPriceInData from '../hooks/useRequestEditPriceInData'

// NOTE: Context
const EditPriceInContext = createContext({})

// NOTE: Provider
const EditPriceInDataProvider = ({ children }) => {
  const [selectedId, setSelectedId] = useState(0)
  const [saveForm] = Form.useForm()

  const onResetForm = useCallback(() => {
    saveForm.resetFields()
    setSelectedId(0)
  }, [saveForm])

  const {
    listBillItemAdhocData,
    listBillItemAdhocLoading,
    UpdatePriceInData,
    UpdatePriceInLoading,
    onUpdatePriceIn,
    onListItemAdhoc,
  } = useRequestEditPriceInData({ onResetForm })

  const onClickRow = (record) => {
    setSelectedId(record.id)
    saveForm.setFieldsValue(record)
  }

  const onSubmitForm = (value) => {
    onUpdatePriceIn({
        id: selectedId,
        price_in: value.new_price_in
      })
  }

  return (
    <EditPriceInContext.Provider
      value={{
        listBillItemAdhocData,
        listBillItemAdhocLoading,
        UpdatePriceInData,
        UpdatePriceInLoading,
        onUpdatePriceIn,
        onListItemAdhoc,
        onClickRow,
        selectedId,
        saveForm,
        onResetForm,
        onSubmitForm,
      }}
    >
      {children}
    </EditPriceInContext.Provider>
  )
}

export { EditPriceInContext, EditPriceInDataProvider }
