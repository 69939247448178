import { useContext } from 'react'
import { OpenStoreDataContext } from '../contexts/OpenStoreDataContext'

const useOpenStoreDataContext = () => {
  const context = useContext(OpenStoreDataContext)
  if (!context)
    throw new Error(
      'OpenStoreDataContext must be use inside PaymentOrderProvider'
    )
  return context
}

export default useOpenStoreDataContext
