/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import CustomLayout from 'pages/CustomLayout'
import LoginPage from 'pages/LoginPage'
import UnauthorizedPage from 'pages/Error/UnauthorizedPage'
export default () => {

  return [
    {
      path: '*',
      exact: true,
      component: CustomLayout,
      routes: [
        {
          path: '/login',
          component: LoginPage
        },
        {
          path:"",
          component: UnauthorizedPage,
          exact:true
        }
      ]
    },
  ];
}
