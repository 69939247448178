import React from 'react'
import { Link } from 'react-router-dom'
import { addCommas } from 'utils'
import moment from 'moment'
import './style.css'
import 'moment/locale/th'
moment.locale('th')

const columns = () => {
  const tableColumns = [
    {
      title: 'เลขที่บิล',
      dataIndex: 'customer_bill_no',
      key: 'customer_bill_no',
      width: '100px',
      render: (text, record) => (
        <Link
          to={`/no-vat/customer-bill?bill_no=${record.customer_bill_no}`}
          target="_blank"
        >
          {text}
        </Link>
      ),
    },
    {
      title: 'วันที่บิล',
      dataIndex: 'customer_bill_date',
      key: 'customer_bill_date',
      sorter: (a, b) =>
        ('' + a.customer_bill_date).localeCompare(b.customer_bill_date),
      sortDirections: ['ascend', 'descend'],
      width: '80px',
      render: (_, record) => {
        return moment(record.customer_bill_date).format('L')
      },
    },
    {
      title: 'รหัส',
      dataIndex: 'code',
      key: 'code',
      width: '60px',
      sorter: (a, b) => a.code.localeCompare(b.code),
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'name',
      key: 'name',
      width: '300px',
    },
    {
      title: 'ราคาเข้า',
      dataIndex: 'price_in',
      key: 'price_in',
      width: '100px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
    },
    {
      title: 'ราคาขายสุทธิ',
      dataIndex: 'net_price_out',
      key: 'net_price_out',
      sorter: {
        compare: (a, b) => a.net_price_out - b.net_price_out,
      },
      sortDirections: ['ascend', 'descend'],
      width: '100px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
    },
    {
      title: 'กำไร',
      dataIndex: 'profit_percent',
      key: 'profit_percent',
      sorter: {
        compare: (a, b) => a.profit_percent - b.profit_percent,
      },
      sortDirections: ['ascend', 'descend'],
      width: '50px',
      render: (_, record) => {
        if (Number(record.profit_percent) > 75) {
          return (
            <div className="more-than-seventy-five text-align-right pr-2">
              {record.profit_percent}%
            </div>
          )
        } else if (Number(record.profit_percent) > 50) {
          return (
            <div className="more-than-fifty text-align-right pr-2">
              {record.profit_percent}%
            </div>
          )
        } else if (Number(record.profit_percent) > 30) {
          return (
            <div className="more-than-thirty text-align-right pr-2">
              {record.profit_percent}%
            </div>
          )
        } else {
          return (
            <div className="text-align-right pr-2">
              {record.profit_percent}%
            </div>
          )
        }
      },
    },
    {
      title: 'จำนวน',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '50px',
    },
    {
      title: 'หน่วย',
      dataIndex: 'unit_name',
      key: 'unit_name',
      width: '50px',
    },
    {
      title: 'ยอดรวม',
      dataIndex: 'total',
      key: 'total',
      width: '100px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
    },
    {
      title: 'Vat',
      dataIndex: 'product_vat_type_name',
      key: 'product_vat_type_name',
      width: '30px',
    },
  ]
  return tableColumns
}

export default columns
