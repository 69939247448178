import React from 'react'
import { Table, Button, Popconfirm } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'

const ProductSupplierTable = (props) => {
  const { onEditLatestBoughtSupplier, onDeleteLatestBoughtSupplierfromTable } = props
  const columns = [
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (_, record) => {
        return (
          <>
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={() => onEditLatestBoughtSupplier(record)}
            />
            <Popconfirm
              title="คุณต้องการลบรายการนี้ใช่ไหม ?"
              okText="ลบ"
              cancelText="ยกเลิก"
              onConfirm={() => onDeleteLatestBoughtSupplierfromTable(record.id)}
            >
              <Button
                type="primary"
                danger
                icon={<DeleteOutlined />}
                // loading={loadings[2]}
                // onClick={}
              />
            </Popconfirm>
          </>
        )
      },
    },
    {
      title: 'รหัสผู้ขาย',
      dataIndex: 'supplier_code',
      key: 'supplier_code',
    },
    {
      title: 'ชื่อผู้ขาย',
      dataIndex: 'supplier_name',
      key: 'supplier_name',
      render: (_, record) => {
        if(record.is_from_supplier_vat) {
          return <div style={{ color: '#ff9bc4'}}>{record.supplier_name}</div>
        } else {
          return <div>{record.supplier_name}</div>
        }
      },
    },
    {
      title: 'ราคาตั้ง',
      dataIndex: 'price_in',
      key: 'price_in',
    },
    {
      title: 'ส่วนลด',
      dataIndex: '',
      key: 'v',
      render: (_, record) => {
        return `${record.discount_1 ? record.discount_1 : 0}+${
          record.discount_2 ? record.discount_2 : 0
        }+${record.discount_3 ? record.discount_3 : 0}+${
          record.discount_4 ? record.discount_4 : 0
        }+${record.discount_5 ? record.discount_5 : 0}+${
          record.discount_6 ? record.discount_6 : 0
        }`
      },
    },
    {
      title: 'เวลารอคอย',
      dataIndex: 'lead_time',
      key: 'lead_time',
    },
    {
      title: 'วันที่แก้ไขล่าสุด',
      dataIndex: 'latest_bought',
      key: 'latest_bought',
    },
    {
      title: 'หมายเหตุ',
      dataIndex: 'remark',
      key: 'remark',
    },
  ]
  return (
    <div>
      <Table rowKey="id" dataSource={props.supplierList} columns={columns} />
    </div>
  )
}

export default ProductSupplierTable
