/* eslint-disable react-hooks/exhaustive-deps */
import React, { Component } from 'react'
import { Button } from 'antd'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import styled from 'styled-components'

import { onGetCustomerBillVat } from 'redux/actions'
import { handleAlert, addCommas, roundToTwo } from 'utils'
// import Logo from 'assets/logo.jpg'
import './style.scss'

const ShortBlock = styled.div`
  @page {
    margin: 0px;
  }
`

const mapStateToProps = (state) => {
  return {
    getCustomerBillVat: state.getCustomerBillVat,
  }
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ onGetCustomerBillVat }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class index extends Component {
    constructor(props) {
      super(props)
      this.state = {
        bill: {},
        isLoading: false,
        isDataAvailable: false,
      }
    }

    componentDidMount() {
      let search = window.location.search
      let params = new URLSearchParams(search)
      let bill_no = params.get('bill_no')
      this.getBillFromQuery(bill_no)
    }

    componentDidUpdate(prevProps, PrevState) {
      const { getCustomerBillVat } = this.props
      if (prevProps.getCustomerBillVat !== getCustomerBillVat) {
        const { error, loading, finished, data, trace } = getCustomerBillVat
        this.setState({ isLoading: loading })
        if (!loading && !error && finished) {
          this.setState({
            bill: data,
            isDataAvailable: true,
          })
          setTimeout(() => {
            window.print()
          }, 1000)
        }
        if (!loading && error) {
          handleAlert('error', trace.message ? trace.message : '')
          this.setState({ isLoading: loading })
        }
      }
    }

    getBillFromQuery = (bill_no) => {
      this.props.onGetCustomerBillVat(bill_no)
    }

    render() {
      const { bill, isDataAvailable } = this.state
      return (
        isDataAvailable && (
          <div>
            <Button type="primary" className="no-print" onClick={window.print}>
              พิมพ์บิล
            </Button>
            <ShortBlock className="short-print-pdf">
              <div className="short-bill-no-title">
                ใบเสร็จรับเงิน / ใบกํากับภาษีอย่างย่อ
              </div>
              <div className="short-company-title">
                {/* <div className="short-company-logo">
                  <img src={Logo} alt="Bonlighting Logo" />
                </div>
                <div className="short-company-name">
                  บ. บี โอ เอ็น ไลติ้ง จํากัด (สํานักงานใหญ่)
                </div> */}
                <div className="short-company-name-row">
                  บริษัท บี โอ เอ็น ไลติ้ง จํากัด (สํานักงานใหญ่)
                </div>
              </div>
              <div className="short-company-detail">
                68/3 หมู่ 11 ถ.ลําลูกกา ต.ลําลูกกา
              </div>
              <div className="short-company-detail">
                อ.ลําลูกกา จ.ปทุมธานี 12150
              </div>
              <div className="short-company-tel-detail">
                Tel : 02-5692354 Fax : 02-5692355
              </div>
              <div className="short-company-tel-detail">
                TAX ID : 0135563021857 (VAT Included)
              </div>
              <div className="short-company-sale">
                พนักงานขาย : {bill.sale_person}
              </div>
              <div className="short-divider">
                --------------------------------------------------------------
              </div>
              <div className="short-bill-detail-section">
                <div className="short-bill-no-row">
                  <div className="short-bill-no-row-label">Bill No : </div>
                  <div className="short-bill-no-row-value">
                    {bill.bill_vat_no}
                  </div>
                </div>
                <div className="short-bill-no-row">
                  <div className="short-bill-no-row-label">
                    วันที่ (เวลา) :{' '}
                  </div>
                  <div className="short-bill-no-row-value">
                    {moment(bill.bill_vat_date).format('DD/MM/YYYY')}
                  </div>
                </div>
              </div>
              <div className="short-divider">
                --------------------------------------------------------------
              </div>
              <div className="short-product-list-title ">
                รายการสินค้า (Product Description)
              </div>
              <div className="short-product-row" key={index}>
                <div className="short-product-first-column">
                  <strong>qty</strong>
                </div>
                <div className="short-product-second-column">
                  <strong>ชื่อสินค้า</strong>
                </div>
                <div className="short-product-third-column">
                  <strong>หน่วยละ</strong>
                </div>
                <div className="short-product-fourth-column">
                  <strong>รวม</strong>
                </div>
              </div>
              {bill.products_vat &&
                bill.products_vat.map((product, index) => {
                  return (
                    <div className="short-product-row" key={index}>
                      <div className="short-product-first-column">
                        {addCommas(Number(product.quantity))}
                      </div>
                      <div className="short-product-second-column">
                        {product.product_vat_name + ' ' + product.remark}
                      </div>
                      <div className="short-product-third-column">
                        {addCommas(
                          roundToTwo(product.price_out_with_vat).toFixed(2)
                        )}
                      </div>
                      <div className="short-product-fourth-column">
                        {addCommas((product.quantity * roundToTwo(product.price_out_with_vat).toFixed(2)).toFixed(2))}
                      </div>
                    </div>
                  )
                })}
              <div className="short-divider">
                --------------------------------------------------------------
              </div>
              <div className="short-totol-section">
                <div className="short-total-row">
                  <div className="short-total-title-column">ยอดรวม (Total)</div>
                  <div className="short-total-value-column">
                    {addCommas(bill.total_before_special_discount)}
                  </div>
                </div>
                <div className="short-total-row">
                  <div className="short-total-title-column">VAT 7.00%</div>
                  <div className="short-total-value-column">
                    {addCommas(bill.value_added_tax)}
                  </div>
                </div>
                <div className="short-total-row">
                  <div className="short-total-title-column">
                    <strong>ยอดสุทธิ (Grand Total)</strong>
                  </div>
                  <div className="short-total-value-column">
                    <strong>{addCommas(bill.grand_total_with_vat)}</strong>
                  </div>
                </div>
                <div className="short-total-row">
                  <div className="short-total-title-column">
                    รับเงินสดมา (Cash Receive)
                  </div>
                  <div className="short-total-value-column">
                    {addCommas(bill.cash_input_amount)}
                  </div>
                </div>
                <div className="short-total-row">
                  <div className="short-total-title-column">
                    เงินทอน (Change)
                  </div>
                  <div className="short-total-value-column">
                    {addCommas(bill.cash_change_amount)}
                  </div>
                </div>
              </div>
              <div className="short-divider">
                --------------------------------------------------------------
              </div>
              {/* <div className="short-member-secton">
                <div>ข้อมูลสมาชิก</div>
                <div>ชื่อ: {bill.customer_data.name}</div>
                <div>คะแนนสะสม: </div>
              </div>
              <div className="short-divider">
                --------------------------------------------------------------
              </div> */}
              <div className="short-footer">สินค้าซื้อแล้วไม่รับเปลี่ยนคืน</div>
            </ShortBlock>
          </div>
        )
      )
    }
  }
)
