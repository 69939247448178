import React from 'react'
import useOpenStoreDataContext from '../hooks/useOpenStoreDataContext'
import { DatePicker, Divider } from 'antd'
import moment from 'moment'

moment.locale('th')

const DatePickerOpenStore = () => {
  const { date, onDateChange } = useOpenStoreDataContext()

  return (
    <>
      <span style={{ paddingRight: '10px' }}>เลือกวันที่ :</span>
      <DatePicker
        defaultValue={moment() || date}
        disabledDate={(current) => current.isAfter(moment())}
        onChange={(_, dateString) => {
          onDateChange(dateString)
        }}
      />
      <span
        style={{
          fontWeight: 700,
          margin: '0px 0px 10px 20px',
          fontSize: '16px',
        }}
      >
        ตารางแสดงจำนวนเงินสดตอนเปิดร้านประจำวันที่ :{' '}
        <span style={{ color: 'blue' }}>{date}</span>
      </span>
      <Divider />
    </>
  )
}

export default DatePickerOpenStore
