import React from 'react'
import { Button } from 'antd'
import useEditPriceInDataContext from 'pages/EditPriceInPage/hooks/useEditPriceInDataContext'
import { ReloadOutlined } from '@ant-design/icons'

const RefreshTableButtonSection = () => {
  const { onListItemAdhoc } = useEditPriceInDataContext()

  return (
    <div className="input-wrapper">
      <div className="input-item">
        <Button
          type="primary"
          icon={<ReloadOutlined />}
          size="medium"
          onClick={onListItemAdhoc}
          style={{ width: '150px' }}
        >
          Refresh
        </Button>
        <b>คำเตือน : </b>อย่าลืมแก้ไข <b>"ราคาเข้า"</b> ของสินค้านั้นๆ ที่หน้า <b>"ข้อมูลสินค้า"</b> หรือ <b>"ย้ายสินค้าเข้าสต๊อก"</b> ให้เรียบร้อย เพื่อความถูกต้องของข้อมูล
      </div>
    </div>
  )
}

export default RefreshTableButtonSection
