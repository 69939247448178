import React, { useEffect, useState } from 'react'
import useStoreReportDataContext from 'pages/SummaryStoreReportPage/hooks/useStoreReportDataContext'
import { Table, Spin } from 'antd'
import columns from './columns'

const CustomerBillTableReport = ({ isPrint }) => {
  const { reportData, reportDataLoading } = useStoreReportDataContext()
  const [printArray, setPrintArray] = useState([])
  const maximumProductPerPage = 20
  useEffect(() => {
    let splitArray = []
    if (reportData?.customer_bill_list.length > maximumProductPerPage) {
      for (
        let i = 0;
        i <
        Math.ceil(
          reportData?.customer_bill_list.length / maximumProductPerPage
        );
        i++
      ) {
        splitArray[i] = reportData?.customer_bill_list.slice(
          i * maximumProductPerPage,
          (i + 1) * maximumProductPerPage
        )
      }
    } else {
      splitArray[0] = reportData?.customer_bill_list
    }
    setPrintArray(splitArray)
    // eslint-disable-next-line
  }, [reportData])

  const renderTable = () => {
    if (isPrint === true) {
      return (
        <>
          <div
            style={{
              fontWeight: 700,
              fontSize: 18,
              margin: '10px 0px 10px 0px',
            }}
          >
            บิลสีฟ้าทั้งหมด
          </div>
          {printArray.map((page, index) => {
            return (
              <div key={index} style={{ marginTop: '40px' }}>
                <Table
                  rowKey={(record) => `print-id-${record.id}`}
                  dataSource={page}
                  columns={columns(isPrint)}
                  scroll={{ x: 650 }}
                  style={{
                    maxWidth: '700px',
                  }}
                  size="small"
                  pagination={false}
                />
                <div className="page-break-after" />
              </div>
            )
          })}
        </>
      )
    } else {
      return (
        <>
          <div
            style={{
              fontWeight: 700,
              fontSize: 18,
              margin: '10px 0px 10px 0px',
            }}
          >
            บิลสีฟ้าทั้งหมด
          </div>
          <Table
            rowKey={(record) => `id+${record.id}`}
            dataSource={reportData?.customer_bill_list}
            columns={columns(isPrint)}
            scroll={{ x: 650 }}
            style={{
              maxWidth: '700px',
            }}
            size="small"
            pagination={false}
          />
        </>
      )
    }
  }

  return <Spin spinning={reportDataLoading}>{renderTable()}</Spin>
}

export default CustomerBillTableReport
