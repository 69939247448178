import React, { useEffect } from 'react'
import { OpenStoreDataProvider } from './contexts/OpenStoreDataContext'
import DatePickerOpenStore from './components/DatePickerOpenStore'
import BankNoteFormOpenStore from './components/BankNoteFormOpenStore'

import { Row, Col } from 'antd'
import ListOpenStoreTable from './components/ListOpenStoreTable'

const OpenStorePage = (props) => {
  useEffect(() => {
    document.title = 'เปิดร้าน (Open) - NoVat'
  }, [])
  return (
    <OpenStoreDataProvider>
      <h1>เปิดร้าน</h1>
      <div className="form-wrapper">
        <DatePickerOpenStore />
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 6 }}>
            <BankNoteFormOpenStore />
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 18 }}>
            <ListOpenStoreTable />
          </Col>
        </Row>
      </div>
    </OpenStoreDataProvider>
  )
}

export default OpenStorePage
