/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Modal } from 'antd'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { DEFAULT_SELETED_SUPPLIER } from 'pages/ProductPage'
import InlineInput from 'components/InlineInput'
import DiscountInput from 'components/InlineInput/DiscountInput'

import {
  onSaveLatestBoughtSupplier,
  fetchSearchSupplierCode,
} from 'redux/actions'

import { handleAlert } from 'utils'

const InputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const InputItem = styled.div`
  margin-right: 20px;
  margin-bottom: 10px;
  width: ${(props) => (props.width ? props.width : 'unset')};
`

function ProductSupplierModalForm(props) {
  const {
    isShowModal,
    setIsShowModal,
    selectedRow,
    productCode,
    isFetchingNewLatestBoughtSupplierList,
    setIsFetchingNewLatestBoughtSupplierList,
    getlistLatestBoughtSupplierByProductId,
  } = props
  const [form, setForm] = useState(selectedRow)
  const [supplier, setSupplier] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const dispatch = useDispatch()
  const saveLatestBoughtSupplier = useSelector(
    (state) => state.saveLatestBoughtSupplier
  )
  const searchSupplierCode = useSelector((state) => state.searchSupplierCode)

  useEffect(() => {
    setForm(selectedRow)
  }, [selectedRow])

  useEffect(() => {
    const { loading, error, data, finished } = saveLatestBoughtSupplier
    setIsLoading(loading)
    if (!loading && !error && finished) {
      setIsModalLoading(false)
      setIsShowModal(false)
      setIsFetchingNewLatestBoughtSupplierList(
        !isFetchingNewLatestBoughtSupplierList
      )
      getlistLatestBoughtSupplierByProductId(data.product_id)
      setForm(DEFAULT_SELETED_SUPPLIER)
    }
    if (error && !loading) {
      setIsModalLoading(false)
      setIsShowModal(false)
      handleAlert('error', 'บันทึกไม่สำเร็จกรุณาลองใหม่อีกครั้ง')
    }
  }, [saveLatestBoughtSupplier])

  useEffect(() => {
    const { trace, loading, error, finished, data } = searchSupplierCode
    setIsLoading(loading)
    if (error) {
      handleAlert('custom', trace.message || '')
      setIsLoading(false)
      setForm(DEFAULT_SELETED_SUPPLIER)
    }
    if (!error && data && finished) {
      setIsLoading(false)
      setSupplier(data)
      setIsLoading(false)
    }
  }, [searchSupplierCode])

  const onSeaarchCode = (searchCode) => {
    setIsLoading(true)
    dispatch(fetchSearchSupplierCode(searchCode))
  }

  const onChangeInputValue = (value, name) => {
    if (errors[name] && value) {
      const tmp_errors = { ...errors }
      delete tmp_errors[name]
      setErrors(tmp_errors)
    }
    setForm({ ...form, [name]: value.toUpperCase() })
  }

  const handleSaveLatestBoughtSupplier = () => {
    const data = {
      ...form,
      product_code: productCode,
    }
    setIsModalLoading(true)
    dispatch(onSaveLatestBoughtSupplier(data))
  }

  return (
    <Modal
      title="เพิ่มข้อมูลสินค้ากับผู้ขายรายใหม่"
      visible={isShowModal}
      onOk={() => handleSaveLatestBoughtSupplier()}
      confirmLoading={isModalLoading}
      onCancel={() => setIsShowModal(false)}
      width="75vw"
    >
      <InputWrapper>
        <InputItem>
          <InlineInput
            onSearch={(value) => {
              onSeaarchCode(value)
              setForm({ ...form, supplier_code: value })
            }}
            inputOption="search"
            label="รหัสผู้ขาย"
            placeholder="รหัสผู้ขาย"
            isLoading={isLoading}
            required={true}
            value={form.supplier_code}
            onChange={(event) => {
              onChangeInputValue(event.target.value, 'supplier_code')
            }}
            error={errors.supplier_code}
            width="200px"
          />
        </InputItem>
        <InputItem>
          <InlineInput
            label="ชื่อผู้ขาย"
            value={supplier.name}
            disabled={true}
          />
        </InputItem>
      </InputWrapper>
      <InputWrapper>
        <InputItem>
          <InlineInput
            label="ราคาตั้ง"
            value={form.price_in}
            disabled={false}
            onChange={(event) =>
              setForm({ ...form, price_in: event.target.value })
            }
          />
        </InputItem>
        <InputItem>
          <DiscountInput
            label="ส่วนลด"
            name1="discount_1"
            name2="discount_2"
            name3="discount_3"
            name4="discount_4"
            name5="discount_5"
            name6="discount_6"
            value1={Number(form.discount_1)}
            value2={Number(form.discount_2)}
            value3={Number(form.discount_3)}
            value4={Number(form.discount_4)}
            value5={Number(form.discount_5)}
            value6={Number(form.discount_6)}
            onChange={(value, name) => setForm({ ...form, [name]: value })}
            width="100px"
          />
        </InputItem>
      </InputWrapper>
      <InputWrapper>
        <InputItem>
          <InlineInput
            label="เวลารอคอย"
            value={form.lead_time}
            disabled={false}
            onChange={(event) =>
              setForm({ ...form, lead_time: event.target.value })
            }
          />
        </InputItem>
        <InputItem>
          <InlineInput
            label="วันที่ซื้อล่าสุด"
            placeholder="เลือกวันที่ซื้อล่าสุด"
            defaultValue={moment()}
            value={form.latest_bought && moment(form.latest_bought)}
            onChange={(_, dateString) => {
              setForm({ ...form, latest_bought: dateString })
            }}
            inputOption="date_picker"
            width="170px"
          />
        </InputItem>
        <InputItem width="80%">
          <InlineInput
            inputOption="text_area_input"
            label="หมายเหตุ"
            placeholder="หมายเหตุ"
            value={form.remark}
            onChange={(event) =>
              setForm({ ...form, remark: event.target.value })
            }
            width="100%"
            labelWidth="74px"
          />
        </InputItem>
      </InputWrapper>
    </Modal>
  )
}

export default ProductSupplierModalForm
