/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Button, Spin, Input, Table, Popconfirm } from 'antd'
import { SaveOutlined, ClearOutlined } from '@ant-design/icons'

import { onGetProductByCode, onSaveProduct } from 'redux/actions'
import { useSelector, useDispatch } from 'react-redux'

import InlineInput from 'components/InlineInput'
import DiscountInput from 'components/InlineInput/DiscountInput'
import { handleAlert, addCommas } from 'utils'
import useListProduct from 'pages/ListProductPage/hooks/useListProduct'
import usePagination from 'hooks/usePagination'

const Search = Input.Search

const DEFAULT_FORM = {
  id: null,
  code: '',
  name: '',
  price_out_cash: null,
  price_out_atm: null,
  discount_cash_1: '0.00',
  discount_cash_2: '0.00',
  discount_cash_3: '0.00',
  discount_cash_4: '0.00',
  discount_atm_1: '0.00',
  discount_atm_2: '0.00',
  discount_atm_3: '0.00',
  discount_atm_4: '0.00',
}

const EditPricePage = () => {
  const {
    currentPageNumber,
    pageSize,
    paginationSetting,
    setCurrentPageNumber,
  } = usePagination()
  const {
    productsData,
    isLoadingProductsData,
    pagination,
    onSearchProductCode,
    onListProduct,
  } = useListProduct({
    currentPageNumber,
    pageSize,
  })
  const columns = [
    {
      title: 'รหัสสินค้า',
      dataIndex: 'code',
      key: 'code',
      render: (text) => (
        <span style={{ color: 'rgb(37, 185, 255)' }}>{text}</span>
      ),
      sorter: (a, b) => ('' + a.code).localeCompare(b.code),
      sortDirections: ['ascend', 'descend'],
      width: '100px',
      fixed: 'left',
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'name',
      key: 'name',
      width: '500px',
      sorter: (a, b) => ('' + a.name).localeCompare(b.name),
      sortDirections: ['ascend', 'descend'],
      fixed: 'left',
    },
    {
      title: 'สต็อก1',
      dataIndex: 'stock1',
      key: 'stock1',
      width: '110px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
    },
    {
      title: 'สต็อก2',
      dataIndex: 'stock2',
      key: 'stock2',
      width: '110px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
    },
    {
      title: 'ต้นทุน',
      dataIndex: 'price_in_1',
      key: 'price_in_1',
      width: '110px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
    },
    {
      title: 'ราคาขายส่ง',
      dataIndex: 'price_out_atm',
      key: 'price_out_atm',
      width: '120px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
    },
    {
      title: 'ส่วนลดราคาส่ง',
      width: '150px',
      render: (_, record) => {
        return `${Number(record.discount_atm_1)}+${Number(
          record.discount_atm_2
        )}+${Number(record.discount_atm_3)}+${Number(record.discount_atm_4)}`
      },
    },
    {
      title: 'ราคาขายปลีก',
      dataIndex: 'price_out_cash',
      key: 'price_out_cash',
      width: '120px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
    },
    {
      title: 'ส่วนลดราคาปลีก',
      width: '150px',
      render: (_, record) => {
        return `${Number(record.discount_cash_1)}+${Number(
          record.discount_cash_2
        )}+${Number(record.discount_cash_3)}+${Number(record.discount_cash_4)}`
      },
    },
  ]
  const [form, setForm] = useState(DEFAULT_FORM)
  const [isLoading, setIsLoading] = useState(false)
  const [searchCode, setSearchCode] = useState('')
  const [errors, setErrors] = useState({})
  const dispatch = useDispatch()
  const saveProduct = useSelector((state) => state.saveProduct)
  const getProductByCode = useSelector((state) => state.getProductByCode)

  const onChangeInputValue = (value, name) => {
    if (errors[name] && value) {
      const tmp_errors = { ...errors }
      delete tmp_errors[name]
      setErrors(tmp_errors)
    }
    setForm({ ...DEFAULT_FORM, [name]: value.toUpperCase() })
  }

  useEffect(() => {
    document.title = 'แก้ไขราคาสินค้า - NoVat'
  }, [])

  useEffect(() => {
    setIsLoading(getProductByCode.loading)
    if (
      !getProductByCode.loading &&
      !getProductByCode.error &&
      getProductByCode.finished
    ) {
      setForm(getProductByCode.data)
    }
    if (!getProductByCode.loading && getProductByCode.error) {
      setForm(DEFAULT_FORM)
      handleAlert('error', getProductByCode.trace.message || '')
    }
  }, [getProductByCode])

  const onSearchProductHandler = (code) => {
    dispatch(onGetProductByCode(code))
  }

  useEffect(() => {
    setIsLoading(saveProduct.loading)
    const { trace } = saveProduct
    if (!saveProduct.loading && !saveProduct.error && saveProduct.finished) {
      handleAlert('success')
      setForm(saveProduct.data)
      onListProduct()
    }
    if (!saveProduct.loading && saveProduct.error) {
      handleAlert('error', trace.message || '')
    }
  }, [saveProduct])

  const onSaveProductHandler = () => {
    if (form.code !== '' && form.id !== null) {
      setErrors({})
      dispatch(onSaveProduct(form))
    } else {
      handleAlert('custom', 'กรุณากรอกรหัสสินค้า')
      setErrors({ ...errors, code: 'กรุณากรอกรหัสสินค้า' })
    }
  }

  const handleSearch = (searchCode) => {
    setCurrentPageNumber(1)
    onSearchProductCode({ productCode: searchCode.toLowerCase() })
  }

  const onClearInput = () => {
    setForm(DEFAULT_FORM)
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'inline-block' }}>
          <h1>แก้ไขราคาสินค้า</h1>
        </div>
        <div style={{ display: 'inline-block' }}>
          <Popconfirm
            title="คุณต้องการล้างหน้าจอใช่หรือไม่ ?"
            okText="ใช่"
            cancelText="ยกเลิก"
            onConfirm={onClearInput}
          >
            <Button
              type="default"
              danger
              icon={<ClearOutlined />}
              size="large"
              block
            >
              ล้างหน้าจอ
            </Button>
          </Popconfirm>
        </div>
      </div>
      <Spin spinning={isLoading}>
        <div className="form-wrapper">
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                onSearch={(value) => {
                  setForm({ ...form, code: value })
                  onSearchProductHandler(value)
                }}
                inputOption="search"
                label="รหัสสินค้า"
                placeholder="รหัสสินค้า"
                isLoading={isLoading}
                required={true}
                value={form.code}
                onChange={(event) => {
                  onChangeInputValue(event.target.value, 'code')
                }}
                error={errors.code}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ชื่อสินค้า"
                width="500px"
                value={form.name}
                disabled={false}
                onChange={(event) =>
                  setForm({ ...form, name: event.target.value })
                }
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="ราคาขายส่ง"
                width="170px"
                value={form.price_out_atm}
                disabled={false}
                onChange={(event) =>
                  setForm({ ...form, price_out_atm: event.target.value })
                }
              />
            </div>
            <div className="input-item">
              <DiscountInput
                label="ส่วนลดราคาส่ง"
                name1="discount_atm_1"
                name2="discount_atm_2"
                name3="discount_atm_3"
                name4="discount_atm_4"
                value1={Number(form.discount_atm_1)}
                value2={Number(form.discount_atm_2)}
                value3={Number(form.discount_atm_3)}
                value4={Number(form.discount_atm_4)}
                onChange={(value, name) =>
                  setForm({ ...form, [name]: Number(value) || 0.0 })
                }
                width="100px"
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="ราคาขายปลีก"
                width="170px"
                value={form.price_out_cash}
                disabled={false}
                onChange={(event) =>
                  setForm({ ...form, price_out_cash: event.target.value })
                }
              />
            </div>
            <div className="input-item">
              <DiscountInput
                label="ส่วนลดราคาปลีก"
                name1="discount_cash_1"
                name2="discount_cash_2"
                name3="discount_cash_3"
                name4="discount_cash_4"
                value1={Number(form.discount_cash_1)}
                value2={Number(form.discount_cash_2)}
                value3={Number(form.discount_cash_3)}
                value4={Number(form.discount_cash_4)}
                onChange={(value, name) =>
                  setForm({ ...form, [name]: Number(value) || 0.0 })
                }
                width="100px"
              />
            </div>
            <div className="input-item" style={{ marginLeft: '20px' }}>
              <Button
                type="primary"
                icon={<SaveOutlined />}
                size="large"
                onClick={onSaveProductHandler}
              >
                บันทึก
              </Button>
            </div>
          </div>
        </div>
      </Spin>
      <Spin spinning={isLoadingProductsData}>
        <div className="mb-2 mt-2">
          <Search
            value={searchCode}
            onChange={(event) => {
              setSearchCode(event.target.value.toUpperCase())
            }}
            placeholder="ค้นหารหัสสินค้า"
            onSearch={handleSearch}
            style={{ width: 200, marginRight: 10 }}
          />
        </div>
        <div>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={productsData}
            size="small"
            scroll={{ y: 685 }}
            style={{ height: '685px' }}
            pagination={paginationSetting({
              totalItems: pagination?.totalItems,
              simple: true,
            })}
            rowClassName="cursor-pointer"
            onRow={(record, index) => {
              return {
                onClick: () => {
                  onSearchProductHandler(record.code)
                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  })
                },
              }
            }}
          />
        </div>
      </Spin>
    </div>
  )
}

export default EditPricePage
