import React, { useCallback, useState, createContext, useEffect } from 'react'
import useRequestCloseStoreData from '../hooks/useRequestCloseStoreData'
import moment from 'moment'
import { Form } from 'antd'

moment.locale('th')

// NOTE: Context
const CloseStoreDataContext = createContext({})

// NOTE: Provider
const CloseStoreDataProvider = ({ children }) => {
  const [form] = Form.useForm()
  const [id, setId] = useState(0)
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
  const [billFormId, setBillFormId] = useState(0)
  const [billVatForm] = Form.useForm()
  const [amendsId, setAmendsId] = useState(0)
  const [amendsForm] = Form.useForm()
  const onResetForm = useCallback(() => {
    form.resetFields()
    setId(0)
  }, [form])

  const onResetBillVatForm = useCallback(() => {
    billVatForm.resetFields()
    setBillFormId(0)
  }, [billVatForm])

  const onResetAmendsForm = useCallback(() => {
    amendsForm.resetFields()
    setAmendsId(0)
  }, [amendsForm])

  const {
    listCashesData,
    listCashesLoading,
    onDeleteCash,
    deleteLoading,
    onAddCash,
    addCashesData,
    addCashesLoading,
    listBillVatCashesData,
    listBillVatCashesLoading,
    addBillVatCashesData,
    addBillVatCashesLoading,
    onAddBillVatCash,
    deleteBillVatCashesLoading,
    onDeleteBillVatCash,
    onAddAmends,
    listAmendsData,
    listAmendsLoading,
    addAmendsLoading,
    deleteAmendsLoading,
    onDeleteAmends,
  } = useRequestCloseStoreData({ date, onResetBillVatForm, onResetAmendsForm })

  useEffect(() => {
    if (listCashesData !== undefined && listCashesData.length > 1) {
      setId(listCashesData[0]?.id)
      form.setFieldsValue(listCashesData[0]?.banknote)
      onResetBillVatForm()
      onResetAmendsForm()
    } else {
      onResetForm()
      onResetBillVatForm()
      onResetAmendsForm()
    }
  }, [listCashesData, form, onResetForm, onResetBillVatForm, onResetAmendsForm])

  useEffect(() => {
    setId(addCashesData?.id)
    form.setFieldsValue(addCashesData?.banknote)
  }, [addCashesData, form])

  const onSubmitCashForm = (value) => {
    onAddCash({
      id,
      date,
      banknote: value,
      record_type: 'close',
    })
  }

  const onSubmitBillVatForm = (value) => {
    onAddBillVatCash({
      id: billFormId,
      date,
      ...value,
    })
  }

  const onSubmitAmendsForm = (value) => {
    onAddAmends({
      id: amendsId,
      date,
      ...value,
    })
  }

  const onClickRow = (record) => {
    setBillFormId(record.id)
    billVatForm.setFieldsValue({
      bill_no: record.bill_no,
      bill_total_amount: record.bill_total_amount,
      total_cash: record.total_cash,
      remark: record.remark,
    })
  }

  const onClickAmendsRow = (record) => {
    setAmendsId(record.id)
    amendsForm.setFieldsValue({
      type: record.type,
      cash_amount: record.cash_amount,
      remark: record.remark,
    })
  }

  const onCashChange = (_, allValue) => {
    let totalCash = 0
    totalCash += allValue?.thousand * 1000
    totalCash += allValue?.fivehundred * 500
    totalCash += allValue?.onehundred * 100
    totalCash += allValue?.fifty * 50
    totalCash += allValue?.twenty * 20
    totalCash += allValue?.ten * 10
    totalCash += allValue?.five * 5
    totalCash += allValue?.two * 2
    totalCash += allValue?.one * 1
    form.setFieldsValue({ total: totalCash })
  }

  const onDateChange = (value) => {
    setDate(value)
  }

  return (
    <CloseStoreDataContext.Provider
      value={{
        id,
        form,
        date,
        billFormId,
        billVatForm,
        onDateChange,
        listCashesData,
        listCashesLoading,
        onCashChange,
        onDeleteCash,
        deleteLoading,
        onSubmitCashForm,
        addCashesLoading,
        onResetForm,
        onClickRow,
        onResetBillVatForm,
        onSubmitBillVatForm,
        listBillVatCashesData,
        listBillVatCashesLoading,
        addBillVatCashesData,
        addBillVatCashesLoading,
        deleteBillVatCashesLoading,
        onDeleteBillVatCash,
        amendsId,
        amendsForm,
        onResetAmendsForm,
        onSubmitAmendsForm,
        onClickAmendsRow,
        listAmendsData,
        listAmendsLoading,
        addAmendsLoading,
        deleteAmendsLoading,
        onDeleteAmends,
      }}
    >
      {children}
    </CloseStoreDataContext.Provider>
  )
}

export { CloseStoreDataContext, CloseStoreDataProvider }
