import React from 'react'
import { Table } from 'antd'

const AlterStockTable = (props) => {
  const tableColumns = [
    {
      title: 'วันที่',
      dataIndex: 'issued_date',
      key: 'issued_date',
      width: '80px',
      sorter: (a, b) => ('' + a.issued_date).localeCompare(b.issued_date),
      sortDirections: ['ascend', 'descend'],  
    },
    {
      title: 'รหัสสินค้า',
      dataIndex: 'product_code',
      key: 'product_code',
      width: '80px',
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'product_name',
      key: 'product_name',
      width: '500px',
    },
    {
      title: 'จำนวนเพิ่ม',
      dataIndex: 'increase_stock',
      key: 'increase_stock',
      width: '100px',
      render: (text) => <div className="text-align-right">{text}</div>,
    },
    {
      title: 'จำนวนลด',
      dataIndex: 'reduce_stock',
      key: 'reduce_stock',
      width: '100px',
      render: (text) => <div className="text-align-right">{text}</div>,
    },
    {
      title: 'จำนวนสุทธิ',
      dataIndex: 'total_stock',
      key: 'total_stock',
      width: '100px',
      render: (text) => <div className="text-align-right">{text}</div>,
    },
    {
      title: 'ยอดรวม',
      dataIndex: 'cost',
      key: 'cost',
      width: '110px',
      render: (text) => <div className="text-align-right">{text}</div>,
    },
  ]
  
  return (
    <div>
      <Table
        rowKey={(record) => `id+${record.product_code}`}
        dataSource={props.alterStockList}
        columns={tableColumns}
        pagination={false}
        bordered
        scroll={{ x: 1500 }}
        size='small'
      />
    </div>
  )
}

export default AlterStockTable
