/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import { Button, Spin, Divider, Modal, Popconfirm } from 'antd'
import {
  SaveOutlined,
  ClearOutlined,
  SearchOutlined,
  PrinterOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'

import InlineInput from 'components/InlineInput'
import ListExpensesTable from 'components/ListExpensesTable'
import ExpensesPDF from 'components/ExpensesPDF'
import { handleAlert, addCommas, roundToTwo } from 'utils'

import {
  onListExpenseType,
  onListExpenseHistory,
  onSaveExpense,
  onDeleteExpense,
} from 'redux/actions'

const PASSCODE = 'jb007'

const { confirm } = Modal

const DEFAULT_FORM = {
  id: 0,
  amount: '',
  payment_date: moment().format('YYYY-MM-DD'),
  remark: '',
  expense_type_id: null,
}

const DEFAULT_SEARCH_LIST = {
  start_date: moment().format('YYYY-MM-DD'),
  end_date: moment().format('YYYY-MM-DD'),
  expense_type: '',
  remark: '',
  expense_summary: '',
}

const ExpensesPage = (props) => {
  function useAsyncState(initialValue) {
    const [value, setValue] = useState(initialValue)
    const setter = (x) =>
      new Promise((resolve) => {
        setValue(x)
        resolve(x)
      })
    return [value, setter]
  }
  const [form, setForm] = useAsyncState(DEFAULT_FORM)
  const [isLoading, setIsLoading] = useState(false)
  const [type, setType] = useState([])
  const [search, setSearch] = useState(DEFAULT_SEARCH_LIST)
  const [list, setList] = useState([])
  const [filteredList, setFilteredList] = useState([])
  const [filterRemark, setFilterRemark] = useState('')
  const [filterTypeId, setFilterTypeId] = useState(null)
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [pass, setPass] = useState('')
  const dispatch = useDispatch()
  const listExpenseType = useSelector((state) => state.listExpenseType)
  const saveExpense = useSelector((state) => state.saveExpense)
  const listExpenseHistory = useSelector((state) => state.listExpenseHistory)
  const deleteExpense = useSelector((state) => state.deleteExpense)

  useEffect(() => {
    document.title = 'บันทึกค่าใช้จ่าย'
    dispatch(onListExpenseType())
  }, [])

  useEffect(() => {
    const { error, finished, data, trace } = listExpenseType
    if (error) {
      handleAlert('custom', trace.message || '')
    }
    if (!error && finished) {
      initialExpenseType(data)
    }
  }, [listExpenseType])

  useEffect(() => {
    const { trace, error, finished, data } = saveExpense
    if (error) {
      handleAlert('custom', trace.message || '')
      setIsLoading(false)
    }
    if (!error && data && finished) {
      if (data === 'update_expenses') {
        Modal.success({
          title: 'สำเร็จ',
          content: 'แก้ไขรายการเรียบร้อย',
        })
      }
      if (data === 'add_expenses') {
        Modal.success({
          title: 'สำเร็จ',
          content: 'เพิ่มรายการใหม่เรียบร้อย',
        })
      }
      onSearchClick()
      setForm(DEFAULT_FORM)
      setIsLoading(false)
    }
  }, [saveExpense])
  // Only re-subscribe if props.searchSupplierCode changes

  useEffect(() => {
    const { trace, error, finished, data } = listExpenseHistory
    if (error) {
      handleAlert('custom', trace.message || '')
      setIsTableLoading(false)
    }
    if (!error && data && finished) {
      setList(data)
      setFilteredList(data)
      calculateExpenseSummary(data)
      setIsTableLoading(false)
    }
  }, [listExpenseHistory])

  useEffect(() => {
    const { error, finished, trace } = deleteExpense
    if (error) {
      handleAlert('custom', trace.message || '')
    }
    if (!error && finished) {
      Modal.success({
        title: 'สำเร็จ',
        content: 'ลบรายการที่เลือกเรียบร้อย',
      })
      setForm(DEFAULT_FORM)
      onSearchClick()
    }
  }, [deleteExpense])

  useEffect(() => {
    if (list !== []) {
      handleSearch(filterTypeId, filterRemark)
    }
  }, [filterTypeId, filterRemark])

  useEffect(() => {
    initialExpenseType(listExpenseType.data)
  }, [pass])

  const handleSearch = (filterTypeId, filterRemark) => {
    const _list = [...list]
    const filteredData = _list.filter(({ expense_type_id, remark }) => {
      remark = remark.toString().toLowerCase()
      if (filterTypeId !== null) {
        return (
          expense_type_id === filterTypeId &&
          remark.includes(filterRemark.toLowerCase())
        )
      } else {
        return remark.includes(filterRemark.toLowerCase())
      }
    })
    setFilteredList(filteredData)
    calculateExpenseSummary(filteredData)
  }

  const initialExpenseType = (data) => {
    let phaseType = []
    data.forEach((type) => {
      let newFormat = {
        id: null,
        name: '',
        value: '',
      }
      newFormat.id = type.expense_type_id
      newFormat.name = type.expense_type_name
      newFormat.value = type.expense_type_value
      if(type.expense_type_value !== 'employee_salary') {
        phaseType.push(newFormat)
      }
      if(type.expense_type_value === 'employee_salary' && pass === PASSCODE) {
        phaseType.push(newFormat)
      }
    })
    setType(phaseType)
  }

  const onClickSaveExpense = () => {
    confirm({
      title: 'คุณต้องการที่จะ บันทึก/แก้ไข รายการดังกล่าวใช่หรือไม่ ?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        setIsLoading(true)
        dispatch(onSaveExpense(form))
      },
      onCancel() {
      },
    });
  };

  const onSearchClick = () => {
    console.log('New CODE!!')
    setIsTableLoading(true)
    dispatch(
      onListExpenseHistory(
        search.start_date,
        search.end_date,
        search.expense_type,
        search.remark
      )
    )
  }

  const calculateExpenseSummary = (data) => {
    let summary = data.reduce(
      (accumulator, currentValue) =>
        Number(accumulator) + Number(currentValue.amount),
      0
    )
    setSearch({
      ...search,
      expense_summary: addCommas(roundToTwo(summary).toFixed(2)),
    })
  }

  const onClickRow = (record) => {
    setForm({
      id: record.id,
      amount: record.amount,
      payment_date: record.payment_date,
      remark: record.remark,
      expense_type_id: record.expense_type_id,
    })
  }

  const onDeleteExpenseItem = (item) => {
    dispatch(onDeleteExpense(item.id))
  }

  const onClearInput = () => {
    setForm(DEFAULT_FORM)
    setSearch(DEFAULT_SEARCH_LIST)
    setList([])
    setList([])
  }

  const componentRef = useRef(null)

  const onBeforeGetContentResolve = useRef(null)

  const [loading, setLoading] = useState(false)
  const [text, setText] = useState('old boring text')

  const handleAfterPrint = useCallback(() => {
    // console.log('`onAfterPrint` called') // tslint:disable-line no-console
  }, [])

  const handleBeforePrint = useCallback(() => {
    // console.log('`onBeforePrint` called') // tslint:disable-line no-console
  }, [])

  const handleOnBeforeGetContent = useCallback(() => {
    // console.log('`onBeforeGetContent` called') // tslint:disable-line no-console
    setLoading(true)
    setText('Loading new text...')

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve

      setTimeout(() => {
        setLoading(false)
        setText('New, Updated Text!')
        resolve()
      }, 2000)
    })
  }, [setLoading, setText])

  const reactToPrintContent = useCallback(() => {
    return componentRef.current
  }, [componentRef.current])

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: 'ExpensesPDF',
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  })

  useEffect(() => {
    if (
      text === 'New, Updated Text!' &&
      typeof onBeforeGetContentResolve.current === 'function'
    ) {
      onBeforeGetContentResolve.current()
    }
  }, [onBeforeGetContentResolve.current, text])

  return (
    <div className="no-print">
      <Spin spinning={loading}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'inline-block' }}>
            <h1>บันทึกค่าใช้จ่าย</h1>
          </div>
          <div style={{ display: 'inline-block' }}>
            <Popconfirm
              title="คุณต้องการล้างหน้าจอใช่หรือไม่ ?"
              okText="ใช่"
              cancelText="ยกเลิก"
              onConfirm={onClearInput}
            >
              <Button
                type="default"
                danger
                icon={<ClearOutlined />}
                size="large"
                block
              >
                ล้างหน้าจอ
              </Button>
            </Popconfirm>
          </div>
        </div>
        {/* <button onClick={() => console.log(form)}>FORM</button> */}
        <div className="form-wrapper">
          <Spin spinning={isLoading}>
            <div className="input-wrapper">
              <div className="input-item">
                <InlineInput
                  label="ประเภทค่าใช้จ่าย"
                  placeholder="เลือกประเภท"
                  required
                  value={form.expense_type_id}
                  onChange={(type_id) => {
                    setForm({ ...form, expense_type_id: type_id })
                  }}
                  width="150px"
                  inputOption="select_input"
                  options={type}
                />
              </div>
              <div className="input-item">
                <InlineInput
                  label="วันที่จ่าย"
                  placeholder="เลือกวัน"
                  defaultValue={moment()}
                  value={form.payment_date && moment(form.payment_date)}
                  inputOption="date_picker"
                  onChange={(_, dateString) => {
                    setForm({ ...form, payment_date: dateString })
                  }}
                />
              </div>
              <div className="input-item">
                <InlineInput
                  label="จำนวนเงิน"
                  width="150px"
                  value={form.amount}
                  onChange={(event) => {
                    if (Number(event.target.value) >= 0) {
                      setForm({ ...form, amount: event.target.value })
                    }
                  }}
                />
              </div>
            </div>
            <div className="input-wrapper">
              <div className="input-item">
                <InlineInput
                  label="หมายเหตุ"
                  width="680px"
                  value={form.remark}
                  onChange={(event) => {
                    setForm({ ...form, remark: event.target.value })
                  }}
                />
              </div>
              <div className="input-item">
                <Button
                  type="primary"
                  icon={<SaveOutlined />}
                  size="medium"
                  onClick={onClickSaveExpense}
                  style={{ width: '184px' }}
                  disabled={
                    Number(form.amount) < 0 || form.expense_type_id === null || form.amount === ''
                  }
                >
                  บันทึก
                </Button>
              </div>
            </div>
          </Spin>
          <Divider style={{ margin: '0px 0px 10px' }} />
          <div className="input-item">
            <InlineInput
              label="Passcode"
              width="150px"
              value={pass}
              inputOption="password"
              visibilityToggle={false}
              autoComplete="new-password"
              onChange={(event) => {
                setPass(event.target.value)
              }}
            />
          </div>
        </div>
        {pass === PASSCODE ? (
          <>
            <div className='form-wrapper mt-2-minus'>
              <div className="input-wrapper">
                <div className="input-item">
                  <InlineInput
                    label="ค้นหารายการเริ่มจากเดือน"
                    placeholder="เลือกเดือน/ปี"
                    defaultValue={moment()}
                    value={search.start_date && moment(search.start_date)}
                    inputOption="date_picker"
                    onChange={(_, dateString) => {
                      setSearch({ ...search, start_date: dateString })
                    }}
                    // pickerType="month"
                  />
                </div>
                <div className="input-item">
                  <InlineInput
                    label="ถึงเดือน"
                    placeholder="เลือกเดือน/ปี"
                    defaultValue={moment()}
                    value={search.end_date && moment(search.end_date)}
                    inputOption="date_picker"
                    onChange={(_, dateString) => {
                      setSearch({ ...search, end_date: dateString })
                    }}
                    // pickerType="month"
                  />
                </div>
                <div className="input-item">
                  <Button
                    type="primary"
                    icon={<SearchOutlined />}
                    size="medium"
                    onClick={onSearchClick}
                    style={{ width: '184px' }}
                  >
                    ค้นหา
                  </Button>
                </div>
              </div>
              <div className="input-wrapper">
                <div className="input-item">
                  <InlineInput
                    label="กรองประเภทค่าใช้จ่าย"
                    placeholder="เลือกประเภท"
                    value={filterTypeId}
                    onChange={(type_id) => {
                      setFilterTypeId(type_id)
                    }}
                    width="150px"
                    inputOption="select_input"
                    options={[{ id: null, name: 'แสดงทั้งหมด' }, ...type]}
                  />
                </div>
                <div className="input-item">
                  <InlineInput
                    label="กรองหมายเหตุ"
                    width="250px"
                    value={filterRemark}
                    onChange={(event) => {
                      setFilterRemark(event.target.value)
                    }}
                  />
                </div>
              </div>
              <div className="input-wrapper">
                <div className="input-item">
                  <InlineInput
                    className="text-align-right"
                    label="ยอดรวมค่าใช้จ่าย"
                    width="125px"
                    value={search.expense_summary}
                    disabled={true}
                    unit="บาท"
                  />
                </div>
                <div className="input-item">
                  <Button
                    type="primary"
                    icon={<PrinterOutlined />}
                    onClick={handlePrint}
                  >
                    พิมพ์รายงาน
                  </Button>
                </div>
              </div>
              <Spin spinning={isTableLoading}>
                <ListExpensesTable
                  list={filteredList}
                  onDelete={onDeleteExpenseItem}
                  onClickRow={onClickRow}
                />
              </Spin>
            </div>
            <div
              ref={componentRef}
              className="only-print"
              style={{ padding: '30px', width: '790px' }}
            >
              <ExpensesPDF
                list={filteredList}
                summary={search.expense_summary}
              />
            </div>
          </>
        ) : (
          ''
        )}
      </Spin>
    </div>
  )
}

export default withRouter(ExpensesPage)
