import React from 'react'
import { Table } from 'antd'
import { addCommas } from 'utils'
import moment from 'moment'
import 'moment/locale/th'
moment.locale('th')

const ProductTransitionHistoryTable = (props) => {
  const { historyList } = props
  const tableColumns = [
    {
      title: 'วันที่แก้ไข',
      dataIndex: 'created',
      key: 'created',
      width: '110px',
      sorter: (a, b) => ('' + a.created).localeCompare(b.created),
      sortDirections: ['ascend', 'descend'],
      fixed: 'left',
      render: (_, record) => {
        return moment(record.created).format('L LT')
      },
    },
    {
      title: 'รหัสต้นทาง',
      dataIndex: 'original_product_code',
      key: 'original_product_code',
      sorter: (a, b) =>
        ('' + a.original_product_code).localeCompare(b.original_product_code),
      sortDirections: ['ascend', 'descend'],
      width: '80px',
      fixed: 'left',
      render: (text) => <div className="break-word">{text}</div>,
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'original_product_name',
      key: 'original_product_name',
      width: '200px',
      fixed: 'left',
      render: (text) => <div className="break-word">{text}</div>,
    },
    {
      title: 'จำนวน',
      dataIndex: 'original_quantity',
      key: 'original_quantity',
      width: '70px',
      fixed: 'left',
      render: (text) => <div className="break-word text-align-right">{addCommas(text)}</div>,
    },
    {
      title: 'หน่วย',
      dataIndex: 'original_product_unit',
      key: 'original_product_unit',
      width: '70px',
      fixed: 'left',
      render: (text) => <div className="break-word">{text}</div>,
    },
    {
      title: 'รหัสปลายทาง',
      dataIndex: 'destination_product_code',
      key: 'destination_product_code',
      sorter: (a, b) =>
        ('' + a.destination_product_code).localeCompare(
          b.destination_product_code
        ),
      sortDirections: ['ascend', 'descend'],
      width: '80px',
      fixed: 'left',
      render: (text) => <div className="break-word">{text}</div>,
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'destination_product_name',
      key: 'destination_product_name',
      width: '200px',
      render: (text) => <div className="break-word">{text}</div>,
    },
    {
      title: 'จำนวน',
      dataIndex: 'destination_quantity',
      key: 'destination_quantity',
      width: '70px',
      render: (text) => <div className="break-word text-align-right">{addCommas(text)}</div>,
    },
    {
      title: 'หน่วย',
      dataIndex: 'destination_product_unit',
      key: 'destination_product_unit',
      width: '80px',
      render: (text) => <div className="break-word">{text}</div>,
    },
    {
      title: 'ราคาเข้าใหม่',
      dataIndex: 'destination_calculated_price',
      key: 'destination_calculated_price',
      width: '80px',
      fixed: 'left',
      render: (text) => <div className="break-word text-align-right">{addCommas(text)}</div>,
    },
  ]

  return (
    <div>
      <Table
        rowKey={(record) => `id+${record.id}`}
        dataSource={historyList}
        columns={tableColumns}
        pagination={{
          simple: true,
          defaultPageSize: 20,
          showSizeChanger: true,
          position: ['bottomRight'],
          pageSizeOptions: ['10', '20', '50', '100'],
        }}
        size="small"
        scroll={{ x: 1250 }}
        style={{
          maxWidth: '1300px',
          height: '740px',
          minHeight: '100%',
          backgroundColor: '#FFFFFF',
          marginTop: '10px',
        }}
      />
    </div>
  )
}

export default ProductTransitionHistoryTable
