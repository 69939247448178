import React, { useRef, useEffect } from 'react'
import { useReactToPrint } from 'react-to-print'
import { Row, Col } from 'antd'
import { StoreReportDataProvider } from './contexts/StoreReportDataContext'
import DatePickerSummaryReportStore from './components/DatePickerSummaryReportStore'
import OpenStoreTableReport from './components/OpenStoreTableReport'
import CloseStoreTableReport from './components/CloseStoreTableReport'
import CustomerBillTableReport from './components/CustomerBillTableReport'
import BillVatTableReport from './components/BillVatTableReport'
import AmendsTableReport from './components/AmendsTableReport'
import SummaryReportSection from './components/SummaryReportSection'
import SummaryReportPDF from './components/SummaryReportPDF'

const SummaryStoreReportPage = (props) => {
  const componentRef = useRef(null)
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Daily Store Report',
    removeAfterPrint: true,
  })
  useEffect(() => {
    document.title = 'สรุปรายงานเปิด-ปิดร้าน - NoVat'
  }, [])

  return (
    <StoreReportDataProvider>
      <h1>สรุปรายงานเปิด-ปิดร้าน</h1>
      <div className="form-wrapper">
        <DatePickerSummaryReportStore handlePrint={handlePrint} />
        <Row gutter={[32, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <OpenStoreTableReport />
            <CloseStoreTableReport />
            <CustomerBillTableReport isPrint={false} />
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <BillVatTableReport />
            <AmendsTableReport />
            <SummaryReportSection />
          </Col>
        </Row>
      </div>
      <div
        ref={componentRef}
        className="only-print"
        style={{ padding: '30px', width: '790px' }}
      >
        <SummaryReportPDF />
      </div>
    </StoreReportDataProvider>
  )
}

export default SummaryStoreReportPage
