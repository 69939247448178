/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Button, Spin } from 'antd'
import { UndoOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import InlineInput from 'components/InlineInput'
import DiscountInput from 'components/InlineInput/DiscountInput'
import MoveStockTable from 'components/MoveStockTable'

// import { getProductByCode } from 'api/products'
// import { listMoveStock, moveStock } from 'api/stock'
import { onGetProductByCode, onListMoveStock, onMoveStock } from 'redux/actions'
import { handleAlert } from 'utils'

const DEFAULT_PRODUCT = {
  code: '',
  name: '',
  package_size: '',
  stock1: 0,
  stock2: 0,
  stock_low: null,
  price_in_1: null,
  price_in_2: null,
  price_out_cash: null,
  price_out_atm: null,
  discount_cash_1: '0.00',
  discount_cash_2: '0.00',
  discount_cash_3: '0.00',
  discount_cash_4: '0.00',
  discount_atm_1: '0.00',
  discount_atm_2: '0.00',
  discount_atm_3: '0.00',
  discount_atm_4: '0.00',
  remark: '',
  unit_id: null,
  vat_type: null,
  movement_type: null,
  physical_stock_zone: null,
  physical_stock_shelf: null,
  product_group: null,
  use_new_price: null,
  is_allow_low: null,
  product_vat_code: '',
  product_vat_id: '',
  product_vat_name: '',
  product_vat_price_out: '',
}

const MoveStockPage = () => {
  const [form, setForm] = useState(DEFAULT_PRODUCT)
  const dispatch = useDispatch()
  // const moveStock = useSelector(state => state.moveStock)
  const moveStockList = useSelector((state) => state.listMoveStock)
  const moveStock = useSelector((state) => state.moveStock)
  const getProductByCode = useSelector((state) => state.getProductByCode)
  // const [moveStockList, setMoveStockList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    listMoveStockHandler()
    document.title = 'ย้ายสินค้าเข้าสต็อก - NoVat'
  }, [])

  useEffect(() => {
    setIsLoading(moveStockList.loading)
    if (moveStockList.error && !moveStockList.loading) {
      handleAlert('error', moveStockList.trace.message || '')
    }
    if (
      !moveStockList.error &&
      !moveStockList.loading &&
      moveStockList.finished
    ) {
      // setMoveStockList(response.data)
      // handleAlert('success')
      setForm(DEFAULT_PRODUCT)
    }
  }, [moveStockList])

  const onChangeInputValue = (value, name) => {
    if (errors[name] && value) {
      const tmp_errors = { ...errors }
      delete tmp_errors[name]
      setErrors(tmp_errors)
    }
    setForm({ ...form, [name]: value.toUpperCase() })
  }

  const onMoveStockHandler = () => {
    dispatch(onMoveStock(form))
  }

  useEffect(() => {
    setIsLoading(moveStock.loading)
    if (moveStock.finished && !moveStock.loading) {
      setForm(DEFAULT_PRODUCT)
      handleAlert('success')
      listMoveStockHandler()
    }
    if (!moveStock.loading && moveStock.error) {
      handleAlert('error', 'ไม่สามารถทำรายการได้ กรุณาลองอีกครั้ง')
    }
  }, [moveStock])

  const listMoveStockHandler = () => {
    dispatch(onListMoveStock())
  }

  const onSearchProductHandler = () => {
    dispatch(onGetProductByCode(form.code))
  }

  useEffect(() => {
    setIsLoading(getProductByCode.loading)
    if (
      getProductByCode.data &&
      !getProductByCode.loading &&
      getProductByCode.finished &&
      !getProductByCode.error
    ) {
      setForm(getProductByCode.data)
    }
    if (getProductByCode.error && !getProductByCode.loading) {
      let searchCode = form.code
      setForm({
        ...DEFAULT_PRODUCT,
        code: searchCode,
      })
      handleAlert('error', getProductByCode.trace.message || '')
    }
  }, [getProductByCode])

  const onClickProduct = (record) => {
    setForm(record)
  }

  return (
    <div>
      <h1>ย้ายสินค้าเข้าสต็อก</h1>
      <Spin spinning={isLoading}>
        <div className="form-wrapper">
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                onSearch={(value) => {
                  setForm({ ...form, code: value })
                  // setSearchCode(value)
                  onSearchProductHandler()
                }}
                inputOption="search"
                label="รหัสสินค้า"
                placeholder="รหัสสินค้า"
                isLoading={isLoading}
                required={true}
                value={form.code}
                onChange={(event) => {
                  onChangeInputValue(event.target.value, 'code')
                }}
                error={errors.code}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ชื่อสินค้า"
                width="300px"
                value={form.name}
                disabled={false}
                onChange={(event) =>
                  setForm({ ...form, name: event.target.value })
                }
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="ราคาขายส่ง"
                width="170px"
                value={form.price_out_atm}
                disabled={false}
                onChange={(event) =>
                  setForm({ ...form, price_out_atm: event.target.value })
                }
              />
            </div>
            <div className="input-item">
              <DiscountInput
                label="ส่วนลดราคาส่ง"
                name1="discount_atm_1"
                name2="discount_atm_2"
                name3="discount_atm_3"
                name4="discount_atm_4"
                value1={Number(form.discount_atm_1)}
                value2={Number(form.discount_atm_2)}
                value3={Number(form.discount_atm_3)}
                value4={Number(form.discount_atm_4)}
                onChange={(value, name) => setForm({ ...form, [name]: value })}
                width="100px"
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="ราคาขายปลีก"
                width="170px"
                value={form.price_out_cash}
                disabled={false}
                onChange={(event) =>
                  setForm({ ...form, price_out_cash: event.target.value })
                }
              />
            </div>
            <div className="input-item">
              <DiscountInput
                label="ส่วนลดราคาปลีก"
                name1="discount_cash_1"
                name2="discount_cash_2"
                name3="discount_cash_3"
                name4="discount_cash_4"
                value1={Number(form.discount_cash_1)}
                value2={Number(form.discount_cash_2)}
                value3={Number(form.discount_cash_3)}
                value4={Number(form.discount_cash_4)}
                onChange={(value, name) => setForm({ ...form, [name]: value })}
                width="100px"
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="ราคาขาย Vat"
                width="170px"
                value={form.product_vat_price_out}
                disabled={false}
                onChange={(event) =>
                  setForm({ ...form, product_vat_price_out: event.target.value })
                }
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="สินค้า Vat"
                width="90px"
                value={form.product_vat_code}
                disabled
              />
            </div>
            <div className="input-item">
              <InlineInput
                width="300px"
                value={form.product_vat_name}
                disabled
              />
            </div>
            <div className="input-item" style={{ marginLeft: 'auto' }}>
              <Button
                type="primary"
                icon={<UndoOutlined />}
                size="large"
                onClick={onMoveStockHandler}
              >
                ย้ายสต็อก
              </Button>
            </div>
          </div>
        </div>
        <MoveStockTable
          moveStockList={moveStockList.data}
          onClickProduct={onClickProduct}
        />
      </Spin>
    </div>
  )
}

export default MoveStockPage
