/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useRef } from 'react'
// import * as React from 'react'
import { useReactToPrint } from 'react-to-print'
import { Spin, Button, Checkbox, Input } from 'antd'
import { withRouter } from 'react-router-dom'
import {
  ReloadOutlined,
  ClearOutlined,
  PrinterOutlined,
} from '@ant-design/icons'

import ListStockLowTable from 'components/ListStockLowTable'
import StockLowPDF from 'components/StockLowPDF'
import { handleAlert } from 'utils'

import { onListProductWithLowStock } from 'redux/actions'
import { useSelector, useDispatch } from 'react-redux'

const Search = Input.Search

const ListProductWithStockLowPage = (props) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [temporaryList, setTemporaryList] = useState([])
  const [filteredList, setFilteredList] = useState([])
  const [renderList, setRenderList] = useState([])
  const [isChecked, setIsChecked] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [searchCode, setSearchCode] = useState('')
  const listProductWithLowStock = useSelector(
    (state) => state.listProductWithLowStock
  )

  useEffect(() => {
    dispatch(onListProductWithLowStock())
    document.title = 'รายการสินค้าน้อยกว่ากำหนด - NoVat'
  }, [])

  useEffect(() => {
    const { loading, error, data, finished, trace } = listProductWithLowStock
    setIsLoading(loading)
    if (!error && !loading && finished) {
      setTemporaryList(data)
      setFilteredList(data)
      setRenderList(data)
    }
    if (!loading && error) {
      handleAlert('error', trace.message || '')
    }
  }, [listProductWithLowStock])

  useEffect(() => {
    if (temporaryList.length > 0) {
      handleSearch(searchText)
    }
  }, [temporaryList, searchText])

  useEffect(() => {
    if (temporaryList.length > 0) {
      handleSearchCode(searchCode)
    }
  }, [temporaryList, searchCode])

  const onCheckDisplayMoreThanZero = (event) => {
    if (event.target.checked) {
      const _filteredData = [...filteredList]
      const NewList = _filteredData.filter(({ stock_low }) => {
        return stock_low > 0
      })
      setRenderList(NewList)
      setIsChecked(event.target.checked)
    } else {
      setRenderList(filteredList)
      setIsChecked(event.target.checked)
    }
  }

  const onRefreshList = () => {
    dispatch(onListProductWithLowStock())
  }

  const handleSearch = (searchText) => {
    const _temporaryList = [...temporaryList]
    const filteredData = _temporaryList.filter(({ name }) => {
      name = name.toString().toLowerCase()
      return (
        name.includes(searchText.toLowerCase())
      )
    })
    setFilteredList(filteredData)
    setRenderList(filteredData)
  }

  const handleSearchCode = (searchCode) => {
    const _temporaryList = [...temporaryList]
    const filteredData = _temporaryList.filter(({ code }) => {
      code = code.toString().toLowerCase()
      return (
        code.startsWith(searchCode.toLowerCase())
      )
    })
    setFilteredList(filteredData)
    setRenderList(filteredData)
  }

  const onClearSearch = () => {
    setRenderList(temporaryList)
    setFilteredList(temporaryList)
    setIsChecked(false)
    setSearchText('')
  }

  const componentRef = useRef(null)

  const onBeforeGetContentResolve = useRef(null)

  const [loading, setLoading] = useState(false)
  const [text, setText] = useState('old boring text')

  const handleAfterPrint = useCallback(() => {
    // console.log('`onAfterPrint` called') // tslint:disable-line no-console
  }, [])

  const handleBeforePrint = useCallback(() => {
    // console.log('`onBeforePrint` called') // tslint:disable-line no-console
  }, [])

  const handleOnBeforeGetContent = useCallback(() => {
    // console.log('`onBeforeGetContent` called') // tslint:disable-line no-console
    setLoading(true)
    setText('Loading new text...')

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve

      setTimeout(() => {
        setLoading(false)
        setText('New, Updated Text!')
        resolve()
      }, 2000)
    })
  }, [setLoading, setText])

  const reactToPrintContent = useCallback(() => {
    return componentRef.current
  }, [componentRef.current])

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: 'AwesomeFileName',
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  })

  useEffect(() => {
    if (
      text === 'New, Updated Text!' &&
      typeof onBeforeGetContentResolve.current === 'function'
    ) {
      onBeforeGetContentResolve.current()
    }
  }, [onBeforeGetContentResolve.current, text])

  return (
    <div className="no-print">
      <Spin spinning={loading}>
        <h1>รายการสินค้าน้อยกว่ากำหนด</h1>
        <div className="form-wrapper">
          <div className="input-wrapper">
            <div
              className="input-item"
              style={{ marginTop: '6px', height: '20px' }}
            >
              <Checkbox
                checked={isChecked}
                onChange={(event) => onCheckDisplayMoreThanZero(event)}
              >
                แสดงเฉพาะรายการที่กำหนดจำนวนต่ำสุดมากกว่า 0
              </Checkbox>
            </div>
            <div className="input-item">
              <Button
                type="primary"
                icon={<ReloadOutlined />}
                onClick={onRefreshList}
              >
                รีเฟรช
              </Button>
            </div>
            <div className="input-item">
              <Search
                value={searchCode}
                onChange={(event) => {
                  setSearchCode(event.target.value.toUpperCase())
                  setSearchText('')
                }}
                placeholder="ค้นรหัสสินค้า"
                onSearch={handleSearchCode}
                style={{ width: 130, marginRight: 10 }}
              />
              <Search
                value={searchText}
                onChange={(event) => {
                  setSearchText(event.target.value.toUpperCase())
                  setSearchCode('')
                }}
                placeholder="ค้นชื่อสินค้า"
                onSearch={handleSearch}
                style={{ width: 200, marginRight: 10 }}
              />
              <Button
                type="primary"
                icon={<ClearOutlined />}
                onClick={onClearSearch}
              >
                เคลียร์คำค้นหา
              </Button>
            </div>
            <div className="input-item">
              <Button
                type="primary"
                icon={<PrinterOutlined />}
                onClick={handlePrint}
              >
                พิมพ์รายงาน
              </Button>
            </div>
          </div>
          <Spin spinning={isLoading}>
            <ListStockLowTable listStockLow={renderList} />
          </Spin>
        </div>
        <div
          ref={componentRef}
          className="only-print"
          style={{ padding: '30px', width: '790px' }}
        >
          <StockLowPDF listStockLow={renderList} />
        </div>
      </Spin>
    </div>
  )
}

export default withRouter(ListProductWithStockLowPage)
