/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Button, Checkbox, Spin, Divider, Popconfirm, Modal } from 'antd'
import {
  SaveOutlined,
  ClearOutlined,
  PlusCircleOutlined,
  PrinterOutlined,
  SelectOutlined,
} from '@ant-design/icons'
import moment from 'moment'

import InlineInput from 'components/InlineInput'
import DiscountInput from 'components/InlineInput/DiscountInput'
import CustomerBillTable from 'components/CustomerBillTable'
import BillCompareStockModal from 'components/BillCompareStockModal'
import SearchProductNoVatPopUp from 'components/SearchProductNoVatPopUp'
import SearchCustomerPopUp from 'components/SearchCustomerPopUp'
import SearchCustomerBillPopUp from 'components/SearchCustomerBillPopUp'
import BillPaymentModal from './components/BillPaymentModal'

import { useDispatch, useSelector } from 'react-redux'
import {
  onListBillType,
  onListSaleChannel,
  onGetCustomerBill,
  onGetLatestBoughtProductOfCustomer,
  onSaveCustomerBill,
  onGetCustomerByCode,
  onGetCustomerType,
} from 'redux/actions'
import { handleAlert, useQuery, PAYMENT_METHODS } from 'utils'

const DEFAULT_FORM = {
  customer_id: '',
  customer_code: '',
  customer_name: '',
  customer_type_id: null,
  bill_no: '',
  bill_date: moment().format('YYYY-MM-DD'),
  bill_type_id: null,
  bill_type_name: '',
  bill_type_abbr: '',
  bill_type_value: '',
  is_included_vat: false,
  sale_channel_id: null,
  sale_channel_name: '',
  sale_channel_abbr: '',
  special_discount_baht: 0,
  special_discount_percent: 0,
  total_before_special_discount: '',
  total_after_special_discount: '',
  grand_total: '',
  total_items: '',
  remark: '',
  payment_method_id: null,
  payment_method: '',
  products: [],
}

const DEFAULT_PRODUCT = {
  id: 0,
  code: '',
  name: '',
  stock1: null,
  unit_id: null,
  unit_name: '',
  quantity: null,
  lastest_quantity: null,
  old_price_out_atm: null,
  old_price_out_cash: null,
  price_out_atm: null,
  price_out_cash: null,
  price_out: null,
  old_price_out: null,
  discount_1: '',
  discount_2: '',
  discount_3: '',
  discount_4: '',
  total: '',
  ramark: '',
  last_purchased: '',
  product_vat_type_name: '',
  net: '0.00',
  wholesale_quantity: '',
}

const CustomerBillPage = (props) => {
  function useAsyncState(initialValue) {
    const [value, setValue] = useState(initialValue)
    const setter = (x) =>
      new Promise((resolve) => {
        setValue(x)
        resolve(x)
      })
    return [value, setter]
  }
  const query = useQuery()
  const bill_no = query.get('bill_no')
  const [form, setForm] = useAsyncState(DEFAULT_FORM)
  const [product, setProduct] = useAsyncState(DEFAULT_PRODUCT)
  const [isLoading, setIsLoading] = useState(false)
  const [customerType, setCustomerType] = useState([])
  const [billType, setBillType] = useState([])
  const [saleChannel, setSaleChannel] = useState([])
  const [isShowModal, setIsShowModal] = useState(false)
  const [disableDiscountBaht, setDisableDiscountBaht] = useState(false)
  const [disableDiscountPercent, setDisableDiscountPercent] = useState(false)
  const [isShowProductPopUp, setIsShowProductPopUp] = useState(false)
  const [isShowCustomerPopUp, setIsShowCustomerPopUp] = useState(false)
  const [isShowBilltPopUp, setIsShowBillPopUp] = useState(false)
  const [printPopUp, setPrintPopUp] = useState(false)
  const [isShowPaymentPopUp, setIsShowPaymentPopUp] = useState(false)
  const dispatch = useDispatch()
  const getCustomerType = useSelector((state) => state.getCustomerType)
  const listBillType = useSelector((state) => state.listBillType)
  const getCustomerByCode = useSelector((state) => state.getCustomerByCode)
  const listSaleChannel = useSelector((state) => state.listSaleChannel)
  const getCustomerBill = useSelector((state) => state.getCustomerBill)
  const getLatestBoughtProductOfCustomer = useSelector(
    (state) => state.getLatestBoughtProductOfCustomer
  )
  const saveCustomerBill = useSelector((state) => state.saveCustomerBill)

  useEffect(() => {
    dispatch(onGetCustomerType())
    dispatch(onListBillType())
    dispatch(onListSaleChannel())
    document.title = 'เปิด-แก้ไขบิล - NoVat'
  }, [])

  useEffect(() => {
    setIsLoading(true)
    if (bill_no) {
      dispatch(onGetCustomerBill(bill_no))
    }
  }, [bill_no, props.history])

  useEffect(() => {
    const { error, data, trace, loading, finished } = listBillType
    setIsLoading(loading)
    if (!loading && !error && finished) {
      setBillType(data)
    }
    if (!loading && error) {
      handleAlert('custom', trace.message ? trace.message : '')
    }
  }, [listBillType])

  useEffect(() => {
    const { loading, error, data, trace, finished } = getCustomerType
    setIsLoading(loading)
    if (!loading && !error && finished) {
      setCustomerType(data)
    }
    if (error && !loading) {
      handleAlert('custom', trace.message ? trace.message : '')
    }
  }, [getCustomerType])

  useEffect(() => {
    const { error, loading, finished, data, trace } = listSaleChannel
    setIsLoading(loading)
    if (!loading && !error && finished) {
      setSaleChannel(data)
    }
    if (!loading && error) {
      handleAlert('custom', trace.message ? trace.message : '')
    }
  }, [listSaleChannel])

  useEffect(() => {
    const { error, loading, finished, data, trace } = getCustomerByCode
    setIsLoading(loading)
    if (!error && !loading && finished) {
      handleAlert('success')
      setForm({
        ...form,
        customer_id: data.id,
        customer_code: data.code,
        customer_name: data.name,
        customer_type_id: data.customer_type_id,
      })
    }
    if (error && !loading) {
      handleAlert('custom', trace.message ? trace.message : '')
      setForm({
        ...form,
        customer_id: '',
        customer_name: '',
        customer_type_id: null,
      })
    }
  }, [getCustomerByCode])

  const renderCustomerType = () => {
    let unitName = ''
    customerType.forEach((x, index) => {
      if (x.id === form.customer_type_id) {
        unitName = x.name
      }
    })
    return (
      <div className="input-item">
        <InlineInput
          label="ประเภทลูกค้า"
          width="130px"
          value={unitName}
          disabled={true}
        />
      </div>
    )
  }
  const onVatChecked = (event) => {
    let new_grand_total = Number(form.grand_total)
    if (event.target.checked) {
      new_grand_total *= 1.07
      setForm({
        ...form,
        is_included_vat: true,
        grand_total: roundToTwo(new_grand_total).toFixed(2),
      })
    } else {
      new_grand_total /= 1.07
      setForm({
        ...form,
        is_included_vat: false,
        grand_total: roundToTwo(new_grand_total).toFixed(2),
      })
    }
  }

  const onChangeBillType = (bill_id, _form) => {
    let bill_type_id = ''
    let bill_type_name = ''
    let bill_type_abbr = ''
    let bill_type_value = ''
    billType.forEach((x) => {
      if (x.id === bill_id) {
        bill_type_id = x.id
        bill_type_name = x.name
        bill_type_abbr = x.abbr_for_bill
        bill_type_value = x.value
      }
    })
    setForm({
      ..._form,
      bill_type_id,
      bill_type_name,
      bill_type_abbr,
      bill_type_value,
    })
    setProduct(DEFAULT_PRODUCT)
  }

  const onChangeMethodType = (method_id, _form) => {
    let payment_method_id = null
    let payment_method = ''
    PAYMENT_METHODS.forEach((x) => {
      if (x.id === method_id) {
        payment_method_id = x.id
        payment_method = x.value
      }
    })
    setForm({
      ..._form,
      payment_method_id,
      payment_method,
    })
  }

  const onChangeSaleChannel = (channel_id) => {
    let sale_channel_id = ''
    let sale_channel_name = ''
    let sale_channel_abbr = ''
    saleChannel.forEach((x) => {
      if (x.id === channel_id) {
        sale_channel_id = x.id
        sale_channel_name = x.name
        sale_channel_abbr = x.abbr_for_bill
      }
    })
    setForm({
      ...form,
      sale_channel_id,
      sale_channel_name,
      sale_channel_abbr,
    })
  }

  useEffect(() => {
    const { error, loading, trace, data, finished } = getCustomerBill
    setIsLoading(loading)
    if (!error && !loading && finished) {
      setForm(data).then((form) => {
        initialSpecialDiscount(form)
      })
      setProduct(DEFAULT_PRODUCT)
    }
    if (!loading && error) {
      handleAlert('custom', trace.message ? trace.message : '')
    }
  }, [getCustomerBill])

  useEffect(() => {
    const { error, loading, finished, trace, data } =
      getLatestBoughtProductOfCustomer
    if (!error && !loading && finished) {
      onSetPriceOut(form.bill_type_value.toLowerCase(), data)
    }
    if (error && !loading) {
      handleAlert('custom', `ไม่สามารถทำรายการได้ - ${trace.message}`)
    }
  }, [getLatestBoughtProductOfCustomer])

  const onSetPriceOut = (bill_type, data) => {
    let old_discount = `${
      Number(data.old_discount_1) > 0 ? data.old_discount_1 : '00.00'
    }+${Number(data.old_discount_2) > 0 ? data.old_discount_2 : '00.00'}+${
      Number(data.old_discount_3) > 0 ? data.old_discount_3 : '00.00'
    }+${Number(data.old_discount_4) > 0 ? data.old_discount_4 : '00.00'} `
    if (bill_type === 'atm') {
      setProduct({
        ...data,
        price_out: data.price_out_atm,
        old_price_out: data.old_price_out_atm,
        lastest_quantity: data.quantity,
        old_discount: old_discount,
        quantity: '',
        remark: '',
        id: 0,
        discount_1: data.discount_atm_1,
        discount_2: data.discount_atm_2,
        discount_3: data.discount_atm_3,
        discount_4: data.discount_atm_4,
      }).then((_product) => {
        onCalculateNet(_product)
      })
    } else if (bill_type === 'cash') {
      setProduct({
        ...data,
        price_out: data.price_out_cash,
        old_price_out: data.old_price_out_cash,
        lastest_quantity: data.quantity,
        old_discount: old_discount,
        quantity: '',
        remark: '',
        id: 0,
        discount_1: data.discount_cash_1,
        discount_2: data.discount_cash_2,
        discount_3: data.discount_cash_3,
        discount_4: data.discount_cash_4,
      }).then((_product) => {
        onCalculateNet(_product)
      })
    } else {
      setProduct({
        ...data,
        price_out: '',
        old_price_out: '',
        lastest_quantity: data.quantity,
        old_discount: old_discount,
        quantity: '',
        remark: '',
        id: 0,
        discount_1: '',
        discount_2: '',
        discount_3: '',
        discount_4: '',
      }).then((_product) => {
        onCalculateNet(_product)
      })
    }
  }

  const onQuantityChange = (new_quantity) => {
    let new_price_out = ''
    if (
      Number(product.wholesale_quantity) > 0 &&
      Number(new_quantity) >= Number(product.wholesale_quantity) &&
      form.bill_type_id === 2
    ) {
      new_price_out = product.price_out_atm
    } else if (
      Number(product.wholesale_quantity) > 0 &&
      Number(new_quantity) < Number(product.wholesale_quantity) &&
      form.bill_type_id === 2
    ) {
      new_price_out = product.price_out_cash
    } else {
      new_price_out = product.price_out
    }
    if (
      Number(new_quantity) > Number(product.stock1) &&
      product.stock1 !== null
    ) {
      setIsShowModal(true)
      setProduct({
        ...product,
        quantity: new_quantity,
        price_out: new_price_out,
      })
    } else {
      setProduct({
        ...product,
        quantity: new_quantity,
        price_out: new_price_out,
      })
    }
  }

  const onCancleStockModal = () => {
    setProduct({
      ...product,
      quantity: '',
    })
    setIsShowModal(false)
  }

  const onSubmitStockModal = (new_quantity) => {
    let new_price_out = ''
    if (
      Number(product.wholesale_quantity) > 0 &&
      Number(new_quantity) >= Number(product.wholesale_quantity) &&
      form.bill_type_id === 2
    ) {
      new_price_out = product.price_out_atm
    } else if (
      Number(product.wholesale_quantity) > 0 &&
      Number(new_quantity) < Number(product.wholesale_quantity) &&
      form.bill_type_id === 2
    ) {
      new_price_out = product.price_out_cash
    } else {
      new_price_out = product.price_out
    }
    setProduct({
      ...product,
      quantity: new_quantity,
      price_out: new_price_out,
    }).then((_product) => {
      addProductToList(_product)
      setIsShowModal(false)
    })
  }

  const roundToTwo = (num) => +(Math.round(num + 'e+2') + 'e-2')

  const onCalculateNet = (_product) => {
    let net = Number(_product.price_out)
    net *= Number(_product.discount_1)
      ? 1 - Number(_product.discount_1) / 100
      : 1
    net *= Number(_product.discount_2)
      ? 1 - Number(_product.discount_2) / 100
      : 1
    net *= Number(_product.discount_3)
      ? 1 - Number(_product.discount_3) / 100
      : 1
    net *= Number(_product.discount_4)
      ? 1 - Number(_product.discount_4) / 100
      : 1
    setProduct({
      ..._product,
      net: roundToTwo(net).toFixed(2),
    })
  }

  const sumProductTotal = (_product) => {
    let total = 0
    total = Number(_product.quantity) * Number(_product.price_out)
    total *= Number(_product.discount_1)
      ? 1 - Number(_product.discount_1) / 100
      : 1
    total *= Number(_product.discount_2)
      ? 1 - Number(_product.discount_2) / 100
      : 1
    total *= Number(_product.discount_3)
      ? 1 - Number(_product.discount_3) / 100
      : 1
    total *= Number(_product.discount_4)
      ? 1 - Number(_product.discount_4) / 100
      : 1
    return roundToTwo(total).toFixed(2)
  }

  const addProductToList = (_product) => {
    let isDuplicatedProduct = false
    form.products.forEach((x) => {
      if (x.code === _product.code) {
        return (isDuplicatedProduct = true)
      }
    })
    if (!isDuplicatedProduct) {
      if (Number(_product.quantity) > 0 && Number(_product.price_out)) {
        let sumTotal = 0
        sumTotal = sumProductTotal(_product)
        setProduct({
          ..._product,
          total: sumTotal,
        }).then((product) => {
          const oldProduct = form.products
          setForm({
            ...form,
            total_items: oldProduct.length + 1,
            products: [...oldProduct, product],
          })
            .then((form) => {
              onChangeCalCulatedValue(form)
            })
            .then(() => setProduct(DEFAULT_PRODUCT))
        })
      } else {
        handleAlert('custom', 'กรุณากรอกจำนวนสินค้า หรือราคาให้ครบถ้วน')
      }
    } else {
      handleAlert(
        'custom',
        'มีสินค้านี้อยู่ในรายการอยู่แล้ว กรุณาเพิ่มสินค้าใหม่'
      )
      setProduct(DEFAULT_PRODUCT)
    }
  }

  const onChangeCalCulatedValue = (form) => {
    let grandTotal = 0
    let sumBeforeDiscount = 0
    let sumAfterDiscount = 0
    let sumOfAllProduct = form.products.reduce(
      (accumulator, currentValue) =>
        Number(accumulator) + Number(currentValue.total),
      0
    )
    sumBeforeDiscount = sumOfAllProduct

    if (
      Number(form.special_discount_baht) > 0 &&
      Number(form.special_discount_percent) === 0
    ) {
      sumAfterDiscount = sumBeforeDiscount - Number(form.special_discount_baht)
    } else if (
      Number(form.special_discount_percent) > 0 &&
      Number(form.special_discount_baht) === 0
    ) {
      sumAfterDiscount =
        sumBeforeDiscount * (1 - Number(form.special_discount_percent) / 100)
    } else {
      sumAfterDiscount = sumBeforeDiscount
    }

    grandTotal = sumAfterDiscount * (form.is_included_vat ? 1.07 : 1)
    setForm({
      ...form,
      total_before_special_discount: roundToTwo(sumBeforeDiscount).toFixed(2),
      total_after_special_discount: roundToTwo(sumAfterDiscount).toFixed(2),
      grand_total: roundToTwo(grandTotal).toFixed(2),
    })
  }

  const onEditTableData = (row, key, value) => {
    let newProducts = []
    form.products.forEach((x) => {
      if (x.id === row.id && x.code === row.code) {
        x[key] = value
        if (key !== 'remark') {
          x.total = sumProductTotal(x)
        }
        return newProducts.push(x)
      } else {
        return newProducts.push(x)
      }
    })
    setForm({
      ...form,
      products: newProducts,
    }).then((form) => {
      onChangeCalCulatedValue(form)
    })
  }

  const onDeleteProductFromBill = (row) => {
    let tempProduct = form.products
    setIsLoading(true)
    tempProduct = tempProduct.filter((x) => x.code !== row.code)
    setIsLoading(false)
    setForm({
      ...form,
      total_items: tempProduct.length,
      products: [...tempProduct],
    }).then((form) => {
      onChangeCalCulatedValue(form)
    })
  }

  const initialSpecialDiscount = (_form) => {
    if (Number(_form.special_discount_baht) > 0) {
      setDisableDiscountPercent(true)
    } else if (Number(_form.special_discount_percent) > 0) {
      setDisableDiscountBaht(true)
    } else {
      setDisableDiscountPercent(false)
      setDisableDiscountBaht(false)
    }
    initialPaymentMethod(_form)
  }

  const initialPaymentMethod = (_form) => {
    if (_form.payment_method === '') {
      setForm({
        ..._form,
        payment_method_id: null,
      }).then((newForm) => {
        onChangeBillType(newForm.bill_type_id, newForm)
      })
    } else {
      PAYMENT_METHODS.forEach((x) => {
        if (_form.payment_method === x.value) {
          setForm({
            ..._form,
            payment_method_id: x.id,
          }).then((newForm) => {
            onChangeBillType(newForm.bill_type_id, newForm)
          })
        }
      })
    }
  }

  const onSpecialDiscountChange = (value, name) => {
    if (name === 'baht' && Number(value) > 0) {
      setDisableDiscountPercent(true)
      setForm({
        ...form,
        special_discount_percent: '',
      })
    } else if (name === 'percent' && Number(value) > 0) {
      setDisableDiscountBaht(true)
      setForm({
        ...form,
        special_discount_bath: '',
      })
    } else if (Number(value) === 0) {
      setDisableDiscountBaht(false)
      setDisableDiscountPercent(false)
      setForm({
        ...form,
        special_discount_bath: '',
        special_discount_percent: '',
      })
    }
  }

  useEffect(() => {
    const { error, loading, finished, data, trace } = saveCustomerBill
    setIsLoading(loading)
    if (!error && !loading && finished) {
      setForm(data).then((form) => {
        initialPaymentMethod(form)
      })
      setPrintPopUp(true)
    }
    if (error && !loading) {
      Modal.error({
        centered: true,
        title: 'ตรวจสอบ',
        content: `${trace.message}`,
      })
    }
  }, [saveCustomerBill])

  const onSaveBill = () => {
    if (
      form.customer_code &&
      form.bill_type_id &&
      form.sale_channel_id &&
      form.bill_date
    ) {
      dispatch(onSaveCustomerBill(form))
    } else {
      handleAlert('custom', 'กรุณากรอกข้อมูลที่มีเครื่องหมาย * ให้ครบถ้วน')
    }
  }

  const onSearchProduct = (value) => {
    if (form.customer_code && form.bill_type_value && form.sale_channel_id) {
      if (value) {
        dispatch(
          onGetLatestBoughtProductOfCustomer(
            value,
            form.customer_code,
            form.bill_type_value
          )
        )
      } else {
        setIsShowProductPopUp(true)
      }
    } else {
      handleAlert(
        'custom',
        'กรุณากรอกรหัสลูกค้า ประเภทบิล ช่องทาง และรหัสสินค้าให้ครบถ้วน'
      )
    }
  }

  const onCancelProdutPopUp = () => {
    setIsShowProductPopUp(false)
  }

  const onSelectProductPopUp = (data) => {
    onSearchProduct(data.code)
    setIsShowProductPopUp(false)
  }

  const onSearchCustomerCode = (code) => {
    if (code !== '') {
      dispatch(onGetCustomerByCode(code))
    } else {
      setIsShowCustomerPopUp(true)
    }
  }

  const onSelectCustomerPopUp = (data) => {
    dispatch(onGetCustomerByCode(data.code))
    setIsShowCustomerPopUp(false)
  }

  const onCancelCustomerPopUp = () => {
    setIsShowCustomerPopUp(false)
  }

  const onSearchCustomerBill = (value) => {
    if (value) {
      dispatch(onGetCustomerBill(value))
    } else {
      setIsShowBillPopUp(true)
    }
  }

  const onCancelBillPopUp = () => {
    setIsShowBillPopUp(false)
  }

  const onCancelPaymentPopUp = () => {
    setIsShowPaymentPopUp(false)
  }

  const onSelectBillPopUp = (data) => {
    onSearchCustomerBill(data.bill_no)
    setIsShowBillPopUp(false)
  }

  const onClearInput = () => {
    setForm(DEFAULT_FORM)
    setProduct(DEFAULT_PRODUCT)
    setDisableDiscountBaht(false)
    setDisableDiscountPercent(false)
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'inline-block' }}>
          <h1>เปิด-แก้ไขบิล</h1>
        </div>
        <div style={{ display: 'inline-block' }}>
          <Popconfirm
            title="คุณต้องการล้างหน้าจอใช่หรือไม่ ?"
            okText="ใช่"
            cancelText="ยกเลิก"
            onConfirm={onClearInput}
          >
            <Button
              type="default"
              danger
              icon={<ClearOutlined />}
              size="large"
              block
            >
              ล้างหน้าจอ
            </Button>
          </Popconfirm>
        </div>
      </div>
      {/* <button onClick={() => console.log(form)}>Form</button> */}
      <SearchCustomerPopUp
        visible={isShowCustomerPopUp}
        onCancel={onCancelCustomerPopUp}
        onClick={onSelectCustomerPopUp}
      />
      <SearchProductNoVatPopUp
        visible={isShowProductPopUp}
        onCancel={onCancelProdutPopUp}
        onClick={onSelectProductPopUp}
      />
      <SearchCustomerBillPopUp
        visible={isShowBilltPopUp}
        onCancel={onCancelBillPopUp}
        onClick={onSelectBillPopUp}
        customer_name={form.customer_name}
      />
      <BillCompareStockModal
        productCode={product.code}
        stock={product.stock1}
        quantity={product.quantity}
        visible={isShowModal}
        onCancleStockModal={onCancleStockModal}
        onSubmitStockModal={onSubmitStockModal}
        handleAlert={handleAlert}
      />
      <BillPaymentModal
        visible={isShowPaymentPopUp}
        onCancel={onCancelPaymentPopUp}
        bill_no={form.bill_no || ''}
        bill_grand_total={form.grand_total || ''}
      />
      <Modal
        width="500px"
        centered
        visible={printPopUp}
        onCancel={() => {
          setPrintPopUp(false)
        }}
        onOk={() => {
          setPrintPopUp(false)
          window.open(
            `/no-vat/customer-bill/print?bill_no=${form.bill_no}`,
            '_blank'
          )
        }}
        title="บันทึกบิลสำเร็จ"
      >
        <div>คุณต้องการพิมพ์ใบเสร็จใช่หรือไม่ ?</div>
      </Modal>
      <Spin spinning={isLoading}>
        <div className="form-wrapper">
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                onSearch={(value) => {
                  onSearchCustomerCode(value)
                }}
                inputOption="search"
                label="รหัสลูกค้า"
                placeholder="รหัสลูกค้า"
                isLoading={false}
                required
                value={form.customer_code}
                onChange={(event) => {
                  setForm({
                    ...form,
                    customer_id: '',
                    customer_name: '',
                    customer_type_id: null,
                    customer_code: event.target.value.toUpperCase(),
                  })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ชื่อลูกค้า"
                width="300px"
                required
                value={form.customer_name}
                disabled={true}
              />
            </div>
            {renderCustomerType()}
            <div
              className="input-item"
              style={{ marginTop: '6px', height: '20px' }}
            >
              <Checkbox checked={form.is_included_vat} onChange={onVatChecked}>
                Vat
              </Checkbox>
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="ประเภทบิล"
                placeholder="เลือกประเภทบิล"
                required
                value={form.bill_type_id}
                onChange={(bill_id) => {
                  onChangeBillType(bill_id, form)
                }}
                width="100px"
                inputOption="select_input"
                options={billType}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ช่องทาง"
                placeholder="เลือกช่องทาง"
                required
                value={form.sale_channel_id}
                onChange={(channel_id) => {
                  onChangeSaleChannel(channel_id)
                }}
                width="120px"
                inputOption="select_input"
                options={saleChannel}
              />
            </div>
            <div className="input-item">
              <InlineInput
                onSearch={(value) => {
                  onSearchCustomerBill(value)
                }}
                inputOption="search"
                label="เลขที่บิล"
                placeholder="เลขที่บิล"
                isLoading={false}
                value={form.bill_no}
                onChange={(event) => {
                  setForm({
                    ...form,
                    bill_no: event.target.value.toUpperCase(),
                  })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="วันที่เปิดบิล"
                placeholder="กรุณาเลือกวันที่"
                required
                defaultValue={moment()}
                value={form.bill_date && moment(form.bill_date)}
                inputOption="date_picker"
                onChange={(_, dateString) => {
                  setForm({ ...form, bill_date: dateString })
                }}
              />
            </div>
          </div>
          <Divider style={{ margin: '0px 0px 10px' }} />
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                onSearch={(value) => {
                  onSearchProduct(value)
                }}
                inputOption="search"
                label="รหัสสินค้า"
                placeholder="รหัสสินค้า"
                width="180px"
                isLoading={false}
                value={product.code}
                onChange={(event) => {
                  setProduct({
                    ...DEFAULT_FORM,
                    code: event.target.value.toUpperCase(),
                  })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ชื่อสินค้า"
                width="440px"
                value={product.name}
                disabled={true}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ขนาดบรรจุ"
                width="120px"
                value={product.package_size}
                disabled={true}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="Vat"
                width="80px"
                value={product.product_vat_type_name}
                disabled={true}
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="จำนวน"
                width="100px"
                required
                value={product.quantity}
                onChange={(event) => {
                  onQuantityChange(event.target.value)
                  // setProduct({ ...product, quantity: event.target.value })
                }}
              />
            </div>
            <div className="input-item" style={{ marginTop: '5px' }}>
              {product.unit_name}
            </div>
            <div className="input-item">
              <InlineInput
                label="ราคาปัจจุบัน"
                width="100px"
                required
                value={product.price_out}
                onChange={(event) => {
                  setProduct({
                    ...product,
                    price_out: event.target.value,
                  }).then((_product) => {
                    onCalculateNet(_product)
                  })
                }}
              />
            </div>
            <div
              className="input-item"
              style={{ marginTop: '5px', color: '#0000FF' }}
            >
              <strong>
                สุทธิ: {Number(product.net) > 0 ? product.net : '0.00'}
              </strong>
            </div>
            <div className="input-item">
              <DiscountInput
                label="ส่วนลด"
                name1="discount_1"
                name2="discount_2"
                name3="discount_3"
                name4="discount_4"
                value1={product.discount_1}
                value2={product.discount_2}
                value3={product.discount_3}
                value4={product.discount_4}
                onChange={(value, name) =>
                  setProduct({ ...product, [name]: value }).then((_product) => {
                    onCalculateNet(_product)
                  })
                }
                width="150px"
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="หมายเหตุ"
                width="300px"
                value={product.remark}
                onChange={(event) => {
                  setProduct({ ...product, remark: event.target.value })
                }}
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item" style={{ marginTop: '5px' }}>
              ปริมาณขั้นต่ำ:{' '}
              {Number(product.wholesale_quantity) > 0
                ? product.wholesale_quantity
                : 'ยังไม่กำหนด'}
            </div>
            <div className="input-item" style={{ marginTop: '5px' }}>
              <strong>
                ราคาเก่า:{' '}
                {Number(product.old_price_out) > 0
                  ? product.old_price_out
                  : '0.00'}
              </strong>
            </div>
            <div className="input-item" style={{ marginTop: '5px' }}>
              ส่วนลด: {product.old_discount}
            </div>
            <div className="input-item">
              <InlineInput
                label="วันที่ซื้อล่าสุด"
                placeholder={
                  product.last_purchased === 'ไม่มีประวัติการซื้อ'
                    ? 'ไม่มีประวัติการซื้อ'
                    : ''
                }
                defaultValue={moment()}
                value={
                  product.last_purchased &&
                  product.last_purchased !== 'ไม่มีประวัติการซื้อ'
                    ? moment(product.last_purchased)
                    : ''
                }
                inputOption="date_picker"
                disabled
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="รายละเอียดสินค้า"
                width="300px"
                value={product.product_remark}
                disabled
              />
            </div>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              size="large"
              style={{ margin: '0px 10px 0px 50px' }}
              onClick={() => addProductToList(product)}
            >
              เพิ่มรายการ
            </Button>
          </div>
          <Divider style={{ margin: '0px 0px 10px' }} />
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="จำนวนในรายการ"
                width="50px"
                value={form.total_items}
                disabled
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="วันที่แก้ไขล่าสุด"
                placeholder="YYYY/MM/DD"
                defaultValue={moment()}
                value={form.updated_at && moment(form.updated_at)}
                disabled
                inputOption="date_picker"
              />
            </div>
          </div>
          <div style={{ background: '#f1f1f1', marginBottom: '20px' }}>
            <CustomerBillTable
              productList={form.products}
              onChange={onEditTableData}
              onDelete={onDeleteProductFromBill}
            />
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '30%', display: 'inline-block' }}>
              <div className="input-wrapper">
                <div className="input-item">
                  <InlineInput
                    className="text-align-right"
                    label="ส่วนลดพิเศษ"
                    width="100px"
                    value={form.special_discount_baht}
                    unit="บาท"
                    disabled={disableDiscountBaht}
                    onChange={(event) => {
                      onSpecialDiscountChange(event.target.value, 'baht')
                      setForm({
                        ...form,
                        special_discount_baht: event.target.value,
                      }).then((form) => onChangeCalCulatedValue(form))
                    }}
                  />
                </div>
                <div className="input-item">
                  <InlineInput
                    className="text-align-right"
                    width="60px"
                    value={form.special_discount_percent}
                    unit="%"
                    disabled={disableDiscountPercent}
                    disableColon={true}
                    onChange={(event) => {
                      onSpecialDiscountChange(event.target.value, 'percent')
                      setForm({
                        ...form,
                        special_discount_percent: event.target.value,
                      }).then((form) => onChangeCalCulatedValue(form))
                    }}
                  />
                </div>
              </div>
              <div className="input-wrapper">
                <div className="input-item">
                  <InlineInput
                    label="หมายเหตุบิล"
                    width="300px"
                    value={form.remark}
                    onChange={(event) => {
                      setForm({
                        ...form,
                        remark: event.target.value,
                      })
                    }}
                  />
                </div>
              </div>
              <div className="input-wrapper">
                <div className="input-item">
                  <InlineInput
                    label="ช่องทางชำระเงิน"
                    placeholder="ไม่ระบุ"
                    value={form.payment_method_id}
                    onChange={(method_id) => {
                      onChangeMethodType(method_id, form)
                    }}
                    width="120px"
                    inputOption="select_input"
                    options={[
                      { id: null, name: '-', value: '' },
                      ...PAYMENT_METHODS,
                    ]}
                  />
                </div>
              </div>
            </div>
            <div style={{ width: '40%', display: 'inline-block' }}>
              <div className="input-wrapper">
                <div className="input-item margin-left-auto">
                  <InlineInput
                    className="text-align-right"
                    label="ยอดก่อนหักส่วนลดพิเศษ"
                    width="180px"
                    value={form.total_before_special_discount}
                    disabled
                  />
                </div>
                <div className="input-item margin-left-auto">
                  <InlineInput
                    className="text-align-right"
                    label="ยอดหลังหักส่วนลดพิเศษ"
                    width="180px"
                    disabled
                    value={form.total_after_special_discount}
                  />
                </div>
                <div className="input-item margin-left-auto">
                  <InlineInput
                    className="text-align-right"
                    label="ยอดรวมภาษ๊"
                    width="180px"
                    disabled
                    value={form.grand_total}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                width: '15%',
                marginLeft: '10px',
                marginRight: '20px',
                display: 'inline-block',
              }}
            >
              <Button
                type="primary"
                icon={<SaveOutlined />}
                size="large"
                block
                style={{ height: '80px' }}
                onClick={onSaveBill}
              >
                บันทึก/แก้ไขบิล
              </Button>
              <Button
                type="default"
                icon={<PrinterOutlined />}
                size="large"
                style={{ height: '50px', marginTop: '20px' }}
                block
                onClick={() => {
                  if (form.bill_no !== '') {
                    setPrintPopUp(true)
                  } else {
                    Modal.warning({
                      centered: true,
                      title: 'ตรวจสอบ',
                      content: 'กรุณาค้นหาบิลก่อนสั่งพิมพ์',
                    })
                  }
                }}
              >
                พิมพ์บิล
              </Button>
            </div>
            <div
              style={{
                width: '12%',
                display: 'inline-block',
              }}
            >
              <div style={{ marginBottom: '10px' }}>
                <Button
                  type="default"
                  icon={<SelectOutlined />}
                  size="large"
                  style={{ height: '80px' }}
                  block
                  onClick={() => {
                    if (form.bill_no !== '') {
                      setIsShowPaymentPopUp(true)
                    } else {
                      Modal.warning({
                        centered: true,
                        title: 'ตรวจสอบ',
                        content: 'กรุณาค้นหาบิลก่อน "บันทึกการจ่ายเงิน"',
                      })
                    }
                  }}
                >
                  บันทึกการจ่ายเงิน
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  )
}

export default withRouter(CustomerBillPage)
