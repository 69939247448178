import React from 'react'
import { Select, Row, Col, Spin, Radio } from 'antd'
import './style.scss'
import { InputComponent } from 'components/Input'

const { Option } = Select

export const CustomerInput = (props) => {
  const {
    data: {
      code,
      name,
      tax_id,
      branch_name,
      address,
      tel,
      website,
      email,
      customer_type_id,
      is_headquarters,
      supplier_vat_code,
      supplier_vat_name,
      supplier_vat_id,
    },
    getDetail,
    handleInputChange,
    isLoading,
    customerType,
    label,
    isVat,
    isInvalidData,
    handleSelectedBranch,
    getSupplierVat,
    isSupplierPage,
  } = props

  const handleSelectedValue = () => {
    if (is_headquarters === true) {
      return 'headquarter'
    } else if (is_headquarters === false) {
      return 'branch'
    } else {
      return ''
    }
  }

  return (
    <Spin spinning={isLoading}>
      <InputComponent
        id="code"
        label={`รหัส${label}`}
        value={code}
        handleInputChange={handleInputChange}
        onSearch={() => getDetail(code)}
        isSearch
      />
      <InputComponent
        id="name"
        label={`ชื่อ${label}`}
        value={name}
        handleInputChange={handleInputChange}
        required
      />
      {isVat && (
        <>
          {isInvalidData && (
            <span style={{ color: 'red' }}>
              * เลขประจำตัวผู้เสียภาษีจะต้องมี 13 หลักเท่านั้น
            </span>
          )}
          <InputComponent
            id="tax_id"
            label="เลขประจำตัวผู้เสียภาษี"
            value={tax_id}
            handleInputChange={handleInputChange}
            required
            isTaxId
          />
        </>
      )}
      {isVat && (
        <div className="input-block">
          <Row gutter={10}>
            <Col xs={{ span: 24 }} lg={{ span: 5 }}>
              <div className="label-Block">
                <label>
                  ประเภทสาขา<span style={{ color: 'red' }}> * </span> &nbsp; :
                </label>
              </div>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 19 }}>
              <Radio.Group
                onChange={handleSelectedBranch}
                value={handleSelectedValue()}
              >
                <Radio
                  className="inline-flex"
                  id="headquarter"
                  value="headquarter"
                >
                  สำนักงานใหญ่
                </Radio>
                <Radio className="inline-flex" id="branch" value="branch">
                  สาขาย่อย
                </Radio>
              </Radio.Group>
            </Col>
          </Row>
        </div>
      )}
      {isVat && (
        <InputComponent
          id="branch_name"
          label="ชื่อสาขา"
          value={branch_name}
          handleInputChange={handleInputChange}
          required
        />
      )}
      <InputComponent
        id="address"
        label="ที่อยู่"
        value={address}
        handleInputChange={handleInputChange}
        isTextArea={true}
      />
      <InputComponent
        id="tel"
        label="โทรศัพท์"
        value={tel}
        handleInputChange={handleInputChange}
      />
      <InputComponent
        id="website"
        label="เว็บไซต์"
        value={website}
        handleInputChange={handleInputChange}
      />
      <InputComponent
        id="email"
        label="อีเมล์"
        value={email}
        handleInputChange={handleInputChange}
      />
      {isSupplierPage && (
        <InputComponent
          id="supplier_vat_code"
          label={`รหัส${label} VAT`}
          value={supplier_vat_code}
          handleInputChange={handleInputChange}
          isSearch
          onSearch={() => getSupplierVat(supplier_vat_code)}
          disabled={supplier_vat_id !== ''}
        />
      )}
      {isSupplierPage && (
        <InputComponent
          id="supplier_vat_name"
          label={`ชื่อ${label} VAT`}
          value={supplier_vat_name}
          handleInputChange={handleInputChange}
          disabled
        />
      )}
      {customerType && (
        <div className="input-block">
          <Row gutter={10}>
            <Col xs={{ span: 24 }} lg={{ span: 5 }}>
              <div className="label-Block">
                <label>
                  ประเภทลูกค้า<span style={{ color: 'red' }}> * </span> &nbsp; :
                </label>
              </div>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Select
                style={{ width: '100%' }}
                value={customer_type_id}
                placeholder="เลือกประเภทลูกค้า"
                onChange={handleInputChange}
              >
                {customerType.map((type) => (
                  <Option value={type.id} key={type.id}>
                    {type.name}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
        </div>
      )}
    </Spin>
  )
}
