import React, { useEffect } from 'react'
import { Divider } from 'antd'
import { ListPriceInPriceOutDataProvider } from './contexts/ListPriceInPriceOutContext'
import ListLossComparisonTable from './components/ListLossComparisonTable'
import LossMonthlySearch from './components/LossMonthlySearch'
import ProfitMonthlySearch from './components/ProfitMonthlySearch'
import ListProfitCompariconTable from './components/ListProfitComparisonTable'

const ListPriceInPriceOutComparisonPage = () => {
  useEffect(() => {
    document.title = 'รายการเปรียบเทียบ ราคาซื้อ/ราคาขาย - NoVat'
  }, [])
  return (
    <ListPriceInPriceOutDataProvider>
      <h1>รายการเปรียบเทียบ ราคาซื้อ/ราคาขาย</h1>
      <div className="form-wrapper">
        <LossMonthlySearch />
        <ListLossComparisonTable />
        <Divider style={{ margin: '10px 0px 20px' }} />
        <ProfitMonthlySearch />
        <ListProfitCompariconTable />
      </div>
    </ListPriceInPriceOutDataProvider>
  )
}

export default ListPriceInPriceOutComparisonPage
