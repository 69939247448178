import React from 'react'
import useCloseStoreDataContext from '../hooks/useCloseStoreDataContext'
import { DatePicker, Divider } from 'antd'
import moment from 'moment'

moment.locale('th')

const DatePickerCloseStore = () => {
  const { date, onDateChange } = useCloseStoreDataContext()

  return (
    <>
      <span style={{ paddingRight: '10px' }}>เลือกวันที่ :</span>
      <DatePicker
        defaultValue={moment() || date}
        disabledDate={(current) => current.isAfter(moment())}
        onChange={(_, dateString) => {
          onDateChange(dateString)
        }}
      />
      <span
        style={{
          fontWeight: 700,
          margin: '0px 0px 10px 20px',
          fontSize: '16px',
        }}
      >
        ตารางแสดงจำนวนเงินสดตอน ปิดร้าน ประจำวันที่ :{' '}
        <span style={{ color: 'blue' }}>{date}</span>
      </span>
      <Divider />
    </>
  )
}

export default DatePickerCloseStore
