import React, { Component } from 'react'
import { Col, Row, Button, Table, Spin } from 'antd'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { CustomerInput } from 'components/CustomerInput'
import { SearchBox } from 'components/SearchBox'
import { ContactList } from 'components/ContactList'
import { handleAlert } from 'utils'
import './style.scss'

import {
  onGetSupplierVatByCode,
  onSaveSupplierVat,
  onListSupplierVat,
  onDeleteSupplierVatContact,
} from 'redux/actions'

const DEFAULT_SUPPLIER = {
  code: '',
  name: '',
  tax_id: '',
  is_headquarters: null,
  branch_name: '',
  address: '',
  tel: '',
  fax: '',
  website: '',
  email: '',
  remark: '',
  contacts: [
    {
      id: 0,
      name: '',
      tel: '',
      email: '',
    },
  ],
}

const supplierListColumns = [
  {
    title: 'รหัสผู้ขาย',
    dataIndex: 'code',
    key: 'code',
    width: '7%',
    render: (text) => (
      <span style={{ color: 'rgb(37, 185, 255)' }}>{text}</span>
    ),
    sorter: (a, b) => ('' + a.code).localeCompare(b.code),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'ชื่อ',
    dataIndex: 'name',
    key: 'name',
    width: '30%',
    sorter: (a, b) => ('' + a.name).localeCompare(b.name),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'โทรศัพท์',
    dataIndex: 'tel',
    key: 'tel',
    width: '20%',
  },
  {
    title: 'TAX ID',
    dataIndex: 'tax_id',
    key: 'tax_id',
    width: '10%',
  },
  {
    title: 'เว็บไซต์',
    dataIndex: 'website',
    key: 'website',
    width: '15%',
  },
  {
    title: 'อีเมล์',
    dataIndex: 'email',
    key: 'email',
    width: '15%',
  },
]

const mapStateToProps = (state) => {
  return {
    listSupplierVat: state.listSupplierVat,
    getSupplierVatByCode: state.getSupplierVatByCode,
    saveSupplierVat: state.saveSupplierVat,
    deleteSupplierVatContact: state.deleteSupplierVatContact,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onGetSupplierVatByCode,
      onListSupplierVat,
      onSaveSupplierVat,
      onDeleteSupplierVatContact,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class SupplierVatPage extends Component {
    constructor(props) {
      super(props)
      this.state = {
        supplier: DEFAULT_SUPPLIER,
        isLoading: false,
        isContactLoading: false,
        supplierList: [],
        searchList: [],
        isLoadingList: false,
        isInvalidData: true,
        tmpContact: [],
        deleteIndex: null,
      }
    }

    componentDidMount() {
      this._listSupplierVat()
      document.title = 'ข้อมูลผู้ขาย [VAT]'
    }

    _listSupplierVat = () => {
      this.props.onListSupplierVat()
    }

    componentDidUpdate(prevProps, PrevState) {
      const {
        listSupplierVat,
        getSupplierVatByCode,
        saveSupplierVat,
        deleteSupplierVatContact,
      } = this.props
      if (prevProps.listSupplierVat !== listSupplierVat) {
        const { error, loading, finished, data, trace } = listSupplierVat
        this.setState({ isLoadingList: loading })
        if (!loading && !error && finished) {
          this.setState({
            supplierList: data,
            searchList: data,
          })
        }
        if (!loading && error) {
          handleAlert('custom', trace.message ? trace.message : '')
        }
      }
      if (prevProps.getSupplierVatByCode !== getSupplierVatByCode) {
        const { error, loading, finished, data, trace } = getSupplierVatByCode
        this.setState({ isLoading: loading })
        if (!loading && !error && finished) {
          this.setState({
            supplier: data,
            isInvalidData: data.tax_id.length === 13 ? false : true,
          })
        }
        if (!loading && error) {
          handleAlert('custom', trace.message ? trace.message : '')
          this.setState({
            supplier: { ...DEFAULT_SUPPLIER },
            isLoading: false,
          })
        }
      }
      if (prevProps.saveSupplierVat !== saveSupplierVat) {
        const { loading, error, trace, data, finished } = saveSupplierVat
        this.setState({ isLoading: loading })
        if (!loading && !error && finished) {
          handleAlert('success')
          this.setState({
            supplier: data,
          })
          this._listSupplierVat()
        }
        if (!loading && error) {
          handleAlert('custom', trace.message ? trace.message : '')
        }
      }
      if (prevProps.deleteSupplierVatContact !== deleteSupplierVatContact) {
        const { loading, error, trace, finished } = deleteSupplierVatContact
        const { tmpContact, deleteIndex, supplier } = this.state
        this.setState({ isContactLoading: loading })
        if (!loading && !error && finished) {
          handleAlert('success')
          tmpContact.splice(deleteIndex, 1)
          this.setState({
            supplier: {
              ...supplier,
              contacts: tmpContact,
            },
          })
        }
        if (!loading && error) {
          handleAlert('custom', trace.message ? trace.message : '')
        }
      }
    }

    _getSupplier = (code = '') => {
      this.setState({
        isLoading: true,
      })
      const tmpCode = code
      if (tmpCode) {
        this.props.onGetSupplierVatByCode(tmpCode)
      } else {
        handleAlert('custom', 'กรุณากรอกรหัสก่อนค้นหา')
        this.setState({
          isLoading: false,
        })
      }
    }

    handleSaveSupplier = () => {
      this.setState({
        isLoading: true,
      })
      if (
        !this.isInvalidData &&
        this.state.supplier.name &&
        this.state.supplier.branch_name
      ) {
        this.props.onSaveSupplierVat(this.state.supplier)
      } else {
        handleAlert('custom', 'กรุณากรอกข้อมูลที่มือเครื่องหมาย * ให้ครับถ้วน')
        this.setState({
          isLoading: false,
        })
      }
    }

    handleSelectedBranch = (event) => {
      let tmp = {}
      if (event.target.id === 'headquarter') {
        tmp = {
          ...this.state.supplier,
          is_headquarters: true,
          branch_name: 'สำนักงานใหญ่',
        }
      } else {
        tmp = {
          ...this.state.supplier,
          is_headquarters: false,
          branch_name: '',
        }
      }
      this.setState({
        supplier: tmp,
      })
    }

    handleInputChange = (event) => {
      let value = ''
      let id = ''
      let tmp = {}
      if (typeof event === 'object' && event.target.id !== 'tax_id') {
        value = event.target.value
        id = event.target.id
        if (id === 'code') {
          tmp = {
            ...this.state.supplier,
            [id]: value.toUpperCase(),
          }
        } else {
          tmp = {
            ...this.state.supplier,
            [id]: value,
          }
        }
      } else if (typeof event === 'object' && event.target.id === 'tax_id') {
        value = event.target.value
        id = event.target.id
        if (value.length === 13) {
          this.setState({
            isInvalidData: false,
          })
          tmp = {
            ...this.state.supplier,
            [id]: value,
          }
        } else if (value.length > 13) {
          this.setState({
            isInvalidData: false,
          })
          tmp = {
            ...this.state.supplier,
            [id]: value.substr(0, 13),
          }
        } else {
          this.setState({
            isInvalidData: true,
          })
          tmp = {
            ...this.state.supplier,
            [id]: value,
          }
        }
      } else {
        value = event
        id = 'supplier_type_id'
        tmp = {
          ...this.state.supplier,
          [id]: value,
        }
      }
      this.setState({
        supplier: tmp,
      })
    }

    addContact = () => {
      const {
        supplier,
        supplier: { contacts },
      } = this.state
      const newContact = {
        id: 0,
        name: '',
        tel: '',
        email: '',
      }
      this.setState({
        supplier: {
          ...supplier,
          contacts: [...contacts, newContact],
        },
      })
    }

    handleContactUpdate = (changeIndex, key, value) => {
      const {
        supplier: { contacts },
      } = this.state
      const newData = contacts.map((row, index) => {
        if (index === changeIndex) {
          return {
            ...row,
            [key]: value,
          }
        }
        return row
      })
      this.setState({ supplier: { ...this.state.supplier, contacts: newData } })
    }

    deleteContactFromIndex = (index, id) => {
      const {
        supplier,
        supplier: { contacts },
      } = this.state

      let tmpContact = [...contacts]
      if (id !== 0) {
        this.setState({ tmpContact, deleteIndex: index })
        this.props.onDeleteSupplierVatContact(id)
      } else {
        tmpContact.splice(index, 1)
        this.setState({
          supplier: {
            ...supplier,
            contacts: tmpContact,
          },
        })
      }
    }

    handleResetField = () => {
      this.setState({
        supplier: DEFAULT_SUPPLIER,
      })
    }

    handleSearch = (searchText) => {
      const { supplierList } = this.state
      const filteredData = supplierList.filter(
        ({ code, name, tel, website, email, tax_id }) => {
          code = code.toString().toLowerCase()
          name = name.toString().toLowerCase()
          tel = tel.toString().toLowerCase()
          website = website.toString().toLowerCase()
          email = email.toString().toLowerCase()
          tax_id = tax_id.toString().toLowerCase()
          return (
            code.includes(searchText.toLowerCase()) ||
            name.includes(searchText.toLowerCase()) ||
            tel.includes(searchText.toLowerCase()) ||
            website.includes(searchText.toLowerCase()) ||
            email.includes(searchText.toLowerCase()) ||
            tax_id.includes(searchText.toLowerCase())
          )
        }
      )
      filteredData.sort((a, b) => a.code - b.code)
      this.setState({
        searchList: filteredData,
      })
    }

    render() {
      const {
        supplier,
        supplier: { contacts },
        isLoading,
        isContactLoading,
      } = this.state
      return (
        <div className="supplier-vat-page">
          <h1>ข้อมูลผู้ขาย [Supplier VAT]</h1>
          <div className="supplier-vat-detail">
            <Row gutter={[0, 32]}>
              <Col xs={{ offset: 1, span: 22 }} lg={{ offset: 1, span: 10 }}>
                <CustomerInput
                  label="ผู้ขาย"
                  data={supplier}
                  getDetail={this._getSupplier}
                  handleInputChange={this.handleInputChange}
                  isLoading={isLoading}
                  isVat
                  isInvalidData={this.state.isInvalidData}
                  handleSelectedBranch={this.handleSelectedBranch}
                />
              </Col>
              <Col xs={{ offset: 1, span: 22 }} lg={{ offset: 1, span: 11 }}>
                <div className="add-contact-button">
                  <Button
                    type="primary"
                    onClick={this.addContact}
                    htmlType="submit"
                  >
                    เพิ่มรายชื่อ
                  </Button>
                </div>
                <Spin spinning={isContactLoading}>
                  <table className="ant-table-header contact-table">
                    <thead className="ant-table-thead">
                      <tr>
                        <th id="del">#</th>
                        <th id="name">Name</th>
                        <th id="Tel">Tel</th>
                        <th id="Email">Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      {contacts &&
                        contacts.map((row, index) => (
                          <ContactList
                            key={index}
                            data={row}
                            index={index}
                            handleContactUpdate={this.handleContactUpdate}
                            deleteContact={this.deleteContactFromIndex}
                          />
                        ))}
                    </tbody>
                  </table>
                </Spin>
              </Col>
            </Row>
            <Row>
              <Col xs={{ offset: 8, span: 12 }} lg={{ offset: 10, span: 12 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={this.handleSaveSupplier}
                  disabled={this.state.isInvalidData}
                >
                  บันทึก
                </Button>
                <Button
                  htmlType="button"
                  onClick={this.handleResetField}
                  danger
                >
                  ล้างข้อมูล
                </Button>
              </Col>
            </Row>
          </div>
          <Row>
            <Col xs={{ offset: 1, span: 22 }} lg={{ offset: 1, span: 22 }}>
              <h1 className="title">รายชื่อผู้ขาย</h1>
            </Col>
          </Row>
          <div className="supplier-list">
            <Row>
              <Col xs={{ offset: 1, span: 11 }}>
                <SearchBox
                  onSearch={this.handleSearch}
                  className={'search-box'}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={{ offset: 1, span: 22 }} lg={{ offset: 1, span: 22 }}>
                <Table
                  scroll={{ x: 1500, y: 680 }}
                  columns={supplierListColumns}
                  dataSource={this.state.searchList}
                  loading={this.state.isLoadingList}
                  rowKey={(record) => record.id}
                  rowClassName={'supplier-list-item'}
                  size='small'
                  style={{ height: '680px' }}
                  pagination={{
                    simple: true,
                    position: ['bottomRight'],
                    defaultPageSize: 20,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'],
                  }}                  onRow={(record, _) => {
                    return {
                      onClick: () => {
                        this._getSupplier(record.code)
                        window.scrollTo({
                          top: 0,
                          behavior: 'smooth',
                        })
                      },
                    }
                  }}
                ></Table>
              </Col>
            </Row>
          </div>
        </div>
      )
    }
  }
)
