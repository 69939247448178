import { request } from './request'

export const saveProduct = (body) =>
  request({
    url: '/api/product/save',
    method: 'post',
    data: body,
  })

/**
 * @param  {string} code
 * Ex: 0001
 */
export const getProductByCode = (code) =>
  request({
    url: `/api/product/${code}`,
    method: 'get',
  })

/**
  params: { 
    page,
    pageSize,
  }
*/
export const listProduct = (params) =>
  request({
    url: `/api/product/list`,
    method: 'get',
    params: params ?? null,
  })

export const listProductVat = (params) =>
  request({
    url: `/api/product_vat/list`,
    method: 'get',
    params: params ?? null,
  })

export const deleteProduct = (code) =>
  request({
    url: `/api/product/delete/${code}`,
    method: 'delete',
  })

export const getUnitList = () =>
  request({
    url: `/api/product/unit/list`,
    method: 'get',
  })

export const getVatTypeList = () =>
  request({
    url: `/api/product/vat_type/list`,
    method: 'get',
  })

export const getMovementTypeList = () =>
  request({
    url: `/api/product/movement_type/list`,
    method: 'get',
  })

export const getPhysicalStockZoneList = () =>
  request({
    url: `/api/product/physical_stock_zone/list`,
    method: 'get',
  })

export const getPhysicalStockShelfList = () =>
  request({
    url: `/api/product/physical_stock_shelf/list`,
    method: 'get',
  })
