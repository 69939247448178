import React from 'react'
import useOpenStoreDataContext from '../../hooks/useOpenStoreDataContext'
import { Table, Spin } from 'antd'
import columns from './columns'

const ListOpenStoreTable = () => {
  const {
    listCashesData,
    listCashesLoading,
    onDeleteCash,
    deleteLoading,
    onClickRow,
  } = useOpenStoreDataContext()

  return (
    <Spin spinning={listCashesLoading || deleteLoading}>
      <Table
        rowKey={(record) => `id+${record.id}`}
        dataSource={listCashesData?.length === 1 ? [] : listCashesData}
        columns={columns({ onDeleteCash })}
        scroll={{ x: 900 }}
        style={{
          maxWidth: '1100px',
        }}
        size="small"
        pagination={false}
        rowClassName="cursor-pointer"
        onRow={(record) => {
          return {
            onClick: () => {
              onClickRow(record)
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              })
            },
          }
        }}
      />
    </Spin>
  )
}

export default ListOpenStoreTable
