import React from 'react'
import { Popconfirm, Button } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { addCommas } from 'utils'

const columns = ({ onDeleteBillVatCash }) => {
  const tableColumns = [
    {
      title: '',
      dataIndex: '',
      key: '',
      width: '40px',
      render: (_, record) => {
        if (record.id === 'total') {
          return <></>
        } else {
          return (
            <Popconfirm
              title="คุณต้องการลบรายการนี้ใช่ไหม ?"
              okText="ลบ"
              cancelText="ยกเลิก"
              onConfirm={() => onDeleteBillVatCash(record.id)}
            >
              <Button
                size="small"
                type="primary"
                danger
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          )
        }
      },
    },
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
      width: '40px',
    },
    {
      title: 'เลขที่บิล',
      dataIndex: 'bill_no',
      key: 'bill_no',
      width: '100px',
    },
    {
      title: 'ยอดบิล',
      dataIndex: 'bill_total_amount',
      key: 'bill_total_amount',
      width: '100px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
    },
    {
      title: 'เงินสดสุทธิ',
      dataIndex: 'total_cash',
      key: 'total_cash',
      width: '100px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
    },
    {
      title: 'หมายเหตุ',
      dataIndex: 'remark',
      key: 'remark',
      width: '150px',
    },
  ]
  return tableColumns
}

export default columns
