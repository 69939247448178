/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Button, Spin, Checkbox, Popconfirm } from 'antd'
import { withRouter } from 'react-router-dom'
import { SaveOutlined, ClearOutlined, PlusOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'

import ProductSupplierTable from 'components/ProductSupplierTable'
import ProductSupplierModalForm from 'components/ProductSupplierModalForm'
import InlineInput from 'components/InlineInput'
import DiscountInput from 'components/InlineInput/DiscountInput'
import SearchProductNoVatPopUp from 'components/SearchProductNoVatPopUp'
import { useQuery, handleAlert } from 'utils'

import {
  onSaveProduct,
  onGetProductByCode,
  onGetUnitList,
  onGetVatTypeList,
  onGetMovementTypeList,
  onGetPhysicalStockZoneList,
  onGetPhysicalStockShelfList,
  onListLatestBoughtSupplierByProductId,
  onDeleteLatestBoughtSupplier,
} from 'redux/actions'

const DEFAULT_PRODUCT = {
  id: 0,
  code: '',
  name: '',
  package_size: '',
  stock1: 0,
  stock2: 0,
  stock_low: null,
  price_in_1: '0',
  price_in_2: '0',
  price_out_cash: '0',
  price_out_atm: '0',
  discount_cash_1: '0.00',
  discount_cash_2: '0.00',
  discount_cash_3: '0.00',
  discount_cash_4: '0.00',
  discount_atm_1: '0.00',
  discount_atm_2: '0.00',
  discount_atm_3: '0.00',
  discount_atm_4: '0.00',
  remark: '',
  unit_id: null,
  vat_type: null,
  movement_type: null,
  physical_stock_zone: null,
  physical_stock_shelf: null,
  product_group: null,
  use_new_price: null,
  is_allow_low: null,
  wholesale_quantity: '0',
}

export const DEFAULT_SELETED_SUPPLIER = {
  supplier_code: '',
  supplier_name: '',
  discount_1: 0,
  discount_2: 0,
  discount_3: 0,
  discount_4: 0,
  discount_5: 0,
  discount_6: 0,
  latest_bought: moment().format('YYYY-MM-DD'),
  lead_time: null,
  price_in: null,
  remark: null,
}

const ProductPage = (props) => {
  const dispatch = useDispatch()
  const saveProduct = useSelector((state) => state.saveProduct)
  const listLatestBoughtSupplierByProductId = useSelector(
    (state) => state.listLatestBoughtSupplierByProductId
  )
  const getProductByCode = useSelector((state) => state.getProductByCode)
  const deleteLatestBoughtSupplier = useSelector(
    (state) => state.deleteLatestBoughtSupplier
  )
  const units = useSelector((state) => state.getUnitList.data)
  const vatTypes = useSelector((state) => state.getVatTypeList.data)
  const movementTypes = useSelector((state) => state.getMovementTypeList.data)
  const physicalStockZones = useSelector(
    (state) => state.getPhysicalStockZoneList.data
  )
  const physicalStockShelf = useSelector(
    (state) => state.getPhysicalStockShelfList.data
  )

  const query = useQuery()
  const code = query.get('code')
  const [form, setForm] = useState(DEFAULT_PRODUCT)
  const [isShowProductPopUp, setIsShowProductPopUp] = useState(false)
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isShowModal, setIsShowModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState({})
  const [latestBoughtSuppliers, setLatestBoughtSuppliers] = useState([])
  const [
    isFetchingNewLatestBoughtSupplierList,
    setIsFetchingNewLatestBoughtSupplierList,
  ] = useState(false)

  useEffect(() => {
    if (code) {
      dispatch(onGetProductByCode(code))
    }
  }, [code, props.history])

  useEffect(() => {
    setIsLoading(getProductByCode.loading)
    if (getProductByCode.error && getProductByCode.trace) {
      setForm(DEFAULT_PRODUCT)
      setLatestBoughtSuppliers([])
      props.history.push(`${window.location.pathname}`)
      if (getProductByCode.trace && getProductByCode.trace.status === 404) {
        handleAlert('404')
      } else {
        handleAlert('error', getProductByCode.trace.message)
      }
    }
    if (
      !getProductByCode.loading &&
      !getProductByCode.error &&
      getProductByCode.finished
    ) {
      setForm(getProductByCode.data)
      // props.history.push(
      //   `${window.location.pathname}?code=${code || form.code}`
      // )
      dispatch(onListLatestBoughtSupplierByProductId(getProductByCode.data.id))
    }
  }, [getProductByCode])

  useEffect(() => {
    setIsLoading(listLatestBoughtSupplierByProductId.loading)
    if (
      !listLatestBoughtSupplierByProductId.loading &&
      listLatestBoughtSupplierByProductId.finished &&
      listLatestBoughtSupplierByProductId.data
    ) {
      setLatestBoughtSuppliers(listLatestBoughtSupplierByProductId.data)
    }
    if (
      !listLatestBoughtSupplierByProductId.loading &&
      listLatestBoughtSupplierByProductId.error
    ) {
      setLatestBoughtSuppliers([])
      handleAlert(
        'error',
        listLatestBoughtSupplierByProductId.trace.message || ''
      )
    }
  }, [listLatestBoughtSupplierByProductId])

  useEffect(() => {
    setIsLoading(deleteLatestBoughtSupplier.loading)
    if (
      !deleteLatestBoughtSupplier.loading &&
      deleteLatestBoughtSupplier.finished &&
      deleteLatestBoughtSupplier.data
    ) {
      dispatch(onListLatestBoughtSupplierByProductId(getProductByCode.data.id))
    }
    if (
      !deleteLatestBoughtSupplier.loading &&
      deleteLatestBoughtSupplier.error
    ) {
      setLatestBoughtSuppliers([])
      handleAlert('error', deleteLatestBoughtSupplier.trace.message || '')
    }
  }, [deleteLatestBoughtSupplier])

  useEffect(() => {
    dispatch(onGetUnitList())
    dispatch(onGetVatTypeList())
    dispatch(onGetMovementTypeList())
    dispatch(onGetPhysicalStockZoneList())
    dispatch(onGetPhysicalStockShelfList())
    document.title = 'ข้อมูลสินค้า - NoVat'
  }, [])

  const getlistLatestBoughtSupplierByProductId = (id) => {
    dispatch(onListLatestBoughtSupplierByProductId(id))
  }

  const saveProductHandler = () => {
    if (form.name) {
      setErrors({})
      dispatch(onSaveProduct(form))
    } else {
      handleAlert('custom', 'กรุณากรอกชื่อสินค้าให้ครบถ้วน')
    }
  }

  useEffect(() => {
    setIsLoading(saveProduct.loading)
    if (saveProduct.error) {
      setIsLoading(false)
      handleAlert('custom', saveProduct.trace.code || '')
    }
    if (
      !saveProduct.loading &&
      !saveProduct.error &&
      saveProduct.data &&
      saveProduct.finished
    ) {
      handleAlert('success')
      setForm(saveProduct.data)
    }
  }, [saveProduct])

  const onClearProduct = () => {
    setForm(DEFAULT_PRODUCT)
    setLatestBoughtSuppliers([])
    props.history.push(`${window.location.pathname}`)
  }

  const onChangeInputValue = (value, name) => {
    if (errors[name] && value) {
      const tmp_errors = { ...errors }
      delete tmp_errors[name]
      setErrors(tmp_errors)
    }
    if (name === 'code') {
      setForm({ ...form, [name]: value.toUpperCase(), id: 0 })
    } else {
      setForm({ ...form, [name]: value.toUpperCase() })
    }
  }

  const onEditLatestBoughtSupplier = (row) => {
    setSelectedRow(row)
    setIsShowModal(true)
  }

  const onAddNewSupplier = () => {
    setIsShowModal(true)
    setSelectedRow(DEFAULT_SELETED_SUPPLIER)
  }

  const onDeleteLatestBoughtSupplierfromTable = (id) => {
    dispatch(onDeleteLatestBoughtSupplier(id))
  }

  const onSearchProduct = (code) => {
    if (code !== '') {
      dispatch(onGetProductByCode(code))
    } else {
      setIsShowProductPopUp(true)
    }
  }

  const onCancelProdutPopUp = () => {
    setIsShowProductPopUp(false)
  }

  const onSelectProductPopUp = (data) => {
    onSearchProduct(data.code)
    setIsShowProductPopUp(false)
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'inline-block' }}>
          <h1>ข้อมูลสินค้า</h1>
        </div>
        <div style={{ display: 'inline-block' }}>
          <Popconfirm
            title="คุณต้องการล้างหน้าจอใช่หรือไม่ ?"
            okText="ใช่"
            cancelText="ยกเลิก"
            onConfirm={onClearProduct}
          >
            <Button
              type="default"
              danger
              icon={<ClearOutlined />}
              size="large"
              block
            >
              ล้างหน้าจอ
            </Button>
          </Popconfirm>
        </div>
      </div>
      <SearchProductNoVatPopUp
        visible={isShowProductPopUp}
        onCancel={onCancelProdutPopUp}
        onClick={onSelectProductPopUp}
      />
      <Spin spinning={isLoading}>
        <div className="form-wrapper">
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                onSearch={(value) => {
                  onSearchProduct(value)
                }}
                inputOption="search"
                label="รหัสสินค้า"
                placeholder="รหัสสินค้า"
                isLoading={isLoading}
                value={form.code}
                onChange={(event) => {
                  onChangeInputValue(event.target.value, 'code')
                }}
                error={errors.code}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ชื่อสินค้า"
                width="300px"
                value={form.name}
                required
                disabled={false}
                onChange={(event) =>
                  setForm({ ...form, name: event.target.value })
                }
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ขนาดบรรจุ"
                value={form.package_size}
                onChange={(event) =>
                  setForm({ ...form, package_size: event.target.value })
                }
                width="120px"
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="จำนวนในสต็อก1"
                value={form.stock1}
                onChange={(event) =>
                  setForm({ ...form, stock1: event.target.value })
                }
                width="100px"
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="จำนวนในสต็อก2"
                value={form.stock2}
                onChange={(event) =>
                  setForm({ ...form, stock2: event.target.value })
                }
                width="100px"
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="จำนวนคงเหลือต่ำสุด"
                value={form.stock_low}
                onChange={(event) =>
                  setForm({ ...form, stock_low: event.target.value })
                }
                width="80px"
                disabled
              />
            </div>
            <div className="input-item pr-2">
              <InlineInput
                label="หน่วยนับ"
                value={form.unit_id}
                onChange={(value) => {
                  setForm({ ...form, unit_id: value })
                }}
                width="120px"
                inputOption="select_input"
                options={units}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="Vat"
                value={form.vat_type}
                onChange={(value) => {
                  setForm({ ...form, vat_type: value })
                }}
                width="130px"
                inputOption="select_input"
                options={vatTypes}
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="ราคาเข้า1"
                value={form.price_in_1}
                onChange={(event) =>
                  setForm({ ...form, price_in_1: event.target.value })
                }
                width="100px"
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ราคาเข้า2"
                value={form.price_in_2}
                onChange={(event) =>
                  setForm({ ...form, price_in_2: event.target.value })
                }
                width="100px"
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ประเภทการเคลื่อนไหว"
                value={form.movement_type}
                onChange={(value) => {
                  setForm({ ...form, movement_type: value })
                }}
                width="100px"
                inputOption="select_input"
                options={movementTypes}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="โซนที่เก็บสินค้า"
                value={form.physical_stock_zone}
                onChange={(value) => {
                  setForm({ ...form, physical_stock_zone: value })
                }}
                width="130px"
                inputOption="select_input"
                options={physicalStockZones}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ชั้นวาง"
                value={form.physical_stock_shelf}
                onChange={(value) => {
                  setForm({ ...form, physical_stock_shelf: value })
                }}
                width="130px"
                inputOption="select_input"
                options={physicalStockShelf}
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="ราคาขายส่ง"
                value={form.price_out_atm}
                onChange={(event) =>
                  setForm({ ...form, price_out_atm: event.target.value })
                }
                width="100px"
              />
            </div>
            <div className="input-item">
              <DiscountInput
                label="ส่วนลดราคาส่ง"
                name1="discount_atm_1"
                name2="discount_atm_2"
                name3="discount_atm_3"
                name4="discount_atm_4"
                value1={Number(form.discount_atm_1)}
                value2={Number(form.discount_atm_2)}
                value3={Number(form.discount_atm_3)}
                value4={Number(form.discount_atm_4)}
                onChange={(value, name) => setForm({ ...form, [name]: value })}
                width="100px"
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ราคาขายปลีก"
                value={form.price_out_cash}
                onChange={(event) =>
                  setForm({ ...form, price_out_cash: event.target.value })
                }
                width="100px"
              />
            </div>
            <div className="input-item">
              <DiscountInput
                label="ส่วนลดราคาปลีก"
                name1="discount_cash_1"
                name2="discount_cash_2"
                name3="discount_cash_3"
                name4="discount_cash_4"
                value1={Number(form.discount_cash_1)}
                value2={Number(form.discount_cash_2)}
                value3={Number(form.discount_cash_3)}
                value4={Number(form.discount_cash_4)}
                onChange={(value, name) => setForm({ ...form, [name]: value })}
                width="100px"
              />
            </div>
          </div>
          <div className="input-wrapper">
            {/* <div className="input-item">
              <InlineInput
                label="กลุ่มสินค้า"
                value={form.product_group}
                onChange={(event) =>
                  setForm({ ...form, price_in_2: event.target.value })
                }
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="กลุ่มเปลี่ยนสินค้า"
                value={form.movement_type}
                onChange={(value) => {
                  setForm({ ...form, movement_type: value })
                }}
                width="150px"
                inputOption="select_input"
                options={movementTypes}
              />
            </div>
            <div className="input-item">
              <Checkbox
                onChange={(event) =>
                  setForm({
                    ...form,
                    use_new_price: event.target.checked,
                  })
                }
              >
                ใช้ราคาใหม่
              </Checkbox>
            </div> */}
            <div className="input-item">
              <InlineInput
                label="ปริมาณขั้นต่ำสำหรับราคาขายส่ง"
                width="120px"
                value={form.wholesale_quantity}
                disabled={false}
                onChange={(event) =>
                  setForm({ ...form, wholesale_quantity: event.target.value })
                }
              />
            </div>
            <div
              className="input-item"
              style={{ marginTop: '6px', height: '20px' }}
            >
              <Checkbox
                checked={form.is_allow_low}
                onChange={(event) =>
                  setForm({
                    ...form,
                    is_allow_low: event.target.checked,
                  })
                }
              >
                อนุญาตให้เปิดบิลต่ำกว่าทุน
              </Checkbox>
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item" style={{ width: '65%' }}>
              <InlineInput
                inputOption="text_area_input"
                label="หมายเหตุ"
                placeholder="หมายเหตุ"
                value={form.remark}
                onChange={(event) =>
                  setForm({ ...form, remark: event.target.value })
                }
                width="100%"
                labelWidth="74px"
              />
            </div>
            <div style={{ width: '30%' }}>
              <div className="d-inline mb-1">
                <Button
                  type="primary"
                  icon={<SaveOutlined />}
                  size="large"
                  onClick={saveProductHandler}
                >
                  บันทึก
                </Button>
              </div>
              {/* <Button
                type="default"
                danger
                icon={<ClearOutlined />}
                size="large"
                onClick={onClearProduct}
              >
                ล้างหน้าจอ
              </Button> */}
            </div>
          </div>
        </div>
        <div className="mt-2">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => onAddNewSupplier()}
            disabled={form.code ? false : true}
          >
            เพิ่มรายการราคาสินค้า
          </Button>
        </div>
        <ProductSupplierTable
          onEditLatestBoughtSupplier={onEditLatestBoughtSupplier}
          onDeleteLatestBoughtSupplierfromTable={
            onDeleteLatestBoughtSupplierfromTable
          }
          supplierList={latestBoughtSuppliers}
        />
      </Spin>
      <div>
        <ProductSupplierModalForm
          selectedRow={selectedRow}
          isShowModal={isShowModal}
          setIsShowModal={setIsShowModal}
          productCode={form.code}
          isFetchingNewLatestBoughtSupplierList={
            isFetchingNewLatestBoughtSupplierList
          }
          setIsFetchingNewLatestBoughtSupplierList={
            setIsFetchingNewLatestBoughtSupplierList
          }
          getlistLatestBoughtSupplierByProductId={
            getlistLatestBoughtSupplierByProductId
          }
        />
      </div>
    </div>
  )
}

export default withRouter(ProductPage)
