import React from 'react'
import { Table, Button, Popconfirm } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { addCommas } from 'utils'
import moment from 'moment'
import 'moment/locale/th'
moment.locale('th')

const ListExpensesTable = (props) => {
  const { list, onDelete } = props
  const tableColumns = [
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: '40px',
      fixed: 'left',
      render: (_, record) => {
        return (
          <Popconfirm
            title="คุณต้องการลบรายการนี้ใช่ไหม ?"
            okText="ลบ"
            cancelText="ยกเลิก"
            onConfirm={() => onDelete(record)}
          >
            <Button
              size="small"
              type="primary"
              danger
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        )
      },
    },
    {
      title: 'วันที่จ่าย',
      dataIndex: 'payment_date',
      key: 'payment_date',
      width: '90px',
      sorter: (a, b) => ('' + a.payment_date).localeCompare(b.payment_date),
      sortDirections: ['ascend', 'descend'],
      fixed: 'left',
      render: (_, record) => {
        return moment(record.payment_date).format('L LT')
      },
    },
    {
      title: 'ประเภท',
      dataIndex: 'expense_type_name',
      key: 'expense_type_name',
      sorter: (a, b) =>
        ('' + a.expense_type_name).localeCompare(b.expense_type_name),
      sortDirections: ['ascend', 'descend'],
      width: '100px',
      fixed: 'left',
      render: (text) => <div className="break-word">{text}</div>,
    },
    {
      title: 'จำนวนเงิน',
      dataIndex: 'amount',
      key: 'amount',
      width: '100px',
      fixed: 'left',
      render: (text) => <div className="text-align-right">{addCommas(text)}</div>,
    },
    {
      title: 'หมายเหตุ',
      dataIndex: 'remark',
      key: 'remark',
      width: '300px',
      render: (text) => <div className="break-word">{text}</div>,
    },
  ]

  return (
    <div>
      <Table
        rowKey={(record) => `id+${record.id}`}
        dataSource={list}
        columns={tableColumns}
        pagination={{
          simple: true,
          defaultPageSize: 20,
          showSizeChanger: true,
          position: ['bottomRight'],
          pageSizeOptions: ['10', '20', '50', '100'],
        }}
        size="small"
        scroll={{ x: 850 }}
        style={{
          maxWidth: '900px',
          height: '760px',
          minHeight: '100%',
          backgroundColor: '#FFFFFF',
          marginTop: '10px',
        }}
        rowClassName="cursor-pointer"
        onRow={(record) => {
          return {
            onClick: () => {
              props.onClickRow(record)
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              })
            },
          }
        }}
      />
    </div>
  )
}

export default ListExpensesTable
