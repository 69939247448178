import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Result, Button } from 'antd'
import { useHistory } from 'react-router-dom'

const ForbiddenPage = (props) => {
  useEffect(() => {
    document.title = 'Bonlighting Admin'
  }, [])
  let history = useHistory()
  return (
    <div>
      <Result
        status="404"
        title="คุณไม่ได้รับสิทธิ์ในการเข้าถึงหน้านี้"
        subTitle={"กรุณาเลือกหน้าใหม่ จาก เมนู ด้านบน"}
        extra={
          <Button onClick={() => history.push('/home')} type="primary">
            Back to Home Page
          </Button>
        }
      />
    </div>
  )
}

export default withRouter(ForbiddenPage)
