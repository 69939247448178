/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Button, Spin, Divider, Modal, Popconfirm } from 'antd'
import {
  SaveOutlined,
  ClearOutlined,
  SearchOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'

import SearchCustomerVatPopUp from 'components/SearchCustomerVatPopUp'
import SearchCustomerVatInvoicePopup from 'components/SearchCustomerVatInvoicePopup'
import InlineInput from 'components/InlineInput'
import InvoiceCustomerVatTable from 'components/InvoiceCustomerVatTable'
import { handleAlert } from 'utils'

import {
  onGetCustomerVatByCode,
  onListCustomerVatBillByCustomerAndDate,
  onSaveCustomerVatInvoice,
  onCancelCustomerVatInvoice,
  onGetCustomerVatInvoiceByCustomer,
} from 'redux/actions'

const DEFAULT_FORM = {
  id: null,
  customer_id: null,
  customer_code: '',
  customer_name: '',
  start_date: moment().format('YYYY-MM-DD'),
  end_date: moment().format('YYYY-MM-DD'),
  customer_invoice_total: 0,
  actually_paid_total: 0,
  customer_invoice_no: '',
  date: moment().format('YYYY-MM-DD'),
  list: [],
}

const DEFAULT_DELETED_INVOICE = {
  id: null,
  customer_id: null,
  customer_code: '',
  customer_name: '',
  date: '',
  customer_invoice_no: '',
  customer_invoice_total: '',
}

const CustomerVatInvoicePage = (props) => {
  function useAsyncState(initialValue) {
    const [value, setValue] = useState(initialValue)
    const setter = (x) =>
      new Promise((resolve) => {
        setValue(x)
        resolve(x)
      })
    return [value, setter]
  }
  const [form, setForm] = useAsyncState(DEFAULT_FORM)
  const [isLoading, setIsLoading] = useState(false)
  const [billList, setBillList] = useState([])
  const [deletedInvoice, setDeletedInvoice] = useState(DEFAULT_DELETED_INVOICE)
  const [isShowCustomerPopUp, setIsShowCustomerPopUp] = useState(false)
  const [isShowCustomerInvoicePopUp, setIsShowCustomerInvoicePopUp] =
    useState(false)
  const dispatch = useDispatch()
  const getCustomerVatByCode = useSelector(
    (state) => state.getCustomerVatByCode
  )
  let listCustomerVatBillByCustomerAndDate = useSelector(
    (state) => state.listCustomerVatBillByCustomerAndDate
  )
  let saveCustomerVatInvoice = useSelector(
    (state) => state.saveCustomerVatInvoice
  )
  let cancelCustomerVatInvoice = useSelector(
    (state) => state.cancelCustomerVatInvoice
  )
  let getCustomerVatInvoiceByCustomer = useSelector(
    (state) => state.getCustomerVatInvoiceByCustomer
  )

  useEffect(() => {
    document.title = 'รวมยอดบิลขายสินค้า - Vat'
    listCustomerVatBillByCustomerAndDate = []
  }, [])

  useEffect(() => {
    const { trace, error, finished, data } = getCustomerVatByCode
    if (error) {
      handleAlert('custom', trace.message || '')
      setForm({
        ...form,
        customer_id: null,
        customer_name: '',
      })
      setIsLoading(false)
    }
    if (!error && data && finished) {
      setForm({
        ...form,
        customer_id: data.id,
        customer_code: data.code,
        customer_name: data.name,
      })
      setIsLoading(false)
    }
  }, [getCustomerVatByCode]) // Only re-subscribe if props.searchCustomerCode changes

  useEffect(() => {
    const { trace, error, finished, data } =
      listCustomerVatBillByCustomerAndDate
    if (error) {
      handleAlert('custom', trace.message || '')
      setBillList([])
      setIsLoading(false)
    }
    if (!error && data && finished) {
      handleAlert('success')
      setForm({
        ...form,
        customer_invoice_total: '',
        actually_paid_total: '',
        customer_invoice_no: '',
        list: data,
      })
      setBillList([])
      setIsLoading(false)
    }
  }, [listCustomerVatBillByCustomerAndDate])

  useEffect(() => {
    const { trace, error, finished, data } = saveCustomerVatInvoice
    if (error) {
      handleAlert('custom', trace.message || '')
      setIsLoading(false)
    }
    if (!error && data && finished) {
      setBillList([])
      setForm({
        ...form,
        customer_invoice_total: 0,
        customer_invoice_no: '',
        actually_paid_total: '',
        date: moment().format('YYYY-MM-DD'),
      })
      dispatch(
        onListCustomerVatBillByCustomerAndDate(
          form.customer_code,
          form.start_date,
          form.end_date
        )
      )
      setIsLoading(false)
    }
  }, [saveCustomerVatInvoice])

  useEffect(() => {
    const { trace, error, finished, data } = cancelCustomerVatInvoice
    if (error) {
      handleAlert('custom', trace.message || '')
      setIsLoading(false)
    }
    if (!error && data && finished) {
      setDeletedInvoice(DEFAULT_DELETED_INVOICE)
      Modal.success({
        centered: true,
        title: 'สำเร็จ',
        content: 'ยกเลิกใบวางบิลเรียบร้อยแล้ว',
      })
      if (form.list.length > 0) {
        dispatch(
          onListCustomerVatBillByCustomerAndDate(
            form.customer_code,
            form.start_date,
            form.end_date
          )
        )
      } else {
        setIsLoading(false)
      }
    }
  }, [cancelCustomerVatInvoice])

  useEffect(() => {
    const { trace, error, finished, data } = getCustomerVatInvoiceByCustomer
    if (error) {
      handleAlert('custom', trace.message || '')
      setIsLoading(false)
    }
    if (!error && data && finished) {
      handleAlert('success')
      setForm({
        ...form,
        id: data.id,
        date: data.date,
        customer_invoice_no: data.customer_invoice_no,
        customer_invoice_total: data.customer_invoice_total,
        actually_paid_total: data.actually_paid_total,
        list: data.bill_list,
      })
      setIsLoading(false)
    }
  }, [getCustomerVatInvoiceByCustomer])

  const onSearchCustomerCode = (code) => {
    if (code !== '') {
      setIsLoading(true)
      dispatch(onGetCustomerVatByCode(code))
    } else {
      setIsShowCustomerPopUp(true)
    }
  }

  //----------- POP UP SUPPLIER ------------//
  const onSelectCustomerPopup = (data) => {
    dispatch(onGetCustomerVatByCode(data.code))
    setIsShowCustomerPopUp(false)
  }

  const onCancelCustomerPopUp = () => {
    setIsShowCustomerPopUp(false)
  }
  //----------- POP UP INVOICE ------------//

  const onSearchCustomerInvoice = (invoice_no) => {
    if (form.customer_id !== null) {
      if (invoice_no !== '') {
        setIsLoading(true)
        dispatch(onGetCustomerVatInvoiceByCustomer(form.customer_code, invoice_no))
      } else {
        setIsShowCustomerInvoicePopUp(true)
      }
    } else {
      handleAlert('custom', 'กรุณาเลือกข้อมูลลูกค้าก่อน')
    }
  }

  const onSelectCustomerInvoicePopup = (data) => {
    dispatch(
      onGetCustomerVatInvoiceByCustomer(
        form.customer_code,
        data.customer_invoice_no
      )
    )
    setIsShowCustomerInvoicePopUp(false)
  }

  const onCancelCustomerInvoicePopUp = () => {
    setIsShowCustomerInvoicePopUp(false)
  }

  const onDeleteCustomerInvoice = (data) => {
    setDeletedInvoice(data)
    setIsShowCustomerInvoicePopUp(false)
  }

  const onSearchClick = () => {
    if (form.customer_id !== null) {
      if (form.start_date !== '' && form.end_date !== '') {
        setIsLoading(true)
        dispatch(
          onListCustomerVatBillByCustomerAndDate(
            form.customer_code,
            form.start_date,
            form.end_date
          )
        )
      } else {
        handleAlert('custom', 'กรุณาระบุวันที่ให้ครบถ้วน')
      }
    } else {
      handleAlert('custom', 'กรุณาเลือกลูกค้าก่อนค้นหา')
    }
  }

  const onSaveClick = () => {
    if (form.date !== '' && form.customer_invoice_no !== '') {
      setIsLoading(true)
      let data = {
        customer_id: form.customer_id,
        customer_invoice_no: form.customer_invoice_no,
        date: form.date,
        customer_invoice_total: form.customer_invoice_total,
        actually_paid_total: form.actually_paid_total,
        bill_list: billList,
      }
      dispatch(onSaveCustomerVatInvoice(data))
    } else {
      handleAlert('custom', 'กรุณากรอกวันที่ และเลขที่ใบวางบิลให้ครบถ้วน')
    }
  }

  const onSelectedBill = (
    checked_value,
    bill_total,
    seleced_bill_no,
    actually_paid_total,
    payment_duration,
  ) => {
    let newList = form.list
    let checkedBillIndex = newList.findIndex(bill => bill.bill_no === seleced_bill_no)
    let tmpBillList = billList
    let item = { bill_no: seleced_bill_no, payment_duration: payment_duration }
    newList[checkedBillIndex].is_checked = checked_value
    let newTotal = form.customer_invoice_total
    let newActuallyPaid = form.actually_paid_total
    if (checked_value === true) {
      if(payment_duration !== 'CN') {
        newTotal = Number(newTotal) + Number(bill_total)
        newActuallyPaid = Number(newActuallyPaid) + Number(actually_paid_total)
      } else {
        newTotal = Number(newTotal) - Number(bill_total)
        newActuallyPaid = Number(newActuallyPaid) - Number(actually_paid_total)
      }
      tmpBillList.push(item)
      setBillList(tmpBillList)
    } else {
      if(payment_duration !== 'CN') {
        newTotal = Number(newTotal) - Number(bill_total)
        newActuallyPaid = Number(newActuallyPaid) - Number(actually_paid_total)
      } else {
        newTotal = Number(newTotal) + Number(bill_total)
        newActuallyPaid = Number(newActuallyPaid) + Number(actually_paid_total)
      }
      tmpBillList = tmpBillList.filter(
        (bill) => bill.bill_no !== seleced_bill_no
      )
      setBillList(tmpBillList)
    }
    newTotal = newTotal.toFixed(2)
    newActuallyPaid= newActuallyPaid.toFixed(2)
    setForm({
      ...form,
      customer_invoice_total: newTotal,
      actually_paid_total: newActuallyPaid,
      list: newList,
    })
  }

  const onCancelInvoice = () => {
    if (deletedInvoice.id !== null) {
      setIsLoading(true)
      dispatch(onCancelCustomerVatInvoice(deletedInvoice.id))
    }
  }

  const onClearInput = () => {
    setForm(DEFAULT_FORM)
    setDeletedInvoice(DEFAULT_DELETED_INVOICE)
    setBillList([])
  }

  const onChangeCustomer = (current_value) => {
    onClearInput()
    setForm((latestForm) => ({ ...latestForm, customer_code: current_value }))
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'inline-block' }}>
          <h1>รวมยอดบิลขายสินค้า [ลูกค้า]</h1>
        </div>
        <div style={{ display: 'inline-block' }}>
          <Popconfirm
            title="คุณต้องการล้างหน้าจอใช่หรือไม่ ?"
            okText="ใช่"
            cancelText="ยกเลิก"
            onConfirm={onClearInput}
          >
            <Button
              type="default"
              danger
              icon={<ClearOutlined />}
              size="large"
              block
            >
              ล้างหน้าจอ
            </Button>
          </Popconfirm>
        </div>
      </div>
      <SearchCustomerVatPopUp
        visible={isShowCustomerPopUp}
        onCancel={onCancelCustomerPopUp}
        onClick={onSelectCustomerPopup}
      />
      <SearchCustomerVatInvoicePopup
        visible={isShowCustomerInvoicePopUp}
        onCancel={onCancelCustomerInvoicePopUp}
        onClick={onSelectCustomerInvoicePopup}
        onDeleteItem={onDeleteCustomerInvoice}
        customerName={form.customer_name}
      />
      <Spin spinning={isLoading}>
        <div className="form-wrapper-vat">
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                onSearch={(value) => {
                  onSearchCustomerCode(value)
                }}
                inputOption="search"
                label="รหัสลูกค้า"
                placeholder="รหัสลูกค้า"
                isLoading={false}
                required={true}
                value={form.customer_code}
                onChange={(event) => {
                  onChangeCustomer(event.target.value.toUpperCase())
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ชื่อลูกค้า"
                width="300px"
                value={form.customer_name}
                disabled={true}
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="วันที่เริ่มค้นหา"
                placeholder="เลือกวัน"
                defaultValue={moment()}
                value={form.start_date && moment(form.start_date)}
                inputOption="date_picker"
                onChange={(_, dateString) => {
                  setForm({ ...form, start_date: dateString })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="วันที่สิ้นสุด"
                placeholder="เลือกวัน"
                defaultValue={moment()}
                value={form.end_date && moment(form.end_date)}
                inputOption="date_picker"
                onChange={(_, dateString) => {
                  setForm({ ...form, end_date: dateString })
                }}
              />
            </div>
            <div className="input-item">
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="medium"
                onClick={onSearchClick}
                style={{ width: '184px' }}
              >
                ค้นหา
              </Button>
            </div>
          </div>
          <Divider style={{ margin: '0px 0px 10px' }} />
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                className="text-align-right"
                label="ยอดรวมรายการที่เลือก"
                width="125px"
                value={form.actually_paid_total}
                disabled={true}
              />
            </div>
            <div className="input-item">
              <InlineInput
                onSearch={(value) => {
                  onSearchCustomerInvoice(value)
                }}
                inputOption="search"
                label="เลขที่ใบวางบิล"
                width="300px"
                isLoading={false}
                value={form.customer_invoice_no}
                onChange={(event) => {
                  setForm({
                    ...form,
                    customer_invoice_no: event.target.value.toUpperCase(),
                  })
                }}
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="วันที่วางบิล"
                placeholder="เลือกวัน"
                defaultValue={moment()}
                value={form.date && moment(form.date)}
                inputOption="date_picker"
                onChange={(_, dateString) => {
                  setForm({ ...form, date: dateString })
                }}
              />
            </div>
            <div className="input-item">
              <Button
                type="primary"
                icon={<SaveOutlined />}
                size="medium"
                onClick={onSaveClick}
                style={{ width: '250px' }}
                disabled={form.customer_id === null}
              >
                บันทึกเลขที่ใบวางบิล
              </Button>
            </div>
            <div className="input-item">
              <InlineInput
                className="text-align-right"
                label="ยอดรวมบิล"
                width="125px"
                value={form.customer_invoice_total}
                disabled={true}
              />
            </div>
          </div>
          <div style={{ background: '#f1f1f1', margin: '10px 0px 20px' }}>
            <InvoiceCustomerVatTable
              list={form.list}
              onSelectedBill={onSelectedBill}
            />
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                // inputOption="search"
                label="ใบวางบิลที่จะยกเลิก"
                width="220px"
                placeholder="เลขที่ใบวางบิล"
                isLoading={false}
                value={deletedInvoice.customer_invoice_no}
                onChange={(event) => {
                  if (event.target.value === '') {
                    setDeletedInvoice(DEFAULT_DELETED_INVOICE)
                  } else {
                    setDeletedInvoice({
                      ...deletedInvoice,
                      customer_invoice_no: event.target.value.toUpperCase(),
                    })
                  }
                }}
              />
            </div>
            <div className="input-item">
              <Button
                type="primary"
                size="medium"
                onClick={() => {
                  setDeletedInvoice(DEFAULT_DELETED_INVOICE)
                }}
                style={{ width: '65px' }}
              >
                เคลียร์
              </Button>
            </div>
            <div className="input-item">
              <InlineInput
                label="ชื่อลูกค้า"
                width="300px"
                value={deletedInvoice.customer_name}
                disabled={true}
              />
            </div>
            <div className="input-item">
              <InlineInput
                className="text-align-right"
                width="110px"
                value={deletedInvoice.customer_invoice_total}
                disabled={true}
              />
            </div>
            <div className="input-item">
              <Button
                type="danger"
                icon={<CloseOutlined />}
                size="medium"
                disabled={deletedInvoice.id === null}
                onClick={onCancelInvoice}
                style={{ width: '150px' }}
              >
                ยกเลิก
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  )
}

export default withRouter(CustomerVatInvoicePage)
