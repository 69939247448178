import React, { useState, useEffect } from 'react'
import { Spin, Button, Popconfirm } from 'antd'
import { SaveOutlined, ClearOutlined } from '@ant-design/icons'

import InlineInput from 'components/InlineInput'

import { onGetProductByCode, onSaveProduct } from 'redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import SearchProductNoVatPopUp from 'components/SearchProductNoVatPopUp'
import { handleAlert } from 'utils'

const DEFAULT_FORM = {
  id: null,
  code: '',
  name: '',
  stock1: 0,
  stock2: 0,
}

const DEFAULT_PRODUCT = {
  id: null,
  code: '',
  name: '',
  package_size: '',
  stock1: 0,
  stock2: 0,
  stock_low: null,
  price_in_1: '0',
  price_in_2: '0',
  price_out_cash: '0',
  price_out_atm: '0',
  discount_cash_1: '0.00',
  discount_cash_2: '0.00',
  discount_cash_3: '0.00',
  discount_cash_4: '0.00',
  discount_atm_1: '0.00',
  discount_atm_2: '0.00',
  discount_atm_3: '0.00',
  discount_atm_4: '0.00',
  remark: '',
  unit_id: null,
  vat_type: null,
  movement_type: null,
  physical_stock_zone: null,
  physical_stock_shelf: null,
  product_group: null,
  use_new_price: null,
  is_allow_low: null,
}

const ChangeProductCodePage = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [searchCode, setSearchCode] = useState('')
  const [form, setForm] = useState(DEFAULT_FORM)
  const [product, setProduct] = useState(DEFAULT_PRODUCT)
  const [isShowProductPopUp, setIsShowProductPopUp] = useState(false)
  const dispatch = useDispatch()
  const getProductByCode = useSelector((state) => state.getProductByCode)
  const saveProduct = useSelector((state) => state.saveProduct)

  useEffect(() => {
    document.title = 'เปลี่ยนรหัสสินค้า - NoVat'
  }, [])

  useEffect(() => {
    const { loading, error, finished, data, trace } = getProductByCode
    setIsLoading(loading)
    if (!loading && !error && finished) {
      setForm({
        id: data.id,
        code: data.code,
        name: data.name,
        stock1: data.stock1,
        stock2: data.stock2,
      })
      setProduct(data)
    }
    if (!loading && error) {
      setForm(DEFAULT_FORM)
      setProduct(DEFAULT_PRODUCT)
      handleAlert('error', trace.message || '')
    }
  }, [getProductByCode])

  useEffect(() => {
    const { loading, error, trace, finished, data } = saveProduct
    setIsLoading(loading)
    if (error) {
      setIsLoading(false)
      handleAlert('custom', trace.message || '')
    }
    if (!loading && !error && data && finished) {
      handleAlert('success')
      setForm({
        id: data.id,
        code: data.code,
        name: data.name,
        stock1: data.stock1,
        stock2: data.stock2,
      })
      setSearchCode('')
      setProduct(DEFAULT_PRODUCT)
    }
  }, [saveProduct])

  const onSearchCodeHandler = (code) => {
    if (code !== '') {
      dispatch(onGetProductByCode(code))
    } else {
      setIsShowProductPopUp(true)
    }
  }

  const onCancelProdutPopUp = () => {
    setIsShowProductPopUp(false)
  }

  const onSelectProductPopUp = (data) => {
    onSearchCodeHandler(data.code)
    setIsShowProductPopUp(false)
  }

  const onSaveNewInfo = () => {
    if (product.id !== null) {
      dispatch(onSaveProduct(product))
    } else {
      handleAlert('custom', 'กรุณาค้นหาสินค้าจากด้านบน ถึงจะสามารถแก้ไข และบันทึกได้')
    }
  }

  const onClearProduct = () => {
    setForm(DEFAULT_FORM)
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'inline-block' }}>
          <h1>เปลี่ยนรหัสสินค้า - Novat</h1>
        </div>
        <div style={{ display: 'inline-block' }}>
          <Popconfirm
            title="คุณต้องการล้างหน้าจอใช่หรือไม่ ?"
            okText="ใช่"
            cancelText="ยกเลิก"
            onConfirm={onClearProduct}
          >
            <Button
              type="default"
              danger
              icon={<ClearOutlined />}
              size="large"
              block
            >
              ล้างหน้าจอ
            </Button>
          </Popconfirm>
        </div>
      </div>
      <SearchProductNoVatPopUp
        visible={isShowProductPopUp}
        onCancel={onCancelProdutPopUp}
        onClick={onSelectProductPopUp}
      />
      <Spin spinning={isLoading}>
        <div className="form-wrapper">
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                onSearch={(value) => {
                  onSearchCodeHandler(value)
                }}
                inputOption="search"
                label="รหัสสินค้า"
                placeholder="รหัสสินค้า"
                isLoading={false}
                required={true}
                value={searchCode}
                onChange={(event) => {
                  setSearchCode(event.target.value.toUpperCase())
                  setForm(DEFAULT_FORM)
                  setProduct(DEFAULT_PRODUCT)
                }}
              />
            </div>
          </div>
          <div
            style={{
              padding: '20px 20px',
              margin: '10px 0px',
              border: 'black 1px solid',
            }}
          >
            <div className="input-wrapper">
              <div className="input-item">
                <InlineInput
                  label="รหัสสินค้าปัจจุบัน"
                  placeholder="รหัสสินค้าปัจจุบัน"
                  isLoading={false}
                  disabled
                  value={form.code}
                />
              </div>
              <div className="input-item">
                <InlineInput
                  label="ชื่อสินค้า"
                  width="300px"
                  disabled
                  value={form.name}
                />
              </div>
            </div>
            <div className="input-wrapper">
              <div className="input-item">
                <InlineInput
                  label="สต็อก1"
                  value={form.stock1}
                  width="100px"
                  disabled
                />
              </div>
              <div className="input-item">
                <InlineInput
                  label="สต็อก2"
                  value={form.stock2}
                  width="100px"
                  disabled
                />
              </div>
            </div>
          </div>
          <div style={{ padding: '20px 0px' }}>
            <div className="input-wrapper">
              <div className="input-item">
                <InlineInput
                  label="รหัสสินค้าใหม่"
                  placeholder="รหัสสินค้าใหม่"
                  isLoading={false}
                  required={true}
                  disabled={ product.id === null ? true : false}
                  value={product.code}
                  onChange={(event) => {
                    setProduct({
                      ...product,
                      code: event.target.value.toUpperCase(),
                    })
                  }}
                />
              </div>
              <div className="input-item">
                <InlineInput
                  label="ชื่อสินค้า"
                  width="300px"
                  value={product.name}
                  disabled={ product.id === null ? true : false}
                  onChange={(event) => {
                    setProduct({
                      ...product,
                      name: event.target.value,
                    })
                  }}
                />
              </div>
            </div>
          </div>
          <div className="text-right">
            <Button
              type="primary"
              icon={<SaveOutlined />}
              size="large"
              onClick={() => onSaveNewInfo()}
            >
              บันทึก
            </Button>
          </div>
        </div>
      </Spin>
    </div>
  )
}

export default ChangeProductCodePage
