import { useEffect } from 'react'
import { listCashes, deleteCashes, addCashes } from 'api/cashCheck'
import useAPI from 'hooks/useAPI'

const useRequestListCashes = ({ date, onResetForm }) => {
  const {
    makeRequest: listCashesRequest,
    data: listCashesData,
    loading: listCashesLoading,
  } = useAPI()

  const { makeRequest: deleteCashesRequest, loading: deleteLoading } = useAPI()
  const { makeRequest: addCashesRequest, loading: addCashesLoading } = useAPI()

  useEffect(() => {
    listCashesRequest(() =>
      listCashes({
        date: date,
        record_type: 'open',
      })
    )
    onResetForm()
  }, [listCashesRequest, date, onResetForm])

  // NOTE: async await style
  const onDeleteCash = async (id) => {
    const res = await deleteCashesRequest(() => deleteCashes(id))
    if (res.status === 204) {
      await listCashesRequest(() =>
        listCashes({
          date: date,
          record_type: 'open',
        })
      )
    }
    onResetForm()
  }

  // NOTE: async await style
  const onAddCash = async (body) => {
    await addCashesRequest(() => addCashes(body))
    await listCashesRequest(() =>
      listCashes({
        date: date,
        record_type: 'open',
      })
    )
    onResetForm()
  }

  return {
    listCashesData,
    listCashesLoading,
    onDeleteCash,
    deleteLoading,
    onAddCash,
    addCashesLoading,
    deleteCashesRequest,
  }
}

export default useRequestListCashes
