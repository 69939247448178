import { listProductVat } from "api/products";
import useAPI from "hooks/useAPI";
import { useCallback, useEffect, useState } from 'react'

const useListProductVat = ({ currentPageNumber, pageSize }) => {
    const [searchTerm, setSearchTerm] = useState(undefined)
    const [productCode, setProductCode] = useState(undefined)
    const {
        makeRequest: listProductVatRequset,
        data: listProductVatData,
        loading: isLoadingListProductVatData,
        pagination: listProductVatPagination,
    } = useAPI()

    const onSearchFilerProductsVat = useCallback(({ searchTerm }) => {
        setSearchTerm(searchTerm)
    }, [])

    const onSearchProductsVatCode = useCallback(({ productCode }) => {
        setProductCode(productCode)
    }, [])

    useEffect(() => {
        listProductVatRequset(() =>
        listProductVat({
            page: currentPageNumber,
            pageSize,
            searchTerm,
            code: productCode,
        })
        )
    }, [listProductVatRequset, searchTerm, productCode, currentPageNumber, pageSize])

    return {
        listProductVatData,
        isLoadingListProductVatData,
        listProductVatPagination,
        onSearchFilerProductsVat,
        onSearchProductsVatCode,
    }
}

export default useListProductVat