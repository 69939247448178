import React from 'react'
import { Table } from 'antd'
import { addCommas } from 'utils'
import {
  CheckOutlined
} from '@ant-design/icons'

import moment from 'moment'
import 'moment/locale/th'
moment.locale('th')

const ListBillPaymentMethodTable = (props) => {
  const { list, isVatPage } = props
  const tableColumns = [
    {
      title: 'วันที่เปิดบิล',
      dataIndex: 'bill_date',
      key: 'bill_date',
      width: '80px',
      fixed: 'left',
      render: (_, record) => {
        return moment(record.bill_date).format('L')
      },
    },
    {
      title: 'เลขที่บิล',
      dataIndex: 'bill_no',
      key: 'bill_no',
      sorter: (a, b) => ('' + a.bill_no).localeCompare(b.bill_no),
      sortDirections: ['ascend', 'descend'],
      width: '120px',
      fixed: 'left',
      render: (text) => <div className="break-word">{text}</div>,
    },
    {
      title: 'ชื่อลูกค้า',
      dataIndex: 'customer_name',
      key: 'customer_name',
      sorter: (a, b) => ('' + a.customer_name).localeCompare(b.customer_name),
      sortDirections: ['ascend', 'descend'],
      width: '250px',
      fixed: 'left',
      render: (text) => <div className="break-word">{text}</div>,
    },
    {
      title: 'ยอดบิล',
      dataIndex: 'grand_total',
      key: 'grand_total',
      width: '100px',
      fixed: 'left',
      render: (text) => (
        <div className="text-align-right">{addCommas(text)}</div>
      ),
    },
    {
      title: 'ช่องทางการชำระ',
      dataIndex: 'payment_method',
      key: 'payment_method',
      sorter: (a, b) => ('' + a.payment_method).localeCompare(b.payment_method),
      sortDirections: ['ascend', 'descend'],
      width: '120px',
      render: (text) => <div className="break-word">{text}</div>,
    },
    {
      title: 'VAT',
      dataIndex: 'is_included_vat',
      key: 'is_included_vat',
      width: '50px',
      render: (_, record) => {
        return <>{record.is_included_vat ? <CheckOutlined /> : ''}</>
      },
    },
  ]

  const tableVatColumns = [
    {
      title: 'วันที่เปิดบิล',
      dataIndex: 'bill_vat_date',
      key: 'bill_vat_date',
      width: '80px',
      fixed: 'left',
      render: (_, record) => {
        return moment(record.bill_vat_date).format('L')
      },
    },
    {
      title: 'เลขที่บิล',
      dataIndex: 'bill_vat_no',
      key: 'bill_vat_no',
      sorter: (a, b) => ('' + a.bill_vat_no).localeCompare(b.bill_vat_no),
      sortDirections: ['ascend', 'descend'],
      width: '120px',
      fixed: 'left',
      render: (text) => <div className="break-word">{text}</div>,
    },
    {
      title: 'ชื่อลูกค้า',
      dataIndex: 'customer_name',
      key: 'customer_name',
      sorter: (a, b) => ('' + a.customer_name).localeCompare(b.customer_name),
      sortDirections: ['ascend', 'descend'],
      width: '250px',
      fixed: 'left',
      render: (text) => <div className="break-word">{text}</div>,
    },
    {
      title: 'ยอดบิล',
      dataIndex: 'grand_total_with_vat',
      key: 'grand_total_with_vat',
      width: '100px',
      fixed: 'left',
      render: (text) => (
        <div className="text-align-right">{addCommas(text)}</div>
      ),
    },
    {
      title: 'ช่องทางการชำระ',
      dataIndex: 'payment_method',
      key: 'payment_method',
      sorter: (a, b) => ('' + a.payment_method).localeCompare(b.payment_method),
      sortDirections: ['ascend', 'descend'],
      width: '120px',
      render: (text) => <div className="break-word">{text}</div>,
    },
    {
      title: 'VAT',
      dataIndex: 'product_is_included_vat',
      key: 'product_is_included_vat',
      width: '50px',
      render: (_, record) => {
        return <>{record.product_is_included_vat ? <CheckOutlined /> : ''}</>
      },
    },
  ]

  return (
    <div>
      <Table
        rowKey={(record) => `id+${record.id}`}
        dataSource={list}
        columns={isVatPage ? tableVatColumns : tableColumns}
        pagination={{
          simple: true,
          defaultPageSize: 20,
          showSizeChanger: true,
          position: ['bottomRight'],
          pageSizeOptions: ['10', '20', '50', '100'],
        }}
        size="small"
        scroll={{ x: 850 }}
        style={{
          maxWidth: '900px',
          height: '760px',
          minHeight: '100%',
          backgroundColor: '#FFFFFF',
          marginTop: '10px',
        }}
      />
    </div>
  )
}

export default ListBillPaymentMethodTable
