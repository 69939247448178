import React from 'react'
import { Table } from 'antd'
import { addCommas } from 'utils'
import moment from 'moment'
import 'moment/locale/th'
moment.locale('th')

const ListProductFromSupplierTable = (props) => {
  const { list } = props
  const tableColumns = [
    {
      title: 'สถานะสินค้า',
      dataIndex: 'status',
      key: 'status',
      width: '80px',
    },
    {
      title: 'รหัสสินค้า',
      dataIndex: 'code',
      key: 'code',
      width: '100px',
      sorter: (a, b) => ('' + a.code).localeCompare(b.code),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'ชือสินค้า',
      dataIndex: 'name',
      key: 'name',
      width: '300px',
      render: (text) => <div className="break-word">{text}</div>,

    },
    {
      title: 'ราคาเข้า',
      dataIndex: 'price_in',
      key: 'price_in',
      width: '100px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
    },
    {
      title: 'ส่วนลด',
      dataIndex: '',
      key: 'v',
      width: '230px',
      render: (_, record) => {
        return `${record.discount_1}+${record.discount_2}+${record.discount_3}+${record.discount_4}`
      },
    },
    {
      title: 'วันที่ล่าสุด',
      dataIndex: 'latest_bought',
      key: 'latest_bought',
      width: '90px',
      className: 'text-aling-right',
      render: (_, record) => {
        return moment(record.latest_bought).format('L')
      },
      sorter: (a, b) => ('' + a.latest_bought).localeCompare(b.latest_bought),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'ยอดคงเหลือ',
      dataIndex: 'stock_total',
      key: 'stock_total',
      width: '120px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
      sorter: (a, b) => ('' + a.stock_total).localeCompare(b.stock_total),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'หน่วย',
      dataIndex: 'unit_name',
      key: 'unit_name',
      width: '100px',
    },  
    {
      title: 'จำนวนขั้นต่ำ',
      dataIndex: 'stock_low',
      key: 'stock_low',
      width: '110px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
      sorter: (a, b) => ('' + a.stock_low).localeCompare(b.stock_low),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'ยอดขายเฉลี่ย 3 เดือน',
      dataIndex: 'average_sale_3_month',
      key: 'average_sale_3_month',
      width: '110px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
      sorter: (a, b) => ('' + a.average_sale_3_month).localeCompare(b.average_sale_3_month),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'ยอดขายเดือนล่าสุด',
      dataIndex: 'lastest_sale_this_month',
      key: 'lastest_sale_this_month',
      width: '110px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
      sorter: (a, b) => ('' + a.lastest_sale_this_month).localeCompare(b.lastest_sale_this_month),
      sortDirections: ['ascend', 'descend'],
    },
  ]

  return (
    <div>
      <Table
        rowKey={(record) => `id+${record.id}`}
        dataSource={list}
        columns={tableColumns}
        pagination={{
          simple: true,
          defaultPageSize: 20,
          showSizeChanger: true,
          position: ['bottomRight'],
          pageSizeOptions: ['10', '20', '50', '100'],
        }}
        size="small"
        scroll={{ x: 1400 }}
        rowClassName={(record) => record.status === 'Low' ? 'stock-low-row-color' :  ''}
        style={{
          maxWidth: '1460px',
          height: '740px',
          minHeight: '100%',
          backgroundColor: '#FFFFFF',
          marginTop: '10px',
        }}
      />
    </div>
  )
}

export default ListProductFromSupplierTable
