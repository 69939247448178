/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'
import { Redirect } from 'react-router-dom'
import PinkLayout from 'pages/CustomLayout/PinkLayout'
import MainPage from 'pages/MainPage'
import ProductVatPage from 'pages/ProductVatPage'
import DeleteProductVatPage from 'pages/DeleteProductVatPage'
import StockVatPage from 'pages/StockVatPage'
import CustomerBillVatPage from 'pages/CustomerBillVatPage'
import CustomerVatPage from 'pages/CustomerVatPage'
import SupplierVatPage from 'pages/SupplierVatPage'
import ListProductVatPage from 'pages/ListProductVatPage'
import CarbonPrintPDF from 'components/CarbonPrintPDF'
import ShortPrintPDF from 'components/ShortPrintPDF'
import ProductGroupMappingPage from 'pages/ProductGroupMappingPage'
import FullVatPrintPDF from 'components/FullVatPrintPDF'
import PurchaseHistoryPage from 'pages/PurchaseHistoryPage'
import SalesHistoryPage from 'pages/SalesHistoryPage'
import CustomerCreditNotePage from 'pages/CustomerCreditNotePage'
import CreditNoteCustomerPrintPDF from 'components/CreditNoteCustomerPrintPDF'
import SupplierCreditNotePage from 'pages/SupplierCreditNotePage'
import CreditNoteSupplierPrintPDF from 'components/CreditNoteSupplierPrintPDF'
import DeliveryOrderPage from 'pages/DeliveryOrderPage'
import DeliveryOrderPDF from 'components/DeliveryOrderPDF'
import DepositReceiptVatPage from 'pages/DepositReceiptVatPage'
import DepositReceiptVatPDF from 'components/DepositReceiptVatPDF'
import SupplierVatInvoicePage from 'pages/SupplierVatInvoicePage'
import PaymentSupplierVatInvoicePage from 'pages/PaymentSupplierVatInvoicePage'
import CustomerVatInvoicePage from 'pages/CustomerVatInvoicePage'
import PaymentCustomerVatInvoicePage from 'pages/PaymentCustomerVatInvoicePage'
import SupplierVatInvoicePDF from 'components/SupplierVatInvoicePDF'
import CustomerVatInvoicePDF from 'components/CustomerVatInvoicePDF'
import ListUnpaidSupplierVatInvoicePage from 'pages/ListUnpaidSupplierVatInvoicePage'
import ListUnpaidCustomerVatInvoicePage from 'pages/ListUnpaidCustomerVatInvoicePage'
import ListBillVatPaymentMethodPage from 'pages/ListBillVatPaymentMethodPage'
import ForbiddenPage from 'pages/Error/ForbiddenPage'

const PRIVATE_ROUTES = [
  {
    path: '/vat/product/delete',
    component: DeleteProductVatPage,
    permission: 'products_vat.BLAD_PINK_access_deleteproduct',
  },
  {
    path: '/vat/product/list',
    component: ListProductVatPage,
    permission: 'products_vat.BLAD_PINK_view_productlist',
  },
  {
    path: '/vat/product',
    component: ProductVatPage,
    permission: 'products_vat.BLAD_PINK_access_product',
  },
  {
    path: '/vat/stock',
    component: StockVatPage,
    permission: 'stocks_vat.BLAD_PINK_access_stock',
  },
  {
    path: '/vat/customer-bill/carbon-print',
    component: CarbonPrintPDF,
    permission: 'customer_bills_vat.BLAD_PINK_access_bill',
  },
  {
    path: '/vat/customer-bill/short-print',
    component: ShortPrintPDF,
    permission: 'customer_bills_vat.BLAD_PINK_access_bill',
  },
  {
    path: '/vat/customer-bill/full-print',
    component: FullVatPrintPDF,
    permission: 'customer_bills_vat.BLAD_PINK_access_bill',
  },
  {
    path: '/vat/customer-bill',
    component: CustomerBillVatPage,
    permission: 'customer_bills_vat.BLAD_PINK_access_bill',
  },
  {
    path: '/vat/customer',
    component: CustomerVatPage,
    permission: 'customers_vat.BLAD_PINK_access_customer',
  },
  {
    path: '/vat/supplier',
    component: SupplierVatPage,
    permission: 'suppliers_vat.BLAD_PINK_access_supplier',
  },
  {
    path: '/vat/product-group-mapping',
    component: ProductGroupMappingPage,
    permission: 'products_vat.BLAD_PINK_access_normalvatgroup',
  },
  {
    path: '/vat/purchase-history',
    component: PurchaseHistoryPage,
    permission: 'customer_bills_vat.BLAD_PINK_view_buyhistory',
  },
  {
    path: '/vat/sales-history',
    component: SalesHistoryPage,
    permission: 'customer_bills_vat.BLAD_PINK_view_salehistory',
  },
  {
    path: '/vat/credit-note/customer/print',
    component: CreditNoteCustomerPrintPDF,
    permission: 'customer_bills_vat.BLAD_PINK_access_cndncustomer',
  },
  {
    path: '/vat/credit-note/customer',
    component: CustomerCreditNotePage,
    permission: 'customer_bills_vat.BLAD_PINK_access_cndncustomer',
  },
  {
    path: '/vat/credit-note/supplier/print',
    component: CreditNoteSupplierPrintPDF,
    permission: 'customer_bills_vat.BLAD_PINK_access_cndnsupplier',
  },
  {
    path: '/vat/credit-note/supplier',
    component: SupplierCreditNotePage,
    permission: 'customer_bills_vat.BLAD_PINK_access_cndnsupplier',
  },
  {
    path: '/vat/delivery-order/print',
    component: DeliveryOrderPDF,
    permission: 'customer_bills_vat.BLAD_PINK_access_deliveryorder',
  },
  {
    path: '/vat/delivery-order',
    component: DeliveryOrderPage,
    permission: 'customer_bills_vat.BLAD_PINK_access_deliveryorder',
  },
  {
    path: '/vat/deposit-receipt/print',
    component: DepositReceiptVatPDF,
    permission: 'customer_bills_vat.BLAD_PINK_access_depositreceipt',
  },
  {
    path: '/vat/deposit-receipt',
    component: DepositReceiptVatPage,
    permission: 'customer_bills_vat.BLAD_PINK_access_depositreceipt',
  },
  {
    path: '/vat/supplier-invoice/print',
    component: SupplierVatInvoicePDF,
    permission: 'supplier_vat_invoice.BLAD_PINK_access_svi',
  },
  {
    path: '/vat/supplier-invoice',
    component: SupplierVatInvoicePage,
    permission: 'supplier_vat_invoice.BLAD_PINK_access_svi',
  },
  {
    path: '/vat/payment-supplier-invoice',
    component: PaymentSupplierVatInvoicePage,
    permission: 'supplier_vat_invoice.BLAD_PINK_access_svipayment',
  },
  {
    path: '/vat/unpaid-payment-supplier-invoice',
    component: ListUnpaidSupplierVatInvoicePage,
    permission: 'supplier_vat_invoice.BLAD_PINK_view_sviunpaid',
  },
  {
    path: '/vat/customer-invoice/print',
    component: CustomerVatInvoicePDF,
    permission: 'customer_vat_invoice.BLAD_PINK_access_cvi',
  },
  {
    path: '/vat/customer-invoice',
    component: CustomerVatInvoicePage,
    permission: 'customer_vat_invoice.BLAD_PINK_access_cvi',
  },
  {
    path: '/vat/payment-customer-invoice',
    component: PaymentCustomerVatInvoicePage,
    permission: 'customer_vat_invoice.BLAD_PINK_access_cvipayment',
  },
  {
    path: '/vat/unpaid-payment-customer-invoice',
    component: ListUnpaidCustomerVatInvoicePage,
    permission: 'customer_vat_invoice.BLAD_PINK_view_cviunpaid',
  },
  {
    path: '/vat/list-bill-vat-payment-method',
    component: ListBillVatPaymentMethodPage,
    permission: 'customer_bills_vat.BLAD_PINK_view_dailybillpayment',
  },
]

const INITIAL_ROUTES = [
  {
    path: '/home',
    component: MainPage,
  },
  {
    path: '*',
    component: ({ location }) => {
      if (location.pathname === '/') {
        return <Redirect to={{ pathname: '/home' }} />
      } else {
        return <ForbiddenPage />
      }
    },
    exact: true,
  },
]

export default function PrivateRoute(prop) {
  const filterRoutesFromPermission = () => {
    let filterdRoutes = PRIVATE_ROUTES.filter((route) =>
      prop?.permissions.includes(route.permission)
    )
    return filterdRoutes
  }

  if (!prop?.is_superuser) {
    // Normol User Need Filter Routes
    let filterdRoutes = [
      {
        path: '*',
        exact: true,
        component: ({ location, history, ...rest }) => {
          if (location.pathname.includes('/login')) {
            return (
              <Redirect to={{ pathname: '/', state: { from: location } }} />
            )
          }
          if (location.pathname.includes('/no-vat')) {
            return (
              <Redirect to={{ pathname: '/', state: { from: location } }} />
            )
          }
          if (localStorage.getItem('menu') === 'pink') {
            return <PinkLayout {...rest} />
          }
        },
        routes: [...filterRoutesFromPermission(), ...INITIAL_ROUTES],
      },
    ]
    return filterdRoutes
  } else {
    // Super User No Need Filter Routes
    let SuperUserRoutes = [
      {
        path: '*',
        exact: true,
        component: ({ location, history, ...rest }) => {
          if (location.pathname.includes('/login')) {
            return (
              <Redirect to={{ pathname: '/', state: { from: location } }} />
            )
          }
          if (location.pathname.includes('/no-vat')) {
            return (
              <Redirect to={{ pathname: '/', state: { from: location } }} />
            )
          }
          if (localStorage.getItem('menu') === 'pink') {
            return <PinkLayout {...rest} />
          }
        },
        routes: [...PRIVATE_ROUTES, ...INITIAL_ROUTES],
      },
    ]
    return SuperUserRoutes
  }
}
