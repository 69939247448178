import React from 'react'
import useCloseStoreDataContext from '../../hooks/useCloseStoreDataContext'
import { Table, Spin } from 'antd'
import columns from './columns'
import './style.css'

const ListBillVatTable = () => {
  const {
    listBillVatCashesData,
    listBillVatCashesLoading,
    deleteBillVatCashesLoading,
    onDeleteBillVatCash,
    onClickRow,
    billFormId,
  } = useCloseStoreDataContext()

  const getRowClassName = (record) => {
    if (record.id === billFormId) {
      return 'bill-vat-selected-row cursor-pointer'
    } else if (record.id === 'total') {
      return 'disable-row'
    } else {
      return 'cursor-pointer'
    }
  }

  return (
    <Spin spinning={listBillVatCashesLoading || deleteBillVatCashesLoading}>
      <Table
        rowKey={(record) => `id+${record.id}`}
        dataSource={
          listBillVatCashesData?.length === 1 ? [] : listBillVatCashesData
        }
        columns={columns({ onDeleteBillVatCash })}
        scroll={{ x: 550 }}
        style={{
          maxWidth: '600px',
        }}
        size="small"
        pagination={false}
        rowClassName={getRowClassName}
        onRow={(record) => {
          return {
            onClick: () => {
              onClickRow(record)
            },
          }
        }}
      />
    </Spin>
  )
}

export default ListBillVatTable
