import React from 'react'
import useCloseStoreDataContext from '../hooks/useCloseStoreDataContext'
import { Spin, Form, Input, Space, Button } from 'antd'
import { layout, tailLayout } from 'constants/layout'

const { TextArea } = Input

const BillVatFormCloseStore = () => {
  const {
    billFormId,
    billVatForm,
    onResetBillVatForm,
    onSubmitBillVatForm,
    addBillVatCashesLoading,
    listCashesLoading,
  } = useCloseStoreDataContext()
  const initialValues = {
    bill_no: '',
    bill_total_amount: 0,
    total_cash: 0,
    remark: '',
  }
  const renderStatus = () => {
    return (
      <div style={{ margin: '0px 0px 10px 0px', fontWeight: 700 }}>
        สถานะ :{' '}
        {billFormId === 0 ? (
          <span style={{ color: 'green' }}>สร้างรายการใหม่</span>
        ) : (
          <span style={{ color: 'blue' }}>แก้ไขรายการที่ : {billFormId}</span>
        )}
      </div>
    )
  }

  return (
    <Spin spinning={addBillVatCashesLoading || listCashesLoading}>
      {renderStatus()}
      <Form
        {...layout}
        form={billVatForm}
        name="billVatForm"
        onFinish={onSubmitBillVatForm}
        style={{
          maxWidth: 300,
        }}
        initialValues={initialValues}
      >
        <Form.Item
          name="bill_no"
          label="เลขที่บิล"
          rules={[
            {
              required: true,
              message: 'กรุณาระบุข้อมูลให้ครบถ้วน',
            },
          ]}
        >
          <Input
            onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
          />
        </Form.Item>
        <Form.Item
          name="bill_total_amount"
          label="ยอดบิล"
          rules={[
            {
              required: true,
              message: 'กรุณาระบุข้อมูลให้ครบถ้วน',
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          name="total_cash"
          label="เงินสดสุทธิ"
          rules={[
            {
              required: true,
              message: 'กรุณาระบุข้อมูลให้ครบถ้วน',
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item name="remark" label="หมายเหตุ">
          <TextArea rows={2} />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Space>
            <Button type="primary" htmlType="submit">
              บันทึก
            </Button>
            <Button htmlType="button" onClick={onResetBillVatForm}>
              รีเซ็ต
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Spin>
  )
}

export default BillVatFormCloseStore
