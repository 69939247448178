/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Button, Spin, Input, Table, Popconfirm } from 'antd'
import { SaveOutlined, ClearOutlined, SyncOutlined } from '@ant-design/icons'

import {
  onGetProductByCode,
  onSaveProduct,
  onListProductWithWholesale,
} from 'redux/actions'
import { useSelector, useDispatch } from 'react-redux'

import InlineInput from 'components/InlineInput'
import { handleAlert, addCommas } from 'utils'

const Search = Input.Search

const DEFAULT_FORM = {
  id: null,
  code: '',
  name: '',
  wholesale_quantity: '',
  package_size: '',
  stock1: '',
  stock2: '',
  unit_name: '',
}

const EditWholesaleQuantityPage = () => {
  const columns = [
    {
      title: 'รหัสสินค้า',
      dataIndex: 'code',
      key: 'code',
      render: (text) => (
        <span style={{ color: 'rgb(37, 185, 255)' }}>{text}</span>
      ),
      sorter: (a, b) => ('' + a.code).localeCompare(b.code),
      sortDirections: ['ascend', 'descend'],
      width: '100px',
      fixed: 'left',
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'name',
      key: 'name',
      width: '470px',
      sorter: (a, b) => ('' + a.name).localeCompare(b.name),
      sortDirections: ['ascend', 'descend'],
      fixed: 'left',
    },
    {
      title: 'ปริมาณขั้นต่ำสำหรับราคาขายส่ง',
      dataIndex: 'wholesale_quantity',
      key: 'wholesale_quantity',
      width: '130px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
    },
    {
      title: 'ขนาดบรรจุ',
      dataIndex: 'package_size',
      key: 'package_size',
      width: '110px',
      render: (text) => <div className="text-align-right pr-2">{text}</div>,
    },
    {
      title: 'หน่วย',
      dataIndex: 'unit_name',
      key: 'unit_name',
      width: '100px',
    },
    {
      title: 'สต็อก1',
      dataIndex: 'stock1',
      key: 'stock1',
      width: '110px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
    },
    {
      title: 'สต็อก2',
      dataIndex: 'stock2',
      key: 'stock2',
      width: '110px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
    },
  ]
  const [form, setForm] = useState(DEFAULT_FORM)
  const [productList, setProductList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isListLoading, setIsListLoading] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [filteredProductList, setFilteredProductList] = useState([])
  const [errors, setErrors] = useState({})
  const dispatch = useDispatch()
  const saveProduct = useSelector((state) => state.saveProduct)
  const getProductByCode = useSelector((state) => state.getProductByCode)
  const listProductWithWholesale = useSelector(
    (state) => state.listProductWithWholesale
  )

  const onChangeInputValue = (value, name) => {
    if (errors[name] && value) {
      const tmp_errors = { ...errors }
      delete tmp_errors[name]
      setErrors(tmp_errors)
    }
    setForm({ ...DEFAULT_FORM, [name]: value.toUpperCase() })
  }

  useEffect(() => {
    dispatch(onListProductWithWholesale())
    document.title = 'แก้ไขปริมาณขั้นต่ำสำหรับราคาขายส่ง - NoVat'
  }, [])

  useEffect(() => {
    const { loading, data, finished, error, trace } = listProductWithWholesale
    setIsListLoading(loading)
    if (!error && finished && !loading && data) {
      setProductList(data)
      setFilteredProductList(data)
    }
    if (error && finished && !loading) {
      handleAlert('error', trace.message || '')
    }
  }, [listProductWithWholesale])

  useEffect(() => {
    const { loading, data, finished, error, trace } = getProductByCode
    setIsLoading(loading)
    if (!loading && !error && finished) {
      setForm({ unit_name: form.unit_name, ...data})
    }
    if (!loading && error) {
      setForm(DEFAULT_FORM)
      handleAlert('error', trace.message || '')
    }
  }, [getProductByCode])

  const onSearchProductHandler = (record) => {
    setForm({ ...form, unit_name: record.unit_name })
    dispatch(onGetProductByCode(record.code))
  }

  const onSearchProduct = (code) => {
    dispatch(onGetProductByCode(code))
  }

  useEffect(() => {
    const { loading, data, finished, error, trace } = saveProduct
    setIsLoading(loading)
    if (!loading && !error && finished) {
      handleAlert('success')
      setForm(data)
      replaceNewData(data)
    }
    if (!loading && error) {
      handleAlert('error', trace.message || '')
    }
  }, [saveProduct])

  const onSaveProductHandler = () => {
    if (form.code !== '' && form.id !== null) {
      setErrors({})
      dispatch(onSaveProduct(form))
    } else {
      handleAlert('custom', 'กรุณากรอกรหัสสินค้า')
      setErrors({ ...errors, code: 'กรุณากรอกรหัสสินค้า' })
    }
  }

  useEffect(() => {
    if (productList !== []) {
      handleSearch(searchText)
    }
  }, [productList, searchText])

  const handleSearch = (searchNormal) => {
    const _productList = [...productList]
    const filteredData = _productList.filter(({ code }) => {
      code = code.toString().toLowerCase()
      return code.startsWith(searchNormal.toString().toLowerCase())
    })
    setFilteredProductList(filteredData)
  }

  const replaceNewData = (data) => {
    const oldProductList = [...productList]
    let newProductList = oldProductList.map((item) => {
      if (item.code === data.code) {
        item.wholesale_quantity = data.wholesale_quantity
        return item
      } else {
        return item
      }
    })
    setProductList([...newProductList])
  }

  const onClearSearch = () => {
    setFilteredProductList(productList)
    setSearchText('')
  }

  const onClearInput = () => {
    setForm(DEFAULT_FORM)
  }

  const onRefereshList = () => {
    dispatch(onListProductWithWholesale())
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'inline-block' }}>
          <h1>แก้ไขปริมาณขั้นต่ำสำหรับราคาขายส่ง</h1>
        </div>
        <div style={{ display: 'inline-block' }}>
          <Popconfirm
            title="คุณต้องการล้างหน้าจอใช่หรือไม่ ?"
            okText="ใช่"
            cancelText="ยกเลิก"
            onConfirm={onClearInput}
          >
            <Button
              type="default"
              danger
              icon={<ClearOutlined />}
              size="large"
              block
            >
              ล้างหน้าจอ
            </Button>
          </Popconfirm>
        </div>
      </div>
      <Spin spinning={isLoading}>
        <div className="form-wrapper">
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                onSearch={(value) => {
                  setForm({ ...form, code: value })
                  onSearchProduct(value)
                }}
                inputOption="search"
                label="รหัสสินค้า"
                width="200px"
                placeholder="รหัสสินค้า"
                isLoading={isLoading}
                required={true}
                value={form.code}
                onChange={(event) => {
                  onChangeInputValue(event.target.value, 'code')
                }}
                error={errors.code}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ชื่อสินค้า"
                width="500px"
                value={form.name}
                disabled
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="ปริมาณขั้นต่ำสำหรับราคาขายส่ง"
                width="120px"
                value={form.wholesale_quantity}
                disabled={false}
                onChange={(event) =>
                  setForm({ ...form, wholesale_quantity: event.target.value })
                }
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ขนาดบรรจุ"
                width="120px"
                value={form.package_size}
                disabled
              />
            </div>
            <div className="input-item" style={{ marginTop: '5px' }}>
              {form.unit_name}
            </div>
            <div className="input-item">
              <InlineInput
                label="สต๊อก 1"
                width="100px"
                value={form.stock1}
                disabled
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="สต๊อก 2"
                width="100px"
                value={form.stock2}
                disabled
              />
            </div>
            <div className="input-item" style={{ marginLeft: '40px' }}>
              <Button
                type="primary"
                icon={<SaveOutlined />}
                size="large"
                onClick={onSaveProductHandler}
              >
                บันทึก
              </Button>
            </div>
          </div>
        </div>
      </Spin>
      <Spin spinning={isListLoading}>
        <div className="mb-2 mt-2">
          <Search
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value)
            }}
            placeholder="ค้นหารหัสสินค้า"
            onSearch={handleSearch}
            style={{ width: 200, marginRight: 10 }}
          />
          <Button
            type="primary"
            icon={<ClearOutlined />}
            onClick={onClearSearch}
          >
            เคลียร์ผลการค้นหา
          </Button>
          <Button
            type="default"
            icon={<SyncOutlined />}
            onClick={onRefereshList}
          >
            รีเฟรชรายการสินค้า
          </Button>
        </div>
        <div>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={filteredProductList}
            size="small"
            scroll={{ y: 720, x: 1100 }}
            style={{ height: '730px', maxWidth: '1160px' }}
            pagination={{
              simple: true,
              position: ['bottomRight'],
              defaultPageSize: 20,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '50', '100'],
            }}
            rowClassName={'cursor-pointer'}
            onRow={(record, index) => {
              return {
                onClick: () => {
                  onSearchProductHandler(record)
                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  })
                },
              }
            }}
          />
        </div>
      </Spin>
    </div>
  )
}

export default EditWholesaleQuantityPage
