import React from 'react'
import { Table, Input, Button, Popconfirm } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import styled from 'styled-components'

const InputGroup = styled.div`
  display: flex;
  align-items: center;
  .discount-input {
    width: 60px;
    padding-left: 2px;
    padding-right: 2px;
    text-align: center;
  }
`

const StockProduuctTable = (props) => {
  const { onChange, onDelete } = props
  const tableColumns = [
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: '50px',
      fixed: true,
      render: (_, record) => {
        return (
          <Popconfirm
            title="คุณต้องการลบรายการนี้ใช่ไหม ?"
            okText="ลบ"
            cancelText="ยกเลิก"
            onConfirm={() => onDelete(record)}
          >
            <Button
              size="small"
              type="primary"
              danger
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        )
      },
    },
    {
      title: 'รหัส',
      dataIndex: 'product_code',
      key: 'product_code',
      width: '70px',
      fixed: true,
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'product_name',
      key: 'product_name',
      width: '400px',
      fixed: true,
    },
    {
      title: 'จำนวน',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '80px',
      render: (_, record) => {
        return (
          <Input
            className="text-align-right"
            value={record.quantity}
            size='small'
            onChange={(event) =>
              onChange(record, 'quantity', event.target.value)
            }
          />
        )
      },
    },
    {
      title: 'หน่วย',
      dataIndex: 'unit_name',
      key: 'unit_name',
      width: '80px',
    },
    {
      title: 'ราคาต่อหน่วย',
      dataIndex: 'price_in',
      key: 'price_in',
      width: '130px',
      render: (_, record) => {
        return (
          <Input
            className="text-align-right"
            value={record.price_in}
            size='small'
            onChange={(event) =>
              onChange(record, 'price_in', event.target.value)
            }
          />
        )
      },
    },
    {
      title: 'ส่วนลด',
      dataIndex: '',
      key: 'v',
      width: '460px',
      render: (_, record) => {
        return (
          <InputGroup>
            <Input
              className="discount-input"
              value={record.discount_1}
              size='small'
              onChange={(event) =>
                onChange(record, 'discount_1', event.target.value)
              }
            />
            +
            <Input
              className="discount-input"
              value={record.discount_2}
              size='small'
              onChange={(event) =>
                onChange(record, 'discount_2', event.target.value)
              }
            />
            +
            <Input
              className="discount-input"
              value={record.discount_3}
              size='small'
              onChange={(event) =>
                onChange(record, 'discount_3', event.target.value)
              }
            />
            +
            <Input
              className="discount-input"
              value={record.discount_4}
              size='small'
              onChange={(event) =>
                onChange(record, 'discount_4', event.target.value)
              }
            />
            +
            <Input
              className="discount-input"
              value={record.discount_5}
              size='small'
              onChange={(event) =>
                onChange(record, 'discount_5', event.target.value)
              }
            />
            +
            <Input
              className="discount-input"
              value={record.discount_6}
              size='small'
              onChange={(event) =>
                onChange(record, 'discount_6', event.target.value)
              }
            />
          </InputGroup>
        )
      },
    },
    // {
    //   title: 'ระยะเวลารอคอย',
    //   dataIndex: 'lead_time',
    //   key: 'lead_time',
    //   width: '100px',
    //   render: (_, record) => {
    //     return (
    //       <Input
    //         value={record.lead_time}
    //         size='small'
    //         onChange={(event) =>
    //           onChange(record, 'lead_time', event.target.value)
    //         }
    //       />
    //     )
    //   },
    // },
    {
      title: 'ยอดรวม',
      dataIndex: 'total',
      key: 'total',
      width: '110px',
      render: (text) => <div className="text-align-right pr-2">{text}</div>,
    },
  ]

  return (
    <div>
      <Table
        rowKey={(record) => `id+${record.product_code}`}
        dataSource={props.productList}
        columns={tableColumns}
        pagination={false}
        scroll={{ x: 1500 }}
        style={{ minHeight: '380px', backgroundColor: '#FFFFFF'}}
        size='small'
      />
    </div>
  )
}

export default StockProduuctTable
