import React, { useCallback, useEffect, useState } from 'react'
import { Form, Modal, Spin, Input, Button, Space, Row, Col } from 'antd'
import {
  LeftSquareOutlined,
  CheckSquareOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import useBillPaymentRequest from '../hooks/useBillPaymentRequest'
import { paymentLayout, paymentTailLayout } from 'constants/layout'
import { roundToTwo } from 'utils'

const { TextArea } = Input

const BillPaymentModal = ({ visible, onCancel, bill_no, bill_grand_total }) => {
  const [id, setId] = useState(0)
  const [form] = Form.useForm()
  const [isFullPaid, setIsFullPaid] = useState(false)
  const setFormAfterSuccess = (res) => {
    setId(res?.data.id)
    form.setFieldsValue({ ...res?.data })
  }
  const { billPaymentData, billPaymentLoading, paymentLoading, onAddPayment } =
    useBillPaymentRequest({
      visible,
      bill_no,
      setFormAfterSuccess,
    })

  const initialValues = {
    cash_amount: 0,
    transfer_amount: 0,
    credit_note_amount: 0,
    debit_note_amount: 0,
    total_amount: 0,
    remaining_amount: bill_grand_total,
    remark: '',
  }

  const onResetPaymentForm = useCallback(() => {
    form.resetFields()
  }, [form])

  useEffect(() => {
    if (visible && billPaymentData?.id !== 0) {
      setId(billPaymentData?.id)
      form.setFieldsValue({
        ...billPaymentData,
      })
      if (billPaymentData?.remaining_amount <= 0) {
        setIsFullPaid(true)
      } else {
        setIsFullPaid(false)
      }
    } else if (visible && billPaymentData?.id === 0) {
      setId(0)
      onResetPaymentForm()
      setIsFullPaid(false)
    } else {
      setId(0)
      setIsFullPaid(false)
    }
  }, [billPaymentData, form, onResetPaymentForm, visible])

  const onFormChange = useCallback(
    (_, allValue) => {
      let totalCash = 0
      totalCash += Number(allValue?.cash_amount)
      totalCash += Number(allValue?.transfer_amount)
      totalCash += Number(allValue?.credit_note_amount)
      totalCash -= Number(allValue?.debit_note_amount)
      let remainAmount = roundToTwo(
        Number(bill_grand_total) - totalCash
      ).toFixed(2)
      form.setFieldsValue({
        total_amount: totalCash,
        remaining_amount: remainAmount,
      })
      if (remainAmount <= 0) {
        setIsFullPaid(true)
      } else {
        setIsFullPaid(false)
      }
    },
    [form, bill_grand_total]
  )

  const onSubmitPaymentForm = (value) => {
    onAddPayment({
      id,
      bill_no,
      ...value,
    })
  }

  const onClickAutoInsert = useCallback(
    (field) => {
      let tmpValue = billPaymentData?.billPaymentData
        ? Number(billPaymentData?.billPaymentData)
        : Number(bill_grand_total)
      let credit = form.getFieldValue('credit_note_amount')
      let debit = form.getFieldValue('debit_note_amount')
      tmpValue -= Number(credit)
      tmpValue += Number(debit)
      if (field === 'cash') {
        form.setFieldsValue({
          cash_amount: roundToTwo(tmpValue).toFixed(2),
        })
        onFormChange('', form.getFieldsValue())
      } else {
        form.setFieldsValue({
          transfer_amount: roundToTwo(tmpValue).toFixed(2),
        })
        onFormChange('', form.getFieldsValue())
      }
    },
    [form, billPaymentData, bill_grand_total, onFormChange]
  )

  const renderStatus = () => {
    return (
      <div style={{ margin: '0px 0px 10px 0px' }}>
        สถานะ :{' '}
        {id === 0 ? (
          <span style={{ color: 'green' }}>สร้างรายการใหม่</span>
        ) : (
          <span style={{ color: 'blue' }}>แก้ไขรายการที่บันทึกไว้แล้ว</span>
        )}
      </div>
    )
  }

  const renderModalTitle = () => {
    return (
      <>
        <div style={{ margin: '0px 0px 10px 0px', fontWeight: 900 }}>
          บันทึกการจ่ายเงิน
        </div>
        <div style={{ margin: '0px 0px 5px 0px' }}>บิลเลขที่ : {bill_no}</div>
        <div style={{ margin: '0px 0px 5px 0px' }}>
          ยอดบิล :{' '}
          {billPaymentData?.billPaymentData
            ? billPaymentData?.billPaymentData
            : bill_grand_total}
        </div>
        {renderStatus()}
      </>
    )
  }

  const renderCheckedIcon = () => {
    return (
      <>
        {isFullPaid === true ? (
          <CheckSquareOutlined style={{ color: 'green', fontSize: '32px' }} />
        ) : (
          <ExclamationCircleOutlined style={{ fontSize: '32px' }} />
        )}
      </>
    )
  }

  return (
    <>
      <Spin spinning={billPaymentLoading || paymentLoading}>
        <Modal
          visible={visible}
          width="500px"
          onCancel={onCancel}
          footer={null}
          title={renderModalTitle()}
        >
          <Form
            form={form}
            name="patmentForm"
            onFinish={onSubmitPaymentForm}
            style={{
              maxWidth: 400,
            }}
            initialValues={initialValues}
            onValuesChange={onFormChange}
          >
            <Form.Item
              {...paymentLayout}
              name="credit_note_amount"
              label="ลดหนี้ (ลดยอด)"
              rules={[
                {
                  required: true,
                  message: 'กรุณาระบุข้อมูลให้ครบถ้วน',
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              {...paymentLayout}
              name="debit_note_amount"
              label="เพิ่มหนี้ (เพิ่มยอด)"
              rules={[
                {
                  required: true,
                  message: 'กรุณาระบุข้อมูลให้ครบถ้วน',
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
            <Row>
              <Col span={22}>
                <Form.Item
                  style={{ marginLeft: '16px' }}
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 15 }}
                  name="cash_amount"
                  label="เงินสด"
                  rules={[
                    {
                      required: true,
                      message: 'กรุณาระบุข้อมูลให้ครบถ้วน',
                    },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Button
                  icon={<LeftSquareOutlined />}
                  onClick={() => onClickAutoInsert('cash')}
                />
              </Col>
            </Row>
            <Row>
              <Col span={22}>
                <Form.Item
                  style={{ marginLeft: '16px' }}
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 15 }}
                  name="transfer_amount"
                  label="โอน"
                  rules={[
                    {
                      required: true,
                      message: 'กรุณาระบุข้อมูลให้ครบถ้วน',
                    },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Button
                  icon={<LeftSquareOutlined />}
                  onClick={() => onClickAutoInsert('transfer')}
                />
              </Col>
            </Row>
            <Form.Item
              {...paymentLayout}
              name="total_amount"
              label="Total"
              rules={[
                {
                  required: true,
                  message: 'กรุณาระบุข้อมูลให้ครบถ้วน',
                },
              ]}
            >
              <Input disabled type="number" />
            </Form.Item>
            <Row>
              <Col span={22}>
                <Form.Item
                  shouldUpdate
                  style={{ marginLeft: '16px', fontWeight: 700 }}
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 15 }}
                  name="remaining_amount"
                  label="คงเหลือ"
                  rules={[
                    {
                      required: true,
                      message: 'กรุณาระบุข้อมูลให้ครบถ้วน',
                    },
                  ]}
                >
                  <Input style={{ fontWeight: 700 }} disabled type="number" />
                </Form.Item>
              </Col>
              <Col span={2}>{renderCheckedIcon()}</Col>
            </Row>
            <Form.Item {...paymentLayout} name="remark" label="หมายเหตุ">
              <TextArea rows={2} />
            </Form.Item>
            <Form.Item {...paymentTailLayout}>
              <Space>
                <Button type="primary" size="large" htmlType="submit">
                  บันทึก
                </Button>
                {/* <Button
                  htmlType="button"
                  onClick={onResetPaymentForm}
                  disabled={id === 0 ? false : true}
                >
                  รีเซ็ต
                </Button> */}
              </Space>
            </Form.Item>
          </Form>
        </Modal>
      </Spin>
    </>
  )
}

export default BillPaymentModal
