import { getBillPayment, addBillPayment } from 'api/cashCheck'
import useAPI from 'hooks/useAPI'
import { useEffect } from 'react'
import { handleAlert } from 'utils'

const useBillPaymentRequest = ({ visible, bill_no, setFormAfterSuccess }) => {
  const {
    makeRequest: getBillPaymentRequest,
    data: billPaymentData,
    loading: billPaymentLoading,
  } = useAPI()

  const { makeRequest: addBillPaymentRequest, loading: paymentLoading } =
    useAPI()

  useEffect(() => {
    if (visible === true) {
      getBillPaymentRequest(() =>
        getBillPayment({
          bill_no: bill_no,
        })
      )
    }
  }, [visible, bill_no, getBillPaymentRequest])

  const onAddPayment = async (body) => {
    const res = await addBillPaymentRequest(() => addBillPayment(body))
    if (res?.status === 200) {
      handleAlert('success')
    }
    setFormAfterSuccess(res)
  }

  return {
    billPaymentData,
    billPaymentLoading,
    paymentLoading,
    onAddPayment,
  }
}

export default useBillPaymentRequest
