import React from 'react'
import { Table, Input, Button, Popconfirm } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import styled from 'styled-components'

const InputGroup = styled.div`
  display: flex;
  align-items: center;
  .discount-input {
    width: 60px;
    padding-left: 2px;
    padding-right: 2px;
    text-align: center;
  }
`

const CustomerBillVatTable = (props) => {
  const { onChange, onDelete } = props
  const tableColumns = [
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: '50px',
      fixed: 'left',
      render: (_, record) => {
        return (
          <Popconfirm
            title="คุณต้องการลบรายการนี้ใช่ไหม ?"
            okText="ลบ"
            cancelText="ยกเลิก"
            onConfirm={() => onDelete(record)}
          >
            <Button
              size="small"
              type="primary"
              danger
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        )
      },
    },
    {
      title: 'รหัส',
      dataIndex: 'product_vat_code',
      key: 'product_vat_code',
      width: '70px',
      fixed: 'left',
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'product_vat_name',
      key: 'product_vat_name',
      width: '400px',
      fixed: 'left',
    },
    {
      title: 'จำนวน',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '80px',
      // render: (_, record) => {
      //   return (
      //     <Input
      //       className="text-align-right"
      //       value={record.quantity}
      //       onChange={(event) =>
      //         onChange(record, 'quantity', event.target.value)
      //       }
      //     />
      //   )
      // },
    },
    {
      title: 'หน่วย',
      dataIndex: 'unit_name',
      key: 'unit_name',
      width: '80px',
    },
    {
      title: 'ราคาต่อหน่วย',
      dataIndex: 'price_out',
      key: 'price_out',
      width: '130px',
      className: 'text-align-right',
      render: (_, record) => {
        return (
          <Input
            className="text-align-right"
            value={record.price_out}
            size='small'
            onChange={(event) =>
              onChange(record, 'price_out', event.target.value)
            }
          />
        )
      },
    },
    {
      title: 'ส่วนลด',
      dataIndex: '',
      key: 'v',
      width: '300px',
      render: (_, record) => {
        return (
          <InputGroup>
            <Input
              className="discount-input"
              value={record.discount_1}
              size='small'
              onChange={(event) =>
                onChange(record, 'discount_1', event.target.value)
              }
            />
            +
            <Input
              className="discount-input"
              value={record.discount_2}
              size='small'
              onChange={(event) =>
                onChange(record, 'discount_2', event.target.value)
              }
            />
            +
            <Input
              className="discount-input"
              value={record.discount_3}
              size='small'
              onChange={(event) =>
                onChange(record, 'discount_3', event.target.value)
              }
            />
            +
            <Input
              className="discount-input"
              value={record.discount_4}
              size='small'
              onChange={(event) =>
                onChange(record, 'discount_4', event.target.value)
              }
            />
          </InputGroup>
        )
      },
    },
    {
      title: 'ยอดรวม',
      dataIndex: 'total',
      key: 'total',
      width: '110px',
      render: (text) => <div className="text-align-right">{text}</div>,
    },
    {
      title: 'หมายเหตุ',
      dataIndex: 'remark',
      key: 'remark',
      width: '200px',
      render: (_, record) => {
        return (
          <Input
            value={record.remark}
            size='small'
            onChange={(event) =>
              onChange(record, 'remark', event.target.value)
            }
          />
        )
      },
    },
      // {
      //   title: 'รวม Vat',
      //   dataIndex: 'is_included_vat',
      //   key: 'is_included_vat',
      //   width: '100px',
      //   className: 'text-align-right',
      //   render: (_, record) => <div className="text-align-center">{isIncludedVat ? `รวม - (${percentVat}%)` : 'ไม่รวม'}</div>,
      // },
    {
      title: 'รหัสปกติ',
      dataIndex: 'product_normal_code',
      key: 'product_normal_code',
      width: '70px',
    },
    {
      title: 'ชื่อสินค้าปกติ',
      dataIndex: 'product_normal_name',
      key: 'product_normal_name',
      width: '250px',
    },
    
  ]

  return (
    <div>
      <Table
        rowKey={(record) => `id+${record.product_code}`}
        dataSource={props.productList}
        columns={tableColumns}
        pagination={false}
        scroll={{ x: 1500 }}
        size='small'
        style={{ minHeight: '280px', backgroundColor: '#FFFFFF'}}
      />
    </div>
  )
}

export default CustomerBillVatTable
