import React from 'react'
import { Popconfirm, Button } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { addCommas } from 'utils'

const columns = ({ onDeleteAmends }) => {
  const tableColumns = [
    {
      title: '',
      dataIndex: '',
      key: '',
      width: '40px',
      render: (_, record) => {
        if (record.id === 'total') {
          return <></>
        } else {
          return (
            <Popconfirm
              title="คุณต้องการลบรายการนี้ใช่ไหม ?"
              okText="ลบ"
              cancelText="ยกเลิก"
              onConfirm={() => onDeleteAmends(record.id)}
            >
              <Button
                size="small"
                type="primary"
                danger
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          )
        }
      },
    },
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
      width: '40px',
    },
    {
      title: 'ประเภท',
      dataIndex: 'type',
      key: 'type',
      width: '50px',
      render: (text) =>
        text === 'plus' ? (
          <div className="text-align-center">เพิ่ม</div>
        ) : text === 'minus' ? (
          <div className="text-align-center">ลด</div>
        ) : (
          ''
        ),
    },
    {
      title: 'จำนวนเงิน',
      dataIndex: 'cash_amount',
      key: 'cash_amount',
      width: '60px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
    },
    {
      title: 'หมายเหตุ',
      dataIndex: 'remark',
      key: 'remark',
      width: '150px',
    },
  ]
  return tableColumns
}

export default columns
