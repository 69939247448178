import querystring from 'querystring';
import { unAuthorized } from '../actions'
import { request } from '../../api/request'

/**
 * Assign the redux to an action.
 * @param  {string} type - the type of reducers dispatch.
 * @param  {Object} path - the path route.
 * @param  {Object} params - Object params to map query name and params value.
 * @param  {string} method - the method for rest api
 * @param  {Object} body - the Object data to application body
 * @param  {Function} transform data transform then response
 */


export default function createAction(type, path, params = {}, method = "get", body = {}, transform) {
  return (dispatch) => {
    const url = `${path}?${typeof params === 'string' ? params.substring(1) : querystring.stringify(params)}`;
    const fetchApiStart = () => ({
      type: `REQUEST/${type}`,
    });
    const getDataOk = payload => ({
      type: `OK/${type}`,
      payload,
    });
    const getError = error => ({
      type: `ERROR/${type}`,
      error,
    });

    const tokenToString = () => {
      const token = localStorage.getItem('bearerToken')
      return token
    }

    if (!tokenToString()) {
      return dispatch(unAuthorized())
    }

    const fetchDataWithRetry = delay => request({
      url,
      method,
      data: method === 'post' && body,
      headers: {
        Authorization: 'Bearer ' + tokenToString()
      }
    })
      .then((response) => {
        if (!response.status) {
          const err = new Error();
          err.fetchError = true;
          dispatch(getError(response.status));
          if (response.status >= 400 && response.status < 500) {
            err.clientError = true;
            err.message = 'fetch failed - client error';
          } else {
            err.message = 'fetch failed - retrying';
          }
          throw err;
        }
        return response.data
      })
      .then(transform || (json => json.data))
      .then(json => {
        return dispatch(getDataOk(json))
      })
      .catch((e) => {
        if (e.response.status === 401) {
          return dispatch(unAuthorized())
        }
        return dispatch(getError(e.response.data))
        // eslint-disable-next-line no-console

      });
    dispatch(fetchApiStart());
    return fetchDataWithRetry(300);
  };
}
