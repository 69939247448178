import React from 'react'
import useStoreReportDataContext from 'pages/SummaryStoreReportPage/hooks/useStoreReportDataContext'
import { Table, Spin } from 'antd'
import columns from './columns'

const AmendsTableReport = () => {
  const { reportData, reportDataLoading } = useStoreReportDataContext()

  return (
    <Spin spinning={reportDataLoading}>
      <div
        style={{ fontWeight: 700, fontSize: 18, margin: '10px 0px 10px 0px' }}
      >
        รายการ เพิ่ม/ลด เงินสด
      </div>
      <Table
        rowKey={(record) => `id+${record.id}`}
        dataSource={reportData?.amend_list}
        columns={columns()}
        scroll={{ x: 350 }}
        style={{
          maxWidth: '400px',
        }}
        size="small"
        pagination={false}
      />
    </Spin>
  )
}

export default AmendsTableReport
