import React from 'react'
import { addCommas } from 'utils'

const columns = () => {
  const tableColumns = [
    {
      title: 'เลขที่บิล',
      dataIndex: 'bill_no',
      key: 'bill_no',
      width: '150px',
      fixed: 'left',
    },
    {
      title: 'ยอดบิล',
      dataIndex: 'bill_total_amount',
      key: 'bill_total_amount',
      width: '100px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
    },
    {
      title: 'เงินสด',
      dataIndex: 'total_cash',
      key: 'total_cash',
      width: '100px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
    },
    {
      title: 'หมายเหตุ',
      dataIndex: 'remark',
      key: 'remark',
      width: '150px',
    },
  ]
  return tableColumns
}

export default columns
