export const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
}

export const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
}

export const paymentLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
}

export const paymentTailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
}