/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Spin, Divider, Popconfirm, Modal } from 'antd'
import {
  SaveOutlined,
  ClearOutlined,
  CloseOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons'
import moment from 'moment'

import InlineInput from 'components/InlineInput'
import DiscountInput from 'components/InlineInput/DiscountInput'
import StockVatProductTable from 'components/StockVatProductTable'
import ProductMemberModal from 'components/ProductMemberModal'
import SearchProductVatPopUp from 'components/SearchProductVatPopUp'
import SearchSupplierVatPopUp from 'components/SearchSupplierVatPopUp'
import SearchSupplierBillVatPopUp from 'components/SearchSupplierBillVatPopUp'
import { useQuery, handleAlert } from 'utils'

import {
  fetchPaymentDurationList,
  fetchUnitList,
  onListStockVatType,
  onGetSupplierVatByCode,
  onGetProductVatWithLatestBoughtSupplier,
  onSaveStockVat,
  onDeleteStockVat,
  onGetSupplierBillVatByBillNo,
} from 'redux/actions'

const DEFAULT_FORM = {
  id: 0,
  supplier_id: null,
  supplier_code: '',
  supplier_name: '',
  vat: '7.00',
  bill_no: '',
  bill_date: moment().format('YYYY-MM-DD'),
  bill_type_id: '',
  bill_type_value: '',
  payment_duration: '',
  payment_date: '',
  special_discount_baht: '0',
  special_discount_percent: '0',
  total_before_special_discount: '0',
  total_after_special_discount: '0',
  grand_total_with_vat: '0',
  products_vat: [],
}

const DEFAULT_PRODUCT = {
  id: 0,
  product_vat_id: 0,
  product_vat_code: '',
  product_vat_name: '',
  product_normal_id: '',
  product_normal_code: '',
  product_normal_name: '',
  unit_id: '',
  unit_name: '',
  quantity: '',
  price_in: '',
  discount_1: '0',
  discount_2: '0',
  discount_3: '0',
  discount_4: '0',
  discount_5: '0',
  discount_6: '0',
  latest_bought: '',
  lead_time: null,
  members: [],
}

const StockVatPage = (props) => {
  function useAsyncState(initialValue) {
    const [value, setValue] = useState(initialValue)
    const setter = (x) =>
      new Promise((resolve) => {
        setValue(x)
        resolve(x)
      })
    return [value, setter]
  }
  const query = useQuery()
  const bill_no = query.get('bill_no')
  const [form, setForm] = useAsyncState(DEFAULT_FORM)
  const [product, setProduct] = useAsyncState(DEFAULT_PRODUCT)
  const [memberList, setMemberList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [disableDiscountBaht, setDisableDiscountBaht] = useState(false)
  const [disableDiscountPercent, setDisableDiscountPercent] = useState(false)
  const [isShowMemberList, setIsShowMemberList] = useState(false)
  const [isShowProductPopUp, setIsShowProductPopUp] = useState(false)
  const [isShowSupplierPopUp, setIsShowSupplierPopUp] = useState(false)
  const [isShowBilltPopUp, setIsShowBillPopUp] = useState(false)
  const dispatch = useDispatch()
  const paymentDurationList = useSelector((state) => state.paymentDurationList)
  const unitList = useSelector((state) => state.unitList)
  const listStockVatType = useSelector((state) => state.listStockVatType)
  let deleteStockVat = useSelector((state) => state.deleteStockVat)
  let getSupplierVatByCode = useSelector((state) => state.getSupplierVatByCode)
  let saveStockVat = useSelector((state) => state.saveStockVat)
  let productVatWithLatestBoughtSupplier = useSelector(
    (state) => state.productVatWithLatestBoughtSupplier
  )
  let getSupplierBillVatByBillNo = useSelector(
    (state) => state.getSupplierBillVatByBillNo
  )

  useEffect(() => {
    dispatch(fetchPaymentDurationList())
    dispatch(fetchUnitList())
    dispatch(onListStockVatType())
    getSupplierVatByCode = {}
    productVatWithLatestBoughtSupplier = {}
    saveStockVat = {}
    deleteStockVat = {}
    getSupplierBillVatByBillNo = {}
    document.title = 'รับสินค้าเข้าสต๊อก [VAT]'
  }, [])

  useEffect(() => {
    if (bill_no) {
      setIsLoading(true)
      dispatch(onGetSupplierBillVatByBillNo(bill_no))
    }
  }, [bill_no, props.history])

  useEffect(() => {
    const { error, loading, finished, data, trace } = getSupplierBillVatByBillNo
    if (error) {
      setIsLoading(loading)
      return handleAlert('custom', trace.message || '')
    }
    if (!loading && !error && data && finished) {
      setIsLoading(false)
      setForm(data).then((_form) => {
        initialSpecialDiscount(_form)
      })
      setProduct(DEFAULT_PRODUCT)
    }
  }, [getSupplierBillVatByBillNo])

  useEffect(() => {
    const { error, loading, finished, data, trace } = getSupplierVatByCode
    if (error) {
      setIsLoading(loading)
      handleAlert('custom', trace.message || '')
      setForm({
        ...form,
        supplier_id: 0,
        supplier_name: '',
      })
    }
    if (!loading && !error && data && finished) {
      setIsLoading(loading)
      handleAlert('success')
      setForm({
        ...form,
        supplier_id: data.id,
        supplier_code: data.code,
        supplier_name: data.name,
      })
    }
  }, [getSupplierVatByCode])

  useEffect(() => {
    const { error, loading, finished, data, trace } =
      productVatWithLatestBoughtSupplier
    if (error) {
      setIsLoading(loading)
      return handleAlert('custom', trace.message || '')
    }
    if (!loading && !error && data && finished) {
      handleAlert('success')
      setProduct({
        ...data,
        total: 0,
        id: 0,
      })
      setMemberList(data.members)
      setIsLoading(loading)
    }
  }, [productVatWithLatestBoughtSupplier])

  useEffect(() => {
    const { error, loading, finished, data, trace } = saveStockVat
    if (error) {
      setIsLoading(false)
      return handleAlert('custom', trace.message || '')
    }
    if (!loading && !error && data && finished) {
      setForm(data)
      setIsLoading(false)
      Modal.success({
        centered: true,
        content: 'บันทึกเรียบร้อย',
      })
    }
  }, [saveStockVat])

  useEffect(() => {
    const { error, loading, finished, data, trace } = deleteStockVat
    if (error) {
      setIsLoading(false)
      return handleAlert('custom', trace.message || '')
    }
    if (!loading && !error && data && finished) {
      setForm(DEFAULT_FORM).then((_) => {
        onClearInput()
        setIsLoading(false)
        Modal.success({
          centered: true,
          content: 'ลบเรียบร้อย',
        })
      })
    }
  }, [deleteStockVat])

  const onCancelMemberListModal = () => {
    setIsShowMemberList(false)
  }

  const onSelectMember = (data) => {
    setProduct({
      ...product,
      product_normal_id: data.product_id,
      product_normal_code: data.product_code,
      product_normal_name: data.product_name,
    }).then((product) => {
      setIsShowMemberList(false)
      setMemberList([])
      addProductToList(product)
    })
  }

  const initialSpecialDiscount = (form) => {
    if (Number(form.special_discount_baht) > 0) {
      setDisableDiscountPercent(true)
    } else if (Number(form.special_discount_percent) > 0) {
      setDisableDiscountBaht(true)
    } else {
      setDisableDiscountPercent(false)
      setDisableDiscountBaht(false)
    }
  }

  const roundToTwo = (num) => +(Math.round(num + 'e+2') + 'e-2')

  const sumProductTotal = (product) => {
    let total = 0
    total = Number(product.quantity) * Number(product.price_in)
    total *= Number(product.discount_1)
      ? 1 - Number(product.discount_1) / 100
      : 1
    total *= Number(product.discount_2)
      ? 1 - Number(product.discount_2) / 100
      : 1
    total *= Number(product.discount_3)
      ? 1 - Number(product.discount_3) / 100
      : 1
    total *= Number(product.discount_4)
      ? 1 - Number(product.discount_4) / 100
      : 1
    total *= Number(product.discount_5)
      ? 1 - Number(product.discount_5) / 100
      : 1
    total *= Number(product.discount_6)
      ? 1 - Number(product.discount_6) / 100
      : 1
    return roundToTwo(total).toFixed(2)
  }

  const checkAddBothStock = () => {
    if (product.product_vat_id !== 0) {
      if (
        form.bill_type_value === 'add_both_stock' ||
        form.bill_type_value === 'add_only_stock_vat'
      ) {
        if (
          form.bill_type_value === 'add_both_stock' &&
          memberList.length !== 0
        ) {
          if (Number(product.quantity) > 0 && Number(product.price_in) > 0) {
            setIsShowMemberList(true)
          } else {
            Modal.warning({
              centered: true,
              title: 'ตรวจสอบ',
              content: 'กรุณากรอกจำนวนสินค้า หรือราคาให้ครบถ้วนก่อนทำรายการ',
            })
          }
        } else if (
          form.bill_type_value === 'add_both_stock' &&
          memberList.length === 0
        ) {
          Modal.warning({
            centered: true,
            title: 'ตรวจสอบ',
            content: 'ไม่พบรหัสสินค้าปกติ สำหรับสินค้าแวตรายการนี้',
          })
        } else {
          addProductToList(product)
        }
      } else {
        Modal.warning({
          centered: true,
          title: 'ตรวจสอบ',
          content:
            'กรุณาระบุ "ประเภทการนำเข้าสต๊อก" ให้เรียบร้อยก่อนเพิ่มรายการ',
        })
      }
    } else {
      Modal.warning({
        centered: true,
        title: 'ตรวจสอบ',
        content: 'กรุณากดค้นหาสินค้า ก่อนเพิ่มรายการ',
      })
    }
  }

  const addProductToList = (_product) => {
    let isDuplicatedProduct = false
    form.products_vat.forEach((x) => {
      if (form.bill_type_value === 'add_both_stock') {
        if (x.product_vat_code === _product.product_vat_code) {
          if (x.product_normal_code === _product.product_normal_code) {
            return (isDuplicatedProduct = true)
          }
        }
      } else {
        if (x.product_vat_code === _product.product_vat_code) {
          return (isDuplicatedProduct = true)
        }
      }
    })
    if (!isDuplicatedProduct) {
      if (Number(_product.quantity) > 0 && Number(_product.price_in) > 0) {
        let sumTotal = 0
        sumTotal = sumProductTotal(_product)
        setProduct({
          ..._product,
          total: sumTotal,
        }).then((product) => {
          const oldProduct = form.products_vat
          setForm({
            ...form,
            total_items: oldProduct.length + 1,
            products_vat: [...oldProduct, product],
          })
            .then((form) => {
              onChangeCalCulatedValue(form)
            })
            .then(() => setProduct(DEFAULT_PRODUCT))
        })
      } else {
        Modal.warning({
          centered: true,
          title: 'ตรวจสอบ',
          content: 'กรุณากรอกจำนวนสินค้า และราคาให้ครบถ้วน',
        })
      }
    } else {
      Modal.warning({
        centered: true,
        title: 'ตรวจสอบ',
        content: 'มีสินค้านี้อยู่ในรายการอยู่แล้ว กรุณาเพิ่มสินค้าใหม่',
      })
      setProduct(DEFAULT_PRODUCT)
    }
  }

  const onBillDateChange = (form) => {
    let paymentDate = {}
    let duration = ''
    if (form.payment_duration && form.bill_date) {
      duration = paymentDurationList.data.find(
        (x) => x.id === form.payment_duration
      ).name
      paymentDate = moment(form.bill_date)
        .add(duration, 'days')
        .format('YYYY-MM-DD')
      setForm({
        ...form,
        payment_date: paymentDate,
      })
    }
  }

  const onSelectedType = (bill_id) => {
    let bill_type_id = ''
    let bill_type_value = ''
    listStockVatType.data.forEach((x) => {
      if (x.id === bill_id) {
        bill_type_id = x.id
        bill_type_value = x.value
      }
    })
    setForm({
      ...form,
      bill_type_id,
      bill_type_value,
    })
  }

  const onChangeCalCulatedValue = (form) => {
    let grandTotal = 0
    let sumBeforeDiscount = 0
    let sumAfterDiscount = 0
    let sumOfAllProduct = form.products_vat.reduce(
      (accumulator, currentValue) =>
        Number(accumulator) + Number(currentValue.total),
      0
    )
    sumBeforeDiscount = sumOfAllProduct

    if (
      Number(form.special_discount_baht) > 0 &&
      Number(form.special_discount_percent) === 0
    ) {
      sumAfterDiscount = sumBeforeDiscount - Number(form.special_discount_baht)
    } else if (
      Number(form.special_discount_percent) > 0 &&
      Number(form.special_discount_baht) === 0
    ) {
      sumAfterDiscount =
        sumBeforeDiscount * (1 - Number(form.special_discount_percent) / 100)
    } else {
      sumAfterDiscount = sumBeforeDiscount
    }

    grandTotal =
      sumAfterDiscount * (Number(form.vat) > 0 ? 1 + Number(form.vat) / 100 : 1)
    setForm({
      ...form,
      total_before_special_discount: roundToTwo(sumBeforeDiscount).toFixed(2),
      total_after_special_discount: roundToTwo(sumAfterDiscount).toFixed(2),
      grand_total_with_vat: roundToTwo(grandTotal).toFixed(2),
    })
  }

  const onSearchProductVat = (value) => {
    if (!form.supplier_code) {
      handleAlert('custom', 'กรุณากรอก "รหัสผู้ขาย" ให้เรียบร้อยก่อน')
    } else {
      if (value !== '') {
        dispatch(
          onGetProductVatWithLatestBoughtSupplier(value, form.supplier_code)
        )
      } else {
        setIsShowProductPopUp(true)
      }
    }
  }

  const onCancelProdutPopUp = () => {
    setIsShowProductPopUp(false)
  }

  const onSelectProduct = (data) => {
    onSearchProductVat(data.code)
    setIsShowProductPopUp(false)
  }

  const onEditTableData = (row, key, value) => {
    if (
      ((key === 'quantity' || key === 'price_in') && Number(value) > 0) ||
      (key !== 'quantity' && key !== 'price_in')
    ) {
      let newProducts = []
      form.products_vat.forEach((x) => {
        if (form.bill_type_value === 'add_both_stock') {
          if (
            x.product_normal_code === row.product_normal_code &&
            x.product_vat_code === row.product_vat_code
          ) {
            x[key] = value
            x.total = sumProductTotal(x)
            return newProducts.push(x)
          } else {
            return newProducts.push(x)
          }
        } else {
          if (
            x.product_vat_id === row.product_vat_id &&
            x.product_vat_code === row.product_vat_code
          ) {
            x[key] = value
            x.total = sumProductTotal(x)
            return newProducts.push(x)
          } else {
            return newProducts.push(x)
          }
        }
      })
      setForm({
        ...form,
        products_vat: newProducts,
      }).then((form) => {
        onChangeCalCulatedValue(form)
      })
    } else {
      handleAlert('custom', 'จำนวนสินค้ากับราคา ต้องมีค่ามากกว่า 0')
    }
  }

  const onDeleteProductFromBill = (row) => {
    let tempProduct = form.products_vat
    setIsLoading(true)
    tempProduct = tempProduct.filter((x) => {
      if (form.bill_type_value === 'add_both_stock') {
        return (
          x.product_vat_code !== row.product_vat_code ||
          x.product_normal_code !== row.product_normal_code
        )
      } else {
        return x.product_vat_code !== row.product_vat_code
      }
    })
    setForm({
      ...form,
      total_items: tempProduct.length,
      products_vat: [...tempProduct],
    }).then((form) => {
      setIsLoading(false)
      onChangeCalCulatedValue(form)
    })
  }

  const onSpecialDiscountChange = (value, name) => {
    if (name === 'baht' && Number(value) > 0) {
      setDisableDiscountPercent(true)
      setForm({
        ...form,
        special_discount_percent: '',
      })
    } else if (name === 'percent' && Number(value) > 0) {
      setDisableDiscountBaht(true)
      setForm({
        ...form,
        special_discount_bath: '',
      })
    } else if (Number(value) === 0) {
      setDisableDiscountBaht(false)
      setDisableDiscountPercent(false)
      setForm({
        ...form,
        special_discount_bath: '',
        special_discount_percent: '',
      })
    }
  }

  const onSaveStock = () => {
    if (form.supplier_name && form.bill_type_value && form.bill_date) {
      dispatch(onSaveStockVat(form))
    } else {
      handleAlert('custom', 'กรุณากรอกข้อมูลที่มี * ให้ครบถ้วน')
    }
  }

  const onDeleteBill = () => {
    if (form.id && form.id !== 0) {
      setIsLoading(true)
      dispatch(onDeleteStockVat(form.id))
    } else {
      Modal.warning({
        centered: true,
        title: 'ตรวจสอบ',
        content: 'กรุณาเลือกบิลให้เรียบร้อยก่อนทำรายการนี้',
      })
    }
  }

  ///// NEW version
  const onSearchSupplierCode = (code) => {
    if (code !== '') {
      dispatch(onGetSupplierVatByCode(code))
    } else {
      setIsShowSupplierPopUp(true)
    }
  }

  const onSelectSupplierPopup = (data) => {
    dispatch(onGetSupplierVatByCode(data.code))
    setIsShowSupplierPopUp(false)
  }

  const onCancelSupplierPopUp = () => {
    setIsShowSupplierPopUp(false)
  }

  const onSearchBill = (value) => {
    if (value) {
      dispatch(onGetSupplierBillVatByBillNo(value))
    } else {
      setIsShowBillPopUp(true)
    }
  }

  const onCancelBillPopUp = () => {
    setIsShowBillPopUp(false)
  }

  const onSelectBillPopUp = (data) => {
    onSearchBill(data.bill_no)
    setIsShowBillPopUp(false)
  }

  const onChangeBillNo = (value) => {
    let currentForm = form
    if (form.id !== 0) {
      setForm({
        ...DEFAULT_FORM,
        supplier_id: currentForm.supplier_id,
        supplier_code: currentForm.supplier_code,
        supplier_name: currentForm.supplier_name,
        bill_no: value.toUpperCase(),
      })
    } else {
      setForm({
        ...currentForm,
        bill_no: value.toUpperCase(),
      })
    }
  }

  const onClearInput = () => {
    setForm(DEFAULT_FORM)
    setProduct(DEFAULT_PRODUCT)
    setDisableDiscountPercent(false)
    setDisableDiscountBaht(false)
    setMemberList([])
    setIsLoading(false)
  }

  const onChangeSupplierCode = (current_value) => {
    onClearInput()
    setForm((latestForm) => ({ ...latestForm, supplier_code: current_value }))
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'inline-block' }}>
          <h1>รับสินค้าเข้าสต๊อก [VAT]</h1>
        </div>
        <div style={{ display: 'inline-block' }}>
          <Popconfirm
            title="คุณต้องการล้างหน้าจอใช่หรือไม่ ?"
            okText="ใช่"
            cancelText="ยกเลิก"
            onConfirm={onClearInput}
          >
            <Button
              type="default"
              danger
              icon={<ClearOutlined />}
              size="large"
              block
            >
              ล้างหน้าจอ
            </Button>
          </Popconfirm>
        </div>
      </div>
      <ProductMemberModal
        visible={isShowMemberList}
        members={memberList}
        onCancel={onCancelMemberListModal}
        onClick={onSelectMember}
        title="เลือกรหัสสินค้าปกติ"
      />
      <SearchSupplierVatPopUp
        visible={isShowSupplierPopUp}
        onCancel={onCancelSupplierPopUp}
        onClick={onSelectSupplierPopup}
      />
      <SearchProductVatPopUp
        visible={isShowProductPopUp}
        onCancel={onCancelProdutPopUp}
        onClick={onSelectProduct}
      />
      <SearchSupplierBillVatPopUp
        visible={isShowBilltPopUp}
        onCancel={onCancelBillPopUp}
        onClick={onSelectBillPopUp}
        supplier_name={form.supplier_name}
      />
      <Spin spinning={isLoading ? true : false}>
        <div className="form-wrapper-vat">
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                onSearch={(value) => {
                  onSearchSupplierCode(value)
                }}
                inputOption="search"
                label="รหัสผู้ขาย"
                placeholder="รหัสผู้ขาย"
                isLoading={false}
                value={form.supplier_code}
                onChange={(event) => {
                  onChangeSupplierCode(event.target.value.toUpperCase())
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ชื่อผู้ขาย"
                width="300px"
                required={true}
                value={form.supplier_name}
                disabled={true}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="อัตราภาษีมูลค่าเพิ่ม"
                value={form.vat}
                width="60px"
                onChange={(event) => {
                  setForm({ ...form, vat: event.target.value }).then((form) => {
                    onChangeCalCulatedValue(form)
                  })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ระยะเวลาชำระเงิน"
                placeholder="Select a Duration"
                value={form.payment_duration}
                onChange={(value) => {
                  setForm({ ...form, payment_duration: value }).then((form) => {
                    onBillDateChange(form)
                  })
                }}
                width="80px"
                inputOption="select_input"
                options={paymentDurationList.data || []}
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="ประเภทการนำเข้าสต๊อก"
                placeholder="เลือกประเภท"
                required
                value={form.bill_type_id}
                onChange={(value) => {
                  // setForm({ ...form, bill_type_value: value })
                  onSelectedType(value)
                }}
                width="180px"
                inputOption="select_input"
                options={listStockVatType.data || []}
              />
            </div>
            <div className="input-item">
              <InlineInput
                onSearch={(value) => {
                  onSearchBill(value)
                }}
                inputOption="search"
                label="เลขที่บิล"
                placeholder="เลขที่บิล"
                width="250px"
                isLoading={false}
                required={true}
                value={form.bill_no}
                onChange={(event) => {
                  onChangeBillNo(event.target.value)
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="วันที่เปิดบิล"
                placeholder="เลือกวันเปิดบิล"
                required
                defaultValue={moment()}
                value={form.bill_date && moment(form.bill_date)}
                onChange={(value, dateString) => {
                  setForm({ ...form, bill_date: dateString }).then((form) => {
                    onBillDateChange(form)
                  })
                }}
                inputOption="date_picker"
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="วันที่นัดชำระ"
                disabled
                defaultValue={moment()}
                value={form.payment_date && moment(form.payment_date)}
                inputOption="date_picker"
              />
            </div>
          </div>
          <Divider style={{ margin: '0px 0px 10px' }} />
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                onSearch={(value) => {
                  onSearchProductVat(value)
                }}
                inputOption="search"
                label="รหัสสินค้า"
                placeholder="รหัสสินค้า"
                isLoading={false}
                value={product.product_vat_code}
                onChange={(event) => {
                  setProduct({
                    ...product,
                    product_vat_code: event.target.value.toUpperCase(),
                  })
                }}
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ชื่อสินค้า"
                width="300px"
                value={product.product_vat_name}
                disabled
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-item">
              <InlineInput
                label="จำนวน"
                width="80px"
                required
                value={product.quantity ? product.quantity : ''}
                unit={
                  product.unit_name
                    ? product.unit_name
                    : product.unit_id &&
                      unitList.data.find((x) => x.id === product.unit_id).name
                }
                onChange={(event) =>
                  setProduct({ ...product, quantity: event.target.value })
                }
              />
            </div>
            <div className="input-item">
              <InlineInput
                label="ราคาต่อหน่วย"
                width="90px"
                required
                value={product.price_in ? product.price_in : ''}
                unit="บาท"
                onChange={(event) =>
                  setProduct({ ...product, price_in: event.target.value })
                }
              />
            </div>
            <div className="input-item">
              <DiscountInput
                label="ส่วนลด"
                name1="discount_1"
                name2="discount_2"
                name3="discount_3"
                name4="discount_4"
                name5="discount_5"
                name6="discount_6"
                value1={product.discount_1}
                value2={product.discount_2}
                value3={product.discount_3}
                value4={product.discount_4}
                value5={product.discount_5}
                value6={product.discount_6}
                onChange={(value, name) =>
                  setProduct({ ...product, [name]: value })
                }
                width="150px"
              />
            </div>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              size="large"
              onClick={checkAddBothStock}
              style={{ marginLeft: '50px' }}
            >
              เพิ่มรายการ
            </Button>
          </div>
          <div style={{ background: '#f1f1f1', margin: '10px 0px 20px' }}>
            <div>
              <StockVatProductTable
                productList={form.products_vat}
                onChange={onEditTableData}
                onDelete={onDeleteProductFromBill}
              />
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div
              style={{
                width: '8%',
                display: 'inline-block',
                marginRight: '20px',
              }}
            >
              <div style={{ marginBottom: '10px' }}>
                <Popconfirm
                  title="คุณต้องการลบบิลนี้ใช่หรือไม่ ?"
                  okText="ลบ"
                  cancelText="ยกเลิก"
                  onConfirm={onDeleteBill}
                >
                  <Button
                    type="primary"
                    danger
                    icon={<CloseOutlined />}
                    size="large"
                    style={{ height: '80px' }}
                    block
                  >
                    ยกเลิกบิล
                  </Button>
                </Popconfirm>
              </div>
            </div>
            <div style={{ width: '45%', display: 'inline-block' }}>
              <div className="input-wrapper">
                <div className="input-item">
                  <InlineInput
                    className="text-align-right"
                    label="ส่วนลดพิเศษ"
                    width="150px"
                    value={form.special_discount_baht}
                    unit="บาท"
                    disabled={disableDiscountBaht}
                    onChange={(event) => {
                      onSpecialDiscountChange(event.target.value, 'baht')
                      setForm({
                        ...form,
                        special_discount_baht: event.target.value,
                      }).then((form) => onChangeCalCulatedValue(form))
                    }}
                  />
                </div>
                <div className="input-item">
                  <InlineInput
                    className="text-align-right"
                    width="80px"
                    value={form.special_discount_percent}
                    unit="%"
                    disabled={disableDiscountPercent}
                    disableColon={true}
                    onChange={(event) => {
                      onSpecialDiscountChange(event.target.value, 'percent')
                      setForm({
                        ...form,
                        special_discount_percent: event.target.value,
                      }).then((form) => onChangeCalCulatedValue(form))
                    }}
                  />
                </div>
              </div>
            </div>
            <div style={{ width: '30%', display: 'inline-block' }}>
              <div className="input-wrapper">
                <div className="input-item margin-left-auto">
                  <InlineInput
                    className="text-align-right"
                    label="ยอดก่อนหักส่วนลดพิเศษ"
                    width="180px"
                    value={form.total_before_special_discount}
                    disabled
                  />
                </div>
                <div className="input-item margin-left-auto">
                  <InlineInput
                    className="text-align-right"
                    label="ยอดหลังหักส่วนลดพิเศษ"
                    width="180px"
                    disabled
                    value={form.total_after_special_discount}
                  />
                </div>
                <div className="input-item margin-left-auto">
                  <InlineInput
                    className="text-align-right"
                    label="ยอดรวมภาษ๊"
                    width="180px"
                    disabled
                    value={form.grand_total_with_vat}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                width: '12%',
                marginLeft: 'auto',
                display: 'inline-block',
              }}
            >
              <Button
                type="primary"
                icon={<SaveOutlined />}
                size="large"
                block
                style={{ height: '80px' }}
                onClick={onSaveStock}
              >
                บันทึก
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  )
}

export default withRouter(StockVatPage)
