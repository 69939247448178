/* eslint-disable react-hooks/exhaustive-deps */
import React, { Component } from 'react'
import { Button } from 'antd'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import { onPrintSupplierInvoice } from 'redux/actions'
import { handleAlert, addCommas } from 'utils'
import './style.scss'

const InvoiceBlock = styled.div`
  @page {
    size: 8.5in 11in; 
    margin: 0px;
  }
`
const maximumProductPerPage = 12

const emptyProduct = {
  bill_no: '',
  total: '',
}

const emptyArray = [
  {
    bill_no: '',
    total: '',
  },
  {
    bill_no: '',
    total: '',
  },
  {
    bill_no: '',
    total: '',
  },
  {
    bill_no: '',
    total: '',
  },
  {
    bill_no: '',
    total: '',
  },
  {
    bill_no: '',
    total: '',
  },
  {
    bill_no: '',
    total: '',
  },
  {
    bill_no: '',
    total: '',
  },
  {
    bill_no: '',
    total: '',
  },
  {
    bill_no: '',
    total: '',
  },
  {
    bill_no: '',
    total: '',
  },
  {
    bill_no: '',
    total: '',
  },
]

const mapStateToProps = (state) => {
  return {
    printSupplierInvoice: state.printSupplierInvoice,
  }
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ onPrintSupplierInvoice }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class index extends Component {
    constructor(props) {
      super(props)
      this.state = {
        bill: {},
        printArray: [],
        docListArray: [],
        isLoading: false,
        totalPage: 0,
        isDataAvailable: false,
      }
    }

    componentDidMount() {
      let search = window.location.search
      let params = new URLSearchParams(search)
      let supplier_bill_no = params.get('bill_no')
      let supplier_invoice_no = params.get('supplier_invoice_no')
      let supplier_code = params.get('supplier_code')
      if (supplier_invoice_no === '') {
        this.props.onPrintSupplierInvoice(supplier_bill_no, '', supplier_code)
      } else {
        this.props.onPrintSupplierInvoice(
          '',
          supplier_invoice_no,
          supplier_code
        )
      }
    }

    componentDidUpdate(prevProps, PrevState) {
      const { printSupplierInvoice } = this.props
      if (prevProps.printSupplierInvoice !== printSupplierInvoice) {
        const { error, loading, finished, data, trace } = printSupplierInvoice
        this.setState({ isLoading: loading })
        if (!loading && !error && finished) {
          this.setState({
            bill: data,
            isDataAvailable: true,
          })
          this.splitBill(data.bill_list, data.doc_list)
        }
        if (!loading && error) {
          handleAlert('error', trace.message ? trace.message : '')
          this.setState({ isLoading: loading })
        }
      }
    }

    splitBill = (bill_list, doc_list) => {
      let splitArray = []
      if (bill_list.length > maximumProductPerPage) {
        for (
          let i = 0;
          i < Math.ceil(bill_list.length / maximumProductPerPage);
          i++
        ) {
          splitArray[i] = bill_list.slice(
            i * maximumProductPerPage,
            (i + 1) * maximumProductPerPage
          )
        }
      } else {
        splitArray[0] = bill_list
      }
      this.splitDocList(splitArray, doc_list)
    }

    splitDocList = (splitArray, doc_list) => {
      let splitDocList = []
      if (doc_list.length > maximumProductPerPage) {
        for (
          let i = 0;
          i < Math.ceil(doc_list.length / maximumProductPerPage);
          i++
        ) {
          splitDocList[i] = doc_list.slice(
            i * maximumProductPerPage,
            (i + 1) * maximumProductPerPage
          )
        }
      } else {
        splitDocList[0] = doc_list
      }
      this.fullFillItem(splitArray, splitDocList)
    }

    fullFillItem = (splitArray, splitDocList) => {
      const rowMaximun = 12
      let lastChunk = splitArray.length - 1
      let lastIndex = splitArray[lastChunk].length - 1
      let lastChunkDoc = splitDocList.length - 1
      let lastIndexDoc = splitDocList[lastChunkDoc].length - 1
      for (let y = 0; y < lastChunk + 1; y++) {
        if (y !== lastChunk) {
          for (let i = 0; i < rowMaximun - maximumProductPerPage; i++) {
            splitArray[y].push(emptyProduct)
          }
        } else {
          for (let i = 0; i < rowMaximun - lastIndex - 1; i++) {
            splitArray[y].push(emptyProduct)
          }
        }
      }
      for (let j = 0; j < lastChunkDoc + 1; j++) {
        if (j !== lastChunkDoc) {
          for (let k = 0; k < rowMaximun - maximumProductPerPage; k++) {
            splitDocList[j].push(emptyProduct)
          }
        } else {
          for (let k = 0; k < rowMaximun - lastIndexDoc - 1; k++) {
            splitDocList[j].push(emptyProduct)
          }
        }
      }
      let diffLength = splitArray.length - splitDocList.length
      let absDiff = Math.abs(diffLength)
      if (diffLength < 0) {
        for (let i = 0; i < absDiff; i++) {
          splitArray.push(emptyArray)
        }
      }
      if (diffLength > 0) {
        for (let i = 0; i < absDiff; i++) {
          splitDocList.push(emptyArray)
        }
      }
      this.setState({
        printArray: [...splitArray],
        totalPage: splitArray.length,
        docListArray: [...splitDocList],
      })
      setTimeout(() => {
        window.print()
      }, 1000)
    }

    handleRenderPage = () => {
      const { bill, printArray, docListArray, totalPage } = this.state
      return printArray.map((bill_list, i) => {
        return (
          <InvoiceBlock key={i}>
            <div id="pdfdiv" className="invoice-supplier-pdf-page {">
              <div className="print-bill-header">
                <div className="company-detail">
                  BL
                </div>
                <div className="invoice-header">
                  <div className="invoice-tag">ใบสำคัญจ่าย</div>
                </div>
              </div>
              <div className="print-bill-customer-detail">
                <div className="customer-bill-detail">
                  <strong>จ่ายให้ : </strong> {bill.name}
                </div>
                <div className="page-index">
                  หน้าที่ {i + 1} จาก {totalPage}
                </div>
              </div>
              {/*------------- Product Table ------------- */}
              <div className="bill-item-list">
                <div className="bill-table">
                  <table>
                    <thead>
                      <tr>
                        <th style={{ width: '7%' }}>
                          <strong>#</strong>
                        </th>
                        <th style={{ width: '63%' }}>
                          <strong>เลขที่บิล</strong>
                        </th>
                        <th style={{ width: '30%' }}>
                          <strong>จำนวนเงิน</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {bill_list &&
                        bill_list.map((bill, index) => {
                          return (
                            <tr key={index}>
                              <td
                                style={{
                                  textAlign: 'right',
                                  paddingRight: '3px',
                                }}
                              >
                                {bill.bill_no ? (
                                  i * maximumProductPerPage + index + 1
                                ) : (
                                  <span>&nbsp;</span>
                                )}
                              </td>
                              <td style={{ paddingLeft: '10px' }}>
                                {bill.bill_no}
                              </td>
                              <td
                                style={{
                                  textAlign: 'right',
                                  paddingRight: '10px',
                                }}
                              >
                                {addCommas(bill.total)}
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                </div>
                <div className="credit-table">
                  <table>
                    <thead>
                      <tr>
                        <th style={{ width: '7%' }}>
                          <strong>#</strong>
                        </th>
                        <th style={{ width: '63%' }}>
                          <strong>ใบลดหนี้ | เพิ่มหนี้</strong>
                        </th>
                        <th style={{ width: '30%' }}>
                          <strong>จำนวนเงิน</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {docListArray[i] &&
                        docListArray[i].map((bill, index) => {
                          return (
                            <tr key={index}>
                              <td
                                style={{
                                  textAlign: 'right',
                                  paddingRight: '3px',
                                }}
                              >
                                {bill.bill_no ? (
                                  i * maximumProductPerPage + index + 1
                                ) : (
                                  <span>&nbsp;</span>
                                )}
                              </td>
                              <td
                                style={{
                                  padding: '0px 5px 0px 10px',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {bill.bill_no}
                              </td>
                              <td
                                style={{
                                  textAlign: 'right',
                                  paddingRight: '10px',
                                }}
                              >
                                {addCommas(bill.total)}
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="total-summary-section">
                <div className="total-section">
                  <div className="bill-remark">
                    <div className="remark-label">
                      <strong>หมายเหตุ :</strong>
                    </div>
                    <div className="remark-content">
                      {bill.payment_detail_list.map((remark, index) => {
                        return (
                          <div className="remark-item" key={index}>
                            {i === totalPage - 1
                              ? `${index + 1}. ${
                                  remark.payment_channel_name
                                } - วันที่ : ${remark.date}`
                              : ' '}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div className="discount-total-vat">
                    <div className="text-total-label">
                      <div>ยอดรวม</div>
                    </div>
                    <div className="number-total">
                      <div>
                        {i === totalPage - 1
                          ? addCommas(bill.grand_total)
                          : ' '}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="footer-section">
                  <br />
                  <div className="sign-box">
                    ผู้จ่ายเงิน
                    ___________________________________________________ : วันที่
                    _________ / _________ / __________________
                  </div>
                  <div className="sign-box">
                    ผู้รับเงิน
                    ___________________________________________________ : วันที่
                    _________ / _________ / __________________
                  </div>
                </div>
              </div>
            </div>
            {i < printArray.length - 1 ? this.renderPageBreak() : ''}
          </InvoiceBlock>
        )
      })
    }

    renderPageBreak = () => {
      return (
        <div style={{ marginTop: '10px' }}>
          <span className="page-break-before" />
          <p className="page-break-after" />
        </div>
      )
    }

    render() {
      const { isDataAvailable } = this.state
      return (
        <div>
          <Button type="primary" className="no-print" onClick={window.print}>
            พิมพ์บิล
          </Button>
          {isDataAvailable && this.handleRenderPage()}
        </div>
      )
    }
  }
)
