/* eslint-disable react-hooks/exhaustive-deps */
import React, { Component } from 'react'
import { Button } from 'antd'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import {
  onGetQuotation,
  onGetCustomerByCode,
  onListQODurationUnit,
} from 'redux/actions'
import { handleAlert, ArabicNumberToText, addCommas, roundToFour } from 'utils'
import Logo from 'assets/logo.jpg'
import './style.scss'

const FullPrintBlock = styled.div`
  @page {
    size: A4;
    margin: 0px;
  }
`

const mapStateToProps = (state) => {
  return {
    getQuotation: state.getQuotation,
    getCustomerByCode: state.getCustomerByCode,
    listQODurationUnit: state.listQODurationUnit,
  }
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { onGetQuotation, onGetCustomerByCode, onListQODurationUnit },
    dispatch
  )

const emptyProduct = {
  code: '',
  name: '',
  unit_name: '',
  quantity: '',
  price_out: '',
  discount_1: '',
  discount_2: '',
  discount_3: '',
  discount_4: '',
  total: '',
  remark: '',
}

const maximumProductPerPage = 15 // maximum must less or equal 25 product per page

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class index extends Component {
    constructor(props) {
      super(props)
      this.state = {
        bill: {},
        customer: {},
        printArray: [],
        isLoading: false,
        totalPage: 0,
        isDataAvailable: false,
      }
    }

    componentDidMount() {
      let search = window.location.search
      let params = new URLSearchParams(search)
      let doc_no = params.get('doc_no')
      this.getBillFromQuery(doc_no)
      this.props.onListQODurationUnit()
    }

    componentDidUpdate(prevProps, PrevState) {
      const { getQuotation, getCustomerByCode, listQODurationUnit } = this.props
      if (prevProps.listQODurationUnit !== listQODurationUnit) {
        const { error, loading, finished, trace } = listQODurationUnit
        // this.setState({ isLoading: loading })
        if (!loading && !error && finished) {
        }
        if (!loading && error) {
          handleAlert('error', trace.message ? trace.message : '')
        }
      }
      if (prevProps.getQuotation !== getQuotation) {
        const { error, loading, finished, data, trace } = getQuotation
        // this.setState({ isLoading: loading })
        if (!loading && !error && finished) {
          this.setState({
            bill: data,
          })
          this.props.onGetCustomerByCode(data.customer_code)
          // this.splitProductVat(bill.products_vat)
        }
        if (!loading && error) {
          handleAlert('error', trace.message ? trace.message : '')
          this.setState({ isLoading: false })
        }
      }

      if (prevProps.getCustomerByCode !== getCustomerByCode) {
        const { error, loading, finished, data, trace } = getCustomerByCode
        // this.setState({ isLoading: loading })
        if (!loading && !error && finished) {
          this.setState({
            customer: data,
          })
          this.splitProductVat(this.state.bill.items)
        }
        if (!loading && error) {
          handleAlert('error', trace.message ? trace.message : '')
          this.setState({ isLoading: false })
        }
      }
    }

    getBillFromQuery = (doc_no) => {
      this.setState({ isLoading: true })
      this.props.onGetQuotation(doc_no)
    }

    renderDuration = (id) => {
      const {
        listQODurationUnit: { data },
      } = this.props
      let duration_name = ''
      data.forEach((x) => {
        if (x.id === id) {
          duration_name = x.name
        }
      })
      return duration_name
    }

    splitProductVat = (products_vat) => {
      let splitArray = []
      if (products_vat.length > maximumProductPerPage) {
        for (
          let i = 0;
          i < Math.ceil(products_vat.length / maximumProductPerPage);
          i++
        ) {
          splitArray[i] = products_vat.slice(
            i * maximumProductPerPage,
            (i + 1) * maximumProductPerPage
          )
        }
      } else {
        splitArray[0] = products_vat
      }
      this.fullFillArray(splitArray)
    }

    fullFillArray = (splitArray) => {
      const rowMaximun = 30
      let lastChunk = splitArray.length - 1
      let lastIndex = splitArray[lastChunk].length - 1
      for (let y = 0; y < lastChunk + 1; y++) {
        if (y !== lastChunk) {
          for (let i = 0; i < rowMaximun - maximumProductPerPage; i++) {
            splitArray[y].push(emptyProduct)
          }
        } else {
          for (let i = 0; i < rowMaximun - lastIndex - 1; i++) {
            splitArray[y].push(emptyProduct)
          }
        }
      }
      this.setState({
        printArray: [...splitArray],
        totalPage: splitArray.length,
        isLoading: false,
        isDataAvailable: true,
      })
      setTimeout(() => {
        window.print()
      }, 1000)
    }

    renderDiscount = (
      discount1,
      discount2,
      discount3,
      discount4,
      product_vat_code
    ) => {
      if (product_vat_code === '') {
        return <td style={{ textAlign: 'center' }}>&nbsp;</td>
      } else {
        let renderDiscount = 0
        renderDiscount = 100 * (1 - Number(discount1) / 100)
        renderDiscount *= 1 - Number(discount2) / 100
        renderDiscount *= 1 - Number(discount3) / 100
        renderDiscount *= 1 - Number(discount4) / 100
        renderDiscount = 100 - renderDiscount
        return (
          <td style={{ textAlign: 'center' }}>
            {this.state.bill.doc_type_value !== 'qr'
              ? this.state.bill.doc_type_value === 'do'
                ? ' '
                : renderDiscount <= 0
                ? ' '
                : roundToFour(renderDiscount) + '%'
              : ' '}
          </td>
        )
      }
    }

    calculatedPrice = (i) => {
      const { bill, totalPage } = this.state
      let renderCalculatedPrice = 0

      if (i === totalPage - 1) {
        if (bill.is_credit_note === true) {
          renderCalculatedPrice =
            Number(bill.bill_total_after_special_discount) -
            Number(bill.total_before_special_discount)
        } else {
          renderCalculatedPrice =
            Number(bill.bill_total_after_special_discount) +
            Number(bill.total_before_special_discount)
        }
      } else {
        renderCalculatedPrice = ''
      }
      return <div>{addCommas(renderCalculatedPrice.toFixed(2))}</div>
    }

    handleRenderPage = () => {
      const { printArray, totalPage, bill, customer } = this.state
      // const { printArray, totalPage } = this.state
      return printArray.map((products, i) => {
        return (
          <FullPrintBlock key={i}>
            <div id="pdfdiv" className="doc-pdf-page-qo">
              <div className="print-bill-header">
                <div className="company-logo">
                  <img src={Logo} alt="Bonlighting Logo" />
                </div>
                <div className="company-detail">
                  <div className="company-name">
                    บริษัท บี โอ เอ็น ไลติ้ง จำกัด
                  </div>
                  <div>68/3 หมู่ 11 ถ.ลำลูกกา ต.ลำลูกกา อ.ลำลูกกา</div>
                  <div>จ.ปทุมธานี 12150</div>
                  <div>สำนักงานใหญ่ เลขประจำตัวผู้เสียภาษี 0135563021857</div>
                  <div>โทร : 02-5692354, แฟกซ์ : 02-5692355</div>
                </div>
                <div className="invoice-header">
                  {bill.doc_type_value === 'qo' ? (
                    <div className="invoice-tag-qo">ใบเสนอราคา</div>
                  ) : bill.doc_type_value === 'qr' ? (
                    <div className="invoice-tag-qr">ใบขอราคา</div>
                  ) : bill.doc_type_value === 'do' ? (
                    <div className="invoice-tag-do">ใบส่งสินค้า</div>
                  ) : (
                    <div className="invoice-tag">ใบสั่งซื้อ</div>
                  )}
                  <div className="bill-no-detail">
                    เลขที่ NO : {bill.doc_no}
                  </div>
                </div>
              </div>
              <div className="print-bill-customer-detail">
                <div className="customer-bill-detail">
                  {/* <div>
                    <strong>รหัสลูกค้า :</strong> {customer.code}
                  </div> */}
                  <div className="detail-block">
                    <strong>ถึง:</strong> {customer.name}
                  </div>
                  {/* <div className="detail-block">
                    <strong>เรียน:</strong> {bill.to_message}
                  </div> */}
                  <div className="detail-block">
                    <strong>ที่อยู่:</strong> {customer.address}
                  </div>
                  <div className="detail-block">
                    <strong>โทร : </strong> {customer.tel ? customer.tel : '-'}{' '}
                    / <strong>FAX : </strong>{' '}
                    {customer.fax ? customer.fax : '-'}
                  </div>
                  <div className="detail-block">
                    <strong>เลขประจำตัวผู้เสียภาษี:</strong> {bill.tax_id}
                  </div>
                  {/* <div className="branch-tax">
                    <div className="branch-name">
                      <strong>สาขา :</strong> {customer.branch_name}
                    </div>
                    <div className="tax-id">
                      <strong>เลขประจำตัวผู้เสียภาษี :</strong>{' '}
                      {customer.tax_id}
                    </div>
                  </div> */}
                  {/* <div>
                    <strong>ที่อยู่ :</strong> {customer.address}
                  </div> */}
                </div>
                <div className="date-detail">
                  <div className="box-spacing">
                    <strong>วันที่ :</strong> {bill.doc_date}
                  </div>
                  <div className="box-spacing">
                    <strong>เรียน:</strong> {bill.to_message}
                  </div>
                  <div>
                    <strong>จาก:</strong> {bill.from_message}
                  </div>
                </div>
                <div className="page-index">
                  หน้าที่ {i + 1} จาก {totalPage}
                </div>
                {/* <div className="page-index">
                  หน้าที่ {i + 1} จาก {totalPage}
                </div> */}
              </div>
              {/*------------- Product Table ------------- */}
              <div className="product-bill-table">
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: '5%' }}>
                        <strong>ลำดับ</strong>
                      </th>
                      <th style={{ width: '8%' }}>
                        <strong>จำนวน</strong>
                      </th>
                      <th style={{ width: '8%' }}>
                        <strong>หน่วย</strong>
                      </th>
                      <th style={{ width: '44%' }}>
                        <strong>รายการสินค้า</strong>
                      </th>
                      <th style={{ width: '8%' }}>
                        <strong>ส่วนลด</strong>
                      </th>
                      <th style={{ width: '12%' }}>
                        <strong>ราคา/หน่วย</strong>
                      </th>
                      <th style={{ width: '15%' }}>
                        <strong>จำนวนเงิน</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {products &&
                      products.map((product, index) => {
                        let productTitle = product.name + ' ' + product.remark
                        return (
                          <tr key={index}>
                            <td>
                              {product.code
                                ? i * maximumProductPerPage + index + 1
                                : ''}
                            </td>
                            <td>{addCommas(product.quantity)}</td>
                            <td>{product.unit_name}</td>
                            <td
                              style={{
                                textAlign: 'left',
                                paddingLeft: '5px',
                                paddingRight: '5px',
                              }}
                            >
                              <div className="product-title-column">
                                {productTitle}
                              </div>
                            </td>
                            {this.renderDiscount(
                              product.discount_1,
                              product.discount_2,
                              product.discount_3,
                              product.discount_4,
                              product.code
                            )}
                            <td
                              style={{
                                textAlign: 'right',
                                paddingRight: '5px',
                              }}
                            >
                              {bill.doc_type_value !== 'qr'
                                ? bill.doc_type_value === 'do'
                                  ? ' '
                                  : addCommas(product.price_out)
                                : ''}
                            </td>
                            <td
                              style={{
                                textAlign: 'right',
                                paddingRight: '5px',
                              }}
                            >
                              {bill.doc_type_value !== 'qr'
                                ? bill.doc_type_value === 'do'
                                  ? ' '
                                  : addCommas(product.total)
                                : ''}
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
              <div className="total-summary-section">
                <div className="last-row-in-table">
                  <div className="col-one"> </div>
                  <div className="col-two"> </div>
                  <div className="col-three"> </div>
                  <div className="col-four">ผิด ตก ยกเว้น E. & O.E.</div>
                  <div className="col-five"> </div>
                  <div className="col-six"> </div>
                  <div className="col-seven"> </div>
                </div>
                <div className="total-section">
                  <div className="bill-remark">
                    <p>หมายเหตุ :</p>
                    <p>{i === totalPage - 1 ? bill.remark : ' '}</p>
                  </div>
                  <div className="discount-total-vat">
                    <div className="text-total-label">
                      <div>รวม</div>
                      <div>ส่วนลด</div>
                      <div>มูลค่าสินค้า</div>
                      <div>ภาษีมูลค่าเพิ่ม 7%</div>
                    </div>
                    <div className="number-total">
                      {bill.doc_type_value !== 'qr' ? (
                        bill.doc_type_value === 'do' ? (
                          ' '
                        ) : (
                          <>
                            <div>
                              {i === totalPage - 1
                                ? addCommas(bill.total_before_special_discount)
                                : ' '}
                            </div>
                            <div>
                              {i === totalPage - 1
                                ? Number(bill.special_discount_baht) > 0
                                  ? addCommas(bill.special_discount_baht)
                                  : bill.special_discount_percent + '%'
                                : ' '}
                            </div>
                            <div>
                              {i === totalPage - 1
                                ? addCommas(bill.total_after_special_discount)
                                : ' '}
                            </div>
                            <div>
                              {i === totalPage - 1
                                ? addCommas(bill.value_added_tax)
                                : ' '}
                            </div>
                          </>
                        )
                      ) : (
                        ' '
                      )}
                    </div>
                  </div>
                </div>
                <div className="grand-total-section">
                  <div className="text-section">
                    <div className="text-label">
                      <span>จำนวนเงิน</span>
                      <br />
                      <span>(ตัวอักษร)</span>
                    </div>
                    <div className="text-grand-total">
                      {i === totalPage - 1
                        ? bill.doc_type_value !== 'qr'
                          ? bill.doc_type_value === 'do'
                            ? ' '
                            : ArabicNumberToText(bill.grand_total)
                          : ' '
                        : ' '}
                    </div>
                  </div>
                  <div className="number-section">
                    <div className="number-label">รวมค่าสินค้าสุทธิ</div>
                    <div className="number-grand-total">
                      {i === totalPage - 1
                        ? bill.doc_type_value !== 'qr'
                          ? bill.doc_type_value === 'do'
                            ? ' '
                            : addCommas(bill.grand_total)
                          : ''
                        : ''}
                    </div>
                  </div>
                </div>
                <div className="footer-section">
                  <div className="section-one">
                    {bill.doc_type_value !== 'qr' ? (
                      <>
                        <div className="row-block">
                          <div className="column-block-one">กำหนดส่งของ</div>
                          <div className="column-block-two">
                            {bill.delivery_due_date}
                          </div>
                          <div className="column-block-three">วัน</div>
                        </div>
                        <div className="row-block">
                          <div className="column-block-one">
                            เงื่อนไขการชำระเงิน
                          </div>
                          <div className="column-block-two">
                            {bill.payment_condition_duration}{' '}
                          </div>
                          <div className="column-block-three">
                            {this.renderDuration(
                              bill.payment_condition_duration_unit_id
                            )}
                          </div>
                        </div>
                        <div className="row-block">
                          <div className="column-block-one">กำหนดรับราคา</div>
                          <div className="column-block-two">
                            {bill.accepted_price_due_date}
                          </div>
                          <div className="column-block-three">
                            {this.renderDuration(
                              bill.accepted_price_due_date_unit_id
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="section-two">
                    {bill.doc_type_value === 'qo' &&
                    bill.is_included_vat === true ? (
                      <>
                        <p style={{ marginBottom: '10px' }}>
                          กรุณาชำระค่าสินค้าที่
                        </p>
                        <p>
                          <strong>ธนคาร:</strong> กสิกรไทย
                        </p>
                        <p>
                          <strong>เลขที่บัญชี:</strong> 076-3-13199-8
                        </p>
                        <p style={{ marginBottom: '0px' }}>
                          <strong>ชื่อบัญชี:</strong> บริษัท บี โอ เอ็น ไลติ้ง
                          จำกัด
                        </p>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="section-three">
                    <p style={{ marginTop: '5px', marginBottom: '20px' }}>
                      ในนาม บริษัท บี โอ เอ็น ไลติ้ง จำกัด
                    </p>
                    <br />
                    <span>
                      ___________________________________________________
                    </span>
                    <p>อนุมัติโดย</p>
                    <p style={{ marginTop: '7px' }}>
                      วันที่&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/{' '}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {i < printArray.length - 1 ? this.renderPageBreak() : ''}
          </FullPrintBlock>
        )
      })
    }

    renderPageBreak = () => {
      return (
        <div style={{ marginTop: '10px' }}>
          <span className="page-break-before" />
          <span className="page-break-after" />
        </div>
      )
    }

    render() {
      const { isDataAvailable } = this.state
      return (
        <div>
          <Button type="primary" className="no-print" onClick={window.print}>
            พิมพ์บิล
          </Button>
          {isDataAvailable && this.handleRenderPage()}
        </div>
      )
    }
  }
)
