import React from 'react'
import styled from 'styled-components'
import { Input } from 'antd'

const InputGroup = styled.div`
  display: flex;
  align-items: center;
  .discount-input {
    width: 60px;
    padding-left: 2px;
    padding-right: 2px;
    text-align: center;
  }
`

const InputLabel = styled.span`
  margin-right: 10px;
  display: inline-block;
`

const DiscountInput = ({
  label,
  name1,
  name2,
  name3,
  name4,
  name5,
  name6,
  value1,
  value2,
  value3,
  value4,
  value5,
  value6,
  onChange,
}) => {
  return (
    <InputGroup>
      <InputLabel className="input-label">{label}:</InputLabel>
      <Input
        className="discount-input"
        name={name1}
        value={value1}
        onChange={(event) => onChange(event.target.value, name1)}
      />
      +
      <Input
        className="discount-input"
        name={name2}
        value={value2}
        onChange={(event) => onChange(event.target.value, name2)}
      />
      +
      <Input
        className="discount-input"
        name={name3}
        value={value3}
        onChange={(event) => onChange(event.target.value, name3)}
      />
      +
      <Input
        className="discount-input"
        name={name4}
        value={value4}
        onChange={(event) => onChange(event.target.value, name4)}
      />
      {name5 && (
        <div>
          +
          <Input
            className="discount-input"
            name={name5}
            value={value5}
            onChange={(event) => onChange(event.target.value, name5)}
          />
        </div>
      )}
      {name6 && (
        <div>
          +
          <Input
            className="discount-input"
            name={name6}
            value={value6}
            onChange={(event) => onChange(event.target.value, name6)}
          />
        </div>
      )}
    </InputGroup>
  )
}

export default DiscountInput
