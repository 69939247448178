import axios from 'axios'

const getURL = () => {
  if (process.env.REACT_APP_STAGE === 'production')
    return 'https://api.bonlightings.com'
  else if (process.env.REACT_APP_STAGE === 'staging')
    return 'https://api-dev.bonlightings.com'
  else return 'https://api-dev.bonlightings.com'
}

export const request = axios.create({
  baseURL: getURL(),
  // baseURL: 'https://bl.luxmaylay.xyz',
  // baseURL: 'http://127.0.0.1:8000',
  // timeout: 5000,
})
