import React, { Component } from 'react'
import { Col, Row, Button, Table, Spin } from 'antd'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { CustomerInput } from 'components/CustomerInput'
import { SearchBox } from 'components/SearchBox'
import { ContactList } from 'components/ContactList'
import { handleAlert } from 'utils'
import './style.scss'

import {
  onGetCustomerVatByCode,
  onSaveCustomerVat,
  onListCustomerVat,
  onGetCustomerType,
  onDeleteCustomerVatContact,
} from 'redux/actions'

const DEFAULT_CUSTOMER = {
  code: '',
  name: '',
  tax_id: '',
  is_headquarters: null,
  branch_name: '',
  address: '',
  tel: '',
  fax: '',
  website: '',
  email: '',
  remark: '',
  customer_type_id: '',
  contacts: [
    {
      id: 0,
      name: '',
      tel: '',
      email: '',
    },
  ],
}

const customerListColumns = [
  {
    title: 'รหัสลูกค้า',
    dataIndex: 'code',
    key: 'code',
    width: '7%',
    render: (text) => (
      <span style={{ color: 'rgb(37, 185, 255)' }}>{text}</span>
    ),
    sorter: (a, b) => ('' + a.code).localeCompare(b.code),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'ชื่อ',
    dataIndex: 'name',
    key: 'name',
    width: '30%',
    sorter: (a, b) => ('' + a.name).localeCompare(b.name),
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'โทรศัพท์',
    dataIndex: 'tel',
    key: 'tel',
    width: '10%',
  },
  {
    title: 'TAX ID',
    dataIndex: 'tax_id',
    key: 'tax_id',
    width: '10%',
  },
  {
    title: 'เว็บไซต์',
    dataIndex: 'website',
    key: 'website',
    width: '20%',
  },
  {
    title: 'อีเมล์',
    dataIndex: 'email',
    key: 'email',
    width: '20%',
  },
]

const mapStateToProps = (state) => {
  return {
    getCustomerType: state.getCustomerType,
    listCustomerVat: state.listCustomerVat,
    getCustomerVatByCode: state.getCustomerVatByCode,
    saveCustomerVat: state.saveCustomerVat,
    deleteCustomerVatContact: state.deleteCustomerVatContact,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onGetCustomerVatByCode,
      onGetCustomerType,
      onListCustomerVat,
      onSaveCustomerVat,
      onDeleteCustomerVatContact,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class CustomerVatPage extends Component {
    constructor(props) {
      super(props)
      this.state = {
        customer: DEFAULT_CUSTOMER,
        isLoading: false,
        isContactLoading: false,
        customerList: [],
        searchList: [],
        isLoadingList: false,
        customerType: [],
        isInvalidData: true,
        tmpContact: [],
        deleteIndex: null,
      }
    }

    componentDidMount() {
      this.props.onGetCustomerType()
      this._listCustomerVat()
      document.title = 'ข้อมูลลูกค้า [VAT]'
    }

    _listCustomerVat = () => {
      this.props.onListCustomerVat()
    }

    componentDidUpdate(prevProps, PrevState) {
      const {
        getCustomerType,
        listCustomerVat,
        getCustomerVatByCode,
        saveCustomerVat,
        deleteCustomerVatContact,
      } = this.props
      if (prevProps.getCustomerType !== getCustomerType) {
        const { error, finished, loading, data, trace } = getCustomerType
        this.setState({ isLoading: loading })
        if (!error && !loading && finished) {
          this.setState({ customerType: data })
        }
        if (!loading && error) {
          handleAlert('custom', trace.message ? trace.message : '')
        }
      }
      if (prevProps.listCustomerVat !== listCustomerVat) {
        const { error, loading, finished, data, trace } = listCustomerVat
        this.setState({ isLoadingList: loading })
        if (!loading && !error && finished) {
          this.setState({
            customerList: data,
            searchList: data,
          })
        }
        if (!loading && error) {
          handleAlert('custom', trace.message ? trace.message : '')
        }
      }
      if (prevProps.getCustomerVatByCode !== getCustomerVatByCode) {
        const { error, loading, finished, data, trace } = getCustomerVatByCode
        this.setState({ isLoading: loading })
        if (!loading && !error && finished) {
          this.setState({
            customer: data,
            isInvalidData: data.tax_id.length === 13 ? false : true,
          })
        }
        if (!loading && error) {
          handleAlert('custom', trace.message ? trace.message : '')
          this.setState({
            customer: { ...DEFAULT_CUSTOMER },
            isLoading: false,
          })
        }
      }
      if (prevProps.saveCustomerVat !== saveCustomerVat) {
        const { loading, error, trace, data, finished } = saveCustomerVat
        this.setState({ isLoading: loading })
        if (!loading && !error && finished) {
          handleAlert('success')
          this.setState({
            customer: data,
          })
          this._listCustomerVat()
        }
        if (!loading && error) {
          handleAlert('custom', trace.message ? trace.message : '')
        }
      }
      if (prevProps.deleteCustomerVatContact !== deleteCustomerVatContact) {
        const { loading, error, trace, finished } = deleteCustomerVatContact
        const { tmpContact, deleteIndex, customer } = this.state
        this.setState({ isContactLoading: loading })
        if (!loading && !error && finished) {
          handleAlert('success')
          tmpContact.splice(deleteIndex, 1)
          this.setState({
            customer: {
              ...customer,
              contacts: tmpContact,
            },
          })
        }
        if (!loading && error) {
          handleAlert('custom', trace.message ? trace.message : '')
        }
      }
    }

    _getCustomer = (code = '') => {
      this.setState({
        isLoading: true,
      })
      const tmpCode = code
      if (tmpCode) {
        this.props.onGetCustomerVatByCode(tmpCode)
      } else {
        handleAlert('custom', 'กรุณากรอกรหัสก่อนค้นหา')
        this.setState({
          isLoading: false,
        })
      }
    }

    handleSaveCustomer = () => {
      this.setState({
        isLoading: true,
      })
      if (
        !this.isInvalidData &&
        this.state.customer.name &&
        this.state.customer.customer_type_id &&
        this.state.customer.branch_name
      ) {
        this.props.onSaveCustomerVat(this.state.customer)
      } else {
        handleAlert('custom', 'กรุณากรอกข้อมูลที่มือเครื่องหมาย * ให้ครับถ้วน')
        this.setState({
          isLoading: false,
        })
      }
    }

    handleSelectedBranch = (event) => {
      let tmp = {}
      if (event.target.id === 'headquarter') {
        tmp = {
          ...this.state.customer,
          is_headquarters: true,
          branch_name: 'สำนักงานใหญ่',
        }
      } else {
        tmp = {
          ...this.state.customer,
          is_headquarters: false,
          branch_name: '',
        }
      }
      this.setState({
        customer: tmp,
      })
    }

    handleInputChange = (event) => {
      let value = ''
      let id = ''
      let tmp = {}
      if (typeof event === 'object' && event.target.id !== 'tax_id') {
        value = event.target.value
        id = event.target.id
        if (id === 'code') {
          tmp = {
            ...this.state.customer,
            [id]: value.toUpperCase(),
          }
        } else {
          tmp = {
            ...this.state.customer,
            [id]: value,
          }
        }
      } else if (typeof event === 'object' && event.target.id === 'tax_id') {
        value = event.target.value
        id = event.target.id
        if (value.length === 13) {
          this.setState({
            isInvalidData: false,
          })
          tmp = {
            ...this.state.customer,
            [id]: value,
          }
        } else if (value.length > 13) {
          this.setState({
            isInvalidData: false,
          })
          tmp = {
            ...this.state.customer,
            [id]: value.substr(0, 13),
          }
        } else {
          this.setState({
            isInvalidData: true,
          })
          tmp = {
            ...this.state.customer,
            [id]: value,
          }
        }
      } else {
        value = event
        id = 'customer_type_id'
        tmp = {
          ...this.state.customer,
          [id]: value,
        }
      }
      this.setState({
        customer: tmp,
      })
    }

    addContact = () => {
      const {
        customer,
        customer: { contacts },
      } = this.state
      const newContact = {
        id: 0,
        name: '',
        tel: '',
        email: '',
      }
      this.setState({
        customer: {
          ...customer,
          contacts: [...contacts, newContact],
        },
      })
    }

    handleContactUpdate = (changeIndex, key, value) => {
      const {
        customer: { contacts },
      } = this.state
      const newData = contacts.map((row, index) => {
        if (index === changeIndex) {
          return {
            ...row,
            [key]: value,
          }
        }
        return row
      })
      this.setState({ customer: { ...this.state.customer, contacts: newData } })
    }

    deleteContactFromIndex = (index, id) => {
      const {
        customer,
        customer: { contacts },
      } = this.state

      let tmpContact = [...contacts]
      if (id !== 0) {
        this.setState({ tmpContact, deleteIndex: index })
        this.props.onDeleteCustomerVatContact(id)
      } else {
        tmpContact.splice(index, 1)
        this.setState({
          customer: {
            ...customer,
            contacts: tmpContact,
          },
        })
      }
    }

    handleResetField = () => {
      this.setState({
        customer: DEFAULT_CUSTOMER,
      })
    }

    handleSearch = (searchText) => {
      const { customerList } = this.state
      const filteredData = customerList.filter(
        ({ code, name, tel, website, email, tax_id }) => {
          code = code.toString().toLowerCase()
          name = name.toString().toLowerCase()
          tel = tel.toString().toLowerCase()
          website = website.toString().toLowerCase()
          email = email.toString().toLowerCase()
          tax_id = tax_id.toString().toLowerCase()
          return (
            code.includes(searchText.toLowerCase()) ||
            name.includes(searchText.toLowerCase()) ||
            tel.includes(searchText.toLowerCase()) ||
            website.includes(searchText.toLowerCase()) ||
            email.includes(searchText.toLowerCase()) ||
            tax_id.includes(searchText.toLowerCase())
          )
        }
      )
      filteredData.sort((a, b) => a.code - b.code)
      this.setState({
        searchList: filteredData,
      })
    }

    render() {
      const {
        customer,
        customer: { contacts },
        isLoading,
        customerType,
        isContactLoading,
      } = this.state
      return (
        <div className="customer-page">
          <h1>ข้อมูลลูกค้า [Customer VAT]</h1>
          <div className="customer-vat-detail">
            <Row gutter={[0, 32]}>
              <Col xs={{ offset: 1, span: 22 }} lg={{ offset: 1, span: 10 }}>
                <CustomerInput
                  label="ลูกค้า"
                  data={customer}
                  getDetail={this._getCustomer}
                  handleInputChange={this.handleInputChange}
                  isLoading={isLoading}
                  customerType={customerType}
                  isVat
                  isInvalidData={this.state.isInvalidData}
                  handleSelectedBranch={this.handleSelectedBranch}
                />
              </Col>
              <Col xs={{ offset: 1, span: 22 }} lg={{ offset: 1, span: 11 }}>
                <div className="add-contact-button">
                  <Button
                    type="primary"
                    onClick={this.addContact}
                    htmlType="submit"
                  >
                    เพิ่มรายชื่อ
                  </Button>
                </div>
                <Spin spinning={isContactLoading}>
                  <table className="ant-table-header contact-table">
                    <thead className="ant-table-thead">
                      <tr>
                        <th id="del">#</th>
                        <th id="name">Name</th>
                        <th id="Tel">Tel</th>
                        <th id="Email">Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      {contacts &&
                        contacts.map((row, index) => (
                          <ContactList
                            key={index}
                            data={row}
                            index={index}
                            handleContactUpdate={this.handleContactUpdate}
                            deleteContact={this.deleteContactFromIndex}
                          />
                        ))}
                    </tbody>
                  </table>
                </Spin>
              </Col>
            </Row>
            <Row>
              <Col xs={{ offset: 8, span: 12 }} lg={{ offset: 10, span: 12 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={this.handleSaveCustomer}
                  disabled={this.state.isInvalidData}
                >
                  บันทึก
                </Button>
                <Button
                  htmlType="button"
                  onClick={this.handleResetField}
                  danger
                >
                  ล้างข้อมูล
                </Button>
              </Col>
            </Row>
          </div>
          <Row>
            <Col xs={{ offset: 1, span: 22 }} lg={{ offset: 1, span: 22 }}>
              <h1 className="title">รายชื่อลูกค้า</h1>
            </Col>
          </Row>
          <div className="customer-list">
            <Row>
              <Col xs={{ offset: 1, span: 11 }}>
                <SearchBox
                  onSearch={this.handleSearch}
                  className={'search-box'}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={{ offset: 1, span: 22 }} lg={{ offset: 1, span: 22 }}>
                <Table
                  scroll={{ x: 1500, y: 680 }}
                  columns={customerListColumns}
                  dataSource={this.state.searchList}
                  loading={this.state.isLoadingList}
                  rowKey={(record) => record.id}
                  size="small"
                  rowClassName={'customer-list-item'}
                  style={{ height: '680px' }}
                  pagination={{
                    simple: true,
                    position: ['bottomRight'],
                    defaultPageSize: 20,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'],
                  }}
                  onRow={(record, _) => {
                    return {
                      onClick: () => {
                        this._getCustomer(record.code)
                        window.scrollTo({
                          top: 0,
                          behavior: 'smooth',
                        })
                      },
                    }
                  }}
                ></Table>
              </Col>
            </Row>
          </div>
        </div>
      )
    }
  }
)
