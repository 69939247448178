import { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { renderRoutes } from 'react-router-config'
import publicRoutes from './publicRoute'
import privateRoutes from './privateRoute'
import privateVatRoutes from './privateVatRoute'
import { getCredential } from 'redux/actions'
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
import splashRouter from './splashRouter'
export default function RouterRender() {
  const authenticate = useSelector((reducers) => reducers.authenticate)
  const dispatch = useDispatch()
  useMemo(() => {
    dispatch(getCredential())
  }, [getCredential])

  if (authenticate.is_loading) {
    return renderRoutes(splashRouter())
  }
  if (authenticate.is_login) {
    if (localStorage.getItem('menu') === 'blue') {
      return renderRoutes(privateRoutes(authenticate))
    } else if (localStorage.getItem('menu') === 'pink') {
      return renderRoutes(privateVatRoutes(authenticate))
    } else {
      return renderRoutes(publicRoutes())
    }
  } else {
    return renderRoutes(publicRoutes())
  }
}
