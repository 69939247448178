/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Table, Spin, Button, Input, Modal } from 'antd'
import { ClearOutlined, SaveOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'

import {
  onListProductPriceGroupWithoutMember,
  onGetVatTypeList,
  onEditPriceByProductPriceGroup,
} from 'redux/actions'

import InlineInput from 'components/InlineInput'
import DiscountInput from 'components/InlineInput/DiscountInput'
import { handleAlert } from 'utils'
import './style.scss'

const Search = Input.Search

const DEFAULT_GROUP = {
  id: '',
  name: '',
}

const DEFAULT_FORM = {
  price_out_cash: '',
  price_out_atm: '',
  discount_cash_1: '',
  discount_cash_2: '',
  discount_cash_3: '',
  discount_cash_4: '',
  discount_atm_1: '',
  discount_atm_2: '',
  discount_atm_3: '',
  discount_atm_4: ' ',
  vat_type_id: '',
}

const EditPricebyProductGroupPage = () => {
  const GROUP_COLUMN = [
    {
      title: 'ชื่อกลุ่ม (Double Click เพื่อยกเลิกกลุ่มที่เลือก)',
      dataIndex: 'name',
      key: 'name',
      className: 'break-word',
    },
  ]
  const [form, setForm] = useState(DEFAULT_FORM)
  const [selectedGroup, setSelectedGroup] = useState(DEFAULT_GROUP)
  const [searchGroup, setSearchGroup] = useState('')
  const [lastestProductGroupList, setLastestProductGroupList] = useState([])
  const [filteredProductGroupList, setFilteredProductGroupList] = useState([])
  const [isProductGroupLoading, setIsProductGroupLoading] = useState(false)
  const [isEditLoading, setIsEditLoading] = useState(false)
  const dispatch = useDispatch()
  const listProductPriceGroupWithoutMember = useSelector(
    (state) => state.listProductPriceGroupWithoutMember
  )
  const vatTypes = useSelector((state) => state.getVatTypeList.data)
  const editPriceByProductPriceGroup = useSelector(
    (state) => state.editPriceByProductPriceGroup
  )

  useEffect(() => {
    document.title = 'แก้ไขราคากลุ่มสินค้า'
    setIsProductGroupLoading(true)
    dispatch(onListProductPriceGroupWithoutMember())
    dispatch(onGetVatTypeList())
  }, [])

  useEffect(() => {
    const { error, data, loading, finished, trace } =
      listProductPriceGroupWithoutMember
    setIsProductGroupLoading(loading)
    if (!error && finished && !loading && data) {
      setLastestProductGroupList(data)
      setFilteredProductGroupList(data)
      setIsProductGroupLoading(false)
    }
    if (error && !loading) {
      setIsProductGroupLoading(false)
      handleAlert('custom', trace.message || '')
    }
  }, [listProductPriceGroupWithoutMember])

  useEffect(() => {
    const { error, data, loading, finished, trace } =
      editPriceByProductPriceGroup
    setIsProductGroupLoading(loading)
    if (!error && finished && !loading && data) {
      Modal.success({
        centered: true,
        title: 'สำเร็จ',
        content: 'แก้ไขราคาเรียบร้อย',
      })
      setSelectedGroup(DEFAULT_GROUP)
      setForm(DEFAULT_FORM)
      setIsEditLoading(false)
    }
    if (error && !loading) {
      setIsEditLoading(false)
      handleAlert('custom', trace.message || '')
    }
  }, [editPriceByProductPriceGroup])

  const handleGroupSearch = (searchGroup) => {
    const filteredData = lastestProductGroupList.filter(({ name }) => {
      name = name.toString().toLowerCase()
      return name.includes(searchGroup.toLowerCase())
    })
    setFilteredProductGroupList(filteredData)
    setIsProductGroupLoading(false)
  }

  const onClearSearchGroup = () => {
    setFilteredProductGroupList(lastestProductGroupList)
    setSearchGroup('')
  }

  useEffect(() => {
    if (lastestProductGroupList !== []) {
      handleGroupSearch(searchGroup)
    }
  }, [lastestProductGroupList, searchGroup])

  const onClickGroupRow = (clickedGroup) => {
    setSelectedGroup({
      id: clickedGroup.id,
      name: clickedGroup.name,
    })
    setForm(DEFAULT_FORM)
  }

  const onSaveProductHandler = () => {
    if (selectedGroup.id === '') {
      handleAlert('custom', 'กรุณาเลือกกลุ่มสินค้าก่อนทำการแก้ไข')
    } else {
      setIsEditLoading(true)
      dispatch(onEditPriceByProductPriceGroup(selectedGroup.id, form))
    }
  }

  return (
    <div>
      <h1>แก้ไขราคากลุ่มสินค้า</h1>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '20px 0px',
          background: '#c1ebff',
        }}
      >
        <div
          style={{
            display: 'inline-block',
            width: '40%',
            padding: '0px 20px',
          }}
        >
          <div>
            <h2
              className="mb-2"
              style={{ textAlign: 'center', background: 'white ' }}
            >
              กลุ่มสินค้า
            </h2>
          </div>
          <div className="mb-2">
            <Search
              value={searchGroup}
              onChange={(event) => {
                setSearchGroup(event.target.value)
              }}
              placeholder="ค้นชื่อกลุ่ม"
              onSearch={(value) => {
                handleGroupSearch(value)
              }}
              style={{ width: '60%' }}
            />
            <Button
              type="primary"
              icon={<ClearOutlined />}
              onClick={onClearSearchGroup}
              style={{ width: '35%', float: 'right', marginRight: 0 }}
            >
              เคลียร์การค้นหา
            </Button>
          </div>
          <Spin spinning={isProductGroupLoading}>
            <Table
              rowKey="id"
              columns={GROUP_COLUMN}
              dataSource={filteredProductGroupList}
              size="middle"
              scroll={{ y: '65vh', x: '800' }}
              style={{ height: '65vh', cursor: 'pointer' }}
              rowClassName={(record) =>
                record.id === selectedGroup.id ? 'selected-price-group' : ''
              }
              pagination={{
                simple: true,
                position: ['bottomRight'],
                defaultPageSize: 20,
              }}
              onRow={(record) => {
                return {
                  onClick: () => onClickGroupRow(record),
                  onDoubleClick: () => onClickGroupRow(DEFAULT_GROUP),
                }
              }}
            />
          </Spin>
        </div>
        <div
          style={{
            display: 'inline-block',
            width: '60%',
            padding: '0px 20px',
          }}
        >
          <h3 className="mb-2">
            กลุ่มที่กำลังแก้ไข :{' '}
            <span style={{ color: '#181cff' }}>{selectedGroup.name}</span>
          </h3>
          <Spin spinning={isEditLoading}>
            <div className="form-wrapper">
              <div className="input-wrapper">
                <div className="input-item">
                  <InlineInput
                    label="ราคาขายส่ง"
                    width="17 0px"
                    value={form.price_out_atm}
                    disabled={false}
                    onChange={(event) =>
                      setForm({ ...form, price_out_atm: event.target.value })
                    }
                  />
                </div>
                <div className="input-item">
                  <DiscountInput
                    label="ส่วนลดราคาส่ง"
                    name1="discount_atm_1"
                    name2="discount_atm_2"
                    name3="discount_atm_3"
                    name4="discount_atm_4"
                    value1={form.discount_atm_1}
                    value2={form.discount_atm_2}
                    value3={form.discount_atm_3}
                    value4={form.discount_atm_4}
                    onChange={(value, name) =>
                      setForm({ ...form, [name]: value })
                    }
                    width="100px"
                  />
                </div>
              </div>
              <div className="input-wrapper" style={{ marginTop: '10px' }}>
                <div className="input-item">
                  <InlineInput
                    label="ราคาขายปลีก"
                    width="170px"
                    value={form.price_out_cash}
                    disabled={false}
                    onChange={(event) =>
                      setForm({ ...form, price_out_cash: event.target.value })
                    }
                  />
                </div>
                <div className="input-item">
                  <DiscountInput
                    label="ส่วนลดราคาปลีก"
                    name1="discount_cash_1"
                    name2="discount_cash_2"
                    name3="discount_cash_3"
                    name4="discount_cash_4"
                    value1={form.discount_cash_1}
                    value2={form.discount_cash_2}
                    value3={form.discount_cash_3}
                    value4={form.discount_cash_4}
                    onChange={(value, name) =>
                      setForm({ ...form, [name]: value })
                    }
                    width="100px"
                  />
                </div>
              </div>
              <div className="input-wrapper" style={{ marginTop: '10px' }}>
                <div className="input-item">
                  <InlineInput
                    label="Vat"
                    value={form.vat_type_id}
                    onChange={(value) => {
                      setForm({ ...form, vat_type_id: value })
                    }}
                    width="150px"
                    inputOption="select_input"
                    options={vatTypes}
                  />
                </div>
              </div>
              <div className="input-wrapper">
                <div
                  className="input-item"
                  style={{ marginTop: '40px', marginBottom: '20px' }}
                >
                  <Button
                    type="primary"
                    icon={<SaveOutlined />}
                    size="large"
                    style={{ width: '180px', height: '60px' }}
                    onClick={onSaveProductHandler}
                  >
                    บันทึก
                  </Button>
                </div>
              </div>
              <span style={{ color: 'red' }}>
                * ส่วนลด : หากต้องการเปลี่ยนแปลง "ส่วนลด"
                กรุณากรอกเลขให้ครบทุกช่อง หากกรอกไม่ครบ
                ช่องที่ไม่ได้กรอกจะถูกบันทึกค่าเป็น 0{' '}
              </span>
            </div>
          </Spin>
        </div>
      </div>
    </div>
  )
}

export default EditPricebyProductGroupPage
