/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Modal, Table, Button, Spin } from 'antd'
import { SelectOutlined } from '@ant-design/icons'

import { addCommas } from 'utils'
import useListSupplierBillVatPopUp from './hooks/useListSupplierBillVatPopUp'
import usePagination from 'hooks/usePagination'
import InlineInput from 'components/InlineInput'

const SearchSupplierBillVatPopUp = ({
  visible,
  onClick,
  onCancel,
  supplier_name = '',
}) => {
  const {
    currentPageNumber,
    pageSize,
    paginationSetting,
    setCurrentPageNumber,
  } = usePagination({ customPageSize: 10 })
  const {
    listSupplierBillVatPopUpData,
    isLoadingListSupplierBillVatPopUp,
    listSupplierBillVatPopUpPagination,
    onSearchName,
  } = useListSupplierBillVatPopUp({ currentPageNumber, pageSize })
  const [name, setName] = useState('')

  useEffect(() => {
    if (visible && supplier_name !== undefined) {
      setCurrentPageNumber(1)
      onSearchName({ searchName: supplier_name })
      setName(supplier_name)
    }
  }, [visible])

  const fetchList = (value) => {
    setCurrentPageNumber(1)
    onSearchName({ searchName: value.toLowerCase() })
  }

  const tableColumns = [
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: '30px',
      render: (_, record) => {
        return (
          <Button
            size="small"
            type="primary"
            icon={<SelectOutlined />}
            onClick={() => onClick(record)}
          />
        )
      },
    },
    {
      title: 'เลขที่บิล',
      dataIndex: 'bill_no',
      key: 'bill_no',
      width: '170px',
    },
    {
      title: 'วันที่เปิดบิล',
      dataIndex: 'bill_date',
      key: 'bill_date',
      width: '100px',
    },
    {
      title: 'ชื่อผู้ขาย',
      dataIndex: 'supplier_name',
      key: 'supplier_name',
      width: '250px',
      render: (text) => <div className="break-word">{text}</div>,
    },
    {
      title: 'ยอดรวม',
      dataIndex: 'total',
      key: 'total',
      width: '100px',
      render: (text) => <div className="text-align-right pr-2">{addCommas(text)}</div>,
    },
  ]

  return (
    <Modal
      visible={visible}
      width="750px"
      onCancel={onCancel}
      footer={null}
      title={
        <div>
          <InlineInput
            onSearch={(value) => {
              fetchList(value)
            }}
            inputOption="search"
            label="ค้นหา"
            placeholder="ใส่ชื่อผู้ขาย"
            value={name}
            width="280px"
            onChange={(event) => {
              setName(event.target.value.toUpperCase())
            }}
          />
        </div>
      }
    >
      <Spin spinning={isLoadingListSupplierBillVatPopUp}>
        <Table
          rowKey={(record) => `id+${record.code}`}
          dataSource={listSupplierBillVatPopUpData}
          columns={tableColumns}
          style={{ height: '440px' }}
          scroll={{ y: 360 }}
          size="small"
          pagination={paginationSetting({
            totalItems: listSupplierBillVatPopUpPagination?.totalItems,
            simple: true,
          })}
        />
      </Spin>
    </Modal>
  )
}

export default SearchSupplierBillVatPopUp
