import React from 'react'
import { Modal, Table, Button } from 'antd'
import { SelectOutlined } from '@ant-design/icons'

const ProductMemberModal = ({ visible, onClick, members, onCancel, title }) => {
  const tableColumns = [
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: '30px',
      render: (_, record) => {
        return (
          <Button
            size="small"
            type="primary"
            icon={<SelectOutlined />}
            onClick={() => onClick(record)}
          />
        )
      },
    },
    {
      title: 'รหัส',
      dataIndex: 'product_code',
      key: 'product_code',
      width: '100px',
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'product_name',
      key: 'product_name',
      width: '250px',
    },
  ]
  return (
    <Modal
      title={title}
      visible={visible}
      width="600px"
      onCancel={onCancel}
      footer={null}
      bodyStyle={{ background: '#F6C9CC' }}
    >
      <Table
        rowKey={(record) => `id+${record.product_id}`}
        dataSource={members}
        columns={tableColumns}
        pagination={false}
        scroll={{ y: 300 }}
        size='small'
      />
    </Modal>
  )
}

export default ProductMemberModal
