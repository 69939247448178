/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Modal, Spin, Divider } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import InlineInput from 'components/InlineInput'
import { onGetReasonList, onSaveTemporaryStock } from 'redux/actions'
import { handleAlert } from 'utils'

const DEFAULT_TEMPSTOCK = {
  product_code: '',
  quantity: '',
  reason_value: '',
}

const BillCompareStockModal = ({
  productCode,
  stock,
  quantity,
  visible,
  onCancleStockModal,
  onSubmitStockModal,
}) => {
  function useAsyncState(initialValue) {
    const [value, setValue] = useState(initialValue)
    const setter = (x) =>
      new Promise((resolve) => {
        setValue(x)
        resolve(x)
      })
    return [value, setter]
  }
  const [isLoading, setIsLoading] = useState(false)
  const [newQuantity, setNewQuantity] = useState('')
  const [reasonList, setReasonList] = useState([])
  const [isMoreThanStock, setIsMoreThanStock] = useState(false)
  const [tempStockForm, setTempStockForm] = useAsyncState(DEFAULT_TEMPSTOCK)
  const [canSubmit, setCanSubmit] = useState(false)
  const dispatch = useDispatch()
  const getReasonList = useSelector((state) => state.getReasonList)
  const saveTemporaryStock = useSelector((state) => state.saveTemporaryStock)

  useEffect(() => {
    dispatch(onGetReasonList())
  }, [])

  useEffect(() => {
    setIsLoading(getReasonList.loading)
    if (
      !getReasonList.error &&
      getReasonList.finished &&
      !getReasonList.loading
    ) {
      const data = getReasonList.data.map((e) => ({
        id: e.value,
        name: e.reason,
      }))
      setReasonList(data)
    }
  }, [getReasonList])

  useEffect(() => {
    setIsLoading(saveTemporaryStock.loading)
    if (
      !saveTemporaryStock.error &&
      saveTemporaryStock.finished &&
      !saveTemporaryStock.loading
    ) {
      handleAlert('success')
      setIsMoreThanStock(false)
      setTempStockForm(DEFAULT_TEMPSTOCK)
      onSubmitStockModal(newQuantity)
      setNewQuantity('')
      setCanSubmit(false)
    }
    if (saveTemporaryStock.error && !saveTemporaryStock.loading) {
      handleAlert('custom', 'ไม่สามารถทำรายการได้ กรุณาลองใหม่อีกครั้ง')
      setCanSubmit(false)
    }
  }, [saveTemporaryStock])

  const compareNewQuantity = (new_quantity) => {
    if (new_quantity > stock) {
      setCanSubmit(false)
      const tempStockQuantity = new_quantity - stock
      setIsMoreThanStock(true)
      setTempStockForm({
        ...tempStockForm,
        product_code: productCode,
        quantity: tempStockQuantity,
      })
    } else if (
      new_quantity <= stock &&
      new_quantity !== '' &&
      new_quantity !== 0
    ) {
      setCanSubmit(true)
      setIsMoreThanStock(false)
      setTempStockForm(DEFAULT_TEMPSTOCK)
    } else {
      setCanSubmit(false)
      setIsMoreThanStock(false)
      setTempStockForm(DEFAULT_TEMPSTOCK)
    }
  }

  const handleCancel = () => {
    setIsMoreThanStock(false)
    setCanSubmit(false)
    setTempStockForm(DEFAULT_TEMPSTOCK)
    setNewQuantity('')
    onCancleStockModal()
  }

  const saveTemporaryStockHandler = () => {
    dispatch(onSaveTemporaryStock(tempStockForm))
  }

  const handleOk = () => {
    if (canSubmit) {
      if (Number(tempStockForm.quantity) > 0) {
        saveTemporaryStockHandler()
      } else {
        onSubmitStockModal(newQuantity)
        setNewQuantity('')
        setIsLoading(false)
      }
    } else {
      Modal.warning({
        title: 'ตรวจสอบ',
        content: 'กรุณาระบุจำนวนที่ต้องการเปิดให้เรียบร้อย',
      })
    }
  }

  return (
    <>
      <Modal
        width="500px"
        visible={visible}
        onCancel={handleCancel}
        onOk={handleOk}
        okButtonProps={{
          disabled: !canSubmit,
        }}
        title="จำนวนสินค้าไม่พอ"
      >
        <Spin spinning={isLoading}>
          <div className="form-wrapper">
            <div className="input-wrapper">
              <div className="input-item">
                <InlineInput
                  label="จำนวนที่เปิดบิล"
                  value={quantity}
                  disabled
                />
              </div>
            </div>
            <div className="input-wrapper">
              <div className="input-item">
                <InlineInput
                  label="จำนวนคงเหลือปัจจุบัน"
                  value={stock}
                  disabled
                />
              </div>
            </div>
            <div className="input-wrapper">
              <div className="input-item">
                <InlineInput
                  label="จำนวนที่ต้องการเปิด"
                  value={newQuantity}
                  onChange={(event) => {
                    setNewQuantity(event.target.value)
                    compareNewQuantity(event.target.value)
                  }}
                />{' '}
              </div>
            </div>
            <Divider style={{ margin: '10px 0px 20px' }} />
            <div
              style={
                isMoreThanStock
                  ? { display: 'block', paddingBottom: '10px', color: 'red' }
                  : { display: 'none' }
              }
            >
              ** โปรดระบุสาเหตุ หากต้องการเปิดบิลมากกว่าจำนวนคงเหลือปัจจุบัน
            </div>
            <div className="input-wrapper">
              <div className="input-item">
                <InlineInput
                  disabled={!isMoreThanStock}
                  label="สาเหตุ"
                  value={
                    tempStockForm.reason_value ? tempStockForm.reason_value : ''
                  }
                  onChange={(value) => {
                    setTempStockForm({
                      ...tempStockForm,
                      reason_value: value,
                    }).then(() => {
                      setCanSubmit(true)
                    })
                  }}
                  width="200px"
                  inputOption="select_input"
                  options={reasonList}
                  disableColon
                />
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
    </>
  )
}

export default BillCompareStockModal
