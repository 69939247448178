/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Spin, Button, Table, Input } from 'antd'
import { ClearOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router-dom'

import { handleAlert, addCommas } from 'utils'

import { onListUnpaidSupplierVatPayment } from 'redux/actions'
import { useSelector, useDispatch } from 'react-redux'

const Search = Input.Search

const tableColumns = [
  // {
  //   title: '',
  //   dataIndex: '',
  //   key: 'x',
  //   width: '40px',
  //   fixed: 'left',
  //   render: (_, record) => {
  //     return (
  //       <Button
  //         type="primary"
  //         size="small"
  //         icon={<PrinterOutlined />}
  //         onClick={(_) => onPrint(record)}
  //         disabled={record.payment_status !== 'ชำระเงินแล้ว'}
  //       />
  //     )
  //   },
  // },
  {
    title: 'เลขที่บิล',
    dataIndex: 'bill_no',
    key: 'bill_no',
    width: '110px',
    fixed: 'left',
    sorter: (a, b) => ('' + a.bill_no).localeCompare(b.bill_no),
    sortDirections: ['ascend', 'descend'],
    render: (_, record) => {
      if (record.supplier_invoice_no === '') {
        return (
          <div
            // onClick={() => onClickRow(record)}
            style={{
              // cursor: 'pointer',
              color: '#0000ff',
            }}
          >
            {record.bill_no}
          </div>
        )
      } else {
        return <div>{record.bill_no}</div>
      }
    },
  },
  {
    title: 'เลขที่ใบวางบิล',
    dataIndex: 'supplier_invoice_no',
    key: 'supplier_invoice_no',
    width: '110px',
    fixed: 'left',
    sorter: (a, b) =>
      ('' + a.supplier_invoice_no).localeCompare(b.supplier_invoice_no),
    sortDirections: ['ascend', 'descend'],
    render: (_, record) => {
      if (record.supplier_invoice_no !== '') {
        return (
          <div
            // onClick={() => onClickRow(record)}
            style={{
              // cursor: 'pointer',
              color: '#0000ff',
            }}
          >
            {record.supplier_invoice_no}
          </div>
        )
      } else {
        return <div>{record.supplier_invoice_no}</div>
      }
    },
  },
  {
    title: 'วันที่',
    dataIndex: 'date',
    key: 'date',
    width: '80px',
    fixed: 'left',
    sorter: (a, b) => ('' + a.date).localeCompare(b.date),
    defaultSortOrder: 'descend',
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'รหัสผู้ขาย',
    dataIndex: 'supplier_code',
    key: 'supplier_code',
    width: '80px',
  },
  {
    title: 'ชื่อผู้ขาย',
    dataIndex: 'supplier_name',
    key: 'supplier_name',
    width: '140px',
    render: (text) => <div className="break-word">{text}</div>,
  },
  {
    title: 'ยอดรวม',
    dataIndex: 'actually_paid_total',
    key: 'actually_paid_total',
    width: '90px',
    className: 'text-align-right',
    render: (text) => (
      <div className="text-align-right pr-2"><strong>{addCommas(text)}</strong></div>
    ),
  },
  {
    title: 'ยอดบิล',
    dataIndex: 'individual_bill_total',
    key: 'individual_bill_total',
    width: '90px',
    className: 'text-align-right',
    render: (text) => (
      <div className="text-align-right pr-2">{addCommas(text)}</div>
    ),
  },
  {
    title: 'สถานะ',
    dataIndex: 'payment_status',
    key: 'payment_status',
    width: '90px',
    sorter: (a, b) => ('' + a.payment_status).localeCompare(b.payment_status),
    sortDirections: ['ascend', 'descend'],
  },
]

const ListUnpaidSupplierVatInvoicePage = (props) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [temporaryList, setTemporaryList] = useState([])
  const [filteredList, setFilteredList] = useState([])
  const [searchText, setSearchText] = useState('')
  const listUnpaidSupplierVatPayment = useSelector(
    (state) => state.listUnpaidSupplierVatPayment
  )

  useEffect(() => {
    dispatch(onListUnpaidSupplierVatPayment())
    document.title = 'รายการบัญชีผู้ขายที่ยังไม่ได้ชำระ - Vat'
  }, [])

  useEffect(() => {
    if (temporaryList !== []) {
      handleSearch(searchText)
    }
  }, [temporaryList, searchText])

  useEffect(() => {
    const { loading, error, data, finished, trace } = listUnpaidSupplierVatPayment
    setIsLoading(loading)
    if (!error && !loading && finished) {
      setTemporaryList(data)
    }
    if (!loading && error) {
      handleAlert('error', trace.message || '')
    }
  }, [listUnpaidSupplierVatPayment])

  const handleSearch = (searchText) => {
    const _temporaryList = [...temporaryList]
    const filteredData = _temporaryList.filter(({ supplier_code }) => {
      supplier_code = supplier_code.toString().toLowerCase()
      return supplier_code.startsWith(searchText.toLowerCase())
    })
    setFilteredList(filteredData)
  }

  const onClearSearch = () => {
    setFilteredList(temporaryList)
    setSearchText('')
  }

  return (
    <div>
      <h1>รายการบัญชีผู้ขายที่ยังไม่ได้ชำระ</h1>
      <div className="form-wrapper-vat">
        <div className="input-wrapper">
          <div className="input-item">
            <Search
              value={searchText}
              onChange={(event) => {
                setSearchText(event.target.value.toUpperCase())
              }}
              placeholder="กรองรหัสผู้ขาย"
              onSearch={(value) => {
                handleSearch(value)
              }}
            />
          </div>
          <div className="input-item">
            <Button
              type="primary"
              icon={<ClearOutlined />}
              onClick={onClearSearch}
            >
              เคลียร์
            </Button>
          </div>
        </div>
        <Spin spinning={isLoading}>
          <Table
            rowKey={(record) => `id+${record.product_code}`}
            dataSource={filteredList}
            columns={tableColumns}
            pagination={{
              simple: true,
              defaultPageSize: 20,
              showSizeChanger: true,
              position: ['bottomRight'],
            }}
            scroll={{ x: 1000, y: 680 }}
            size="small"
            style={{
              maxWidth: '1050px',
              height: '700px',
              minHeight: '100%',
              backgroundColor: '#FFFFFF',
              marginTop: '10px'
            }}
          />
        </Spin>
      </div>
    </div>
  )
}

export default withRouter(ListUnpaidSupplierVatInvoicePage)
