import React, { createContext, useState } from 'react'
import moment from 'moment'
import useRequestListPriceInPriceOutData from '../hooks/useRequestListPriceInPriceOutData'

// NOTE: Context
const ListPriceInPriceOutContext = createContext({})

// NOTE: Provider
const ListPriceInPriceOutDataProvider = ({ children }) => {
  const [month, setMonth] = useState(moment().format('YYYY-MM'))
  const [profitMonth, setProfitMonth] = useState(moment().format('YYYY-MM'))
  const {
    listItemAdhocData,
    listItemAdhocLoading,
    listItemProfitAdhocData,
    listItemProfitAdhocLoading,
    onSearchLossData,
    onSearchProfitData,
  } = useRequestListPriceInPriceOutData({ month, profitMonth })

  const onClickRefreshLoss = () => {
    onSearchLossData(month)
  }

  const onClickRefreshProfit = () => {
    onSearchProfitData(profitMonth)
  }

  return (
    <ListPriceInPriceOutContext.Provider
      value={{
        listItemAdhocData,
        listItemAdhocLoading,
        listItemProfitAdhocData,
        listItemProfitAdhocLoading,
        onClickRefreshLoss,
        onClickRefreshProfit,
        month,
        setMonth,
        profitMonth,
        setProfitMonth,
      }}
    >
      {children}
    </ListPriceInPriceOutContext.Provider>
  )
}

export { ListPriceInPriceOutContext, ListPriceInPriceOutDataProvider }
