/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Modal, Table, Button, Spin } from 'antd'
import { SelectOutlined } from '@ant-design/icons'
import { useSelector, useDispatch } from 'react-redux'

import { onListNoteByCustomerName } from 'redux/actions'

import InlineInput from 'components/InlineInput'

const SearchCustomerNotePopUp = ({ visible, onClick, onCancel }) => {
  const [list, setList] = useState([])
  const [name, setName] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [filteredList, setFilteredList] = useState([])
  const dispatch = useDispatch()
  let listNoteByCustomerName = useSelector(
    (state) => state.listNoteByCustomerName
  )

  useEffect(() => {
    const { error, loading, finished, data, trace } = listNoteByCustomerName
    if (!error && !loading && finished) {
      setList(data)
      setFilteredList(data)
      setIsLoading(false)
    }
    if (error && !loading) {
      Modal.error({
        centered: true,
        title: 'ตรวจสอบ',
        content: `${trace.message}`,
      })
      setIsLoading(false)
    }
  }, [listNoteByCustomerName])

  const handleSearch = (searchValue) => {
    const _list = [...list]
    const filteredData = _list.filter(({ doc_no, customer_name }) => {
      doc_no = doc_no.toString().toLowerCase()
      customer_name = customer_name.toString().toLowerCase()
      return (
        doc_no.includes(searchValue.toLowerCase()) ||
        customer_name.includes(searchValue.toLowerCase())
      )
    })
    setFilteredList(filteredData)
  }

  const fetchList = (value) => {
    setIsLoading(true)
    dispatch(onListNoteByCustomerName('', value))
  }

  const onClosePopUp = () => {
    clearInput()
    onCancel()
  }

  const onSelectItem = (record) => {
    onClick(record)
    clearInput()
  }

  const clearInput = () => {
    setList([])
    setFilteredList([])
    setSearchText('')
    setName('')
  }

  const tableColumns = [
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: '30px',
      render: (_, record) => {
        return (
          <Button
            size="small"
            type="primary"
            icon={<SelectOutlined />}
            onClick={() => onSelectItem(record)}
          />
        )
      },
    },
    {
      title: 'เลขที่',
      dataIndex: 'doc_no',
      key: 'doc_no',
      width: '120px',
    },
    {
      title: 'วันที่',
      dataIndex: 'doc_date',
      key: 'doc_date',
      width: '90px',
    },
    {
      title: 'ประเภท',
      dataIndex: 'is_credit_note',
      key: 'is_credit_note',
      width: '80px',
      render: (text) => <div>{text === true ? 'ใบลดหนี้' : 'ใบเพิ่มหนี้'}</div>,
    },
    {
      title: 'ชื่อลูกค้า',
      dataIndex: 'customer_name',
      key: 'customer_name',
      width: '300px',
      render: (text) => <div className="break-word">{text}</div>,
    },
    {
      title: 'ยอดรวม',
      dataIndex: 'total',
      key: 'total',
      width: '100px',
      render: (text) => <div className="text-align-right">{text}</div>,
    },
  ]

  return (
    <Modal
      visible={visible}
      width="800px"
      onCancel={onClosePopUp}
      footer={null}
      title={
        <div>
          <InlineInput
            onSearch={(value) => {
              fetchList(value)
            }}
            inputOption="search"
            label="ค้นหา"
            placeholder="ใส่ชื่อลูกค้า"
            value={name}
            width="280px"
            onChange={(event) => {
              setName(event.target.value.toUpperCase())
            }}
          />
        </div>
      }
    >
      <Spin spinning={isLoading}>
        <Table
          rowKey={(record) => `id+${record.code}`}
          dataSource={filteredList}
          columns={tableColumns}
          style={{ height: '440px' }}
          scroll={{ y: 360 }}
          size="small"
          pagination={{
            position: ['bottomRight'],
            simple: true,
            defaultPageSize: 10,
          }}
        />
        <div style={{ position: 'absolute', bottom: '-8px' }}>
          <div className="mb-1">
            <InlineInput
              onSearch={(value) => {
                handleSearch(value)
              }}
              inputOption="search"
              label="กรองข้อมูล"
              placeholder="ใส่รหัส หรือ ชื่อ"
              value={searchText}
              width="280px"
              onChange={(event) => {
                setSearchText(event.target.value.toUpperCase())
              }}
            />
          </div>
        </div>
      </Spin>
    </Modal>
  )
}

export default SearchCustomerNotePopUp
