/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Table, Spin, Button, Input, Popconfirm, Modal } from 'antd'
import {
  DeleteOutlined,
  ClearOutlined,
  SelectOutlined,
  PlusOutlined,
  EditOutlined,
} from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'

import {
  onListProductPriceGroup,
  onListProductNormalWithNoPriceGroup,
  onSaveProductPriceGroup,
  onDeleteProductPriceGroup,
  onAddProductPriceGroupMember,
  onDeleteMemberFromProductPriceGroup,
} from 'redux/actions'

import InlineInput from 'components/InlineInput'
import { handleAlert } from 'utils'

const Search = Input.Search

const ProductPricingGroupPage = () => {
  const NO_GROUP_NORMAL_COLUMN = [
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: '40px',
      fixed: 'left',
      render: (_, record) => {
        return (
          <Button
            size="small"
            type="primary"
            icon={<SelectOutlined />}
            onClick={() => handleAddProductToGroup(record.id)}
          />
        )
      },
    },
    {
      title: 'รหัส',
      dataIndex: 'code',
      key: 'code',
      width: '70px',
      sorter: (a, b) => ('' + a.code).localeCompare(b.code),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'name',
      key: 'name',
      width: '500px',
      sorter: (a, b) => ('' + a.name).localeCompare(b.name),
      sortDirections: ['ascend', 'descend'],
    },
  ]

  const GROUP_COLUMN = [
    {
      title: 'ชื่อกลุ่ม',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: '80%',
      className: 'break-word',
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: '20%',
      render: (record) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div style={{ display: 'inline-block', width: '32px' }}>
              <Button
                icon={<EditOutlined />}
                size="small"
                block
                onClick={(event) => {
                  event.stopPropagation()
                  setNewGroup({
                    id: record.id,
                    name: record.name,
                  })
                  setIsShowAddModal(true)
                }}
              />
            </div>
            <div style={{ display: 'inline-block', width: '30px' }}>
              <Popconfirm
                title={`คุณต้องการลบกลุ่ม "${record.name}" ใช่ไหม ?`}
                okText="ใช่"
                cancelText="ยกเลิก"
                onConfirm={(event) => {
                  event.stopPropagation()
                  dispatch(onDeleteProductPriceGroup(record.id))
                }}
                onCancel={(event) => {
                  event.stopPropagation()
                }}
              >
                <Button
                  type="primary"
                  danger
                  icon={<DeleteOutlined />}
                  size="small"
                  block
                  onClick={(event) => {
                    event.stopPropagation()
                  }}
                />
              </Popconfirm>
            </div>
          </div>
        )
      },
    },
  ]

  const DEFAULT_NEW_GROUP = {
    id: 0,
    name: '',
  }

  const DEFAULT_ADD_PRODUCT_TO_GROUP = {
    group_id: '',
    product_id: '',
  }

  function useAsyncState(initialValue) {
    const [value, setValue] = useState(initialValue)
    const setter = (x) =>
      new Promise((resolve) => {
        setValue(x)
        resolve(x)
      })
    return [value, setter]
  }
  const [isNoGroupNormalLoading, setIsNoGroupNormalLoading] = useState(false)
  const [searchNormal, setSearchNormal] = useState('')
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [searchGroup, setSearchGroup] = useState('')
  const [searchGroupMember, setSearchGroupMember] = useState('')
  const [searchNormalName, setSearchNormalName] = useState('')
  const [lastestProductGroupList, setLastestProductGroupList] = useState([])
  const [filteredProductGroupList, setFilteredProductGroupList] = useAsyncState(
    []
  )
  const [isProductGroupLoading, setIsProductGroupLoading] = useState(false)
  const [lastestNoGroupNormalList, setLastestNoGroupNormalList] = useState([])
  const [filteredNoGroupNormalList, setFilteredNoGroupNormalList] = useState([])
  const [isShowAddModal, setIsShowAddModal] = useState(false)
  const [newGroup, setNewGroup] = useState(DEFAULT_NEW_GROUP)
  const [tempList, setTempList] = useState([])
  const [tempProduct, setTempProduct] = useState({})
  const [formAddProductToGroup, setFormAddProductToGroup] = useAsyncState(
    DEFAULT_ADD_PRODUCT_TO_GROUP
  )
  const dispatch = useDispatch()
  const listProductPriceGroup = useSelector(
    (state) => state.listProductPriceGroup
  )
  const listProductNormalWithNoPriceGroup = useSelector(
    (state) => state.listProductNormalWithNoPriceGroup
  )
  const saveProductPriceGroup = useSelector(
    (state) => state.saveProductPriceGroup
  )
  const deleteProductPriceGroup = useSelector(
    (state) => state.deleteProductPriceGroup
  )
  const addProductPriceGroupMember = useSelector(
    (state) => state.addProductPriceGroupMember
  )
  const deleteMemberFromProductPriceGroup = useSelector(
    (state) => state.deleteMemberFromProductPriceGroup
  )

  useEffect(() => {
    document.title = 'จับกลุ่มสินค้าราคาเดียวกัน'
    setIsProductGroupLoading(true)
    setIsNoGroupNormalLoading(true)
    dispatch(onListProductPriceGroup())
    dispatch(onListProductNormalWithNoPriceGroup())
  }, [])

  useEffect(() => {
    const { error, data, loading, finished, trace } = listProductPriceGroup
    setIsProductGroupLoading(loading)
    if (!error && finished && !loading && data) {
      setLastestProductGroupList(data)
      setIsProductGroupLoading(false)
    }
    if (error && !loading) {
      setIsProductGroupLoading(false)
      handleAlert('error', trace.message || '')
    }
  }, [listProductPriceGroup])

  useEffect(() => {
    const { error, data, loading, finished, trace } =
      listProductNormalWithNoPriceGroup
    if (!error && finished && !loading && data) {
      setLastestNoGroupNormalList(data)
      setIsNoGroupNormalLoading(loading)
    }
    if (error && !loading) {
      setIsNoGroupNormalLoading(loading)
      handleAlert('error', trace.message || '')
    }
  }, [listProductNormalWithNoPriceGroup])

  useEffect(() => {
    const { error, data, loading, finished, trace } = saveProductPriceGroup
    if (!error && finished && !loading && data) {
      let pharseData = {
        id: data.id,
        name: data.name,
        members: [],
      }
      let _tempList = tempList
      let _newGroup = newGroup
      if (_newGroup.id !== 0) {
        let newList = _tempList.map((group) => {
          if (group.id === pharseData.id) {
            return {
              ...group,
              name: pharseData.name,
            }
          } else return group
        })
        setLastestProductGroupList([...newList])
        setNewGroup(DEFAULT_NEW_GROUP)
        setIsProductGroupLoading(false)
        setIsShowAddModal(false)
      } else {
        setLastestProductGroupList([pharseData, ...lastestProductGroupList])
        setNewGroup(DEFAULT_NEW_GROUP)
        setIsProductGroupLoading(false)
        setIsShowAddModal(false)
      }
    }
    if (error && !loading) {
      Modal.error({
        centered: true,
        title: 'ตรวจสอบ',
        content: `${
          trace.message !== undefined
            ? trace.message
            : trace.name !== undefined
            ? 'ไม่สามารถตั้งชื่อกลุ่มซ้ำได้'
            : ''
        }`,
      })
      setIsShowAddModal(false)
      setIsProductGroupLoading(false)
    }
  }, [saveProductPriceGroup])

  const fetchAllList = () => {
    dispatch(onListProductPriceGroup())
    dispatch(onListProductNormalWithNoPriceGroup())
  }

  useEffect(() => {
    const { error, data, loading, finished, trace } = deleteProductPriceGroup
    if (!error && finished && !loading && data) {
      setIsNoGroupNormalLoading(true)
      setIsProductGroupLoading(true)
      fetchAllList()
    }
    if (error && !loading) {
      Modal.error({
        centered: true,
        title: 'ตรวจสอบ',
        content: `${trace.message}`,
      })
    }
  }, [deleteProductPriceGroup])

  useEffect(() => {
    const { error, loading, finished, data, trace } = addProductPriceGroupMember
    let addedProduct = formAddProductToGroup
    let lastestNormal = lastestNoGroupNormalList
    if (!error && !loading && finished && data) {
      let newList = lastestProductGroupList.map((group) =>
        group.id === data.id ? data : group
      )
      setLastestProductGroupList(newList)
      setFilteredProductGroupList(newList)
      lastestNormal = lastestNormal.filter(
        (x) => x.id !== addedProduct.product_id
      )
      setLastestNoGroupNormalList(lastestNormal)
      setIsProductGroupLoading(false)
      setIsNoGroupNormalLoading(false)
    }
    if (error && !loading) {
      Modal.error({
        centered: true,
        title: 'ตรวจสอบ',
        content: `${trace.message}`,
      })
      setIsProductGroupLoading(false)
      setIsNoGroupNormalLoading(false)
    }
  }, [addProductPriceGroupMember])

  useEffect(() => {
    const { error, data, loading, finished, trace } =
      deleteMemberFromProductPriceGroup
    if (!error && finished && !loading && data) {
      let newList = lastestProductGroupList.map((group) =>
        group.id === data.id ? data : group
      )
      setLastestProductGroupList(newList)
      let lastestList = [tempProduct, ...lastestNoGroupNormalList]
      lastestList.sort((a, b) => a.code - b.code)
      setLastestNoGroupNormalList(lastestList)
      setIsNoGroupNormalLoading(false)
      setIsProductGroupLoading(false)
    }
    if (error && !loading) {
      Modal.error({
        centered: true,
        title: 'ตรวจสอบ',
        content: `${trace.message}`,
      })
      setIsProductGroupLoading(false)
      setIsNoGroupNormalLoading(false)
    }
  }, [deleteMemberFromProductPriceGroup])

  const handleGroupSearch = (searchGroup) => {
    const filteredData = lastestProductGroupList.filter(({ name }) => {
      name = name.toString().toLowerCase()
      return name.includes(searchGroup.toLowerCase())
    })
    setFilteredProductGroupList(filteredData)
    setIsProductGroupLoading(false)
  }

  const handleGroupMemberSearch = (searchGroupMember) => {
    const filteredData = []
    lastestProductGroupList.forEach((group) => {
      let foundMember = group.members.filter(
        ({ product_name, product_code }) => {
          product_name = product_name.toString().toLowerCase()
          product_code = product_code.toString().toLowerCase()
          return (
            product_name.includes(searchGroupMember.toLowerCase()) ||
            product_code.startsWith(searchGroupMember.toLowerCase())
          )
        }
      )
      if (foundMember.length !== 0) {
        return filteredData.push(group)
      }
    })
    setFilteredProductGroupList(filteredData)
    setIsProductGroupLoading(false)
  }

  const onClearSearchGroup = () => {
    setFilteredProductGroupList(lastestProductGroupList)
    setSearchGroup('')
  }

  useEffect(() => {
    if (lastestProductGroupList !== []) {
      if(searchGroupMember === '') {
        handleGroupSearch(searchGroup)
      }
      if(searchGroup === '') {
        handleGroupMemberSearch(searchGroupMember)
      }
    }
  }, [lastestProductGroupList, searchGroup, searchGroupMember])

  useEffect(() => {
    if (lastestNoGroupNormalList !== []) {
      if (searchNormal === '') {
        handleNormalNameSearch(searchNormalName)
      }
      if (searchNormalName === '') {
        handleNormalSearch(searchNormal)
      }
    }
  }, [lastestNoGroupNormalList, searchNormal, searchNormalName])

  useEffect(() => {
    if (lastestNoGroupNormalList !== []) {
      if (searchNormal === '') {
        handleNormalNameSearch(searchNormalName)
      }
      if (searchNormalName === '') {
        handleNormalSearch(searchNormal)
      }
    }
  }, [lastestNoGroupNormalList, searchNormal, searchNormalName])

  const handleNormalSearch = (searchNormal) => {
    const filteredData = lastestNoGroupNormalList.filter(({ code }) => {
      code = code.toString().toLowerCase()
      return code.startsWith(searchNormal.toString().toLowerCase())
    })
    setFilteredNoGroupNormalList(filteredData)
  }

  const handleNormalNameSearch = (searchNormalName) => {
    const filteredData = lastestNoGroupNormalList.filter(({ name }) => {
      name = name.toString().toLowerCase()
      return name.includes(searchNormalName.toString().toLowerCase())
    })
    setFilteredNoGroupNormalList(filteredData)
  }

  const onClearSearchNormal = () => {
    setFilteredNoGroupNormalList(lastestNoGroupNormalList)
    setSearchNormal('')
    setSearchNormalName('')
  }

  const onTableRowExpand = (expanded, record) => {
    const keys = []
    let group_id = ''
    if (expanded) {
      keys.push(record.id) // I have set my record.id as row key. Check the documentation for more details.
      group_id = record.id
    }
    setExpandedRowKeys(keys)
    setFormAddProductToGroup({
      ...formAddProductToGroup,
      group_id: group_id,
    })
  }

  const handleAddGroup = (newGroup) => {
    let tmplist = lastestProductGroupList
    if (newGroup.name !== '') {
      setIsProductGroupLoading(true)
      setTempList(tmplist)
      dispatch(onSaveProductPriceGroup(newGroup))
    } else {
      Modal.warning({
        title: 'ตรวจสอบ',
        centered: true,
        content: 'กรุณากรอกชื่อกลุ่ม',
      })
    }
  }

  const handleAddProductToGroup = (product_id) => {
    setIsProductGroupLoading(true)
    let tmpNormal = filteredNoGroupNormalList
    if (formAddProductToGroup.group_id !== '') {
      setIsNoGroupNormalLoading(true)
      setTempList(tmpNormal)
      setFormAddProductToGroup({
        ...formAddProductToGroup,
        product_id: product_id,
      }).then((form) => {
        dispatch(onAddProductPriceGroupMember(form))
      })
    } else {
      Modal.warning({
        centered: true,
        title: 'ตรวจสอบ',
        content: 'กรุณาเลือกกลุ่มก่อนเพิ่มสินค้าเข้ากลุ่ม',
      })
      setIsProductGroupLoading(false)
    }
  }

  const handleDeleteProductFromGroup = (member) => {
    setIsProductGroupLoading(true)
    setIsNoGroupNormalLoading(true)
    setTempProduct({
      id: member.product_id,
      code: member.product_code,
      name: member.product_name,
    })
    dispatch(onDeleteMemberFromProductPriceGroup(member.id))
  }

  return (
    <div>
      <h1>จับกลุ่มสินค้าราคาเดียวกัน</h1>
      <Modal
        width="600px"
        centered
        visible={isShowAddModal}
        onCancel={() => {
          setIsShowAddModal(false)
          setNewGroup(DEFAULT_NEW_GROUP)
        }}
        onOk={() => {
          handleAddGroup(newGroup)
        }}
        title="เพิ่ม/แก้ไขกลุ่มสินค้า"
      >
        <div className="input-wrapper">
          <div className="input-item">
            <InlineInput
              label="ชื่อ"
              width="500px"
              required
              value={newGroup.name}
              onChange={(event) => {
                setNewGroup({ ...newGroup, name: event.target.value })
              }}
            />
          </div>
        </div>
      </Modal>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '20px 0px',
          background: '#c1ebff',
        }}
      >
        <div
          style={{
            display: 'inline-block',
            width: '50%',
            padding: '0px 20px',
          }}
        >
          <div>
            <h2
              className="mb-2"
              style={{ textAlign: 'center', background: 'white ' }}
            >
              กลุ่มสินค้า
              <Button
                icon={<PlusOutlined />}
                onClick={() => {
                  setIsShowAddModal(true)
                }}
                style={{ float: 'right', marginRight: 10 }}
              />
            </h2>
          </div>
          <div className="mb-2">
            <Search
              value={searchGroup}
              onChange={(event) => {
                setSearchGroup(event.target.value)
                setSearchGroupMember('')
              }}
              placeholder="ค้นชื่อกลุ่ม"
              onSearch={(value) => {
                handleGroupSearch(value)
              }}
              style={{ width: '35%',  marginRight: '20px' }}
            />
            <Search
              value={searchGroupMember}
              onChange={(event) => {
                setSearchGroupMember(event.target.value)
                setSearchGroup('')
              }}
              placeholder="ค้นรหัส/ชื่อ สมาชิก"
              onSearch={(value) => {
                handleGroupMemberSearch(value)
              }}
              style={{ width: '35%' }}
            />
            <Button
              type="primary"
              icon={<ClearOutlined />}
              onClick={onClearSearchGroup}
              style={{ width: '20%', float: 'right', marginRight: 0 }}
            >
              เคลียร์
            </Button>
          </div>
          <Spin spinning={isProductGroupLoading}>
            <Table
              rowKey="id"
              columns={GROUP_COLUMN}
              expandable={{
                expandedRowClassName: '',
                expandRowByClick: true,
                expandedRowKeys: expandedRowKeys,
                onExpand: onTableRowExpand,
                expandedRowRender: (record) => {
                  if (record.members.length === 0) {
                    return (
                      <div>
                        <i>ยังไม่มีสมาชิก</i>
                      </div>
                    )
                  } else {
                    return record.members
                      .sort((a, b) =>
                        a.product_code.localeCompare(b.product_code)
                      )
                      .map((member) => {
                        return (
                          <div
                            key={member.id}
                            className="group-product-content-row"
                          >
                            <div className="product-delete-icon">
                              <Popconfirm
                                title={`ต้องการลบสินค้าออกจากกลุ่มใช่ไหม ?`}
                                okText="ใช่"
                                cancelText="ยกเลิก"
                                onConfirm={(event) => {
                                  event.stopPropagation()
                                  handleDeleteProductFromGroup(member)
                                }}
                                onCancel={(event) => {
                                  event.stopPropagation()
                                }}
                              >
                                <Button
                                  size="small"
                                  type="primary"
                                  icon={<DeleteOutlined />}
                                  // onClick={() =>
                                  //   handleDeleteProductFromGroup(member)
                                  // }
                                />
                              </Popconfirm>
                            </div>
                            <div className="product-code">
                              {member.product_code}
                            </div>
                            <div className="product-name">
                              {member.product_name}
                            </div>
                          </div>
                        )
                      })
                  }
                },
              }}
              dataSource={filteredProductGroupList}
              size="middle"
              scroll={{ y: '65vh', x: '800' }}
              style={{ height: '65vh' }}
              pagination={{
                simple: true,
                position: ['bottomRight'],
                defaultPageSize: 20,
              }}
            />
          </Spin>
        </div>
        <div
          style={{
            display: 'inline-block',
            width: '50%',
            padding: '0px 20px',
          }}
        >
          <h2
            className="mb-2"
            style={{ textAlign: 'center', background: 'white ' }}
          >
            สินค้าไม่มีกลุ่ม
          </h2>
          <div className="mb-2">
            <Search
              value={searchNormal}
              onChange={(event) => {
                setSearchNormal(event.target.value)
                setSearchNormalName('')
              }}
              placeholder="ค้นรหัส"
              onSearch={(value) => {
                handleNormalSearch(value)
              }}
              style={{ width: '35%', marginRight: '20px' }}
            />
            <Search
              value={searchNormalName}
              onChange={(event) => {
                setSearchNormalName(event.target.value)
                setSearchNormal('')
              }}
              placeholder="ค้นชื่อ"
              onSearch={(value) => {
                handleNormalNameSearch(value)
              }}
              style={{ width: '35%' }}
            />
            <Button
              type="primary"
              icon={<ClearOutlined />}
              onClick={onClearSearchNormal}
              style={{ width: '20%', float: 'right', marginRight: 0 }}
            >
              เคลียร์
            </Button>
          </div>
          <Spin spinning={isNoGroupNormalLoading}>
            <Table
              rowKey="id"
              columns={NO_GROUP_NORMAL_COLUMN}
              dataSource={filteredNoGroupNormalList}
              size="middle"
              scroll={{ y: '65vh', x: '800' }}
              style={{ height: '65vh' }}
              pagination={{
                simple: true,
                position: ['bottomRight'],
                defaultPageSize: 20,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '50', '100'],
              }}
            />
          </Spin>
        </div>
      </div>
    </div>
  )
}

export default ProductPricingGroupPage
