import React from 'react'
import { Input } from 'antd'

const Search = Input.Search

export const SearchBox = ({ onSearch, ...props }) => (
  <div {...props}>
    <Search placeholder="คำค้นหา" onSearch={onSearch} style={{ width: 200 }} />
  </div>
)
