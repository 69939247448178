import _ from 'lodash'
export default (type, initialData, list = false) => (
  state = {
    loading: false,
    trace: null,
    finished: false,
    data: initialData || [],
    list,
  },
  action
) => {
  switch (action.type) {
    case `REQUEST/${type}`:
      return {
        ...state,
        finished: false,
        loading: true,
      }

    case `OK/${type}`:
      if (_.isEmpty(action.payload)) {
        if (_.isEmpty(state.data)) {
          return {
            ...state,
            has_more: false,
            error: false,
            loading: false,
            trace: null,
            finished: true,
          }
        } else {
          return {
            ...state,
            has_more: false,
            error: false,
            loading: false,
            trace: null,
            finished: true,
            data: action.payload,
          }
        }
      }
      if (_.isEmpty(state.data) || !list) {
        return {
          ...state,
          loading: false,
          finished: true,
          data: action.payload,
          error: false,
          trace: null,
          has_more: true,
        }
      } else {
        return {
          ...state,
          loading: false,
          finished: true,
          data: [...state.data, ...action.payload],
          error: false,
          trace: null,
          has_more: true,
        }
      }

    case `ERROR/${type}`:
      return {
        ...state,
        error: true,
        loading: false,
        trace: action.error,
        finished: false,
      }
    case `AUTH/${type}`:
      return {
        ...state,
        finished: false,
        data: action.payload,
      }
    default:
      return state
  }
}
